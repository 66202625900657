var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1200px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("상품정보고시 등록")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "bar-title" }, [_vm._v("기본정보")]),
            _c("div", { staticClass: "boxing" }, [
              _c("div", { staticClass: "form-area" }, [
                _c("dl", [
                  _vm._m(0),
                  _c("dd", [
                    _c("div", { staticClass: "radio_wrap wide" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.istrash,
                            expression: "info.istrash",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "rgroup00",
                          id: "rgroup01",
                          value: "F",
                          checked: "",
                        },
                        domProps: { checked: _vm._q(_vm.info.istrash, "F") },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.info, "istrash", "F")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rgroup01" } }, [
                        _vm._v("사용함"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.istrash,
                            expression: "info.istrash",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "rgroup00",
                          id: "rgroup02",
                          value: "T",
                        },
                        domProps: { checked: _vm._q(_vm.info.istrash, "T") },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.info, "istrash", "T")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rgroup02" } }, [
                        _vm._v("사용안함"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("dl", [
                  _vm._m(1),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.title,
                          expression: "info.title",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", placeholder: "상품정보고시명" },
                      domProps: { value: _vm.info.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.info, "title", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("설명")]),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.note,
                          expression: "info.note",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", placeholder: "상품정보고시 설명" },
                      domProps: { value: _vm.info.note },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.info, "note", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "bar-title" }, [_vm._v("조건설정")]),
            _c("div", { staticClass: "form-area" }, [
              _c(
                "table",
                {
                  staticClass: "gray-tb",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(2),
                  _c(
                    "tbody",
                    _vm._l(this.itemList, function (row, index) {
                      return _c("tr", { key: index }, [
                        _vm._m(3, true),
                        _c("td", [
                          _c("div", { staticClass: "dpb" }, [
                            _c("span", [_vm._v("항목")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: row.notifyname,
                                  expression: "row.notifyname",
                                },
                              ],
                              staticClass: "ml10",
                              attrs: { type: "text", placeholder: "항목 명칭" },
                              domProps: { value: row.notifyname },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    row,
                                    "notifyname",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("span", { staticClass: "ml10" }, [
                              _vm._v("내용"),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: row.notifydata,
                                  expression: "row.notifydata",
                                },
                              ],
                              staticClass: "ml10",
                              staticStyle: { width: "calc(100% - 395px)" },
                              attrs: { type: "text", placeholder: "내용" },
                              domProps: { value: row.notifydata },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    row,
                                    "notifydata",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("button", {
                              staticClass: "del",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeItem(index)
                                },
                              },
                            }),
                            index === _vm.itemList.length - 1
                              ? _c("button", {
                                  staticClass: "add",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addItem()
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.onSave },
                },
                [_vm._v("저장")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("취소")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_vm._v("사용여부"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _vm._v("상품정보고시명"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("항목/내용"),
      _c("i", { staticClass: "essential" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }