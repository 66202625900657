var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1600px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [
              _vm._v("반품상세" + _vm._s(" - " + _vm.claimInfo.rtnstatusnm)),
            ]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c(
            "div",
            { staticClass: "pop-body" },
            [
              _c("div", { staticClass: "gray-box mg0" }, [
                _c("div", { staticClass: "clearfix" }, [
                  _c("div", { staticClass: "fl" }, [
                    _vm._v(
                      " [" +
                        _vm._s(_vm.orderInfo.ordpathtypename) +
                        "] 주문번호 : "
                    ),
                    _c("strong", [_vm._v(_vm._s(_vm.orderInfo.ordno))]),
                  ]),
                  _c("div", { staticClass: "fr txt-gray" }, [
                    _c("span", [
                      _vm._v("주문일 : " + _vm._s(_vm.orderInfo.orderdate)),
                    ]),
                    _vm.isPartner
                      ? _c("span", { staticClass: "left-bar" }, [
                          _vm._v(
                            "반품신청일 : " + _vm._s(_vm.orderInfo.clmreqdate)
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                !_vm.isPartner
                  ? _c("hr", {
                      staticClass: "solid",
                      staticStyle: { margin: "10px 0" },
                    })
                  : _vm._e(),
                !_vm.isPartner
                  ? _c("div", { staticClass: "clearfix" }, [
                      _c("div", { staticClass: "fl" }, [
                        _vm.orderInfo.isnonmember == "F"
                          ? _c("span", [
                              _vm._v(
                                "[" +
                                  _vm._s(_vm.orderInfo.membertypename) +
                                  "] [" +
                                  _vm._s(_vm.orderInfo.memlvtypename) +
                                  "] " +
                                  _vm._s(_vm.orderInfo.ordname) +
                                  "(" +
                                  _vm._s(_vm.orderInfo.ordid) +
                                  ")"
                              ),
                            ])
                          : _c("span", [_vm._v(_vm._s(_vm.orderInfo.ordname))]),
                        _vm.orderInfo.isnonmember == "F"
                          ? _c("span", { staticClass: "left-bar" }, [
                              _vm._v(_vm._s(_vm.orderInfo.ordtel)),
                            ])
                          : _vm._e(),
                        _vm.orderInfo.isnonmember == "F"
                          ? _c("span", { staticClass: "left-bar" }, [
                              _vm._v(_vm._s(_vm.orderInfo.ordemail)),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "fr txt-gray" }, [
                        _c("span", [
                          _vm._v(
                            "반품신청일 : " + _vm._s(_vm.orderInfo.clmreqdate)
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._m(0),
              _vm._l(_vm.claimGoodsList, function (row) {
                return [
                  _c(
                    "table",
                    {
                      key: "list_" + row.ordgdidx,
                      staticClass: "data-tb align-c mt0",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _c("caption", [_vm._v(" 반품상품 목록 ")]),
                      _c("colgroup", [
                        !_vm.isPartner
                          ? _c("col", { attrs: { width: "4%" } })
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("col", { attrs: { width: "6%" } })
                          : _vm._e(),
                        _c("col", { attrs: { width: "6.5%" } }),
                        _c("col", { attrs: { width: "4%" } }),
                        _c("col", { attrs: { width: "62px" } }),
                        _c("col", { attrs: { width: "" } }),
                        _c("col", { attrs: { width: "10%" } }),
                        _c("col", { attrs: { width: "4%" } }),
                        _c("col", { attrs: { width: "4%" } }),
                        _c("col", { attrs: { width: "5.5%" } }),
                        _c("col", { attrs: { width: "5.5%" } }),
                        _c("col", { attrs: { width: "5.5%" } }),
                        _c("col", { attrs: { width: "8%" } }),
                        _c("col", { attrs: { width: "9%" } }),
                      ]),
                      _c("thead", [
                        _c("tr", [
                          !_vm.isPartner
                            ? _c("th", [_vm._v("판매구분")])
                            : _vm._e(),
                          !_vm.isPartner
                            ? _c("th", [_vm._v("파트너사")])
                            : _vm._e(),
                          _c("th", [_vm._v("상품코드")]),
                          _c("th", [_vm._v("단품코드")]),
                          _c("th", { attrs: { colspan: "2" } }, [
                            _vm._v("상품명"),
                          ]),
                          _c("th", [_vm._v("옵션")]),
                          _c("th", [_vm._v("주문수량")]),
                          _c("th", [_vm._v("반품수량")]),
                          _c("th", [_vm._v("판매단가")]),
                          _c("th", [_vm._v("판매금액")]),
                          _c("th", [_vm._v("반품금액")]),
                          _c("th", [_vm._v("반품상태")]),
                          _c("th", [_vm._v("반품번호")]),
                        ]),
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          !_vm.isPartner
                            ? _c("td", [
                                _vm._v(
                                  _vm._s(
                                    row.ispbgoods === "T" ? "자사" : "파트너사"
                                  )
                                ),
                              ])
                            : _vm._e(),
                          !_vm.isPartner
                            ? _c("td", [_vm._v(_vm._s(row.dealernm))])
                            : _vm._e(),
                          _c("td", [_vm._v(_vm._s(row.goodscode))]),
                          _c("td", [_vm._v(_vm._s(row.optioncode))]),
                          _c("td", [
                            _c(
                              "div",
                              {
                                staticClass: "img-thumb size60 link",
                                class: {
                                  "no-image": _vm.$util.isNull(row.fullpath),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goFrontGoodsDetail(row.goodscode)
                                  },
                                },
                              },
                              [
                                !_vm.$util.isNull(row.fullpath)
                                  ? _c("img", { attrs: { src: row.fullpath } })
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _c("td", { staticClass: "left no-left" }, [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goGoodsDetail(row.goodsno)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.goodsname))]
                            ),
                          ]),
                          _c(
                            "td",
                            { staticStyle: { "white-space": "pre-wrap" } },
                            [_vm._v(_vm._s(row.optionname))]
                          ),
                          _c("td", [
                            _vm._v(_vm._s(_vm.$util.maskComma(row.bfordcnt))),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.$util.maskComma(row.clmcnt))),
                          ]),
                          _c("td", { staticClass: "right" }, [
                            _vm._v(_vm._s(_vm.$util.maskComma(row.price))),
                          ]),
                          _c("td", { staticClass: "right" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$util.maskComma(
                                    Number(row.price) * Number(row.bfordcnt)
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", { staticClass: "right" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$util.maskComma(
                                  Number(row.price) * Number(row.clmcnt)
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goClaimHistory(row.clmgdidx)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(row.boclmstatusnm) +
                                    " " +
                                    _vm._s(
                                      row.clmstatus ===
                                        _vm.$store.getters["ADMIN"]
                                          .RETURN_APPROVAL_COMPLETE &&
                                        _vm.claimInfo.isrectracking === "T"
                                        ? " - 회수진행요청"
                                        : ""
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(row.clmno))]),
                        ]),
                      ]),
                    ]
                  ),
                ]
              }),
              _c(
                "table",
                {
                  staticClass: "gray-tb",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(1),
                  _c("tbody", [
                    _c("tr", [
                      _vm._m(2),
                      _c("td", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.claimInfo.rtntype,
                                expression: "claimInfo.rtntype",
                              },
                            ],
                            attrs: { disabled: "" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.claimInfo,
                                  "rtntype",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("구분선택"),
                            ]),
                            _vm._l(_vm.commonCode.rtntype, function (item) {
                              return _c(
                                "option",
                                {
                                  key: item.cmcode,
                                  domProps: { value: item.cmcode },
                                },
                                [_vm._v(" " + _vm._s(item.codename) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.claimInfo.clmdetail,
                              expression: "claimInfo.clmdetail",
                            },
                          ],
                          staticClass: "ml3",
                          staticStyle: { width: "calc(100% - 156px)" },
                          attrs: {
                            type: "text",
                            maxlength: "500",
                            disabled: "",
                          },
                          domProps: { value: _vm.claimInfo.clmdetail },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.claimInfo,
                                "clmdetail",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("사진첨부")]),
                      _c(
                        "td",
                        _vm._l(_vm.fileList, function (row, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "dpib" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "file-link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewFile(row.fullpath)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.imgforiname))]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]
              ),
              !_vm.isPartner &&
              !_vm.$util.isNull(_vm.claimGiftList) &&
              _vm.claimGiftList.length > 0
                ? _c("div", { staticClass: "bar-title small" }, [
                    _vm._v(" 반품 사은품 "),
                  ])
                : _vm._e(),
              !_vm.$util.isNull(_vm.claimGiftList) &&
              _vm.claimGiftList.length > 0
                ? _c(
                    "table",
                    {
                      staticClass: "data-tb align-c",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _c("caption", [_vm._v(" 사은품 목록 ")]),
                      _vm._m(3),
                      _vm._m(4),
                      _c(
                        "tbody",
                        _vm._l(_vm.claimGiftList, function (item, index) {
                          return _c("tr", { key: "cncgift" + index }, [
                            _c("td", [_vm._v(_vm._s(item.giftcode))]),
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticClass: "img-thumb size60",
                                  class: {
                                    "no-image": _vm.$util.isNull(item.fullpath),
                                  },
                                },
                                [
                                  !_vm.$util.isNull(item.fullpath)
                                    ? _c("img", {
                                        attrs: { src: item.fullpath },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                            _c("td", { staticClass: "left no-left" }, [
                              _c("p", { staticClass: "mg0" }, [
                                _vm._v(_vm._s(item.giftname)),
                              ]),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.rcvamt))]),
                            _c("td", [_vm._v(_vm._s(item.promoname))]),
                          ])
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.isPartner &&
              (_vm.isEditGift ||
                (!_vm.$util.isNull(_vm.chkReapplyGiftList) &&
                  _vm.chkReapplyGiftList.length > 0))
                ? _c("div", { staticClass: "clearfix" }, [
                    _c("div", { staticClass: "bar-title small fl" }, [
                      _vm._v("재 적용 사은품"),
                    ]),
                  ])
                : _vm._e(),
              !_vm.isPartner &&
              (_vm.isEditGift ||
                (!_vm.$util.isNull(_vm.chkReapplyGiftList) &&
                  _vm.chkReapplyGiftList.length > 0))
                ? _c(
                    "table",
                    {
                      staticClass: "data-tb align-c mt0",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _c("caption", [_vm._v(" 사은품 목록 ")]),
                      _vm._m(5),
                      _vm._m(6),
                      _vm.chkReapplyGiftList.length > 0
                        ? _c(
                            "tbody",
                            _vm._l(
                              _vm.chkReapplyGiftList,
                              function (item, index) {
                                return _c("tr", { key: "chkgift" + index }, [
                                  _c("td", [_vm._v(_vm._s(item.giftcode))]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "img-thumb size60",
                                        class: {
                                          "no-image": _vm.$util.isNull(
                                            item.fullpath
                                          ),
                                        },
                                      },
                                      [
                                        !_vm.$util.isNull(item.fullpath)
                                          ? _c("img", {
                                              attrs: { src: item.fullpath },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                  _c("td", { staticClass: "left no-left" }, [
                                    _c("p", { staticClass: "mg0" }, [
                                      _vm._v(_vm._s(item.giftname)),
                                    ]),
                                  ]),
                                  _c("td", [_vm._v(_vm._s(item.rcvamt))]),
                                  _c("td", [_vm._v(_vm._s(item.promoname))]),
                                ])
                              }
                            ),
                            0
                          )
                        : _c("tbody", [_vm._m(7)]),
                    ]
                  )
                : _vm._e(),
              _c("hr", { staticClass: "dash" }),
              _c("div", { staticClass: "col2 pd scroll" }, [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticClass: "bar-title small" }, [
                    _vm._v("회수지 정보"),
                  ]),
                  _c(
                    "table",
                    {
                      staticClass: "gray-tb",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _vm._m(8),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("회수자 명")]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.recInfo.recname,
                                  expression: "recInfo.recname",
                                },
                              ],
                              attrs: {
                                type: "text",
                                maxlength: "50",
                                disabled:
                                  _vm.claimInfo.isrectracking === "T" ||
                                  !_vm.isEditRecInfo,
                              },
                              domProps: { value: _vm.recInfo.recname },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.recInfo,
                                    "recname",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("연락처")]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.recInfo.rectel,
                                  expression: "recInfo.rectel",
                                },
                              ],
                              attrs: {
                                type: "text",
                                maxlength: "11",
                                disabled:
                                  _vm.claimInfo.isrectracking === "T" ||
                                  !_vm.isEditRecInfo,
                                oninput:
                                  "this.value = this.value.replace(/([^0-9])/gi, '');",
                              },
                              domProps: { value: _vm.recInfo.rectel },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.recInfo,
                                    "rectel",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("회수지 주소")]),
                          _c("td", { attrs: { colspan: "3" } }, [
                            _c("div", { staticClass: "dpb" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.recInfo.recpost,
                                    expression: "recInfo.recpost",
                                  },
                                ],
                                staticClass: "short",
                                attrs: {
                                  type: "text",
                                  maxlength: "7",
                                  readonly: "",
                                },
                                domProps: { value: _vm.recInfo.recpost },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.recInfo,
                                      "recpost",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn blue-line ml3",
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      _vm.claimInfo.isrectracking === "T" ||
                                      !_vm.isEditRecInfo,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.searchAddress("rec")
                                    },
                                  },
                                },
                                [_vm._v(" 주소검색 ")]
                              ),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.recInfo.recaddrroad,
                                  expression: "recInfo.recaddrroad",
                                },
                              ],
                              staticClass: "dpb",
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "text",
                                maxlength: "100",
                                readonly: "",
                              },
                              domProps: { value: _vm.recInfo.recaddrroad },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.recInfo,
                                    "recaddrroad",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.recInfo.recaddrdetailroad,
                                  expression: "recInfo.recaddrdetailroad",
                                },
                              ],
                              staticClass: "dpb",
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "text",
                                maxlength: "100",
                                disabled:
                                  _vm.claimInfo.isrectracking === "T" ||
                                  !_vm.isEditRecInfo,
                              },
                              domProps: {
                                value: _vm.recInfo.recaddrdetailroad,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.recInfo,
                                    "recaddrdetailroad",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "right" }, [
                  _c("div", { staticClass: "bar-title small" }, [
                    _vm._v("회수택배사 정보"),
                  ]),
                  _c(
                    "table",
                    {
                      staticClass: "gray-tb",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _vm._m(9),
                      _c("tbody", [
                        this.claimInfo.rtndelivtype == "DLT004" &&
                        (this.claimInfo.rtnstatus == "RTS006" ||
                          this.claimInfo.rtnstatus == "RTS007")
                          ? _c("tr", [
                              _c("th", [_vm._v("회수 택배사")]),
                              _c("td", [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.recInfo.reclogistype,
                                        expression: "recInfo.reclogistype",
                                      },
                                    ],
                                    staticStyle: { width: "160px" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.recInfo,
                                          "reclogistype",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(_vm.rtnlogislist, function (row) {
                                    return _c(
                                      "option",
                                      {
                                        key: row.index,
                                        domProps: { value: row.rtnlogistype },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(row.logistypename) + " "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.recInfo.recinvoiceno,
                                      expression: "recInfo.recinvoiceno",
                                    },
                                  ],
                                  staticClass: "ml3",
                                  attrs: {
                                    type: "text",
                                    placeholder: "송장번호",
                                    maxlength: "50",
                                    oninput:
                                      "this.value = this.value.replace(/(^0|[^0-9])/gi, '');",
                                  },
                                  domProps: { value: _vm.recInfo.recinvoiceno },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.recInfo,
                                        "recinvoiceno",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn big blue ml3",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveRtninvoiceno(_vm.recInfo)
                                      },
                                    },
                                  },
                                  [_vm._v(" 저장 ")]
                                ),
                              ]),
                            ])
                          : _c("tr", [
                              _c("th", [_vm._v("회수 택배사")]),
                              _c("td", [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.recInfo.reclogistype,
                                        expression: "recInfo.reclogistype",
                                      },
                                    ],
                                    staticStyle: { width: "160px" },
                                    attrs: { disabled: "" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.recInfo,
                                          "reclogistype",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(_vm.rtnlogislist, function (row) {
                                    return _c(
                                      "option",
                                      {
                                        key: row.rtnlogistype,
                                        domProps: { value: row.rtnlogistype },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(row.logistypename) + " "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.recInfo.recinvoiceno,
                                      expression: "recInfo.recinvoiceno",
                                    },
                                  ],
                                  staticClass: "ml3",
                                  attrs: {
                                    type: "text",
                                    placeholder: "송장번호",
                                    maxlength: "50",
                                    disabled: "",
                                    oninput:
                                      "this.value = this.value.replace(/(^0|[^0-9])/gi, '');",
                                  },
                                  domProps: { value: _vm.recInfo.recinvoiceno },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.recInfo,
                                        "recinvoiceno",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                        _c("tr", [
                          _c("th", [_vm._v("회수 완료일")]),
                          _c(
                            "td",
                            [
                              _c("CommonDatePicker", {
                                attrs: {
                                  value: _vm.recInfo.reccomdate,
                                  disable: true,
                                },
                                on: { change: _vm.onChangeRecDate },
                              }),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.recInfo.reccomtime,
                                    expression: "recInfo.reccomtime",
                                  },
                                ],
                                staticClass: "ml3",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.recInfo.reccomtime },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.recInfo,
                                      "reccomtime",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("상태")]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.recInfo.recstatname,
                                  expression: "recInfo.recstatname",
                                },
                              ],
                              attrs: { type: "text", readonly: "" },
                              domProps: { value: _vm.recInfo.recstatname },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.recInfo,
                                    "recstatname",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "button",
                              {
                                staticClass: "btn blue-line ml3",
                                attrs: {
                                  type: "button",
                                  disabled:
                                    _vm.$util.isNull(
                                      _vm.recInfo.recinvoiceno
                                    ) ||
                                    _vm.claimInfo.rtnstatus ===
                                      _vm.$store.getters["ADMIN"]
                                        .RETURN_APPROVAL_COMPLETE ||
                                    _vm.claimInfo.rtndelivtype ===
                                      _vm.$store.getters["ADMIN"].DELIV_DIRECT,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goDelivTracking("rec")
                                  },
                                },
                              },
                              [_vm._v(" 배송조회 ")]
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("hr", { staticClass: "dash" }),
              _c("div", { staticClass: "col3 pd scroll" }, [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticClass: "bar-title small" }, [
                    _vm._v("이전 주문정보"),
                  ]),
                  _c(
                    "table",
                    {
                      staticClass: "gray-tb lower",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _vm._m(10),
                      _c(
                        "tbody",
                        [
                          _c("tr", [
                            _c("th", [_vm._v("판매금액 합계")]),
                            _c(
                              "td",
                              { staticClass: "right", attrs: { colspan: "2" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.clmInfo.bforder.ordtotprice
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          !_vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("자사 배송비(+)")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.clmInfo.bforder.isolatetype === "N"
                                          ? "기본배송비"
                                          : "도서산간배송비"
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.clmInfo.bforder.dadadelivamt
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c("th", [_vm._v("파트너사 배송비(+)")]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.clmInfo.bforder.isolatetype === "N"
                                      ? "기본배송비"
                                      : "도서산간배송비"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.maskComma(
                                    _vm.clmInfo.bforder.ptndelivamt
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm.$util.isNull(_vm.clmInfo.bfpromotion) ||
                          _vm.clmInfo.bfpromotion.length === 0
                            ? _c("tr", [
                                _c("th", [_vm._v("프로모션 할인")]),
                                _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                                _c("td", { staticClass: "right txt-red" }, [
                                  _vm._v("0"),
                                ]),
                              ])
                            : _c(
                                "fragment",
                                _vm._l(
                                  _vm.clmInfo.bfpromotion,
                                  function (promoRow, index) {
                                    return _c("tr", { key: promoRow.idx }, [
                                      index === 0
                                        ? _c(
                                            "th",
                                            {
                                              attrs: {
                                                rowspan:
                                                  _vm.clmInfo.bfpromotion
                                                    .length,
                                              },
                                            },
                                            [_vm._v("프로모션 할인")]
                                          )
                                        : _vm._e(),
                                      _c("td", [
                                        _vm._v(_vm._s(promoRow.promoname)),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "right txt-red" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$util.maskComma(
                                                  Number(promoRow.disamount) *
                                                    -1
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                          _vm.$util.isNull(_vm.clmInfo.bfgoodscoupon) ||
                          _vm.clmInfo.bfgoodscoupon.length === 0
                            ? _c("tr", [
                                _c("th", [_vm._v("상품 할인")]),
                                _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                                _c("td", { staticClass: "right txt-red" }, [
                                  _vm._v("0"),
                                ]),
                              ])
                            : _c(
                                "fragment",
                                _vm._l(
                                  _vm.clmInfo.bfgoodscoupon,
                                  function (goodsCpnRow, index) {
                                    return _c("tr", { key: goodsCpnRow.idx }, [
                                      index === 0
                                        ? _c(
                                            "th",
                                            {
                                              attrs: {
                                                rowspan:
                                                  _vm.clmInfo.bfgoodscoupon
                                                    .length,
                                              },
                                            },
                                            [_vm._v("상품 할인")]
                                          )
                                        : _vm._e(),
                                      _c("td", [
                                        _vm._v(_vm._s(goodsCpnRow.cpnname)),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "right txt-red" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$util.maskComma(
                                                  Number(
                                                    goodsCpnRow.disamount
                                                  ) * -1
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                          _c("tr", [
                            _c("th", [_vm._v("장바구니 할인")]),
                            !_vm.$util.isNull(_vm.clmInfo.bfbasketcoupon) &&
                            _vm.clmInfo.bforder.basketcpnamt > 0
                              ? _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.clmInfo.bfbasketcoupon.cpnname
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                            _c("td", { staticClass: "right txt-red" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(_vm.clmInfo.bforder.basketcpnamt) *
                                        -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("배송비 할인")]),
                            !_vm.$util.isNull(_vm.clmInfo.bfdelivcoupon) &&
                            _vm.clmInfo.bforder.totdelivcpnamt > 0
                              ? _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.clmInfo.bfdelivcoupon.cpnname
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                            _c("td", { staticClass: "right txt-red" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(
                                        _vm.clmInfo.bforder.totdelivcpnamt
                                      ) * -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          !_vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("임직원적립금 사용(-)")]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right txt-red",
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$util.maskComma(
                                            Number(
                                              _vm.clmInfo.bforder
                                                .empreservetotamt
                                            ) * -1
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          !_vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("적립금 사용(-)")]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right txt-red",
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$util.maskComma(
                                            Number(
                                              _vm.clmInfo.bforder.reservetotamt
                                            ) * -1
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          !_vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("D포인트 사용(-)")]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right txt-red",
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$util.maskComma(
                                            Number(
                                              _vm.clmInfo.bforder.epointtotamt
                                            ) * -1
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("적립금 사용(-)")]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right txt-red",
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$util.maskComma(
                                            (Number(
                                              _vm.clmInfo.bforder
                                                .empreservetotamt
                                            ) +
                                              Number(
                                                _vm.clmInfo.bforder
                                                  .reservetotamt
                                              ) +
                                              Number(
                                                _vm.clmInfo.bforder.epointtotamt
                                              )) *
                                              -1
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c("th", [_vm._v("실 결제금액")]),
                            _c(
                              "td",
                              { staticClass: "right", attrs: { colspan: "2" } },
                              [
                                _c("strong", { staticClass: "large-txt" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.clmInfo.bforder.rpaytotprice
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "middle" }, [
                  _c("div", { staticClass: "bar-title small" }, [
                    _vm._v("재 계산 판매금액 합계"),
                  ]),
                  _c(
                    "table",
                    {
                      staticClass: "gray-tb lower",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _vm._m(11),
                      _c(
                        "tbody",
                        [
                          _c("tr", [
                            _c("th", [_vm._v("재 계산 주문금액 합계")]),
                            _c(
                              "td",
                              { staticClass: "right", attrs: { colspan: "2" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.clmInfo.aforder.ordtotprice
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          !_vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("자사 배송비(+)")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.clmInfo.bforder.isolatetype === "N"
                                          ? "기본배송비"
                                          : "도서산간배송비"
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.clmInfo.aforder.dadadelivamt
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c("th", [_vm._v("파트너사 배송비(+)")]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.clmInfo.bforder.isolatetype === "N"
                                      ? "기본배송비"
                                      : "도서산간배송비"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.maskComma(
                                    _vm.clmInfo.aforder.ptndelivamt
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm.$util.isNull(_vm.clmInfo.afpromotion) ||
                          _vm.clmInfo.afpromotion.length === 0
                            ? _c("tr", [
                                _c("th", [_vm._v("프로모션 할인")]),
                                _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                                _c("td", { staticClass: "right txt-red" }, [
                                  _vm._v("0"),
                                ]),
                              ])
                            : _c(
                                "fragment",
                                _vm._l(
                                  _vm.clmInfo.afpromotion,
                                  function (promoRow, index) {
                                    return _c("tr", { key: promoRow.idx }, [
                                      index === 0
                                        ? _c(
                                            "th",
                                            {
                                              attrs: {
                                                rowspan:
                                                  _vm.clmInfo.afpromotion
                                                    .length,
                                              },
                                            },
                                            [_vm._v("프로모션 할인")]
                                          )
                                        : _vm._e(),
                                      _c("td", [
                                        _vm._v(_vm._s(promoRow.promoname)),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "right txt-red" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$util.maskComma(
                                                  Number(promoRow.disamount) *
                                                    -1
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                          _vm.$util.isNull(_vm.clmInfo.afgoodscoupon) ||
                          _vm.clmInfo.afgoodscoupon.length === 0
                            ? _c("tr", [
                                _c("th", [_vm._v("상품 할인")]),
                                _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                                _c("td", { staticClass: "right txt-red" }, [
                                  _vm._v("0"),
                                ]),
                              ])
                            : _c(
                                "fragment",
                                _vm._l(
                                  _vm.clmInfo.afgoodscoupon,
                                  function (goodsCpnRow, index) {
                                    return _c("tr", { key: goodsCpnRow.idx }, [
                                      index === 0
                                        ? _c(
                                            "th",
                                            {
                                              attrs: {
                                                rowspan:
                                                  _vm.clmInfo.afgoodscoupon
                                                    .length,
                                              },
                                            },
                                            [_vm._v("상품 할인")]
                                          )
                                        : _vm._e(),
                                      _c("td", [
                                        _vm._v(_vm._s(goodsCpnRow.cpnname)),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "right txt-red" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$util.maskComma(
                                                  Number(
                                                    goodsCpnRow.disamount
                                                  ) * -1
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                          _c("tr", [
                            _c("th", [_vm._v("장바구니 할인")]),
                            _vm.clmInfo.aforder.basketcpnamt > 0
                              ? _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.clmInfo.bfbasketcoupon.cpnname
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                            _c("td", { staticClass: "right txt-red" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(_vm.clmInfo.aforder.basketcpnamt) *
                                        -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("배송비 할인")]),
                            !_vm.$util.isNull(_vm.clmInfo.afdelivcoupon) &&
                            _vm.clmInfo.aforder.totdelivcpnamt > 0
                              ? _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.clmInfo.afdelivcoupon.cpnname
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                            _c("td", { staticClass: "right txt-red" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(
                                        _vm.clmInfo.aforder.totdelivcpnamt
                                      ) * -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          !_vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("임직원적립금 사용(-)")]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right txt-red",
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$util.maskComma(
                                            Number(
                                              _vm.clmInfo.aforder
                                                .empreservetotamt
                                            ) * -1
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          !_vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("적립금 사용(-)")]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right txt-red",
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$util.maskComma(
                                            Number(
                                              _vm.clmInfo.aforder.reservetotamt
                                            ) * -1
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          !_vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("D포인트 사용(-)")]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right txt-red",
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$util.maskComma(
                                            Number(
                                              _vm.clmInfo.aforder.epointtotamt
                                            ) * -1
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("적립금 사용(-)")]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right txt-red",
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$util.maskComma(
                                            (Number(
                                              _vm.clmInfo.aforder
                                                .empreservetotamt
                                            ) +
                                              Number(
                                                _vm.clmInfo.aforder
                                                  .reservetotamt
                                              ) +
                                              Number(
                                                _vm.clmInfo.aforder.epointtotamt
                                              )) *
                                              -1
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c("th", [_vm._v("재 계산 최종금액")]),
                            _c(
                              "td",
                              { staticClass: "right", attrs: { colspan: "2" } },
                              [
                                _c("strong", { staticClass: "large-txt" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.clmInfo.aforder.rpaytotprice
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    Number(_vm.claimInfo.rtndadadelivamt) +
                      Number(_vm.claimInfo.rtnptndelivamt) >
                    0
                      ? [
                          _c("div", { staticClass: "bar-title small" }, [
                            _vm._v("차감배송비"),
                          ]),
                          _c(
                            "table",
                            {
                              staticClass: "gray-tb lower",
                              attrs: { cellpadding: "0", cellspacing: "0" },
                            },
                            [
                              _vm._m(12),
                              _c("tbody", [
                                !_vm.isPartner
                                  ? _c("tr", [
                                      _c("th", [_vm._v("자사배송비")]),
                                      _c("td", { staticClass: "right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$util.maskComma(
                                              _vm.claimInfo.rtndadadelivamt
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _c("tr", [
                                  _c("th", [_vm._v("파트너사 배송비")]),
                                  _c("td", { staticClass: "right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$util.maskComma(
                                          _vm.claimInfo.rtnptndelivamt
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _c("div", { staticClass: "bar-title small" }, [
                      _vm._v("추가배송비"),
                    ]),
                    _c(
                      "table",
                      {
                        staticClass: "gray-tb lower",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _vm._m(13),
                        _c("tbody", [
                          !_vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("자사배송비")]),
                                _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.claimInfo.adddadadelivamt
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c("th", [_vm._v("파트너사 배송비")]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.maskComma(
                                    _vm.claimInfo.addptndelivamt
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    Number(_vm.claimInfo.addrpaytotprice) > 0
                      ? [
                          _c("div", { staticClass: "bar-title small" }, [
                            _vm._v(" 추가결제내역"),
                            _vm.claimInfo.rtnstatus ===
                            _vm.$store.getters["ADMIN"].RETURN_WAITING_DEPOSIT
                              ? _c(
                                  "span",
                                  { staticClass: "normal txt-orange ml10" },
                                  [
                                    _vm._v(
                                      "추가금 발생일로부터 " +
                                        _vm._s(
                                          _vm.$util.maskComma(
                                            _vm.claimInfo.elapsedaycnt
                                          )
                                        ) +
                                        "일 경과"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "table",
                            {
                              staticClass: "gray-tb lower",
                              attrs: { cellpadding: "0", cellspacing: "0" },
                            },
                            [
                              _vm._m(14),
                              _c("tbody", [
                                _c("tr", [
                                  _c("th", [_vm._v("결제일시")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.claimInfo.addpaymentdate)
                                    ),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [_vm._v("결제자명")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$util.isNull(
                                          _vm.claimInfo.addpaymentdate
                                        )
                                          ? ""
                                          : _vm.orderInfo.ordname
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [_vm._v("결제수단")]),
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.claimInfo.addpaywaytypenm) +
                                        " " +
                                        _vm._s(
                                          _vm.claimInfo.addpaywaytype ===
                                            _vm.$store.getters["ADMIN"]
                                              .PAY_CREDIT_CARD
                                            ? "(" +
                                                _vm.claimInfo.cardcompany +
                                                "/" +
                                                (_vm.claimInfo.planmonth == 0 ||
                                                _vm.$util.isNull(
                                                  _vm.claimInfo.planmonth
                                                )
                                                  ? "일시불"
                                                  : _vm.claimInfo.planmonth +
                                                    "개월") +
                                                ")"
                                            : ""
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.claimInfo.addpaywaytype ===
                                            _vm.$store.getters["ADMIN"]
                                              .PAY_VIRTURE_ACCOUNT
                                            ? "(" +
                                                _vm.claimInfo.virbank +
                                                "/" +
                                                _vm.claimInfo.accntnumber +
                                                ")"
                                            : ""
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.claimInfo.addpaywaytype ===
                                            _vm.$store.getters["ADMIN"]
                                              .PAY_ACCOUNT_TRANSFER
                                            ? "(" + _vm.claimInfo.trsbank + ")"
                                            : ""
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.claimInfo.addpaywaytype ===
                                            _vm.$store.getters["ADMIN"]
                                              .PAY_MOBILE
                                            ? "(" +
                                                _vm.claimInfo.mopcarrier +
                                                ")"
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [_vm._v("금액")]),
                                  _c("td", { staticClass: "right" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.claimInfo.addrpaytotprice === 0
                                            ? ""
                                            : _vm.$util.maskComma(
                                                _vm.claimInfo.addrpaytotprice
                                              )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _c("div", { staticClass: "bar-title small" }, [
                      _vm._v("환불 금액"),
                    ]),
                    _c(
                      "table",
                      {
                        staticClass: "gray-tb lower",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _vm._m(15),
                        _c("tbody", [
                          _c("tr", [
                            _c("th", [_vm._v("환불예정금액")]),
                            _c("td", { staticClass: "right" }, [
                              _c("strong", { staticClass: "large-txt" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$util.maskComma(_vm.claimInfo.rtnamt)
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("최종환불금액")]),
                            !_vm.isPartner
                              ? _c("td", { staticClass: "right" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.claimInfo.finadjustamt,
                                        expression: "claimInfo.finadjustamt",
                                      },
                                    ],
                                    staticClass: "right",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "text",
                                      disabled:
                                        _vm.claimInfo.orgrtnstatus !==
                                        _vm.$store.getters["ADMIN"]
                                          .RETURN_COMPLETE_DELIVERY,
                                      maxlength: "11",
                                      oninput:
                                        "this.value = this.value.replace(/(^0[0-9]|[^0-9])/gi, '');",
                                    },
                                    domProps: {
                                      value: _vm.claimInfo.finadjustamt,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.claimInfo,
                                          "finadjustamt",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ])
                              : _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.claimInfo.finadjustamt
                                      )
                                    )
                                  ),
                                ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("조정금액(-)")]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      (Number(_vm.claimInfo.rtnamt) -
                                        Number(_vm.claimInfo.finadjustamt)) *
                                        -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("조정사유")]),
                            !_vm.isPartner
                              ? _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.claimInfo.adjustreason,
                                        expression: "claimInfo.adjustreason",
                                      },
                                    ],
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "text",
                                      placeholder:
                                        "조정금액 저장시 사유는 필수 입력사항입니다.",
                                      maxlength: "200",
                                      disabled:
                                        _vm.claimInfo.orgrtnstatus !==
                                        _vm.$store.getters["ADMIN"]
                                          .RETURN_COMPLETE_DELIVERY,
                                    },
                                    domProps: {
                                      value: _vm.claimInfo.adjustreason,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.claimInfo,
                                          "adjustreason",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ])
                              : _c("td", [
                                  _vm._v(_vm._s(_vm.claimInfo.adjustreason)),
                                ]),
                          ]),
                        ]),
                      ]
                    ),
                    _vm.clmInfo.bforder.paywaytype ===
                    _vm.$store.getters["ADMIN"].PAY_VIRTURE_ACCOUNT
                      ? [
                          _c("div", { staticClass: "bar-title small" }, [
                            _vm._v("환불계좌 정보"),
                          ]),
                          _c(
                            "table",
                            {
                              staticClass: "gray-tb lower",
                              attrs: { cellpadding: "0", cellspacing: "0" },
                            },
                            [
                              _vm._m(16),
                              _c("tbody", [
                                _c("tr", [
                                  _c("th", [_vm._v("예금주명")]),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.claimInfo.refcusname)),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [_vm._v("환불계좌")]),
                                  _c("td", [
                                    _vm._v(
                                      "[" +
                                        _vm._s(_vm.claimInfo.refbanknm) +
                                        "] " +
                                        _vm._s(_vm.claimInfo.refaccnumber)
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _c("div", { staticClass: "bar-title small" }, [
                      _vm._v("최종환불"),
                    ]),
                    _c(
                      "table",
                      {
                        staticClass: "gray-tb lower",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _vm._m(17),
                        _c("tbody", [
                          _c("tr", [
                            _c("th", [_vm._v("금액(PG)")]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.clmInfo.bforder.paywaytypename)
                              ),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _c("strong", { staticClass: "large-txt" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$util.maskComma(_vm.claimInfo.rtnpayamt)
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          !_vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("임직원적립금 반환")]),
                                _c("td"),
                                _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.claimInfo.rtnempresamt
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          !_vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("적립금 반환")]),
                                _c("td"),
                                _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.claimInfo.rtnresamt
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          !_vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("D포인트 반환")]),
                                _c("td"),
                                _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.claimInfo.rtnepoamt
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.isPartner
                            ? _c("tr", [
                                _c("th", [_vm._v("적립금 반환")]),
                                _c("td"),
                                _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$util.maskComma(
                                          Number(_vm.claimInfo.rtnempresamt) +
                                            Number(_vm.claimInfo.rtnresamt) +
                                            Number(_vm.claimInfo.rtnepoamt)
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
              (_vm.claimInfo.orgrtnstatus !==
                _vm.$store.getters["ADMIN"].RETURN_REQUEST_REFUSE &&
                _vm.claimInfo.orgrtnstatus !==
                  _vm.$store.getters["ADMIN"].RETURN_APPROVAL_REFUSE &&
                _vm.claimInfo.rtnstatus ===
                  _vm.$store.getters["ADMIN"].RETURN_APPROVAL_REFUSE) ||
              _vm.claimInfo.rtnstatus ===
                _vm.$store.getters["ADMIN"].RETURN_CANCEL ||
              _vm.claimInfo.rtnstatus ===
                _vm.$store.getters["ADMIN"].RETURN_REQUEST_REFUSE ||
              _vm.claimInfo.rtnstatus ===
                _vm.$store.getters["ADMIN"].RETURN_REQUEST_REFUSE ||
              !_vm.$util.isNull(_vm.claimInfo.rejsubreason) ||
              (_vm.claimInfo.orgrtnstatus ===
                _vm.$store.getters["ADMIN"].RETURN_REQUEST_REFUSE &&
                _vm.claimInfo.rtnstatus ===
                  _vm.$store.getters["ADMIN"].RETURN_APPROVAL_COMPLETE) ||
              _vm.claimInfo.rtnstatus ===
                _vm.$store.getters["ADMIN"].RETURN_A_RETURN ||
              !_vm.$util.isNull(_vm.claimInfo.rtnlogistype) ||
              !_vm.$util.isNull(_vm.claimInfo.rejreason) ||
              !_vm.$util.isNull(_vm.claimInfo.dropreason) ||
              !_vm.$util.isNull(_vm.claimInfo.rejsubreason) ||
              !_vm.$util.isNull(_vm.claimInfo.rejdenyreason)
                ? _c(
                    "table",
                    {
                      staticClass: "gray-tb",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _vm._m(18),
                      _c(
                        "tbody",
                        [
                          (_vm.claimInfo.orgrtnstatus !==
                            _vm.$store.getters["ADMIN"].RETURN_REQUEST_REFUSE &&
                            _vm.claimInfo.orgrtnstatus !==
                              _vm.$store.getters["ADMIN"]
                                .RETURN_APPROVAL_REFUSE &&
                            _vm.claimInfo.rtnstatus ===
                              _vm.$store.getters["ADMIN"]
                                .RETURN_APPROVAL_REFUSE) ||
                          (_vm.claimInfo.orgrtnstatus ===
                            _vm.$store.getters["ADMIN"]
                              .RETURN_APPROVAL_REFUSE &&
                            _vm.$util.isNull(_vm.claimInfo.rejsubreason) &&
                            !_vm.$util.isNull(_vm.claimInfo.rejreason))
                            ? _c("tr", [
                                _vm._m(19),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.claimInfo.rejreason,
                                        expression: "claimInfo.rejreason",
                                      },
                                    ],
                                    style: _vm.isEditReason
                                      ? "width: calc(100% - 57px);"
                                      : "width: 100%;",
                                    attrs: {
                                      type: "text",
                                      placeholder: "반려사유 입력 필수",
                                      maxlength: "200",
                                    },
                                    domProps: {
                                      value: _vm.claimInfo.rejreason,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.claimInfo,
                                          "rejreason",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm.isEditReason
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn blue ml3",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveClaim(
                                                _vm.$store.getters["ADMIN"]
                                                  .RETURN_APPROVAL_REFUSE,
                                                "save"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 저장 ")]
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.claimInfo.rtnstatus ===
                            _vm.$store.getters["ADMIN"].RETURN_CANCEL ||
                          !_vm.$util.isNull(_vm.claimInfo.dropreason)
                            ? _c("tr", [
                                _vm._m(20),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.claimInfo.dropreason,
                                        expression: "claimInfo.dropreason",
                                      },
                                    ],
                                    style: _vm.isEditReason
                                      ? "width: calc(100% - 57px);"
                                      : "width: 100%;",
                                    attrs: {
                                      type: "text",
                                      placeholder: "철회사유 입력 필수",
                                      maxlength: "200",
                                    },
                                    domProps: {
                                      value: _vm.claimInfo.dropreason,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.claimInfo,
                                          "dropreason",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm.isEditReason
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn blue ml3",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveClaim(
                                                _vm.$store.getters["ADMIN"]
                                                  .RETURN_CANCEL,
                                                "save"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 저장 ")]
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.claimInfo.rtnstatus ===
                            _vm.$store.getters["ADMIN"].RETURN_REQUEST_REFUSE ||
                          !_vm.$util.isNull(_vm.claimInfo.rejsubreason)
                            ? _c("tr", [
                                _vm._m(21),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.claimInfo.rejsubreason,
                                        expression: "claimInfo.rejsubreason",
                                      },
                                    ],
                                    style:
                                      _vm.isPartner && _vm.isEditReason
                                        ? "width: calc(100% - 57px);"
                                        : "width: 100%;",
                                    attrs: {
                                      type: "text",
                                      placeholder: "반려승인요청사유 입력 필수",
                                      maxlength: "200",
                                    },
                                    domProps: {
                                      value: _vm.claimInfo.rejsubreason,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.claimInfo,
                                          "rejsubreason",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm.isPartner && _vm.isEditReason
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn blue ml3",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveClaim(
                                                _vm.$store.getters["ADMIN"]
                                                  .RETURN_REQUEST_REFUSE,
                                                "save"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 저장 ")]
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                          (_vm.claimInfo.orgrtnstatus ===
                            _vm.$store.getters["ADMIN"].RETURN_REQUEST_REFUSE &&
                            _vm.claimInfo.rtnstatus ===
                              _vm.$store.getters["ADMIN"]
                                .RETURN_APPROVAL_REFUSE) ||
                          (_vm.claimInfo.orgrtnstatus ===
                            _vm.$store.getters["ADMIN"]
                              .RETURN_APPROVAL_REFUSE &&
                            !_vm.$util.isNull(_vm.claimInfo.rejsubreason) &&
                            !_vm.$util.isNull(_vm.claimInfo.rejreason))
                            ? _c("tr", [
                                _vm._m(22),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.claimInfo.rejreason,
                                        expression: "claimInfo.rejreason",
                                      },
                                    ],
                                    style: _vm.isEditReason
                                      ? "width: calc(100% - 57px);"
                                      : "width: 100%;",
                                    attrs: {
                                      type: "text",
                                      placeholder: "반려승인사유 입력 필수",
                                      maxlength: "200",
                                    },
                                    domProps: {
                                      value: _vm.claimInfo.rejreason,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.claimInfo,
                                          "rejreason",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm.isEditReason
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn blue ml3",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveClaim(
                                                _vm.$store.getters["ADMIN"]
                                                  .RETURN_APPROVAL_REFUSE,
                                                "save"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 저장 ")]
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                          (_vm.claimInfo.orgrtnstatus ===
                            _vm.$store.getters["ADMIN"].RETURN_REQUEST_REFUSE &&
                            _vm.claimInfo.rtnstatus ===
                              _vm.$store.getters["ADMIN"]
                                .RETURN_APPROVAL_COMPLETE) ||
                          !_vm.$util.isNull(_vm.claimInfo.rejdenyreason)
                            ? _c("tr", [
                                _vm._m(23),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.claimInfo.rejdenyreason,
                                        expression: "claimInfo.rejdenyreason",
                                      },
                                    ],
                                    style:
                                      _vm.isEditReason &&
                                      _vm.claimInfo.rtnstatus !==
                                        _vm.$store.getters["ADMIN"]
                                          .RETURN_A_RETURN
                                        ? "width: calc(100% - 57px);"
                                        : "width: 100%;",
                                    attrs: {
                                      type: "text",
                                      placeholder: "반려승인거부사유 입력 필수",
                                      maxlength: "200",
                                    },
                                    domProps: {
                                      value: _vm.claimInfo.rejdenyreason,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.claimInfo,
                                          "rejdenyreason",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm.isEditReason &&
                                  _vm.claimInfo.rtnstatus !==
                                    _vm.$store.getters["ADMIN"].RETURN_A_RETURN
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn blue ml3",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveClaim(
                                                _vm.$store.getters["ADMIN"]
                                                  .RETURN_APPROVAL_COMPLETE,
                                                "save"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 저장 ")]
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.claimInfo.rtnstatus ===
                            _vm.$store.getters["ADMIN"].RETURN_A_RETURN ||
                          !_vm.$util.isNull(_vm.claimInfo.rtnlogistype)
                            ? [
                                _c("tr", [
                                  _vm._m(24),
                                  _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.claimInfo.rtnreason,
                                          expression: "claimInfo.rtnreason",
                                        },
                                      ],
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "text",
                                        placeholder: "반송 사유 입력 필수",
                                        maxlength: "200",
                                      },
                                      domProps: {
                                        value: _vm.claimInfo.rtnreason,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.claimInfo,
                                            "rtnreason",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c("tr", [
                                  _vm._m(25),
                                  _c("td", [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.claimInfo.rtnlogistype,
                                            expression:
                                              "claimInfo.rtnlogistype",
                                          },
                                        ],
                                        staticStyle: { width: "160px" },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.claimInfo,
                                              "rtnlogistype",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          { domProps: { value: null } },
                                          [_vm._v("선택")]
                                        ),
                                        _vm._l(
                                          _vm.commonCode.logistype,
                                          function (row) {
                                            return _c(
                                              "option",
                                              {
                                                key: row.cmcode,
                                                domProps: { value: row.cmcode },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(row.codename) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.claimInfo.rtninvoiceno,
                                          expression: "claimInfo.rtninvoiceno",
                                        },
                                      ],
                                      staticClass: "ml3",
                                      attrs: {
                                        type: "text",
                                        placeholder: "송장번호",
                                        maxlength: "50",
                                        oninput:
                                          "this.value = this.value.replace(/(^0|[^0-9])/gi, '');",
                                      },
                                      domProps: {
                                        value: _vm.claimInfo.rtninvoiceno,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.claimInfo,
                                            "rtninvoiceno",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm.isEditReason
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn blue ml3",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.saveClaim(
                                                  _vm.$store.getters["ADMIN"]
                                                    .RETURN_A_RETURN,
                                                  "save"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 저장 ")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.isPartner
                ? _c("div", { staticClass: "btn-group" }, [
                    _vm.claimInfo.orgrtnstatus ===
                      _vm.$store.getters["ADMIN"].RETURN_WAITING_APPLY ||
                    _vm.claimInfo.orgrtnstatus ===
                      _vm.$store.getters["ADMIN"].RETURN_COMPLEATE_PAYMENT
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big blue",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.saveClaim(
                                  _vm.$store.getters["ADMIN"]
                                    .RETURN_APPROVAL_COMPLETE,
                                  "proc"
                                )
                              },
                            },
                          },
                          [_vm._v(" 반품승인 ")]
                        )
                      : _vm._e(),
                    _vm.claimInfo.orgrtnstatus ===
                      _vm.$store.getters["ADMIN"].RETURN_WAITING_APPLY ||
                    _vm.claimInfo.orgrtnstatus ===
                      _vm.$store.getters["ADMIN"].RETURN_COMPLEATE_PAYMENT
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big red",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.changeClaimStatus(
                                  _vm.$store.getters["ADMIN"]
                                    .RETURN_APPROVAL_REFUSE
                                )
                              },
                            },
                          },
                          [_vm._v(" 반품반려 ")]
                        )
                      : _vm._e(),
                    _vm.claimInfo.orgrtnstatus ===
                    _vm.$store.getters["ADMIN"].RETURN_WAITING_DEPOSIT
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big red",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.changeClaimStatus(
                                  _vm.$store.getters["ADMIN"]
                                    .RETURN_APPROVAL_REFUSE
                                )
                              },
                            },
                          },
                          [_vm._v(" 반품반려 ")]
                        )
                      : _vm._e(),
                    _vm.claimInfo.orgrtnstatus ===
                    _vm.$store.getters["ADMIN"].RETURN_REQUEST_REFUSE
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big blue",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.changeClaimStatus(
                                  _vm.$store.getters["ADMIN"]
                                    .RETURN_APPROVAL_REFUSE
                                )
                              },
                            },
                          },
                          [_vm._v(" 반품반려승인 ")]
                        )
                      : _vm._e(),
                    _vm.claimInfo.orgrtnstatus ===
                    _vm.$store.getters["ADMIN"].RETURN_REQUEST_REFUSE
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big red",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.changeClaimStatus(
                                  _vm.$store.getters["ADMIN"]
                                    .RETURN_APPROVAL_COMPLETE
                                )
                              },
                            },
                          },
                          [_vm._v(" 반품반려승인거부 ")]
                        )
                      : _vm._e(),
                    _vm.claimInfo.ispbgoods === "F" &&
                    _vm.claimInfo.isrectracking === "F" &&
                    _vm.claimInfo.rtndelivtype != "DLT004" &&
                    _vm.claimInfo.orgrtnstatus ===
                      _vm.$store.getters["ADMIN"].RETURN_APPROVAL_COMPLETE
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big blue",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.procRtnDeliv("rec")
                              },
                            },
                          },
                          [_vm._v(" 회수진행 ")]
                        )
                      : _vm._e(),
                    _vm.claimInfo.ispbgoods === "F" &&
                    _vm.claimInfo.orgrtnstatus ===
                      _vm.$store.getters["ADMIN"].RETURN_IN_DELIVERY
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big blue",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.saveClaim(
                                  _vm.$store.getters["ADMIN"]
                                    .RETURN_COMPLETE_DELIVERY,
                                  "proc"
                                )
                              },
                            },
                          },
                          [_vm._v(" 회수완료 ")]
                        )
                      : _vm._e(),
                    !_vm.isPartner &&
                    _vm.claimInfo.orgrtnstatus ===
                      _vm.$store.getters["ADMIN"].RETURN_COMPLETE_DELIVERY
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big blue-line",
                            attrs: { type: "button" },
                            on: { click: _vm.saveFinAdjustamt },
                          },
                          [_vm._v(" 조정금액저장 ")]
                        )
                      : _vm._e(),
                    _vm.claimInfo.orgrtnstatus ===
                    _vm.$store.getters["ADMIN"].RETURN_COMPLETE_DELIVERY
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big blue",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.saveClaim(
                                  _vm.$store.getters["ADMIN"].RETURN_COMPLETE,
                                  "proc"
                                )
                              },
                            },
                          },
                          [_vm._v(" 처리완료 ")]
                        )
                      : _vm._e(),
                    _vm.claimInfo.orgrtnstatus ===
                    _vm.$store.getters["ADMIN"].RETURN_COMPLETE_DELIVERY
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big red",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.changeClaimStatus(
                                  _vm.$store.getters["ADMIN"].RETURN_A_RETURN
                                )
                              },
                            },
                          },
                          [_vm._v(" 반송처리 ")]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn big darkgray",
                        attrs: { type: "button" },
                        on: { click: _vm.onClose },
                      },
                      [_vm._v("닫기")]
                    ),
                  ])
                : _c("div", { staticClass: "btn-group" }, [
                    _vm.claimInfo.orgrtnstatus ===
                      _vm.$store.getters["ADMIN"].RETURN_WAITING_APPLY ||
                    _vm.claimInfo.orgrtnstatus ===
                      _vm.$store.getters["ADMIN"].RETURN_COMPLEATE_PAYMENT
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big blue",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.saveClaim(
                                  _vm.$store.getters["ADMIN"]
                                    .RETURN_APPROVAL_COMPLETE,
                                  "proc"
                                )
                              },
                            },
                          },
                          [_vm._v(" 반품승인 ")]
                        )
                      : _vm._e(),
                    _vm.claimInfo.orgrtnstatus ===
                      _vm.$store.getters["ADMIN"].RETURN_WAITING_APPLY ||
                    _vm.claimInfo.orgrtnstatus ===
                      _vm.$store.getters["ADMIN"].RETURN_COMPLEATE_PAYMENT
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big red",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.changeClaimStatus(
                                  _vm.$store.getters["ADMIN"]
                                    .RETURN_REQUEST_REFUSE
                                )
                              },
                            },
                          },
                          [_vm._v(" 반품반려승인요청 ")]
                        )
                      : _vm._e(),
                    _vm.claimInfo.orgrtnstatus ===
                    _vm.$store.getters["ADMIN"].RETURN_WAITING_DEPOSIT
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big red",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.changeClaimStatus(
                                  _vm.$store.getters["ADMIN"]
                                    .RETURN_REQUEST_REFUSE
                                )
                              },
                            },
                          },
                          [_vm._v(" 반품반려승인요청 ")]
                        )
                      : _vm._e(),
                    _vm.claimInfo.isrectracking === "F" &&
                    _vm.claimInfo.rtndelivtype != "DLT004" &&
                    _vm.claimInfo.orgrtnstatus ===
                      _vm.$store.getters["ADMIN"].RETURN_APPROVAL_COMPLETE
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big blue",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.procRtnDeliv("rec")
                              },
                            },
                          },
                          [_vm._v(" 회수진행 ")]
                        )
                      : _vm._e(),
                    _vm.claimInfo.orgrtnstatus ===
                    _vm.$store.getters["ADMIN"].RETURN_IN_DELIVERY
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big blue",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.saveClaim(
                                  _vm.$store.getters["ADMIN"]
                                    .RETURN_COMPLETE_DELIVERY,
                                  "proc"
                                )
                              },
                            },
                          },
                          [_vm._v(" 회수완료 ")]
                        )
                      : _vm._e(),
                    _vm.claimInfo.orgrtnstatus ===
                    _vm.$store.getters["ADMIN"].RETURN_COMPLETE_DELIVERY
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big blue",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.saveClaim(
                                  _vm.$store.getters["ADMIN"].RETURN_COMPLETE,
                                  "proc"
                                )
                              },
                            },
                          },
                          [_vm._v(" 처리완료 ")]
                        )
                      : _vm._e(),
                    _vm.claimInfo.orgrtnstatus ===
                    _vm.$store.getters["ADMIN"].RETURN_COMPLETE_DELIVERY
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big red",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.changeClaimStatus(
                                  _vm.$store.getters["ADMIN"].RETURN_A_RETURN
                                )
                              },
                            },
                          },
                          [_vm._v(" 반송처리 ")]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn big darkgray",
                        attrs: { type: "button" },
                        on: { click: _vm.onClose },
                      },
                      [_vm._v("닫기")]
                    ),
                  ]),
            ],
            2
          ),
        ]
      ),
      _vm.isShowMemDetail
        ? _c("AdminMemberInfo", {
            attrs: { activeUserNo: _vm.activeUserNo },
            on: { closeDetail: _vm.closeMemDetail },
          })
        : _vm._e(),
      _vm.isGoodsDetailShow
        ? _c("GoodsDetail", {
            attrs: { activeGoodsNo: _vm.activeGoodsNo },
            on: { closePopup: _vm.closeGoodsDetail },
          })
        : _vm._e(),
      _vm.isShowAddGift
        ? _c("AddReapplyGiftPopup", {
            attrs: { reapplyGiftList: _vm.reapplyGiftList },
            on: { closePopup: _vm.closeAddGiftPopup },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix mt20" }, [
      _c("div", { staticClass: "bar-title small fl" }, [_vm._v("반품상품")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("반품사유"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "35%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("사은품코드")]),
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("사은품명")]),
        _c("th", [_vm._v("수량")]),
        _c("th", [_vm._v("적용프로모션 명")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "35%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("사은품코드")]),
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("사은품명")]),
        _c("th", [_vm._v("수량")]),
        _c("th", [_vm._v("적용프로모션 명")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "5" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "230px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "230px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("반려사유"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("철회사유"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("반려승인요청사유"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("반려승인사유"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("반려승인거부사유"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("반송처리사유"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("반송 택배사"),
      _c("i", { staticClass: "essential" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }