var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "common_text_input_wrap" }, [
    _c("input", {
      attrs: { type: _vm.type, maxlength: _vm.maxlength },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
      },
    }),
    _c("div", { staticClass: "common_text_input_counter" }, [
      _c("strong", [_vm._v(_vm._s(_vm.getCurrentTextCount))]),
      _vm._v(" / "),
      _c("span", [_vm._v(_vm._s(_vm.maxlength))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }