var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _vm.isPictureViewPopupShow
        ? _c("PictureViewPopup", {
            ref: "picturePopup",
            attrs: { "img-path": _vm.imgPath },
            on: { close: _vm.closeUploadFilePopup },
          })
        : _vm._e(),
      _vm.isVideoViewPopupShow
        ? _c("VideoView", {
            ref: "videoPopup",
            attrs: { videoInfo: _vm.videoInfo },
            on: { close: _vm.closeVideoPopup },
          })
        : _vm._e(),
      _vm.isShowOrderDetail
        ? _c("OrderDetail", {
            attrs: { activeOrderCode: _vm.activeOrderCode },
            on: { closeDetail: _vm.closeOrderDetail },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "modal-content",
          style:
            _vm.detailShow || _vm.isGoodsDetailShow
              ? "width: 1600px;"
              : "width: 1300px;",
        },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("리뷰 상세")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c(
            "div",
            { staticClass: "pop-body" },
            [
              _vm.isAdmin
                ? _c("div", { staticClass: "gray-box mg0 clearfix" }, [
                    _c("div", { staticClass: "fl" }, [
                      _vm.info.isbest === "T"
                        ? _c("span", [_vm._v("베스트")])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "fr txt-gray" }, [
                      _c("span", [
                        _vm._v("등록일 : " + _vm._s(_vm.info.regdate)),
                      ]),
                      _c("span", { staticClass: "left-bar" }, [
                        _vm._v("수정일 : " + _vm._s(_vm.info.moddate)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.isAdmin
                ? _c("div", { staticClass: "bar-title mt10" }, [
                    _vm._v("작성자 정보"),
                  ])
                : _vm._e(),
              _vm.isAdmin
                ? _c("div", { staticClass: "form-area" }, [
                    _c(
                      "table",
                      {
                        staticClass: "gray-tb",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _vm._m(0),
                        _c("tbody", [
                          _c("tr", [
                            _c("th", [_vm._v("아이디")]),
                            _c("td", [_vm._v(_vm._s(_vm.info.reguserid))]),
                            _c("th", [_vm._v("이름")]),
                            _c("td", [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goDetail(_vm.info.userno)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.info.regusername))]
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("회원유형")]),
                            _c("td", [_vm._v(_vm._s(_vm.info.dadamembertype))]),
                            _c("th", [_vm._v("회원등급")]),
                            _c("td", [_vm._v(_vm._s(_vm.info.memlvtype))]),
                          ]),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "bar-title" }, [_vm._v("상품 정보")]),
              _c("div", { staticClass: "form-area" }, [
                _c(
                  "table",
                  {
                    staticClass: "gray-tb",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _vm._m(1),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v("판매구분")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.goodsInfo.dealername === ""
                                  ? _vm.goodsInfo.ispbgoodsname
                                  : _vm.goodsInfo.ispbgoodsname +
                                      "(" +
                                      _vm.goodsInfo.dealername +
                                      ")"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("카테고리")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.goodsInfo.fullcategoryname)),
                        ]),
                        _c("th", [_vm._v("브랜드")]),
                        _c("td", [_vm._v(_vm._s(_vm.goodsInfo.brandname))]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("상품코드/상품명")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              on: {
                                click: function ($event) {
                                  return _vm.goGoodsDetail(
                                    _vm.goodsInfo.goodsno
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.goodsInfo.goodscode))]
                          ),
                          _vm._v(" / " + _vm._s(_vm.goodsInfo.goodsname) + " "),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("대표이미지")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _c("div", { staticClass: "img-thumb size200" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.imgPreview["goodsimgfile"],
                                alt: "대표이미지",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("상품요약설명")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v(_vm._s(_vm.goodsInfo.summary)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("적용채널")]),
                        _c("td", [_vm._v(_vm._s(_vm.goodsInfo.muappchtype))]),
                        _c("th", [_vm._v("담당MD")]),
                        _c("td", [
                          _vm._v(
                            "[" +
                              _vm._s(_vm.goodsInfo.mdcode) +
                              "] " +
                              _vm._s(_vm.goodsInfo.mdname)
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("판매상태")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.goodsInfo.goodsselltypename)),
                        ]),
                        _c("th", [_vm._v("상품상태")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.goodsInfo.goodsdivtypename)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("전시기간")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v(
                            _vm._s(_vm.goodsInfo.disdate) +
                              " (" +
                              _vm._s(_vm.goodsInfo.disdaterange) +
                              ")"
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "bar-title" }, [_vm._v("주문 정보")]),
              _c("div", { staticClass: "form-area" }, [
                _c(
                  "table",
                  {
                    staticClass: "gray-tb",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _vm._m(2),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v("주문번호")]),
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              on: {
                                click: function ($event) {
                                  return _vm.goOrderDetail(_vm.orderInfo.ordno)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.orderInfo.ordno))]
                          ),
                        ]),
                        _c("th", [_vm._v("구매일자")]),
                        _c("td", [_vm._v(_vm._s(_vm.orderInfo.paymentdate))]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("결제금액")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.orderInfo.totprice) + "원"),
                        ]),
                        _c("th", [_vm._v("결제수량")]),
                        _c("td", [_vm._v(_vm._s(_vm.orderInfo.ordcnt))]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("옵션")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v(_vm._s(_vm.orderInfo.optionconts)),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "bar-title" }, [_vm._v("리뷰 정보")]),
              _c("div", { staticClass: "form-area" }, [
                _c(
                  "table",
                  {
                    staticClass: "gray-tb",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _vm._m(3),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v("리뷰구분")]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.files.reviewimgfile.length > 0 ||
                                  !_vm.$util.isNull(_vm.files.reviewvideofile)
                                  ? "사진&동영상 리뷰"
                                  : "텍스트 리뷰"
                              ) +
                              " "
                          ),
                        ]),
                        _c("th", [_vm._v("좋아요 수")]),
                        _c("td", [_vm._v(_vm._s(_vm.info.goodcnt))]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("평점")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v(_vm._s(_vm.info.totpoint)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("내용")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v(_vm._s(_vm.info.content)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("사진")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _c(
                            "div",
                            [
                              _vm.files.reviewimgfile.length === 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "img-with-text",
                                      staticStyle: { width: "202px" },
                                    },
                                    [
                                      _c("div", {
                                        staticClass:
                                          "img-thumb size200 no-image",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._l(
                                _vm.files.reviewimgfile.length > 5
                                  ? 5
                                  : _vm.files.reviewimgfile.length,
                                function (row, n) {
                                  return _c(
                                    "div",
                                    {
                                      key: row,
                                      staticClass: "img-with-text",
                                      staticStyle: { width: "202px" },
                                    },
                                    [
                                      _vm.$util.isNull(
                                        _vm.files["reviewimgfile"][n]
                                      )
                                        ? _c("div", {
                                            staticClass:
                                              "img-thumb size200 no-image",
                                          })
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "img-thumb size200",
                                            },
                                            [
                                              !_vm.$util.isNull(
                                                _vm.files["reviewimgfile"][n]
                                              )
                                                ? _c("img", {
                                                    attrs: {
                                                      src: _vm.imgPreview[
                                                        "reviewimgfile"
                                                      ][n],
                                                      alt: "리뷰이미지" + n,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showPicture(
                                                          n
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.imgPreview[
                                              "reviewimgfile"
                                            ][n],
                                            download: "",
                                          },
                                        },
                                        [
                                          !_vm.$util.isNull(
                                            _vm.files["reviewimgfile"][n]
                                          )
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn blue-line mt10",
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: { type: "button" },
                                                },
                                                [_vm._v(" 다운로드 ")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "mt20" },
                            _vm._l(
                              _vm.files.reviewimgfile.length < 10
                                ? _vm.files.reviewimgfile.length >= 5
                                  ? _vm.files.reviewimgfile.length % 5
                                  : 0
                                : 5,
                              function (row, n) {
                                return _c(
                                  "div",
                                  {
                                    key: row + 5,
                                    staticClass: "img-with-text",
                                    staticStyle: { width: "202px" },
                                  },
                                  [
                                    _vm.$util.isNull(
                                      _vm.files["reviewimgfile"][n + 5]
                                    )
                                      ? _c("div", {
                                          staticClass:
                                            "img-thumb size200 no-image",
                                        })
                                      : _c(
                                          "div",
                                          { staticClass: "img-thumb size200" },
                                          [
                                            !_vm.$util.isNull(
                                              _vm.files["reviewimgfile"][n + 5]
                                            )
                                              ? _c("img", {
                                                  attrs: {
                                                    src: _vm.imgPreview[
                                                      "reviewimgfile"
                                                    ][n + 5],
                                                    alt: "리뷰이미지" + n,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showPicture(
                                                        n + 5
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.imgPreview["reviewimgfile"][
                                            n + 5
                                          ],
                                          download: "",
                                        },
                                      },
                                      [
                                        !_vm.$util.isNull(
                                          _vm.files["reviewimgfile"][n + 5]
                                        )
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn blue-line mt10",
                                                staticStyle: { width: "100%" },
                                                attrs: { type: "button" },
                                              },
                                              [_vm._v(" 다운로드 ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("동영상")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "img-with-text",
                              staticStyle: { width: "202px" },
                            },
                            [
                              _vm.$util.isNull(_vm.files.reviewvideofile)
                                ? _c("div", {
                                    staticClass: "img-thumb size200 no-image",
                                  })
                                : _c(
                                    "div",
                                    { staticClass: "img-thumb size200" },
                                    [
                                      !_vm.$util.isNull(
                                        _vm.files.reviewvideofile
                                      )
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.imgPreview
                                                .reviewvideofile,
                                              alt: "리뷰동영상",
                                            },
                                            on: { click: _vm.showVideo },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm.isAdmin
                        ? _c("tr", [
                            _c("th", [_vm._v("신고여부")]),
                            _c("td", [_vm._v(_vm._s(_vm.info.isnoti))]),
                            _c("th", [_vm._v("미노출")]),
                            _c("td", [
                              _c("div", { staticClass: "radio_wrap" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.isdisplay,
                                      expression: "info.isdisplay",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "group01blind",
                                    id: "group11blind",
                                    value: "F",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.info.isdisplay, "F"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.info,
                                        "isdisplay",
                                        "F"
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "group11blind" } },
                                  [_vm._v("지정")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.isdisplay,
                                      expression: "info.isdisplay",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "group01blind",
                                    id: "group12blind",
                                    value: "T",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.info.isdisplay, "T"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.info,
                                        "isdisplay",
                                        "T"
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "group12blind" } },
                                  [_vm._v("해지")]
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]),
              _vm.isAdmin
                ? _c("div", { staticClass: "bar-title" }, [
                    _vm._v("베스트 설정"),
                  ])
                : _vm._e(),
              _vm.isAdmin
                ? _c("div", { staticClass: "form-area" }, [
                    _c(
                      "table",
                      {
                        staticClass: "gray-tb",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _vm._m(4),
                        _c("tbody", [
                          _c("tr", [
                            _c("th", [_vm._v("베스트")]),
                            _c("td", { attrs: { colspan: "3" } }, [
                              _c("div", { staticClass: "radio_wrap" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.isbest,
                                      expression: "info.isbest",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "group00best",
                                    id: "group01best",
                                    value: "T",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.info.isbest, "T"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(_vm.info, "isbest", "T")
                                    },
                                  },
                                }),
                                _c("label", { attrs: { for: "group01best" } }, [
                                  _vm._v("지정"),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.isbest,
                                      expression: "info.isbest",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "group00best",
                                    id: "group02best",
                                    value: "F",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.info.isbest, "F"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(_vm.info, "isbest", "F")
                                    },
                                  },
                                }),
                                _c("label", { attrs: { for: "group02best" } }, [
                                  _vm._v("해지"),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tr",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.info.isbest === "T",
                                  expression: "info.isbest === 'T'",
                                },
                              ],
                            },
                            [
                              _c("th", [_vm._v("전시기간")]),
                              _c(
                                "td",
                                { attrs: { colspan: "3" } },
                                [
                                  _c("CommonDatePickerFromTo", {
                                    attrs: {
                                      fromYYYYMMDD: _vm.checkObj.startYYYYMMDD,
                                      fromHH: _vm.checkObj.startHH,
                                      fromMM: _vm.checkObj.startMi,
                                      toYYYYMMDD: _vm.checkObj.endYYYYMMDD,
                                      toHH: _vm.checkObj.endHH,
                                      toMM: _vm.checkObj.endMi,
                                    },
                                    on: { getDate: _vm.changeStartDate },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.isAdmin
                ? _c("div", { staticClass: "bar-title" }, [_vm._v("신고 정보")])
                : _vm._e(),
              _vm.isAdmin
                ? _c(
                    "div",
                    {
                      staticClass: "scroll-y",
                      staticStyle: { "max-height": "300px" },
                    },
                    [
                      _c(
                        "table",
                        {
                          staticClass: "data-tb align-c",
                          attrs: { cellpadding: "0", cellspacing: "0" },
                        },
                        [
                          _vm._m(5),
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("No")]),
                              _c("th", [_vm._v("아이디")]),
                              _c("th", [_vm._v("이름")]),
                              _c("th", [
                                _vm._v(" 유형 "),
                                _vm.isRead
                                  ? _c("button", {
                                      staticClass: "sort",
                                      class: [
                                        {
                                          up:
                                            _vm.sortData.dadamembertype ===
                                            "dadamembertype_asc",
                                        },
                                        {
                                          down:
                                            _vm.sortData.dadamembertype ===
                                            "dadamembertype_desc",
                                        },
                                      ],
                                      attrs: {
                                        type: "button",
                                        value: _vm.sortData.hits,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.sortToggle(
                                            _vm.sortData.dadamembertype
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                              _c("th", [
                                _vm._v(" 등급 "),
                                _vm.isRead
                                  ? _c("button", {
                                      staticClass: "sort",
                                      class: [
                                        {
                                          up:
                                            _vm.sortData.memlvtype ===
                                            "memlvtype_asc",
                                        },
                                        {
                                          down:
                                            _vm.sortData.memlvtype ===
                                            "memlvtype_desc",
                                        },
                                      ],
                                      attrs: {
                                        type: "button",
                                        value: _vm.sortData.memlvtype,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.sortToggle(
                                            _vm.sortData.memlvtype
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                              _c("th", [_vm._v("신고일시")]),
                              _c("th", [_vm._v("사유구분")]),
                              _c("th", [_vm._v("기타사유")]),
                            ]),
                          ]),
                          this.info.notilist.length > 0
                            ? _c(
                                "tbody",
                                _vm._l(
                                  this.info.notilist,
                                  function (row, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [_vm._v(_vm._s(row.no))]),
                                      _c("td", [_vm._v(_vm._s(row.userid))]),
                                      _c("td", [_vm._v(_vm._s(row.name))]),
                                      _c("td", [
                                        _vm._v(_vm._s(row.dadamembertype)),
                                      ]),
                                      _c("td", [_vm._v(_vm._s(row.memlvtype))]),
                                      _c("td", [_vm._v(_vm._s(row.regdate))]),
                                      _c("td", [_vm._v(_vm._s(row.notitype))]),
                                      _c("td", { staticClass: "left" }, [
                                        _vm._v(
                                          _vm._s(
                                            row.etcreason === ""
                                              ? "-"
                                              : row.etcreason
                                          )
                                        ),
                                      ]),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _c("tbody", [_vm._m(6)]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isGoodsDetailShow
                ? _c("GoodsDetail", {
                    attrs: { activeGoodsNo: _vm.activeGoodsNo },
                    on: { closePopup: _vm.closeGoodsDetail },
                  })
                : _vm._e(),
              _vm.detailShow
                ? _c("AdminMemberInfo", {
                    attrs: { activeUserNo: _vm.activeUserNo },
                    on: { closeDetail: _vm.closeDetail },
                  })
                : _vm._e(),
              _c("div", { staticClass: "btn-group" }, [
                _vm.isAdmin
                  ? _c(
                      "button",
                      {
                        staticClass: "btn big blue",
                        attrs: { type: "button" },
                        on: { click: _vm.onSave },
                      },
                      [_vm._v("저장")]
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "btn big darkgray",
                    attrs: { type: "button" },
                    on: { click: _vm.onClose },
                  },
                  [_vm._v("취소")]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "477px" } }),
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "477px" } }),
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "477px" } }),
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "477px" } }),
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "477px" } }),
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "8" } }, [
        _vm._v("신고 목록이 존재하지 않습니다."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }