var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1200px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("주문상품")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.closePopup },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "gray-box mg0 clearfix" }, [
              _c("div", { staticClass: "fl" }, [
                _c("span", [_vm._v("주문번호 : " + _vm._s(_vm.ordno))]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "scroll-y",
                staticStyle: { "max-height": "500px" },
              },
              [
                _c(
                  "table",
                  {
                    staticClass: "data-tb align-c",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _c("colgroup", [
                      !_vm.isPartner
                        ? _c("col", { attrs: { width: "7.5%" } })
                        : _vm._e(),
                      !_vm.isPartner
                        ? _c("col", { attrs: { width: "9%" } })
                        : _vm._e(),
                      _c("col", { attrs: { width: "10%" } }),
                      _c("col", { attrs: { width: "7%" } }),
                      _c("col", { attrs: { width: "7%" } }),
                      _c("col", { attrs: { width: "62px" } }),
                      _c("col", { attrs: { width: "" } }),
                      _c("col", { attrs: { width: "11%" } }),
                      _c("col", { attrs: { width: "7%" } }),
                      _c("col", { attrs: { width: "8%" } }),
                      _c("col", { attrs: { width: "8%" } }),
                    ]),
                    _c("thead", [
                      _c("tr", [
                        !_vm.isPartner
                          ? _c("th", [
                              _vm._v(" 판매구분 "),
                              _c("button", {
                                staticClass: "sort",
                                class: [
                                  {
                                    up:
                                      _vm.sortData.ispbgoods ===
                                      "ispbgoods_asc",
                                  },
                                  {
                                    down:
                                      _vm.sortData.ispbgoods ===
                                      "ispbgoods_desc",
                                  },
                                ],
                                attrs: {
                                  type: "button",
                                  value: _vm.sortData.ispbgoods,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.sortToggle(
                                      _vm.sortData.ispbgoods
                                    )
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("th", [
                              _vm._v(" 파트너사 "),
                              _c("button", {
                                staticClass: "sort",
                                class: [
                                  {
                                    up:
                                      _vm.sortData.dealername ===
                                      "dealername_asc",
                                  },
                                  {
                                    down:
                                      _vm.sortData.dealername ===
                                      "dealername_desc",
                                  },
                                ],
                                attrs: {
                                  type: "button",
                                  value: _vm.sortData.dealername,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.sortToggle(
                                      _vm.sortData.dealername
                                    )
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        _c("th", [
                          _vm._v(" 상품코드 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up: _vm.sortData.goodscode === "goodscode_asc",
                              },
                              {
                                down:
                                  _vm.sortData.goodscode === "goodscode_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.goodscode,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.goodscode)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 단품코드 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up:
                                  _vm.sortData.optioncode === "optioncode_asc",
                              },
                              {
                                down:
                                  _vm.sortData.optioncode === "optioncode_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.optioncode,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.optioncode)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 상품순번 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up: _vm.sortData.goodsturn === "goodsturn_asc",
                              },
                              {
                                down:
                                  _vm.sortData.goodsturn === "goodsturn_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.goodsturn,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.goodsturn)
                              },
                            },
                          }),
                        ]),
                        _c("th", { attrs: { colspan: "2" } }, [
                          _vm._v(" 상품명 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up: _vm.sortData.goodsname === "goodsname_asc",
                              },
                              {
                                down:
                                  _vm.sortData.goodsname === "goodsname_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.goodsname,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.goodsname)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 옵션 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up:
                                  _vm.sortData.optionconts ===
                                  "optionconts_asc",
                              },
                              {
                                down:
                                  _vm.sortData.optionconts ===
                                  "optionconts_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.optionconts,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.optionconts)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 주문수량 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.ordcnt === "ordcnt_asc" },
                              { down: _vm.sortData.ordcnt === "ordcnt_desc" },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.ordcnt,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.ordcnt)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 판매단가 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.price === "price_asc" },
                              { down: _vm.sortData.price === "price_desc" },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.price,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.price)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 판매금액 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.totprice === "totprice_asc" },
                              {
                                down: _vm.sortData.totprice === "totprice_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.totprice,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.totprice)
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _vm.list.length > 0
                      ? _c(
                          "tbody",
                          _vm._l(_vm.list, function (item) {
                            return _c("tr", { key: item.ordgdidx }, [
                              !_vm.isPartner
                                ? _c("td", [_vm._v(_vm._s(item.ispbgoodsname))])
                                : _vm._e(),
                              !_vm.isPartner
                                ? _c("td", [_vm._v(_vm._s(item.dealername))])
                                : _vm._e(),
                              _c("td", [_vm._v(_vm._s(item.goodscode))]),
                              _c("td", [_vm._v(_vm._s(item.optioncode))]),
                              _c("td", [_vm._v(_vm._s(item.goodsturn))]),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "img-thumb size60",
                                    class: {
                                      "no-image": _vm.$util.isNull(
                                        item.fullpath
                                      ),
                                    },
                                  },
                                  [
                                    !_vm.$util.isNull(item.fullpath)
                                      ? _c("img", {
                                          attrs: { src: item.fullpath },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _c("td", { staticClass: "left no-left" }, [
                                _vm._v(_vm._s(item.goodsname)),
                              ]),
                              _c(
                                "td",
                                { staticStyle: { "white-space": "pre-wrap" } },
                                [_vm._v(_vm._s(item.optionconts))]
                              ),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.ordcnt))
                                ),
                              ]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(_vm._s(_vm.$util.maskComma(item.price))),
                              ]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.totprice))
                                ),
                              ]),
                            ])
                          }),
                          0
                        )
                      : _c("tbody", [
                          _c("tr", [
                            _c(
                              "td",
                              { attrs: { colspan: _vm.isPartner ? 9 : 11 } },
                              [_vm._v("조회 결과가 존재하지 않습니다.")]
                            ),
                          ]),
                        ]),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.closePopup },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }