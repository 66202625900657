var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "600px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("첨부 사진")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "slide-area mt20" }, [
              _c(
                "div",
                { staticClass: "slider", staticStyle: { width: "100%" } },
                [
                  _c("ul", [
                    _c("li", [
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: { src: _vm.imgPath },
                      }),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "btn-group mt20" }, [
              _c(
                "button",
                {
                  staticClass: "btn darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }