var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "800px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("클레임 처리이력")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.closePopup },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c(
              "div",
              {
                staticClass: "scroll-y",
                staticStyle: { "max-height": "500px" },
              },
              [
                _c(
                  "table",
                  {
                    staticClass: "data-tb align-c",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _vm._m(0),
                    _vm._m(1),
                    _vm.list.length > 0
                      ? _c(
                          "tbody",
                          _vm._l(_vm.list, function (item, index) {
                            return _c("tr", { key: item.clmstidx }, [
                              _c("td", [
                                _vm._v(_vm._s(_vm.list.length - index)),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.clmprcdate))]),
                              _c("td", [_vm._v(_vm._s(item.clmprcaftstatus))]),
                              _c("td", [_vm._v(_vm._s(item.clmprctypename))]),
                              _c("td", [_vm._v(_vm._s(item.clmprcmanager))]),
                            ])
                          }),
                          0
                        )
                      : _c("tbody", [_vm._m(2)]),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.closePopup },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("No")]),
        _c("th", [_vm._v("처리일시")]),
        _c("th", [_vm._v("처리상태")]),
        _c("th", [_vm._v("처리구분")]),
        _c("th", [_vm._v("처리자")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "5" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }