var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "manager-regist-wrap" }, [
    _c(
      "div",
      { staticStyle: { width: "1200px" } },
      [
        _c("h2", [_vm._v("부운영자 신청")]),
        _c("ManagerRegistForm", {
          attrs: { isNew: true },
          model: {
            value: _vm.managerInfo,
            callback: function ($$v) {
              _vm.managerInfo = $$v
            },
            expression: "managerInfo",
          },
        }),
        _c("div", { staticClass: "btn-group" }, [
          _c(
            "button",
            {
              staticClass: "btn big blue",
              attrs: { type: "button", disabled: !_vm.onValid() },
              on: { click: _vm.onSave },
            },
            [_vm._v(" 저장 ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn big darkgray",
              attrs: { type: "button" },
              on: { click: _vm.onCancel },
            },
            [_vm._v("취소")]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }