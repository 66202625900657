<template>
  <div class="manager-regist-wrap">
    <div style="width: 1200px">
      <h2>부운영자 신청</h2>
      <ManagerRegistForm :isNew="true" v-model="managerInfo" />
      <div class="btn-group">
        <button type="button" class="btn big blue" :disabled="!onValid()" @click="onSave">
          저장
        </button>
        <button type="button" class="btn big darkgray" @click="onCancel">취소</button>
      </div>
    </div>
  </div>
</template>

<script>
import ManagerRegistForm from './ManagerRegistForm.vue';
import { MANAGER_ROLE_TYPE } from '@constants/manager';

export default {
  name: 'manager.regist.page',
  components: {
    ManagerRegistForm,
  },
  data() {
    return {
      partnerId: 0,
      managerInfo: {
        loginId: '',
        name: '',
        mobile: '',
        email: '',
        roles: [],
        agree: false,
        invaildField: [],
      },
    };
  },
  mounted() {
    this.checkJoinKey();
  },
  methods: {
    async onSave() {
      this.createManager(() => {
        alert('부운영자 계정이 생성되었습니다.');
        this.goToLoginPage();
      });
    },
    onCancel() {
      this.goToLoginPage();
    },
    onValid() {
      return this.managerInfo.invaildField.length === 0;
    },
    goToLoginPage() {
      this.$router.push('/login');
    },
    async createManager(successCallback) {
      try {
        if (this.managerInfo.invaildField.length > 0) {
          return alert('필수 정보를 입력해 주세요.');
        }

        const partnerId = this.partnerId;
        const joinKey = this.$route.params.key;
        const { loginId, name, mobile, email, roles } = this.managerInfo;

        if (roles.includes(MANAGER_ROLE_TYPE.세금)) {
          const { data } = await this.$http.post('/v2/manager/validate/essential-role', {
            partnerId,
          });

          if (
            data &&
            !confirm(
              '귀사의 세금계산서 담당자가 다른 계정에 이미 지정되어 있습니다.\n담당자를 변경하시겠습니까?\n("확인" 버튼 클릭 시 세금계산서 담당자 자동 변경)',
            )
          ) {
            return;
          }
        }

        const param = {
          partnerId,
          loginId,
          name,
          mobile: mobile.replaceAll('-', ''),
          email,
          joinKey,
          roleList: roles.map((role) => ({ roleType: role })),
          isloading: true,
        };
        await this.$http.post('/v2/manager', param);
        successCallback();
      } catch (error) {
        this.$util.debug(error);
        alert(error.message || '운영자 정보 생성중 오류가 발생하였습니다.');
      }
    },
    async checkJoinKey() {
      const joinKey = this.$route.params.key;
      if (!joinKey) {
        alert('잘못된 접근입니다.');
        return this.goToLoginPage();
      }

      this.$emit('loading', true);
      try {
        await this.$http.get(`/v2/manager/join-key/${joinKey}`);
        const { data } = await this.$http.get(`/v2/manager/partner-id/${joinKey}`);
        this.partnerId = data.partnerId;
      } catch (error) {
        this.$util.debug(error);
        alert(error.message || '잘못된 접근입니다.');
        this.goToLoginPage();
      } finally {
        this.$emit('loading', false);
      }
    },
  },
};
</script>
<style scoped>
.manager-regist-wrap {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.manager-regist-wrap h2 {
  margin-bottom: 20px;
}
</style>
