var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1400px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("이벤트 등록")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "bar-title" }, [_vm._v("기본정보")]),
            _c("div", { staticClass: "boxing" }, [
              _c("div", { staticClass: "form-area" }, [
                _c("dl", [
                  _c("dt", [_vm._v("사용여부")]),
                  _c("dd", [
                    _c("div", { staticClass: "radio_wrap wide" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.boardInfo.istrash,
                            expression: "boardInfo.istrash",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "group00",
                          id: "group01",
                          value: "F",
                          checked: "",
                        },
                        domProps: {
                          checked: _vm._q(_vm.boardInfo.istrash, "F"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.boardInfo, "istrash", "F")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "group01" } }, [
                        _vm._v("사용함"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.boardInfo.istrash,
                            expression: "boardInfo.istrash",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "group00",
                          id: "group02",
                          value: "T",
                        },
                        domProps: {
                          checked: _vm._q(_vm.boardInfo.istrash, "T"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.boardInfo, "istrash", "T")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "group02" } }, [
                        _vm._v("사용안함"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("dl", [
                  _vm._m(0),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.boardInfo.subject,
                          expression: "boardInfo.subject",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", placeholder: "제목" },
                      domProps: { value: _vm.boardInfo.subject },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.boardInfo,
                            "subject",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("이벤트 설명")]),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.boardInfo.evdesc,
                          expression: "boardInfo.evdesc",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", placeholder: "이벤트 설명" },
                      domProps: { value: _vm.boardInfo.evdesc },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.boardInfo, "evdesc", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "bar-title" }, [_vm._v("조건설정")]),
            _c("div", { staticClass: "form-area" }, [
              _c(
                "table",
                {
                  staticClass: "gray-tb",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(1),
                  _c("tbody", [
                    _c("tr", [
                      _vm._m(2),
                      _c(
                        "td",
                        [
                          _c("CommonDatePickerFromTo", {
                            attrs: {
                              fromYYYYMMDD: _vm.eventTimeInfo.startYYYYMMDD,
                              fromHH: _vm.eventTimeInfo.startHH,
                              fromMM: _vm.eventTimeInfo.startMM,
                              toYYYYMMDD: _vm.eventTimeInfo.toYYYYMMDD,
                              toHH: _vm.eventTimeInfo.toHH,
                              toMM: _vm.eventTimeInfo.toMM,
                            },
                            on: { getDate: _vm.getEventTimeDate },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("tr", [
                      _vm._m(3),
                      _c(
                        "td",
                        [
                          _c("CommonDatePickerFromTo", {
                            attrs: {
                              toYYYYMMDD: _vm.pubTimeInfo.toYYYYMMDD,
                              toHH: _vm.pubTimeInfo.toHH,
                              toMM: _vm.pubTimeInfo.toMM,
                              "use-from": false,
                              "use-to": true,
                            },
                            on: { getDate: _vm.getEventPubTimeDate },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("적용채널")]),
                      _c(
                        "td",
                        [
                          _c("div", { staticClass: "check-wrap" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.checkObj.isallmuappch,
                                  expression: "checkObj.isallmuappch",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "all1",
                                "true-value": "T",
                                "false-value": "F",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.checkObj.isallmuappch
                                )
                                  ? _vm._i(_vm.checkObj.isallmuappch, null) > -1
                                  : _vm._q(_vm.checkObj.isallmuappch, "T"),
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.checkObj.isallmuappch,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? "T" : "F"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.checkObj,
                                            "isallmuappch",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.checkObj,
                                            "isallmuappch",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.checkObj,
                                        "isallmuappch",
                                        $$c
                                      )
                                    }
                                  },
                                  function ($event) {
                                    return _vm.checkAllMuAppch(
                                      $event.target.checked
                                    )
                                  },
                                ],
                              },
                            }),
                            _c("label", { attrs: { for: "all1" } }, [
                              _vm._v("전체"),
                            ]),
                          ]),
                          _vm._l(_vm.pageCode.muappchtype, function (row, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "check-wrap" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checkObj.muappchTypeChecked,
                                      expression: "checkObj.muappchTypeChecked",
                                    },
                                  ],
                                  attrs: { type: "checkbox", id: "group1" + i },
                                  domProps: {
                                    value: row.cmcode,
                                    checked: Array.isArray(
                                      _vm.checkObj.muappchTypeChecked
                                    )
                                      ? _vm._i(
                                          _vm.checkObj.muappchTypeChecked,
                                          row.cmcode
                                        ) > -1
                                      : _vm.checkObj.muappchTypeChecked,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.checkObj.muappchTypeChecked,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = row.cmcode,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.checkObj,
                                              "muappchTypeChecked",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.checkObj,
                                              "muappchTypeChecked",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.checkObj,
                                          "muappchTypeChecked",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c("label", { attrs: { for: "group1" + i } }, [
                                  _vm._v(_vm._s(row.codename)),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("대상회원유형")]),
                      _c(
                        "td",
                        [
                          _c("div", { staticClass: "check-wrap" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.checkObj.isallmumember,
                                  expression: "checkObj.isallmumember",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "all2",
                                "true-value": "T",
                                "false-value": "F",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.checkObj.isallmumember
                                )
                                  ? _vm._i(_vm.checkObj.isallmumember, null) >
                                    -1
                                  : _vm._q(_vm.checkObj.isallmumember, "T"),
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.checkObj.isallmumember,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? "T" : "F"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.checkObj,
                                            "isallmumember",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.checkObj,
                                            "isallmumember",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.checkObj,
                                        "isallmumember",
                                        $$c
                                      )
                                    }
                                  },
                                  function ($event) {
                                    return _vm.checkAllMuMemer(
                                      $event.target.checked
                                    )
                                  },
                                ],
                              },
                            }),
                            _c("label", { attrs: { for: "all2" } }, [
                              _vm._v("전체"),
                            ]),
                          ]),
                          _vm._l(_vm.pageCode.mumembertype, function (row, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "check-wrap" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checkObj.mumemberTypeChecked,
                                      expression:
                                        "checkObj.mumemberTypeChecked",
                                    },
                                  ],
                                  attrs: { type: "checkbox", id: "group2" + i },
                                  domProps: {
                                    value: row.cmcode,
                                    checked: Array.isArray(
                                      _vm.checkObj.mumemberTypeChecked
                                    )
                                      ? _vm._i(
                                          _vm.checkObj.mumemberTypeChecked,
                                          row.cmcode
                                        ) > -1
                                      : _vm.checkObj.mumemberTypeChecked,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.checkObj.mumemberTypeChecked,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = row.cmcode,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.checkObj,
                                              "mumemberTypeChecked",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.checkObj,
                                              "mumemberTypeChecked",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.checkObj,
                                          "mumemberTypeChecked",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c("label", { attrs: { for: "group2" + i } }, [
                                  _vm._v(_vm._s(row.codename)),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("대상회원등급")]),
                      _c(
                        "td",
                        [
                          _c("div", { staticClass: "check-wrap" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.checkObj.isallmumemlv,
                                  expression: "checkObj.isallmumemlv",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "all3",
                                "true-value": "T",
                                "false-value": "F",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.checkObj.isallmumemlv
                                )
                                  ? _vm._i(_vm.checkObj.isallmumemlv, null) > -1
                                  : _vm._q(_vm.checkObj.isallmumemlv, "T"),
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.checkObj.isallmumemlv,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? "T" : "F"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.checkObj,
                                            "isallmumemlv",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.checkObj,
                                            "isallmumemlv",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.checkObj,
                                        "isallmumemlv",
                                        $$c
                                      )
                                    }
                                  },
                                  function ($event) {
                                    return _vm.checkAllMuMemLv(
                                      $event.target.checked
                                    )
                                  },
                                ],
                              },
                            }),
                            _c("label", { attrs: { for: "all3" } }, [
                              _vm._v("전체"),
                            ]),
                          ]),
                          _vm._l(_vm.pageCode.mumemlvtype, function (row, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "check-wrap" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checkObj.mumemlvTypeChecked,
                                      expression: "checkObj.mumemlvTypeChecked",
                                    },
                                  ],
                                  attrs: { type: "checkbox", id: "group3" + i },
                                  domProps: {
                                    value: row.cmcode,
                                    checked: Array.isArray(
                                      _vm.checkObj.mumemlvTypeChecked
                                    )
                                      ? _vm._i(
                                          _vm.checkObj.mumemlvTypeChecked,
                                          row.cmcode
                                        ) > -1
                                      : _vm.checkObj.mumemlvTypeChecked,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.checkObj.mumemlvTypeChecked,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = row.cmcode,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.checkObj,
                                              "mumemlvTypeChecked",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.checkObj,
                                              "mumemlvTypeChecked",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.checkObj,
                                          "mumemlvTypeChecked",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c("label", { attrs: { for: "group3" + i } }, [
                                  _vm._v(_vm._s(row.codename)),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("tr", [
                      _vm._m(4),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "radio_wrap wide3" },
                          _vm._l(_vm.pageCode.disptype, function (row, i) {
                            return _c("div", { key: i }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.boardInfo.disptype,
                                    expression: "boardInfo.disptype",
                                  },
                                ],
                                attrs: { type: "radio", id: "group4" + i },
                                domProps: {
                                  value: row.cmcode,
                                  checked: _vm._q(
                                    _vm.boardInfo.disptype,
                                    row.cmcode
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.boardInfo,
                                      "disptype",
                                      row.cmcode
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "group4" + i } }, [
                                _vm._v(_vm._s(row.codename)),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("옵션")]),
                      _c("td", [
                        _c("div", { staticClass: "dpb" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.iscomment,
                                expression: "boardInfo.iscomment",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "group61",
                              "true-value": "T",
                              "false-value": "F",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.boardInfo.iscomment)
                                ? _vm._i(_vm.boardInfo.iscomment, null) > -1
                                : _vm._q(_vm.boardInfo.iscomment, "T"),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.boardInfo.iscomment,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "T" : "F"
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.boardInfo,
                                        "iscomment",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.boardInfo,
                                        "iscomment",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.boardInfo, "iscomment", $$c)
                                }
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "group61" } }, [
                            _vm._v("댓글"),
                          ]),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.boardInfo.iscomment === "T",
                                  expression: "boardInfo.iscomment === 'T'",
                                },
                              ],
                              staticClass: "txt-gray dpib",
                              staticStyle: {
                                width: "40px",
                                "text-align": "center",
                              },
                            },
                            [_vm._v("비밀글")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.boardInfo.iscomment === "T",
                                  expression: "boardInfo.iscomment === 'T'",
                                },
                              ],
                              staticClass: "radio_wrap dpib ml3",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.boardInfo.isseccomment,
                                    expression: "boardInfo.isseccomment",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "group061",
                                  id: "group611",
                                  value: "F",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.boardInfo.isseccomment,
                                    "F"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.boardInfo,
                                      "isseccomment",
                                      "F"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "group611" } }, [
                                _vm._v("미허용"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.boardInfo.isseccomment,
                                    expression: "boardInfo.isseccomment",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "group061",
                                  id: "group612",
                                  value: "T",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.boardInfo.isseccomment,
                                    "T"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.boardInfo,
                                      "isseccomment",
                                      "T"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "group612" } }, [
                                _vm._v("허용"),
                              ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "dpb" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.isenter,
                                expression: "boardInfo.isenter",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "group62",
                              "true-value": "T",
                              "false-value": "F",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.boardInfo.isenter)
                                ? _vm._i(_vm.boardInfo.isenter, null) > -1
                                : _vm._q(_vm.boardInfo.isenter, "T"),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.boardInfo.isenter,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "T" : "F"
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.boardInfo,
                                        "isenter",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.boardInfo,
                                        "isenter",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.boardInfo, "isenter", $$c)
                                }
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "group62" } }, [
                            _vm._v("응모"),
                          ]),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.boardInfo.isenter === "T",
                                  expression: "boardInfo.isenter === 'T'",
                                },
                              ],
                              staticClass: "txt-gray dpib",
                              staticStyle: {
                                width: "40px",
                                "text-align": "center",
                              },
                            },
                            [_vm._v("중복")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.boardInfo.isenter === "T",
                                  expression: "boardInfo.isenter === 'T'",
                                },
                              ],
                              staticClass: "radio_wrap dpib ml3",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.boardInfo.isdupenter,
                                    expression: "boardInfo.isdupenter",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "group062",
                                  id: "group621",
                                  value: "F",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.boardInfo.isdupenter,
                                    "F"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.boardInfo,
                                      "isdupenter",
                                      "F"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "group621" } }, [
                                _vm._v("미허용"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.boardInfo.isdupenter,
                                    expression: "boardInfo.isdupenter",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "group062",
                                  id: "group622",
                                  value: "T",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.boardInfo.isdupenter,
                                    "T"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.boardInfo,
                                      "isdupenter",
                                      "T"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "group622" } }, [
                                _vm._v("허용"),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.boardInfo.isdupenter === "T",
                                  expression: "boardInfo.isdupenter === 'T'",
                                },
                              ],
                              staticClass: "dpib ml3",
                            },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.boardInfo.dupentertype,
                                      expression: "boardInfo.dupentertype",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.boardInfo,
                                        "dupentertype",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("선택"),
                                  ]),
                                  _vm._l(
                                    _vm.pageCode.dupentertype,
                                    function (row, i) {
                                      return _c(
                                        "option",
                                        {
                                          key: i,
                                          domProps: { value: row.cmcode },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(row.codename) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.boardInfo.dupentercnt,
                                    expression: "boardInfo.dupentercnt",
                                  },
                                ],
                                staticClass: "ml3 center",
                                staticStyle: { width: "50px" },
                                attrs: { type: "text" },
                                domProps: { value: _vm.boardInfo.dupentercnt },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.boardInfo,
                                      "dupentercnt",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("span", [_vm._v("회까지 허용")]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(5),
                      _c("td", [
                        _c("div", { staticClass: "radio_wrap wide dpib" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.isInfo,
                                expression: "isInfo",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "group07",
                              id: "group71",
                            },
                            domProps: {
                              value: false,
                              checked: _vm._q(_vm.isInfo, false),
                            },
                            on: {
                              change: function ($event) {
                                _vm.isInfo = false
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "group71" } }, [
                            _vm._v("불필요"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.isInfo,
                                expression: "isInfo",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "group07",
                              id: "group72",
                            },
                            domProps: {
                              value: true,
                              checked: _vm._q(_vm.isInfo, true),
                            },
                            on: {
                              change: function ($event) {
                                _vm.isInfo = true
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "group72" } }, [
                            _vm._v("필요"),
                          ]),
                        ]),
                        _vm.isInfo === true
                          ? _c("div", { staticClass: "dpib ml3" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.boardInfo.isperagree,
                                    expression: "boardInfo.isperagree",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "group81",
                                  "true-value": "T",
                                  "false-value": "F",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.boardInfo.isperagree
                                  )
                                    ? _vm._i(_vm.boardInfo.isperagree, null) >
                                      -1
                                    : _vm._q(_vm.boardInfo.isperagree, "T"),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.boardInfo.isperagree,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? "T" : "F"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.boardInfo,
                                            "isperagree",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.boardInfo,
                                            "isperagree",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.boardInfo, "isperagree", $$c)
                                    }
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "group81" } }, [
                                _vm._v("개인정보 수집 및 이용 동의"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.boardInfo.ismktagree,
                                    expression: "boardInfo.ismktagree",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "group82",
                                  "true-value": "T",
                                  "false-value": "F",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.boardInfo.ismktagree
                                  )
                                    ? _vm._i(_vm.boardInfo.ismktagree, null) >
                                      -1
                                    : _vm._q(_vm.boardInfo.ismktagree, "T"),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.boardInfo.ismktagree,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? "T" : "F"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.boardInfo,
                                            "ismktagree",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.boardInfo,
                                            "ismktagree",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.boardInfo, "ismktagree", $$c)
                                    }
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "group82" } }, [
                                _vm._v("마케팅 정보 활용 동의"),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(6),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "img-with-text",
                            staticStyle: { width: "202px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "img-thumb size200",
                                class: {
                                  "no-image": _vm.$util.isNull(
                                    _vm.files["pcimgFile"]
                                  ),
                                },
                              },
                              [
                                !_vm.$util.isNull(_vm.files["pcimgFile"])
                                  ? _c("img", {
                                      attrs: {
                                        src: _vm.imgPreview["pcimgFile"],
                                        alt: "대표이미지(PC)",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _vm.$util.isNull(_vm.files["pcimgFile"])
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn blue-line mt10",
                                    staticStyle: { width: "100%" },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.fileAttach("pcimgFile")
                                      },
                                    },
                                  },
                                  [_vm._v(" 파일 올리기 ")]
                                )
                              : _vm._e(),
                            _c("input", {
                              ref: "pcimgFile",
                              attrs: {
                                type: "file",
                                accept: "image/jpeg, image/png",
                                hidden: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleFileUpload("pcimgFile")
                                },
                              },
                            }),
                            !_vm.$util.isNull(_vm.files["pcimgFile"])
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn blue-line mt10",
                                    staticStyle: { width: "calc(50% - 3px)" },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.fileAttach("pcimgFile")
                                      },
                                    },
                                  },
                                  [_vm._v(" 변경 ")]
                                )
                              : _vm._e(),
                            !_vm.$util.isNull(_vm.files["pcimgFile"])
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn red-line mt10",
                                    staticStyle: { width: "calc(50% - 4px)" },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeFile("pcimgFile")
                                      },
                                    },
                                  },
                                  [_vm._v(" 삭제 ")]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._m(7),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(8),
                      _c("td", [
                        _c("div", { staticClass: "mb10" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.copyimgcheck,
                                expression: "copyimgcheck",
                              },
                            ],
                            attrs: { type: "checkbox", id: "copy-img" },
                            domProps: {
                              checked: Array.isArray(_vm.copyimgcheck)
                                ? _vm._i(_vm.copyimgcheck, null) > -1
                                : _vm.copyimgcheck,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.copyimgcheck,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.copyimgcheck = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.copyimgcheck = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.copyimgcheck = $$c
                                  }
                                },
                                _vm.setSameAsPcepreImg,
                              ],
                            },
                          }),
                          _c("label", { attrs: { for: "copy-img" } }, [
                            _vm._v("PC 대표 이미지를 복사"),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "img-with-text",
                            staticStyle: { width: "202px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "img-thumb",
                                class: [
                                  [
                                    _vm.copyimgcheck
                                      ? "size200"
                                      : "size460wide",
                                  ],
                                  {
                                    "no-image": _vm.$util.isNull(
                                      _vm.files["mobileimgFile"]
                                    ),
                                  },
                                ],
                              },
                              [
                                !_vm.$util.isNull(_vm.files["mobileimgFile"])
                                  ? _c("img", {
                                      attrs: {
                                        src: _vm.imgPreview["mobileimgFile"],
                                        alt: "대표이미지(모바일)",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _vm.$util.isNull(_vm.files["mobileimgFile"])
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn blue-line mt10",
                                    staticStyle: { width: "100%" },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.fileAttach("mobileimgFile")
                                      },
                                    },
                                  },
                                  [_vm._v(" 파일 올리기 ")]
                                )
                              : _vm._e(),
                            _c("input", {
                              ref: "mobileimgFile",
                              attrs: {
                                type: "file",
                                accept: "image/jpeg, image/png",
                                hidden: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleFileUpload("mobileimgFile")
                                },
                              },
                            }),
                            !_vm.$util.isNull(_vm.files["mobileimgFile"])
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn blue-line mt10",
                                    staticStyle: { width: "calc(50% - 3px)" },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.fileAttach("mobileimgFile")
                                      },
                                    },
                                  },
                                  [_vm._v(" 변경 ")]
                                )
                              : _vm._e(),
                            !_vm.$util.isNull(_vm.files["mobileimgFile"])
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn red-line mt10",
                                    staticStyle: { width: "calc(50% - 4px)" },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeFile("mobileimgFile")
                                      },
                                    },
                                  },
                                  [_vm._v(" 삭제 ")]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.copyimgcheck,
                                expression: "!copyimgcheck",
                              },
                            ],
                            staticClass: "img-with-text text",
                          },
                          [_vm._m(9), _vm._m(10)]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.copyimgcheck,
                                expression: "copyimgcheck",
                              },
                            ],
                            staticClass: "img-with-text text",
                          },
                          [_vm._m(11), _vm._m(12)]
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(13),
                      _c("td", [
                        _c("div", [_c("CommonEditor", { ref: "pcEditor" })], 1),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(14),
                      _c("td", [
                        _c("div", { staticClass: "mb10" }, [
                          _c("input", {
                            attrs: { type: "checkbox", id: "copy-text" },
                            on: {
                              click: function ($event) {
                                return _vm.copyByContent($event.target.checked)
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "copy-text" } }, [
                            _vm._v("PC 내용을 복사"),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [_c("CommonEditor", { ref: "mobileEditor" })],
                          1
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(15),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.boardInfo.goodstitle,
                              expression: "boardInfo.goodstitle",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "text",
                            placeholder: "연관상품 목록 타이틀",
                          },
                          domProps: { value: _vm.boardInfo.goodstitle },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.boardInfo,
                                "goodstitle",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("연관상품(선택)")]),
                      _c("td", [
                        _c("div", { staticClass: "caption-group clearfix" }, [
                          _vm._m(16),
                          _c("div", { staticClass: "btn-group fr" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn blue-line",
                                attrs: { type: "button" },
                                on: { click: _vm.openGoodsAdditionPopup },
                              },
                              [_vm._v(" 상품추가 ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn red-line",
                                attrs: { type: "button" },
                                on: { click: _vm.removeGoodsAddition },
                              },
                              [_vm._v(" 삭제 ")]
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "scroll-y",
                            staticStyle: {
                              width: "100%",
                              "max-height": "350px",
                              "margin-bottom": "0",
                            },
                          },
                          [
                            _c(
                              "table",
                              {
                                staticClass: "data-tb align-c",
                                attrs: { cellpadding: "0", cellspacing: "0" },
                              },
                              [
                                _vm._m(17),
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          id: "chkall",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkAll(
                                              $event.target.checked
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _c("th", [_vm._v("No")]),
                                    _c("th", [_vm._v("판매구분")]),
                                    _c("th", [_vm._v("파트너사명")]),
                                    _c("th", [_vm._v("상품코드")]),
                                    _c("th", { attrs: { colspan: "2" } }, [
                                      _vm._v("상품명"),
                                    ]),
                                    _c("th", [_vm._v("판매가")]),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.productList, function (row, i) {
                                    return _c("tr", { key: i }, [
                                      _c("td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.moveData.targetIdx,
                                              expression: "moveData.targetIdx",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: "chk01",
                                          },
                                          domProps: {
                                            value: i,
                                            checked: Array.isArray(
                                              _vm.moveData.targetIdx
                                            )
                                              ? _vm._i(
                                                  _vm.moveData.targetIdx,
                                                  i
                                                ) > -1
                                              : _vm.moveData.targetIdx,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.moveData.targetIdx,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = i,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.moveData,
                                                      "targetIdx",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.moveData,
                                                      "targetIdx",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.moveData,
                                                  "targetIdx",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.$util.lpad(i + 1, 2, "0"))
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(row.ispbgoodsname)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(row.dealername)),
                                      ]),
                                      _c("td", [_vm._v(_vm._s(row.goodscode))]),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "img-thumb size60",
                                            class: {
                                              "no-image": _vm.$util.isNull(
                                                row.fullpath
                                              ),
                                            },
                                          },
                                          [
                                            !_vm.$util.isNull(row.fullpath)
                                              ? _c("img", {
                                                  attrs: {
                                                    src: row.fullpath,
                                                    alt: "사진",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "left no-left" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "small-txt" },
                                            [
                                              _vm._v(
                                                _vm._s(row.fullcategoryname)
                                              ),
                                            ]
                                          ),
                                          _c("p", { staticClass: "mg0" }, [
                                            _vm._v(_vm._s(row.goodsname)),
                                          ]),
                                        ]
                                      ),
                                      _c("td", { staticClass: "right" }, [
                                        _vm._v(_vm._s(row.price)),
                                      ]),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "bottom-group" },
                          [
                            _c("CommonArraySort", {
                              attrs: {
                                "move-data": _vm.moveData,
                                "list-data": _vm.productList,
                                "key-name": "goodsno",
                                "is-active-save-btn": false,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.goSave },
                },
                [_vm._v("저장")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("취소")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_vm._v("제목"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("진행기간"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("발표일"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("노출여부"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("개인정보동의"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("대표이미지(PC)"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-with-text text" }, [
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          "판매상품의 대표 이미지입니다. 보기 쉬운 간결한 이미지를 활용해 주세요. "
        ),
      ]),
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          "사이즈: 460*460 / 최소: 200*200 / 용량: 10MB 이하 / 파일 : JPG, JPEG, PNG "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("대표이미지(모바일)"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        "모바일 리스팅 및 와이드형 화면에 노출되는 이미지를 업로드 해 주세요. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        "사이즈: 460*460 / 최소: 200*200 / 용량: 10MB 이하 / 파일 : JPG, JPEG, PNG "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        "판매상품의 대표 이미지입니다. 보기 쉬운 간결한 이미지를 활용해 주세요. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        "사이즈: 460*460 / 최소: 200*200 / 용량: 10MB 이하 / 파일 : JPG, JPEG, PNG "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("내용(PC)"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("내용(모바일)"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("연관상품목록타이틀"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "total-group fl" }, [
      _c("span", { staticClass: "total" }, [_vm._v("적용대상 상품목록")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "7%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }