<template>
  <div class="form-area">
    <table cellpadding="0" cellspacing="0" class="gray-tb">
      <colgroup>
        <col width="180px" />
        <col width="" />
      </colgroup>
      <tbody>
        <tr>
          <th>
            공지제목
            <i class="essential"></i>
          </th>
          <td colspan="3">
            <div>
              <CommonTextInput type="text" v-model.trim="value.title" />
            </div>
            <p v-if="checkValidField('title')" class="alert-text txt-red">
              <i class="icon-alert2" />
              공지 제목은 필수 입력입니다.
            </p>
          </td>
        </tr>
        <tr>
          <th>
            전시설정
            <i class="essential"></i>
          </th>
          <td colspan="3">
            <div class="radio_wrap">
              <div
                v-for="noticeDisplayStatus in getGoodsNoticeDisplayStatusOptions()"
                :key="noticeDisplayStatus.value"
              >
                <input
                  type="radio"
                  :id="'notice_display_status_' + noticeDisplayStatus.value"
                  v-model="value.displayStatus"
                  :value="noticeDisplayStatus.value"
                />
                <label :for="'notice_display_status_' + noticeDisplayStatus.value">
                  {{ noticeDisplayStatus.label }}
                </label>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <th>
            전시기간
            <i class="essential"></i>
          </th>
          <td colspan="3">
            <div>
              <CommonDatePicker
                :disable="!hasNoticeDisplayStatusVisible(value.displayStatus)"
                :value="value.displayStartDate"
                :disabledDateCallback="onStartDatedisabledDateCallback"
                @change="onChangeStartDate"
              />
              <span>-</span>
              <CommonDatePicker
                :disable="!hasNoticeDisplayStatusVisible(value.displayStatus)"
                :value="value.displayEndDate"
                :disabledDateCallback="onEndDatedisabledDateCallback"
                @change="onChangeEndDate"
              />
            </div>
            <p
              v-if="checkValidField('displayStartDate') || checkValidField('displayEndDate')"
              class="alert-text txt-red"
            >
              <i class="icon-alert2" />
              전시 기간은 필수 입력입니다. 범위를 지정해주세요.
            </p>
            <p class="txt-gray">전시기간이 겹치는 경우, 최신으로 등록한 공지사항이 게시됩니다.</p>
          </td>
        </tr>

        <tr>
          <th>
            공지사항 유형
            <i class="essential"></i>
          </th>
          <td colspan="3">
            <div class="radio_wrap">
              <div v-for="noticeType in getGoodsNoticeTypeOptions()" :key="noticeType.value">
                <input
                  type="radio"
                  :id="'notice_type_' + noticeType.value"
                  v-model="value.noticeType"
                  :value="noticeType.value"
                />
                <label :for="'notice_type_' + noticeType.value">{{ noticeType.label }}</label>
              </div>
            </div>
          </td>
        </tr>

        <tr v-if="isTextNoticeType">
          <th>
            내용 입력
            <i class="essential"></i>
          </th>
          <td colspan="3">
            <div>
              <textarea
                type="text"
                v-model.trim="value.description"
                style="width: 100%; min-height: 200px"
                maxlength="150"
              ></textarea>
              <p v-if="checkValidField('description')" class="alert-text txt-red">
                <i class="icon-alert2" />
                내용은 필수 입력입니다.
              </p>
              <p v-else class="txt-gray">- 최대 150자 까지 등록 가능합니다.</p>
            </div>
          </td>
        </tr>
        <tr v-else>
          <th>
            이미지 업로드
            <i class="essential"></i>
          </th>
          <td colspan="3">
            <div class="file-upload-wrap">
              <button
                v-if="!isImageUpload"
                class="btn blue-line"
                type="button"
                @click="onAttachImageFile"
              >
                <i class="icon-file-photo" />
                이미지 추가
              </button>
              <div class="file-name-wrap">
                <span>{{ uploadImageName }}</span>
                <button
                  v-if="isImageUpload"
                  type="button"
                  class="btn red-line"
                  @click="onClickDeleteImage"
                >
                  삭제
                </button>
              </div>
              <input type="file" ref="imageUpload" @input="onInputFile" accept="image/*" hidden />
            </div>
            <p v-if="checkValidField('imageUrl')" class="alert-text txt-red">
              <i class="icon-alert2" />
              이미지는 필수 입력입니다.
            </p>
            <div>
              <p class="txt-gray">
                - 이미지 업로드 시, 가로 1000px / 1MB 이내 이미지만 등록 가능합니다. (PNG, JPG,
                JPEG만 가능)
              </p>
              <p class="txt-gray">
                - 권장 공지사항 이미지 가이드
                <a
                  class="download_guide"
                  href="https://img.dplot.co.kr/partners/images/D.PLOT_상품상세공지가이드.psd"
                  target="_blank"
                >
                  [다운로드]
                </a>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  GOODS_NOTICE_DISPLAY_STATUS,
  GOODS_NOTICE_DISPLAY_STATUS_OPTIONS,
  GOODS_NOTICE_TYPE,
  GOODS_NOTICE_TYPE_OPTIONS,
} from '@constants/goods/notice';
import CommonDatePicker from '@views.admin/common/CommonDatePicker.vue';
import CommonTextInput from '@views.admin/common/CommonTextInput.vue';

export default {
  name: 'goods.notice.form',
  props: ['value', 'isNew'],
  components: {
    CommonDatePicker,
    CommonTextInput,
  },
  data() {
    return {
      isChecked: false,
      uploadFile: null,
      originValue: {},
      invaildField: [],
      dirtyfield: [],
    };
  },
  computed: {
    isImageUpload() {
      return !!this.uploadFile || !!this.value.imageUrl;
    },
    isTextNoticeType() {
      return this.value.noticeType === GOODS_NOTICE_TYPE.TEXT;
    },
    uploadImageName() {
      if (this.value.imageUrl) {
        return this.value.imageUrl.split('/').pop() || '';
      }

      if (!this.uploadFile) {
        return '';
      }

      return this.uploadFile.name || '';
    },
  },
  watch: {
    'value.title': {
      handler(value) {
        const isValid = !!value;
        this.updateField('title', { value, isValid });
      },
      deep: true,
    },
    'value.displayStatus': {
      handler(value) {
        const isValid = !!value;
        this.updateField('displayStatus', { value, isValid });

        if (value === GOODS_NOTICE_DISPLAY_STATUS.전시) {
          this.updateField('displayStartDate', {
            value: this.value.displayStartDate,
            isValid: !!this.value.displayStartDate,
          });
          this.updateField('displayEndDate', {
            value: this.value.displayEndDate,
            isValid: !!this.value.displayEndDate,
          });
        } else {
          this.updateField('displayStartDate', {
            value: this.value.displayStartDate,
            isValid: true,
          });
          this.updateField('displayEndDate', { value: this.value.displayEndDate, isValid: true });
        }
      },
      deep: true,
    },
    'value.noticeType': {
      handler(value) {
        const isValid = !!value;
        this.updateField('noticeType', { value, isValid });

        if (value === GOODS_NOTICE_TYPE.TEXT) {
          this.updateField('description', {
            value: this.value.description,
            isValid: !!this.value.description,
          });
          this.updateField('imageUrl', {
            value: this.value.imageUrl,
            isValid: true,
          });
        } else {
          this.updateField('imageUrl', {
            value: this.value.imageUrl,
            isValid: !!this.value.imageUrl || !!this.uploadFile,
          });
          this.updateField('description', {
            value: this.value.description,
            isValid: true,
          });
        }
      },
      deep: true,
    },
    'value.displayStartDate': {
      handler(value) {
        const isValid = !!value;
        this.updateField('displayStartDate', { value, isValid });
      },
      deep: true,
    },
    'value.displayEndDate': {
      handler(value) {
        const isValid = !!value;
        this.updateField('displayEndDate', { value, isValid });
      },
      deep: true,
    },
    'value.description': {
      handler(value) {
        if (this.value.noticeType === GOODS_NOTICE_TYPE.TEXT) {
          const isValid = !!value;
          this.updateField('description', { value, isValid });
        } else {
          this.updateField('description', { value, isValid: true });
        }
      },
      deep: true,
    },
    invaildField: {
      handler(value) {
        this.value.invaildField = value;
      },
      deep: true,
    },
    uploadFile: {
      handler(value) {
        this.value.uploadFile = value;
        this.updateField('imageUrl', {
          value: this.value.imageUrl,
          isValid: !!this.value.imageUrl || !!this.uploadFile,
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.originValue = { ...this.value };
    this.onValid();
  },
  methods: {
    onValid() {
      const noticeInfo = this.value;

      Object.keys(noticeInfo).forEach((key) => {
        let isValid = true;
        const value = noticeInfo[key];

        switch (key) {
          case 'title':
            isValid = !!value;
            break;
          case 'displayStartDate':
          case 'displayEndDate':
            isValid =
              noticeInfo['displayStatus'] === GOODS_NOTICE_DISPLAY_STATUS.전시 ? !!value : true;
            break;
          case 'description':
            if (noticeInfo['noticeType'] === GOODS_NOTICE_TYPE.TEXT) {
              isValid = !!value;
            }
            break;
          case 'imageUrl':
            if (noticeInfo['noticeType'] === GOODS_NOTICE_TYPE.IMAGE) {
              isValid = !!value || !this.uploadFile;
            }
            break;
          default:
            break;
        }

        this.updateInvalidField(key, isValid);
      });
    },
    onAttachImageFile() {
      this.$refs.imageUpload.click();
    },
    onInputFile(event) {
      const files = event.target.files;
      if (files[0]) {
        const maxSize = 1 * 1024 * 1024;
        if (files[0].size > maxSize) {
          alert('파일은 1MB 이내 이미지만 등록 가능합니다.');
          return;
        }
        this.uploadFile = files[0] || null;
      }
    },
    onChangeStartDate(value) {
      this.value.displayStartDate = value;
    },
    onStartDatedisabledDateCallback(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (this.value.displayEndDate) {
        const endDate = new Date(this.value.displayEndDate);
        endDate.setHours(0, 0, 0, 0);

        return date < today || date > endDate;
      }

      return date < today;
    },
    onEndDatedisabledDateCallback(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (this.value.displayStartDate) {
        const startDate = new Date(this.value.displayStartDate);
        startDate.setHours(0, 0, 0, 0);

        return date < startDate;
      }

      return date < today;
    },
    onClickDeleteImage() {
      this.uploadFile = null;
      this.value.imageUrl = '';

      this.updateField('imageUrl', {
        value: this.value.imageUrl,
        isValid: !!this.value.imageUrl || !!this.uploadFile,
      });
    },
    onChangeEndDate(value) {
      this.value.displayEndDate = value;
    },
    hasNoticeDisplayStatusVisible(status) {
      return status === GOODS_NOTICE_DISPLAY_STATUS.전시;
    },
    getGoodsNoticeDisplayStatusOptions() {
      return [...GOODS_NOTICE_DISPLAY_STATUS_OPTIONS];
    },
    getGoodsNoticeTypeOptions() {
      return [...GOODS_NOTICE_TYPE_OPTIONS];
    },
    updateField(fieldName, param) {
      this.updateInvalidField(fieldName, param.isValid);
      this.updateDirtyField(fieldName, param.value);
    },
    updateInvalidField(fieldName, isValid) {
      const invaildField = this.invaildField.filter((field) => field !== fieldName);
      this.invaildField = [...invaildField, ...(isValid ? [] : [fieldName])];
    },
    updateDirtyField(fieldName, value) {
      const originValue = this.originValue[fieldName];
      const isDirty = originValue !== value;
      this.dirtyfield = [
        ...this.dirtyfield.filter((field) => field !== fieldName),
        ...(isDirty ? [fieldName] : []),
      ];
    },
    checkValidField(fieldName) {
      return this.dirtyfield.includes(fieldName) && this.invaildField.includes(fieldName);
    },
  },
};
</script>

<style scoped>
.download_guide {
  color: #167cd8;
}

.file-upload-wrap {
  display: flex;
  gap: 5px;
  align-items: center;
}

.file-name-wrap {
  display: flex;
  gap: 5px;
  align-items: center;
}
</style>
