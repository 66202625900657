var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("PartnersHeader"),
      _c("PartnersSide"),
      _c(_vm.modalComp, { tag: "component" }),
      _c("common-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }