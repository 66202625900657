var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "800px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("상품정보 변경이력")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide("commonModal")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "caption-group clearfix" }, [
              _c("div", { staticClass: "total-group fl" }, [
                _c("span", { staticClass: "total" }, [
                  _vm._v(_vm._s(_vm.info.goodscode)),
                  _c("strong", { staticClass: "left-bar txt-black" }, [
                    _vm._v(_vm._s(_vm.info.goodsname)),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "scroll-y",
                staticStyle: { "max-height": "500px" },
              },
              [
                _c(
                  "table",
                  {
                    staticClass: "data-tb align-c",
                    staticStyle: { "margin-left": "0" },
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _vm._m(0),
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("No")]),
                        _c("th", [_vm._v("구분")]),
                        _c("th", [_vm._v("변경내용")]),
                        _c("th", [
                          _vm._v(" 변경일시 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.regdate === "regdate_asc" },
                              { down: _vm.sortData.regdate === "regdate_desc" },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.regdate,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.regdate)
                              },
                            },
                          }),
                        ]),
                        _c("th", [_vm._v("처리자ID")]),
                        _c("th", [_vm._v("처리자명")]),
                      ]),
                    ]),
                    _vm.info.goodshistlist.length > 0
                      ? _c(
                          "tbody",
                          _vm._l(
                            _vm.info.goodshistlist,
                            function (item, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [_vm._v(_vm._s(index + 1))]),
                                _c("td", [_vm._v(_vm._s(item.kindname))]),
                                _c("td", { staticClass: "left" }, [
                                  _c("ul", { staticClass: "dot-list" }, [
                                    _c("li", [_vm._v(_vm._s(item.summary))]),
                                  ]),
                                ]),
                                _c("td", [_vm._v(_vm._s(item.regdate))]),
                                _c("td", [_vm._v(_vm._s(item.userid))]),
                                _c("td", [_vm._v(_vm._s(item.username))]),
                              ])
                            }
                          ),
                          0
                        )
                      : _c("tbody", [_vm._m(1)]),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("commonModal")
                    },
                  },
                },
                [_vm._v(" 닫기 ")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "%" } }),
      _c("col", { attrs: { width: "22%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "14%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [
      _c("td", { attrs: { colspan: "6" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }