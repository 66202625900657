<template>
  <!-- 컨텐츠 영역 -->
  <div class="content partner m-leftmenu">
    <!-- 파트너사 관리자는 partner 클래스 추가 -->
    <div class="page-info clearfix">
      <div class="title">정보수정</div>
    </div>
    <div class="inner">
      <div class="blue-box mg0">
        정보를 안전하게 보호하기 위해 비밀번호를 다시 한 번 확인합니다.
      </div>
      <table cellpadding="0" cellspacing="0" class="gray-tb mt10">
        <colgroup>
          <col width="150px" />
          <col width="" />
        </colgroup>
        <tbody>
          <tr>
            <th>아이디</th>
            <td><input type="text" v-model="user.id" style="width: 194px" readonly /></td>
          </tr>
          <tr>
            <th>담당자명</th>
            <td><input type="text" v-model="info.managerName" style="width: 194px" readonly /></td>
          </tr>
          <tr>
            <th>휴대폰</th>
            <td>
              <input type="text" v-model="info.managerMobile" style="width: 194px" readonly />
              <button
                v-if="!timer.isStart && !authComplete"
                type="button"
                class="btn blue-line ml3"
                @click="handleSendAuthMessage"
              >
                인증번호 전송
              </button>
              <button
                v-if="timer.isStart && !authComplete"
                type="button"
                class="btn blue-line ml3"
                @click="handleSendAuthMessage"
              >
                재발송
              </button>
            </td>
          </tr>
          <tr>
            <th>인증번호</th>
            <td>
              <input
                type="text"
                v-model="authConfirmNumber"
                placeholder="휴대폰 인증번호 6자리"
                style="width: 194px"
                :readonly="authComplete"
              />
              <span v-show="timer.isStart" style="color: red" class="ml3">
                {{ timer.expiredTime }}
              </span>
              <div class="mt10">인증번호 유효 시간은 발송 후 10분 이내 입니다.</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="btn-group mb10">
        <button type="button" class="btn big blue" @click="handleAuthConfirm">확인</button>
      </div>
    </div>
    <partners-info-apply-detail
      v-if="detailShow"
      :active-user-no="info.userno"
      :disableInitPartnerNameInput="true"
      v-on:closeDetail="closeDetail"
    />
  </div>
  <!-- /컨텐츠 영역 -->
</template>

<script>
import debounce from 'lodash/debounce';
import PartnersInfoApplyDetail from '@views.admin/partners/apply/PartnersInfoApplyDetail';
const { VUE_APP_API_V2_PREFIX } = process.env;
let polling = null;
export default {
  name: 'partners.info.auth',
  components: { PartnersInfoApplyDetail },
  data() {
    return {
      user: this.$util.getUser(this.$store.getters.CONSTANTS.MANAGER_SESSION),
      info: {
        userno: '',
        managerName: '',
        managerMobile: '',
      },
      timer: {
        isStart: false,
        timeCounter: 600,
        expiredTime: '',
        authNumber: '',
      },
      authConfirmNumber: '',
      authComplete: false,
      detailShow: false,
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    clearInterval(polling);
  },

  methods: {
    async setPartnerInfo() {
      try {
        const { data } = await this.$http.get(`${VUE_APP_API_V2_PREFIX}/partner/${this.user.no}`);
        const { id, managerName, managerMobile, representativeName, representativeMobile } = data;
        this.info = {
          ...this.info,
          userno: id,
          managerName: managerName || representativeName,
          managerMobile: managerMobile || representativeMobile,
        };
        // 기존 대표자명, 대표자번호가 담당자 정보로 변경됨에 따라 방어로직 추가 (manager 정보가 없으면 안되지만 방어로직 차원에서 유지)
      } catch (err) {
        alert(err.message);
      }
    },
    init() {
      this.setPartnerInfo();
    },
    debounceSendAuthMessage: debounce(
      async function () {
        try {
          const { managerName: chargename, managerMobile: chargemobile, userno } = this.info;
          const hasPartnerInfo = !!chargename && !!chargemobile && !!userno;
          if (!hasPartnerInfo) {
            throw new Error('인증번호 전송 중 에러가 발생 하였습니다.');
          }
          const param = { chargemobile, userno, chargename };
          const { data } = await this.$http.post('/partners/common/auth/send-message', param);

          this.timer.authNumber = data.number;
          this.timerStart();
        } catch (err) {
          alert(err.message ?? '인증번호 전송 중 에러가 발생 하였습니다.');
        }
      },
      500,
      {
        leading: true,
        trailing: false,
      },
    ),
    handleSendAuthMessage() {
      this.debounceSendAuthMessage();
    },
    timerStart() {
      this.timer.timeCounter = 600;
      this.timer.isStart = true;
      polling = setInterval(() => {
        this.timer.timeCounter--;
        this.secondsToMinuteSeconds();
        if (this.timer.timeCounter <= 0) {
          this.timeStop();
        }
      }, 1000);
    },
    timeStop() {
      this.timer.isStart = false;
      clearInterval(polling);
    },
    secondsToMinuteSeconds() {
      const minutes = Math.floor(this.timer.timeCounter / 60) % 60 || 0;
      const seconds = this.timer.timeCounter % 60 || 0;
      this.timer.expiredTime = [minutes, seconds]
        .map((v) => (v < 10 ? '0' + v : v))
        .filter((v, i) => v !== '00' || i > 0)
        .join(':');
    },
    handleAuthConfirm() {
      this.debounceAuthConfirm();
    },
    debounceAuthConfirm: debounce(
      async function () {
        try {
          if (!this.timer.authNumber) {
            alert('인증번호를 전송 후 확인이 가능합니다.');
            return;
          }

          if (!this.authConfirmNumber) {
            alert('인증번호 입력 후 확인이 가능합니다.');
            return;
          }

          const params = {
            userno: this.info.userno,
            authNo: this.timer.authNumber,
            inputAuthNo: this.authConfirmNumber,
            phone: this.info.managerMobile,
          };
          const { data } = await this.$http.post('/partners/common/auth/confirm-message', params);
          this.timeStop();
          this.authComplete = true;
          this.detailShow = true;
        } catch (err) {
          return;
        }
      },
      500,
      { leading: true, trailing: false },
    ),
    closeDetail: function () {
      this.detailShow = false;
      this.timer.isStart = false;
      this.timer.expiredTime = '';
      this.timer.authNumber = '';
      this.authConfirmNumber = '';
      polling = null;
      this.authComplete = false;

      this.init();
    },
  },
};
</script>

<style scoped></style>
