var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "560px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("항목관리")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide("commonModal")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _vm._m(0),
            _c(
              "div",
              { staticStyle: { margin: "20px 0" } },
              _vm._l(_vm.optionItemList, function (item, index) {
                return _c("div", { key: item.optionno, staticClass: "dpb" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.optionname,
                        expression: "item.optionname",
                      },
                    ],
                    staticStyle: { width: "calc(100% - 58px)" },
                    attrs: { type: "text", placeholder: "항목 " + (index + 1) },
                    domProps: { value: item.optionname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(item, "optionname", $event.target.value)
                      },
                    },
                  }),
                  index < 3
                    ? _c("button", {
                        staticClass: "add",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.addItem(index)
                          },
                        },
                      })
                    : _vm._e(),
                  index > 0
                    ? _c("button", {
                        staticClass: "del",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.removeItem(index)
                          },
                        },
                      })
                    : _vm._e(),
                ])
              }),
              0
            ),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.sendData },
                },
                [_vm._v("적용")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("commonModal")
                    },
                  },
                },
                [_vm._v(" 취소 ")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "blue-box mg0" }, [
      _c("ul", [
        _c("li", [
          _vm._v(
            "최대 4개까지 추가 가능하며, 항목명은 최대 20자까지 입력 가능합니다."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }