var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "600px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v(_vm._s(_vm.headerName))]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide("commonModal")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _vm.popupType === "TYPE"
              ? _c(
                  "div",
                  {
                    staticClass: "scroll-y",
                    staticStyle: { "max-height": "500px" },
                  },
                  [
                    _c(
                      "table",
                      {
                        staticClass: "data-tb align-c",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _vm._m(0),
                        _vm._m(1),
                        _vm.listData.length > 0
                          ? _c(
                              "tbody",
                              _vm._l(_vm.listData, function (row) {
                                return _c("tr", { key: row.mhisidx }, [
                                  _c("td", [_vm._v(_vm._s(row.regdatestr))]),
                                  _c("td", [_vm._v(_vm._s(row.preval))]),
                                  _c("td", [_vm._v(_vm._s(row.aftval))]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(row.regtypename) +
                                        "(" +
                                        _vm._s(row.reguserid) +
                                        ")"
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            )
                          : _c("tbody", [_vm._m(2)]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.popupType === "LV"
              ? _c(
                  "div",
                  {
                    staticClass: "scroll-y",
                    staticStyle: { "max-height": "500px" },
                  },
                  [
                    _c(
                      "table",
                      {
                        staticClass: "data-tb align-c",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _vm._m(3),
                        _vm._m(4),
                        _vm.listData.length > 0
                          ? _c(
                              "tbody",
                              _vm._l(_vm.listData, function (row) {
                                return _c("tr", { key: row.mhisidx }, [
                                  _c("td", [_vm._v(_vm._s(row.regdatestr))]),
                                  _c("td", [_vm._v(_vm._s(row.memlvtypename))]),
                                  _c("td", [_vm._v(_vm._s(row.preval))]),
                                  _c("td", [_vm._v(_vm._s(row.aftval))]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(row.regtypename) +
                                        "(" +
                                        _vm._s(row.reguserid) +
                                        ")"
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            )
                          : _c("tbody", [_vm._m(5)]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.popupType === "ADEMAIL" ||
            _vm.popupType === "ADSMS" ||
            _vm.popupType === "ADPUSH"
              ? _c(
                  "div",
                  {
                    staticClass: "scroll-y",
                    staticStyle: { "max-height": "500px" },
                  },
                  [
                    _c(
                      "table",
                      {
                        staticClass: "data-tb align-c",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _vm._m(6),
                        _vm._m(7),
                        _vm.listData.length > 0
                          ? _c(
                              "tbody",
                              _vm._l(_vm.listData, function (row) {
                                return _c("tr", { key: row.mhisidx }, [
                                  _c("td", [_vm._v(_vm._s(row.regdatestr))]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        row.aftval === "T" ? "동의" : "거부"
                                      )
                                    ),
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(row.regtypename) +
                                        "(" +
                                        _vm._s(row.reguserid) +
                                        ")"
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            )
                          : _c("tbody", [_vm._m(8)]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("commonModal")
                    },
                  },
                },
                [_vm._v(" 닫기 ")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "35%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "35%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("변경일시")]),
        _c("th", [_vm._v("변경 전")]),
        _c("th", [_vm._v("변경 후")]),
        _c("th", [_vm._v("처리자")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "4" } }, [_vm._v("변경 이력이 없습니다.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "25%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("변경일시")]),
        _c("th", [_vm._v("회원유형")]),
        _c("th", [_vm._v("변경 전")]),
        _c("th", [_vm._v("변경 후")]),
        _c("th", [_vm._v("처리자")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "5" } }, [_vm._v("변경 이력이 없습니다.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "40%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "40%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("변경일시")]),
        _c("th", [_vm._v("동의여부")]),
        _c("th", [_vm._v("처리자")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "3" } }, [_vm._v("변경 이력이 없습니다.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }