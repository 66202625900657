var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "left-menu" }, [
        _c("div", { staticClass: "inner" }, [
          _c("div", { staticClass: "title", on: { click: _vm.goStatsMain } }, [
            _c("i", { staticClass: "icons icon-partner-t" }),
            _c("span", [_vm._v("통계정보")]),
          ]),
          _c(
            "ul",
            { staticClass: "menu" },
            _vm._l(_vm.sideMenu, function (menu) {
              return _c(
                "li",
                { key: menu.code },
                [
                  _c(
                    "router-link",
                    {
                      class: { active: menu.isactive },
                      attrs: { to: { name: menu.url } },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onActive(menu.url)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(menu.name))]),
                      _c("i", {
                        staticClass: "icon-expand",
                        class: { active: menu.isactive },
                      }),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }