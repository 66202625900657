var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1400px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("쿠폰 등록")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "bar-title" }, [_vm._v("기본정보")]),
            _c("div", { staticClass: "boxing" }, [
              _c("div", { staticClass: "form-area" }, [
                _c("dl", [
                  _vm._m(0),
                  _c("dd", [
                    _c("div", { staticClass: "radio_wrap wide" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.istrash,
                            expression: "info.istrash",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "istrashD",
                          id: "istrashDF",
                          value: "F",
                        },
                        domProps: { checked: _vm._q(_vm.info.istrash, "F") },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.info, "istrash", "F")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "istrashDF" } }, [
                        _vm._v("사용함"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.istrash,
                            expression: "info.istrash",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "istrashD",
                          id: "istrashDT",
                          value: "T",
                        },
                        domProps: { checked: _vm._q(_vm.info.istrash, "T") },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.info, "istrash", "T")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "istrashDT" } }, [
                        _vm._v("사용안함"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("dl", [
                  _vm._m(1),
                  _c("dd", [
                    _c(
                      "div",
                      { staticClass: "radio_wrap" },
                      _vm._l(_vm.commonCode.comcpntype, function (item) {
                        return _c("div", { key: item.cmcode }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.info.comcpntype,
                                expression: "info.comcpntype",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "comcpntypeD",
                              id: "comcpntypeD_" + item.cmcode,
                            },
                            domProps: {
                              value: item.cmcode,
                              checked: _vm._q(_vm.info.comcpntype, item.cmcode),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(
                                  _vm.info,
                                  "comcpntype",
                                  item.cmcode
                                )
                              },
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "comcpntypeD_" + item.cmcode } },
                            [_vm._v(_vm._s(item.codename))]
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("dl", [
                  _vm._m(2),
                  _c("dd", [
                    _c(
                      "div",
                      { staticClass: "radio_wrap" },
                      _vm._l(_vm.commonCode.cpnissuetype, function (item) {
                        return _c("div", { key: item.cmcode }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.info.cpnissuetype,
                                expression: "info.cpnissuetype",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "cpnissuetypeD",
                              id: "cpnissuetypeD_" + item.cmcode,
                            },
                            domProps: {
                              value: item.cmcode,
                              checked: _vm._q(
                                _vm.info.cpnissuetype,
                                item.cmcode
                              ),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(
                                  _vm.info,
                                  "cpnissuetype",
                                  item.cmcode
                                )
                              },
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "cpnissuetypeD_" + item.cmcode } },
                            [_vm._v(_vm._s(item.codename))]
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("dl", [
                  _vm._m(3),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.cpnname,
                          expression: "info.cpnname",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "text",
                        placeholder: "쿠폰명",
                        maxlength: "200",
                      },
                      domProps: { value: _vm.info.cpnname },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.info, "cpnname", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("설명")]),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.cpndesc,
                          expression: "info.cpndesc",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "text",
                        placeholder: "설명",
                        maxlength: "400",
                      },
                      domProps: { value: _vm.info.cpndesc },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.info, "cpndesc", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "bar-title" }, [_vm._v("발급대상회원")]),
            _c("div", { staticClass: "form-area" }, [
              _c(
                "table",
                {
                  staticClass: "gray-tb",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(4),
                  _c("tbody", [
                    _c("tr", [
                      _vm._m(5),
                      _c("td", [
                        _c("div", { staticClass: "radio_wrap" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.info.ismemtype,
                                expression: "info.ismemtype",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "ismemtypeD",
                              id: "ismemtypeDT",
                              value: "T",
                            },
                            domProps: {
                              checked: _vm._q(_vm.info.ismemtype, "T"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.info, "ismemtype", "T")
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "ismemtypeDT" } }, [
                            _vm._v("특정 유형/등급 대상"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.info.ismemtype,
                                expression: "info.ismemtype",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "ismemtypeD",
                              id: "ismemtypeDF",
                              value: "F",
                            },
                            domProps: {
                              checked: _vm._q(_vm.info.ismemtype, "F"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.info, "ismemtype", "F")
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "ismemtypeDF" } }, [
                            _vm._v("특정 회원 대상"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm.info.ismemtype == "T"
                      ? _c("tr", [
                          _vm._m(6),
                          _c(
                            "td",
                            [
                              _c("div", { staticClass: "check-wrap" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.isallmember,
                                      expression: "info.isallmember",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "chkAllMemberD",
                                    "true-value": "T",
                                    "false-value": "F",
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.info.isallmember)
                                      ? _vm._i(_vm.info.isallmember, null) > -1
                                      : _vm._q(_vm.info.isallmember, "T"),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.info.isallmember,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? "T" : "F"
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.info,
                                                "isallmember",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.info,
                                                "isallmember",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.info, "isallmember", $$c)
                                        }
                                      },
                                      _vm.checkAllMembertype,
                                    ],
                                  },
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "chkAllMemberD" } },
                                  [_vm._v("전체")]
                                ),
                              ]),
                              _vm._l(
                                _vm.commonCode.dadamembertype,
                                function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.cmcode,
                                      staticClass: "check-wrap",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.info.mumembertypeArr,
                                            expression: "info.mumembertypeArr",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "mumembertypeD_" + item.cmcode,
                                          "true-value": [],
                                        },
                                        domProps: {
                                          value: item.cmcode,
                                          checked: Array.isArray(
                                            _vm.info.mumembertypeArr
                                          )
                                            ? _vm._i(
                                                _vm.info.mumembertypeArr,
                                                item.cmcode
                                              ) > -1
                                            : _vm._q(
                                                _vm.info.mumembertypeArr,
                                                []
                                              ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.info.mumembertypeArr,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? [] : false
                                            if (Array.isArray($$a)) {
                                              var $$v = item.cmcode,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.info,
                                                    "mumembertypeArr",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.info,
                                                    "mumembertypeArr",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.info,
                                                "mumembertypeArr",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "mumembertypeD_" + item.cmcode,
                                          },
                                        },
                                        [_vm._v(_vm._s(item.codename))]
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm.info.ismemtype == "T"
                      ? _c("tr", [
                          _vm._m(7),
                          _c(
                            "td",
                            [
                              _c("div", { staticClass: "check-wrap" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.isallmemlv,
                                      expression: "info.isallmemlv",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "chkAllMemlvD",
                                    "true-value": "T",
                                    "false-value": "F",
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.info.isallmemlv)
                                      ? _vm._i(_vm.info.isallmemlv, null) > -1
                                      : _vm._q(_vm.info.isallmemlv, "T"),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.info.isallmemlv,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? "T" : "F"
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.info,
                                                "isallmemlv",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.info,
                                                "isallmemlv",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.info, "isallmemlv", $$c)
                                        }
                                      },
                                      _vm.checkAllMemlvtype,
                                    ],
                                  },
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "chkAllMemlvD" } },
                                  [_vm._v("전체")]
                                ),
                              ]),
                              _vm._l(_vm.commonCode.memlvtype, function (item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.cmcode,
                                    staticClass: "check-wrap",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.info.mumemlvtypeArr,
                                          expression: "info.mumemlvtypeArr",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: "mumemlvtypeD_" + item.cmcode,
                                        "true-value": [],
                                      },
                                      domProps: {
                                        value: item.cmcode,
                                        checked: Array.isArray(
                                          _vm.info.mumemlvtypeArr
                                        )
                                          ? _vm._i(
                                              _vm.info.mumemlvtypeArr,
                                              item.cmcode
                                            ) > -1
                                          : _vm._q(_vm.info.mumemlvtypeArr, []),
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.info.mumemlvtypeArr,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? [] : false
                                          if (Array.isArray($$a)) {
                                            var $$v = item.cmcode,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.info,
                                                  "mumemlvtypeArr",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.info,
                                                  "mumemlvtypeArr",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.info,
                                              "mumemlvtypeArr",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "mumemlvtypeD_" + item.cmcode,
                                        },
                                      },
                                      [_vm._v(_vm._s(item.codename))]
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm.info.ismemtype == "F"
                      ? _c("tr", [
                          _vm._m(8),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "caption-group clearfix dpb" },
                              [
                                _vm._m(9),
                                _c("div", { staticClass: "btn-group fr" }, [
                                  _c("input", {
                                    ref: "userExcelFile",
                                    attrs: {
                                      type: "file",
                                      hidden: "",
                                      accept:
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.readExcelFile(
                                          "userExcelFile",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn black-line",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadExcelTemplate(
                                            "MemberTemplate.xlsx"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 양식 다운로드 ")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn green-line",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.fileAttach("userExcelFile")
                                        },
                                      },
                                    },
                                    [_vm._v(" 엑셀파일 올리기 ")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line",
                                      attrs: { type: "button" },
                                      on: { click: _vm.openAddUserPopup },
                                    },
                                    [_vm._v(" 회원추가 ")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn red-line",
                                      attrs: { type: "button" },
                                      on: { click: _vm.removeUser },
                                    },
                                    [_vm._v("삭제")]
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", { staticClass: "scroll-y mh-400" }, [
                              _c(
                                "table",
                                {
                                  staticClass: "data-tb align-c",
                                  staticStyle: { "margin-left": "0" },
                                  attrs: { cellpadding: "0", cellspacing: "0" },
                                },
                                [
                                  _c("caption", [_vm._v("특정회원목록")]),
                                  _vm._m(10),
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.info.isallchkmem,
                                              expression: "info.isallchkmem",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: "chkallgoodsmem",
                                            "true-value": "T",
                                            "false-value": "F",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.info.isallchkmem
                                            )
                                              ? _vm._i(
                                                  _vm.info.isallchkmem,
                                                  null
                                                ) > -1
                                              : _vm._q(
                                                  _vm.info.isallchkmem,
                                                  "T"
                                                ),
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a = _vm.info.isallchkmem,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked ? "T" : "F"
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.info,
                                                        "isallchkmem",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.info,
                                                        "isallchkmem",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.info,
                                                    "isallchkmem",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function ($event) {
                                                return _vm.checkAllMemberList(
                                                  $event.target.checked
                                                )
                                              },
                                            ],
                                          },
                                        }),
                                      ]),
                                      _c("th", [_vm._v("No")]),
                                      _c("th", [_vm._v("아이디")]),
                                      _c("th", [_vm._v("이름")]),
                                      _c("th", [
                                        _vm._v(" 유형 "),
                                        _c("button", {
                                          staticClass: "sort",
                                          class: [
                                            {
                                              up:
                                                _vm.sortData
                                                  .dadamembertypename ===
                                                "dadamembertypename_asc",
                                            },
                                            {
                                              down:
                                                _vm.sortData
                                                  .dadamembertypename ===
                                                "dadamembertypename_desc",
                                            },
                                          ],
                                          attrs: {
                                            type: "button",
                                            value:
                                              _vm.sortData.dadamembertypename,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.sortToggle(
                                                _vm.sortData.dadamembertypename
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("th", [
                                        _vm._v(" 등급 "),
                                        _c("button", {
                                          staticClass: "sort",
                                          class: [
                                            {
                                              up:
                                                _vm.sortData.memlvtypename ===
                                                "memlvtypename_asc",
                                            },
                                            {
                                              down:
                                                _vm.sortData.memlvtypename ===
                                                "memlvtypename_desc",
                                            },
                                          ],
                                          attrs: {
                                            type: "button",
                                            value: _vm.sortData.memlvtypename,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.sortToggle(
                                                _vm.sortData.memlvtypename
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("th", [
                                        _vm._v(" 가입일 "),
                                        _c("button", {
                                          staticClass: "sort",
                                          class: [
                                            {
                                              up:
                                                _vm.sortData.regdate ===
                                                "regdate_asc",
                                            },
                                            {
                                              down:
                                                _vm.sortData.regdate ===
                                                "regdate_desc",
                                            },
                                          ],
                                          attrs: {
                                            type: "button",
                                            value: _vm.sortData.regdate,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.sortToggle(
                                                _vm.sortData.regdate
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]),
                                  _vm.info.issueTargetMemberList.length > 0
                                    ? _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.info.issueTargetMemberList,
                                          function (item, index) {
                                            return _c(
                                              "tr",
                                              { key: item.userno },
                                              [
                                                _c("td", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: item.ischecked,
                                                        expression:
                                                          "item.ischecked",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      id: "member_" + index,
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        item.ischecked
                                                      )
                                                        ? _vm._i(
                                                            item.ischecked,
                                                            null
                                                          ) > -1
                                                        : item.ischecked,
                                                    },
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$a =
                                                              item.ischecked,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  item,
                                                                  "ischecked",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  item,
                                                                  "ischecked",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              item,
                                                              "ischecked",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                        function ($event) {
                                                          return _vm.checkMemberList(
                                                            $event.target
                                                              .checked
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  }),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(index + 1)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.userid)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.username)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.dadamembertypename
                                                    )
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.memlvtypename)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.regdate)),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _c("tbody", [_vm._m(11)]),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c("tr", [
                      _vm._m(12),
                      _c("td", [
                        _c("div", { staticClass: "radio_wrap wide dpib" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.info.isrcvlimit,
                                expression: "info.isrcvlimit",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "isrcvlimitD",
                              id: "isrcvlimitDF",
                              value: "F",
                            },
                            domProps: {
                              checked: _vm._q(_vm.info.isrcvlimit, "F"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.info, "isrcvlimit", "F")
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "isrcvlimitDF" } }, [
                            _vm._v("불필요"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.info.isrcvlimit,
                                expression: "info.isrcvlimit",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "isrcvlimitD",
                              id: "isrcvlimitDT",
                              value: "T",
                            },
                            domProps: {
                              checked: _vm._q(_vm.info.isrcvlimit, "T"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.info, "isrcvlimit", "T")
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "isrcvlimitDT" } }, [
                            _vm._v("필요"),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "dpib ml10" },
                          _vm._l(_vm.commonCode.mumemrcvtype, function (item) {
                            return _c(
                              "div",
                              { key: item.cmcode, staticClass: "check-wrap" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.mumemrcvtypeArr,
                                      expression: "info.mumemrcvtypeArr",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "mumemrcvtypeD_" + item.cmcode,
                                    "true-value": [],
                                    disabled: _vm.info.isrcvlimit == "F",
                                  },
                                  domProps: {
                                    value: item.cmcode,
                                    checked: Array.isArray(
                                      _vm.info.mumemrcvtypeArr
                                    )
                                      ? _vm._i(
                                          _vm.info.mumemrcvtypeArr,
                                          item.cmcode
                                        ) > -1
                                      : _vm._q(_vm.info.mumemrcvtypeArr, []),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.info.mumemrcvtypeArr,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? [] : false
                                      if (Array.isArray($$a)) {
                                        var $$v = item.cmcode,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.info,
                                              "mumemrcvtypeArr",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.info,
                                              "mumemrcvtypeArr",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.info,
                                          "mumemrcvtypeArr",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    attrs: {
                                      for: "mumemrcvtypeD_" + item.cmcode,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.codename))]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "bar-title" }, [
              _vm._v("쿠폰적용대상 상품"),
            ]),
            _c("div", { staticClass: "form-area" }, [
              _c(
                "table",
                {
                  staticClass: "gray-tb",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(13),
                  _c("tbody", [
                    _c("tr", [
                      _vm._m(14),
                      _c("td", [
                        _c("div", { staticClass: "radio_wrap dpib" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.info.istotcate,
                                expression: "info.istotcate",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "istotcateD",
                              id: "istotcateDT",
                              value: "T",
                            },
                            domProps: {
                              checked: _vm._q(_vm.info.istotcate, "T"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.info, "istotcate", "T")
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "istotcateDT" } }, [
                            _vm._v("전체"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.info.istotcate,
                                expression: "info.istotcate",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "istotcateD",
                              id: "istotcateDF",
                              value: "F",
                            },
                            domProps: {
                              checked: _vm._q(_vm.info.istotcate, "F"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.info, "istotcate", "F")
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "istotcateDF" } }, [
                            _vm._v("특정카테고리"),
                          ]),
                        ]),
                        _vm.info.istotcate == "F"
                          ? _c("div", { staticClass: "dpib" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.depth1Category.idx,
                                      expression: "info.depth1Category.idx",
                                    },
                                  ],
                                  staticStyle: { width: "175px" },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.info.depth1Category,
                                          "idx",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        return _vm.setCategoryCodeInfo(
                                          "1",
                                          _vm.info.depth1Category.idx
                                        )
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("대분류"),
                                  ]),
                                  _vm._l(
                                    _vm.categoryObj.depth1list,
                                    function (item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.idx,
                                          domProps: { value: item.idx },
                                        },
                                        [_vm._v(" " + _vm._s(item.value) + " ")]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.depth2Category.idx,
                                      expression: "info.depth2Category.idx",
                                    },
                                  ],
                                  staticStyle: { width: "175px" },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.info.depth2Category,
                                          "idx",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        return _vm.setCategoryCodeInfo(
                                          "2",
                                          _vm.info.depth2Category.idx
                                        )
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("중분류"),
                                  ]),
                                  _vm._l(
                                    _vm.categoryObj.depth2list,
                                    function (item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.idx,
                                          domProps: { value: item.idx },
                                        },
                                        [_vm._v(" " + _vm._s(item.value) + " ")]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.depth3Category.idx,
                                      expression: "info.depth3Category.idx",
                                    },
                                  ],
                                  staticStyle: { width: "175px" },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.info.depth3Category,
                                          "idx",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        return _vm.setCategoryCodeInfo(
                                          "3",
                                          _vm.info.depth3Category.idx
                                        )
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("소분류"),
                                  ]),
                                  _vm._l(
                                    _vm.categoryObj.depth3list,
                                    function (item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.idx,
                                          domProps: { value: item.idx },
                                        },
                                        [_vm._v(" " + _vm._s(item.value) + " ")]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.depth4Category.idx,
                                      expression: "info.depth4Category.idx",
                                    },
                                  ],
                                  staticStyle: { width: "175px" },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.info.depth4Category,
                                          "idx",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        return _vm.setCategoryCodeInfo(
                                          "4",
                                          _vm.info.depth4Category.idx
                                        )
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("세분류"),
                                  ]),
                                  _vm._l(
                                    _vm.categoryObj.depth4list,
                                    function (item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.idx,
                                          domProps: { value: item.idx },
                                        },
                                        [_vm._v(" " + _vm._s(item.value) + " ")]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c("button", {
                                staticClass: "add",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addCpnCategory("T")
                                  },
                                },
                              }),
                              _c("button", {
                                staticClass: "minus",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addCpnCategory("F")
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm.info.istotcate == "F"
                      ? _c("tr", [
                          _vm._m(15),
                          _c("td", [
                            _c("div", { staticClass: "category-selected" }, [
                              _c(
                                "ul",
                                _vm._l(_vm.info.cpncateList, function (item) {
                                  return _c("li", { key: item.idx }, [
                                    _c("span", { staticClass: "box mr5" }, [
                                      _vm._v(
                                        _vm._s(
                                          item.isadd == "T" ? "추가" : "제외"
                                        )
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(_vm._s(item.fullcategoryname)),
                                    ]),
                                    _c("button", {
                                      staticClass: "del",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeCategory(
                                            item,
                                            "cpncateList"
                                          )
                                        },
                                      },
                                    }),
                                  ])
                                }),
                                0
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c("tr", [
                      _vm._m(16),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "radio_wrap" },
                          _vm._l(
                            _vm.commonCode.goodsrangetype,
                            function (item) {
                              return _c("div", { key: item.cmcode }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.goodsrangetype,
                                      expression: "info.goodsrangetype",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "goodsrangetypeD",
                                    id: "goodsrangetypeD_" + item.cmcode,
                                    disabled:
                                      _vm.info.istotcate == "F" &&
                                      item.cmcode ==
                                        _vm.$store.getters["ADMIN"]
                                          .GOODS_RANGE_ALL,
                                  },
                                  domProps: {
                                    value: item.cmcode,
                                    checked: _vm._q(
                                      _vm.info.goodsrangetype,
                                      item.cmcode
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.info,
                                        "goodsrangetype",
                                        item.cmcode
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    attrs: {
                                      for: "goodsrangetypeD_" + item.cmcode,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.codename))]
                                ),
                              ])
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                    (_vm.info.istotcate == "F" ||
                      !_vm.$util.isNull(_vm.info.goodsrangetype)) &&
                    _vm.info.goodsrangetype !=
                      _vm.$store.getters["ADMIN"].GOODS_RANGE_ALL
                      ? _c("tr", [
                          _vm._m(17),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "caption-group clearfix" },
                              [
                                _vm._m(18),
                                _c("div", { staticClass: "btn-group fr" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addGoods("cpngoodsList")
                                        },
                                      },
                                    },
                                    [_vm._v(" 상품추가 ")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn red-line",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeGoods("cpngoodsList")
                                        },
                                      },
                                    },
                                    [_vm._v(" 삭제 ")]
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", { staticClass: "scroll-y mh-350" }, [
                              _c(
                                "table",
                                {
                                  staticClass: "data-tb align-c",
                                  attrs: { cellpadding: "0", cellspacing: "0" },
                                },
                                [
                                  _vm._m(19),
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.info.isallchkcpngoods,
                                              expression:
                                                "info.isallchkcpngoods",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: "chkallcpngoods",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.info.isallchkcpngoods
                                            )
                                              ? _vm._i(
                                                  _vm.info.isallchkcpngoods,
                                                  null
                                                ) > -1
                                              : _vm.info.isallchkcpngoods,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a =
                                                    _vm.info.isallchkcpngoods,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.info,
                                                        "isallchkcpngoods",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.info,
                                                        "isallchkcpngoods",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.info,
                                                    "isallchkcpngoods",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function ($event) {
                                                return _vm.checkAllGoodsList(
                                                  $event.target.checked,
                                                  "cpngoodsList"
                                                )
                                              },
                                            ],
                                          },
                                        }),
                                      ]),
                                      _c("th", [_vm._v("No")]),
                                      _c("th", [_vm._v("판매구분")]),
                                      _c("th", [_vm._v("파트너사명")]),
                                      _c("th", [_vm._v("상품코드")]),
                                      _c("th", [_vm._v("단품코드")]),
                                      _c("th", { attrs: { colspan: "2" } }, [
                                        _vm._v("상품명"),
                                      ]),
                                      _c("th", [_vm._v("옵션명")]),
                                      _c("th", [_vm._v("판매가")]),
                                    ]),
                                  ]),
                                  _vm.info.cpngoodsList.length > 0
                                    ? _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.info.cpngoodsList,
                                          function (item, index) {
                                            return _c(
                                              "tr",
                                              {
                                                key:
                                                  item.goodsno +
                                                  "_" +
                                                  item.optioncode,
                                              },
                                              [
                                                _c("td", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: item.ischecked,
                                                        expression:
                                                          "item.ischecked",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      id:
                                                        item.goodsno +
                                                        "_" +
                                                        item.optioncode,
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        item.ischecked
                                                      )
                                                        ? _vm._i(
                                                            item.ischecked,
                                                            null
                                                          ) > -1
                                                        : item.ischecked,
                                                    },
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$a =
                                                              item.ischecked,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  item,
                                                                  "ischecked",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  item,
                                                                  "ischecked",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              item,
                                                              "ischecked",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                        function ($event) {
                                                          return _vm.checkGoodsList(
                                                            "cpngoodsList"
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  }),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(index + 1)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.ispbgoodsname)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.dealername)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.goodscode)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.optioncode)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "img-thumb size60",
                                                      class: {
                                                        "no-image":
                                                          _vm.$util.isNull(
                                                            item.fullpath
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      !_vm.$util.isNull(
                                                        item.fullpath
                                                      )
                                                        ? _c("img", {
                                                            attrs: {
                                                              src: item.fullpath,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "left no-left",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "small-txt",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.fullcategoryname
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      { staticClass: "mg0" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.goodsname)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "white-space": "pre-wrap",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.optionconts)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$util.maskComma(
                                                          item.price
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _c("tbody", [_vm._m(20)]),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.info.comcpntype ==
                    _vm.$store.getters["ADMIN"].COM_CPN_GOODS
                      ? _c("tr", [
                          _vm._m(21),
                          _c("td", [
                            _c("span", [_vm._v("파트너사")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.info.partratio,
                                  expression: "info.partratio",
                                },
                              ],
                              staticClass: "ml3 right",
                              staticStyle: { width: "50px" },
                              attrs: { type: "text", maxlength: "5" },
                              domProps: { value: _vm.info.partratio },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.info,
                                    "partratio",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("span", { staticClass: "ml3" }, [_vm._v("%")]),
                            _vm._v("   "),
                            _c("span", [_vm._v("(D.PLOT")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.info.dadaratio,
                                  expression: "info.dadaratio",
                                },
                              ],
                              staticClass: "ml3 right",
                              staticStyle: { width: "50px" },
                              attrs: {
                                type: "text",
                                maxlength: "5",
                                readonly: "",
                              },
                              domProps: { value: _vm.info.dadaratio },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.info,
                                    "dadaratio",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("span", { staticClass: "ml3" }, [_vm._v("%)")]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.$util.isNull(_vm.info.comcpntype) &&
                      !_vm.$util.isNull(_vm.info.cpnissuetype),
                    expression:
                      "!$util.isNull(info.comcpntype) && !$util.isNull(info.cpnissuetype)",
                  },
                ],
                staticClass: "bar-title",
              },
              [_vm._v(" 사용 조건 ")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.$util.isNull(_vm.info.comcpntype) &&
                      !_vm.$util.isNull(_vm.info.cpnissuetype),
                    expression:
                      "!$util.isNull(info.comcpntype) && !$util.isNull(info.cpnissuetype)",
                  },
                ],
                staticClass: "form-area",
              },
              [
                _c(
                  "table",
                  {
                    staticClass: "gray-tb",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _vm._m(22),
                    _c("tbody", [
                      _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.info.cpnissuetype ==
                                _vm.$store.getters["ADMIN"].CPN_ISSUE_REGULAR,
                              expression:
                                "info.cpnissuetype == $store.getters['ADMIN'].CPN_ISSUE_REGULAR",
                            },
                          ],
                        },
                        [
                          _vm._m(23),
                          _c(
                            "td",
                            [
                              _c("CommonDatePickerFromTo", {
                                attrs: {
                                  fromYYYYMMDD: _vm.info.evrcpnusestdate,
                                  fromHH: _vm.info.evrcpnusesthour,
                                  fromMM: _vm.info.evrcpnusestmin,
                                  toYYYYMMDD: _vm.info.evrcpnuseeddate,
                                  toHH: _vm.info.evrcpnuseedhour,
                                  toMM: _vm.info.evrcpnuseedmin,
                                  useFrom: true,
                                  useTo: true,
                                },
                                on: { getDate: _vm.pickerEvCpnuseChangeEvent },
                              }),
                              _c(
                                "div",
                                { staticClass: "radio_wrap dpib ml3 period" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.info.evrcpnuseperiod,
                                        expression: "info.evrcpnuseperiod",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "periodEV_aday_0",
                                      value: "aday_0",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.info.evrcpnuseperiod,
                                        "aday_0"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.info,
                                          "evrcpnuseperiod",
                                          "aday_0"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "periodEV_aday_0" } },
                                    [_vm._v("오늘")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.info.evrcpnuseperiod,
                                        expression: "info.evrcpnuseperiod",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "periodEV_months_1",
                                      value: "months_1",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.info.evrcpnuseperiod,
                                        "months_1"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.info,
                                          "evrcpnuseperiod",
                                          "months_1"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "periodEV_months_1" } },
                                    [_vm._v("1개월")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.info.evrcpnuseperiod,
                                        expression: "info.evrcpnuseperiod",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "periodEV_months_3",
                                      value: "months_3",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.info.evrcpnuseperiod,
                                        "months_3"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.info,
                                          "evrcpnuseperiod",
                                          "months_3"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "periodEV_months_3" } },
                                    [_vm._v("3개월")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.info.evrcpnuseperiod,
                                        expression: "info.evrcpnuseperiod",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "periodEV_months_6",
                                      value: "months_6",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.info.evrcpnuseperiod,
                                        "months_6"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.info,
                                          "evrcpnuseperiod",
                                          "months_6"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "periodEV_months_6" } },
                                    [_vm._v("6개월")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.info.evrcpnuseperiod,
                                        expression: "info.evrcpnuseperiod",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "periodEV_years_1",
                                      value: "years_1",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.info.evrcpnuseperiod,
                                        "years_1"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.info,
                                          "evrcpnuseperiod",
                                          "years_1"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "periodEV_years_1" } },
                                    [_vm._v("1년")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.info.cpnissuetype ==
                                _vm.$store.getters["ADMIN"].CPN_ISSUE_REGULAR,
                              expression:
                                "info.cpnissuetype == $store.getters['ADMIN'].CPN_ISSUE_REGULAR",
                            },
                          ],
                        },
                        [
                          _vm._m(24),
                          _c(
                            "td",
                            _vm._l(_vm.commonCode.cpnusetype, function (item) {
                              return _c(
                                "div",
                                { key: item.cmcode, staticClass: "dpb" },
                                [
                                  item.cmcode !=
                                  _vm.$store.getters["ADMIN"].CPN_USE_PERIOD
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.info.cpnusetype,
                                            expression: "info.cpnusetype",
                                          },
                                        ],
                                        staticClass: "circle",
                                        attrs: {
                                          type: "radio",
                                          name: "cpnusetypeEV",
                                          id: "cpnusetypeEV_" + item.cmcode,
                                        },
                                        domProps: {
                                          value: item.cmcode,
                                          checked: _vm._q(
                                            _vm.info.cpnusetype,
                                            item.cmcode
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.info,
                                              "cpnusetype",
                                              item.cmcode
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  item.cmcode !=
                                  _vm.$store.getters["ADMIN"].CPN_USE_PERIOD
                                    ? _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "cpnusetypeEV_" + item.cmcode,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.codename) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.cmcode ==
                                  _vm.$store.getters["ADMIN"].CPN_USE_DAY_CNT
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.info.issuedaycnt,
                                            expression: "info.issuedaycnt",
                                          },
                                        ],
                                        staticClass: "center",
                                        staticStyle: { width: "60px" },
                                        attrs: {
                                          type: "text",
                                          maxlength: "11",
                                          disabled:
                                            _vm.info.cpnusetype !==
                                            _vm.$store.getters["ADMIN"]
                                              .CPN_USE_DAY_CNT,
                                        },
                                        domProps: {
                                          value: _vm.info.issuedaycnt,
                                        },
                                        on: {
                                          input: [
                                            function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.info,
                                                "issuedaycnt",
                                                $event.target.value
                                              )
                                            },
                                            () =>
                                              (this.value = this.value.replace(
                                                /(^0|[^0-9])/gi,
                                                ""
                                              )),
                                          ],
                                        },
                                      })
                                    : _vm._e(),
                                  item.cmcode ==
                                  _vm.$store.getters["ADMIN"].CPN_USE_DAY_CNT
                                    ? _c("span", { staticClass: "ml3" }, [
                                        _vm._v(" 일간 사용가능 "),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                      _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.info.cpnissuetype !=
                                _vm.$store.getters["ADMIN"].CPN_ISSUE_REGULAR,
                              expression:
                                "info.cpnissuetype != $store.getters['ADMIN'].CPN_ISSUE_REGULAR",
                            },
                          ],
                        },
                        [
                          _vm._m(25),
                          _c(
                            "td",
                            _vm._l(_vm.commonCode.cpnusetype, function (item) {
                              return _c(
                                "div",
                                { key: item.cmcode, staticClass: "dpb" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.info.cpnusetype,
                                        expression: "info.cpnusetype",
                                      },
                                    ],
                                    staticClass: "circle",
                                    attrs: {
                                      type: "radio",
                                      name: "cpnusetypeD",
                                      id: "cpnusetypeD_" + item.cmcode,
                                    },
                                    domProps: {
                                      value: item.cmcode,
                                      checked: _vm._q(
                                        _vm.info.cpnusetype,
                                        item.cmcode
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.info,
                                          "cpnusetype",
                                          item.cmcode
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: "cpnusetypeD_" + item.cmcode,
                                      },
                                    },
                                    [_vm._v(_vm._s(item.codename))]
                                  ),
                                  _c("CommonDatePickerFromTo", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          item.cmcode ==
                                          _vm.$store.getters["ADMIN"]
                                            .CPN_USE_PERIOD,
                                        expression:
                                          "item.cmcode == $store.getters['ADMIN'].CPN_USE_PERIOD",
                                      },
                                    ],
                                    attrs: {
                                      toDisable:
                                        _vm.info.cpnusetype !==
                                        _vm.$store.getters["ADMIN"]
                                          .CPN_USE_PERIOD,
                                      fromDisable:
                                        _vm.info.cpnusetype !==
                                        _vm.$store.getters["ADMIN"]
                                          .CPN_USE_PERIOD,
                                      fromYYYYMMDD: _vm.info.cpnusestdate,
                                      fromHH: _vm.info.cpnusesthour,
                                      fromMM: _vm.info.cpnusestmin,
                                      toYYYYMMDD: _vm.info.cpnuseeddate,
                                      toHH: _vm.info.cpnuseedhour,
                                      toMM: _vm.info.cpnuseedmin,
                                      useFrom: true,
                                      useTo: true,
                                    },
                                    on: {
                                      getDate: _vm.pickerCpnuseChangeEvent,
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            item.cmcode ==
                                            _vm.$store.getters["ADMIN"]
                                              .CPN_USE_PERIOD,
                                          expression:
                                            "item.cmcode == $store.getters['ADMIN'].CPN_USE_PERIOD",
                                        },
                                      ],
                                      staticClass: "radio_wrap dpib ml3 period",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.info.cpnuseperiod,
                                            expression: "info.cpnuseperiod",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          id: "periodD_aday_0",
                                          value: "aday_0",
                                          disabled:
                                            _vm.info.cpnusetype !==
                                            _vm.$store.getters["ADMIN"]
                                              .CPN_USE_PERIOD,
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.info.cpnuseperiod,
                                            "aday_0"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.info,
                                              "cpnuseperiod",
                                              "aday_0"
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: "periodD_aday_0" } },
                                        [_vm._v("오늘")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.info.cpnuseperiod,
                                            expression: "info.cpnuseperiod",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          id: "periodD_days_3",
                                          value: "days_3",
                                          disabled:
                                            _vm.info.cpnusetype !==
                                            _vm.$store.getters["ADMIN"]
                                              .CPN_USE_PERIOD,
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.info.cpnuseperiod,
                                            "days_3"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.info,
                                              "cpnuseperiod",
                                              "days_3"
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: "periodD_days_3" } },
                                        [_vm._v("3일")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.info.cpnuseperiod,
                                            expression: "info.cpnuseperiod",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          id: "periodD_days_7",
                                          value: "days_7",
                                          disabled:
                                            _vm.info.cpnusetype !==
                                            _vm.$store.getters["ADMIN"]
                                              .CPN_USE_PERIOD,
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.info.cpnuseperiod,
                                            "days_7"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.info,
                                              "cpnuseperiod",
                                              "days_7"
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: "periodD_days_7" } },
                                        [_vm._v("7일")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.info.cpnuseperiod,
                                            expression: "info.cpnuseperiod",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          id: "periodD_days_30",
                                          value: "days_30",
                                          disabled:
                                            _vm.info.cpnusetype !==
                                            _vm.$store.getters["ADMIN"]
                                              .CPN_USE_PERIOD,
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.info.cpnuseperiod,
                                            "days_30"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.info,
                                              "cpnuseperiod",
                                              "days_30"
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: "periodD_days_30" } },
                                        [_vm._v("30일")]
                                      ),
                                    ]
                                  ),
                                  item.cmcode ==
                                  _vm.$store.getters["ADMIN"].CPN_USE_DAY_CNT
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.info.issuedaycnt,
                                            expression: "info.issuedaycnt",
                                          },
                                        ],
                                        staticClass: "center",
                                        staticStyle: { width: "60px" },
                                        attrs: {
                                          type: "text",
                                          maxlength: "11",
                                          disabled:
                                            _vm.info.cpnusetype !==
                                            _vm.$store.getters["ADMIN"]
                                              .CPN_USE_DAY_CNT,
                                        },
                                        domProps: {
                                          value: _vm.info.issuedaycnt,
                                        },
                                        on: {
                                          input: [
                                            function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.info,
                                                "issuedaycnt",
                                                $event.target.value
                                              )
                                            },
                                            () =>
                                              (this.value = this.value.replace(
                                                /(^0|[^0-9])/gi,
                                                ""
                                              )),
                                          ],
                                        },
                                      })
                                    : _vm._e(),
                                  item.cmcode ==
                                  _vm.$store.getters["ADMIN"].CPN_USE_DAY_CNT
                                    ? _c("span", { staticClass: "ml3" }, [
                                        _vm._v(" 일간 사용가능 "),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                      _c("tr", [
                        _vm._m(26),
                        _c(
                          "td",
                          [
                            _c("div", { staticClass: "check-wrap" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.info.isallmuappch,
                                    expression: "info.isallmuappch",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "chkAllChannelD",
                                  "true-value": "T",
                                  "false-value": "F",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.info.isallmuappch)
                                    ? _vm._i(_vm.info.isallmuappch, null) > -1
                                    : _vm._q(_vm.info.isallmuappch, "T"),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.info.isallmuappch,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? "T" : "F"
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.info,
                                              "isallmuappch",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.info,
                                              "isallmuappch",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.info, "isallmuappch", $$c)
                                      }
                                    },
                                    _vm.checkAllAppchtype,
                                  ],
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: "chkAllChannelD" } },
                                [_vm._v("전체적용")]
                              ),
                            ]),
                            _vm._l(_vm.commonCode.muappchtype, function (item) {
                              return _c(
                                "div",
                                { key: item.cmcode, staticClass: "check-wrap" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.info.muappchtypeArr,
                                        expression: "info.muappchtypeArr",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "muappchtypeD_" + item.cmcode,
                                      "true-value": [],
                                    },
                                    domProps: {
                                      value: item.cmcode,
                                      checked: Array.isArray(
                                        _vm.info.muappchtypeArr
                                      )
                                        ? _vm._i(
                                            _vm.info.muappchtypeArr,
                                            item.cmcode
                                          ) > -1
                                        : _vm._q(_vm.info.muappchtypeArr, []),
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.info.muappchtypeArr,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? [] : false
                                        if (Array.isArray($$a)) {
                                          var $$v = item.cmcode,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.info,
                                                "muappchtypeArr",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.info,
                                                "muappchtypeArr",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.info,
                                            "muappchtypeArr",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: "muappchtypeD_" + item.cmcode,
                                      },
                                    },
                                    [_vm._v(_vm._s(item.codename))]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                      _vm.info.comcpntype ==
                      _vm.$store.getters["ADMIN"].COM_CPN_DELIV
                        ? _c("tr", [
                            _vm._m(27),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "radio_wrap wide dpib" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.info.is_shipping_free,
                                        expression: "info.is_shipping_free",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "isshippingfreeD",
                                      id: "isshippingfreeDT",
                                      value: "T",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.info.is_shipping_free,
                                        "T"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.info,
                                          "is_shipping_free",
                                          "T"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "isshippingfreeDT" } },
                                    [_vm._v("적용")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.info.is_shipping_free,
                                        expression: "info.is_shipping_free",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "isshippingfreeD",
                                      id: "isshippingfreeDF",
                                      value: "F",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.info.is_shipping_free,
                                        "F"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.info,
                                          "is_shipping_free",
                                          "F"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "isshippingfreeDF" } },
                                    [_vm._v("미적용")]
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.info.comcpntype !=
                      _vm.$store.getters["ADMIN"].COM_CPN_DELIV
                        ? _c("tr", [
                            _vm._m(28),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "radio_wrap wide dpib" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.info.isorderlimit,
                                        expression: "info.isorderlimit",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "isorderlimitD",
                                      id: "isorderlimitDF",
                                      value: "F",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.info.isorderlimit,
                                        "F"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.info,
                                          "isorderlimit",
                                          "F"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "isorderlimitDF" } },
                                    [_vm._v("제한없음")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.info.isorderlimit,
                                        expression: "info.isorderlimit",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "isorderlimitD",
                                      id: "isorderlimitDT",
                                      value: "T",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.info.isorderlimit,
                                        "T"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.info,
                                          "isorderlimit",
                                          "T"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "isorderlimitDT" } },
                                    [_vm._v("주문금액기준")]
                                  ),
                                ]
                              ),
                              _vm.info.isorderlimit == "T"
                                ? _c("div", { staticClass: "dpib ml3" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.info.orlimitamt,
                                          expression: "info.orlimitamt",
                                        },
                                      ],
                                      staticClass: "right",
                                      staticStyle: { width: "80px" },
                                      attrs: {
                                        type: "text",
                                        maxlength: "11",
                                        oninput:
                                          "this.value = this.value.replace(/(^0[0-9]|[^0-9])/gi, '');",
                                      },
                                      domProps: { value: _vm.info.orlimitamt },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.info,
                                            "orlimitamt",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c("span", { staticClass: "ml3" }, [
                                      _vm._v("원 이상 구매 시"),
                                    ]),
                                    _vm.info.comcpntype ===
                                    _vm.$store.getters["ADMIN"].COM_CPN_GOODS
                                      ? _c(
                                          "span",
                                          { staticClass: "txt-orange ml10" },
                                          [
                                            _c("i", {
                                              staticClass: "icon-alert",
                                            }),
                                            _vm._v(
                                              " 할인 프로모션(즉시할인)이 적용된 상품금액기준 "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "txt-orange ml10" },
                                          [
                                            _c("i", {
                                              staticClass: "icon-alert",
                                            }),
                                            _vm._v(
                                              " 할인 프로모션(즉시할인)이 적용된 주문금액기준 "
                                            ),
                                          ]
                                        ),
                                  ])
                                : _vm._e(),
                            ]),
                          ])
                        : _c("tr", [
                            _vm._m(29),
                            _c("td", [
                              _c("span", [_vm._v("상품 금액 기준")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.info.orlimitamt,
                                    expression: "info.orlimitamt",
                                  },
                                ],
                                staticClass: "right ml3",
                                staticStyle: { width: "80px" },
                                attrs: {
                                  type: "text",
                                  maxlength: "11",
                                  oninput:
                                    "this.value = this.value.replace(/(^0[0-9]|[^0-9])/gi, '');",
                                },
                                domProps: { value: _vm.info.orlimitamt },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.info,
                                      "orlimitamt",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("span", { staticClass: "ml3" }, [
                                _vm._v("원 이상 구매 시"),
                              ]),
                              _vm._m(30),
                            ]),
                          ]),
                      _vm.info.comcpntype !=
                      _vm.$store.getters["ADMIN"].COM_CPN_DELIV
                        ? _c("tr", [
                            _vm._m(31),
                            _c("td", [
                              _c("div", { staticClass: "dpb" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.ispercent,
                                      expression: "info.ispercent",
                                    },
                                  ],
                                  staticClass: "circle",
                                  attrs: {
                                    type: "radio",
                                    name: "ispercentD",
                                    id: "ispercentDF",
                                    value: "F",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.info.ispercent, "F"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.info,
                                        "ispercent",
                                        "F"
                                      )
                                    },
                                  },
                                }),
                                _c("label", { attrs: { for: "ispercentDF" } }, [
                                  _vm._v("정액"),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.disprice,
                                      expression: "info.disprice",
                                    },
                                  ],
                                  staticClass: "right ml3",
                                  staticStyle: { width: "80px" },
                                  attrs: {
                                    type: "text",
                                    maxlength: "11",
                                    oninput:
                                      "this.value = this.value.replace(/(^0[0-9]|[^0-9])/gi, '');",
                                    disabled: _vm.info.ispercent === "T",
                                  },
                                  domProps: { value: _vm.info.disprice },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.info,
                                        "disprice",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c("span", { staticClass: "ml3" }, [
                                  _vm._v("원"),
                                ]),
                              ]),
                              _c("div", { staticClass: "dpb" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.ispercent,
                                      expression: "info.ispercent",
                                    },
                                  ],
                                  staticClass: "circle",
                                  attrs: {
                                    type: "radio",
                                    name: "ispercentD",
                                    id: "ispercentDT",
                                    value: "T",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.info.ispercent, "T"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.info,
                                        "ispercent",
                                        "T"
                                      )
                                    },
                                  },
                                }),
                                _c("label", { attrs: { for: "ispercentDT" } }, [
                                  _vm._v("정률"),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.dispercent,
                                      expression: "info.dispercent",
                                    },
                                  ],
                                  staticClass: "right ml3",
                                  staticStyle: { width: "80px" },
                                  attrs: {
                                    type: "text",
                                    maxlength: "11",
                                    disabled: _vm.info.ispercent === "F",
                                  },
                                  domProps: { value: _vm.info.dispercent },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.info,
                                        "dispercent",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c("span", { staticClass: "ml3" }, [
                                  _vm._v("% (원단위 절사/최대할인금액"),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.maxdisamt,
                                      expression: "info.maxdisamt",
                                    },
                                  ],
                                  staticClass: "right ml3",
                                  staticStyle: { width: "80px" },
                                  attrs: {
                                    type: "text",
                                    maxlength: "11",
                                    oninput:
                                      "this.value = this.value.replace(/(^0[0-9]|[^0-9])/gi, '');",
                                    disabled: _vm.info.ispercent === "F",
                                  },
                                  domProps: { value: _vm.info.maxdisamt },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.info,
                                        "maxdisamt",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c("span", { staticClass: "ml3" }, [
                                  _vm._v("원)"),
                                ]),
                              ]),
                              _vm.info.comcpntype ==
                              _vm.$store.getters["ADMIN"].COM_CPN_GOODS
                                ? _c("div", { staticClass: "dpb mt10" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.info.isrvmargin,
                                          expression: "info.isrvmargin",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: "isrvmarginD",
                                        "true-value": "T",
                                        "false-value": "F",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.info.isrvmargin
                                        )
                                          ? _vm._i(_vm.info.isrvmargin, null) >
                                            -1
                                          : _vm._q(_vm.info.isrvmargin, "T"),
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.info.isrvmargin,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? "T" : "F"
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.info,
                                                  "isrvmargin",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.info,
                                                  "isrvmargin",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.info,
                                              "isrvmargin",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      { attrs: { for: "isrvmarginD" } },
                                      [_vm._v("역마진 발생상품 제외")]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ])
                        : _c("tr", [
                            _c("th", [
                              _vm._v(" 배송비 최대할인금액 "),
                              _vm.info.is_shipping_free == "F"
                                ? _c("i", { staticClass: "essential" })
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c("span", [_vm._v("최대")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.info.maxdisamt,
                                    expression: "info.maxdisamt",
                                  },
                                ],
                                staticClass: "right ml3",
                                staticStyle: { width: "80px" },
                                attrs: {
                                  type: "text",
                                  maxlength: "11",
                                  disabled: _vm.info.is_shipping_free == "T",
                                  oninput:
                                    "this.value = this.value.replace(/(^0[0-9]|[^0-9])/gi, '');",
                                },
                                domProps: { value: _vm.info.maxdisamt },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.info,
                                      "maxdisamt",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("span", { staticClass: "ml3" }, [
                                _vm._v("원 까지"),
                              ]),
                            ]),
                          ]),
                      _vm.info.comcpntype ==
                      _vm.$store.getters["ADMIN"].COM_CPN_CART
                        ? _c("tr", [
                            _c("th", [_vm._v("중복사용범위")]),
                            _c(
                              "td",
                              _vm._l(
                                _vm.commonCode.mucpnduptype,
                                function (item, idx) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.cmcode,
                                      staticClass: "check-wrap",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.info.mucpnduptypeArr,
                                            expression: "info.mucpnduptypeArr",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "mucpnduptypeD_" + item.cmcode,
                                          "true-value": [],
                                          disabled:
                                            _vm.info.goodsrangetype !==
                                            "GGT001",
                                        },
                                        domProps: {
                                          value: item.cmcode,
                                          checked: Array.isArray(
                                            _vm.info.mucpnduptypeArr
                                          )
                                            ? _vm._i(
                                                _vm.info.mucpnduptypeArr,
                                                item.cmcode
                                              ) > -1
                                            : _vm._q(
                                                _vm.info.mucpnduptypeArr,
                                                []
                                              ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.info.mucpnduptypeArr,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? [] : false
                                            if (Array.isArray($$a)) {
                                              var $$v = item.cmcode,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.info,
                                                    "mucpnduptypeArr",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.info,
                                                    "mucpnduptypeArr",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.info,
                                                "mucpnduptypeArr",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "mucpnduptypeD_" + item.cmcode,
                                          },
                                        },
                                        [_vm._v(_vm._s(item.codename))]
                                      ),
                                      idx + 1 ===
                                        _vm.commonCode.mucpnduptype?.length &&
                                      _vm.info.goodsrangetype !== "GGT001"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "alert-text txt-orange ml-20",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "icon-alert2",
                                              }),
                                              _vm._v(
                                                " 대상 상품범위가 '전체상품'인 경우에만 설정 가능합니다. "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.$util.isNull(_vm.info.cpnissuetype) &&
                      _vm.info.cpnissuetype ==
                        _vm.$store.getters["ADMIN"].CPN_ISSUE_PURCH_CONFIRM,
                    expression:
                      "\n          !$util.isNull(info.cpnissuetype) &&\n          info.cpnissuetype == $store.getters['ADMIN'].CPN_ISSUE_PURCH_CONFIRM\n        ",
                  },
                ],
                staticClass: "bar-title",
              },
              [_vm._v(" 구매확정 조건 ")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.$util.isNull(_vm.info.cpnissuetype) &&
                      _vm.info.cpnissuetype ==
                        _vm.$store.getters["ADMIN"].CPN_ISSUE_PURCH_CONFIRM,
                    expression:
                      "\n          !$util.isNull(info.cpnissuetype) &&\n          info.cpnissuetype == $store.getters['ADMIN'].CPN_ISSUE_PURCH_CONFIRM\n        ",
                  },
                ],
                staticClass: "form-area",
              },
              [
                _c(
                  "table",
                  {
                    staticClass: "gray-tb",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _vm._m(32),
                    _c("tbody", [
                      _c("tr", [
                        _vm._m(33),
                        _c("td", [
                          _c("div", { staticClass: "radio_wrap wide" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.info.isfirstord,
                                  expression: "info.isfirstord",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                name: "isfirstordD",
                                id: "isfirstordDF",
                                value: "F",
                              },
                              domProps: {
                                checked: _vm._q(_vm.info.isfirstord, "F"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.info, "isfirstord", "F")
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "isfirstordDF" } }, [
                              _vm._v("재구매 확정"),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.info.isfirstord,
                                  expression: "info.isfirstord",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                name: "isfirstordD",
                                id: "isfirstordDT",
                                value: "T",
                              },
                              domProps: {
                                checked: _vm._q(_vm.info.isfirstord, "T"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.info, "isfirstord", "T")
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "isfirstordDT" } }, [
                              _vm._v("첫구매 확정"),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm.info.isfirstord == "F"
                        ? _c("tr", [
                            _vm._m(34),
                            _c("td", [
                              _c("div", { staticClass: "radio_wrap dpib" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.isordtotcate,
                                      expression: "info.isordtotcate",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "isordtotcateD",
                                    id: "isordtotcateDT",
                                    value: "T",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.info.isordtotcate, "T"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.info,
                                        "isordtotcate",
                                        "T"
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "isordtotcateDT" } },
                                  [_vm._v("전체")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.isordtotcate,
                                      expression: "info.isordtotcate",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "isordtotcateD",
                                    id: "isordtotcateDF",
                                    value: "F",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.info.isordtotcate, "F"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.info,
                                        "isordtotcate",
                                        "F"
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "isordtotcateDF" } },
                                  [_vm._v("특정카테고리")]
                                ),
                              ]),
                              _vm.info.isordtotcate == "F"
                                ? _c("div", { staticClass: "dpib" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.info.depth1OrdCategory.idx,
                                            expression:
                                              "info.depth1OrdCategory.idx",
                                          },
                                        ],
                                        staticStyle: { width: "175px" },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.info.depth1OrdCategory,
                                                "idx",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function ($event) {
                                              return _vm.setCategoryCodeInfo(
                                                "1",
                                                _vm.info.depth1OrdCategory.idx,
                                                "ord"
                                              )
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("대분류"),
                                        ]),
                                        _vm._l(
                                          _vm.categoryObj.depth1ordlist,
                                          function (item) {
                                            return _c(
                                              "option",
                                              {
                                                key: item.idx,
                                                domProps: { value: item.idx },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.value) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.info.depth2OrdCategory.idx,
                                            expression:
                                              "info.depth2OrdCategory.idx",
                                          },
                                        ],
                                        staticStyle: { width: "175px" },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.info.depth2OrdCategory,
                                                "idx",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function ($event) {
                                              return _vm.setCategoryCodeInfo(
                                                "2",
                                                _vm.info.depth2OrdCategory.idx,
                                                "ord"
                                              )
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("중분류"),
                                        ]),
                                        _vm._l(
                                          _vm.categoryObj.depth2ordlist,
                                          function (item) {
                                            return _c(
                                              "option",
                                              {
                                                key: item.idx,
                                                domProps: { value: item.idx },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.value) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.info.depth3OrdCategory.idx,
                                            expression:
                                              "info.depth3OrdCategory.idx",
                                          },
                                        ],
                                        staticStyle: { width: "175px" },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.info.depth3OrdCategory,
                                                "idx",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function ($event) {
                                              return _vm.setCategoryCodeInfo(
                                                "3",
                                                _vm.info.depth3OrdCategory.idx,
                                                "ord"
                                              )
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("소분류"),
                                        ]),
                                        _vm._l(
                                          _vm.categoryObj.depth3ordlist,
                                          function (item) {
                                            return _c(
                                              "option",
                                              {
                                                key: item.idx,
                                                domProps: { value: item.idx },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.value) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.info.depth4OrdCategory.idx,
                                            expression:
                                              "info.depth4OrdCategory.idx",
                                          },
                                        ],
                                        staticStyle: { width: "175px" },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.info.depth4OrdCategory,
                                                "idx",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function ($event) {
                                              return _vm.setCategoryCodeInfo(
                                                "4",
                                                _vm.info.depth4OrdCategory.idx,
                                                "ord"
                                              )
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("세분류"),
                                        ]),
                                        _vm._l(
                                          _vm.categoryObj.depth4ordlist,
                                          function (item) {
                                            return _c(
                                              "option",
                                              {
                                                key: item.idx,
                                                domProps: { value: item.idx },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.value) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c("button", {
                                      staticClass: "add",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addCpnCategory("T", "ord")
                                        },
                                      },
                                    }),
                                    _c("button", {
                                      staticClass: "minus",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addCpnCategory("F", "ord")
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.info.isordtotcate == "F"
                        ? _c("tr", [
                            _vm._m(35),
                            _c("td", [
                              _c("div", { staticClass: "category-selected" }, [
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.info.cpnordcateList,
                                    function (item) {
                                      return _c("li", { key: item.idx }, [
                                        _c("span", { staticClass: "box mr5" }, [
                                          _vm._v(
                                            _vm._s(
                                              item.isadd == "T"
                                                ? "추가"
                                                : "제외"
                                            )
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(_vm._s(item.fullcategoryname)),
                                        ]),
                                        _c("button", {
                                          staticClass: "del",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeCategory(
                                                item,
                                                "cpnordcateList"
                                              )
                                            },
                                          },
                                        }),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.info.isfirstord == "F"
                        ? _c("tr", [
                            _vm._m(36),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "radio_wrap" },
                                _vm._l(
                                  _vm.commonCode.goodsrangetype,
                                  function (item) {
                                    return _c("div", { key: item.cmcode }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.info.ordgdrangetype,
                                            expression: "info.ordgdrangetype",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "ordgdrangetypeD",
                                          id: "ordgdrangetypeD_" + item.cmcode,
                                          disabled:
                                            _vm.info.isordtotcate == "F" &&
                                            item.cmcode ==
                                              _vm.$store.getters["ADMIN"]
                                                .GOODS_RANGE_ALL,
                                        },
                                        domProps: {
                                          value: item.cmcode,
                                          checked: _vm._q(
                                            _vm.info.ordgdrangetype,
                                            item.cmcode
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.info,
                                              "ordgdrangetype",
                                              item.cmcode
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for:
                                              "ordgdrangetypeD_" + item.cmcode,
                                          },
                                        },
                                        [_vm._v(_vm._s(item.codename))]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      (_vm.info.isordtotcate == "F" ||
                        !_vm.$util.isNull(_vm.info.ordgdrangetype)) &&
                      _vm.info.ordgdrangetype !=
                        _vm.$store.getters["ADMIN"].GOODS_RANGE_ALL
                        ? _c("tr", [
                            _vm._m(37),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "caption-group clearfix" },
                                [
                                  _vm._m(38),
                                  _c("div", { staticClass: "btn-group fr" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn blue-line",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addGoods(
                                              "cpnordgoodsList"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 상품추가 ")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn red-line",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeGoods(
                                              "cpnordgoodsList"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 삭제 ")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "scroll-y mh-350" }, [
                                _c(
                                  "table",
                                  {
                                    staticClass: "data-tb align-c",
                                    attrs: {
                                      cellpadding: "0",
                                      cellspacing: "0",
                                    },
                                  },
                                  [
                                    _vm._m(39),
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.info.isallchkcpnordgoods,
                                                expression:
                                                  "info.isallchkcpnordgoods",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              id: "chkallcpnordgoods",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.info.isallchkcpnordgoods
                                              )
                                                ? _vm._i(
                                                    _vm.info
                                                      .isallchkcpnordgoods,
                                                    null
                                                  ) > -1
                                                : _vm.info.isallchkcpnordgoods,
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a =
                                                      _vm.info
                                                        .isallchkcpnordgoods,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.info,
                                                          "isallchkcpnordgoods",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.info,
                                                          "isallchkcpnordgoods",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.info,
                                                      "isallchkcpnordgoods",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                function ($event) {
                                                  return _vm.checkAllGoodsList(
                                                    $event.target.checked,
                                                    "cpnordgoodsList"
                                                  )
                                                },
                                              ],
                                            },
                                          }),
                                        ]),
                                        _c("th", [_vm._v("No")]),
                                        _c("th", [_vm._v("판매구분")]),
                                        _c("th", [_vm._v("파트너사명")]),
                                        _c("th", [_vm._v("상품코드")]),
                                        _c("th", [_vm._v("단품코드")]),
                                        _c("th", { attrs: { colspan: "2" } }, [
                                          _vm._v("상품명"),
                                        ]),
                                        _c("th", [_vm._v("옵션명")]),
                                        _c("th", [_vm._v("판매가")]),
                                      ]),
                                    ]),
                                    _vm.info.cpnordgoodsList.length > 0
                                      ? _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.info.cpnordgoodsList,
                                            function (item, index) {
                                              return _c(
                                                "tr",
                                                {
                                                  key:
                                                    item.goodsno +
                                                    "_" +
                                                    item.optioncode,
                                                },
                                                [
                                                  _c("td", [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: item.ischecked,
                                                          expression:
                                                            "item.ischecked",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "checkbox",
                                                        id:
                                                          "ord_" +
                                                          item.goodsno +
                                                          "_" +
                                                          item.optioncode,
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          item.ischecked
                                                        )
                                                          ? _vm._i(
                                                              item.ischecked,
                                                              null
                                                            ) > -1
                                                          : item.ischecked,
                                                      },
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            var $$a =
                                                                item.ischecked,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    item,
                                                                    "ischecked",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    item,
                                                                    "ischecked",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                item,
                                                                "ischecked",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                          function ($event) {
                                                            return _vm.checkGoodsList(
                                                              "cpnordgoodsList"
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    }),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(_vm._s(index + 1)),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(item.ispbgoodsname)
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(item.dealername)
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(item.goodscode)
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(item.optioncode)
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "img-thumb size60",
                                                        class: {
                                                          "no-image":
                                                            _vm.$util.isNull(
                                                              item.fullpath
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        !_vm.$util.isNull(
                                                          item.fullpath
                                                        )
                                                          ? _c("img", {
                                                              attrs: {
                                                                src: item.fullpath,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "left no-left",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "small-txt",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.fullcategoryname
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        { staticClass: "mg0" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.goodsname
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "white-space":
                                                          "pre-wrap",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.optionconts)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    { staticClass: "right" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$util.maskComma(
                                                            item.price
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _c("tbody", [_vm._m(40)]),
                                  ]
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.$util.isNull(_vm.info.comcpntype) &&
                      !_vm.$util.isNull(_vm.info.cpnissuetype),
                    expression:
                      "!$util.isNull(info.comcpntype) && !$util.isNull(info.cpnissuetype)",
                  },
                ],
                staticClass: "bar-title",
              },
              [_vm._v(" 발급 조건 ")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.$util.isNull(_vm.info.comcpntype) &&
                      !_vm.$util.isNull(_vm.info.cpnissuetype),
                    expression:
                      "!$util.isNull(info.comcpntype) && !$util.isNull(info.cpnissuetype)",
                  },
                ],
                staticClass: "form-area",
              },
              [
                _c(
                  "table",
                  {
                    staticClass: "gray-tb",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _vm._m(41),
                    _c("tbody", [
                      _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.info.cpnissuetype ==
                                _vm.$store.getters["ADMIN"].CPN_ISSUE_REGULAR,
                              expression:
                                "info.cpnissuetype == $store.getters['ADMIN'].CPN_ISSUE_REGULAR",
                            },
                          ],
                        },
                        [
                          _vm._m(42),
                          _c("td", [
                            _c("div", { staticClass: "radio_wrap wide dpib" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.info.iseveryday,
                                    expression: "info.iseveryday",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "iseverydayD",
                                  id: "iseverydayDT",
                                  value: "T",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.info.iseveryday, "T"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(_vm.info, "iseveryday", "T")
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "iseverydayDT" } }, [
                                _vm._v("발급 후"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.info.iseveryday,
                                    expression: "info.iseveryday",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "iseverydayD",
                                  id: "iseverydayDF",
                                  value: "F",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.info.iseveryday, "F"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(_vm.info, "iseveryday", "F")
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "iseverydayDF" } }, [
                                _vm._v("매월"),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "dpib" },
                              [
                                _vm.info.iseveryday == "F"
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.info.everymonthloop,
                                          expression: "info.everymonthloop",
                                        },
                                      ],
                                      staticClass: "center",
                                      staticStyle: { width: "60px" },
                                      attrs: {
                                        type: "text",
                                        maxlength: "11",
                                        oninput:
                                          "this.value = this.value.replace(/(^0|[^0-9])/gi, '');",
                                      },
                                      domProps: {
                                        value: _vm.info.everymonthloop,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.info,
                                            "everymonthloop",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    })
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.info.everydayloop,
                                          expression: "info.everydayloop",
                                        },
                                      ],
                                      staticClass: "center",
                                      staticStyle: { width: "60px" },
                                      attrs: {
                                        type: "text",
                                        maxlength: "11",
                                        oninput:
                                          "this.value = this.value.replace(/(^0|[^0-9])/gi, '');",
                                      },
                                      domProps: {
                                        value: _vm.info.everydayloop,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.info,
                                            "everydayloop",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                _c("span", { staticClass: "ml3" }, [
                                  _vm._v("일마다 재발급"),
                                ]),
                                _c("DatePicker", {
                                  staticClass: "com-time ml10",
                                  staticStyle: { width: "70px" },
                                  attrs: {
                                    type: "time",
                                    format: "HH시",
                                    "value-type": "format",
                                  },
                                  on: { change: _vm.changeEveryHHMM },
                                  model: {
                                    value: _vm.info.everyhh,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.info, "everyhh", $$v)
                                    },
                                    expression: "info.everyhh",
                                  },
                                }),
                                _c("DatePicker", {
                                  staticClass: "com-time ml3",
                                  staticStyle: { width: "70px" },
                                  attrs: {
                                    type: "time",
                                    format: "mm분",
                                    "value-type": "format",
                                  },
                                  on: { change: _vm.changeEveryHHMM },
                                  model: {
                                    value: _vm.info.everymm,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.info, "everymm", $$v)
                                    },
                                    expression: "info.everymm",
                                  },
                                }),
                                _c("span", [_vm._v("에 발급")]),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.info.cpnissuetype ==
                                  _vm.$store.getters["ADMIN"]
                                    .CPN_ISSUE_NOW_DISCOUNT ||
                                (_vm.info.cpnissuetype ==
                                  _vm.$store.getters["ADMIN"]
                                    .CPN_ISSUE_REGULAR &&
                                  _vm.info.iseveryday == "T"),
                              expression:
                                "\n                info.cpnissuetype == $store.getters['ADMIN'].CPN_ISSUE_NOW_DISCOUNT ||\n                (info.cpnissuetype == $store.getters['ADMIN'].CPN_ISSUE_REGULAR &&\n                  info.iseveryday == 'T')\n              ",
                            },
                          ],
                        },
                        [
                          _vm._m(43),
                          _c("td", [
                            _c("div", { staticClass: "radio_wrap dpib" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.info.isnowissue,
                                    expression: "info.isnowissue",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "isnowissueD",
                                  id: "isnowissueDT",
                                  value: "T",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.info.isnowissue, "T"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(_vm.info, "isnowissue", "T")
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "isnowissueDT" } }, [
                                _vm._v("즉시발급"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.info.isnowissue,
                                    expression: "info.isnowissue",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "isnowissueD",
                                  id: "isnowissueDF",
                                  value: "F",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.info.isnowissue, "F"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(_vm.info, "isnowissue", "F")
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "isnowissueDF" } }, [
                                _vm._v("지정시점에 발급"),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.info.isnowissue == "F",
                                    expression: "info.isnowissue == 'F'",
                                  },
                                ],
                                staticClass: "dpib ml3",
                              },
                              [
                                _c("CommonDatePickerFromTo", {
                                  attrs: {
                                    fromYYYYMMDD: _vm.info.cpnissuedate,
                                    fromHH: _vm.info.cpnissuehour,
                                    fromMM: _vm.info.cpnissuemin,
                                    useFrom: true,
                                    useTo: false,
                                  },
                                  on: {
                                    getDate: _vm.pickerCpnissueChangeEvent,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.info.cpnissuetype ==
                                _vm.$store.getters["ADMIN"].CPN_ISSUE_NEW_JOIN,
                              expression:
                                "info.cpnissuetype == $store.getters['ADMIN'].CPN_ISSUE_NEW_JOIN",
                            },
                          ],
                        },
                        [_vm._m(44), _vm._m(45)]
                      ),
                      _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.info.cpnissuetype ==
                                _vm.$store.getters["ADMIN"].CPN_ISSUE_BIRTHDAY,
                              expression:
                                "info.cpnissuetype == $store.getters['ADMIN'].CPN_ISSUE_BIRTHDAY",
                            },
                          ],
                        },
                        [
                          _vm._m(46),
                          _c("td", [
                            _c("div", { staticClass: "radio_wrap wide dpib" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.info.isbirththeday,
                                    expression: "info.isbirththeday",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "isbirththedayD",
                                  id: "isbirththedayDT",
                                  value: "T",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.info.isbirththeday, "T"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.info,
                                      "isbirththeday",
                                      "T"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: "isbirththedayDT" } },
                                [_vm._v("생일당일")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.info.isbirththeday,
                                    expression: "info.isbirththeday",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "isbirththedayD",
                                  id: "isbirththedayDF",
                                  value: "F",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.info.isbirththeday, "F"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.info,
                                      "isbirththeday",
                                      "F"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: "isbirththedayDF" } },
                                [_vm._v("생일")]
                              ),
                            ]),
                            _vm.info.isbirththeday == "F"
                              ? _c("div", { staticClass: "dpib ml3" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.info.birthbfdaycnt,
                                        expression: "info.birthbfdaycnt",
                                      },
                                    ],
                                    staticClass: "right",
                                    staticStyle: { width: "80px" },
                                    attrs: {
                                      type: "text",
                                      maxlength: "11",
                                      oninput:
                                        "this.value = this.value.replace(/(^0|[^0-9])/gi, '');",
                                    },
                                    domProps: { value: _vm.info.birthbfdaycnt },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.info,
                                          "birthbfdaycnt",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c("span", { staticClass: "ml3" }, [
                                    _vm._v("일전"),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.info.cpnissuetype ==
                                _vm.$store.getters["ADMIN"]
                                  .CPN_ISSUE_PURCH_CONFIRM,
                              expression:
                                "info.cpnissuetype == $store.getters['ADMIN'].CPN_ISSUE_PURCH_CONFIRM",
                            },
                          ],
                        },
                        [_vm._m(47), _vm._m(48)]
                      ),
                      _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.info.cpnissuetype ==
                                _vm.$store.getters["ADMIN"].CPN_ISSUE_MEM_PROMO,
                              expression:
                                "info.cpnissuetype == $store.getters['ADMIN'].CPN_ISSUE_MEM_PROMO",
                            },
                          ],
                        },
                        [_vm._m(49), _vm._m(50)]
                      ),
                      _c("tr", [
                        _vm._m(51),
                        _c("td", [
                          _c("div", { staticClass: "radio_wrap wide dpib" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.info.isautopay,
                                  expression: "info.isautopay",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                name: "isautopayD",
                                id: "isautopayDT",
                                value: "T",
                              },
                              domProps: {
                                checked: _vm._q(_vm.info.isautopay, "T"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.info, "isautopay", "T")
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "isautopayDT" } }, [
                              _vm._v("자동적용"),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.info.isautopay,
                                  expression: "info.isautopay",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                name: "isautopayD",
                                id: "isautopayDF",
                                value: "F",
                              },
                              domProps: {
                                checked: _vm._q(_vm.info.isautopay, "F"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.info, "isautopay", "F")
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "isautopayDF" } }, [
                              _vm._v("다운로드"),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm.info.comcpntype ===
                        _vm.$store.getters["ADMIN"].COM_CPN_GOODS &&
                      _vm.info.cpnissuetype ===
                        _vm.$store.getters["ADMIN"].CPN_ISSUE_NOW_DISCOUNT &&
                      _vm.info.isautopay == "T"
                        ? _c("tr", [_vm._m(52), _vm._m(53)])
                        : _vm._e(),
                      _vm.info.isautopay == "F"
                        ? _c("tr", [
                            _vm._m(54),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "radio_wrap wide dpib" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.info.iscntlimt,
                                        expression: "info.iscntlimt",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "iscntlimtD",
                                      id: "iscntlimtDF",
                                      value: "F",
                                    },
                                    domProps: {
                                      checked: _vm._q(_vm.info.iscntlimt, "F"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.info,
                                          "iscntlimt",
                                          "F"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "iscntlimtDF" } },
                                    [_vm._v("제한없음")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.info.iscntlimt,
                                        expression: "info.iscntlimt",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "iscntlimtD",
                                      id: "iscntlimtDT",
                                      value: "T",
                                    },
                                    domProps: {
                                      checked: _vm._q(_vm.info.iscntlimt, "T"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.info,
                                          "iscntlimt",
                                          "T"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "iscntlimtDT" } },
                                    [_vm._v("제한")]
                                  ),
                                ]
                              ),
                              _vm.info.iscntlimt == "T"
                                ? _c("div", { staticClass: "dpib ml3" }, [
                                    _c("span", [_vm._v("선착순 최대")]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.info.cpnlimtcnt,
                                          expression: "info.cpnlimtcnt",
                                        },
                                      ],
                                      staticClass: "right ml3",
                                      staticStyle: { width: "80px" },
                                      attrs: {
                                        type: "text",
                                        maxlength: "11",
                                        oninput:
                                          "this.value = this.value.replace(/(^0|[^0-9])/gi, '');",
                                      },
                                      domProps: { value: _vm.info.cpnlimtcnt },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.info,
                                            "cpnlimtcnt",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c("span", { staticClass: "ml3" }, [
                                      _vm._v("장"),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.info.isautopay == "F"
                        ? _c("tr", [
                            _vm._m(55),
                            _c("td", [
                              _vm.info.isdupperson == "T"
                                ? _c("div", { staticClass: "dpib ml3" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.info.dupcnt,
                                          expression: "info.dupcnt",
                                        },
                                      ],
                                      staticClass: "right",
                                      staticStyle: { width: "80px" },
                                      attrs: {
                                        type: "text",
                                        maxlength: "11",
                                        oninput:
                                          "this.value = this.value.replace(/(^0|[^0-9])/gi, '');",
                                      },
                                      domProps: { value: _vm.info.dupcnt },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.info,
                                            "dupcnt",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c("span", { staticClass: "ml3" }, [
                                      _vm._v("회까지 다운 가능"),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.save },
                },
                [_vm._v("저장")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("취소")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [
      _vm._v(" 사용여부 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [
      _vm._v(" 쿠폰종류 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [
      _vm._v(" 발급종류 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [_vm._v(" 쿠폰명 "), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 대상범위 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 대상회원유형 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 대상회원등급 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 대상회원 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "total-group fl" }, [
      _c("span", { staticClass: "total" }, [_vm._v("발급대상 회원목록")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [
      _c("td", { attrs: { colspan: "7" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 수신동의상태제한 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 대상카테고리범위 "),
      _c("i", { staticClass: "essential" }),
      _c("br"),
      _vm._v(" (쿠폰적용대상) "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 특정 카테고리 "),
      _c("i", { staticClass: "essential" }),
      _c("br"),
      _vm._v(" (쿠폰적용대상) "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 대상 상품범위 "),
      _c("i", { staticClass: "essential" }),
      _c("br"),
      _vm._v(" (쿠폰적용대상) "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 대상 상품 "),
      _c("i", { staticClass: "essential" }),
      _c("br"),
      _vm._v(" (쿠폰적용대상) "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "total-group fl" }, [
      _c("span", { staticClass: "total" }, [_vm._v("적용대상 상품목록")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "5.5%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "7%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [
      _c("td", { attrs: { colspan: "10" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 쿠폰비용 분담비율 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 정기발급기간 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 사용기간 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 사용기간 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 사용채널 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 무료배송 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 사용가능기준금액 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 사용가능기준금액 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "txt-orange ml10" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(" 할인 프로모션(즉시할인)이 적용된 상품금액 기준 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 할인 정액/정률 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [_vm._v(" 구분 "), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 대상카테고리범위 "),
      _c("i", { staticClass: "essential" }),
      _c("br"),
      _vm._v(" (구매확정대상) "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 특정 카테고리 "),
      _c("i", { staticClass: "essential" }),
      _c("br"),
      _vm._v(" (구매확정대상) "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 대상 상품범위 "),
      _c("i", { staticClass: "essential" }),
      _c("br"),
      _vm._v(" (구매확정대상) "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 대상 상품 "),
      _c("i", { staticClass: "essential" }),
      _c("br"),
      _vm._v(" (구매확정대상) "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "total-group fl" }, [
      _c("span", { staticClass: "total" }, [_vm._v("적용대상 상품목록")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "5.5%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "7%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [
      _c("td", { attrs: { colspan: "10" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 재발급주기 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 발급시점 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 발급시점 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("span", [_vm._v("신규 회원가입 완료 후 즉시 발급됩니다.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 발급시점 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 발급시점 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("span", [_vm._v("구매확정조건 만족 시 즉시 발급됩니다.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 발급시점 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("span", [
        _vm._v(
          " 추천 리워드(쿠폰) 신규 등록 시, 시리얼 프로모션(쿠폰) 등록 시 발급됩니다. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 발급방법 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 쿠폰수량제한 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("span", [
        _vm._v("상품 즉시할인 쿠폰의 자동적용은 수량을 제한하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 쿠폰수량제한 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 계정당 발급 제한 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }