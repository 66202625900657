var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "700px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("현금영수증 수기 발급")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.closePopup },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c(
              "table",
              {
                staticClass: "gray-tb",
                attrs: { cellpadding: "0", cellspacing: "0" },
              },
              [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("현금영수증 타입")]),
                    _c("td", [
                      _c("div", { staticClass: "radio_wrap" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cashReceiptType,
                              expression: "cashReceiptType",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "cashReceiptType1",
                            value: "소득공제",
                          },
                          domProps: {
                            checked: _vm._q(_vm.cashReceiptType, "소득공제"),
                          },
                          on: {
                            change: function ($event) {
                              _vm.cashReceiptType = "소득공제"
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "cashReceiptType1" } }, [
                          _vm._v("소득공제"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cashReceiptType,
                              expression: "cashReceiptType",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "cashReceiptType2",
                            value: "지출증빙",
                          },
                          domProps: {
                            checked: _vm._q(_vm.cashReceiptType, "지출증빙"),
                          },
                          on: {
                            change: function ($event) {
                              _vm.cashReceiptType = "지출증빙"
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "cashReceiptType2" } }, [
                          _vm._v("지출증빙"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("현금영수증 기준 정보")]),
                    _c("td", [
                      _vm.isCashReceiptTypeEarn
                        ? _c(
                            "div",
                            {
                              staticClass: "radio_wrap",
                              staticStyle: { display: "inline-block" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.cashReceiptValueType,
                                    expression: "cashReceiptValueType",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "cashReceiptValueType1",
                                  value: "cashReceiptValueType1",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.cashReceiptValueType,
                                    "cashReceiptValueType1"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.cashReceiptValueType =
                                      "cashReceiptValueType1"
                                  },
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: "cashReceiptValueType1" } },
                                [_vm._v("전화번호")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.cashReceiptValueType,
                                    expression: "cashReceiptValueType",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "cashReceiptValueType2",
                                  value: "cashReceiptValueType2",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.cashReceiptValueType,
                                    "cashReceiptValueType2"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.cashReceiptValueType =
                                      "cashReceiptValueType2"
                                  },
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: "cashReceiptValueType2" } },
                                [_vm._v("현금영수증카드번호")]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v("  "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.ordtel,
                            expression: "info.ordtel",
                          },
                        ],
                        staticStyle: {
                          width: "137px",
                          display: "inline-block",
                        },
                        attrs: { type: "text", maxlength: "20" },
                        domProps: { value: _vm.info.ordtel },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.info, "ordtel", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.save },
                },
                [_vm._v("저장")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.closePopup },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "120px" } }),
      _c("col", { attrs: { width: "420px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }