var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1200px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("공지사항 상세")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "boxing" }, [
              _c("div", { staticClass: "form-area" }, [
                _vm.isAdmin
                  ? _c("dl", [
                      _c("dt", [_vm._v("작성자")]),
                      _c("dd", [
                        _c("p", [_vm._v(_vm._s(_vm.boardInfo.writer))]),
                      ]),
                    ])
                  : _vm._e(),
                _c("dl", [
                  _c("dt", [_vm._v("등록일")]),
                  _c("dd", [_c("p", [_vm._v(_vm._s(_vm.boardInfo.regdate))])]),
                ]),
                _vm.isAdmin
                  ? _c("dl", [
                      _c("dt", [_vm._v("필독공지")]),
                      _c("dd", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.boardInfo.isessnotice,
                              expression: "boardInfo.isessnotice",
                            },
                          ],
                          attrs: { type: "checkbox", id: "chk01" },
                          domProps: {
                            checked: Array.isArray(_vm.boardInfo.isessnotice)
                              ? _vm._i(_vm.boardInfo.isessnotice, null) > -1
                              : _vm.boardInfo.isessnotice,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.boardInfo.isessnotice,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.boardInfo,
                                      "isessnotice",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.boardInfo,
                                      "isessnotice",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.boardInfo, "isessnotice", $$c)
                              }
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "chk01" } }, [
                          _vm._v("필독 공지글로 등록"),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.isAdmin
                  ? _c(
                      "dl",
                      [
                        _c("dt", [_vm._v("팝업공지")]),
                        _c("dd", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.ispapopnotice,
                                expression: "boardInfo.ispapopnotice",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "chk02",
                              value: "T",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.boardInfo.ispapopnotice
                              )
                                ? _vm._i(_vm.boardInfo.ispapopnotice, "T") > -1
                                : _vm.boardInfo.ispapopnotice,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.boardInfo.ispapopnotice,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "T",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.boardInfo,
                                        "ispapopnotice",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.boardInfo,
                                        "ispapopnotice",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.boardInfo, "ispapopnotice", $$c)
                                }
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "chk02" } }, [
                            _vm._v("파트너사 팝업 공지로 등록"),
                          ]),
                        ]),
                        _c("CommonDatePickerFromTo", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.boardInfo.ispapopnotice,
                              expression: "boardInfo.ispapopnotice",
                            },
                          ],
                          attrs: {
                            fromYYYYMMDD: _vm.timeInfo.startYYYYMMDD,
                            fromHH: _vm.timeInfo.startHH,
                            fromMM: _vm.timeInfo.startMM,
                            toYYYYMMDD: _vm.timeInfo.toYYYYMMDD,
                            toHH: _vm.timeInfo.toHH,
                            toMM: _vm.timeInfo.toMM,
                          },
                          on: { getDate: _vm.getTimeDate },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isAdmin
                  ? _c("dl", [
                      _c("dt", [_vm._v("사용여부")]),
                      _c("dd", [
                        _c("div", { staticClass: "radio_wrap wide" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.istrash,
                                expression: "boardInfo.istrash",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "sms",
                              id: "rd11",
                              value: "F",
                              checked: "",
                            },
                            domProps: {
                              checked: _vm._q(_vm.boardInfo.istrash, "F"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.boardInfo, "istrash", "F")
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "rd11" } }, [
                            _vm._v("사용"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.istrash,
                                expression: "boardInfo.istrash",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "sms",
                              id: "rd12",
                              value: "T",
                            },
                            domProps: {
                              checked: _vm._q(_vm.boardInfo.istrash, "T"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.boardInfo, "istrash", "T")
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "rd12" } }, [
                            _vm._v("미사용"),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("dl", [
                  _c("dt", [_vm._v("제목")]),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.boardInfo.subject,
                          expression: "boardInfo.subject",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", disabled: !_vm.isAdmin },
                      domProps: { value: _vm.boardInfo.subject },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.boardInfo,
                            "subject",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "boxing", staticStyle: { border: "0" } },
              [
                _c("CommonEditor", {
                  ref: "editor",
                  attrs: {
                    "style-object": { height: 300 + "px" },
                    disable: !_vm.isAdmin,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "boxing" }, [
              _c("div", { staticClass: "form-area" }, [
                _c("dl", [
                  _c("dt", [_vm._v("링크 URL 1")]),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.boardInfo.linkurl1,
                          expression: "boardInfo.linkurl1",
                        },
                      ],
                      staticStyle: { width: "calc(100% - 72px)" },
                      attrs: { type: "text", disabled: !_vm.isAdmin },
                      domProps: { value: _vm.boardInfo.linkurl1 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.boardInfo,
                            "linkurl1",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "btn blue-line",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.goLink(_vm.boardInfo.linkurl1)
                          },
                        },
                      },
                      [_vm._v(" 링크이동 ")]
                    ),
                  ]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("링크 URL 2")]),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.boardInfo.linkurl2,
                          expression: "boardInfo.linkurl2",
                        },
                      ],
                      staticStyle: { width: "calc(100% - 72px)" },
                      attrs: { type: "text", disabled: !_vm.isAdmin },
                      domProps: { value: _vm.boardInfo.linkurl2 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.boardInfo,
                            "linkurl2",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "btn blue-line",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.goLink(_vm.boardInfo.linkurl2)
                          },
                        },
                      },
                      [_vm._v(" 링크이동 ")]
                    ),
                  ]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("첨부파일")]),
                  _c("dd", [
                    _vm.isAdmin
                      ? _c(
                          "label",
                          {
                            staticClass: "btn blue-line",
                            attrs: { for: "input-file" },
                          },
                          [_vm._v("파일첨부")]
                        )
                      : _vm._e(),
                    _c("input", {
                      ref: "inputFile",
                      staticStyle: { display: "none" },
                      attrs: {
                        type: "file",
                        id: "input-file",
                        accept: "image/*",
                        multiple: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.selectFile($event.target)
                        },
                      },
                    }),
                    _c(
                      "div",
                      [
                        _vm._l(_vm.boardInfo.uploadedfile, function (row, i) {
                          return _c("div", { key: (i + 1) * -1 }, [
                            _c(
                              "a",
                              {
                                staticClass: "file-link",
                                on: {
                                  click: function ($event) {
                                    return _vm.imageView(row.fullpath)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.imgforiname))]
                            ),
                            _vm.isAdmin
                              ? _c("button", {
                                  staticClass: "file-del",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteUploadFile(i, "N")
                                    },
                                  },
                                })
                              : _vm._e(),
                          ])
                        }),
                        _vm._l(_vm.addUploadFile, function (row, i) {
                          return _c("div", { key: i }, [
                            _c(
                              "label",
                              {
                                staticClass: "file-link",
                                staticStyle: {
                                  "text-decoration": "none !important",
                                  color: "#666",
                                },
                              },
                              [_vm._v(" " + _vm._s(row.file.name) + " ")]
                            ),
                            _vm.isAdmin
                              ? _c("button", {
                                  staticClass: "file-del",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteUploadFile(i, "Y")
                                    },
                                  },
                                })
                              : _vm._e(),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "btn-group" }, [
              _vm.isWrite && _vm.isAdmin
                ? _c(
                    "button",
                    {
                      staticClass: "btn big blue",
                      attrs: { type: "button" },
                      on: { click: _vm.goSave },
                    },
                    [_vm._v(" 저장 ")]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }