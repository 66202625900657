var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content m-leftmenu" },
    [
      _vm.isDetailShow
        ? _c("ProductDetail", {
            ref: "refDetail",
            attrs: { idx: _vm.isDetailIdx },
          })
        : _vm._e(),
      _vm.isCommonSmsShow
        ? _c("CommonSms", {
            attrs: { "user-no": _vm.smsUserNo },
            on: { close: _vm.goCloseSms },
          })
        : _vm._e(),
      _vm.isCommonMailShow
        ? _c("CommonMail", {
            attrs: { "user-no": _vm.mailUserNo },
            on: { "close-popup": _vm.goCloseMail },
          })
        : _vm._e(),
      _vm.isMemberDetailShow
        ? _c("AdminMemberInfo", {
            staticStyle: { "z-index": "1005" },
            attrs: { activeUserNo: _vm.memberDetailUserNo },
            on: { closeDetail: _vm.closeMemberInfoPopup },
          })
        : _vm._e(),
      _c("AdminCommonNavigator"),
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "boxing search-area" }, [
          _c("dl", [
            _c("dt", [_vm._v("직접검색")]),
            _c("dd", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.skey,
                      expression: "searchData.skey",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "skey",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _vm.searchData.isAdmin
                    ? _c("option", { attrs: { value: "userid" } }, [
                        _vm._v("아이디"),
                      ])
                    : _vm._e(),
                  _vm.searchData.isAdmin
                    ? _c("option", { attrs: { value: "username" } }, [
                        _vm._v("고객명"),
                      ])
                    : _vm._e(),
                  _c("option", { attrs: { value: "productname" } }, [
                    _vm._v("상품명"),
                  ]),
                  _c("option", { attrs: { value: "repusername" } }, [
                    _vm._v("답변자"),
                  ]),
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.sword,
                    expression: "searchData.sword",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.searchData.sword },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.onSearch(1)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "sword", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("조회기간")]),
            _c(
              "dd",
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.sdate,
                        expression: "searchData.sdate",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "sdate",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "start" } }, [
                      _vm._v("문의일자"),
                    ]),
                    _c("option", { attrs: { value: "end" } }, [
                      _vm._v("답변일자"),
                    ]),
                  ]
                ),
                _c("CommonDatePicker", {
                  attrs: { value: _vm.searchData.startDate },
                  on: { change: _vm.onChangeStartDate },
                }),
                _c("span", [_vm._v("-")]),
                _c("CommonDatePicker", {
                  attrs: { value: _vm.searchData.endDate },
                  on: { change: _vm.onChangeEndDate },
                }),
                _c("div", { staticClass: "radio_wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd1", value: "-1" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "-1") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "-1")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd1" } }, [_vm._v("어제")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd2", value: "0" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "0") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "0")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd2" } }, [_vm._v("오늘")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd3", value: "7" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "7") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "7")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd3" } }, [_vm._v("일주일")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd4", value: "1" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "1") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "1")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd4" } }, [_vm._v("1개월")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd5", value: "3" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "3") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "3")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd5" } }, [_vm._v("3개월")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd6", value: "6" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "6") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "6")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd6" } }, [_vm._v("6개월")]),
                ]),
              ],
              1
            ),
          ]),
          _vm.searchData.isAdmin
            ? _c("dl", [
                _c("dt", [_vm._v("판매사구분")]),
                _c("dd", [
                  _c("div", { staticClass: "radio_wrap" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.ispbgoods,
                          expression: "searchData.ispbgoods",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "rad",
                        id: "rd11",
                        value: "",
                        checked: "",
                      },
                      domProps: {
                        checked: _vm._q(_vm.searchData.ispbgoods, ""),
                      },
                      on: {
                        click: function ($event) {
                          _vm.searchData.dealerno = ""
                        },
                        change: function ($event) {
                          return _vm.$set(_vm.searchData, "ispbgoods", "")
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "rd11" } }, [_vm._v("전체")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.ispbgoods,
                          expression: "searchData.ispbgoods",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "rad",
                        id: "rd12",
                        value: "T",
                      },
                      domProps: {
                        checked: _vm._q(_vm.searchData.ispbgoods, "T"),
                      },
                      on: {
                        click: function ($event) {
                          _vm.searchData.dealerno = ""
                        },
                        change: function ($event) {
                          return _vm.$set(_vm.searchData, "ispbgoods", "T")
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "rd12" } }, [_vm._v("직매입")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.ispbgoods,
                          expression: "searchData.ispbgoods",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "rad",
                        id: "rd13",
                        value: "F",
                      },
                      domProps: {
                        checked: _vm._q(_vm.searchData.ispbgoods, "F"),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.searchData, "ispbgoods", "F")
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "rd13" } }, [
                      _vm._v("파트너사"),
                    ]),
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.dealerno,
                          expression: "searchData.dealerno",
                        },
                      ],
                      attrs: { disabled: _vm.searchData.ispbgoods !== "F" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "dealerno",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("파트너사 선택"),
                      ]),
                      _vm._l(this.partnersList, function (row, i) {
                        return _c(
                          "option",
                          { key: i, domProps: { value: row.no } },
                          [_vm._v(" " + _vm._s(row.name) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.searchData.isAdmin
            ? _c("dl", [
                _c("dt", [_vm._v("문의유형")]),
                _c("dd", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.qnatype,
                          expression: "searchData.qnatype",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "qnatype",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("전체")]),
                      _vm._l(_vm.commonCode.qnatype, function (item, i) {
                        return _c(
                          "option",
                          {
                            key: "qnatype_" + i,
                            domProps: { value: item.cmcode },
                          },
                          [_vm._v(" " + _vm._s(item.codename) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ])
            : _vm._e(),
          _c("dl", [
            _c("dt", [_vm._v("상태")]),
            _c("dd", [
              _c("div", { staticClass: "radio_wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.isreply,
                      expression: "searchData.isreply",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "status",
                    id: "rd01",
                    value: "",
                    checked: "",
                  },
                  domProps: { checked: _vm._q(_vm.searchData.isreply, "") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.searchData, "isreply", "")
                    },
                  },
                }),
                _c("label", { attrs: { for: "rd01" } }, [_vm._v("전체")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.isreply,
                      expression: "searchData.isreply",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "status",
                    id: "rd02",
                    value: "F",
                  },
                  domProps: { checked: _vm._q(_vm.searchData.isreply, "F") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.searchData, "isreply", "F")
                    },
                  },
                }),
                _c("label", { attrs: { for: "rd02" } }, [_vm._v("대기")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.isreply,
                      expression: "searchData.isreply",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "status",
                    id: "rd03",
                    value: "T",
                  },
                  domProps: { checked: _vm._q(_vm.searchData.isreply, "T") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.searchData, "isreply", "T")
                    },
                  },
                }),
                _c("label", { attrs: { for: "rd03" } }, [_vm._v("완료")]),
              ]),
            ]),
          ]),
        ]),
        _vm.isRead
          ? _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.onSearch(1)
                    },
                  },
                },
                [_vm._v("검색")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big gray",
                  attrs: { type: "button" },
                  on: { click: _vm.onSearchDataReset },
                },
                [_vm._v("초기화")]
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "caption-group mt10 clearfix" }, [
          _c("div", { staticClass: "total-group fl" }, [
            _c("span", { staticClass: "total" }, [
              _vm._v(" 전체 "),
              _c("strong", [_vm._v(_vm._s(_vm.stateData.total))]),
              _vm._v(" 건 "),
            ]),
            _vm.searchData.isAdmin
              ? _c("span", [
                  _vm._v(" 대기 "),
                  _c("strong", [_vm._v(_vm._s(_vm.stateData.stay))]),
                  _vm._v(" 건 "),
                ])
              : _vm._e(),
            _vm.searchData.isAdmin
              ? _c("span", [
                  _vm._v(" 완료 "),
                  _c("strong", [_vm._v(_vm._s(_vm.stateData.comp))]),
                  _vm._v(" 건 "),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "btn-group fr" }, [
            _vm.isRead
              ? _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pagingData.pageCount,
                        expression: "pagingData.pageCount",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.pagingData,
                          "pageCount",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "20" } }, [
                      _vm._v("20개씩 보기"),
                    ]),
                    _c("option", { attrs: { value: "50" } }, [
                      _vm._v("50개씩 보기"),
                    ]),
                    _c("option", { attrs: { value: "100" } }, [
                      _vm._v("100개씩 보기"),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
        _c(
          "table",
          {
            staticClass: "data-tb align-c",
            attrs: { cellpadding: "0", cellspacing: "0" },
          },
          [
            _c("caption", [_vm._v("상품 문의 목록")]),
            _c("colgroup", [
              _c("col", { attrs: { width: "3%" } }),
              _vm.searchData.isAdmin
                ? _c("col", { attrs: { width: "5%" } })
                : _vm._e(),
              _vm.searchData.isAdmin
                ? _c("col", { attrs: { width: "5%" } })
                : _vm._e(),
              _c("col", { attrs: { width: "5%" } }),
              _vm.searchData.isAdmin
                ? _c("col", { attrs: { width: "5%" } })
                : _vm._e(),
              _vm.searchData.isAdmin
                ? _c("col", { attrs: { width: "7%" } })
                : _vm._e(),
              _vm.searchData.isAdmin
                ? _c("col", { attrs: { width: "7%" } })
                : _vm._e(),
              _vm.searchData.isAdmin
                ? _c("col", { attrs: { width: "7%" } })
                : _vm._e(),
              _c("col", { attrs: { width: "" } }),
              _c("col", { attrs: { width: "" } }),
              _c("col", { attrs: { width: "8%" } }),
              _c("col", {
                attrs: { width: _vm.searchData.isAdmin ? "4%" : "8%" },
              }),
              _c("col", { attrs: { width: "8%" } }),
              _vm.searchData.isAdmin
                ? _c("col", { attrs: { width: "5%" } })
                : _vm._e(),
              _c("col", { attrs: { width: "5%" } }),
            ]),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("No")]),
                _vm.searchData.isAdmin
                  ? _c("th", [_vm._v("판매구분")])
                  : _vm._e(),
                _vm.searchData.isAdmin
                  ? _c("th", [
                      _vm._v(" 파트너사명 "),
                      _vm.isRead
                        ? _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up:
                                  _vm.sortData.dealername === "dealername_asc",
                              },
                              {
                                down:
                                  _vm.sortData.dealername === "dealername_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.dealername,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.dealername)
                              },
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c("th", [
                  _vm._v(" 문의구분 "),
                  _vm.isRead
                    ? _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.qnatype === "qnatype_asc" },
                          { down: _vm.sortData.qnatype === "qnatype_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.qnatype },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.qnatype)
                          },
                        },
                      })
                    : _vm._e(),
                ]),
                _vm.searchData.isAdmin
                  ? _c("th", [
                      _vm._v(" 아이디 "),
                      _vm.isRead
                        ? _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.userid === "userid_asc" },
                              { down: _vm.sortData.userid === "userid_desc" },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.userid,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.userid)
                              },
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.searchData.isAdmin
                  ? _c("th", [
                      _vm._v(" 고객명 "),
                      _vm.isRead
                        ? _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.username === "username_asc" },
                              {
                                down: _vm.sortData.username === "username_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.username,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.username)
                              },
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.searchData.isAdmin
                  ? _c("th", [
                      _vm._v(" 휴대폰번호 "),
                      _vm.isRead
                        ? _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.mobile === "mobile_asc" },
                              { down: _vm.sortData.mobile === "mobile_desc" },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.mobile,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.mobile)
                              },
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.searchData.isAdmin
                  ? _c("th", [
                      _vm._v(" 이메일 "),
                      _vm.isRead
                        ? _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.email === "email_asc" },
                              { down: _vm.sortData.email === "email_desc" },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.email,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.email)
                              },
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c("th", [
                  _vm._v(" 상품명 "),
                  _vm.isRead
                    ? _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.goodsname === "goodsname_asc" },
                          { down: _vm.sortData.goodsname === "goodsname_desc" },
                        ],
                        attrs: {
                          type: "button",
                          value: _vm.sortData.goodsname,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.goodsname)
                          },
                        },
                      })
                    : _vm._e(),
                ]),
                _c("th", [
                  _vm._v(" 문의내용 "),
                  _vm.isRead
                    ? _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.content === "content_asc" },
                          { down: _vm.sortData.content === "content_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.content },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.content)
                          },
                        },
                      })
                    : _vm._e(),
                ]),
                _c("th", [
                  _vm._v(" 문의일시 "),
                  _vm.isRead
                    ? _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.regdate === "regdate_asc" },
                          { down: _vm.sortData.regdate === "regdate_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.regdate },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.regdate)
                          },
                        },
                      })
                    : _vm._e(),
                ]),
                _c("th", [
                  _vm._v(" 답변자 "),
                  _vm.isRead
                    ? _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.repuname === "repuname_asc" },
                          { down: _vm.sortData.repuname === "repuname_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.repuname },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.repuname)
                          },
                        },
                      })
                    : _vm._e(),
                ]),
                _c("th", [
                  _vm._v(" 답변일시 "),
                  _vm.isRead
                    ? _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.repregdate === "repregdate_asc" },
                          {
                            down: _vm.sortData.repregdate === "repregdate_desc",
                          },
                        ],
                        attrs: {
                          type: "button",
                          value: _vm.sortData.repregdate,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.repregdate)
                          },
                        },
                      })
                    : _vm._e(),
                ]),
                _vm.searchData.isAdmin
                  ? _c("th", [
                      _vm._v(" 공개여부 "),
                      _vm.isRead
                        ? _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.issecret === "issecret_asc" },
                              {
                                down: _vm.sortData.issecret === "issecret_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.issecret,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.issecret)
                              },
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c("th", [
                  _vm._v(" 답변상태 "),
                  _vm.isRead
                    ? _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.isreply === "isreply_asc" },
                          { down: _vm.sortData.isreply === "isreply_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.isreply },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.isreply)
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm.listData.length > 0
              ? _c(
                  "tbody",
                  _vm._l(_vm.listData, function (row, i) {
                    return _c("tr", { key: i }, [
                      _c("td", [_vm._v(_vm._s(row.rn))]),
                      _vm.searchData.isAdmin
                        ? _c("td", [_vm._v(_vm._s(row.ispbgoods))])
                        : _vm._e(),
                      _vm.searchData.isAdmin
                        ? _c("td", [_vm._v(_vm._s(row.dealername))])
                        : _vm._e(),
                      _c("td", [_vm._v(_vm._s(row.qnatype))]),
                      _vm.searchData.isAdmin
                        ? _c("td", [_vm._v(_vm._s(row.userid))])
                        : _vm._e(),
                      _vm.searchData.isAdmin
                        ? _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                on: {
                                  click: function ($event) {
                                    return _vm.goUserDtl(row.no)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.username))]
                            ),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goSms(row.no)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "icon-sms" })]
                            ),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goMail(row.no)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "icon-mail" })]
                            ),
                          ])
                        : _vm._e(),
                      _vm.searchData.isAdmin
                        ? _c("td", [_vm._v(_vm._s(row.mobile))])
                        : _vm._e(),
                      _vm.searchData.isAdmin
                        ? _c("td", [_vm._v(_vm._s(row.email))])
                        : _vm._e(),
                      _c("td", { staticClass: "left" }, [
                        _c(
                          "a",
                          {
                            staticClass: "link",
                            on: {
                              click: function ($event) {
                                return _vm.goFront(row.goodscode)
                              },
                            },
                          },
                          [
                            _vm._v(" " + _vm._s(row.goodsname) + " "),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$util.isNull(row.optionname)
                                    ? "옵션 : " + row.optionname
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("td", { staticClass: "left" }, [
                        _c(
                          "a",
                          {
                            staticClass: "link",
                            on: {
                              click: function ($event) {
                                return _vm.goDetail(row.idx)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.content))]
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(row.regdate))]),
                      _c("td", [_vm._v(_vm._s(row.repuname))]),
                      _c("td", [_vm._v(_vm._s(row.repregdate))]),
                      _vm.searchData.isAdmin
                        ? _c("td", [_vm._v(_vm._s(row.issecret))])
                        : _vm._e(),
                      row.isreply === "완료"
                        ? _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.mouseOver(i)
                                  },
                                  mouseout: function ($event) {
                                    return _vm.mouseOut(i)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(row.isreply) + " ")]
                            ),
                            _c(
                              "div",
                              {
                                class: [
                                  _vm.isHovering[i]
                                    ? "a-cell dpb"
                                    : "a-cell dpn",
                                ],
                              },
                              [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$util.showImageResize(row.repcontent)
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ])
                        : _c("td", [_vm._v(" " + _vm._s(row.isreply) + " ")]),
                    ])
                  }),
                  0
                )
              : _c("tbody", [
                  _c(
                    "td",
                    { attrs: { colspan: _vm.searchData.isAdmin ? 16 : 8 } },
                    [_vm._v("조회 결과가 존재하지 않습니다.")]
                  ),
                ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "bottom-group" },
          [
            _c("CommonPageNavigator", {
              attrs: { pagingData: _vm.pagingData },
              on: { setPagingData: _vm.setPagingData },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }