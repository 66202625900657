<template>
  <div id="modal-wrap" class="modal" style="display: block">
    <div class="modal-content" style="width: 600px">
      <div class="pop-header">
        <h2>접속기록보기</h2>
        <button type="button" class="pop-close" @click="$modal.hide('commonModal')"></button>
      </div>
      <div class="pop-body">
        <i class="essential"></i>
        접속횟수 : 총 {{ $util.maskComma(this.totalCnt) }}회
        <div class="scroll-y" style="max-height: 500px">
          <table cellpadding="0" cellspacing="0" class="data-tb align-c">
            <colgroup>
              <col width="10%" />
              <!-- No -->
              <col width="45%" />
              <!-- 접속일시 -->
              <col width="45%" />
              <!-- 접속IP -->
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>접속일시</th>
                <th>접속IP</th>
              </tr>
            </thead>
            <tbody v-if="listData.length > 0">
              <tr v-for="(row, index) in listData" v-bind:key="index">
                <td>{{ loopNumberForPaging(index) }}</td>
                <td>{{ row.regdate }}</td>
                <td>{{ row.ip }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4">접속 이력이 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bottom-group">
          <common-page-navigator
            v-bind:pagingData="pagingData"
            v-on:setPagingData="setPagingData"
          ></common-page-navigator>
        </div>
        <br />
        <div class="btn-group">
          <button type="button" class="btn darkgray" @click="$modal.hide('commonModal')">
            닫기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonPageNavigator from '@views.admin/common/CommonPageNavigator';

export default {
  name: 'CommonAccessHistoryPopup',
  components: {
    CommonPageNavigator,
  },
  props: {
    params: Object,
  },
  data() {
    return {
      listData: [],
      totalCnt: 0,
      pagingData: {
        pageCount: 20,
        page: 1,
        listCount: 0,
        isFirstSearch: true, // 최초 조회 여부 (최초 조회여부가 true인 경우 setPagingData에서 조회하지 않는다.)
      },
    };
  },
  methods: {
    loopNumberForPaging: function (index) {
      if (this.pagingData.page > 1) {
        let page = this.pagingData.page - 1;
        return index + 1 + page * this.pagingData.pageCount;
      } else {
        return index + 1;
      }
    },
    setPagingData: function (obj) {
      this.pagingData = obj;
      if (this.isFirstSearch == false) {
        this.onSearch();
      }
      if (this.isFirstSearch == true) {
        this.isFirstSearch = false;
      }
    },
    onSearch: function () {
      let param = {
        pageCount: this.pagingData.pageCount,
        page: typeof page === 'undefined' ? this.pagingData.page : 1,
        listCount: this.pagingData.listCount,
      };

      if (this.params.isPartner) {
        param = {
          userno: this.params.managerNo,
          ...param,
        };
      } else {
        param = {
          userno: this.params.no,
          ...param,
        };
      }

      let url = '/admin/common/access-history';
      if (this.params.isPartner) {
        url = '/partners/common/access-history';
      }

      this.$http
        .post(url, param)
        .then((result) => {
          this.pagingData.listCount = result.data.listcount;
          this.totalCnt = result.data.listcount;
          this.listData = result.data.list;

          this.$util.debug(result);
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
  },
  mounted() {
    this.onSearch();
  },
};
</script>

<style scoped />
