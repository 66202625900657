var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "720px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("업로드 실패")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "gray-box mg0" }, [
              _c("div", { staticClass: "clearfix" }, [
                _c("div", { staticClass: "fl" }, [
                  _vm._v("파일명 : " + _vm._s(_vm.excelFailParams.exfilename)),
                ]),
                _c("div", { staticClass: "fr txt-gray" }, [
                  _vm._v(
                    "업로드 일시 : " + _vm._s(_vm.excelFailParams.uploaddate)
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "caption-group mt10 clearfix" }, [
              _c("div", { staticClass: "total-group fl" }, [
                _c("span", { staticClass: "total" }, [
                  _vm._v("실패건수 "),
                  _c("strong", [_vm._v(_vm._s(_vm.excelFailParams.failcnt))]),
                  _vm._v("건"),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "boxing", staticStyle: { height: "100px" } },
              [
                _c("dl", [
                  _c("dd", [
                    _c("ul", [
                      _c("li", [_vm._v(_vm._s(_vm.excelFailParams.failrow))]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }