<template>
  <div class="content m-leftmenu">
    <common-navigator></common-navigator>

    <div class="inner">
      <div class="bar-title small">월별 계획</div>
      <div class="form-area">
        <table cellpadding="0" cellspacing="0" class="gray-tb line">
          <colgroup>
            <col width="10%" />
            <col width="30%" />
            <col width="30%" />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>월별 구분</th>
              <th>경영 계획</th>
              <th>실행 계획</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(monthly, index) in monthlyTableHeaders" :key="monthly">
              <th>{{ monthly }}</th>
              <td>
                <vue-numeric
                  class="numeric-input"
                  v-model="annualSalesPlan[index]"
                  :read-only="!isWrite"
                ></vue-numeric>
              </td>
              <td>
                <vue-numeric
                  class="numeric-input"
                  v-model="monthlySalesPlan[index]"
                  :read-only="!isWrite"
                ></vue-numeric>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="btn-group">
        <button type="button" v-if="isWrite" class="btn big blue" @click="save">저장</button>
      </div>
    </div>
  </div>
</template>

<script>
import CommonNavigator from '@views.admin/common/CommonNavigator.vue';
export default {
  name: 'admin.operation.setting.revenuemanagement',
  components: {
    CommonNavigator,
  },
  data() {
    return {
      isWrite: false,
      isRead: false,
      monthlyArray: new Array(12),
      annualSalesPlan: Array.from(new Array(12)).map(() => 0),
      monthlySalesPlan: Array.from(new Array(12)).map(() => 0),
    };
  },
  computed: {
    monthlyTableHeaders() {
      return Array.from(this.monthlyArray).map((value, index) => `${index + 1}월`);
    },
  },
  mounted() {
    let params = { url: this.$options.name, isloading: false };
    this.$http
      .post('/admin/common/pageAuth/check', params)
      .then((result) => {
        this.isRead = result.data.isread === 'T';
        this.isWrite = result.data.iswrite === 'T';

        if (this.isRead) {
          this.onInit();
        }
      })
      .catch((error) => {
        this.$util.debug(error);
      });
  },
  methods: {
    async onInit() {
      try {
        const { data } = await this.$http.get('/v2/revenue-management');
        const { annualSalesPlan, monthlySalesPlan } = data
          .sort((a, b) => {
            if (a.month > b.month) {
              return 1;
            }

            if (a.month < b.month) {
              return -1;
            }

            return 0;
          })
          .reduce(
            (acc, { executionPlanAmount, managementPlanAmount }) => {
              acc.annualSalesPlan.push(executionPlanAmount || 0);
              acc.monthlySalesPlan.push(managementPlanAmount || 0);

              return acc;
            },
            { annualSalesPlan: [], monthlySalesPlan: [] },
          );

        this.annualSalesPlan = annualSalesPlan;
        this.monthlySalesPlan = monthlySalesPlan;
      } catch (error) {
        this.$util.debug(error);
      }
    },
    convertCommaString(dataKey) {
      if (!dataKey) {
        return;
      }

      return this.$util.maskComma;
    },
    async save() {
      const validAnnualSalesPlan = this.annualSalesPlan.every((sale) =>
        typeof sale !== 'number' ? !!sale : true,
      );
      const validMonthlySalesPlan = this.monthlySalesPlan.every((sale) =>
        typeof sale !== 'number' ? !!sale : true,
      );

      if (!(validAnnualSalesPlan && validMonthlySalesPlan)) {
        return alert('빈값을 모두 입력해주세요.');
      }

      const params = Array.from(new Array(12))
        .map(() => 0)
        .reduce((acc, value, index) => {
          const data = {
            [`month${index + 1}`]: {
              executionPlanAmount: this.annualSalesPlan[index] || 0,
              managementPlanAmount: this.monthlySalesPlan[index] || 0,
            },
          };

          return { ...acc, ...data };
        }, {});

      if (confirm('저장하시겠습니까?')) {
        // 저장 호출
        try {
          const { statusCode } = await this.$http.put('/v2/revenue-management', params);
          if (statusCode == '200') {
            alert('저장이 완료되였습니다.');
            this.$router.go();
          }
        } catch (error) {
          this.$util.debug(error);
        }
      }
    },
  },
};
</script>

<style scoped>
.numeric-input {
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 4px;
  width: 400px;
  height: 30px;
  font-size: 14px;
  color: #666;
  padding: 4px;
  box-sizing: border-box;
}

.numeric-input:focus,
.numeric-input:active {
  border: 1px solid rgba(22, 125, 216, 0.8);
  box-shadow: 0 0 3px rgba(22, 124, 216, 0.5);
}
</style>
