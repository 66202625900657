<template>
  <fragment>
    <button class="btn blue-line" type="button" @click="searchAddress">주소검색</button>
    <input ref="address" v-model.trim="address" name="address1" readonly type="text" />
    <input
      ref="addressDetail"
      v-model="addressDetail"
      placeholder="상세주소"
      name="address2"
      type="text"
    />
  </fragment>
</template>

<script>
export default {
  name: 'SearchAddress',
  props: {
    loadAddress: String,
    loadAddressDetail: String,
    onSearchCompleteAddress: Function,
    onChangeAddressDetail: Function,
  },
  data() {
    return {
      address: '',
      addressDetail: '',
    };
  },
  watch: {
    addressDetail(val) {
      this.onChangeAddressDetail?.(val);
    },
    loadAddress(val) {
      this.address = val;
    },
    loadAddressDetail(val) {
      this.addressDetail = val;
    },
  },
  methods: {
    searchAddress() {
      window.daum &&
        new window.daum.Postcode({
          oncomplete: (data) => {
            this.address = data.address;
            this.onSearchCompleteAddress?.(data);
          },
        })?.open();
    },
  },
};
</script>

<style lang="scss" scoped>
input[type='text'] {
  width: 360px;
}
</style>
