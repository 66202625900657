var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content m-leftmenu",
      on: {
        mousedown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "right", 39, $event.key, [
              "Right",
              "ArrowRight",
            ])
          )
            return null
          if ("button" in $event && $event.button !== 2) return null
          return _vm.mouseright.apply(null, arguments)
        },
        contextmenu: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("common-navigator"),
      _c("div", { staticClass: "inner" }, [
        _vm._m(0),
        _c("div", { staticClass: "boxing search-area pd0" }, [
          _c("dl", [
            _c("dt", [_vm._v("직접검색")]),
            _c("dd", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.skey,
                      expression: "searchData.skey",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "skey",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.searchData.skeyArr, function (item) {
                  return _c(
                    "option",
                    { key: item.key, domProps: { value: item.key } },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                0
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.sword,
                    expression: "searchData.sword",
                  },
                ],
                attrs: { type: "text", maxlength: "200" },
                domProps: { value: _vm.searchData.sword },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.searchList(1)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "sword", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("조회일자")]),
            _c(
              "dd",
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.dtkey,
                        expression: "searchData.dtkey",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "dtkey",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "clmreq" } }, [
                      _vm._v("반품신청일"),
                    ]),
                    _c("option", { attrs: { value: "clmcomp" } }, [
                      _vm._v("반품완료일"),
                    ]),
                    _c("option", { attrs: { value: "order" } }, [
                      _vm._v("주문일"),
                    ]),
                  ]
                ),
                _c("CommonDatePicker", {
                  attrs: { value: _vm.searchData.startdate },
                  on: { change: _vm.onChangeStartDate },
                }),
                _c("span", [_vm._v("-")]),
                _c("CommonDatePicker", {
                  attrs: { value: _vm.searchData.enddate },
                  on: { change: _vm.onChangeEndDate },
                }),
                _c("div", { staticClass: "radio_wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "period_aday_1",
                      value: "aday_1",
                    },
                    domProps: {
                      checked: _vm._q(_vm.searchData.period, "aday_1"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "aday_1")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "period_aday_1" } }, [
                    _vm._v("어제"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "period_aday_0",
                      value: "aday_0",
                    },
                    domProps: {
                      checked: _vm._q(_vm.searchData.period, "aday_0"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "aday_0")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "period_aday_0" } }, [
                    _vm._v("오늘"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "period_days_7",
                      value: "days_7",
                    },
                    domProps: {
                      checked: _vm._q(_vm.searchData.period, "days_7"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "days_7")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "period_days_7" } }, [
                    _vm._v("일주일"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "period_months_1",
                      value: "months_1",
                    },
                    domProps: {
                      checked: _vm._q(_vm.searchData.period, "months_1"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "months_1")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "period_months_1" } }, [
                    _vm._v("1개월"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "period_months_3",
                      value: "months_3",
                    },
                    domProps: {
                      checked: _vm._q(_vm.searchData.period, "months_3"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "months_3")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "period_months_3" } }, [
                    _vm._v("3개월"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "period_months_6",
                      value: "months_6",
                    },
                    domProps: {
                      checked: _vm._q(_vm.searchData.period, "months_6"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "months_6")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "period_months_6" } }, [
                    _vm._v("6개월"),
                  ]),
                ]),
              ],
              1
            ),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("상품검색")]),
            _c("dd", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.gdkey,
                      expression: "searchData.gdkey",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "gdkey",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "goodsname" } }, [
                    _vm._v("상품명"),
                  ]),
                  _c("option", { attrs: { value: "goodscode" } }, [
                    _vm._v("상품코드"),
                  ]),
                  _c("option", { attrs: { value: "optioncode" } }, [
                    _vm._v("단품코드"),
                  ]),
                  _c("option", { attrs: { value: "optionname" } }, [
                    _vm._v("옵션명"),
                  ]),
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.gdword,
                    expression: "searchData.gdword",
                  },
                ],
                attrs: { type: "text", maxlength: "500" },
                domProps: { value: _vm.searchData.gdword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "gdword", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("신청구분")]),
            _c(
              "dd",
              [
                _c("div", { staticClass: "check-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.isallclmreqtype,
                        expression: "searchData.isallclmreqtype",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "chkAllClmreqtype",
                      "true-value": "T",
                      "false-value": "F",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.searchData.isallclmreqtype)
                        ? _vm._i(_vm.searchData.isallclmreqtype, null) > -1
                        : _vm._q(_vm.searchData.isallclmreqtype, "T"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.searchData.isallclmreqtype,
                            $$el = $event.target,
                            $$c = $$el.checked ? "T" : "F"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallclmreqtype",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallclmreqtype",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.searchData, "isallclmreqtype", $$c)
                          }
                        },
                        _vm.checkAllClmreqtype,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "chkAllClmreqtype" } }, [
                    _vm._v("전체"),
                  ]),
                ]),
                _vm._l(_vm.commonCode.clmreqtype, function (item) {
                  return _c(
                    "div",
                    { key: item.cmcode, staticClass: "check-wrap" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.clmreqtypeArr,
                            expression: "searchData.clmreqtypeArr",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "clmreqtype_" + item.cmcode,
                          "true-value": [],
                        },
                        domProps: {
                          value: item.cmcode,
                          checked: Array.isArray(_vm.searchData.clmreqtypeArr)
                            ? _vm._i(
                                _vm.searchData.clmreqtypeArr,
                                item.cmcode
                              ) > -1
                            : _vm._q(_vm.searchData.clmreqtypeArr, []),
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.searchData.clmreqtypeArr,
                              $$el = $event.target,
                              $$c = $$el.checked ? [] : false
                            if (Array.isArray($$a)) {
                              var $$v = item.cmcode,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "clmreqtypeArr",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "clmreqtypeArr",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.searchData, "clmreqtypeArr", $$c)
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        { attrs: { for: "clmreqtype_" + item.cmcode } },
                        [_vm._v(_vm._s(item.codename))]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("반품상태")]),
            _c(
              "dd",
              [
                _c("div", { staticClass: "check-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.isallrtnstatus,
                        expression: "searchData.isallrtnstatus",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "chkAllRtnstatus",
                      "true-value": "T",
                      "false-value": "F",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.searchData.isallrtnstatus)
                        ? _vm._i(_vm.searchData.isallrtnstatus, null) > -1
                        : _vm._q(_vm.searchData.isallrtnstatus, "T"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.searchData.isallrtnstatus,
                            $$el = $event.target,
                            $$c = $$el.checked ? "T" : "F"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallrtnstatus",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallrtnstatus",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.searchData, "isallrtnstatus", $$c)
                          }
                        },
                        _vm.checkAllRtnstatus,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "chkAllRtnstatus" } }, [
                    _vm._v("전체"),
                  ]),
                ]),
                _vm._l(_vm.commonCode.rtnstatus1, function (item) {
                  return _c(
                    "div",
                    { key: item.cmcode, staticClass: "check-wrap" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.rtnstatusArr,
                            expression: "searchData.rtnstatusArr",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "rtnstatus_" + item.cmcode,
                          "true-value": [],
                        },
                        domProps: {
                          value: item.cmcode,
                          checked: Array.isArray(_vm.searchData.rtnstatusArr)
                            ? _vm._i(_vm.searchData.rtnstatusArr, item.cmcode) >
                              -1
                            : _vm._q(_vm.searchData.rtnstatusArr, []),
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.searchData.rtnstatusArr,
                              $$el = $event.target,
                              $$c = $$el.checked ? [] : false
                            if (Array.isArray($$a)) {
                              var $$v = item.cmcode,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "rtnstatusArr",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "rtnstatusArr",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.searchData, "rtnstatusArr", $$c)
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        { attrs: { for: "rtnstatus_" + item.cmcode } },
                        [_vm._v(_vm._s(item.codename))]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _c("dl", [
            _c("dt"),
            _c(
              "dd",
              _vm._l(_vm.commonCode.rtnstatus2, function (item) {
                return _c(
                  "div",
                  { key: item.cmcode, staticClass: "check-wrap" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.rtnstatusArr,
                          expression: "searchData.rtnstatusArr",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "rtnstatus_" + item.cmcode,
                        "true-value": [],
                      },
                      domProps: {
                        value: item.cmcode,
                        checked: Array.isArray(_vm.searchData.rtnstatusArr)
                          ? _vm._i(_vm.searchData.rtnstatusArr, item.cmcode) >
                            -1
                          : _vm._q(_vm.searchData.rtnstatusArr, []),
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.searchData.rtnstatusArr,
                            $$el = $event.target,
                            $$c = $$el.checked ? [] : false
                          if (Array.isArray($$a)) {
                            var $$v = item.cmcode,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "rtnstatusArr",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "rtnstatusArr",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.searchData, "rtnstatusArr", $$c)
                          }
                        },
                      },
                    }),
                    _c(
                      "label",
                      { attrs: { for: "rtnstatus_" + item.cmcode } },
                      [_vm._v(_vm._s(item.codename))]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
        _vm.isRead
          ? _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.searchList(1)
                    },
                  },
                },
                [_vm._v("검색")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big gray",
                  attrs: { type: "button" },
                  on: { click: _vm.initSearchData },
                },
                [_vm._v("초기화")]
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "caption-group mt10 clearfix" }, [
          _c("div", { staticClass: "total-group fl" }, [
            _c("span", { staticClass: "total" }, [
              _vm._v(" 전체 "),
              _c("strong", [_vm._v(_vm._s(_vm.count.totalcnt))]),
              _vm._v(" 건 "),
            ]),
          ]),
          _c("div", { staticClass: "btn-group fr" }, [
            !_vm.isPartner
              ? _c(
                  "button",
                  {
                    staticClass: "btn black-line",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.fnChangeRtnstatus(
                          _vm.$store.getters["ADMIN"].RETURN_WAITING_APPLY,
                          "반품신청"
                        )
                      },
                    },
                  },
                  [_vm._v(" 반품신청 ")]
                )
              : _vm._e(),
            !_vm.isPartner
              ? _c(
                  "button",
                  {
                    staticClass: "btn black-line",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.fnChangeRtnstatus(
                          _vm.$store.getters["ADMIN"].RETURN_WAITING_DEPOSIT,
                          "결제대기"
                        )
                      },
                    },
                  },
                  [_vm._v(" 결제대기 ")]
                )
              : _vm._e(),
            _vm.isWrite
              ? _c(
                  "button",
                  {
                    staticClass: "btn blue-line",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.fnChangeRtnstatus(
                          _vm.$store.getters["ADMIN"].RETURN_APPROVAL_COMPLETE,
                          "승인처리"
                        )
                      },
                    },
                  },
                  [_vm._v(" 승인처리 ")]
                )
              : _vm._e(),
            _vm.isWrite && !_vm.isPartner
              ? _c(
                  "button",
                  {
                    staticClass: "btn red-line",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.fnChangeRtnstatus(
                          _vm.$store.getters["ADMIN"].RETURN_APPROVAL_REFUSE,
                          "반려처리"
                        )
                      },
                    },
                  },
                  [_vm._v(" 반려처리 ")]
                )
              : _vm._e(),
            _vm.isWrite && _vm.isPartner
              ? _c(
                  "button",
                  {
                    staticClass: "btn red-line",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.fnChangeRtnstatus(
                          _vm.$store.getters["ADMIN"].RETURN_REQUEST_REFUSE,
                          "반품반려승인요청"
                        )
                      },
                    },
                  },
                  [_vm._v(" 반품반려승인요청 ")]
                )
              : _vm._e(),
            _vm.isWrite
              ? _c(
                  "button",
                  {
                    staticClass: "btn black-line",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.fnChangeRtnstatus(
                          _vm.$store.getters["ADMIN"].RETURN_COMPLETE_DELIVERY,
                          "회수완료"
                        )
                      },
                    },
                  },
                  [_vm._v(" 회수완료 ")]
                )
              : _vm._e(),
            _vm.isWrite
              ? _c(
                  "button",
                  {
                    staticClass: "btn red-line",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.fnChangeRtnstatus(
                          _vm.$store.getters["ADMIN"].RETURN_A_RETURN,
                          "반송처리"
                        )
                      },
                    },
                  },
                  [_vm._v(" 반송처리 ")]
                )
              : _vm._e(),
            _vm.isWrite && !_vm.isPartner
              ? _c(
                  "button",
                  {
                    staticClass: "btn black-line",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.fnChangeRtnstatus(
                          _vm.$store.getters["ADMIN"].RETURN_COMPLETE,
                          "처리완료"
                        )
                      },
                    },
                  },
                  [_vm._v(" 처리완료 ")]
                )
              : _vm._e(),
            _vm.isRead
              ? _c(
                  "button",
                  {
                    staticClass: "btn green-line",
                    attrs: { type: "button" },
                    on: { click: _vm.fnExcelDownload },
                  },
                  [
                    _c("i", { staticClass: "icon-excel" }),
                    _vm._v(" 엑셀다운로드 "),
                  ]
                )
              : _vm._e(),
            _vm.isRead
              ? _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pagingData.pageCount,
                        expression: "pagingData.pageCount",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.pagingData,
                          "pageCount",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "20" } }, [
                      _vm._v("20개씩 보기"),
                    ]),
                    _c("option", { attrs: { value: "50" } }, [
                      _vm._v("50개씩 보기"),
                    ]),
                    _c("option", { attrs: { value: "100" } }, [
                      _vm._v("100개씩 보기"),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "scroll-x" }, [
          _c(
            "table",
            {
              staticClass: "data-tb align-c",
              style: _vm.isPartner ? "width: 1900px;" : "width: 2400px;",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _c("caption", [_vm._v("반품 목록")]),
              !_vm.isPartner
                ? _c("colgroup", [
                    _c("col", { attrs: { width: "1.2%" } }),
                    _c("col", { attrs: { width: "4%" } }),
                    _c("col", { attrs: { width: "4%" } }),
                    _c("col", { attrs: { width: "5.2%" } }),
                    _c("col", { attrs: { width: "4%" } }),
                    _c("col", { attrs: { width: "3%" } }),
                    _c("col", { attrs: { width: "4%" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "3.5%" } }),
                    _c("col", { attrs: { width: "2.5%" } }),
                    _c("col", { attrs: { width: "2.7%" } }),
                    _c("col", { attrs: { width: "2.2%" } }),
                    _c("col", { attrs: { width: "3.2%" } }),
                    _c("col", { attrs: { width: "4%" } }),
                    _c("col", { attrs: { width: "4%" } }),
                    _c("col", { attrs: { width: "3.2%" } }),
                    _c("col", { attrs: { width: "62px" } }),
                    _c("col", { attrs: { width: "" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "3.2%" } }),
                    _c("col", { attrs: { width: "3.2%" } }),
                    _c("col", { attrs: { width: "3.5%" } }),
                    _c("col", { attrs: { width: "3.5%" } }),
                    _c("col", { attrs: { width: "3.5%" } }),
                    _c("col", { attrs: { width: "3.1%" } }),
                    _c("col", { attrs: { width: "3.1%" } }),
                    _c("col", { attrs: { width: "5.5%" } }),
                  ])
                : _c("colgroup", [
                    _c("col", { attrs: { width: "1.2%" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "7%" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "4%" } }),
                    _c("col", { attrs: { width: "62px" } }),
                    _c("col", { attrs: { width: "" } }),
                    _c("col", { attrs: { width: "8%" } }),
                    _c("col", { attrs: { width: "4.5%" } }),
                    _c("col", { attrs: { width: "4.5%" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "4.5%" } }),
                    _c("col", { attrs: { width: "4%" } }),
                    _c("col", { attrs: { width: "7%" } }),
                  ]),
              _c("thead", [
                _c("tr", [
                  _c("th", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isallchk,
                          expression: "isallchk",
                        },
                      ],
                      attrs: { type: "checkbox", id: "chkall" },
                      domProps: {
                        checked: Array.isArray(_vm.isallchk)
                          ? _vm._i(_vm.isallchk, null) > -1
                          : _vm.isallchk,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.isallchk,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.isallchk = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.isallchk = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.isallchk = $$c
                            }
                          },
                          function ($event) {
                            return _vm.checkAllList($event.target.checked)
                          },
                        ],
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 반품 신청일 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.clmreqdate === "clmreqdate_asc" },
                        { down: _vm.sortData.clmreqdate === "clmreqdate_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.clmreqdate },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.clmreqdate)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 반품 완료일 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.clmcompdate === "clmcompdate_asc" },
                        {
                          down: _vm.sortData.clmcompdate === "clmcompdate_desc",
                        },
                      ],
                      attrs: {
                        type: "button",
                        value: _vm.sortData.clmcompdate,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.clmcompdate)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 반품번호 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.clmno === "clmno_asc" },
                        { down: _vm.sortData.clmno === "clmno_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.clmno },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.clmno)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 주문일 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.orderdate === "orderdate_asc" },
                        { down: _vm.sortData.orderdate === "orderdate_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.orderdate },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.orderdate)
                        },
                      },
                    }),
                  ]),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 주문자 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.ordname === "ordname_asc" },
                            { down: _vm.sortData.ordname === "ordname_desc" },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.ordname,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.ordname)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 아이디 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.ordid === "ordid_asc" },
                            { down: _vm.sortData.ordid === "ordid_desc" },
                          ],
                          attrs: { type: "button", value: _vm.sortData.ordid },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.ordid)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 연락처 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.ordtel === "ordtel_asc" },
                            { down: _vm.sortData.ordtel === "ordtel_desc" },
                          ],
                          attrs: { type: "button", value: _vm.sortData.ordtel },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.ordtel)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 회원구분 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            {
                              up:
                                _vm.sortData.isnonmember === "isnonmember_asc",
                            },
                            {
                              down:
                                _vm.sortData.isnonmember === "isnonmember_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.isnonmember,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.isnonmember)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 유형 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            {
                              up: _vm.sortData.membertype === "membertype_asc",
                            },
                            {
                              down:
                                _vm.sortData.membertype === "membertype_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.membertype,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.membertype)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 등급 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.memlvtype === "memlvtype_asc" },
                            {
                              down: _vm.sortData.memlvtype === "memlvtype_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.memlvtype,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.memlvtype)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 차수 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.clmturn === "clmturn_asc" },
                            { down: _vm.sortData.clmturn === "clmturn_desc" },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.clmturn,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.clmturn)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 판매구분 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.ispbgoods === "ispbgoods_asc" },
                            {
                              down: _vm.sortData.ispbgoods === "ispbgoods_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.ispbgoods,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.ispbgoods)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 파트너사 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            {
                              up: _vm.sortData.dealername === "dealername_asc",
                            },
                            {
                              down:
                                _vm.sortData.dealername === "dealername_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.dealername,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.dealername)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  _c("th", [
                    _vm._v(" 상품코드 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.goodscode === "goodscode_asc" },
                        { down: _vm.sortData.goodscode === "goodscode_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.goodscode },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.goodscode)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 단품코드 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.optioncode === "optioncode_asc" },
                        { down: _vm.sortData.optioncode === "optioncode_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.optioncode },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.optioncode)
                        },
                      },
                    }),
                  ]),
                  _c("th", { attrs: { colspan: "2" } }, [
                    _vm._v(" 상품명 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.goodsname === "goodsname_asc" },
                        { down: _vm.sortData.goodsname === "goodsname_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.goodsname },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.goodsname)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 옵션 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.optionconts === "optionconts_asc" },
                        {
                          down: _vm.sortData.optionconts === "optionconts_desc",
                        },
                      ],
                      attrs: {
                        type: "button",
                        value: _vm.sortData.optionconts,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.optionconts)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 주문수량 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.bfordcnt === "bfordcnt_asc" },
                        { down: _vm.sortData.bfordcnt === "bfordcnt_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.bfordcnt },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.bfordcnt)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 반품수량 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.clmcnt === "clmcnt_asc" },
                        { down: _vm.sortData.clmcnt === "clmcnt_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.clmcnt },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.clmcnt)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 판매단가 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.price === "price_asc" },
                        { down: _vm.sortData.price === "price_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.price },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.price)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 판매금액 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.totprice === "totprice_asc" },
                        { down: _vm.sortData.totprice === "totprice_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.totprice },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.totprice)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 반품금액 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.clmamt === "clmamt_asc" },
                        { down: _vm.sortData.clmamt === "clmamt_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.clmamt },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.clmamt)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 신청구분 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.clmreqtype === "clmreqtype_asc" },
                        { down: _vm.sortData.clmreqtype === "clmreqtype_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.clmreqtype },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.clmreqtype)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 반품상태 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.rtnstatus === "rtnstatus_asc" },
                        { down: _vm.sortData.rtnstatus === "rtnstatus_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.rtnstatus },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.rtnstatus)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 주문번호 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.ordno === "ordno_asc" },
                        { down: _vm.sortData.ordno === "ordno_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.ordno },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.ordno)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _vm.list.length > 0
                ? _c(
                    "tbody",
                    _vm._l(_vm.list, function (item) {
                      return _c("tr", { key: item.clmgdidx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.checkedList,
                                expression: "checkedList",
                              },
                            ],
                            attrs: { type: "checkbox", id: item.clmgdidx },
                            domProps: {
                              value: item.clmgdidx,
                              checked: Array.isArray(_vm.checkedList)
                                ? _vm._i(_vm.checkedList, item.clmgdidx) > -1
                                : _vm.checkedList,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.checkedList,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = item.clmgdidx,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.checkedList = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.checkedList = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.checkedList = $$c
                                  }
                                },
                                function ($event) {
                                  return _vm.checkList($event.target.checked)
                                },
                              ],
                            },
                          }),
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.clmreqdate) + " "),
                          _c("br"),
                          _vm._v(" " + _vm._s(item.clmreqtime) + " "),
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.clmcompdate) + " "),
                          _c("br"),
                          _vm._v(" " + _vm._s(item.clmcomptime) + " "),
                        ]),
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.goReturnDetail(item)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.clmno) + " ")]
                          ),
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.orderdate) + " "),
                          _c("br"),
                          _vm._v(" " + _vm._s(item.ordertime) + " "),
                        ]),
                        item.isnonmember === "F" && !_vm.isPartner
                          ? _c("td", [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goMemDetail(item.orduserno)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.ordname) + " ")]
                              ),
                            ])
                          : _vm._e(),
                        item.isnonmember !== "F" && !_vm.isPartner
                          ? _c("td", [_vm._v(_vm._s(item.ordname))])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("td", [_vm._v(_vm._s(item.ordid))])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("td", [
                              _vm._v(_vm._s(_vm.$util.maskTel(item.ordtel))),
                            ])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("td", [_vm._v(_vm._s(item.isnonmembername))])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("td", [_vm._v(_vm._s(item.membertypename))])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("td", [_vm._v(_vm._s(item.memlvtypename))])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("td", [_vm._v(_vm._s(item.clmturn))])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("td", [_vm._v(_vm._s(item.ispbgoodsname))])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("td", [_vm._v(_vm._s(item.dealername))])
                          : _vm._e(),
                        _c("td", [_vm._v(_vm._s(item.goodscode))]),
                        _c("td", [_vm._v(_vm._s(item.optioncode))]),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass: "img-thumb size60 link",
                              class: {
                                "no-image": _vm.$util.isNull(item.fullpath),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goFrontGoodsDetail(item.goodscode)
                                },
                              },
                            },
                            [
                              !_vm.$util.isNull(item.fullpath)
                                ? _c("img", { attrs: { src: item.fullpath } })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _c("td", { staticClass: "left no-left" }, [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.goGoodsDetail(item.goodsno)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.goodsname) + " ")]
                          ),
                        ]),
                        _c(
                          "td",
                          { staticStyle: { "white-space": "pre-wrap" } },
                          [_vm._v(_vm._s(item.optionconts))]
                        ),
                        _c("td", [
                          _vm._v(_vm._s(_vm.$util.maskComma(item.bfordcnt))),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.$util.maskComma(item.clmcnt))),
                        ]),
                        _c("td", { staticClass: "right" }, [
                          _vm._v(_vm._s(_vm.$util.maskComma(item.price))),
                        ]),
                        _c("td", { staticClass: "right" }, [
                          _vm._v(
                            _vm._s(_vm.$util.maskComma(Number(item.totprice)))
                          ),
                        ]),
                        _c("td", { staticClass: "right" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$util.isNull(item.clmamt)
                                  ? ""
                                  : _vm.$util.maskComma(item.clmamt)
                              ) +
                              " "
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(item.clmreqtypename))]),
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.goClaimHistory(item.clmgdidx)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.rtnstatusname) + " ")]
                          ),
                        ]),
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.goOrderDetail(item.ordno)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.ordno) + " ")]
                          ),
                        ]),
                      ])
                    }),
                    0
                  )
                : _c("tbody", [
                    _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: _vm.isPartner ? 18 : 27 } },
                        [_vm._v("조회 결과가 존재하지 않습니다.")]
                      ),
                    ]),
                  ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "bottom-group" }, [
          _c(
            "div",
            { staticClass: "paging" },
            [
              _c("CommonPageNavigator", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isRead,
                    expression: "isRead",
                  },
                ],
                attrs: { pagingData: _vm.pagingData },
                on: { setPagingData: _vm.setPagingData },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.isShowReturnDetail
        ? _c("ReturnDetail", {
            attrs: {
              activeClmParam: _vm.activeClmParam,
              activeOrderInfo: _vm.activeOrderInfo,
            },
            on: { closeDetail: _vm.closeReturnDetail },
          })
        : _vm._e(),
      _vm.isShowOrderDetail
        ? _c("OrderDetail", {
            attrs: { activeOrderCode: _vm.activeOrderCode },
            on: { closeDetail: _vm.closeOrderDetail },
          })
        : _vm._e(),
      _vm.isShowMemDetail
        ? _c("AdminMemberInfo", {
            attrs: { activeUserNo: _vm.activeUserNo },
            on: { closeDetail: _vm.closeMemDetail },
          })
        : _vm._e(),
      _vm.isGoodsDetailShow
        ? _c("GoodsDetail", {
            attrs: { activeGoodsNo: _vm.activeGoodsNo },
            on: { closePopup: _vm.closeGoodsDetail },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "bar-title fl" }, [_vm._v("반품관리")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }