var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isMemberDetailShow
        ? _c("AdminMemberInfo", {
            staticStyle: { "z-index": "1005" },
            attrs: { activeUserNo: _vm.memberDetailUserNo },
            on: { closeDetail: _vm.closeMemberInfoPopup },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "modal",
          staticStyle: { display: "block" },
          attrs: { id: "modal-wrap" },
        },
        [
          _c(
            "div",
            { staticClass: "modal-content", staticStyle: { width: "1200px" } },
            [
              _c("div", { staticClass: "pop-header" }, [
                _c("h2", [_vm._v("시리얼프로모션 상세")]),
                _c("button", {
                  staticClass: "pop-close",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "pop-body" }, [
                _c("div", { staticClass: "gray-box mg0 clearfix" }, [
                  _c("div", { staticClass: "fl" }, [
                    _c("span", [
                      _vm._v("작성자 : " + _vm._s(_vm.boardInfo.reguserid)),
                    ]),
                  ]),
                  _c("div", { staticClass: "fr txt-gray" }, [
                    _c("span", [
                      _vm._v("등록일 : " + _vm._s(_vm.boardInfo.regdate)),
                    ]),
                    _c("span", { staticClass: "left-bar" }, [
                      _vm._v("수정일 : " + _vm._s(_vm.boardInfo.moddate)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "bar-title mt10" }, [
                  _vm._v("기본정보"),
                ]),
                _c("div", { staticClass: "boxing" }, [
                  _c("div", { staticClass: "form-area" }, [
                    _c("dl", [
                      _c("dt", [_vm._v("사용여부")]),
                      _c("dd", [
                        _c("div", { staticClass: "radio_wrap wide" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.istrash,
                                expression: "boardInfo.istrash",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "tr_group001",
                              id: "tr_group011",
                              value: "F",
                            },
                            domProps: {
                              checked: _vm._q(_vm.boardInfo.istrash, "F"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.boardInfo, "istrash", "F")
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "tr_group011" } }, [
                            _vm._v("사용함"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.istrash,
                                expression: "boardInfo.istrash",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "tr_group002",
                              id: "tr_group021",
                              value: "T",
                            },
                            domProps: {
                              checked: _vm._q(_vm.boardInfo.istrash, "T"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.boardInfo, "istrash", "T")
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "tr_group021" } }, [
                            _vm._v("사용안함"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("dl", [
                      _vm._m(0),
                      _c("dd", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.boardInfo.title,
                              expression: "boardInfo.title",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: { type: "text", placeholder: "관리제목" },
                          domProps: { value: _vm.boardInfo.title },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.boardInfo,
                                "title",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._m(1),
                _c("div", { staticClass: "form-area" }, [
                  _c(
                    "table",
                    {
                      staticClass: "gray-tb",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _vm._m(2),
                      _c("tbody", [
                        _c("tr", [
                          _vm._m(3),
                          _c(
                            "td",
                            [
                              _c("CommonDatePickerFromTo", {
                                attrs: {
                                  fromYYYYMMDD:
                                    _vm.serialTimeInfo.startYYYYMMDD,
                                  fromHH: _vm.serialTimeInfo.startHH,
                                  fromMM: _vm.serialTimeInfo.startMM,
                                  toYYYYMMDD: _vm.serialTimeInfo.toYYYYMMDD,
                                  toHH: _vm.serialTimeInfo.toHH,
                                  toMM: _vm.serialTimeInfo.toMM,
                                },
                                on: { getDate: _vm.pickerChangeEvent },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _vm._m(4),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "radio_wrap wide3" },
                              _vm._l(_vm.commonCode.srptype, function (row, i) {
                                return _c("div", { key: i }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.boardInfo.srptype,
                                        expression: "boardInfo.srptype",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "group01",
                                      id: "group01" + i,
                                    },
                                    domProps: {
                                      value: row.cmcode,
                                      checked: _vm._q(
                                        _vm.boardInfo.srptype,
                                        row.cmcode
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.boardInfo,
                                          "srptype",
                                          row.cmcode
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "group01" + i } },
                                    [_vm._v(_vm._s(row.codename))]
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _vm._m(5),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "radio_wrap dpib" },
                              _vm._l(_vm.commonCode.srctype, function (row, i) {
                                return _c("div", { key: i }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.boardInfo.srctype,
                                        expression: "boardInfo.srctype",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "group02",
                                      id: "group02" + i,
                                    },
                                    domProps: {
                                      value: row.cmcode,
                                      checked: _vm._q(
                                        _vm.boardInfo.srctype,
                                        row.cmcode
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.boardInfo,
                                          "srctype",
                                          row.cmcode
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "group02" + i } },
                                    [_vm._v(_vm._s(row.codename))]
                                  ),
                                ])
                              }),
                              0
                            ),
                            _vm._m(6),
                          ]),
                        ]),
                        (_vm.boardInfo.srptype ===
                          _vm.$store.getters["ADMIN"].SRPTYPE_SRT_SAVE ||
                          _vm.boardInfo.srptype ===
                            _vm.$store.getters["ADMIN"].SRPTYPE_SRT_EPOINT ||
                          _vm.boardInfo.srptype ===
                            _vm.$store.getters["ADMIN"].SRPTYPE_SRT_COUPON) &&
                        (_vm.boardInfo.srctype ===
                          _vm.$store.getters["ADMIN"].SRCTYPE_SRC_AUTO ||
                          _vm.boardInfo.srctype ===
                            _vm.$store.getters["ADMIN"].SRCTYPE_SRC_DIR)
                          ? _c("tr", [
                              _vm._m(7),
                              _c("td", [
                                _c("div", { staticClass: "dpb" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.boardInfo.isequalserial,
                                        expression: "boardInfo.isequalserial",
                                      },
                                    ],
                                    staticClass: "circle",
                                    attrs: {
                                      type: "radio",
                                      name: "group03",
                                      id: "group31",
                                      value: "T",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.boardInfo.isequalserial,
                                        "T"
                                      ),
                                    },
                                    on: {
                                      click: _vm.onClickEqual,
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.boardInfo,
                                          "isequalserial",
                                          "T"
                                        )
                                      },
                                    },
                                  }),
                                  _c("label", { attrs: { for: "group31" } }, [
                                    _vm._v("한 개의 동일한 시리얼 번호"),
                                  ]),
                                  _c("span", [_vm._v("(선착순")]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.boardInfo.dupcnt,
                                        expression: "boardInfo.dupcnt",
                                      },
                                    ],
                                    staticClass: "center ml3",
                                    staticStyle: { width: "60px" },
                                    attrs: { type: "number", value: "T" },
                                    domProps: { value: _vm.boardInfo.dupcnt },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.boardInfo,
                                          "dupcnt",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c("span", { staticClass: "ml3" }, [
                                    _vm._v("회 사용 유효 / 전체회원 대상)"),
                                  ]),
                                ]),
                                _c("div", { staticClass: "dpb" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.boardInfo.isequalserial,
                                        expression: "boardInfo.isequalserial",
                                      },
                                    ],
                                    staticClass: "circle",
                                    attrs: {
                                      type: "radio",
                                      name: "group03",
                                      id: "group32",
                                      value: "F",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.boardInfo.isequalserial,
                                        "F"
                                      ),
                                    },
                                    on: {
                                      click: _vm.onClickEqual,
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.boardInfo,
                                          "isequalserial",
                                          "F"
                                        )
                                      },
                                    },
                                  }),
                                  _c("label", { attrs: { for: "group32" } }, [
                                    _vm._v("여러 개의 다른 시리얼 번호"),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.boardInfo.issuecnt,
                                        expression: "boardInfo.issuecnt",
                                      },
                                    ],
                                    staticClass: "center",
                                    staticStyle: { width: "60px" },
                                    attrs: { type: "number" },
                                    domProps: { value: _vm.boardInfo.issuecnt },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.boardInfo,
                                          "issuecnt",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c("span", { staticClass: "ml3" }, [
                                    _vm._v("개(각 번호당 1회만 사용 유효)"),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        (_vm.boardInfo.srptype ===
                          _vm.$store.getters["ADMIN"].SRPTYPE_SRT_SAVE ||
                          _vm.boardInfo.srptype ===
                            _vm.$store.getters["ADMIN"].SRPTYPE_SRT_EPOINT ||
                          _vm.boardInfo.srptype ===
                            _vm.$store.getters["ADMIN"].SRPTYPE_SRT_COUPON) &&
                        _vm.boardInfo.srctype ===
                          _vm.$store.getters["ADMIN"].SRCTYPE_SRC_DIR
                          ? _c("tr", [
                              _vm._m(8),
                              _c("td", [
                                _c("div", { staticClass: "dpb" }, [
                                  _c("input", {
                                    attrs: { type: "text", maxlength: "20" },
                                    domProps: { value: _vm.serialInput },
                                    on: {
                                      input: _vm.bindSerialInput,
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.onSerialNumDupCheck.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line ml3",
                                      attrs: { type: "button" },
                                      on: { click: _vm.onSerialNumDupCheck },
                                    },
                                    [_vm._v(" 중복확인 ")]
                                  ),
                                  _vm._m(9),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dpb scroll-y mb0 mt10",
                                    staticStyle: { "max-height": "300px" },
                                  },
                                  [
                                    _c("div", { staticClass: "check-field" }, [
                                      _c(
                                        "ul",
                                        { staticClass: "clearfix" },
                                        _vm._l(
                                          _vm.serialList,
                                          function (row, i) {
                                            return _c("li", { key: i }, [
                                              _vm._v(
                                                " " + _vm._s(row.serialno) + " "
                                              ),
                                              _c("button", {
                                                staticClass: "del ml10",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onRemoveCerealCode(
                                                      i
                                                    )
                                                  },
                                                },
                                              }),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        (_vm.boardInfo.srptype ===
                          _vm.$store.getters["ADMIN"].SRPTYPE_SRT_SAVE ||
                          _vm.boardInfo.srptype ===
                            _vm.$store.getters["ADMIN"].SRPTYPE_SRT_EPOINT ||
                          _vm.boardInfo.srptype ===
                            _vm.$store.getters["ADMIN"].SRPTYPE_SRT_COUPON) &&
                        _vm.boardInfo.srctype ===
                          _vm.$store.getters["ADMIN"].SRCTYPE_SRC_EXCEL
                          ? _c("tr", [
                              _vm._m(10),
                              _c(
                                "td",
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "btn blue-line",
                                      attrs: { for: "input-file-new" },
                                    },
                                    [_vm._v("파일첨부")]
                                  ),
                                  _c("input", {
                                    ref: "inputFile",
                                    staticStyle: { display: "none" },
                                    attrs: {
                                      type: "file",
                                      id: "input-file-new",
                                      accept: ".xls,.xlsx",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.selectFile($event)
                                      },
                                    },
                                  }),
                                  _vm._l(_vm.uploadFile, function (row, i) {
                                    return _c("span", { key: i }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "file-link",
                                          staticStyle: {
                                            "text-decoration":
                                              "none !important",
                                            color: "#666",
                                          },
                                        },
                                        [_vm._v(_vm._s(row.file.name))]
                                      ),
                                      _c("button", {
                                        staticClass: "file-del",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteUploadFile(i)
                                          },
                                        },
                                      }),
                                    ])
                                  }),
                                  _vm._m(11),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                        _vm.boardInfo.srptype ===
                        _vm.$store.getters["ADMIN"].SRPTYPE_SRT_SAVE
                          ? _c("tr", [
                              _vm._m(12),
                              _c("td", [
                                _c("span", [
                                  _vm._v("시리얼번호를 입력한 회원에게 적립금"),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.boardInfo.srppoint,
                                      expression: "boardInfo.srppoint",
                                    },
                                  ],
                                  staticClass: "right ml3",
                                  staticStyle: { width: "60px" },
                                  attrs: { type: "number" },
                                  domProps: { value: _vm.boardInfo.srppoint },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.boardInfo,
                                        "srppoint",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c("span", { staticClass: "ml3" }, [
                                  _vm._v("포인트를 지급"),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.boardInfo.srptype ===
                        _vm.$store.getters["ADMIN"].SRPTYPE_SRT_EPOINT
                          ? _c("tr", [
                              _vm._m(13),
                              _c("td", [
                                _c("span", [
                                  _vm._v(
                                    "시리얼번호를 입력한 회원에게 D포인트"
                                  ),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.boardInfo.srppoint,
                                      expression: "boardInfo.srppoint",
                                    },
                                  ],
                                  staticClass: "right ml3",
                                  staticStyle: { width: "60px" },
                                  attrs: { type: "number" },
                                  domProps: { value: _vm.boardInfo.srppoint },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.boardInfo,
                                        "srppoint",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c("span", { staticClass: "ml3" }, [
                                  _vm._v("포인트를 지급"),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.boardInfo.srptype ===
                        _vm.$store.getters["ADMIN"].SRPTYPE_SRT_EPOINT
                          ? _c("tr", [
                              _vm._m(14),
                              _c("td", [
                                _c(
                                  "div",
                                  { staticClass: "radio_wrap wide dpib" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.boardInfo.isepointdup,
                                          expression: "boardInfo.isepointdup",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "group04",
                                        id: "group41",
                                        value: "T",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.boardInfo.isepointdup,
                                          "T"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.boardInfo,
                                            "isepointdup",
                                            "T"
                                          )
                                        },
                                      },
                                    }),
                                    _c("label", { attrs: { for: "group41" } }, [
                                      _vm._v("허용"),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.boardInfo.isepointdup,
                                          expression: "boardInfo.isepointdup",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "group04",
                                        id: "group42",
                                        value: "F",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.boardInfo.isepointdup,
                                          "F"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.boardInfo,
                                            "isepointdup",
                                            "F"
                                          )
                                        },
                                      },
                                    }),
                                    _c("label", { attrs: { for: "group42" } }, [
                                      _vm._v("미허용"),
                                    ]),
                                  ]
                                ),
                                _vm._m(15),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.boardInfo.srptype ===
                        _vm.$store.getters["ADMIN"].SRPTYPE_SRT_EPOINT
                          ? _c("tr", [
                              _vm._m(16),
                              _c("td", [
                                _vm._v(
                                  " D포인트 적립금 프로모션을 통해 지급되는 D포인트의 유효기간은 진행기간과 동일하게 자동 설정됩니다. "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.boardInfo.srptype ===
                        _vm.$store.getters["ADMIN"].SRPTYPE_SRT_COUPON
                          ? _c("tr", [
                              _vm._m(17),
                              _c("td", [
                                _c("span", [
                                  _vm._v("시리얼번호를 입력한 회원에게"),
                                ]),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.boardInfo.comcpnidx,
                                        expression: "boardInfo.comcpnidx",
                                      },
                                    ],
                                    staticStyle: { width: "450px" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.boardInfo,
                                          "comcpnidx",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(_vm.couponList, function (row, i) {
                                    return _c(
                                      "option",
                                      {
                                        key: i,
                                        domProps: { value: row.comcpnidx },
                                      },
                                      [_vm._v(" " + _vm._s(row.title) + " ")]
                                    )
                                  }),
                                  0
                                ),
                                _c("span", { staticClass: "ml3" }, [
                                  _vm._v("쿠폰을 지급"),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "bar-title" }, [
                  _vm._v("S/N 등록 내역"),
                ]),
                _c("div", { staticClass: "caption-group clearfix" }, [
                  _c("div", { staticClass: "total-group fl" }, [
                    _c("span", { staticClass: "total" }, [
                      _vm._v("전체 "),
                      _c("strong", [_vm._v(_vm._s(_vm.serialNoList.length))]),
                      _vm._v("건"),
                    ]),
                  ]),
                  _c("div", { staticClass: "btn-group fr" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn green-line",
                        attrs: { type: "button" },
                        on: { click: _vm.onSerialNoExcelDownload },
                      },
                      [
                        _c("i", { staticClass: "icon-excel" }),
                        _vm._v("엑셀다운로드 "),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "scroll-y",
                    staticStyle: { "max-height": "400px" },
                  },
                  [
                    _c(
                      "table",
                      {
                        staticClass: "data-tb align-c",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _c("caption", [_vm._v(" D포인트 ")]),
                        _vm._m(18),
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("No")]),
                            _c("th", [_vm._v("시리얼번호")]),
                            _c("th", [_vm._v("아이디")]),
                            _c("th", [_vm._v("이름")]),
                            _c("th", [
                              _vm._v(" 유형 "),
                              _c("button", {
                                staticClass: "sort",
                                class: [
                                  {
                                    up:
                                      _vm.sortData.dadamembertype ===
                                      "dadamembertype_asc",
                                  },
                                  {
                                    down:
                                      _vm.sortData.dadamembertype ===
                                      "dadamembertype_desc",
                                  },
                                ],
                                attrs: {
                                  type: "button",
                                  value: _vm.sortData.dadamembertype,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.sortToggle(
                                      _vm.sortData.dadamembertype
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("th", [
                              _vm._v(" 등급 "),
                              _c("button", {
                                staticClass: "sort",
                                class: [
                                  {
                                    up:
                                      _vm.sortData.memlvtype ===
                                      "memlvtype_asc",
                                  },
                                  {
                                    down:
                                      _vm.sortData.memlvtype ===
                                      "memlvtype_desc",
                                  },
                                ],
                                attrs: {
                                  type: "button",
                                  value: _vm.sortData.memlvtype,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.sortToggle(
                                      _vm.sortData.memlvtype
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("th", [
                              _vm._v(" S/N 등록일자 "),
                              _c("button", {
                                staticClass: "sort",
                                class: [
                                  {
                                    up: _vm.sortData.regdate === "regdate_asc",
                                  },
                                  {
                                    down:
                                      _vm.sortData.regdate === "regdate_desc",
                                  },
                                ],
                                attrs: {
                                  type: "button",
                                  value: _vm.sortData.regdate,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.sortToggle(_vm.sortData.regdate)
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.serialNoList, function (row, i) {
                            return _c("tr", { key: i }, [
                              _c("td", [
                                _vm._v(_vm._s(_vm.$util.addZero(i + 1))),
                              ]),
                              _c("td", [_vm._v(_vm._s(row.serialno))]),
                              _c("td", [_vm._v(_vm._s(row.userid))]),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goMemberInfoPopup(row.userno)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row.name))]
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(row.dadamembertype))]),
                              _c("td", [_vm._v(_vm._s(row.memlvtype))]),
                              _c("td", [_vm._v(_vm._s(row.regdate))]),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "btn-group mt20" }, [
                  _vm.isWrite
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big blue",
                          attrs: { type: "button" },
                          on: { click: _vm.goSave },
                        },
                        [_vm._v("저장")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn big darkgray",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v("취소")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_vm._v("관리제목"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "bar-title fl" }, [_vm._v("조건설정")]),
      _c("div", { staticClass: "fr" }, [
        _c("span", { staticClass: "txt-orange" }, [
          _c("i", { staticClass: "icon-alert" }),
          _vm._v(
            " 여러 개의 시리얼번호의 자동발급 제한 : 제한없음/1회, 100회/1일 "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("진행기간"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("혜택 구분"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("시리얼번호 생성방법"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "txt-orange ml10" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v("시리얼번호 : 영문대문자 및 숫자 조합 20자"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("시리얼번호 발급기준"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("시리얼번호 등록"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "txt-orange ml10" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        "영문대문자 및 숫자 조합 최소 20자, 재 등록시 기존 번호는 삭제후 재 발급합니다."
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("시리얼번호 등록"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "txt-orange ml10" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v("재 등록시 기존 시리얼 번호는 삭제되고 새로 발급됩니다."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("적립금 지급"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("D포인트 지급"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("D포인트 중복사용여부"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "txt-orange ml10" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v("적립금과 함께 사용 가능한지의 여부"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("D포인트 유효기간"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("쿠폰 지급"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "15%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }