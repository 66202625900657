import Vue from 'vue';
import Vuex from 'vuex';
import constants from './constants.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    constants: constants,
    // 서브 헤더 옵션 관리
    subHeaderOption: {
      title: '페이지 제목',
      searchIcon: true,
      cartIcon: true,
      homeIcon: true,
    },
    platform: '',
    partnerCompanyName: '', // 파트너사 업체명
    managerName: '', // 매니저 이름
  },
  mutations: {
    onSubHeaderOption: (state, payload) => {
      state.subHeaderOption = payload;
    },
    platform: (state, payload) => {
      state.platform = payload;
    },
    partnerCompanyName: (state, payload) => {
      state.partnerCompanyName = payload;
    },
    managerName: (state, payload) => {
      state.managerName = payload;
    },
  },
  getters: {
    // this.store.getters.TODO
    CONSTANTS: (state) => {
      return state.constants;
    },
    ADMIN: (state) => {
      return state.constants.ADMIN;
    },
  },
});
