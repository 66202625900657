var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1200px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [
              _vm._v("상품 공지사항 " + _vm._s(_vm.isNew ? "등록" : "수정")),
            ]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c(
            "div",
            { staticClass: "pop-body" },
            [
              !!_vm.noticeInfo
                ? _c("NoticeForm", {
                    model: {
                      value: _vm.noticeInfo,
                      callback: function ($$v) {
                        _vm.noticeInfo = $$v
                      },
                      expression: "noticeInfo",
                    },
                  })
                : _vm._e(),
              _c("div", { staticClass: "btn-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn big blue",
                    attrs: { type: "button", disabled: !_vm.onValid() },
                    on: { click: _vm.onSave },
                  },
                  [_vm._v(" " + _vm._s(_vm.isNew ? "등록" : "수정") + " ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn big darkgray",
                    attrs: { type: "button" },
                    on: { click: _vm.onClose },
                  },
                  [_vm._v("취소")]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }