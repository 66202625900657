var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "800px" } },
        [
          _c("div", { staticClass: "pop-header f-height" }, [
            _c("h2", [_vm._v(_vm._s(_vm.agreeList[_vm.nowIdx].subject))]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "txt-gray midium mb10" }, [
              _vm._v(
                "동의기간 : " + _vm._s(_vm.agreeList[_vm.nowIdx].posttime)
              ),
            ]),
            _c("div", {
              staticClass: "boxing notice-con",
              staticStyle: { "min-height": "calc(30vh - 32px)" },
              domProps: {
                innerHTML: _vm._s(_vm.agreeList[_vm.nowIdx].content),
              },
            }),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.goAgree("T")
                    },
                  },
                },
                [_vm._v("동의")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big red",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.goAgree("F")
                    },
                  },
                },
                [_vm._v("미동의")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }