<template>
  <div class="tab-area" style="height: calc(90vh - 246px)">
    <div class="clearfix">
      <div class="bar-title small fl">접속기록</div>
    </div>
    <div class="boxing search-area">
      <dl>
        <dt>조회기간</dt>
        <dd>
          <common-date-picker
            :value="searchData.startDate"
            @change="onChangeStartDate"
          ></common-date-picker>
          <span>-</span>
          <common-date-picker
            :value="searchData.endDate"
            @change="onChangeEndDate"
          ></common-date-picker>
        </dd>
      </dl>
    </div>
    <div class="btn-group">
      <button type="button" class="btn big blue" @click="onSearch">검색</button>
    </div>
    <div class="caption-group mt10 clearfix">
      <div class="total-group fl">
        <span class="total"
          >전체 <strong>{{ totalCnt }}</strong
          >건</span
        >
      </div>
      <div class="btn-group fr">
        <select v-model="pagingData.pageCount">
          <option value="20">20개씩 보기</option>
          <option value="50">50개씩 보기</option>
          <option value="100">100개씩 보기</option>
        </select>
      </div>
    </div>
    <table cellpadding="0" cellspacing="0" class="data-tb align-c">
      <caption>
        접속 기록
      </caption>
      <colgroup>
        <col width="10%" />
        <!-- No -->
        <col width="15%" />
        <!-- 접속일자 -->
        <col width="15%" />
        <!-- IP -->
        <col width="20%" />
        <!-- 접속경로 -->
        <col width="20%" />
        <!-- 접속경로 -->
        <col width="20%" />
        <!-- 접속방법 -->
      </colgroup>
      <thead>
        <tr>
          <th>No</th>
          <th>접속일자</th>
          <th>IP</th>
          <th>로그인성공여부</th>
          <th>접속경로</th>
          <th>접속방법</th>
        </tr>
      </thead>
      <tbody v-if="this.list.length > 0">
        <tr v-for="(row, index) in this.list" :key="index">
          <td>{{ loopNumberForPaging(index) }}</td>
          <td>{{ row.regdate }}</td>
          <td>{{ row.ip }}</td>
          <td>{{ row.isloginsuccess }}</td>
          <td>{{ row.loginchtype }}</td>
          <td>{{ row.loginwaytype }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="14">조회 결과가 존재하지 않습니다.</td>
        </tr>
      </tbody>
    </table>
    <div class="bottom-group">
      <common-page-navigator :pagingData="pagingData" v-on:setPagingData="setPagingData" />
    </div>
  </div>
</template>

<script>
import CommonDatePicker from '@views.admin/common/CommonDatePicker';
import CommonPageNavigator from '@views.admin/common/CommonPageNavigator';

export default {
  name: 'AdminMemberLoginLog',
  props: ['activeUserNo'],
  components: { CommonDatePicker, CommonPageNavigator },
  data() {
    return {
      totalCnt: 0,
      searchData: {
        userno: this.activeUserNo,
        startDate: '', // 조회시작날짜
        endDate: '', // 조회종료날짜
        psort: 'regdate_desc', // 정렬
      },
      sortData: {
        regdate: 'regdate_desc', // 등록일자 정렬기준
      },
      pagingData: {
        pageCount: 20, // 페이징 옵션(최대수)
        page: 1, // 현재 페이지
        listCount: 0, // 총 수량
      },
      list: [],
      isFirstSearch: true, // 최초 조회 여부 (최초 조회여부가 true인 경우 setPagingData에서 조회하지 않는다.)
    };
  },
  mounted() {
    this.searchData.startDate = this.$util.getAddMonth(this.$util.getDate(), -3, '-');
    this.searchData.endDate = this.$util.getDate('-');
    this.onSearch();
  },
  methods: {
    onSearch() {
      let params = Object.assign(this.searchData, this.pagingData);
      this.$http
        .post('/admin/member/log/list', params)
        .then((result) => {
          this.list = result.data.list;
          this.totalCnt = result.data.count.totalcnt;
          this.pagingData.listCount = this.totalCnt;
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    loopNumberForPaging(index) {
      if (this.pagingData.page > 1) {
        let page = this.pagingData.page - 1;
        return index + 1 + page * this.pagingData.pageCount;
      } else {
        return index + 1;
      }
    },
    // 시작날짜 picker 콜백 함수
    onChangeStartDate: function (value) {
      this.searchData.startDate = value;
    },
    // 종료날짜 picker 콜백 함수
    onChangeEndDate: function (value) {
      this.searchData.endDate = value;
    },
    // 페이징데이터 세팅
    setPagingData(param) {
      this.pagingData = param;
      if (this.isFirstSearch == false) {
        this.onSearch();
      }
      if (this.isFirstSearch == true) {
        this.isFirstSearch = false;
      }
    },
    sortToggle(key) {
      let arr = key.split('_');
      let sortKey = arr[0];
      let sortOrder = arr[1] === 'asc' ? 'desc' : 'asc';
      let sortName = sortKey + '_' + sortOrder;

      this.sortData[sortKey] = sortName;
      this.searchData.psort = sortName;

      this.onSearch();
    },
  },
};
</script>

<style scoped />
