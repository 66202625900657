var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content m-leftmenu" },
    [
      _c("common-navigator"),
      _c(
        "div",
        { staticClass: "inner" },
        [
          _vm._l(_vm.exlist, function (row, n) {
            return [
              _c("div", { key: n + "a", staticClass: "bar-title" }, [
                _vm._v("전시영역 " + _vm._s(n + 1)),
              ]),
              _vm.isRead
                ? _c(
                    "table",
                    {
                      key: n,
                      staticClass: "gray-tb",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _vm._m(0, true),
                      _c("tbody", [
                        _c("tr", [
                          _vm._m(1, true),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.exlist[n].exhibitname,
                                  expression: "exlist[n].exhibitname",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "text",
                                placeholder: "전시영역 제목",
                              },
                              domProps: { value: _vm.exlist[n].exhibitname },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.exlist[n],
                                    "exhibitname",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("tr", [
                          _vm._m(2, true),
                          _c("td", [
                            _c("div", { staticClass: "radio_wrap wide" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.exlist[n].isintro,
                                    expression: "exlist[n].isintro",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "group00" + n,
                                  id: "group01" + n,
                                  value: "T",
                                  checked: "",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.exlist[n].isintro, "T"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.exlist[n],
                                      "isintro",
                                      "T"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "group01" + n } }, [
                                _vm._v("제공함"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.exlist[n].isintro,
                                    expression: "exlist[n].isintro",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "group00" + n,
                                  id: "group02" + n,
                                  value: "F",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.exlist[n].isintro, "F"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.exlist[n],
                                      "isintro",
                                      "F"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "group02" + n } }, [
                                _vm._v("제공안함"),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c(
                          "tr",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.exlist[n].isintro === "T",
                                expression: "exlist[n].isintro === 'T'",
                              },
                            ],
                          },
                          [
                            _vm._m(3, true),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.exlist[n].intro,
                                    expression: "exlist[n].intro",
                                  },
                                ],
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "text",
                                  placeholder: "소개문구 텍스트",
                                },
                                domProps: { value: _vm.exlist[n].intro },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.exlist[n],
                                      "intro",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          }),
          _c("div", { staticClass: "btn-group" }, [
            _vm.isWrite
              ? _c(
                  "button",
                  {
                    staticClass: "btn big blue",
                    attrs: { type: "button" },
                    on: { click: _vm.onSave },
                  },
                  [_vm._v("저장")]
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("전시영역명"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("소개문구 제공여부"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("전시영역 소개문구"),
      _c("i", { staticClass: "essential" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }