var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "900px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("메일 발송")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close-popup")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "bar-title small" }, [_vm._v("받는사람")]),
            _c(
              "table",
              {
                staticClass: "gray-tb",
                attrs: { cellpadding: "0", cellspacing: "0" },
              },
              [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("이름")]),
                    _c("td", [_vm._v(_vm._s(_vm.userInfo.name))]),
                    _c("th", [_vm._v("아이디")]),
                    _c("td", [_vm._v(_vm._s(_vm.userInfo.userid))]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("이메일")]),
                    _c("td", { attrs: { colspan: "3" } }, [
                      _vm._v(_vm._s(_vm.userInfo.email)),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "bar-title small" }, [
              _vm._v("메일 내용"),
            ]),
            _c(
              "table",
              {
                staticClass: "gray-tb",
                attrs: { cellpadding: "0", cellspacing: "0" },
              },
              [
                _vm._m(1),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("제목")]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.boardInfo.subject,
                            expression: "boardInfo.subject",
                          },
                        ],
                        staticStyle: { width: "100%" },
                        attrs: { type: "text" },
                        domProps: { value: _vm.boardInfo.subject },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.boardInfo,
                              "subject",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("내용")]),
                    _c("td", [_c("CommonEditor", { ref: "editor" })], 1),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.sendMail },
                },
                [_vm._v("메일보내기")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close-popup")
                    },
                  },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "120px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "120px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "120px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }