<template>
  <!-- 파트너 2차 인증(SMS) 팝업 -->
  <div id="modal-wrap" class="modal" style="display: block">
    <div class="modal-content" style="width: 800px">
      <div class="pop-header">
        <h2>인증번호 입력</h2>
        <button type="button" class="pop-close" @click="onClose"></button>
      </div>
      <div class="pop-body">
        <table cellpadding="0" cellspacing="0" class="gray-tb">
          <colgroup>
            <col width="150px" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th>전화번호<i class="essential"></i></th>
              <td>
                <input type="text" v-model="info.phone" disabled />
                <button
                  v-if="!timer.isStart && !authComplete"
                  type="button"
                  class="btn blue-line ml3"
                  @click="onSendAuthNo"
                >
                  인증번호 전송
                </button>
                <button
                  v-if="timer.isStart && !authComplete"
                  type="button"
                  class="btn blue-line ml3"
                  @click="onSendAuthNo"
                >
                  재발송
                </button>
              </td>
            </tr>
            <tr>
              <th>인증번호 입력<i class="essential"></i></th>
              <td>
                <input
                  type="text"
                  v-model="info.inputAuthNo"
                  placeholder="휴대폰 인증번호 6자리"
                  style="width: 194px"
                  :readonly="authComplete"
                /><span v-show="timer.isStart" style="color: red" class="ml3">{{
                  timer.expiredTime
                }}</span>
                <div class="mt10">인증번호 유효 시간은 발송 후 10분 이내 입니다.</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="btn-group">
          <button type="button" class="btn big blue" @click="onConfirm">확인</button>
          <button type="button" class="btn big darkgray" @click="onClose">취소</button>
        </div>
      </div>
    </div>
  </div>
  <!-- /운영자 등록 팝업 -->
</template>

<script>
export default {
  name: 'AdminFindPasswordPopup',
  props: {
    params: Object,
  },
  data() {
    return {
      authComplete: false,
      info: {
        userid: '',
        userno: 0,
        phone: '',
        authNo: '',
        inputAuthNo: '',
      },
      timer: {
        isStart: false,
        timeCounter: 600,
        expiredTime: '',
      },
    };
  },
  mounted() {
    //console.log(JSON.stringify(this.params));
    this.info.userid = this.params.userid;
    this.info.phone = this.params.mobile;
    this.info.userno = this.params.no;
  },
  methods: {
    onConfirm() {
      if (this.checkValidation()) {
        let param = {
          userno: this.info.userno,
          authNo: this.info.authNo,
          inputAuthNo: this.info.inputAuthNo,
          phone: this.info.phone,
        };

        this.$http
          .post('/partners/common/auth/confirm-message', param)
          .then((result) => {
            if (result.statusCode === 200) {
              alert('인증이 완료되었습니다.');
              this.authComplete = true;
              this.$eventBus.$emit('secondAuthSuccess', this.authComplete, this);
              this.$modal.hide('commonModal');
            }
          })
          .catch((error) => {
            this.$util.debug(error);
          });
      }
    },
    checkValidation() {
      if (this.timer.isStart == false) {
        alert('인증번호 유효시간이 만료하였습니다. 인증번호를 다시 전송하시기 바랍니다.');
        return false;
      }
      var patternMobile = /[0-9]{6}/;

      if (!patternMobile.test(this.info.inputAuthNo)) {
        alert('잘못된 인증번호입니다.');
        return false;
      } else {
        return true;
      }
    },
    onSendAuthNo() {
      if (
        typeof this.info.userno !== 'undefined' &&
        this.info.userno !== '' &&
        typeof this.info.phone !== 'undefined' &&
        this.info.phone !== ''
      ) {
        this.$http
          .post('/partners/common/auth/send-message', this.info)
          .then((result) => {
            if (result.statusCode === 200) {
              /**
               * 암호화 된 인증번호를 받아서 서버로 재전송
               */
              this.info.authNo = result.data.number;
              alert('모바일로 인증번호가 전송되었습니다.');
              this.info.inputAuthNo = '';
              this.timerStart();
            }
          })
          .catch((error) => {
            this.$util.debug(error);
          });
      }
    },
    timerStart() {
      this.timer.timeCounter = 600;
      this.timer.isStart = true;
      this.polling = setInterval(() => {
        this.timer.timeCounter--;
        this.secondsToMinuteSeconds();
        if (this.timer.timeCounter <= 0) {
          this.timeStop();
        }
      }, 1000);
    },
    timeStop() {
      this.timer.isStart = false;
      clearInterval(this.polling);
    },
    secondsToMinuteSeconds() {
      let minutes = Math.floor(this.timer.timeCounter / 60) % 60 || 0;
      let seconds = this.timer.timeCounter % 60 || 0;
      this.timer.expiredTime = [minutes, seconds]
        .map((v) => (v < 10 ? '0' + v : v))
        .filter((v, i) => v !== '00' || i > 0)
        .join(':');
    },
    onClose() {
      this.authComplete = false;
      this.$modal.hide('commonModal');
    },
  },
};
</script>

<style></style>
