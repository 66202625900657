var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        ref: "reqKMCISForm",
        attrs: {
          name: "reqKMCISForm",
          method: "post",
          action: "https://www.kmcert.com/kmcis/web/kmcisReq.jsp",
          target: "KMCISWindow",
        },
      },
      [
        _c("input", {
          ref: "trCert",
          attrs: { type: "hidden", name: "tr_cert" },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.kmcInfo.tr_url,
              expression: "kmcInfo.tr_url",
            },
          ],
          attrs: { type: "hidden", name: "tr_url" },
          domProps: { value: _vm.kmcInfo.tr_url },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.kmcInfo, "tr_url", $event.target.value)
            },
          },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.kmcInfo.tr_add,
              expression: "kmcInfo.tr_add",
            },
          ],
          attrs: { type: "hidden", name: "tr_add" },
          domProps: { value: _vm.kmcInfo.tr_add },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.kmcInfo, "tr_add", $event.target.value)
            },
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }