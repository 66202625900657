var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1200px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("자주 묻는 질문 상세")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "boxing" }, [
              _c("div", { staticClass: "form-area" }, [
                _c("dl", [
                  _c("dt", [_vm._v("작성자")]),
                  _c("dd", [_c("p", [_vm._v(_vm._s(_vm.boardInfo.writer))])]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("등록일")]),
                  _c("dd", [_c("p", [_vm._v(_vm._s(_vm.boardInfo.regdate))])]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("분류")]),
                  _c("dd", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.boardInfo.faqtype,
                            expression: "boardInfo.faqtype",
                          },
                        ],
                        ref: "faqType",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.boardInfo,
                              "faqtype",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.commonCode.faqtype, function (row, i) {
                        return _c(
                          "option",
                          { key: i, domProps: { value: row.cmcode } },
                          [_vm._v(" " + _vm._s(row.codename) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("노출대상")]),
                  _c(
                    "dd",
                    [
                      _c("div", { staticClass: "check-wrap" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.isallmumember,
                              expression: "isallmumember",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "rd1010",
                            "true-value": "T",
                            "false-value": "F",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.isallmumember)
                              ? _vm._i(_vm.isallmumember, null) > -1
                              : _vm._q(_vm.isallmumember, "T"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.checkAllMuMemer($event.target.checked)
                            },
                            change: function ($event) {
                              var $$a = _vm.isallmumember,
                                $$el = $event.target,
                                $$c = $$el.checked ? "T" : "F"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.isallmumember = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.isallmumember = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.isallmumember = $$c
                              }
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "rd1010" } }, [
                          _vm._v("전체"),
                        ]),
                      ]),
                      _vm._l(_vm.commonCode.mumembertype, function (row, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "check-wrap" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.mumemberTypeChecked,
                                  expression: "mumemberTypeChecked",
                                },
                              ],
                              attrs: { type: "checkbox", id: "murd12" + i },
                              domProps: {
                                value: row.cmcode,
                                checked: Array.isArray(_vm.mumemberTypeChecked)
                                  ? _vm._i(
                                      _vm.mumemberTypeChecked,
                                      row.cmcode
                                    ) > -1
                                  : _vm.mumemberTypeChecked,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.mumemberTypeChecked,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = row.cmcode,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.mumemberTypeChecked = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.mumemberTypeChecked = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.mumemberTypeChecked = $$c
                                  }
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "murd12" + i } }, [
                              _vm._v(_vm._s(row.codename)),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("사용여부")]),
                  _c("dd", [
                    _c("div", { staticClass: "radio_wrap wide" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.boardInfo.istrash,
                            expression: "boardInfo.istrash",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "rad",
                          id: "rd11",
                          value: "F",
                        },
                        domProps: {
                          checked: _vm._q(_vm.boardInfo.istrash, "F"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.boardInfo, "istrash", "F")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rd11" } }, [_vm._v("사용")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.boardInfo.istrash,
                            expression: "boardInfo.istrash",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "rad",
                          id: "rd12",
                          value: "T",
                        },
                        domProps: {
                          checked: _vm._q(_vm.boardInfo.istrash, "T"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.boardInfo, "istrash", "T")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rd12" } }, [
                        _vm._v("미사용"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("메인노출")]),
                  _c("dd", [
                    _c("div", { staticClass: "radio_wrap wide" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.boardInfo.isfaqmain,
                            expression: "boardInfo.isfaqmain",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "rad2",
                          id: "rd113",
                          value: "T",
                        },
                        domProps: {
                          checked: _vm._q(_vm.boardInfo.isfaqmain, "T"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.boardInfo, "isfaqmain", "T")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rd113" } }, [
                        _vm._v("노출"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.boardInfo.isfaqmain,
                            expression: "boardInfo.isfaqmain",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "rad2",
                          id: "rd123",
                          value: "F",
                        },
                        domProps: {
                          checked: _vm._q(_vm.boardInfo.isfaqmain, "F"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.boardInfo, "isfaqmain", "F")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rd123" } }, [
                        _vm._v("미노출"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("제목")]),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.boardInfo.subject,
                          expression: "boardInfo.subject",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text" },
                      domProps: { value: _vm.boardInfo.subject },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.boardInfo,
                            "subject",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "boxing" },
              [
                _c("CommonEditor", {
                  ref: "editor",
                  attrs: { "style-object": { height: 300 + "px" } },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "btn-group" }, [
              _vm.isWrite
                ? _c(
                    "button",
                    {
                      staticClass: "btn big blue",
                      attrs: { type: "button" },
                      on: { click: _vm.goSave },
                    },
                    [_vm._v("저장")]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }