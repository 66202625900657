var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1600px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("상세 내역")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c(
              "table",
              {
                staticClass: "gray-tb align-c",
                attrs: { cellpadding: "0", cellspacing: "0" },
              },
              [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("파트너사명")]),
                    _c("td", [_vm._v(_vm._s(_vm.info.name))]),
                    _c("th", [_vm._v("파트너사코드")]),
                    _c("td", [_vm._v(_vm._s(_vm.info.userno))]),
                    _c("th", [_vm._v("사업자등록번호")]),
                    _c("td", [_vm._v(_vm._s(_vm.info.bizno))]),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "boxing search-area" }, [
              _c("dl", [
                _c("dt", [_vm._v("조회기간")]),
                _c(
                  "dd",
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.datetype,
                            expression: "searchData.datetype",
                          },
                        ],
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "datetype",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "complete" } }, [
                          _vm._v("완료일"),
                        ]),
                        _c("option", { attrs: { value: "apply" } }, [
                          _vm._v("신청일"),
                        ]),
                      ]
                    ),
                    _c("CommonDatePicker", {
                      attrs: { value: _vm.searchData.startdate },
                      on: { change: _vm.onChangeStartDate },
                    }),
                    _c("span", [_vm._v("-")]),
                    _c("CommonDatePicker", {
                      attrs: { value: _vm.searchData.enddate },
                      on: { change: _vm.onChangeEndDate },
                    }),
                    _c("div", { staticClass: "radio_wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.period,
                            expression: "searchData.period",
                          },
                        ],
                        attrs: { type: "radio", id: "rd1", value: "-1" },
                        domProps: {
                          checked: _vm._q(_vm.searchData.period, "-1"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.searchData, "period", "-1")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rd1" } }, [_vm._v("어제")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.period,
                            expression: "searchData.period",
                          },
                        ],
                        attrs: { type: "radio", id: "rd2", value: "0" },
                        domProps: {
                          checked: _vm._q(_vm.searchData.period, "0"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.searchData, "period", "0")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rd2" } }, [_vm._v("오늘")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.period,
                            expression: "searchData.period",
                          },
                        ],
                        attrs: { type: "radio", id: "rd3", value: "7" },
                        domProps: {
                          checked: _vm._q(_vm.searchData.period, "7"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.searchData, "period", "7")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rd3" } }, [
                        _vm._v("일주일"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.period,
                            expression: "searchData.period",
                          },
                        ],
                        attrs: { type: "radio", id: "rd4", value: "1" },
                        domProps: {
                          checked: _vm._q(_vm.searchData.period, "1"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.searchData, "period", "1")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rd4" } }, [_vm._v("1개월")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.period,
                            expression: "searchData.period",
                          },
                        ],
                        attrs: { type: "radio", id: "rd5", value: "3" },
                        domProps: {
                          checked: _vm._q(_vm.searchData.period, "3"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.searchData, "period", "3")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rd5" } }, [_vm._v("3개월")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.period,
                            expression: "searchData.period",
                          },
                        ],
                        attrs: { type: "radio", id: "rd6", value: "6" },
                        domProps: {
                          checked: _vm._q(_vm.searchData.period, "6"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.searchData, "period", "6")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rd6" } }, [_vm._v("6개월")]),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn blue",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.onSearch(1)
                          },
                        },
                      },
                      [_vm._v("검색")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "caption-group mt10 clearfix" }, [
              _c("div", { staticClass: "total-group fl" }, [
                _c("span", { staticClass: "total" }, [
                  _vm._v(" 상세내역 "),
                  _c("strong", [_vm._v(_vm._s(_vm.totalCnt))]),
                  _vm._v(" 건 "),
                ]),
              ]),
              _c("div", { staticClass: "btn-group fr" }, [
                _vm.isAdmin
                  ? _c(
                      "button",
                      {
                        staticClass: "btn green-line",
                        attrs: { type: "button" },
                        on: { click: _vm.excelDown },
                      },
                      [
                        _c("i", { staticClass: "icon-excel" }),
                        _vm._v(" 엑셀다운로드 "),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pagingData.pageCount,
                        expression: "pagingData.pageCount",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.pagingData,
                          "pageCount",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "20" } }, [
                      _vm._v("20개씩 보기"),
                    ]),
                    _c("option", { attrs: { value: "50" } }, [
                      _vm._v("50개씩 보기"),
                    ]),
                    _c("option", { attrs: { value: "100" } }, [
                      _vm._v("100개씩 보기"),
                    ]),
                    _c("option", { attrs: { value: "200" } }, [
                      _vm._v("200개씩 보기"),
                    ]),
                    _c("option", { attrs: { value: "300" } }, [
                      _vm._v("300개씩 보기"),
                    ]),
                    _c("option", { attrs: { value: "400" } }, [
                      _vm._v("400개씩 보기"),
                    ]),
                    _c("option", { attrs: { value: "500" } }, [
                      _vm._v("500개씩 보기"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "scroll-x" }, [
              _c(
                "table",
                {
                  staticClass: "data-tb align-c",
                  staticStyle: { "min-width": "2800px" },
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(1),
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { rowspan: "2" } }, [_vm._v("No")]),
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _vm._v(" 구분 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.type === "type_asc" },
                            { down: _vm.sortData.type === "type_desc" },
                          ],
                          attrs: { type: "button", value: _vm.sortData.type },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.type)
                            },
                          },
                        }),
                      ]),
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _vm._v(" 주문번호 "),
                        _c("br"),
                        _vm._v(" /클레임번호 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.ordno === "ordno_asc" },
                            { down: _vm.sortData.ordno === "ordno_desc" },
                          ],
                          attrs: { type: "button", value: _vm.sortData.ordno },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.ordno)
                            },
                          },
                        }),
                      ]),
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _vm._v(" 주문 "),
                        _c("br"),
                        _vm._v(" /클레임일시 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.orderdate === "orderdate_asc" },
                            {
                              down: _vm.sortData.orderdate === "orderdate_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.orderdate,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.orderdate)
                            },
                          },
                        }),
                      ]),
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _vm._v(" 상품코드 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.goodsno === "goodsno_asc" },
                            { down: _vm.sortData.goodsno === "goodsno_desc" },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.goodsno,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.goodsno)
                            },
                          },
                        }),
                      ]),
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _vm._v(" 단품코드 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            {
                              up: _vm.sortData.optioncode === "optioncode_asc",
                            },
                            {
                              down:
                                _vm.sortData.optioncode === "optioncode_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.optioncode,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.optioncode)
                            },
                          },
                        }),
                      ]),
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _vm._v(" 상품순번 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.turn === "turn_asc" },
                            { down: _vm.sortData.turn === "turn_desc" },
                          ],
                          attrs: { type: "button", value: _vm.sortData.turn },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.turn)
                            },
                          },
                        }),
                      ]),
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _vm._v(" 상품명 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.goodsname === "goodsname_asc" },
                            {
                              down: _vm.sortData.goodsname === "goodsname_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.goodsname,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.goodsname)
                            },
                          },
                        }),
                      ]),
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _vm._v(" 옵션명 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            {
                              up: _vm.sortData.optionname === "optionname_asc",
                            },
                            {
                              down:
                                _vm.sortData.optionname === "optionname_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.optionname,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.optionname)
                            },
                          },
                        }),
                      ]),
                      _vm._m(2),
                      _c(
                        "th",
                        {
                          staticClass: "tk-top tk-bottom",
                          attrs: { rowspan: "2" },
                        },
                        [_vm._v("부가세(판매가)")]
                      ),
                      _vm._m(3),
                      _vm._m(4),
                      _vm._m(5),
                      _vm._m(6),
                      _vm._m(7),
                      _c(
                        "th",
                        {
                          staticClass: "tk-top tk-bottom",
                          attrs: { rowspan: "2" },
                        },
                        [_vm._v("플랫폼수수료")]
                      ),
                      _vm._m(8),
                      _c(
                        "th",
                        {
                          staticClass: "tk-left tk-top",
                          attrs: { colspan: "2" },
                        },
                        [_vm._v("기본할인프로모션")]
                      ),
                      _c(
                        "th",
                        { staticClass: "tk-top", attrs: { colspan: "2" } },
                        [_vm._v("상품쿠폰")]
                      ),
                      _c(
                        "th",
                        { staticClass: "tk-top", attrs: { colspan: "2" } },
                        [_vm._v("장바구니쿠폰")]
                      ),
                      _c(
                        "th",
                        { staticClass: "tk-top", attrs: { colspan: "2" } },
                        [_vm._v("배송비쿠폰")]
                      ),
                      _vm._m(9),
                      _vm._m(10),
                      _vm._m(11),
                      _c(
                        "th",
                        { staticClass: "tk-left", attrs: { rowspan: "2" } },
                        [
                          _vm._v(" 주문상태 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up: _vm.sortData.ordstatus === "ordstatus_asc",
                              },
                              {
                                down:
                                  _vm.sortData.ordstatus === "ordstatus_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.ordstatus,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.ordstatus)
                              },
                            },
                          }),
                        ]
                      ),
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _vm._v(" 완료일시 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            {
                              up:
                                _vm.sortData.completedate ===
                                "completedate_asc",
                            },
                            {
                              down:
                                _vm.sortData.completedate ===
                                "completedate_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.completedate,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.completedate)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._m(12),
                  ]),
                  _vm.detailList.length > 0
                    ? _c(
                        "tbody",
                        _vm._l(_vm.detailList, function (row, index) {
                          return _c("tr", { key: index }, [
                            _c("td", [
                              _vm._v(_vm._s(_vm.loopNumberForPaging(index))),
                            ]),
                            _c("td", [_vm._v(_vm._s(row.type))]),
                            _c("td", [_vm._v(_vm._s(row.ordno))]),
                            _c("td", [
                              _vm._v(" " + _vm._s(row.orddate) + " "),
                              _c("br"),
                              _vm._v(" " + _vm._s(row.ordtime) + " "),
                            ]),
                            _c("td", [_vm._v(_vm._s(row.goodsno))]),
                            _c("td", [_vm._v(_vm._s(row.optioncode))]),
                            _c("td", [_vm._v(_vm._s(row.goodsturn))]),
                            _c("td", { staticClass: "left" }, [
                              _vm._v(_vm._s(row.goodsname)),
                            ]),
                            _c("td", { staticClass: "left" }, [
                              _vm._v(_vm._s(row.optionname)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.price)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.vatamt)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.saleamtmvat)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.calcTotalAmount(row.price, row.cnt)
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.commrate) + "%"),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.supplyamt)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.supplyvatamt)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.platformcommamt || "-")),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.cnt)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.salepromoamt)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.promoshareamt)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.goodscpnamt)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.cpnshareamt)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.basketcpnamt)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.basketcpnshareamt)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.shippingcouponamount)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.shippingcouponshareamount)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.delivamt)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.addrpaytotprice)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(row.calcamt)),
                            ]),
                            _c("td", [_vm._v(_vm._s(row.statusname))]),
                            _c("td", [
                              _vm._v(" " + _vm._s(row.comdate) + " "),
                              _c("br"),
                              _vm._v(" " + _vm._s(row.comtime) + " "),
                            ]),
                          ])
                        }),
                        0
                      )
                    : _c("tbody", [_vm._m(13)]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "bottom-group" },
              [
                _c("common-page-navigator", {
                  attrs: { pagingData: _vm.pagingData },
                  on: { setPagingData: _vm.setPagingData },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "btn-group mt20" }, [
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "300px" } }),
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "300px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "1.5%" } }),
      _c("col", { attrs: { width: "2.5%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "4%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "tk-left tk-top tk-bottom", attrs: { rowspan: "2" } },
      [_vm._v(" 판매단가 "), _c("br"), _vm._v(" ⓐ ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "tk-top tk-bottom", attrs: { rowspan: "2" } },
      [_vm._v(" 판매단가(VAT제외) "), _c("br"), _vm._v(" ⓑ ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "tk-top tk-bottom", attrs: { rowspan: "2" } },
      [_vm._v(" 총판매금액 "), _c("br"), _vm._v(" ⓐ*ⓗ ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "tk-left tk-top tk-bottom", attrs: { rowspan: "2" } },
      [_vm._v(" 수수료율 "), _c("br"), _vm._v(" ⓒ ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "tk-top tk-bottom", attrs: { rowspan: "2" } },
      [_vm._v(" 공급단가 "), _c("br"), _vm._v(" ⓓ(ⓑ*ⓒ) ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "tk-top tk-bottom", attrs: { rowspan: "2" } },
      [_vm._v(" 부가세(공급단가) "), _c("br"), _vm._v(" ⓔ ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "tk-left tk-top tk-bottom", attrs: { rowspan: "2" } },
      [_vm._v(" 판매수량 "), _c("br"), _vm._v(" ⓗ ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "tk-left tk-top tk-bottom", attrs: { rowspan: "2" } },
      [_vm._v(" 기본배송비 "), _c("br"), _vm._v(" ⓘ ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "tk-top tk-bottom", attrs: { rowspan: "2" } },
      [_vm._v(" 추가배송비 "), _c("br"), _vm._v(" ⓙ ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "tk-left tk-top tk-bottom", attrs: { rowspan: "2" } },
      [_vm._v(" 정산금액 "), _c("br"), _vm._v(" (ⓐ-ⓓ-ⓔ)*ⓗ-ⓕ-ⓖ-ⓚ-ⓛ+ⓘ+ⓙ ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticClass: "bg-yellow tk-left tk-bottom" }, [
        _vm._v("전체금액"),
      ]),
      _c("th", { staticClass: "bg-yellow tk-bottom" }, [
        _vm._v(" 분담금 "),
        _c("br"),
        _vm._v(" ⓕ "),
      ]),
      _c("th", { staticClass: "bg-yellow tk-bottom" }, [_vm._v("전체금액")]),
      _c("th", { staticClass: "bg-yellow tk-bottom" }, [
        _vm._v(" 분담금 "),
        _c("br"),
        _vm._v(" ⓖ "),
      ]),
      _c("th", { staticClass: "bg-yellow tk-bottom" }, [_vm._v("전체금액")]),
      _c("th", { staticClass: "bg-yellow tk-bottom" }, [
        _vm._v(" 분담금 "),
        _c("br"),
        _vm._v(" ⓚ "),
      ]),
      _c("th", { staticClass: "bg-yellow tk-bottom" }, [_vm._v("전체금액")]),
      _c("th", { staticClass: "bg-yellow tk-bottom" }, [
        _vm._v(" 분담금 "),
        _c("br"),
        _vm._v(" ⓛ "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "24" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }