var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1400px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("D포인트 수동지급")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "bar-title" }, [_vm._v("기본정보")]),
            _c("div", { staticClass: "boxing" }, [
              _c("div", { staticClass: "form-area" }, [
                _c("dl", [
                  _vm._m(0),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.boardInfo.eponame,
                          expression: "boardInfo.eponame",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", placeholder: "관리제목" },
                      domProps: { value: _vm.boardInfo.eponame },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.boardInfo,
                            "eponame",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "bar-title" }, [_vm._v("조건설정")]),
            _c("div", { staticClass: "form-area" }, [
              _c(
                "table",
                {
                  staticClass: "gray-tb",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(1),
                  _c("tbody", [
                    _c("tr", [
                      _vm._m(2),
                      _c("td", [
                        _c("div", { staticClass: "radio_wrap wide dpib" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.isepointdup,
                                expression: "boardInfo.isepointdup",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "group00",
                              id: "group01",
                              value: "T",
                            },
                            domProps: {
                              checked: _vm._q(_vm.boardInfo.isepointdup, "T"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(
                                  _vm.boardInfo,
                                  "isepointdup",
                                  "T"
                                )
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "group01" } }, [
                            _vm._v("허용"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.isepointdup,
                                expression: "boardInfo.isepointdup",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "group00",
                              id: "group02",
                              value: "F",
                            },
                            domProps: {
                              checked: _vm._q(_vm.boardInfo.isepointdup, "F"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(
                                  _vm.boardInfo,
                                  "isepointdup",
                                  "F"
                                )
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "group02" } }, [
                            _vm._v("미허용"),
                          ]),
                        ]),
                        _vm._m(3),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(4),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.boardInfo.eporeason,
                              expression: "boardInfo.eporeason",
                            },
                          ],
                          staticClass: "dpib",
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "text",
                            placeholder:
                              "이벤트 제목, C/S 관련 문구 등을 작성 (사용자에게 노출되는 적립사유)",
                          },
                          domProps: { value: _vm.boardInfo.eporeason },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.boardInfo,
                                "eporeason",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(5),
                      _c("td", [
                        _c("div", { staticClass: "radio_wrap wide dpib" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.isnowpay,
                                expression: "boardInfo.isnowpay",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "group01",
                              id: "group11",
                              value: "T",
                            },
                            domProps: {
                              checked: _vm._q(_vm.boardInfo.isnowpay, "T"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.boardInfo, "isnowpay", "T")
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "group11" } }, [
                            _vm._v("즉시지급"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.isnowpay,
                                expression: "boardInfo.isnowpay",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "group01",
                              id: "group12",
                              value: "F",
                            },
                            domProps: {
                              checked: _vm._q(_vm.boardInfo.isnowpay, "F"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.boardInfo, "isnowpay", "F")
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "group12" } }, [
                            _vm._v("일자설정"),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.boardInfo.isnowpay === "F",
                                expression: "boardInfo.isnowpay === 'F'",
                              },
                            ],
                            staticClass: "dpib",
                          },
                          [
                            _c("CommonDatePickerFromTo", {
                              attrs: {
                                toYYYYMMDD: _vm.epointTimeInfo.toYYYYMMDD,
                                toHH: _vm.epointTimeInfo.toHH,
                                toMM: _vm.epointTimeInfo.toMM,
                                "use-to": true,
                                "use-from": false,
                              },
                              on: { getDate: _vm.getEventTimeDate },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(6),
                      _c("td", [
                        _c("div", { staticClass: "dpb" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.isDay,
                                expression: "isDay",
                              },
                            ],
                            staticClass: "circle",
                            attrs: {
                              type: "radio",
                              name: "group02",
                              id: "group21",
                              value: "T",
                            },
                            domProps: { checked: _vm._q(_vm.isDay, "T") },
                            on: {
                              change: function ($event) {
                                _vm.isDay = "T"
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "group21" } }, [
                            _vm._v("지급 후"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.epousedaydnt,
                                expression: "boardInfo.epousedaydnt",
                              },
                            ],
                            staticClass: "right ml3",
                            staticStyle: { width: "80px" },
                            attrs: { type: "text" },
                            domProps: { value: _vm.boardInfo.epousedaydnt },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.boardInfo,
                                  "epousedaydnt",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c("span", { staticClass: "ml3" }, [
                            _vm._v("일간 사용 가능(최대 30일)"),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "dpb" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.isDay,
                                  expression: "isDay",
                                },
                              ],
                              staticClass: "circle",
                              attrs: {
                                type: "radio",
                                name: "group02",
                                value: "F",
                                id: "group22",
                              },
                              domProps: { checked: _vm._q(_vm.isDay, "F") },
                              on: {
                                change: function ($event) {
                                  _vm.isDay = "F"
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "group22" } }, [
                              _vm._v("지급 후"),
                            ]),
                            _c("CommonDatePicker", {
                              attrs: { value: _vm.boardInfo.epovaliddayformat },
                              on: { change: _vm.onChangeValidDay },
                            }),
                            _c("span", { staticClass: "ml3" }, [
                              _vm._v("23:59까지 사용 가능"),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(7),
                      _c("td", [
                        _c("div", { staticClass: "radio_wrap" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.ismemtype,
                                expression: "boardInfo.ismemtype",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "group03",
                              id: "group31",
                              value: "T",
                            },
                            domProps: {
                              checked: _vm._q(_vm.boardInfo.ismemtype, "T"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.boardInfo, "ismemtype", "T")
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "group31" } }, [
                            _vm._v("특정 유형/등급 대상"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.ismemtype,
                                expression: "boardInfo.ismemtype",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "group03",
                              id: "group32",
                              value: "F",
                            },
                            domProps: {
                              checked: _vm._q(_vm.boardInfo.ismemtype, "F"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.boardInfo, "ismemtype", "F")
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "group32" } }, [
                            _vm._v("특정 회원 대상"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm.boardInfo.ismemtype === "T"
                      ? _c("tr", [
                          _vm._m(8),
                          _c(
                            "td",
                            [
                              _c("div", { staticClass: "check-wrap" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checkObj.isallmumember,
                                      expression: "checkObj.isallmumember",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "all2",
                                    "true-value": "T",
                                    "false-value": "F",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.checkObj.isallmumember
                                    )
                                      ? _vm._i(
                                          _vm.checkObj.isallmumember,
                                          null
                                        ) > -1
                                      : _vm._q(_vm.checkObj.isallmumember, "T"),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.checkObj.isallmumember,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? "T" : "F"
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.checkObj,
                                                "isallmumember",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.checkObj,
                                                "isallmumember",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.checkObj,
                                            "isallmumember",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        return _vm.checkAllMuMemer(
                                          $event.target.checked
                                        )
                                      },
                                    ],
                                  },
                                }),
                                _c("label", { attrs: { for: "all2" } }, [
                                  _vm._v("전체"),
                                ]),
                              ]),
                              _vm._l(
                                _vm.pageCode.mumembertype,
                                function (row, i) {
                                  return _c(
                                    "div",
                                    { key: i, staticClass: "check-wrap" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.checkObj.mumemberTypeChecked,
                                            expression:
                                              "checkObj.mumemberTypeChecked",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "group24" + i,
                                        },
                                        domProps: {
                                          value: row.cmcode,
                                          checked: Array.isArray(
                                            _vm.checkObj.mumemberTypeChecked
                                          )
                                            ? _vm._i(
                                                _vm.checkObj
                                                  .mumemberTypeChecked,
                                                row.cmcode
                                              ) > -1
                                            : _vm.checkObj.mumemberTypeChecked,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.checkObj
                                                  .mumemberTypeChecked,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = row.cmcode,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.checkObj,
                                                    "mumemberTypeChecked",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.checkObj,
                                                    "mumemberTypeChecked",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.checkObj,
                                                "mumemberTypeChecked",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: "group24" + i } },
                                        [_vm._v(_vm._s(row.codename))]
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm.boardInfo.ismemtype === "T"
                      ? _c("tr", [
                          _vm._m(9),
                          _c(
                            "td",
                            [
                              _c("div", { staticClass: "check-wrap" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checkObj.isallmumemlv,
                                      expression: "checkObj.isallmumemlv",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "all3",
                                    "true-value": "T",
                                    "false-value": "F",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.checkObj.isallmumemlv
                                    )
                                      ? _vm._i(
                                          _vm.checkObj.isallmumemlv,
                                          null
                                        ) > -1
                                      : _vm._q(_vm.checkObj.isallmumemlv, "T"),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.checkObj.isallmumemlv,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? "T" : "F"
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.checkObj,
                                                "isallmumemlv",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.checkObj,
                                                "isallmumemlv",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.checkObj,
                                            "isallmumemlv",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        return _vm.checkAllMuMemLv(
                                          $event.target.checked
                                        )
                                      },
                                    ],
                                  },
                                }),
                                _c("label", { attrs: { for: "all3" } }, [
                                  _vm._v("전체"),
                                ]),
                              ]),
                              _vm._l(
                                _vm.pageCode.mumemlvtype,
                                function (row, i) {
                                  return _c(
                                    "div",
                                    { key: i, staticClass: "check-wrap" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.checkObj.mumemlvTypeChecked,
                                            expression:
                                              "checkObj.mumemlvTypeChecked",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "group35" + i,
                                        },
                                        domProps: {
                                          value: row.cmcode,
                                          checked: Array.isArray(
                                            _vm.checkObj.mumemlvTypeChecked
                                          )
                                            ? _vm._i(
                                                _vm.checkObj.mumemlvTypeChecked,
                                                row.cmcode
                                              ) > -1
                                            : _vm.checkObj.mumemlvTypeChecked,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.checkObj.mumemlvTypeChecked,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = row.cmcode,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.checkObj,
                                                    "mumemlvTypeChecked",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.checkObj,
                                                    "mumemlvTypeChecked",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.checkObj,
                                                "mumemlvTypeChecked",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: "group35" + i } },
                                        [_vm._v(_vm._s(row.codename))]
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm.boardInfo.ismemtype === "F"
                      ? _c("tr", [
                          _vm._m(10),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "caption-group clearfix dpb" },
                              [
                                _vm._m(11),
                                _c("div", { staticClass: "btn-group fr" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn black-line",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadExcelTemplate(
                                            "MemberTemplate.xlsx"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 양식 다운로드 ")]
                                  ),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "btn green-line",
                                      staticStyle: { "margin-right": "2px" },
                                      attrs: { for: "input-file" },
                                    },
                                    [_vm._v("엑셀파일 올리기")]
                                  ),
                                  _c("input", {
                                    ref: "excelFiles",
                                    staticStyle: { display: "none" },
                                    attrs: {
                                      type: "file",
                                      id: "input-file",
                                      accept:
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onExcelRead($event)
                                      },
                                    },
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line",
                                      attrs: { type: "button" },
                                      on: { click: _vm.openAddUserPopup },
                                    },
                                    [_vm._v(" 회원추가 ")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn red-line",
                                      attrs: { type: "button" },
                                      on: { click: _vm.onDeleteUser },
                                    },
                                    [_vm._v("삭제")]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "scroll-y",
                                staticStyle: {
                                  width: "100%",
                                  "max-height": "400px",
                                  "margin-bottom": "0",
                                },
                              },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass: "data-tb align-c",
                                    staticStyle: { "margin-left": "0" },
                                    attrs: {
                                      cellpadding: "0",
                                      cellspacing: "0",
                                    },
                                  },
                                  [
                                    _c("caption", [_vm._v(" 특정회원목록 ")]),
                                    _vm._m(12),
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", [
                                          _c("input", {
                                            attrs: {
                                              type: "checkbox",
                                              id: "chkall",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onUserCheckAll(
                                                  $event.target.checked
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                        _c("th", [_vm._v("No")]),
                                        _c("th", [_vm._v("아이디")]),
                                        _c("th", [_vm._v("이름")]),
                                        _c("th", [
                                          _vm._v(" 유형 "),
                                          _c("button", {
                                            staticClass: "sort",
                                            class: [
                                              {
                                                up:
                                                  _vm.sortData
                                                    .dadamembertype ===
                                                  "dadamembertype_asc",
                                              },
                                              {
                                                down:
                                                  _vm.sortData
                                                    .dadamembertype ===
                                                  "dadamembertype_desc",
                                              },
                                            ],
                                            attrs: {
                                              type: "button",
                                              value:
                                                _vm.sortData.dadamembertype,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sortToggle(
                                                  _vm.sortData.dadamembertype
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                        _c("th", [
                                          _vm._v(" 등급 "),
                                          _c("button", {
                                            staticClass: "sort",
                                            class: [
                                              {
                                                up:
                                                  _vm.sortData.memlvtype ===
                                                  "memlvtype_asc",
                                              },
                                              {
                                                down:
                                                  _vm.sortData.memlvtype ===
                                                  "memlvtype_desc",
                                              },
                                            ],
                                            attrs: {
                                              type: "button",
                                              value: _vm.sortData.memlvtype,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sortToggle(
                                                  _vm.sortData.memlvtype
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                        _c("th", [
                                          _vm._v(" 가입일 "),
                                          _c("button", {
                                            staticClass: "sort",
                                            class: [
                                              {
                                                up:
                                                  _vm.sortData.regdate ===
                                                  "regdate_asc",
                                              },
                                              {
                                                down:
                                                  _vm.sortData.regdate ===
                                                  "regdate_desc",
                                              },
                                            ],
                                            attrs: {
                                              type: "button",
                                              value: _vm.sortData.regdate,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sortToggle(
                                                  _vm.sortData.regdate
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(_vm.memberList, function (row, i) {
                                        return _c("tr", { key: i }, [
                                          _c("td", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.memberCheckList,
                                                  expression: "memberCheckList",
                                                },
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                id: "chk01_enter_0" + i,
                                              },
                                              domProps: {
                                                value: row,
                                                checked: Array.isArray(
                                                  _vm.memberCheckList
                                                )
                                                  ? _vm._i(
                                                      _vm.memberCheckList,
                                                      row
                                                    ) > -1
                                                  : _vm.memberCheckList,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.memberCheckList,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = row,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.memberCheckList =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.memberCheckList =
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                    }
                                                  } else {
                                                    _vm.memberCheckList = $$c
                                                  }
                                                },
                                              },
                                            }),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(_vm.$util.addZero(i + 1))
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(row.userid)),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(row.username)),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(row.dadamembertypename)
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(row.memlvtypename)),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(row.regdate)),
                                          ]),
                                        ])
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("tr", [
                      _vm._m(13),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.boardInfo.payepoint,
                              expression: "boardInfo.payepoint",
                            },
                          ],
                          staticClass: "right",
                          staticStyle: { width: "80px" },
                          attrs: { type: "number" },
                          domProps: { value: _vm.boardInfo.payepoint },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.boardInfo,
                                "payepoint",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("span", { staticClass: "ml3" }, [_vm._v("포인트")]),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(14),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.boardInfo.uselimitordamt,
                              expression: "boardInfo.uselimitordamt",
                            },
                          ],
                          staticClass: "right",
                          staticStyle: { width: "80px" },
                          attrs: { type: "number" },
                          domProps: { value: _vm.boardInfo.uselimitordamt },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.boardInfo,
                                "uselimitordamt",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("span", { staticClass: "ml3" }, [
                          _vm._v("원 이상 결제 시 1회 주문 당 최대"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.boardInfo.uselimitpoint,
                              expression: "boardInfo.uselimitpoint",
                            },
                          ],
                          staticClass: "right",
                          staticStyle: { width: "80px" },
                          attrs: { type: "number" },
                          domProps: { value: _vm.boardInfo.uselimitpoint },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.boardInfo,
                                "uselimitpoint",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("span", { staticClass: "ml3" }, [
                          _vm._v("포인트까지 사용 가능(실결제금액 기준)"),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "btn-group" }, [
              _vm.isWrite
                ? _c(
                    "button",
                    {
                      staticClass: "btn big blue",
                      attrs: { type: "button" },
                      on: { click: _vm.goSave },
                    },
                    [_vm._v("저장")]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("취소")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_vm._v("관리제목"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("D포인트 중복사용여부"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "txt-orange ml10" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v("적립금과 함께 사용 가능한지의 여부"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("지급사유"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("지급일"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("D포인트 유효기간"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("대상범위"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("대상회원유형"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("대상회원등급"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("대상회원"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "total-group fl" }, [
      _c("span", { staticClass: "total" }, [_vm._v("적용대상 회원목록")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("지급포인트"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("사용제한"), _c("i", { staticClass: "essential" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }