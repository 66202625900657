var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1400px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("매거진카테고리관리 상세")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "gray-box mg0 clearfix" }, [
              _c("div", { staticClass: "fl" }, [
                _c("span", [_vm._v("작성자 : " + _vm._s(_vm.info.reguserid))]),
              ]),
              _c("div", { staticClass: "fr txt-gray" }, [
                _c("span", [_vm._v("등록일 : " + _vm._s(_vm.info.regdate))]),
                _c("span", { staticClass: "left-bar" }, [
                  _vm._v("수정일 : " + _vm._s(_vm.info.moddate) + " "),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "bar-title" }, [_vm._v("기본정보")]),
            _c("div", { staticClass: "boxing" }, [
              _c("div", { staticClass: "form-area" }, [
                _c("dl", [
                  _vm._m(0),
                  _c("dd", [
                    _c("div", { staticClass: "radio_wrap wide" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.isdisplay,
                            expression: "info.isdisplay",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "group00",
                          id: "group01",
                          value: "T",
                          checked: "",
                        },
                        domProps: { checked: _vm._q(_vm.info.isdisplay, "T") },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.info, "isdisplay", "T")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "group01" } }, [
                        _vm._v("노출"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.isdisplay,
                            expression: "info.isdisplay",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "group00",
                          id: "group02",
                          value: "F",
                        },
                        domProps: { checked: _vm._q(_vm.info.isdisplay, "F") },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.info, "isdisplay", "F")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "group02" } }, [
                        _vm._v("미노출"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("dl", [
                  _vm._m(1),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.subject,
                          expression: "info.subject",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", placeholder: "카테고리 제목" },
                      domProps: { value: _vm.info.subject },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.info, "subject", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("설명")]),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.desc,
                          expression: "info.desc",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", placeholder: "카테고리 설명" },
                      domProps: { value: _vm.info.desc },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.info, "desc", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.goSave },
                },
                [_vm._v("저장")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("취소")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_vm._v("노출상태"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_vm._v("제목"), _c("i", { staticClass: "essential" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }