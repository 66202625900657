var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1000px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("신고정보")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c(
              "div",
              {
                staticClass: "scroll-y",
                staticStyle: { "max-height": "500px" },
              },
              [
                _c(
                  "table",
                  {
                    staticClass: "data-tb align-c",
                    staticStyle: { "margin-left": "0" },
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _vm._m(0),
                    _vm._m(1),
                    _c(
                      "tbody",
                      _vm._l(_vm.dataList, function (row, i) {
                        return _c("tr", { key: i }, [
                          _c("td", [_vm._v(_vm._s(row.num))]),
                          _c("td", [_vm._v(_vm._s(row.userid))]),
                          _c("td", [_vm._v(_vm._s(row.name))]),
                          _c("td", [_vm._v(_vm._s(row.dadamembertype))]),
                          _c("td", [_vm._v(_vm._s(row.mumemlvtype))]),
                          _c("td", [_vm._v(_vm._s(row.regdate))]),
                          _c("td", [_vm._v(_vm._s(row.reporttype))]),
                          _c("td", { staticClass: "left" }, [
                            _vm._v(_vm._s(row.etcreason)),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("No")]),
        _c("th", [_vm._v("아이디")]),
        _c("th", [_vm._v("이름")]),
        _c("th", [
          _vm._v(" 유형 "),
          _c("button", { staticClass: "sort down", attrs: { type: "button" } }),
        ]),
        _c("th", [
          _vm._v(" 등급 "),
          _c("button", { staticClass: "sort down", attrs: { type: "button" } }),
        ]),
        _c("th", [
          _vm._v(" 신고일시 "),
          _c("button", { staticClass: "sort down", attrs: { type: "button" } }),
        ]),
        _c("th", [_vm._v("사유구분")]),
        _c("th", [_vm._v("기타사유")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }