var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("trumbowyg", {
    ref: "editor",
    staticClass: "form-control",
    style: _vm.styleObject,
    attrs: { config: _vm.config, name: "content", disabled: _vm.disable },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v
      },
      expression: "content",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }