var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content m-leftmenu",
      on: {
        mousedown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "right", 39, $event.key, [
              "Right",
              "ArrowRight",
            ])
          )
            return null
          if ("button" in $event && $event.button !== 2) return null
          return _vm.mouseright.apply(null, arguments)
        },
        contextmenu: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("common-navigator"),
      _c("div", { staticClass: "inner" }, [
        _vm._m(0),
        _c("div", { staticClass: "boxing search-area pd0" }, [
          _c("dl", [
            _c("dt", [_vm._v("직접검색")]),
            _c("dd", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.skey,
                      expression: "searchData.skey",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "skey",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.searchData.skeyArr, function (item) {
                  return _c(
                    "option",
                    { key: item.key, domProps: { value: item.key } },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                0
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.sword,
                    expression: "searchData.sword",
                  },
                ],
                attrs: { type: "text", maxlength: "200" },
                domProps: { value: _vm.searchData.sword },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.searchList(1)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "sword", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("주문일자")]),
            _c(
              "dd",
              [
                _c("CommonDatePicker", {
                  attrs: { value: _vm.searchData.startdate },
                  on: { change: _vm.onChangeStartDate },
                }),
                _c("span", [_vm._v("-")]),
                _c("CommonDatePicker", {
                  attrs: { value: _vm.searchData.enddate },
                  on: { change: _vm.onChangeEndDate },
                }),
                _c("div", { staticClass: "radio_wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "period_aday_1",
                      value: "aday_1",
                    },
                    domProps: {
                      checked: _vm._q(_vm.searchData.period, "aday_1"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "aday_1")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "period_aday_1" } }, [
                    _vm._v("어제"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "period_aday_0",
                      value: "aday_0",
                    },
                    domProps: {
                      checked: _vm._q(_vm.searchData.period, "aday_0"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "aday_0")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "period_aday_0" } }, [
                    _vm._v("오늘"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "period_days_7",
                      value: "days_7",
                    },
                    domProps: {
                      checked: _vm._q(_vm.searchData.period, "days_7"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "days_7")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "period_days_7" } }, [
                    _vm._v("일주일"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "period_months_1",
                      value: "months_1",
                    },
                    domProps: {
                      checked: _vm._q(_vm.searchData.period, "months_1"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "months_1")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "period_months_1" } }, [
                    _vm._v("1개월"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "period_months_3",
                      value: "months_3",
                    },
                    domProps: {
                      checked: _vm._q(_vm.searchData.period, "months_3"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "months_3")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "period_months_3" } }, [
                    _vm._v("3개월"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "period_months_6",
                      value: "months_6",
                    },
                    domProps: {
                      checked: _vm._q(_vm.searchData.period, "months_6"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "months_6")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "period_months_6" } }, [
                    _vm._v("6개월"),
                  ]),
                ]),
              ],
              1
            ),
            !_vm.searchData.isshowdetailarea && !_vm.isPartner
              ? _c("dd", { staticStyle: { width: "104px" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn black-line",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.searchData.isshowdetailarea =
                            !_vm.searchData.isshowdetailarea
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "icon-arr-detail close" }),
                      _vm._v(" 상세검색 "),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.searchData.isshowdetailarea
            ? _c("dl", [
                _c("dt", [_vm._v("주문경로")]),
                _c(
                  "dd",
                  [
                    _c("div", { staticClass: "check-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.isallchannel,
                            expression: "searchData.isallchannel",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "chkAllChannel",
                          "true-value": "T",
                          "false-value": "F",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.searchData.isallchannel)
                            ? _vm._i(_vm.searchData.isallchannel, null) > -1
                            : _vm._q(_vm.searchData.isallchannel, "T"),
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.searchData.isallchannel,
                                $$el = $event.target,
                                $$c = $$el.checked ? "T" : "F"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.searchData,
                                      "isallchannel",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.searchData,
                                      "isallchannel",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.searchData, "isallchannel", $$c)
                              }
                            },
                            _vm.checkAllChannel,
                          ],
                        },
                      }),
                      _c("label", { attrs: { for: "chkAllChannel" } }, [
                        _vm._v("전체"),
                      ]),
                    ]),
                    _vm._l(_vm.commonCode.muappchtype, function (item) {
                      return _c(
                        "div",
                        { key: item.cmcode, staticClass: "check-wrap" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchData.muappchtypeArr,
                                expression: "searchData.muappchtypeArr",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "muappchtype_" + item.cmcode,
                              "true-value": [],
                            },
                            domProps: {
                              value: item.cmcode,
                              checked: Array.isArray(
                                _vm.searchData.muappchtypeArr
                              )
                                ? _vm._i(
                                    _vm.searchData.muappchtypeArr,
                                    item.cmcode
                                  ) > -1
                                : _vm._q(_vm.searchData.muappchtypeArr, []),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.searchData.muappchtypeArr,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? [] : false
                                if (Array.isArray($$a)) {
                                  var $$v = item.cmcode,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.searchData,
                                        "muappchtypeArr",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.searchData,
                                        "muappchtypeArr",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.searchData,
                                    "muappchtypeArr",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "muappchtype_" + item.cmcode } },
                            [_vm._v(_vm._s(item.codename))]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm.searchData.isshowdetailarea
            ? _c("dl", [
                _c("dt", [_vm._v("금액조건")]),
                _c("dd", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.pricekey,
                          expression: "searchData.pricekey",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "pricekey",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "payment" } }, [
                        _vm._v("결제금액"),
                      ]),
                    ]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.minprice,
                        expression: "searchData.minprice",
                      },
                    ],
                    staticClass: "short",
                    attrs: { type: "text", maxlength: "11" },
                    domProps: { value: _vm.searchData.minprice },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchData,
                          "minprice",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("span", [_vm._v("~")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.maxprice,
                        expression: "searchData.maxprice",
                      },
                    ],
                    staticClass: "short",
                    attrs: { type: "text", maxlength: "11" },
                    domProps: { value: _vm.searchData.maxprice },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchData,
                          "maxprice",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm.searchData.isshowdetailarea
            ? _c("dl", [
                _c("dt", [_vm._v("회원구분")]),
                _c("dd", [
                  _c("div", { staticClass: "radio_wrap wide3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.isnonmember,
                          expression: "searchData.isnonmember",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "isnonmember",
                        id: "isnonmemberAll",
                        value: "",
                      },
                      domProps: {
                        checked: _vm._q(_vm.searchData.isnonmember, ""),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.searchData, "isnonmember", "")
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "isnonmemberAll" } }, [
                      _vm._v("전체"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.isnonmember,
                          expression: "searchData.isnonmember",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "isnonmember",
                        id: "isnonmemberF",
                        value: "F",
                      },
                      domProps: {
                        checked: _vm._q(_vm.searchData.isnonmember, "F"),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.searchData, "isnonmember", "F")
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "isnonmemberF" } }, [
                      _vm._v("회원"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.isnonmember,
                          expression: "searchData.isnonmember",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "isnonmember",
                        id: "isnonmemberT",
                        value: "T",
                      },
                      domProps: {
                        checked: _vm._q(_vm.searchData.isnonmember, "T"),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.searchData, "isnonmember", "T")
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "isnonmemberT" } }, [
                      _vm._v("비회원"),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.searchData.isshowdetailarea
            ? _c("dl", [
                _c("dt", [_vm._v("주문구분")]),
                _c("dd", [
                  _c("div", { staticClass: "radio_wrap wide3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.isfrstorder,
                          expression: "searchData.isfrstorder",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "isfrstorder",
                        id: "isfrstorderAll",
                        value: "",
                      },
                      domProps: {
                        checked: _vm._q(_vm.searchData.isfrstorder, ""),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.searchData, "isfrstorder", "")
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "isfrstorderAll" } }, [
                      _vm._v("전체"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.isfrstorder,
                          expression: "searchData.isfrstorder",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "isfrstorder",
                        id: "isfrstorderT",
                        value: "T",
                      },
                      domProps: {
                        checked: _vm._q(_vm.searchData.isfrstorder, "T"),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.searchData, "isfrstorder", "T")
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "isfrstorderT" } }, [
                      _vm._v("첫 주문"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.isfrstorder,
                          expression: "searchData.isfrstorder",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "isfrstorder",
                        id: "isfrstorderF",
                        value: "F",
                      },
                      domProps: {
                        checked: _vm._q(_vm.searchData.isfrstorder, "F"),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.searchData, "isfrstorder", "F")
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "isfrstorderF" } }, [
                      _vm._v("재 주문"),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.searchData.isshowdetailarea
            ? _c("dl", [
                _c("dt", [_vm._v("회원유형")]),
                _c(
                  "dd",
                  [
                    _c("div", { staticClass: "check-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.isallmember,
                            expression: "searchData.isallmember",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "chkAllMember",
                          "true-value": "T",
                          "false-value": "F",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.searchData.isallmember)
                            ? _vm._i(_vm.searchData.isallmember, null) > -1
                            : _vm._q(_vm.searchData.isallmember, "T"),
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.searchData.isallmember,
                                $$el = $event.target,
                                $$c = $$el.checked ? "T" : "F"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.searchData,
                                      "isallmember",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.searchData,
                                      "isallmember",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.searchData, "isallmember", $$c)
                              }
                            },
                            _vm.checkAllMembertype,
                          ],
                        },
                      }),
                      _c("label", { attrs: { for: "chkAllMember" } }, [
                        _vm._v("전체"),
                      ]),
                    ]),
                    _vm._l(_vm.commonCode.dadamembertype, function (item) {
                      return _c(
                        "div",
                        { key: item.cmcode, staticClass: "check-wrap" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchData.mumembertypeArr,
                                expression: "searchData.mumembertypeArr",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "dadamembertype_" + item.cmcode,
                              "true-value": [],
                            },
                            domProps: {
                              value: item.cmcode,
                              checked: Array.isArray(
                                _vm.searchData.mumembertypeArr
                              )
                                ? _vm._i(
                                    _vm.searchData.mumembertypeArr,
                                    item.cmcode
                                  ) > -1
                                : _vm._q(_vm.searchData.mumembertypeArr, []),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.searchData.mumembertypeArr,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? [] : false
                                if (Array.isArray($$a)) {
                                  var $$v = item.cmcode,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.searchData,
                                        "mumembertypeArr",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.searchData,
                                        "mumembertypeArr",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.searchData,
                                    "mumembertypeArr",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "dadamembertype_" + item.cmcode } },
                            [_vm._v(_vm._s(item.codename))]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm.searchData.isshowdetailarea
            ? _c("dl", [
                _c("dt", [_vm._v("회원등급")]),
                _c(
                  "dd",
                  [
                    _c("div", { staticClass: "check-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.isallmemlv,
                            expression: "searchData.isallmemlv",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "chkAllMemlv",
                          "true-value": "T",
                          "false-value": "F",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.searchData.isallmemlv)
                            ? _vm._i(_vm.searchData.isallmemlv, null) > -1
                            : _vm._q(_vm.searchData.isallmemlv, "T"),
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.searchData.isallmemlv,
                                $$el = $event.target,
                                $$c = $$el.checked ? "T" : "F"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.searchData,
                                      "isallmemlv",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.searchData,
                                      "isallmemlv",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.searchData, "isallmemlv", $$c)
                              }
                            },
                            _vm.checkAllMemlvtype,
                          ],
                        },
                      }),
                      _c("label", { attrs: { for: "chkAllMemlv" } }, [
                        _vm._v("전체"),
                      ]),
                    ]),
                    _vm._l(_vm.commonCode.memlvtype, function (item) {
                      return _c(
                        "div",
                        { key: item.cmcode, staticClass: "check-wrap" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchData.mumemlvtypeArr,
                                expression: "searchData.mumemlvtypeArr",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "mumemlvtype_" + item.cmcode,
                              "true-value": [],
                            },
                            domProps: {
                              value: item.cmcode,
                              checked: Array.isArray(
                                _vm.searchData.mumemlvtypeArr
                              )
                                ? _vm._i(
                                    _vm.searchData.mumemlvtypeArr,
                                    item.cmcode
                                  ) > -1
                                : _vm._q(_vm.searchData.mumemlvtypeArr, []),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.searchData.mumemlvtypeArr,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? [] : false
                                if (Array.isArray($$a)) {
                                  var $$v = item.cmcode,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.searchData,
                                        "mumemlvtypeArr",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.searchData,
                                        "mumemlvtypeArr",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.searchData,
                                    "mumemlvtypeArr",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "mumemlvtype_" + item.cmcode } },
                            [_vm._v(_vm._s(item.codename))]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm.searchData.isshowdetailarea
            ? _c("dl", [
                _c("dt", [_vm._v("결제수단")]),
                _c(
                  "dd",
                  [
                    _c("div", { staticClass: "check-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.isallpayway,
                            expression: "searchData.isallpayway",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "chkAllPayway",
                          "true-value": "T",
                          "false-value": "F",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.searchData.isallpayway)
                            ? _vm._i(_vm.searchData.isallpayway, null) > -1
                            : _vm._q(_vm.searchData.isallpayway, "T"),
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.searchData.isallpayway,
                                $$el = $event.target,
                                $$c = $$el.checked ? "T" : "F"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.searchData,
                                      "isallpayway",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.searchData,
                                      "isallpayway",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.searchData, "isallpayway", $$c)
                              }
                            },
                            _vm.checkAllPaywaytype,
                          ],
                        },
                      }),
                      _c("label", { attrs: { for: "chkAllPayway" } }, [
                        _vm._v("전체"),
                      ]),
                    ]),
                    _vm._l(_vm.commonCode.paywaytype, function (item) {
                      return _c(
                        "div",
                        { key: item.cmcode, staticClass: "check-wrap" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchData.paywaytypeArr,
                                expression: "searchData.paywaytypeArr",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "paywaytype_" + item.cmcode,
                              "true-value": [],
                            },
                            domProps: {
                              value: item.cmcode,
                              checked: Array.isArray(
                                _vm.searchData.paywaytypeArr
                              )
                                ? _vm._i(
                                    _vm.searchData.paywaytypeArr,
                                    item.cmcode
                                  ) > -1
                                : _vm._q(_vm.searchData.paywaytypeArr, []),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.searchData.paywaytypeArr,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? [] : false
                                if (Array.isArray($$a)) {
                                  var $$v = item.cmcode,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.searchData,
                                        "paywaytypeArr",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.searchData,
                                        "paywaytypeArr",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.searchData, "paywaytypeArr", $$c)
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "paywaytype_" + item.cmcode } },
                            [_vm._v(_vm._s(item.codename))]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm.searchData.isshowdetailarea && !_vm.isPartner
                  ? _c("dd", { staticStyle: { width: "104px" } }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn black-line",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.searchData.isshowdetailarea =
                                !_vm.searchData.isshowdetailarea
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "icon-arr-detail open" }),
                          _vm._v(" 상세검색 "),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
        _vm.isRead
          ? _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.searchList(1)
                    },
                  },
                },
                [_vm._v("검색")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big gray",
                  attrs: { type: "button" },
                  on: { click: _vm.initSearchData },
                },
                [_vm._v("초기화")]
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "caption-group mt10 clearfix" }, [
          _c("div", { staticClass: "total-group fl" }, [
            _c("span", { staticClass: "total" }, [
              _vm._v(" 전체 "),
              _c("strong", [_vm._v(_vm._s(_vm.count.totalcnt))]),
              _vm._v(" 건 "),
            ]),
          ]),
          _c("div", { staticClass: "btn-group fr" }, [
            _vm.isRead
              ? _c(
                  "button",
                  {
                    staticClass: "btn green-line",
                    attrs: { type: "button" },
                    on: { click: _vm.fnExcelDownload },
                  },
                  [
                    _c("i", { staticClass: "icon-excel" }),
                    _vm._v(" 엑셀다운로드 "),
                  ]
                )
              : _vm._e(),
            _vm.isRead
              ? _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pagingData.pageCount,
                        expression: "pagingData.pageCount",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.pagingData,
                          "pageCount",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "20" } }, [
                      _vm._v("20개씩 보기"),
                    ]),
                    !_vm.isPartner
                      ? _c("option", { attrs: { value: "50" } }, [
                          _vm._v("50개씩 보기"),
                        ])
                      : _vm._e(),
                    !_vm.isPartner
                      ? _c("option", { attrs: { value: "100" } }, [
                          _vm._v("100개씩 보기"),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { class: { "scroll-x": !_vm.isPartner } }, [
          _c(
            "table",
            {
              staticClass: "data-tb align-c",
              style: !_vm.isPartner ? "width: 2200px;" : "",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _c("caption", [_vm._v("주문 목록")]),
              !_vm.isPartner
                ? _c("colgroup", [
                    _c("col", { attrs: { width: "2%" } }),
                    _c("col", { attrs: { width: "4.5%" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "6.5%" } }),
                    _c("col", { attrs: { width: "3.5%" } }),
                    _c("col", { attrs: { width: "3.5%" } }),
                    _c("col", { attrs: { width: "4.5%" } }),
                    _c("col", { attrs: { width: "5.5%" } }),
                    _c("col", { attrs: { width: "3.5%" } }),
                    _c("col", { attrs: { width: "3.5%" } }),
                    _c("col", { attrs: { width: "3.5%" } }),
                    _c("col", { attrs: { width: "62px" } }),
                    _c("col", { attrs: { width: "" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "4.5%" } }),
                    _c("col", { attrs: { width: "4%" } }),
                    _c("col", { attrs: { width: "4%" } }),
                    _c("col", { attrs: { width: "4%" } }),
                    _c("col", { attrs: { width: "4%" } }),
                    _c("col", { attrs: { width: "3.5%" } }),
                    _c("col", { attrs: { width: "3.5%" } }),
                    _c("col", { attrs: { width: "3.5%" } }),
                  ])
                : _c("colgroup", [
                    _c("col", { attrs: { width: "2%" } }),
                    _c("col", { attrs: { width: "6%" } }),
                    _c("col", { attrs: { width: "6%" } }),
                    _c("col", { attrs: { width: "9%" } }),
                    _c("col", { attrs: { width: "62px" } }),
                    _c("col", { attrs: { width: "" } }),
                    _c("col", { attrs: { width: "8%" } }),
                    _c("col", { attrs: { width: "6%" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "5%" } }),
                    _c("col", { attrs: { width: "4.5%" } }),
                    _c("col", { attrs: { width: "4.5%" } }),
                    _c("col", { attrs: { width: "4.5%" } }),
                  ]),
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("No")]),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 주문경로 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            {
                              up:
                                _vm.sortData.ordpathtype === "ordpathtype_asc",
                            },
                            {
                              down:
                                _vm.sortData.ordpathtype === "ordpathtype_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.ordpathtype,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.ordpathtype)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  _c("th", [
                    _vm._v(" 주문일 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.orderdate === "orderdate_asc" },
                        { down: _vm.sortData.orderdate === "orderdate_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.orderdate },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.orderdate)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 결제일 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.paymentdate === "paymentdate_asc" },
                        {
                          down: _vm.sortData.paymentdate === "paymentdate_desc",
                        },
                      ],
                      attrs: {
                        type: "button",
                        value: _vm.sortData.paymentdate,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.paymentdate)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 주문번호 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.ordno === "ordno_asc" },
                        { down: _vm.sortData.ordno === "ordno_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.ordno },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.ordno)
                        },
                      },
                    }),
                  ]),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 첫 구매 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            {
                              up:
                                _vm.sortData.isfrstorder === "isfrstorder_asc",
                            },
                            {
                              down:
                                _vm.sortData.isfrstorder === "isfrstorder_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.isfrstorder,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.isfrstorder)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 주문자 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.ordname === "ordname_asc" },
                            { down: _vm.sortData.ordname === "ordname_desc" },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.ordname,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.ordname)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 아이디 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.ordid === "ordid_asc" },
                            { down: _vm.sortData.ordid === "ordid_desc" },
                          ],
                          attrs: { type: "button", value: _vm.sortData.ordid },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.ordid)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 연락처 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.ordtel === "ordtel_asc" },
                            { down: _vm.sortData.ordtel === "ordtel_desc" },
                          ],
                          attrs: { type: "button", value: _vm.sortData.ordtel },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.ordtel)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 회원구분 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            {
                              up:
                                _vm.sortData.isnonmember === "isnonmember_asc",
                            },
                            {
                              down:
                                _vm.sortData.isnonmember === "isnonmember_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.isnonmember,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.isnonmember)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 유형 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            {
                              up: _vm.sortData.membertype === "membertype_asc",
                            },
                            {
                              down:
                                _vm.sortData.membertype === "membertype_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.membertype,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.membertype)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isPartner
                    ? _c("th", [
                        _vm._v(" 등급 "),
                        _c("button", {
                          staticClass: "sort",
                          class: [
                            { up: _vm.sortData.memlvtype === "memlvtype_asc" },
                            {
                              down: _vm.sortData.memlvtype === "memlvtype_desc",
                            },
                          ],
                          attrs: {
                            type: "button",
                            value: _vm.sortData.memlvtype,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortToggle(_vm.sortData.memlvtype)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  _c("th", { attrs: { colspan: "2" } }, [
                    _vm._v(" 상품명 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.goodsname === "goodsname_asc" },
                        { down: _vm.sortData.goodsname === "goodsname_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.goodsname },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.goodsname)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 실결제금액 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        {
                          up: _vm.sortData.rpaytotprice === "rpaytotprice_asc",
                        },
                        {
                          down:
                            _vm.sortData.rpaytotprice === "rpaytotprice_desc",
                        },
                      ],
                      attrs: {
                        type: "button",
                        value: _vm.sortData.rpaytotprice,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.rpaytotprice)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 결제수단 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.paywaytype === "paywaytype_asc" },
                        { down: _vm.sortData.paywaytype === "paywaytype_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.paywaytype },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.paywaytype)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 미배송 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.undelivcnt === "undelivcnt_asc" },
                        { down: _vm.sortData.undelivcnt === "undelivcnt_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.undelivcnt },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.undelivcnt)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 배송중 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.indelivcnt === "indelivcnt_asc" },
                        { down: _vm.sortData.indelivcnt === "indelivcnt_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.indelivcnt },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.indelivcnt)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 배송완료 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        {
                          up: _vm.sortData.compdelivcnt === "compdelivcnt_asc",
                        },
                        {
                          down:
                            _vm.sortData.compdelivcnt === "compdelivcnt_desc",
                        },
                      ],
                      attrs: {
                        type: "button",
                        value: _vm.sortData.compdelivcnt,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.compdelivcnt)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 구매확정 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.cfmcnt === "cfmcnt_asc" },
                        { down: _vm.sortData.cfmcnt === "cfmcnt_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.cfmcnt },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.cfmcnt)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 취소 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.canclecnt === "canclecnt_asc" },
                        { down: _vm.sortData.canclecnt === "canclecnt_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.canclecnt },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.canclecnt)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 반품 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.returncnt === "returncnt_asc" },
                        { down: _vm.sortData.returncnt === "returncnt_desc" },
                      ],
                      attrs: { type: "button", value: _vm.sortData.returncnt },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.returncnt)
                        },
                      },
                    }),
                  ]),
                  _c("th", [
                    _vm._v(" 교환 "),
                    _c("button", {
                      staticClass: "sort",
                      class: [
                        { up: _vm.sortData.exchangecnt === "exchangecnt_asc" },
                        {
                          down: _vm.sortData.exchangecnt === "exchangecnt_desc",
                        },
                      ],
                      attrs: {
                        type: "button",
                        value: _vm.sortData.exchangecnt,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.sortToggle(_vm.sortData.exchangecnt)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _vm.list.length > 0
                ? _c(
                    "tbody",
                    _vm._l(_vm.list, function (item, index) {
                      return _c("tr", { key: item.orgdelividx }, [
                        _c("td", [
                          _vm._v(_vm._s(_vm.loopNumberForPaging(index))),
                        ]),
                        !_vm.isPartner
                          ? _c("td", [_vm._v(_vm._s(item.ordpathtypename))])
                          : _vm._e(),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.orderdate) + " "),
                          _c("br"),
                          _vm._v(" " + _vm._s(item.ordertime) + " "),
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.paymentdate) + " "),
                          _c("br"),
                          _vm._v(" " + _vm._s(item.paymenttime) + " "),
                        ]),
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.goOrderDetail(item.ordno)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.ordno) + " ")]
                          ),
                        ]),
                        !_vm.isPartner
                          ? _c("td", [_vm._v(_vm._s(item.isfrstorder))])
                          : _vm._e(),
                        !_vm.isPartner && item.isnonmember === "F"
                          ? _c("td", [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goMemDetail(item.orduserno)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.ordname) + " ")]
                              ),
                            ])
                          : _vm._e(),
                        !_vm.isPartner && item.isnonmember === "T"
                          ? _c("td", [_vm._v(_vm._s(item.ordname))])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("td", [_vm._v(_vm._s(item.ordid))])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("td", [
                              _vm._v(_vm._s(_vm.$util.maskTel(item.ordtel))),
                            ])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("td", [_vm._v(_vm._s(item.isnonmembername))])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("td", [_vm._v(_vm._s(item.membertypename))])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("td", [_vm._v(_vm._s(item.memlvtypename))])
                          : _vm._e(),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass: "img-thumb size60",
                              class: {
                                "no-image": _vm.$util.isNull(item.fullpath),
                              },
                            },
                            [
                              !_vm.$util.isNull(item.fullpath)
                                ? _c("img", { attrs: { src: item.fullpath } })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _c("td", { staticClass: "left no-left" }, [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.goOrderGoods(item)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.goodsname) + " ")]
                          ),
                        ]),
                        _c("td", { staticClass: "right" }, [
                          _vm._v(
                            _vm._s(_vm.$util.maskComma(item.rpaytotprice))
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(item.paywaytypename))]),
                        _c("td", [_vm._v(_vm._s(item.undelivcnt))]),
                        _c("td", [_vm._v(_vm._s(item.indelivcnt))]),
                        _c("td", [_vm._v(_vm._s(item.compdelivcnt))]),
                        _c("td", [_vm._v(_vm._s(item.cfmcnt))]),
                        _c("td", [_vm._v(_vm._s(item.canclecnt))]),
                        _c("td", [_vm._v(_vm._s(item.returncnt))]),
                        _c("td", [_vm._v(_vm._s(item.exchangecnt))]),
                      ])
                    }),
                    0
                  )
                : _c("tbody", [
                    _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: _vm.isPartner ? 15 : 23 } },
                        [_vm._v("조회 결과가 존재하지 않습니다.")]
                      ),
                    ]),
                  ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "bottom-group" }, [
          _c(
            "div",
            { staticClass: "paging" },
            [
              _c("CommonPageNavigator", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isRead,
                    expression: "isRead",
                  },
                ],
                attrs: { pagingData: _vm.pagingData },
                on: { setPagingData: _vm.setPagingData },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.isShowMemDetail
        ? _c("AdminMemberInfo", {
            attrs: { activeUserNo: _vm.activeUserNo },
            on: { closeDetail: _vm.closeMemDetail },
          })
        : _vm._e(),
      _vm.isShowOrderDetail
        ? _c("OrderDetail", {
            attrs: { activeOrderCode: _vm.activeOrderCode },
            on: { closeDetail: _vm.closeOrderDetail },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "bar-title fl" }, [_vm._v("전체주문")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }