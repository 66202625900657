var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "720px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("이미지 업로드")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "blue-box mg0" }, [
              _vm._m(0),
              _c("div", { staticClass: "mt20" }, [
                _c("input", {
                  ref: "imageFile",
                  attrs: { type: "file", hidden: "", accept: ".zip" },
                  on: { change: _vm.handleFileUpload },
                }),
                _c("input", {
                  ref: "imageFileName",
                  attrs: { type: "text", readonly: "" },
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn blue-line ml3",
                    attrs: { type: "button" },
                    on: { click: _vm.fileAttach },
                  },
                  [_vm._v("파일찾기")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn blue",
                    attrs: { type: "button" },
                    on: { click: _vm.uploadImageFile },
                  },
                  [_vm._v("파일업로드")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("이미지는 zip파일로 압축해서 업로드 하시기 바랍니다.")]),
      _c("li", [
        _vm._v(
          "엑셀에서 작성한 파일명과 업로드하는 이미지 파일명은 동일해야 합니다."
        ),
      ]),
      _c("li", [_vm._v("파일명이 중복된 경우에는 동일 이미지로 간주됩니다.")]),
      _c("li", [_vm._v("이미지 파일명은 영문으로만 작성해 주세요.")]),
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v("사이즈: 720*720 / 용량: 2MB 이하 / 파일 : JPG, JPEG, PNG "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }