<template>
  <!-- 컨텐츠 영역 -->
  <div class="content m-leftmenu">
    <common-navigator></common-navigator>
    <div class="inner">
      <div class="boxing search-area">
        <dl>
          <dt>조회기간</dt>
          <dd>
            <div>
              <input
                type="checkbox"
                id="chkDateSearch"
                v-model="searchData.isdatesearch"
                true-value="T"
                false-value="F"
                @change="checkDateSearch"
              />
              <label for="chkDateSearch">일단위 조회</label>
              <fragment v-if="searchData.isdatesearch == 'F'">
                <CommonDatePicker
                  :formatType="formatType"
                  :value="searchData.startmonth"
                  @change="onChangeStartMonth"
                />
                <span>~</span>
                <CommonDatePicker
                  :formatType="formatType"
                  :value="searchData.endmonth"
                  @change="onChangeEndMonth"
                />
              </fragment>
              <fragment v-else>
                <CommonDatePicker :value="searchData.startdate" @change="onChangeStartDate" />
                <span>~</span>
                <CommonDatePicker :value="searchData.enddate" @change="onChangeEndDate" />
              </fragment>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>직접검색</dt>
          <dd>
            <select v-model="searchData.skey">
              <option value="">전체</option>
              <option value="name">파트너사명</option>
              <option value="userno">파트너사코드</option>
              <option value="bizno">사업자등록번호</option>
            </select>
            <input v-model="searchData.sword" type="text" @keyup.enter="onSearch(1)" />
          </dd>
        </dl>
        <dl>
          <dt>상태</dt>
          <dd>
            <div class="radio_wrap wide4">
              <div>
                <input
                  id="calcAll"
                  v-model="searchData.calcstatus"
                  name="calcstatus"
                  type="radio"
                  value=""
                />
                <label for="calcAll">전체</label>
              </div>
              <div v-for="item in commonCode.calcStatus" :key="item.cmcode">
                <input
                  :id="'calcstatus_' + item.cmcode"
                  v-model="searchData.calcstatus"
                  :value="item.cmcode"
                  name="calcstatus"
                  type="radio"
                />
                <label :for="'calcstatus_' + item.cmcode">{{ item.codename }}</label>
              </div>
            </div>
          </dd>
        </dl>
      </div>
      <div class="btn-group">
        <button v-if="isRead" class="btn big blue" type="button" @click="onSearch(1)">검색</button>
        <button v-if="isRead" class="btn big gray" type="button" @click="initData">초기화</button>
      </div>
      <div class="caption-group mt10 clearfix">
        <div class="total-group fl">
          <span class="total">
            전체
            <strong>{{ totalCnt }}</strong>
            건
          </span>
        </div>
        <div class="btn-group fr">
          <button
            v-if="isWrite"
            class="btn blue-line"
            type="button"
            @click="calcStateUpdate('CAS003')"
          >
            마감
          </button>
          <button v-if="isRead" class="btn blue-line" type="button" @click="showComparePopup">
            PG결과 대사
          </button>
          <button v-if="isWrite" class="btn green-line" type="button" @click="excelDown">
            <i class="icon-excel"></i>
            엑셀다운로드
          </button>
          <select v-show="isRead" v-model="pagingData.pageCount">
            <option value="20">20개씩 보기</option>
            <option value="50">50개씩 보기</option>
            <option value="100">100개씩 보기</option>
          </select>
        </div>
      </div>
      <div class="scroll-x">
        <table cellpadding="0" cellspacing="0" class="data-tb align-c" style="min-width: 2800px">
          <caption>정산예정내역 목록</caption>
          <colgroup>
            <col width="30px" />
            <!-- checkbox -->
            <col width="1.7%" />
            <!-- No -->
            <col width="2.5%" />
            <!-- 년/월 -->
            <col width="" />
            <!-- 파트너사명 -->
            <col width="2.5%" />
            <!-- e-acc 고객코드 -->
            <col width="2.5%" />
            <!-- e-acc 공급업체코드 -->
            <col width="3%" />
            <!-- 파트너사코드 -->
            <col width="4%" />
            <!-- 사업자등록번호 -->
            <col width="3%" />
            <!-- 파트너사번호 -->
            <col width="3.5%" />
            <!-- 정산예정일 -->
            <col width="3.3%" />
            <!-- 총 판매금액 (판매가x수량) ⓐ -->
            <col width="3.3%" />
            <!-- 총 판매금액(VAT제외) ⓐ -->
            <col width="3.3%" />
            <!-- 총 판매금액(VAT) ⓐ -->
            <col width="3.3%" />
            <!-- 플랫폼수수료 -->
            <col width="3.3%" />
            <!-- 총 공급단가 (공급단가x수량) ⓑ -->
            <col width="3%" />
            <!-- 총 부가세(공급단가) (부가세x수량) ⓒ -->
            <col width="3%" />
            <!-- 프로모션 전체 -->
            <col width="3%" />
            <!-- 프로모션분담금 ⓔ -->
            <col width="3%" />
            <!-- 프로모션당사부담금 -->
            <col width="3%" />
            <!-- 쿠폰전체 -->
            <col width="3%" />
            <!-- 쿠폰분담금 ⓕ -->
            <col width="3%" />
            <!-- 쿠폰당사부담금 ⓕ -->
            <col width="3%" />
            <!-- 장바구니쿠폰 ⓖ -->
            <col width="3%" />
            <!-- 판촉비 -->
            <col width="3%" />
            <!-- 부가세  -->
            <col width="3%" />
            <!-- 장바구니쿠폰분담금 -->
            <col width="3%" />
            <!-- 장바구니쿠폰당사부담금 -->
            <col width="3%" />
            <!-- 배송비쿠폰 ⓖ -->
            <col width="3%" />
            <!-- 판촉비 -->
            <col width="3%" />
            <!-- 부가세  -->
            <col width="3%" />
            <!-- 기본배송비 ⓗ -->
            <col width="3%" />
            <!-- 추가배송비 ⓘ -->
            <col width="4%" />
            <!-- 정산합계 ⓐ-ⓑ-ⓒ+ⓔ+ⓕ+ⓖ+ⓗ+ⓘ -->
            <col width="3%" />
            <!-- 검증 총판매금액-총공급단가-총부가세-쿠폰전체금액+기본배송비-정산합계-->
            <col width="3%" />
            <!-- 상태 -->
          </colgroup>
          <thead>
            <tr>
              <th rowspan="2">
                <input
                  v-model="allChecked"
                  type="checkbox"
                  @change="checkedAll($event.target.checked)"
                />
              </th>
              <th rowspan="2">No</th>
              <th rowspan="2">년/월</th>
              <th rowspan="2">파트너사 명</th>
              <th rowspan="2">E-ACC 고객코드</th>
              <th rowspan="2">E-ACC 공급업체코드</th>
              <th rowspan="2">파트너사 코드</th>
              <th rowspan="2">사업자등록번호</th>
              <th rowspan="2">파트너사 번호</th>
              <th rowspan="2">정산예정일</th>
              <th class="tk-left tk-top tk-bottom" rowspan="2">
                총 판매금액
                <br />
                (판매가x수량)
                <br />
                ⓐ
              </th>
              <th rowspan="2" class="tk-top tk-bottom">
                총 판매금액
                <br />
                (VAT제외)
              </th>
              <th rowspan="2" class="tk-top tk-bottom tk-right">
                총 판매금액
                <br />
                (VAT)
              </th>
              <th rowspan="2">플랫폼수수료</th>
              <th class="tk-left tk-top tk-bottom" rowspan="2">
                총 공급단가
                <br />
                (공급단가x수량)
                <br />
                ⓑ
              </th>
              <th class="tk-top tk-bottom" rowspan="2">
                총 부가세
                <br />
                (공급단가)
                <br />
                (부가세x수량)
                <br />
                ⓒ
              </th>
              <th class="tk-left tk-top" colspan="3">총 기본할인프로모션</th>
              <th class="tk-top" colspan="3">상품쿠폰</th>
              <th class="tk-top" colspan="5">장바구니쿠폰</th>
              <th class="tk-top" colspan="3">배송비쿠폰</th>
              <th class="tk-left tk-top tk-bottom" rowspan="2">
                기본배송비
                <br />
                ⓗ
              </th>
              <th class="tk-top tk-bottom" rowspan="2">
                추가배송비
                <br />
                ⓘ
              </th>
              <th class="tk-left tk-top tk-bottom" rowspan="2">
                정산합계
                <br />
                (ⓐ-ⓑ-ⓒ)-ⓔ-ⓕ-ⓖ+ⓗ+ⓘ
              </th>
              <th class="tk-left" rowspan="2">검증</th>
              <th rowspan="2">상태</th>
            </tr>
            <tr>
              <th class="bg-yellow tk-left tk-bottom">전체금액</th>
              <th class="bg-yellow tk-bottom">
                분담금
                <br />
                ⓔ
              </th>
              <th class="bg-yellow tk-bottom">당사부담금</th>
              <th class="bg-yellow tk-bottom">전체금액</th>
              <th class="bg-yellow tk-bottom">
                분담금
                <br />
                ⓕ
              </th>
              <th class="bg-yellow tk-bottom">당사부담금</th>
              <th class="bg-yellow tk-bottom">전체금액</th>
              <th class="bg-yellow tk-bottom">판촉비</th>
              <th class="bg-yellow tk-bottom">부가세</th>
              <th class="bg-yellow tk-bottom">
                분담금
                <br />
                ⓖ
              </th>
              <th class="bg-yellow tk-bottom">당사부담금</th>
              <th class="bg-yellow tk-bottom">전체금액</th>
              <th class="bg-yellow tk-bottom">판촉비</th>
              <th class="bg-yellow tk-bottom">부가세</th>
            </tr>
          </thead>
          <tbody v-if="calcList.length > 0">
            <tr v-for="(row, index) in calcList" v-bind:key="index">
              <td><input v-model="row.IsChecked" type="checkbox" /></td>
              <td>{{ loopNumberForPaging(index) }}</td>
              <td>{{ row.calcmonth }}</td>
              <td>
                <a v-if="isRead" class="link" @click="showDtlPopup(row)">{{ row.name }}</a>
              </td>
              <td>{{ row.eaccustcode }}</td>
              <td>{{ row.eacsupcode }}</td>
              <td>{{ row.dealercode }}</td>
              <td>{{ row.bizno }}</td>
              <td>{{ row.userno }}</td>
              <td>{{ row.calcexpday }}</td>
              <td class="right">{{ row.saleamt }}</td>
              <td class="right">{{ row.saleamtexvat }}</td>
              <td class="right">{{ row.saleamtvat }}</td>
              <td class="right">{{ row.platformcommamt }}</td>
              <td class="right">{{ row.supplyamt }}</td>
              <td class="right">{{ row.supplyvatamt }}</td>
              <td class="right">{{ row.promoamt }}</td>
              <td class="right">{{ row.promoshareamt }}</td>
              <td class="right">{{ row.promoownamt }}</td>
              <td class="right">{{ row.cpnamt }}</td>
              <td class="right">{{ row.cpnshareamt }}</td>
              <td class="right">{{ row.cpnownamt }}</td>
              <td class="right">{{ row.basketcpnamt }}</td>
              <td class="right">{{ row.basketcpnpe }}</td>
              <td class="right">{{ row.basketcpnvat }}</td>
              <td class="right">{{ row.basketcpnshareamt }}</td>
              <td class="right">{{ row.basketcpnownamt }}</td>
              <td class="right">{{ row.shippingcouponamount }}</td>
              <td class="right">{{ row.shippingcouponamountpe }}</td>
              <td class="right">{{ row.shippingcouponamountvat }}</td>
              <td class="right">{{ row.ptndelivamt }}</td>
              <td class="right">
                <a v-if="isRead" class="link" @click="showCsPopup(row)">
                  {{ row.addrpaytotprice }}
                </a>
              </td>
              <td class="right">{{ row.calcamt }}</td>
              <td class="right">{{ row.verifyamt }}</td>
              <td>
                {{ row.calcstatusname }}
                <br />
                <button
                  v-if="isWrite && row.calcstatus === 'CAS002'"
                  class="btn blue-line"
                  type="button"
                  @click="reCalc(row)"
                >
                  재마감
                </button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="35">조회 결과가 존재하지 않습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="bottom-group">
        <common-page-navigator
          v-show="isRead"
          v-bind:pagingData="pagingData"
          v-on:setPagingData="setPagingData"
        ></common-page-navigator>
      </div>
    </div>
    <AdjustCsResultPopup
      v-if="csShow"
      v-bind:csData="csData"
      v-on:closeCsPopup="closeCsPopup"
    ></AdjustCsResultPopup>
    <AdjustEstimateDetail
      v-if="dtlShow"
      v-bind:dtlData="dtlData"
      v-on:closeDtlPopup="closeDtlPopup"
    ></AdjustEstimateDetail>
    <AdjustCompareResult
      v-if="compareShow"
      v-bind:compareData="compareData"
      v-on:closeComparePopup="closeComparePopup"
    ></AdjustCompareResult>
  </div>
  <!-- /컨텐츠 영역 -->
</template>

<script>
import CommonNavigator from '@views.admin/common/CommonNavigator';
import CommonPageNavigator from '@views.admin/common/CommonPageNavigator';
import CommonDatePicker from '@views.admin/common/CommonDatePicker';
import AdjustCsResultPopup from '@views.admin/adjust/estimate/AdjustCsResult';
import AdjustEstimateDetail from '@views.admin/adjust/estimate/AdjustEstimateDetail';
import AdjustCompareResult from '@views.admin/adjust/estimate/AdjustCompareResult';

export default {
  name: 'admin.adjust.estimate',
  components: {
    AdjustCompareResult,
    AdjustEstimateDetail,
    AdjustCsResultPopup,
    CommonNavigator,
    CommonPageNavigator,
    CommonDatePicker,
  },
  data() {
    return {
      searchData: {
        startmonth: '',
        endmonth: '',
        startdate: '',
        enddate: '',
        calcstatus: '',
        calctype: 'ESTIMATE',
        skey: 'name',
        sword: '',
        psort: '',
        isdatesearch: 'T',
      },
      commonCode: {
        calcStatus: [], //마감상태
      },
      pagingData: {
        pageCount: 20,
        page: 1,
        listCount: 0,
      },
      sortData: {
        month: 'month_desc',
        name: 'name_desc',
        userno: 'userno_desc',
        bizno: 'bizno_desc',
        calcstatus: 'calcstatus_desc',
      },
      calcList: [],
      totalCnt: 0,
      formatType: 'month',
      allChecked: false,
      isRead: false,
      isWrite: false,
      csShow: false,
      csData: {},
      dtlShow: false,
      dtlData: {},
      compareShow: false,
      compareData: {},
      isFirstSearch: true, // 최초 조회 여부 (최초 조회여부가 true인 경우 setPagingData에서 조회하지 않는다.)
    };
  },
  mounted() {
    this.$http
      .post('/admin/common/pageAuth/check', { url: this.$options.name, isloading: false })
      .then((result) => {
        this.isRead = result.data.isread === 'T';
        this.isWrite = result.data.iswrite === 'T';

        if (this.isRead) {
          this.initData();
          let params = { cmclass: 'CALCSTATUS' };
          this.$http
            .post('/common/code/list', params)
            .then((result) => {
              if (result.statusCode === 200) {
                this.commonCode.calcStatus = result.data.list;
              }
            })
            .catch((error) => {
              this.$util.debug(error);
            });
        }
      });
  },
  methods: {
    initData() {
      this.searchData = this.$options.data().searchData;
      let startDate = this.$util.getAddMonth(this.$util.getDate(''), -1, '-');
      let endDate = this.$util.getAddMonth(this.$util.getDate(''), -1, '-');

      this.searchData.startmonth = startDate.substring(0, 7);
      this.searchData.endmonth = endDate.substring(0, 7);
      this.searchData.startdate = this.$util.getMonthFirstDate(
        this.$util.getAddMonth(this.$util.getDate(''), -1, ''),
        '-',
      );
      this.searchData.enddate = this.$util.getMonthLastDate(
        this.$util.getAddMonth(this.$util.getDate(''), -1, ''),
        '-',
      );
    },
    onSearch(page) {
      let param = this.searchData;
      param.pageCount = this.pagingData.pageCount;
      param.page = typeof page === 'undefined' ? this.pagingData.page : 1;
      param.listCount = this.pagingData.listCount;
      param.isloading = true;

      this.$http
        .post('/admin/adjust/estimate/list-new', param)
        .then((result) => {
          this.pagingData.listCount = result.data.listcount;
          this.calcList = result.data.list;
          this.totalCnt = result.data.listcount;
          this.$util.dataSetSearchParam(this);
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    reCalc(obj) {
      let calcMonth = obj.calcmonth.replace(/-/g, '');
      let param = {
        userno: obj.userno,
        calcmonth: calcMonth,
        calcidx: obj.calcidx,
      };

      if (confirm('재 마감처리 하시겠습니까?')) {
        this.$http
          .post('/admin/adjust/recalculate', param)
          .then((result) => {
            if (result.data.count > 0) {
              alert('재 마감처리가 완료되었습니다.');
              this.onSearch(1);
            }
          })
          .catch((error) => {
            this.$util.debug(error);
          });
      }
    },
    calcStateUpdate(state) {
      let idx = [];
      this.calcList.forEach(function (row) {
        if (row.IsChecked && (row.calcstatus === 'CAS001' || row.calcstatus === 'CAS002')) {
          idx.push(row.calcidx);
        }
      });

      if (idx.length <= 0) {
        alert('마감 처리할 데이터가 존재하지 않습니다.');
        return;
      }

      if (confirm('체크된 내역 중 이미 마감된 내용은 제외 처리 됩니다.\n마감 하시겠습니까?')) {
        let param = {
          calcstatus: state,
          calcidx: idx,
        };

        this.$http
          .post('/admin/adjust/state/update', param)
          .then((result) => {
            if (result.data.count > 0) {
              alert('마감처리가 완료되었습니다.');
              this.onSearch(1);
            }
          })
          .catch((error) => {
            this.$util.debug(error);
          });
      }
    },
    excelDown() {
      /*let idx = [];
      this.calcList.forEach(function (row) {
        idx.push(row.calcidx);
      });

      if(idx.length <= 0){
        alert('처리할 데이터가 존재하지 않습니다.');
        return;
      }

      let param = {
        calcidx : idx
      }*/
      let param = this.searchData;
      param.isloading = true;

      let config = { responseType: 'arraybuffer' };
      this.$http
        .post('/admin/adjust/calc/excel-new', param, config)
        .then((result) => {
          this.$util.debug(result);
          this.onSearch(1);
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    setPagingData: function (obj) {
      this.pagingData = obj;
      if (this.isFirstSearch == false) {
        this.onSearch();
      }
      if (this.isFirstSearch == true) {
        this.isFirstSearch = false;
      }
    },
    onChangeStartMonth(val) {
      this.searchData.startmonth = val;
    },
    // 날짜 picker 콜백 함수
    onChangeEndMonth(val) {
      this.searchData.endmonth = val;
    },
    onChangeStartDate(val) {
      this.searchData.startdate = val;
    },
    // 날짜 picker 콜백 함수
    onChangeEndDate(val) {
      this.searchData.enddate = val;
    },
    sortToggle: function (key) {
      let arr = key.split('_');
      let sortKey = arr[0];
      let sortOrder = arr[1] === 'asc' ? 'desc' : 'asc';
      let sortName = sortKey + '_' + sortOrder;

      this.sortData = this.$options.data().sortData;
      this.sortData[sortKey] = sortName;
      this.searchData.psort = sortName;

      this.onSearch();
    },
    checkedAll: function (val) {
      this.calcList.forEach(function (row) {
        row.IsChecked = val;
      });
    },
    loopNumberForPaging(index) {
      if (this.pagingData.page > 1) {
        let page = this.pagingData.page - 1;
        return index + 1 + page * this.pagingData.pageCount;
      } else {
        return index + 1;
      }
    },
    showCsPopup(obj) {
      this.csData = obj;
      this.csShow = true;
    },
    closeCsPopup: function () {
      this.csData = {};
      this.csShow = false;
    },
    showDtlPopup(obj) {
      this.dtlData = obj;
      this.dtlShow = true;
    },
    closeDtlPopup: function () {
      this.dtlData = {};
      this.dtlShow = false;
    },
    showComparePopup() {
      this.compareShow = true;
    },
    closeComparePopup: function () {
      this.compareShow = false;
    },
  },
};
</script>

<style scoped />
