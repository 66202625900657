var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tab-area",
      style: _vm.isPartner
        ? "height: calc(90vh - 100px);"
        : "height: calc(90vh - 290px);",
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "scroll-y mt0", staticStyle: { "max-height": "270px" } },
        [
          _c(
            "table",
            {
              staticClass: "data-tb align-c",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _c("caption", [_vm._v(" CS처리목록 ")]),
              _vm._m(1),
              _vm._m(2),
              _vm.csList.length > 0
                ? _c(
                    "tbody",
                    _vm._l(_vm.csList, function (item, index) {
                      return _c("tr", { key: item.idx }, [
                        _c("td", [_vm._v(_vm._s(index + 1))]),
                        _c("td", [_vm._v(_vm._s(item.inquirytypename))]),
                        _c("td", { staticClass: "left" }, [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.goCsDetail(item.idx)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.content))]
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(item.regdate))]),
                        _c("td", [_vm._v(_vm._s(item.repname))]),
                        _c("td", [_vm._v(_vm._s(item.repregdate))]),
                        _c("td", [_vm._v(_vm._s(item.isreply))]),
                      ])
                    }),
                    0
                  )
                : _c("tbody", [_vm._m(3)]),
            ]
          ),
        ]
      ),
      !_vm.isPartner
        ? _c("fragment", [
            _c("div", { staticClass: "bar-title small" }, [
              _vm._v("관리자 메모"),
            ]),
            _vm.isWrite
              ? _c("div", { staticClass: "memo-write" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.memo.inputMemo,
                        expression: "memo.inputMemo",
                      },
                    ],
                    attrs: { placeholder: "메모를 작성해 주세요!" },
                    domProps: { value: _vm.memo.inputMemo },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.memo, "inputMemo", $event.target.value)
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn big blue",
                      attrs: { type: "button" },
                      on: { click: _vm.addMemo },
                    },
                    [_vm._v("메모"), _c("br"), _vm._v("저장")]
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "scroll-y",
                staticStyle: {
                  width: "100%",
                  "max-height": "550px",
                  "margin-bottom": "0",
                },
              },
              [
                _c(
                  "table",
                  {
                    staticClass: "data-tb align-c",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _c("colgroup", [
                      _c("col", { attrs: { width: "5%" } }),
                      _c("col", { attrs: { width: "20%" } }),
                      _c("col", { attrs: { width: "8%" } }),
                      _c("col", { attrs: { width: "" } }),
                      _c("col", { attrs: { width: "40px" } }),
                    ]),
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("No")]),
                        _c("th", [
                          _vm._v(" 작성일시 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.memo.psort === "regdate_asc" },
                              { down: _vm.memo.psort === "regdate_desc" },
                            ],
                            attrs: { type: "button", value: _vm.memo.psort },
                            on: {
                              click: function ($event) {
                                return _vm.memoSortToggle(_vm.memo.psort)
                              },
                            },
                          }),
                        ]),
                        _c("th", [_vm._v("작성자")]),
                        _c("th", { attrs: { colspan: "2" } }, [_vm._v("내용")]),
                      ]),
                    ]),
                    !_vm.$util.isNull(_vm.orderMemoList) &&
                    _vm.orderMemoList.length > 0
                      ? _c(
                          "tbody",
                          _vm._l(_vm.orderMemoList, function (item, index) {
                            return _c("tr", { key: item.ordmemoidx }, [
                              _c("td", [_vm._v(_vm._s(index + 1))]),
                              _c("td", [_vm._v(_vm._s(item.regdate))]),
                              _c("td", [_vm._v(_vm._s(item.regusername))]),
                              _c("td", { staticClass: "left" }, [
                                _vm._v(_vm._s(item.memo)),
                              ]),
                              _c("td", { staticClass: "no-left" }, [
                                _vm.isWrite
                                  ? _c("button", {
                                      staticClass: "del mg0",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeMeno(item.ordmemoidx)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                            ])
                          }),
                          0
                        )
                      : _c("tbody", [
                          _c("tr", [
                            _c("td", { attrs: { colspan: "5" } }, [
                              _vm._v("조회 결과가 존재하지 않습니다."),
                            ]),
                          ]),
                        ]),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm.isCsDetailShow
        ? _c("OneOneDetail", { attrs: { idx: _vm.activeIdx } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "bar-title small fl" }, [_vm._v("CS처리내역")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "8%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("No")]),
        _c("th", [_vm._v("분류")]),
        _c("th", [_vm._v("제목")]),
        _c("th", [_vm._v("문의일시")]),
        _c("th", [_vm._v("답변자")]),
        _c("th", [_vm._v("답변일시")]),
        _c("th", [_vm._v("답변상태")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "7" } }, [
        _vm._v("CS처리내역이 존재하지 않습니다."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }