var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DatePicker", {
    ref: "picker",
    staticStyle: { width: "160px" },
    attrs: {
      "value-type": "format",
      type: _vm.formatType,
      "disabled-date": _vm.onDisabledDate,
      disabled: _vm.disable,
    },
    on: {
      change: _vm.changeDate,
      "input-error": _vm.inputError,
      input: _vm.inputText,
    },
    model: {
      value: _vm.time,
      callback: function ($$v) {
        _vm.time = $$v
      },
      expression: "time",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }