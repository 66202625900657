<template>
  <div id="modal-wrap" class="modal" style="display: block">
    <div class="modal-content" style="width: 1200px">
      <div class="pop-header">
        <h2>파트너사 상세정보</h2>
        <button type="button" class="pop-close" @click="handleCloseDetailModal" />
      </div>
      <div class="btn-row clearfix">
        <div class="btn-group fr" v-if="viewType.toLowerCase() === 'apply'">
          <button
            v-if="isAdmin && isWrite && isSameDetailInfo"
            type="button"
            class="btn big blue"
            @click="handleApprovePartner"
          >
            입점승인
          </button>
          <button
            v-if="isAdmin && isWrite && !isSameDetailInfo"
            type="button"
            class="btn big blue"
            @click="handleSave"
          >
            저장
          </button>
          <button
            v-if="isAdmin && isWrite"
            type="button"
            class="btn big red"
            @click="handleRejectPartner"
          >
            반려
          </button>
          <button type="button" class="btn big darkgray" @click="handleCloseDetailModal">
            닫기
          </button>
        </div>
        <div class="btn-group fr" v-if="viewType.toLowerCase() === 'save'">
          <button
            v-if="isWrite && !isSameDetailInfo"
            type="button"
            class="btn big blue"
            @click="handleSave"
          >
            저장
          </button>
          <button type="button" class="btn big darkgray" @click="handleCloseDetailModal">
            닫기
          </button>
        </div>
      </div>
      <div class="pop-body" ref="popupBody">
        <div class="boxing mt10">
          <div class="boxing-title" :class="{ closed: !tabs.tab1 }">
            기본 정보
            <i class="arrcodi" @click="handleToggleTab('tab1')" />
          </div>
          <!-- 닫은 상태일때 closed 추가 -->
          <div class="form-area" v-show="tabs.tab1 === true">
            <!-- 닫은 상태일때 display:none -->
            <dl v-if="isWrite && !isPartner">
              <dd>대표 운영 담당자의 정보입니다.</dd>
            </dl>
            <dl>
              <dt>아이디</dt>
              <dd>
                <p>{{ info.userid }}</p>
              </dd>
            </dl>
            <dl v-if="isWrite && !isPartner">
              <dt>담당자명</dt>
              <dd>
                <p>{{ info.managername }}</p>
              </dd>
            </dl>
            <dl v-if="isWrite && !isPartner">
              <dt>담당자 휴대폰 번호</dt>
              <dd>
                <p>{{ dispalyManagerMobile }}</p>
              </dd>
            </dl>
            <dl>
              <dt>신청상태</dt>
              <dd>
                <p>{{ info.reqdealstname }}</p>
              </dd>
            </dl>
            <dl v-if="showInitPasswordButton">
              <dt>비밀번호</dt>
              <dd>
                <button type="button" class="btn blue-line" @click="handleInitPassword">
                  비밀번호 초기화
                </button>
              </dd>
            </dl>
          </div>
        </div>
        <div class="boxing" ref="orgbiznofileWrapSection">
          <div class="boxing-title" :class="{ closed: !tabs.tab2 }">
            사업자 정보
            <span class="required">*</span>
            <i class="arrcodi" @click="handleToggleTab('tab2')" />
          </div>
          <!-- 닫은 상태일때 closed 추가 -->
          <div class="form-area" v-show="tabs.tab2 === true">
            <!-- 닫은 상태일때 display:none -->
            <dl>
              <dt>사업자등록번호</dt>
              <dd>
                <input
                  type="text"
                  :value="$util.bizNumberFilter(info.bizno, 1)"
                  style="width: 68px"
                  disabled
                />
                <span>-</span>
                <input
                  type="text"
                  :value="$util.bizNumberFilter(info.bizno, 2)"
                  style="width: 62px"
                  disabled
                />
                <span>-</span>
                <input
                  type="text"
                  :value="$util.bizNumberFilter(info.bizno, 3)"
                  style="width: 97px"
                  disabled
                />
                <button
                  v-if="isWrite"
                  type="button"
                  class="btn blue-line mr-10"
                  @click="fileAttach('orgbiznofile')"
                >
                  사업자등록증 사본 파일첨부
                </button>
                <input
                  type="file"
                  ref="orgbiznofile"
                  v-on:change="handleChangeFile('orgbiznofile')"
                  v-show="false"
                  accept="image/jpeg, image/png, .pdf"
                />
                <div>
                  <button
                    v-if="info.biznotype === 'FLT001'"
                    class="file-link"
                    @click="imageView(info.pathbiznofile)"
                  >
                    {{ info.orgbiznofile }}
                  </button>
                  <a
                    v-else-if="!!orgbiznofile?.fullpath"
                    :href="info.pathbiznofile"
                    class="file-link"
                  >
                    {{ info.orgbiznofile }}
                  </a>
                  <span v-else-if="!orgbiznofile?.fullpath">{{ info.orgbiznofile }}</span>
                  <button
                    v-if="info.orgbiznofile && isWrite"
                    type="button"
                    class="file-del"
                    @click="handleRemoveFile('orgbiznofile')"
                  ></button>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>상호</dt>
              <dd>
                <input type="text" :value="info.compname" disabled />
              </dd>
            </dl>
            <dl>
              <dt>업체명</dt>
              <dd>
                <input
                  type="text"
                  ref="name"
                  :disabled="disableInitPartnerNameInput"
                  v-model.trim="info.name"
                />
              </dd>
            </dl>
            <dl>
              <dt>대표자명</dt>
              <dd>
                <input type="text" :value="info.reprename" disabled />
              </dd>
            </dl>
            <dl>
              <dt>업태</dt>
              <dd>
                <input type="text" ref="bizcondition" v-model.trim="info.bizcondition" />
              </dd>
            </dl>
            <dl>
              <dt>종목</dt>
              <dd>
                <input type="text" ref="bizitem" v-model.trim="info.bizitem" />
              </dd>
            </dl>
            <dl>
              <dt>주소</dt>
              <dd>
                <search-address
                  :load-address="info.addr ?? ''"
                  :load-address-detail="info.addrdetail ?? ''"
                  :on-search-complete-address="handleCompleteSearchAddress"
                  :on-change-address-detail="handleChangeAddressDetail"
                />
              </dd>
            </dl>
            <dl>
              <dt>판매상품유형</dt>
              <dd>
                <select style="width: 250px" v-model.trim="info.sellprodtype" ref="sellprodtype">
                  <option value="" disabled>대표카테고리 선택</option>
                  <option v-for="item in prodTypeList" v-bind:key="item.idx" :value="item.name">
                    {{ item.name }}
                  </option>
                </select>
              </dd>
            </dl>
            <dl>
              <dt>통신판매 신고번호</dt>
              <dd>
                <input type="text" v-model.trim="info.comsellno" ref="comsellno" />
                <span class="txt-orange">
                  <i class="icon-alert" />
                  통신판매업 신고 후 가입을 진행해주시기 바랍니다.
                </span>
              </dd>
            </dl>
          </div>
        </div>
        <div class="boxing">
          <div class="boxing-title" :class="{ closed: !tabs.tab3 }">
            고객문의 연락처 정보
            <span class="required">*</span>
            <i class="arrcodi" @click="handleToggleTab('tab3')" />
          </div>
          <!-- 닫은 상태일때 closed 추가 -->
          <div class="form-area" v-show="tabs.tab3 === true">
            <!-- 닫은 상태일때 display:none -->
            <dl>
              <dt>고객문의 연락처</dt>
              <dd>
                <input
                  type="text"
                  ref="tel"
                  v-model.trim="info.tel"
                  :class="{ error: !getIsValidPhone(info.tel) }"
                />
              </dd>
            </dl>
            <dl>
              <dt>대표 이메일</dt>
              <dd>
                <input
                  type="text"
                  ref="email"
                  v-model.trim="info.email"
                  :class="{ error: !isValidEmail }"
                />
                <p v-show="!isValidEmail" class="alert-text txt-red">
                  <i class="icon-alert2" />
                  이메일 형식에 맞게 작성해주세요.
                </p>
                <p class="txt-orange">
                  <i class="icon-alert" />
                  고객문의 연락처 및 이메일은 주문정보에 노출되니 정확한 정보를 입력해주시기
                  바랍니다.
                </p>
              </dd>
            </dl>
          </div>
        </div>
        <div class="boxing" ref="orgbcopyfileWrapSection">
          <div class="boxing-title" :class="{ closed: !tabs.tab5 }">
            정산 정보
            <span class="required">*</span>
            <i class="arrcodi" @click="handleToggleTab('tab5')" />
          </div>
          <!-- 닫은 상태일때 closed 추가 -->
          <div class="form-area" v-show="tabs.tab5 === true">
            <!-- 닫은 상태일때 display:none -->
            <dl>
              <dt>정산일</dt>
              <dd>
                <p>전월 1일부터 말일까지의 구매확정건을 기준으로 영업일 기준 익월 말일 정산</p>
              </dd>
            </dl>
            <dl>
              <dt>계좌정보</dt>
              <dd>
                <select v-model.trim="info.remitbank" disabled>
                  <option v-for="bank in banks" :key="bank.note" :value="bank.note">
                    {{ bank.codeName }}
                  </option>
                </select>
                <input type="text" :value="info.remitaccount" style="width: 200px" disabled />
                <input type="text" :value="info.remitdepositor" disabled />
              </dd>
            </dl>
            <dl>
              <dt>기본수수료</dt>
              <dd v-if="isAdmin">
                <input
                  type="text"
                  ref="commrate"
                  class="right"
                  style="width: 50px"
                  maxlength="5"
                  v-model.trim="info.commrate"
                  @blur="handleBlurFee"
                />
                <span class="ml3">%</span>
              </dd>
              <dd v-if="viewType.toLowerCase() === 'save' && !isAdmin">
                <span class="ml3">{{ info.commrate }}%</span>
              </dd>
            </dl>
            <dl>
              <dt>통장사본</dt>
              <dd>
                <button
                  v-if="isWrite"
                  type="button"
                  ref="orgbcopyfileButton"
                  class="btn blue-line mr-10"
                  @click="fileAttach('orgbcopyfile')"
                >
                  통장 사본 파일첨부
                </button>
                <input
                  type="file"
                  ref="orgbcopyfile"
                  v-on:change="handleChangeFile('orgbcopyfile')"
                  v-show="false"
                  accept="image/jpeg, image/png, .pdf"
                />
                <div>
                  <button
                    v-if="info.bcopytype === 'FLT001'"
                    class="file-link"
                    @click="imageView(info.pathbcopyfile)"
                  >
                    {{ info.orgbcopyfile }}
                  </button>
                  <a
                    v-else-if="!!orgbcopyfile?.fullpath"
                    :href="info.pathbcopyfile"
                    class="file-link"
                  >
                    {{ info.orgbcopyfile }}
                  </a>
                  <span v-else-if="!orgbcopyfile?.fullpath">
                    {{ info.orgbcopyfile }}
                  </span>
                  <button
                    v-if="info.orgbcopyfile && isWrite"
                    type="button"
                    class="file-del"
                    @click="handleRemoveFile('orgbcopyfile')"
                  />
                </div>
              </dd>
            </dl>
            <dl v-if="isAdmin">
              <dt>e-account 정보</dt>
              <dd>
                고객코드
                <input
                  type="text"
                  ref="eaccustcode"
                  class="ml3 right"
                  style="width: 150px"
                  v-model.trim="info.eaccustcode"
                  maxlength="10"
                  :disabled="!isAdmin"
                />
                &nbsp;&nbsp;&nbsp;공급업체코드
                <input
                  type="text"
                  ref="eacsupcode"
                  class="ml3 right"
                  style="width: 150px"
                  v-model.trim="info.eacsupcode"
                  maxlength="10"
                  :disabled="!isAdmin"
                />
              </dd>
            </dl>
          </div>
        </div>
        <div class="boxing">
          <div class="boxing-title" :class="{ closed: !tabs.tab6 }">
            굿스플로 자동반품 택배사 계약 정보
            <i class="arrcodi" @click="handleToggleTab('tab6')" />
          </div>
          <!-- 닫은 상태일때 closed 추가 -->
          <div class="form-area" v-show="tabs.tab6 === true">
            <!-- 닫은 상태일때 display:none -->
            <dl
              v-for="(delivery, index) in deliveryList"
              v-bind:key="delivery.idx"
              v-show="index === 0 || index % 2 === 0"
            >
              <dt>{{ delivery.logistname }}</dt>
              <dd style="width: 406px">{{ delivery.locontcode }}</dd>
              <dt>
                {{
                  typeof deliveryList[index + 1] !== 'undefined'
                    ? deliveryList[index + 1].logistname
                    : ''
                }}
              </dt>
              <dd style="width: 406px">
                {{
                  typeof deliveryList[index + 1] !== 'undefined'
                    ? deliveryList[index + 1].locontcode
                    : ''
                }}
              </dd>
            </dl>
          </div>
        </div>
        <div class="boxing" ref="orgcsellfileWrapSection">
          <div class="boxing-title" :class="{ closed: !tabs.tab7 }">
            첨부 서류
            <span class="required">*</span>
            <i class="arrcodi" @click="handleToggleTab('tab7')" />
          </div>
          <!-- 닫은 상태일때 closed 추가 -->
          <div class="form-area" v-show="tabs.tab7 === true">
            <!-- 닫은 상태일때 display:none -->
            <dl>
              <dt>통신판매업신고증</dt>
              <dd class="pt10">
                <button
                  v-if="isWrite"
                  type="button"
                  class="btn blue-line mr-10"
                  @click="fileAttach('orgcsellfile')"
                >
                  통신판매업신고증 사본 파일첨부
                </button>
                <input
                  type="file"
                  ref="orgcsellfile"
                  v-on:change="handleChangeFile('orgcsellfile')"
                  v-show="false"
                  accept="image/jpeg, image/png, .pdf"
                />
                <div>
                  <button
                    v-if="info.cselltype === 'FLT001'"
                    class="file-link"
                    @click="imageView(info.pathcsellfile)"
                  >
                    {{ info.orgcsellfile }}
                  </button>
                  <a
                    v-else-if="!!orgcsellfile?.fullpath"
                    :href="info.pathcsellfile"
                    class="file-link"
                  >
                    {{ info.orgcsellfile }}
                  </a>
                  <span v-else-if="!orgcsellfile?.fullpath">
                    {{ info.orgcsellfile }}
                  </span>
                  <button
                    v-if="info.orgcsellfile && isWrite"
                    type="button"
                    class="file-del"
                    @click="handleRemoveFile('orgcsellfile')"
                  />
                </div>
                <p class="txt-orange">
                  <i class="icon-alert" />
                  jpg, jpeg, png파일 또는 PDF 파일만 첨부 가능하며, 파일 최대 크기는 10MB를 초과 할
                  수 없습니다.
                </p>
              </dd>
            </dl>
          </div>
        </div>
        <div class="boxing">
          <div class="boxing-title" :class="{ closed: !tabs.tab3 }">
            추가 사항
            <i class="arrcodi" @click="handleToggleTab('tab3')" />
          </div>
          <!-- 닫은 상태일때 closed 추가 -->
          <div class="form-area" v-show="tabs.tab3 === true">
            <!-- 닫은 상태일때 display:none -->
            <dl>
              <dt>
                담당 MD
                <span class="required">*</span>
              </dt>
              <dd>
                <select
                  class="medium"
                  v-model.trim="mdManagerId"
                  ref="mdManagerId"
                  :disabled="isPartner"
                >
                  <option value="" selected disabled>담당 MD 선택</option>
                  <option v-for="md in mdManagers" v-bind:key="md.id" :value="md.id">
                    {{ md.name }}
                  </option>
                </select>
              </dd>
            </dl>
            <dl v-if="info.reqdealstname !== '승인'">
              <dt>반려 사유</dt>
              <dd>
                <textarea
                  ref="reasonRejectMessage"
                  @input="handleInputRsasonReject"
                  placeholder="최대 100자까지 입력가능합니다."
                  maxlength="100"
                />
                <p class="txt-orange">
                  <i class="icon-alert" />
                  반려시 사유는 필수로 입력해주세요.
                </p>
              </dd>
            </dl>
          </div>
        </div>
        <div class="btn-group">
          <button
            v-if="viewType.toLowerCase() === 'apply' && isAdmin && isWrite && isSameDetailInfo"
            type="button"
            class="btn big blue"
            @click="handleApprovePartner"
          >
            입점승인
          </button>
          <button
            v-if="viewType.toLowerCase() === 'apply' && isAdmin && isWrite && !isSameDetailInfo"
            type="button"
            class="btn big blue"
            @click="handleSave"
          >
            저장
          </button>
          <button
            v-if="viewType.toLowerCase() === 'apply' && isAdmin && isWrite"
            type="button"
            class="btn big red"
            @click="handleRejectPartner"
          >
            반려
          </button>
          <button
            v-if="viewType.toLowerCase() === 'save' && isWrite && !isSameDetailInfo"
            type="button"
            class="btn big blue"
            @click="handleSave"
          >
            저장
          </button>
          <button type="button" class="btn big darkgray" @click="handleCloseDetailModal">
            닫기
          </button>
        </div>
      </div>
      <!-- TODO 아래 태그가 어떠한 역할을 하는지 여부 파악 필요 -->
      <!-- <form
        ref="nice_form"
        name="form_chk"
        method="post"
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
      >
        <input type="hidden" name="m" value="checkplusService" />
        <input type="hidden" ref="encData" name="EncodeData" />
      </form> -->
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import storage from '@/js/storage';
import constants from '@js/constants';
import util from '@/js/util';
import store from '@/js/store';
import SearchAddress from '@/views/common/components/SearchAddress.vue';

const { VUE_APP_API_V2_PREFIX } = process.env;
const DETAIL_INFO_BASE = {
  // 히든 클래스를 생성 방지를 위한 초기화
  addr: '', // 주소
  addrdetail: '', // 상세 주소
  bcopytype: '', // 통장사본 파일타입
  bizcondition: '', // 업태
  bizitem: '', // 종목
  bizno: '', // 사업자 번호
  biznotype: '', // 사업자등록증 파일타입
  buildingcode: '', // 빌딩코드
  chargemail: '',
  commrate: '0',
  compname: '',
  comsellno: '',
  cselltype: '',
  dealerst: '',
  eaccustcode: '',
  eacsupcode: '',
  email: '',
  handbrand: '',
  isadmailing: '',
  isadsms: '',
  md: '',
  mobile: '',
  muaddauthtype: '',
  name: '',
  no: 0,
  orgbcopyfile: '',
  orgbiznofile: '',
  orgcsellfile: '',
  pathbcopyfile: '',
  pathbiznofile: '',
  pathcsellfile: '',
  post: '',
  reject_reason: '',
  remitaccount: '',
  remitbank: '',
  remitbirth: '',
  remitdepositor: '',
  repci: '',
  reprename: '',
  reqdealst: '',
  reqdealstname: '',
  roadnamecode: '',
  sellprodtype: '',
  sigungucode: '',
  tel: '',
  userid: '',
  managername: '',
  managermobile: '',
};
let tempDetailInfo = {
  // 변경사항을 비교할 temp 변수
  info: null,
  dealerChargeList: null,
  mdManagerId: '',
  orgbiznofile: null,
  orgbcopyfile: null,
  orgcsellfile: null,
};
let tempManagers = []; // 상세 데이터 불러올 때 초기 담당자 데이터. 저장하기 전에 비밀번호 초기화 시 저장된 기준으로 제휴담당자를 찾아 전송해야하기 때문에.

export default {
  name: 'admin.partners.applyList.detail',
  components: {
    'search-address': SearchAddress,
  },
  props: ['activeUserNo', 'disableInitPasswordButton', 'disableInitPartnerNameInput'],
  data() {
    return {
      info: { ...DETAIL_INFO_BASE },
      dealerChargeList: [],
      prodTypeList: [],
      chargeTypeList: [],
      authTypeList: [],
      authCheckArray: [],
      deliveryList: [],
      configAuthTypeOrigin: '',
      orgbiznofile: null,
      orgbcopyfile: null,
      orgcsellfile: null,
      tabs: {
        tab1: true,
        tab2: true,
        tab3: true,
        tab4: true,
        tab5: true,
        tab6: true,
        tab7: true,
        tab8: true,
      },
      parentName: '',
      viewType: '', //SAVE : 정보수정용으로 접근한 경우, APPLY : 입정승인처리로 접근한 경우
      banks: [], //은행 목록
      reasonRejectMessage: '', // 반려 사유
      mdManagerId: '', //MD 담당자 id
      mdManagers: [], // md 담당자 목록
      isSameDetailInfo: true, // 데이터 변경이 있는지 없는지 여부
      isValidEmail: true, // 이메일 validation, 기본은 valid
      isAdmin: false,
      isPartner: false,
      isBasicAuth: true,
      isRead: false,
      isWrite: false,
      files: [], // 변경된 file attach 목록
    };
  },
  watch: {
    info: {
      handler() {
        this.setIsSameDetailInfo();
      },
      deep: true,
    },
    orgbiznofile: {
      handler() {
        this.setIsSameDetailInfo();
      },
      deep: true,
    },
    orgbcopyfile: {
      handler() {
        this.setIsSameDetailInfo();
      },
      deep: true,
    },
    orgcsellfile: {
      handler() {
        this.setIsSameDetailInfo();
      },
      deep: true,
    },
    dealerChargeList: {
      handler() {
        this.setIsSameDetailInfo();
      },
      deep: true,
    },
    mdManagerId() {
      this.setIsSameDetailInfo();
    },
    'info.tel': {
      handler(val) {
        this.handleChangePhoneNumber(val);
      },
      deep: true,
    },
    'info.email': {
      handler(val) {
        this.handleChangeEmail(val);
      },
      deep: true,
    },
    'info.commrate': {
      handler(val) {
        this.handleChangeFee(val);
      },
      deep: true,
    },
    'info.md': {
      handler(val) {
        const findManager = this.mdManagers?.find((md) => md.name === val);
        if (!findManager) {
          return;
        }
        this.mdManagerId = findManager.id;
      },
      deep: true,
    },
    authCheckArray: function (newValue, oldValue) {
      let optionAuthCode = this.$store.getters['ADMIN'].EDIT_AUTH_OPTION;
      let marketPriceAuthCode = this.$store.getters['ADMIN'].EDIT_AUTH_MARKET_PRICE;
      let priceAuthCode = this.$store.getters['ADMIN'].EDIT_AUTH_PRICE;
      let stockCntAuthCode = this.$store.getters['ADMIN'].EDIT_AUTH_STOCK_CNT;
      // 옵션 체크시 정상가, 판매가, 재고 체크
      if (oldValue.indexOf(optionAuthCode) < 0 && newValue.indexOf(optionAuthCode) > -1) {
        if (this.authCheckArray.indexOf(marketPriceAuthCode) < 0) {
          this.authCheckArray.push(marketPriceAuthCode);
        }
        if (this.authCheckArray.indexOf(priceAuthCode) < 0) {
          this.authCheckArray.push(priceAuthCode);
        }
        if (this.authCheckArray.indexOf(stockCntAuthCode) < 0) {
          this.authCheckArray.push(stockCntAuthCode);
        }
      }
      // 정상가, 판매가, 재고 체크해제시 옵션체크 해제
      if (
        (oldValue.indexOf(marketPriceAuthCode) > -1 && newValue.indexOf(marketPriceAuthCode) < 0) ||
        (oldValue.indexOf(priceAuthCode) > -1 && newValue.indexOf(priceAuthCode) < 0) ||
        (oldValue.indexOf(stockCntAuthCode) > -1 && newValue.indexOf(stockCntAuthCode) < 0)
      ) {
        let findIdx = this.authCheckArray.indexOf(optionAuthCode);
        if (findIdx > 0) {
          this.authCheckArray.splice(this.authCheckArray.indexOf(optionAuthCode), 1);
        }
      }
    },
  },
  beforeMount() {
    this.getBanks();
    this.getMDManagers();
  },
  mounted() {
    this.parentName = this.$parent.$options.name;
    this.isPartner = this.$util.isAuthorized(this.$store.getters['CONSTANTS'].PARTNER_USER);

    const session = storage.getLocalStorage(store.getters.CONSTANTS.MANAGER_SESSION);
    if (session.usertype !== this.$store.getters.CONSTANTS.ADMIN.USER_TYPE_PARTNER) {
      this.initDetailWithPageAuth();
    } else if (
      session.usertype === this.$store.getters.CONSTANTS.ADMIN.USER_TYPE_PARTNER &&
      Number(session.no) === Number(this.activeUserNo)
    ) {
      this.initDetailFromPartner();
    }
  },
  computed: {
    dispalyManagerMobile() {
      return util.replaceMobileNumber(this.info.managermobile);
    },
    showInitPasswordButton() {
      if (this.disableInitPasswordButton) {
        return false;
      }

      return this.isWrite && !this.isPartner;
    },
  },
  methods: {
    // 사용자가 파트너일 경우 초기화
    initDetailFromPartner() {
      this.isRead = true;
      this.isWrite = true;

      if (this.parentName !== 'partners.info.auth') {
        this.isWrite = false;
      }

      this.initDetail();
    },
    // 사용자가 파트너가 아닐 경우 권한 체크 후 초기화
    async initDetailWithPageAuth() {
      try {
        const { data } = await this.$http.post('/admin/common/pageAuth/check', {
          url: this.$options.name,
        });

        this.isRead = data.isread === 'T';
        this.isWrite = data.iswrite === 'T';
        this.isAdmin = true;

        this.initDetail();
      } catch (err) {
        util.debug(err.message);
      }
    },
    debounceApproveRejectPartner: debounce(
      async function ({ type = 'reject' }) {
        try {
          const id = this.info.no;
          const isApproveEntry = type === 'approve'; // 승인 true, 반려 false
          const rejectReason = this.reasonRejectMessage;
          if (type === 'reject' && !rejectReason.trim().length) {
            alert('반려시에는 반려메시지를 필수로 입력해주세요.');
            const key = 'reasonRejectMessage';
            const targetEl = this.$refs[key];
            this.scrollIntoViewTarget({ key, targetEl });
            return;
          }
          const params = {
            id,
            isApproveEntry,
            ...(!isApproveEntry && { entryRejectReason: rejectReason }), // 반려일 경우에만 반려메시지를 전달한다.
            isloading: true,
          };
          await this.$http.post(
            `${VUE_APP_API_V2_PREFIX}/partner/entry/request/update/status`,
            params,
          );
          this.isAdmin &&
            this.viewType.toLowerCase() === 'apply' &&
            this.$eventBus.$emit('getPartnerApplyList'); // 어드민이고 입점 관리 페이지일 경우 해당 리스트도 갱신한다.
          alert(isApproveEntry ? '승인되었습니다.' : '반려되었습니다.');
          this.$emit('closeDetail');
        } catch (err) {
          alert(err.message);
        }
      },
      500,
      { leading: true, trailing: false },
    ),
    handleApprovePartner() {
      if (confirm('입점신청을 승인하시겠습니까?')) {
        const resultValidation = this.getResultValidationsWithAlert();
        resultValidation && this.debounceApproveRejectPartner({ type: 'approve' }); // 입점 승인도 validation 모두 통과해야 가능하다.
      }
    },
    handleRejectPartner() {
      if (confirm('입점신청을 반려하시겠습니까?')) {
        this.debounceApproveRejectPartner({ type: 'reject' });
      }
    },
    // 주소 검색 완료시 데이터 설정을 한다.
    handleCompleteSearchAddress(data) {
      this.info.post = data.zonecode;
      this.info.addr = data.address;
      this.info.sigunguCode = data.sigunguCode;
      this.info.buildingCode = data.buildingCode;
      this.info.roadNameCode = data.roadnameCode;
    },
    handleChangeAddressDetail(addressDetail) {
      this.info.addrdetail = addressDetail;
    },
    handleChangeCharger({ target }, charger) {
      const { value } = target;
      const findItem = this.chargeTypeList.find((type) => type.cmcode === value);
      if (!findItem) {
        charger.chargetype = '';
        charger.chargetypename = '';
        return;
      }
      charger.chargetype = value;
      charger.chargetypename = findItem.codename;
    },
    setIsSameDetailInfo() {
      const summary = this.getDetailInfoSummary();
      const filterUUIDUserNoManagersByTemp = {
        ...tempDetailInfo,
        dealerChargeList: [
          ...tempDetailInfo.dealerChargeList.map((manager) => {
            const { uuid, userno, ...other } = manager;
            return other;
          }),
        ],
      };

      const filterUUIDUserNoManagersBySummary = {
        ...summary,
        dealerChargeList: [
          ...summary.dealerChargeList.map((manager) => {
            const { uuid, userno, ...other } = manager;
            return other;
          }),
        ],
      };

      this.isSameDetailInfo = isEqual(
        filterUUIDUserNoManagersByTemp,
        filterUUIDUserNoManagersBySummary,
      );
    },
    handleCloseDetailModal() {
      this.closeDetailModal();
    },
    // 휴대폰 번호 validation
    getIsValidMobile(val) {
      if (!val) {
        return true;
      }
      return util.isValidMobileNumber(val);
    },
    // 이메일 validation
    getIsValidEmail(val) {
      if (!val) {
        return true;
      }
      return util.isValidEmail(val);
    },
    // 전화 또는 휴대폰 번호 validation
    getIsValidPhone(val) {
      if (!val) {
        return true;
      }
      return util.isValidPhoneNumber(val);
    },
    // 담당자 휴대폰 번호 변경시 '-' 추가해준다.
    handleChangeManagerMobileNumber({ target }, elIdx) {
      const { value } = target;
      const replaced = util.replaceMobileNumber(value);

      this.dealerChargeList[elIdx].chargemobile = replaced;
      target.value = replaced;
    },
    // 담당자 이메일 변경시 validation 한다.
    handleChangeManagerEmail({ target }, elIdx) {
      const { value } = target;
      this.dealerChargeList[elIdx].chargeemail = value;
      target.value = value;
    },
    getShowManagerDeleteButton() {
      const dealerChargeList = this.dealerChargeList;

      if (dealerChargeList.length === 1 && dealerChargeList[0].isCreated) {
        return false;
      } else if (dealerChargeList.length === 1 && !dealerChargeList[0].isCreated) {
        return true;
      } else if (dealerChargeList.length > 1) {
        return true;
      }
    },
    // 담당 MD validation
    checkValidMDInfo() {
      if (!this.mdManagerId) {
        alert('담당 MD를 선택해주세요.');
        const key = 'mdManagerId';
        const targetEl = this.$refs[key];
        this.scrollIntoViewTarget({ key, targetEl });
        return false;
      }
      return true;
    },
    // 전체 validation 을 하여 validation 결과를 반환한다. 이 과정에서 validation 통과를 못하면 얼럿을 노출한다.
    getResultValidationsWithAlert() {
      const isValidBusiness = this.checkValidBusinessInfo(); //사업자 정보
      if (!isValidBusiness) {
        return false;
      }
      const isValidCSInfo = this.checkValidCSInfo(); //고객문의 연락처정보
      if (!isValidCSInfo) {
        return false;
      }
      const isValidManagerInfo = this.checkValidManagerInfo(); //담당자 정보
      if (!isValidManagerInfo) {
        return false;
      }
      const isValidCalculateInfo = this.checkValidCalculateInfo(); // 정산 정보
      if (!isValidCalculateInfo) {
        return false;
      }
      const isValidAttachedDocumentInfo = this.checkValidAttachedDocumentInfo(); //첨부서류 정보
      if (!isValidAttachedDocumentInfo) {
        return false;
      }
      const isValidMDInfo = this.checkValidMDInfo(); //첨부서류 정보
      if (!isValidMDInfo) {
        return false;
      }
      return true;
    },
    // 파트너 정보를 저장하기 위한 리퀘스트 body를 만들어서 반환한다.
    getTransformDetailInfoForRequest() {
      const { no, commrate } = this.info;
      const findCategory = this.prodTypeList.find((type) => type.name === this.info.sellprodtype); // 카테고리를 이름만 내려줘서 id를 find 하기위한 로직
      const categoryId = findCategory?.idx.toString();
      const mdId = this.mdManagerId.toString();
      const id = no.toString(); // 문자열인지 숫자인지?
      const fee = !!commrate.toString() && !isNaN(Number(commrate)) ? Number(commrate) : 0;

      if (!categoryId) {
        throw new Error('카테고리 설정에 오류가 발생했습니다.');
      }

      const {
        name: companyName,
        bizcondition: bizCondition,
        bizitem: bizItem,
        post: postNo,
        addr: address,
        addrdetail: addressDetail,
        tel: helpPhoneNo,
        email,
        eaccustcode: eAccountCustomerCode,
        eacsupcode: eAccountSupplierCode,
        sigungucode: sigunguCode,
        buildingcode: buildingCode,
        roadnamecode: roadNameCode,
        comsellno: tellMarketNo,
      } = this.info;

      return {
        id,
        companyName,
        bizCondition,
        bizItem,
        postNo,
        address,
        addressDetail,
        helpPhoneNo: helpPhoneNo.replaceAll('-', ''), // req params에는 하이픈 제거하여 전달한다.
        email,
        eAccountCustomerCode,
        eAccountSupplierCode,
        categoryId,
        mdId,
        fee,
        sigunguCode,
        buildingCode,
        roadNameCode,
        tellMarketNo,
      };
    },
    // 파트너 정보를 저장한다.
    async saveDetailInfo() {
      try {
        const resultValidation = this.getResultValidationsWithAlert();
        if (!resultValidation) {
          return;
        }
        const detailInfo = this.getTransformDetailInfoForRequest();
        const copiedBizRegistration = this.orgbiznofile?.fullpath ? null : this.orgbiznofile; //파일 변경되지 않으면 req에 추가할 필요없다.
        const copiedBankBook = this.orgbcopyfile?.fullpath ? null : this.orgbcopyfile; //파일 변경되지 않으면 req에 추가할 필요없다.
        const onlineBizReport = this.orgcsellfile?.fullpath ? null : this.orgcsellfile; //파일 변경되지 않으면 req에 추가할 필요없다.
        const isReqFileParams = copiedBizRegistration || copiedBankBook || onlineBizReport;
        const fileParams = isReqFileParams ? { files: [] } : {}; //파일이 없다면 files req param 필요없음.
        const frmData = new FormData(); // form 데이터로 전달해야함. (입점 신청 및 수정에서만)
        let params = null;
        if (isReqFileParams) {
          // 파일 변경이 일어 났을 경우
          params = Object.assign({}, fileParams, detailInfo, { isloading: true });
          copiedBizRegistration &&
            params.files.push({ key: 'copiedBizRegistration', file: copiedBizRegistration });
          copiedBankBook && params.files.push({ key: 'copiedBankBook', file: copiedBankBook });
          onlineBizReport && params.files.push({ key: 'onlineBizReport', file: onlineBizReport });
        } else {
          // 파일 변경이 없을 경우
          const entries = Object.entries(detailInfo);
          entries.forEach(([key, value]) => frmData.append(key, value));
        }

        const saveManagersParams = this.getTransformSaveManagersInfoForRequest();
        const getPromises = ({ url, params }) => {
          return this.$http.post(url, params);
        };
        const promisesInfo = [
          {
            url: `${VUE_APP_API_V2_PREFIX}/partner/update`,
            params: params ?? frmData ?? {},
          },
          {
            url: `${VUE_APP_API_V2_PREFIX}/partner/update/managers`,
            params: saveManagersParams,
          },
        ];
        const promises = promisesInfo.map(({ url, params }) => getPromises({ url, params }));
        await Promise.all(promises);

        // 담당자도 저장해야 한다. API 분리되어 있음.
        this.initDetail();
        this.setIsSameDetailInfo(); // 저장 후에 초기 데이터가 같음으로 판단
        if (this.isAdmin) {
          this.viewType.toLowerCase() === 'apply' && this.$eventBus.$emit('getPartnerApplyList'); // 어드민이면 리스트도 갱신한다.
          this.viewType.toLowerCase() === 'save' && this.$eventBus.$emit('getPartners'); // 어드민이면 리스트도 갱신한다.
        }
        this.isPartner && store.commit('partnerCompanyName', detailInfo.companyName); // 파트너사가 정보수정시 파트너 헤더 컴포넌트의 파트너 업체명을 업데이트 한다.
        alert('저장이 완료되었습니다.');
      } catch (err) {
        alert(err.message);
      }
    },
    // 담당자 정보를 저장하기 위한 리퀘스트 body를 만들어서 반환한다.
    getTransformSaveManagersInfoForRequest() {
      const id = this.info.no;
      const managerList = this.dealerChargeList
        .map((manager) => {
          const {
            chargeemail: managerEmail,
            chargemobile: managerMobileNo,
            chargetype: managerType,
            chargename: managerName,
            idx: managerId,
          } = manager;

          return {
            managerId: manager.isCreated ? '' : managerId, // 매니저 ID를 주면 업데이트고 안주면 추가한다.
            managerName,
            managerEmail,
            managerMobileNo: managerMobileNo.replaceAll('-', ''), // req params에는 하이픈 제거하여 전달한다.
            managerType,
          };
        })
        .filter((manager) => {
          //managerId는 옵셔널이라 해당 값 제외 모든 데이터가 있는 담당자만 request body에 포함시킨다.
          const { managerName, managerEmail, managerMobileNo, managerType } = manager;
          if (!!managerName && !!managerEmail && !!managerMobileNo && !!managerType) {
            return true;
          }
          return false;
        });
      return {
        managerList,
        partnerId: id,
      };
    },
    handleSave() {
      if (confirm('변경된 내용을 저장시겠습니까?')) {
        this.saveDetailInfo();
      }
    },
    // 세금 변경시 정규식에 맞게 변경한다.
    handleChangeFee(fee) {
      let temp = fee;
      const pattern = util.regexDecimaloint; // 소수점 정규식
      if (!pattern.test(temp)) {
        temp = temp.substr(0, temp.length - 1);
      }
      this.info.commrate = temp;
    },
    // 모바일 및 전화번호 정규식으로 자동 하이픈 추가 및 숫자만 허용
    handleChangePhoneNumber(value) {
      const result = util.replacePhoneNumber(value);

      this.info.tel = result;
    },
    handleInputRsasonReject({ target }) {
      const { value } = target;
      this.reasonRejectMessage = value;
    },
    // 이메일 입력시 validation 체크
    handleChangeEmail(value = '') {
      if (!value) {
        this.info.email = '';
        this.isValidEmail = true;
        return;
      }
      const replaced = value.replaceAll(' ', '');
      this.info.email = replaced;
      if (!replaced) {
        this.isValidEmail = true;
        return;
      }

      this.isValidEmail = util.isValidEmail(replaced);
    },
    showInvalidMessage(refKey = '') {
      switch (refKey) {
        case 'name':
          alert('업체명을 입력해주세요.');
          break;
        case 'bizcondition':
          alert('업태를 입력해주세요.');
          break;
        case 'bizitem':
          alert('종목을 입력해주세요.');
          break;
        case 'address':
          alert('주소를 입력해주세요.');
          break;
        case 'addressDetail':
          alert('상세주소를 입력해주세요.\n상세주소가 없을 경우 - (대시) 를 입력해주세요.');
          break;
        case 'sellprodtype':
          alert('판매상품유형의 대표카테고리를 선택해주세요.');
          break;
        case 'comsellno':
          alert('통신판매신고번호를 입력해주세요.');
          break;
        case 'tel':
          this.showAlertMessageByPhoneNumber(refKey);
          break;
        case 'email':
          this.showAlertMessageByCompanyEmail();
          break;
        case 'commrate':
          alert('기본수수료를 입력해주세요.');
          break;
        case 'eaccustcode':
          alert('고객코드를 입력해주세요.');
          break;
        case 'eacsupcode':
          alert('공급업체코드를 입력해주세요.');
          break;
        case 'orgbiznofile':
        case 'orgcsellfile':
        case 'orgbcopyfile': {
          const prefixMap = {
            orgbiznofile: '사업자등록증사본',
            orgbcopyfile: '통장사본',
            orgcsellfile: '통신판매업신고증',
          };
          alert(`${prefixMap[refKey]}을 첨부해주세요.`);
          break;
        }
        default:
          break;
      }
    },
    // 대표이메일 validation 실패시 얼럿을 띄운다.
    showAlertMessageByCompanyEmail() {
      const { email } = this.info;
      if (!email) {
        alert('대표 이메일을 입력해주세요.');
      } else if (!!email && !this.isValidEmail) {
        alert('대표 이메일을 정확히 입력해주세요.');
      }
    },
    // 고객문의 전화 validation 실패시 얼럿을 띄운다.
    showAlertMessageByPhoneNumber() {
      const value = this.info[inputKey] ?? '';

      if (!value) {
        alert('고객문의 연락처를 입력해주세요.');
        return;
      }

      const isValid = util.isValidPhoneNumber(value);

      if (!isValid) {
        alert('고객문의 연락처를 정확히 입력해주세요.');
        return;
      }
    },
    getValidBusiness() {
      return [
        { key: 'orgbiznofile', result: this.getValidFileAttach('orgbiznofile') },
        { key: 'name', result: this.getValidNormalInput('name') }, //업체명
        { key: 'bizcondition', result: this.getValidNormalInput('bizcondition') }, //업태
        { key: 'bizitem', result: this.getValidNormalInput('bizitem') }, //종목
        { key: 'address', result: this.getValidNormalInput('addr') }, //주소
        { key: 'addressDetail', result: this.getValidNormalInput('addrdetail') }, //주소
        { key: 'sellprodtype', result: this.getValidNormalInput('sellprodtype') }, //판매상품유형
        { key: 'comsellno', result: this.getValidNormalInput('comsellno') }, //통신판매 신고번호
      ];
    },
    getValidCSInfo() {
      return [
        { key: 'tel', result: this.getValidCsPhoneNumber() }, //고객문의 연락처
        { key: 'email', result: this.getValidCompanyEmail() }, //대표 이메일
      ];
    },
    getValidCalculateInfo() {
      return [
        { key: 'commrate', result: this.getValidFee() }, //수수료
        { key: 'orgbcopyfile', result: this.getValidFileAttach('orgbcopyfile') }, //통장 사본
        { key: 'eaccustcode', result: this.getValidNormalInput('eaccustcode') }, //고객코드
        { key: 'eacsupcode', result: this.getValidNormalInput('eacsupcode') }, //공급업체코드
      ];
    },
    // 대표 이메일 validation
    getValidCompanyEmail() {
      return !!this.info.email && this.isValidEmail;
    },
    // 고객문의 전화 validation
    getValidCsPhoneNumber() {
      return util.isValidPhoneNumber(this.info.tel);
    },
    // validation 통과 못한 영역으로 스크롤 이동
    scrollIntoViewTarget({ key, targetEl }) {
      const isNotFile = key !== 'orgbiznofile' && key !== 'orgcsellfile' && key !== 'orgbcopyfile';
      if (!targetEl || !key) {
        return;
      }
      if (isNotFile) {
        targetEl.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          targetEl?.focus();
        }, 80);
      } else {
        const scrollModalBodyRef = this.$refs['popupBody'];
        scrollModalBodyRef.scrollTo({
          top: targetEl.offsetTop - 100,
          behavior: 'smooth',
        });
      }
    },
    getValidFileAttach(fileKey) {
      const isEmpty = !this[fileKey];
      if (isEmpty) {
        return false;
      }
      return true;
    },
    // 수수료 validation
    getValidFee() {
      const fee = Number(this.info['commrate']);
      if (isNaN(fee)) {
        return false;
      }
      return 0 < fee;
    },
    // 보통의 인풋 validation
    getValidNormalInput(key) {
      return !!this.info[key];
    },
    checkValidBusinessInfo() {
      const validBusiness = this.getValidBusiness();
      return this.getIsValidSection(validBusiness);
    },
    getIsValidSection(validInfo) {
      const findFailValid = validInfo.find((validation) => !validation.result);
      if (findFailValid) {
        const fileKeys = ['orgbiznofile', 'orgcsellfile', 'orgbcopyfile'];
        const key = findFailValid.key;
        const isFile = fileKeys.includes(key);
        let targetEl = this.$refs[isFile ? `${key}WrapSection` : key];
        if (!targetEl) {
          const findChild = this.$children?.find(
            (child) => child?.$vnode.tag.includes('SearchAddress'), // 컴포넌트 이름으로 vnode 찾음
          );
          targetEl = findChild?.$refs?.[key];
        }

        this.showInvalidMessage(key);
        this.scrollIntoViewTarget({ key, targetEl });
        return false;
      }
      return true;
    },
    // 담당자 목록 validation
    checkValidManagerInfo() {
      const managers = this.dealerChargeList;
      let hasFailValid = false; // valiation 에러 발생한게 있는지 여부
      managers.some((manager) => {
        const { chargeemail, chargemobile, chargename, chargetype } = manager;
        const hasInfoValue = !!chargeemail || !!chargemobile || !!chargename || !!chargetype; // 값이 하나라도 설정했으면 데이터 추가하는 거로 판단 Valid 여부 판단한다.
        const hasAllInfoValue = !!chargeemail && !!chargemobile && !!chargename && !!chargetype;
        if (!hasInfoValue) {
          return false;
        }
        let message = '담당자 입력을 완료해주세요.';
        if (hasAllInfoValue) {
          if (!this.getIsValidMobile(chargemobile)) {
            alert('담당자 휴대폰 번호를 정확하게 입력해주세요.');
            hasFailValid = true;
            return hasFailValid;
          } else if (!this.getIsValidEmail(chargeemail)) {
            alert('담당자 이메일을 정확하게 입력해주세요.');
            hasFailValid = true;
            return hasFailValid;
          }
          return false;
        } else {
          if (!this.getIsValidMobile(chargemobile)) {
            message = '담당자 휴대폰 번호를 정확하게 입력해주세요.';
          } else if (!this.getIsValidEmail(chargeemail)) {
            message = '담당자 이메일을 정확하게 입력해주세요.';
          }
          alert(message);
          hasFailValid = true;
          return hasFailValid;
        }
      });
      if (hasFailValid) {
        const key = 'manager';
        const targetEl = this.$refs[`${key}WrapSection`];
        this.scrollIntoViewTarget({ key, targetEl });
        return false;
      }
      return true;
    },
    checkValidCSInfo() {
      const validCSInfo = this.getValidCSInfo();
      return this.getIsValidSection(validCSInfo);
    },
    checkValidCalculateInfo() {
      const validCalculateInfo = this.getValidCalculateInfo();
      return this.getIsValidSection(validCalculateInfo);
    },
    checkValidAttachedDocumentInfo() {
      const validInfo = [{ key: 'orgcsellfile', result: this.getValidFileAttach('orgcsellfile') }];
      return this.getIsValidSection(validInfo);
    },
    // MD 담당자 목록을 가져온다.
    async getMDManagers() {
      try {
        const apiUrl = `${VUE_APP_API_V2_PREFIX}/md-info/list`;
        const { data = [] } = await this.$http.get(apiUrl);
        if (!data.length) {
          throw new Error('MD 담당자 목록을 가져오는데 오류가 발생했습니다.');
        }
        this.mdManagers = data;
      } catch (err) {
        alert(err.message);
        this.mdManagers = [];
      }
    },
    // 은행 목록을 가져온다.
    async getBanks() {
      try {
        const apiUrl = `${VUE_APP_API_V2_PREFIX}/common-code?cmClass=TBANKTYPE`;
        const { data = [] } = await this.$http.get(apiUrl);
        if (!data.length) {
          throw new Error('은행 정보를 가져오는데 오류가 발생했습니다.');
        }
        this.banks = data;
      } catch (err) {
        alert(err.message);
        this.banks = [];
      }
    },
    //초기 데이터에서 반려메시지가 있으면 설정한다.
    setInitRejectReason(rejectReasonMessage) {
      // 반려메시지 영역은 입점 신청이 승인 완료되지 않은 상황에서만 보인다.
      if (this.viewType.toLowerCase() === 'save') {
        return;
      }
      this.$refs['reasonRejectMessage'].value = rejectReasonMessage ?? '';
      this.reasonRejectMessage = rejectReasonMessage ?? '';
    },
    async initDetail() {
      try {
        if (this.isRead) {
          const session = this.$storage.getLocalStorage(store.getters.CONSTANTS.MANAGER_SESSION);
          const param = {
            userNo: this.activeUserNo,
            userType: session.usertype,
            isloading: true,
          };
          const { data } = await this.$http.post('/admin/partners/apply/detail', param);
          const detailInfo = data?.detailinfo ?? { ...DETAIL_INFO_BASE };
          this.info = {
            // temp 저장을 위한 초기값 설정
            // null 또는 필드가 없는 상태로 내려와서 초기화 개념으로 빈문자열로 변경
            orgbiznofile: '',
            orgbcopyfile: '',
            orgcsellfile: '',
            pathbiznofile: '',
            biznotype: '',
            pathbcopyfile: '',
            bcopytype: '',
            pathcsellfile: '',
            cselltype: '',
            ...detailInfo,
            eaccustcode: detailInfo.eaccustcode ?? '',
            eacsupcode: detailInfo.eacsupcode ?? '',
            email: detailInfo.email ?? '',
            addr: detailInfo.addr ?? '',
            addrdetail: detailInfo.addrdetail ?? '',
            comsellno: detailInfo.comsellno ?? '',
            commrate: detailInfo.commrate?.toString() ?? '0', // 문자열로 사용하고 request일 때만 숫자로 치환한다.
          };
          this.viewType.toLowerCase() !== 'apply' &&
            this.setInitRejectReason(detailInfo['reject_reason']); //반려메시지가 있으면 설정한다. (입점 관리 메뉴에서만 실행한다.)
          // null 또는 필드가 없는 상태로 내려와서 초기화 개념으로 빈문자열 또는 초기값 설정
          this.dealerChargeList = data.dealerchargelist?.map((managerInfo) => {
            managerInfo.uuid = uuidv4();
            managerInfo.chargemobile = util.replaceMobileNumber(managerInfo.chargemobile ?? '');
            managerInfo.chargeemail = managerInfo.chargeemail ?? '';
            return managerInfo;
          });
          tempManagers = cloneDeep(this.dealerChargeList);

          this.prodTypeList = data.prodtypelist;
          this.chargeTypeList = data.chargetypelist;
          this.authTypeList = data.authtypelist;
          this.deliveryList = data.deliverylist;
          this.configAuthTypeOrigin = data.configauthtypeorigin;

          const findMd = this.mdManagers?.find((md) => md.name === this.info.md); //md 목록을 찾아서 매핑해준다. (이름만 내려오는 중)
          if (findMd) {
            this.mdManagerId = findMd.id;
          }

          this.setDataFiles(data.partnersfiles);

          if (!this.dealerChargeList.length) {
            this.addCharger();
          }

          if (typeof this.info.muaddauthtype !== 'undefined') {
            const authTypeArray = this.info.muaddauthtype?.split(',') ?? [];
            this.authCheckArray = [...authTypeArray];
          }

          const configAuthArray = this.configAuthTypeOrigin?.split(',') ?? [];
          this.isBasicAuth = this.authCheckArray.sort().join() === configAuthArray.sort().join();
          this.viewType = this.info.reqdealst === 'RDS002' ? 'SAVE' : 'APPLY';

          this.setTempDetailInfo();
        } else {
          alert('페이지 접근 권한이 없습니다.');
          this.info = this.$options.data().info;
          this.$emit('closeDetail');
        }
      } catch (err) {
        this.$util.debug(err);
        this.closeDetailModal();
      }
    },
    handleBlurFee({ target }) {
      const { value } = target;
      const temp = Number(value).toString();
      this.info.commrate = isNaN(Number(temp)) ? '0' : temp;
    },
    // 초기에 init 데이터를 temp에 저장한다.
    setTempDetailInfo() {
      const summary = this.getDetailInfoSummary();
      tempDetailInfo = cloneDeep(summary);
      const targetDetailInfoKey = 'info';
      // 전화/모바일 번호가 temp에는 '-' 이 없는 상태로 저장되기 때문에 입력할 때처럼 하이픈을 넣어준다.
      // 수수료 값이 없으면 기본 0으로 넣어준다.
      Object.keys(tempDetailInfo[targetDetailInfoKey]).forEach((infoKey) => {
        infoKey === 'tel' &&
          (tempDetailInfo[targetDetailInfoKey][infoKey] = util.replacePhoneNumber(
            tempDetailInfo[targetDetailInfoKey][infoKey],
          ));
        infoKey === 'commrate' &&
          typeof tempDetailInfo[targetDetailInfoKey][infoKey] !== 'undefined' &&
          (tempDetailInfo[targetDetailInfoKey][infoKey] =
            tempDetailInfo[targetDetailInfoKey][infoKey].toString());
      });
    },
    setDataFiles(fileData) {
      if (typeof fileData['igt029'] !== 'undefined' && fileData['igt029'] !== '') {
        this.orgbiznofile = fileData['igt029'];
        this.$set(this.info, 'orgbiznofile', fileData['igt029'].imgforiname);
        this.$set(this.info, 'pathbiznofile', fileData['igt029'].fullpath);
        this.$set(this.info, 'biznotype', fileData['igt029'].filetype);
      }
      if (typeof fileData['igt030'] !== 'undefined' && fileData['igt030'] !== '') {
        this.orgbcopyfile = fileData['igt030'];
        this.$set(this.info, 'orgbcopyfile', fileData['igt030'].imgforiname);
        this.$set(this.info, 'pathbcopyfile', fileData['igt030'].fullpath);
        this.$set(this.info, 'bcopytype', fileData['igt030'].filetype);
      }
      if (typeof fileData['igt031'] !== 'undefined' && fileData['igt031'] !== '') {
        this.orgcsellfile = fileData['igt031'];
        this.$set(this.info, 'orgcsellfile', fileData['igt031'].imgforiname);
        this.$set(this.info, 'pathcsellfile', fileData['igt031'].fullpath);
        this.$set(this.info, 'cselltype', fileData['igt031'].filetype);
      }
    },
    getDetailInfoSummary() {
      return {
        info: this.info,
        mdManagerId: this.mdManagerId,
        dealerChargeList: this.dealerChargeList,
        orgbiznofile: this.orgbiznofile,
        orgbcopyfile: this.orgbcopyfile,
        orgcsellfile: this.orgcsellfile,
      };
    },
    // 초기 데이터와 같은지 비교하여 컨펌 노출하여 결과 반환
    confirmSameDetailInfo() {
      // 정보 변경하고 나갈 경우 컨펌창으로 확인한다.
      if (!this.isSameDetailInfo) {
        return confirm(constants.COMMON_MESSAGE.LEAVE_PAGE_MESSAGE);
      }
      return true;
    },
    closeDetailModal() {
      // 비교 컨펌 후 결과에 따라 모달을 닫거나 안닫거나 한다.
      const isSameDetailInfoConfirm = this.confirmSameDetailInfo();
      if (!isSameDetailInfoConfirm) {
        return;
      }
      this.info = this.$options.data().info;
      this.$emit('closeDetail');
    },
    fileAttach(fileTypeKey) {
      this.$refs[fileTypeKey].click();
    },
    handleChangeFile(fileTypeKey) {
      const fileInputRef = this.$refs[fileTypeKey];
      const { files } = fileInputRef ?? { files: null };
      const file = files?.[0];
      if (!file) {
        return;
      }

      const enabledFileTypes = ['image/png', 'image/jpeg', 'image/png', 'application/pdf', '.pdf'];
      if (!enabledFileTypes.includes(file.type)) {
        alert(constants.COMMON_MESSAGE.ENABLED_FILE_TYPE);
        fileInputRef.value = '';
        this[fileTypeKey] = null;
        this.info[fileTypeKey] = '';
        return;
      }

      if (file.size > constants.COMMON_VALUE.TEN_MEGA_BYTE) {
        alert(constans.COMMON_MESSAGE.MAX_FILE_SIZE);
        fileInputRef.value = '';
        this[fileTypeKey] = null;
        this.info[fileTypeKey] = '';
        return;
      }

      this[fileTypeKey] = file;
      this.info[fileTypeKey] = file.name;
    },
    handleRemoveFile(fileTypeKey) {
      const confirmMsg = confirm(constants.COMMON_MESSAGE.DELETE_FILE_QUESTION);
      if (confirmMsg) {
        const fileInputRef = this.$refs[fileTypeKey];
        fileInputRef.value = '';
        this[fileTypeKey] = null;
        this.info[fileTypeKey] = '';
      }
    },
    addCharger() {
      const charger = {
        chargeemail: '',
        chargetypename: '',
        userno: '',
        chargemobile: '',
        chargetype: '',
        chargename: '',
        isCreated: true, // 아직 저장 안한 상태로 추가한 걸 뜻함.
        uuid: uuidv4(), // 렌더링 key로 사용할 유니크 아이디
      };

      this.dealerChargeList.push(charger);
    },
    handleToggleTab(tab) {
      this.tabs[tab] = !this.tabs[tab];
    },
    handleInitPassword() {
      this.debounceInitPassword();
    },
    // 제휴 담당자가 있으면 제휴담당자에게 초기화된 비밀번호 메시지를 보내고 제휴담당자가 없으면 담당자 휴대폰으로 보낸다.
    debounceInitPassword: debounce(
      async function () {
        try {
          const confirmMessage = confirm(constants.COMMON_MESSAGE.INIT_PASSWORD_QUESTION);
          if (!confirmMessage) {
            return;
          }

          const mobile = this.info.managerMobile || this.info.repmobile; // 대표 담당자 연락처가 없을 경우 대표 운영자 연락처로 처리 (방어로직 대표담당자 연락처가 없으면 안됨)
          const params = { userno: this.info.no, mobile: mobile.replaceAll('-', '') };

          await this.$http.post('/admin/partners/apply/password/init', params);
          alert(constants.COMMON_MESSAGE.SEND_PASSWORD_TO_MANAGER);
        } catch (err) {
          alert(err.message);
        }
      },
      500,
      { leading: true, trailing: false },
    ),
    imageView(url) {
      this.$viewerApi({
        images: [url],
      });
    },
  },
};
</script>
<style scoped>
.pop-body {
  max-height: calc(90vh - 126px);
}
.link,
button.file-link {
  border: none;
  padding: 0;
  margin-left: 0;
  background-color: transparent;
}
a.file-link {
  margin-left: 0;
}
.txt-orange {
  font-size: 12px;
}
select:disabled {
  background-color: #f3f3f3 !important;
  border: 1px solid #ddd !important;
  opacity: 1;
  cursor: default;
}
span.required {
  color: red;
}
textarea {
  resize: none;
}
input.error,
input:focus.error {
  border-color: #d74848;
}
button.mr-10 {
  margin-right: 10px;
}
.btn-row {
  position: sticky;
  top: 0;
  margin-bottom: 10px;
}
::placeholder {
  padding: 4px;
}

::-webkit-input-placeholder {
  /* Edge */
  padding: 4px;
}
</style>
