<template>
  <div>
    <div class="visual">
      <h1>입 / 점 / 신 / 청</h1>
      <p>성공적인 비즈니스 파트너! D.PLOT과 함께하세요!</p>
    </div>
    <div class="join-content">
      <div class="flow clearfix">
        <ul>
          <li>
            <span>Step 1.</span>
            <p>입점절차 및 사업자 인증</p>
          </li>
          <li>
            <span>Step 2.</span>
            <p>정보입력</p>
          </li>
          <li class="on">
            <span>Step 3.</span>
            <p>신청완료</p>
          </li>
        </ul>
      </div>
      <div class="step4">
        <img alt="" src="@assets/img/partnership-img.png" />
        <h3>신청완료</h3>
        <p>입점신청서가 정상적으로 접수되었습니다.</p>
      </div>
      <div class="btn-group">
        <button class="btn big blue" type="button" @click="goPartnershipLogin">확인</button>
      </div>
    </div>
  </div>
</template>
<script>
import storage from '@js/storage';
import store from '@js/store';
import constants from '@js/constants';
import util from '@js/util';

export default {
  // 신규 페이지 전환. 추후에 new 제거 필요.
  name: 'PartnershipCompleteNew',
  beforeRouteEnter(from, to, next) {
    if (util.isAuthorized(store.getters.CONSTANTS.MANAGER_SESSION)) {
      next('partners.login');
      return;
    }
    const { BIZ_AUTH_INFO, FINISHED_JOIN_PARTNER } = constants.STORAGE_KEYS;
    const hasSessionBizData =
      typeof storage.getSessionStorage(BIZ_AUTH_INFO) !== 'undefined' &&
      storage.getSessionStorage(BIZ_AUTH_INFO) != null;

    const hasSessionFinishedJoin = storage.getSessionStorage(FINISHED_JOIN_PARTNER);

    if (!hasSessionBizData) {
      next('partners.login');
      return;
    }
    if (!hasSessionFinishedJoin) {
      next('partners.login');
    }
    next();
  },
  beforeRouteLeave(from, to, next) {
    const { FINISHED_JOIN_PARTNER } = this.$store.getters.CONSTANTS.STORAGE_KEYS;
    storage.removeSessionStorage(FINISHED_JOIN_PARTNER);
    next();
  },
  beforeMount() {
    this.initSession();
  },
  methods: {
    goPartnershipLogin() {
      this.$router.push({ name: 'partners.login' });
    },
    // 해당 페이지로 진입시 모든 가입절차시 저장된 세션 스토리지를 초기화한다.
    initSession() {
      const { BIZ_AUTH_INFO } = this.$store.getters.CONSTANTS.STORAGE_KEYS;
      storage.removeSessionStorage(BIZ_AUTH_INFO);
    },
  },
};
</script>

<style scoped src="@assets/css/partnership.css"></style>
