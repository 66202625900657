<template>
  <!-- 운영자 계정 비밀번호 찾기 팝업 -->
  <div id="modal-wrap" class="modal" style="display: block">
    <div class="modal-content" style="width: 800px">
      <div class="pop-header">
        <h2>비밀번호 찾기</h2>
        <button type="button" class="pop-close" @click="onClose"></button>
      </div>
      <div class="pop-body">
        <table cellpadding="0" cellspacing="0" class="gray-tb">
          <colgroup>
            <col width="150px" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th>아이디<i class="essential"></i></th>
              <td>
                <input type="text" v-model="userid" />
              </td>
            </tr>
            <tr>
              <th>전화번호<i class="essential"></i></th>
              <td>
                <input type="text" v-model="mobile" />
                <span class="txt-orange ml3"><i class="icon-alert"></i>- 없이 입력 바랍니다.</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="btn-group">
          <button type="button" class="btn big blue" @click="onFind">비밀번호 찾기</button>
          <button type="button" class="btn big darkgray" @click="onClose">취소</button>
        </div>
      </div>
    </div>
  </div>
  <!-- /운영자 등록 팝업 -->
</template>

<script>
export default {
  name: 'AdminFindPasswordPopup',
  data() {
    return {
      userid: '',
      mobile: '',
    };
  },
  methods: {
    onFind() {
      if (this.checkValidation()) {
        let param = { userid: this.userid, mobile: this.mobile };

        this.$http
          .post('/admin/common/find-password', param)
          .then((result) => {
            if (result.statusCode === 200) {
              alert('모바일로 임시 비밀번호가 전송되었습니다.');
            }
          })
          .catch((error) => {
            this.$util.debug(error);
          });
      }
    },
    checkValidation() {
      var patternMobile = /01[016789][^0][0-9]{2,3}[0-9]{4}/;

      if (!patternMobile.test(this.mobile)) {
        alert('잘못된 모바일 번호입니다.');
        return false;
      } else {
        return true;
      }
    },
    onClose() {
      this.$modal.hide('commonModal');
    },
  },
};
</script>

<style></style>
