var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content m-leftmenu" },
    [
      _c("CommonNavigator"),
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "boxing search-area pd0" }, [
          _c("dl", [
            _c("dt", [_vm._v("조회기간")]),
            _c(
              "dd",
              [
                _c("div", { staticClass: "radio_wrap wide3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.type,
                        expression: "searchData.type",
                      },
                    ],
                    attrs: { type: "radio", id: "group01", value: "day" },
                    domProps: { checked: _vm._q(_vm.searchData.type, "day") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "type", "day")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "group01" } }, [_vm._v("일별")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.type,
                        expression: "searchData.type",
                      },
                    ],
                    attrs: { type: "radio", id: "group02", value: "week" },
                    domProps: { checked: _vm._q(_vm.searchData.type, "week") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "type", "week")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "group02" } }, [_vm._v("주별")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.type,
                        expression: "searchData.type",
                      },
                    ],
                    attrs: { type: "radio", id: "group03", value: "month" },
                    domProps: { checked: _vm._q(_vm.searchData.type, "month") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "type", "month")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "group03" } }, [_vm._v("월별")]),
                ]),
                _c("CommonDatePicker", {
                  attrs: { value: _vm.searchData.startdate },
                  on: { change: _vm.onChangeStartDate },
                }),
                _c("span", [_vm._v("-")]),
                _c("CommonDatePicker", {
                  attrs: { value: _vm.searchData.enddate },
                  on: { change: _vm.onChangeEndDate },
                }),
                _c("div", { staticClass: "radio_wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd1", value: "-1" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "-1") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "-1")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd1" } }, [_vm._v("어제")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd2", value: "0" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "0") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "0")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd2" } }, [_vm._v("오늘")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd3", value: "7" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "7") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "7")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd3" } }, [_vm._v("일주일")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd4", value: "1" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "1") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "1")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd4" } }, [_vm._v("1개월")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd5", value: "3" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "3") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "3")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd5" } }, [_vm._v("3개월")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd6", value: "6" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "6") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "6")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd6" } }, [_vm._v("6개월")]),
                ]),
              ],
              1
            ),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("회원구분")]),
            _c("dd", [
              _c("div", { staticClass: "radio_wrap wide3" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.isnonmember,
                      expression: "searchData.isnonmember",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "isnonmember",
                    id: "isnonmemberAll",
                    value: "",
                  },
                  domProps: { checked: _vm._q(_vm.searchData.isnonmember, "") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.searchData, "isnonmember", "")
                    },
                  },
                }),
                _c("label", { attrs: { for: "isnonmemberAll" } }, [
                  _vm._v("전체"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.isnonmember,
                      expression: "searchData.isnonmember",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "isnonmember",
                    id: "isnonmemberF",
                    value: "F",
                  },
                  domProps: {
                    checked: _vm._q(_vm.searchData.isnonmember, "F"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.searchData, "isnonmember", "F")
                    },
                  },
                }),
                _c("label", { attrs: { for: "isnonmemberF" } }, [
                  _vm._v("회원"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.isnonmember,
                      expression: "searchData.isnonmember",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "isnonmember",
                    id: "isnonmemberT",
                    value: "T",
                  },
                  domProps: {
                    checked: _vm._q(_vm.searchData.isnonmember, "T"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.searchData, "isnonmember", "T")
                    },
                  },
                }),
                _c("label", { attrs: { for: "isnonmemberT" } }, [
                  _vm._v("비회원"),
                ]),
              ]),
            ]),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("회원유형")]),
            _c(
              "dd",
              [
                _c("div", { staticClass: "check-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.isallmember,
                        expression: "searchData.isallmember",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "chkAllMember",
                      "true-value": "T",
                      "false-value": "F",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.searchData.isallmember)
                        ? _vm._i(_vm.searchData.isallmember, null) > -1
                        : _vm._q(_vm.searchData.isallmember, "T"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.searchData.isallmember,
                            $$el = $event.target,
                            $$c = $$el.checked ? "T" : "F"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallmember",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallmember",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.searchData, "isallmember", $$c)
                          }
                        },
                        _vm.checkAllMembertype,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "chkAllMember" } }, [
                    _vm._v("전체"),
                  ]),
                ]),
                _vm._l(_vm.commonCode.dadamembertype, function (item) {
                  return _c(
                    "div",
                    { key: item.cmcode, staticClass: "check-wrap" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.mumembertypeArr,
                            expression: "searchData.mumembertypeArr",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "dadamembertype_" + item.cmcode,
                          "true-value": [],
                        },
                        domProps: {
                          value: item.cmcode,
                          checked: Array.isArray(_vm.searchData.mumembertypeArr)
                            ? _vm._i(
                                _vm.searchData.mumembertypeArr,
                                item.cmcode
                              ) > -1
                            : _vm._q(_vm.searchData.mumembertypeArr, []),
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.searchData.mumembertypeArr,
                              $$el = $event.target,
                              $$c = $$el.checked ? [] : false
                            if (Array.isArray($$a)) {
                              var $$v = item.cmcode,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "mumembertypeArr",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "mumembertypeArr",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.searchData, "mumembertypeArr", $$c)
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        { attrs: { for: "dadamembertype_" + item.cmcode } },
                        [_vm._v(_vm._s(item.codename))]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("회원등급")]),
            _c(
              "dd",
              [
                _c("div", { staticClass: "check-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.isallmemlv,
                        expression: "searchData.isallmemlv",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "chkAllMemlv",
                      "true-value": "T",
                      "false-value": "F",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.searchData.isallmemlv)
                        ? _vm._i(_vm.searchData.isallmemlv, null) > -1
                        : _vm._q(_vm.searchData.isallmemlv, "T"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.searchData.isallmemlv,
                            $$el = $event.target,
                            $$c = $$el.checked ? "T" : "F"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallmemlv",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallmemlv",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.searchData, "isallmemlv", $$c)
                          }
                        },
                        _vm.checkAllMemlvtype,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "chkAllMemlv" } }, [
                    _vm._v("전체"),
                  ]),
                ]),
                _vm._l(_vm.commonCode.memlvtype, function (item) {
                  return _c(
                    "div",
                    { key: item.cmcode, staticClass: "check-wrap" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.mumemlvtypeArr,
                            expression: "searchData.mumemlvtypeArr",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "mumemlvtype_" + item.cmcode,
                          "true-value": [],
                        },
                        domProps: {
                          value: item.cmcode,
                          checked: Array.isArray(_vm.searchData.mumemlvtypeArr)
                            ? _vm._i(
                                _vm.searchData.mumemlvtypeArr,
                                item.cmcode
                              ) > -1
                            : _vm._q(_vm.searchData.mumemlvtypeArr, []),
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.searchData.mumemlvtypeArr,
                              $$el = $event.target,
                              $$c = $$el.checked ? [] : false
                            if (Array.isArray($$a)) {
                              var $$v = item.cmcode,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "mumemlvtypeArr",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "mumemlvtypeArr",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.searchData, "mumemlvtypeArr", $$c)
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        { attrs: { for: "mumemlvtype_" + item.cmcode } },
                        [_vm._v(_vm._s(item.codename))]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("주문경로")]),
            _c(
              "dd",
              [
                _c("div", { staticClass: "check-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.isallchannel,
                        expression: "searchData.isallchannel",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "chkAllAppch",
                      "true-value": "T",
                      "false-value": "F",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.searchData.isallchannel)
                        ? _vm._i(_vm.searchData.isallchannel, null) > -1
                        : _vm._q(_vm.searchData.isallchannel, "T"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.searchData.isallchannel,
                            $$el = $event.target,
                            $$c = $$el.checked ? "T" : "F"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallchannel",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallchannel",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.searchData, "isallchannel", $$c)
                          }
                        },
                        _vm.checkAllAppchtype,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "chkAllAppch" } }, [
                    _vm._v("전체"),
                  ]),
                ]),
                _vm._l(_vm.commonCode.muappchtype, function (item) {
                  return _c(
                    "div",
                    { key: item.cmcode, staticClass: "check-wrap" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.muappchtypeArr,
                            expression: "searchData.muappchtypeArr",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "muappchtype_" + item.cmcode,
                          "true-value": [],
                        },
                        domProps: {
                          value: item.cmcode,
                          checked: Array.isArray(_vm.searchData.muappchtypeArr)
                            ? _vm._i(
                                _vm.searchData.muappchtypeArr,
                                item.cmcode
                              ) > -1
                            : _vm._q(_vm.searchData.muappchtypeArr, []),
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.searchData.muappchtypeArr,
                              $$el = $event.target,
                              $$c = $$el.checked ? [] : false
                            if (Array.isArray($$a)) {
                              var $$v = item.cmcode,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "muappchtypeArr",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "muappchtypeArr",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.searchData, "muappchtypeArr", $$c)
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        { attrs: { for: "muappchtype_" + item.cmcode } },
                        [_vm._v(_vm._s(item.codename))]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("결제수단")]),
            _c(
              "dd",
              [
                _c("div", { staticClass: "check-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.isallpayway,
                        expression: "searchData.isallpayway",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "chkAllPayway",
                      "true-value": "T",
                      "false-value": "F",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.searchData.isallpayway)
                        ? _vm._i(_vm.searchData.isallpayway, null) > -1
                        : _vm._q(_vm.searchData.isallpayway, "T"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.searchData.isallpayway,
                            $$el = $event.target,
                            $$c = $$el.checked ? "T" : "F"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallpayway",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallpayway",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.searchData, "isallpayway", $$c)
                          }
                        },
                        _vm.checkAllPaywaytype,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "chkAllPayway" } }, [
                    _vm._v("전체"),
                  ]),
                ]),
                _vm._l(_vm.commonCode.paywaytype, function (item) {
                  return _c(
                    "div",
                    { key: item.cmcode, staticClass: "check-wrap" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.paywaytypeArr,
                            expression: "searchData.paywaytypeArr",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "paywaytype_" + item.cmcode,
                          "true-value": [],
                        },
                        domProps: {
                          value: item.cmcode,
                          checked: Array.isArray(_vm.searchData.paywaytypeArr)
                            ? _vm._i(
                                _vm.searchData.paywaytypeArr,
                                item.cmcode
                              ) > -1
                            : _vm._q(_vm.searchData.paywaytypeArr, []),
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.searchData.paywaytypeArr,
                              $$el = $event.target,
                              $$c = $$el.checked ? [] : false
                            if (Array.isArray($$a)) {
                              var $$v = item.cmcode,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "paywaytypeArr",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "paywaytypeArr",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.searchData, "paywaytypeArr", $$c)
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        { attrs: { for: "paywaytype_" + item.cmcode } },
                        [_vm._v(_vm._s(item.codename))]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
            _c("dd", { staticStyle: { width: "130px" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.isTotCheck,
                    expression: "searchData.isTotCheck",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  id: "chk_searchAll",
                  "true-value": "T",
                  "false-value": "F",
                },
                domProps: {
                  checked: Array.isArray(_vm.searchData.isTotCheck)
                    ? _vm._i(_vm.searchData.isTotCheck, null) > -1
                    : _vm._q(_vm.searchData.isTotCheck, "T"),
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.searchData.isTotCheck,
                        $$el = $event.target,
                        $$c = $$el.checked ? "T" : "F"
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.searchData,
                              "isTotCheck",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.searchData,
                              "isTotCheck",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.searchData, "isTotCheck", $$c)
                      }
                    },
                    _vm.checkTotAll,
                  ],
                },
              }),
              _c(
                "label",
                { staticClass: "txt-blue", attrs: { for: "chk_searchAll" } },
                [_vm._v("조건 전체체크")]
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isRead,
                expression: "isRead",
              },
            ],
            staticClass: "btn-group",
          },
          [
            _c(
              "button",
              {
                staticClass: "btn big blue",
                attrs: { type: "button" },
                on: { click: _vm.searchList },
              },
              [_vm._v("검색")]
            ),
            _c(
              "button",
              {
                staticClass: "btn big gray",
                attrs: { type: "button" },
                on: { click: _vm.initSearchData },
              },
              [_vm._v("초기화")]
            ),
          ]
        ),
        _c("div", { staticClass: "caption-group mt10 clearfix" }, [
          _c("div", { staticClass: "total-group fl" }, [
            _c("span", { staticClass: "total" }, [
              _vm._v("전체 "),
              _c("strong", [_vm._v(_vm._s(_vm.totalCnt))]),
              _vm._v("건"),
            ]),
          ]),
          _c("div", { staticClass: "btn-group fr" }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isRead,
                    expression: "isRead",
                  },
                ],
                staticClass: "btn green-line",
                attrs: { type: "button" },
                on: { click: _vm.goExcelDownload },
              },
              [_c("i", { staticClass: "icon-excel" }), _vm._v("엑셀다운로드 ")]
            ),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pagingData.pageCount,
                    expression: "pagingData.pageCount",
                  },
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isRead,
                    expression: "isRead",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.pagingData,
                      "pageCount",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { attrs: { value: "20" } }, [
                  _vm._v("20개씩 보기"),
                ]),
                _c("option", { attrs: { value: "50" } }, [
                  _vm._v("50개씩 보기"),
                ]),
                _c("option", { attrs: { value: "100" } }, [
                  _vm._v("100개씩 보기"),
                ]),
              ]
            ),
          ]),
        ]),
        _c(
          "table",
          {
            staticClass: "data-tb align-r",
            attrs: { cellpadding: "0", cellspacing: "0" },
          },
          [
            _c("caption", [_vm._v(" 목록 ")]),
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { rowspan: "2" } }, [_vm._v("No")]),
                _c("th", { attrs: { rowspan: "2" } }, [
                  _vm._v(" 일자 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.target === "target_asc" },
                      { down: _vm.sortData.target === "target_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.target },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.target)
                      },
                    },
                  }),
                ]),
                _c("th", { attrs: { rowspan: "2" } }, [
                  _vm._v(" 총 주문건수 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.ordercnt === "ordercnt_asc" },
                      { down: _vm.sortData.ordercnt === "ordercnt_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.ordercnt },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.ordercnt)
                      },
                    },
                  }),
                ]),
                _c("th", { attrs: { rowspan: "2" } }, [
                  _vm._v(" 순 주문건수 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.pordercnt === "pordercnt_asc" },
                      { down: _vm.sortData.pordercnt === "pordercnt_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.pordercnt },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.pordercnt)
                      },
                    },
                  }),
                ]),
                _c("th", { attrs: { rowspan: "2" } }, [
                  _c("i", {
                    staticClass: "icon-th-q",
                    on: {
                      click: function ($event) {
                        _vm.toggleData.price = !_vm.toggleData.price
                      },
                    },
                  }),
                  _vm._v("순 주문금액 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.price === "price_asc" },
                      { down: _vm.sortData.price === "price_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.price },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.price)
                      },
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "th-cell",
                      class: [
                        { dpb: _vm.toggleData.price },
                        { dpn: !_vm.toggleData.price },
                      ],
                      staticStyle: { width: "240px" },
                    },
                    [_vm._v(" 판매가(프로모션할인가) – 쿠폰할인 ")]
                  ),
                ]),
                _c("th", { attrs: { rowspan: "2" } }, [
                  _c("i", {
                    staticClass: "icon-th-q",
                    on: {
                      click: function ($event) {
                        _vm.toggleData.unitamt = !_vm.toggleData.unitamt
                      },
                    },
                  }),
                  _vm._v("객단가 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.unitamt === "unitamt_asc" },
                      { down: _vm.sortData.unitamt === "unitamt_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.unitamt },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.unitamt)
                      },
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "th-cell",
                      class: [
                        { dpb: _vm.toggleData.unitamt },
                        { dpn: !_vm.toggleData.unitamt },
                      ],
                      staticStyle: { width: "240px" },
                    },
                    [_vm._v(" 순 주문금액/순 주문건수 ")]
                  ),
                ]),
                _c("th", { attrs: { rowspan: "2" } }, [
                  _vm._v(" 취소건수 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.cnccnt === "cnccnt_asc" },
                      { down: _vm.sortData.cnccnt === "cnccnt_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.cnccnt },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.cnccnt)
                      },
                    },
                  }),
                ]),
                _c("th", { attrs: { rowspan: "2" } }, [
                  _vm._v(" 교환건수 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.exccnt === "exccnt_asc" },
                      { down: _vm.sortData.exccnt === "exccnt_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.exccnt },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.exccnt)
                      },
                    },
                  }),
                ]),
                _c("th", { attrs: { rowspan: "2" } }, [
                  _vm._v(" 환불건수 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.ramtcnt === "ramtcnt_asc" },
                      { down: _vm.sortData.ramtcnt === "ramtcnt_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.ramtcnt },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.ramtcnt)
                      },
                    },
                  }),
                ]),
                _c("th", { attrs: { rowspan: "2" } }, [
                  _vm._v(" 반품건수 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.rtncnt === "rtncnt_asc" },
                      { down: _vm.sortData.rtncnt === "rtncnt_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.rtncnt },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.rtncnt)
                      },
                    },
                  }),
                ]),
                _c("th", { attrs: { colspan: "4" } }, [_vm._v("회원")]),
              ]),
              _c("tr", [
                _c("th", { staticClass: "bg-yellow" }, [
                  _vm._v(" 신규회원수 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.regcnt === "regcnt_asc" },
                      { down: _vm.sortData.regcnt === "regcnt_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.regcnt },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.regcnt)
                      },
                    },
                  }),
                ]),
                _c("th", { staticClass: "bg-yellow" }, [
                  _vm._v(" SMS수신동의수 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.smscnt === "smscnt_asc" },
                      { down: _vm.sortData.smscnt === "smscnt_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.smscnt },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.smscnt)
                      },
                    },
                  }),
                ]),
                _c("th", { staticClass: "bg-yellow" }, [
                  _vm._v(" 탈퇴회원수 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.rescnt === "rescnt_asc" },
                      { down: _vm.sortData.rescnt === "rescnt_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.rescnt },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.rescnt)
                      },
                    },
                  }),
                ]),
                _c("th", { staticClass: "bg-yellow" }, [
                  _vm._v(" 휴면회원전환수 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.dorcnt === "dorcnt_asc" },
                      { down: _vm.sortData.dorcnt === "dorcnt_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.dorcnt },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.dorcnt)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm.listData.length > 0
              ? _c(
                  "tbody",
                  [
                    _c("tr", { staticClass: "bg blue" }, [
                      _c("td", { attrs: { colspan: "2" } }, [_vm._v("합계")]),
                      _c("td", [_vm._v(_vm._s(_vm.ordercnt))]),
                      _c("td", [_vm._v(_vm._s(_vm.pordercnt))]),
                      _c("td", [_vm._v(_vm._s(_vm.price))]),
                      _c("td", [_vm._v(_vm._s(_vm.unitamt))]),
                      _c("td", [_vm._v(_vm._s(_vm.cnccnt))]),
                      _c("td", [_vm._v(_vm._s(_vm.exccnt))]),
                      _c("td", [_vm._v(_vm._s(_vm.ramtcnt))]),
                      _c("td", [_vm._v(_vm._s(_vm.rtncnt))]),
                      _c("td", [_vm._v(_vm._s(_vm.regcnt))]),
                      _c("td", [_vm._v(_vm._s(_vm.smscnt))]),
                      _c("td", [_vm._v(_vm._s(_vm.rescnt))]),
                      _c("td", [_vm._v(_vm._s(_vm.dorcnt))]),
                    ]),
                    _vm._l(_vm.listData, function (row, index) {
                      return _c("tr", { key: index }, [
                        _c("td", { staticClass: "center" }, [
                          _vm._v(_vm._s(_vm.loopNumberForPaging(index))),
                        ]),
                        _c("td", { staticClass: "center" }, [
                          _vm._v(_vm._s(row.target)),
                        ]),
                        _c("td", [_vm._v(_vm._s(row.ordercnt))]),
                        _c("td", [_vm._v(_vm._s(row.pordercnt))]),
                        _c("td", [_vm._v(_vm._s(row.price))]),
                        _c("td", [_vm._v(_vm._s(row.unitamt))]),
                        _c("td", [_vm._v(_vm._s(row.cnccnt))]),
                        _c("td", [_vm._v(_vm._s(row.exccnt))]),
                        _c("td", [_vm._v(_vm._s(row.ramtcnt))]),
                        _c("td", [_vm._v(_vm._s(row.rtncnt))]),
                        _c("td", [_vm._v(_vm._s(row.regcnt))]),
                        _c("td", [_vm._v(_vm._s(row.smscnt))]),
                        _c("td", [_vm._v(_vm._s(row.rescnt))]),
                        _c("td", [_vm._v(_vm._s(row.dorcnt))]),
                      ])
                    }),
                  ],
                  2
                )
              : _c("tbody", [_vm._m(1)]),
          ]
        ),
        _c(
          "div",
          { staticClass: "bottom-group" },
          [
            _c("CommonPageNavigator", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isRead,
                  expression: "isRead",
                },
              ],
              attrs: { pagingData: _vm.pagingData },
              on: { setPagingData: _vm.setPagingData },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "center", attrs: { colspan: "14" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }