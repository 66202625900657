var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content m-leftmenu" },
    [
      _c("CommonNavigator"),
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "caption-group mt10 clearfix" }, [
          _c("div", { staticClass: "btn-group fr" }, [
            _vm.isWrite
              ? _c(
                  "button",
                  {
                    staticClass: "btn blue",
                    attrs: { type: "button" },
                    on: { click: _vm.onClickAddPromotion },
                  },
                  [_vm._v(" 등록 ")]
                )
              : _vm._e(),
          ]),
        ]),
        _c(
          "table",
          {
            staticClass: "data-tb align-c promotion-list",
            attrs: { cellpadding: "0", cellspacing: "0" },
          },
          [
            _c("caption", [_vm._v("임직원 프로모션 관리 목록")]),
            _vm._m(0),
            _vm._m(1),
            _vm.employeePromotionList.length > 0
              ? _c(
                  "tbody",
                  _vm._l(
                    _vm.employeePromotionList,
                    function (promotion, index) {
                      return _c(
                        "tr",
                        {
                          key: promotion.id,
                          staticClass: "clickable",
                          on: {
                            click: function ($event) {
                              return _vm.onClickPromotionDetail(promotion.id)
                            },
                          },
                        },
                        [
                          _c("td", [_vm._v(_vm._s(index + 1))]),
                          _c("td", [_vm._v(_vm._s(promotion.id))]),
                          _c("td", [
                            _vm._v(" " + _vm._s(promotion.title) + " "),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                )
              : _c("tbody", [
                  _c("tr", [
                    _c("td", { attrs: { colspan: 3 } }, [
                      _vm._v("조회 결과가 존재하지 않습니다."),
                    ]),
                  ]),
                ]),
          ]
        ),
      ]),
      _vm.openedCreatePopup
        ? _c("EmployeePromotionCreatePopup", {
            on: { onRefresh: _vm.onRefresh, onClose: _vm.onCloseCreatePopup },
          })
        : _vm._e(),
      _vm.openedDetailPopup
        ? _c("EmployeePromotionDetailPopup", {
            attrs: {
              isWrite: _vm.isWrite,
              promotion: _vm.selectEmployeePromotion,
            },
            on: { onClose: _vm.onCloseDetailPopup },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("No")]),
        _c("th", [_vm._v("ID")]),
        _c("th", [_vm._v("관리 제목")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }