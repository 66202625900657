<template>
  <div></div>
</template>

<script>
export default {
  name: 'admin.member.manage.level',
};
</script>

<style scoped></style>
