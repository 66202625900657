<template>
  <!-- 적립금선택 팝업 -->
  <div id="modal-wrap" class="modal" style="display: block">
    <div class="modal-content" style="width: 1400px">
      <div class="pop-header">
        <h2>적립금검색</h2>
        <button type="button" class="pop-close" @click="$modal.hide('commonModal')"></button>
      </div>
      <div class="pop-body">
        <div class="boxing search-area">
          <dl>
            <dt>직접검색</dt>
            <dd>
              <select v-model="searchData.skey">
                <option value="">전체</option>
                <option value="resreason">지급사유</option>
                <option value="reguserid">등록자</option>
              </select>
              <input
                type="text"
                v-model="searchData.sword"
                maxlength="200"
                @keyup.enter="searchList(1)"
              />
            </dd>
          </dl>
          <dl>
            <dt>조회기간</dt>
            <dd>
              <common-date-picker
                :value="searchData.startdate"
                @change="onChangeStartDate"
              ></common-date-picker>
              <span>-</span>
              <common-date-picker
                :value="searchData.enddate"
                @change="onChangeEndDate"
              ></common-date-picker>
              <div class="radio_wrap">
                <input
                  type="radio"
                  v-model="searchData.period"
                  id="period_aday_1"
                  value="aday_1"
                /><label for="period_aday_1">어제</label>
                <input
                  type="radio"
                  v-model="searchData.period"
                  id="period_aday_0"
                  value="aday_0"
                /><label for="period_aday_0">오늘</label>
                <input
                  type="radio"
                  v-model="searchData.period"
                  id="period_days_7"
                  value="days_7"
                /><label for="period_days_7">일주일</label>
                <input
                  type="radio"
                  v-model="searchData.period"
                  id="period_months_1"
                  value="months_1"
                /><label for="period_months_1">1개월</label>
                <input
                  type="radio"
                  v-model="searchData.period"
                  id="period_months_3"
                  value="months_3"
                /><label for="period_months_3">3개월</label>
                <input
                  type="radio"
                  v-model="searchData.period"
                  id="period_months_6"
                  value="months_6"
                /><label for="period_months_6">6개월</label>
              </div>
            </dd>
          </dl>
        </div>

        <div class="btn-group">
          <button type="button" class="btn big blue" @click="searchList">검색</button>
          <button type="button" class="btn big gray" @click="initSearchData">초기화</button>
        </div>
        <div class="caption-group mt10 clearfix">
          <div class="btn-group fr">
            <select v-model="pagingData.pageCount" v-if="isRead">
              <option value="20">20개씩 보기</option>
              <option value="50">50개씩 보기</option>
              <option value="100">100개씩 보기</option>
            </select>
          </div>
        </div>
        <table cellpadding="0" cellspacing="0" class="data-tb align-c">
          <caption>
            적립금
          </caption>
          <colgroup>
            <col width="3%" />
            <!-- No -->
            <col width="" />
            <!-- 적립금 지급사유 -->
            <col width="10%" />
            <!-- 적립금 금액 -->
            <col width="10%" />
            <!-- 즉시여부 -->
            <col width="10%" />
            <!-- 등록자 -->
            <col width="15%" />
            <!-- 등록일자 -->
          </colgroup>
          <thead>
            <tr>
              <th>No</th>
              <th>지급사유</th>
              <th>적립금금액</th>
              <th>즉시여부</th>
              <th>등록자</th>
              <th>등록일자</th>
            </tr>
          </thead>
          <tbody v-if="this.list.length > 0">
            <tr v-for="(item, index) in this.list" :key="index">
              <td>{{ index + 1 }}</td>
              <td class="left">
                <a class="link" @click="sendSelectedList(item)">{{ item.resreason }}</a>
              </td>
              <td>{{ item.paypoint }}</td>
              <td>{{ item.isnowpay }}</td>
              <td>{{ item.reguserid }}</td>
              <td>{{ item.regdate }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="16">조회 결과가 존재하지 않습니다.</td>
            </tr>
          </tbody>
        </table>
        <div class="bottom-group">
          <div class="paging">
            <CommonPageNavigator
              v-show="isRead"
              :pagingData="pagingData"
              v-on:setPagingData="setPagingData"
            />
          </div>
        </div>
        <br />
        <div class="btn-group">
          <button type="button" class="btn big darkgray" @click="$modal.hide('commonModal')">
            닫기
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- /상품추가 팝업 -->
</template>

<script>
import CommonPageNavigator from '@views.admin/common/CommonPageNavigator';
import CommonDatePicker from '@views.admin/common/CommonDatePicker.vue';

export default {
  name: 'CommonAddPointPopup',
  components: {
    CommonDatePicker,
    CommonPageNavigator,
  },
  props: {
    modalComp: Object,
    params: Object,
    callbackFn: Function,
  },
  mounted() {
    // 초기화
    this.onInit();
  },
  data() {
    return {
      searchData: {
        skey: '', // 직접검색 조건(comcpnno: 쿠폰번호, cpnname: 쿠폰명)
        sword: '', // 직접검색 단어
        startdate: '', // 조회시작날짜
        enddate: '', // 조회종료날짜
        period: '', // 조회기간
      },
      pagingData: {
        pageCount: 20, // 페이징 옵션(최대수)
        page: 1, // 현재 페이지
        listCount: 0, // 총 수량
      },
      totalcnt: 0, // 전체 수량
      list: [], // 조회 데이터
      isRead: true, // 읽기권한여부
      isWrite: false, // 쓰기권한여부
      isFirstSearch: true, // 최초 조회 여부 (최초 조회여부가 true인 경우 setPagingData에서 조회하지 않는다.)
    };
  },
  methods: {
    onInit: function () {
      this.initSearchData();
      //this.$util.componentSetSearchParam(this);
      this.searchList();
    },
    // 검색조건 초기화
    initSearchData: function (isreload) {
      this.searchData.skey = '';
      this.searchData.sword = '';
      this.searchData.period = 'months_3';
      this.searchData.startdate = this.$util.getAddMonth(this.$util.getDate(), -3, '-');
      this.searchData.enddate = this.$util.getDate('-');
    },
    // 적립금 내역 조회
    searchList: function (page) {
      let params = Object.assign(this.searchData, this.pagingData);
      params.page = typeof page === 'undefined' ? this.pagingData.page : 1;
      this.$http
        .post('/admin/promotion/reserve/master/list', params)
        .then((result) => {
          this.$util.debug(result);
          let data = result.data;
          this.list = data.list;

          this.totalcnt = data.totalcnt;
          this.pagingData.listCount = data.totalcnt;
          this.$util.dataSetSearchParam(this);
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    // 시작날짜 picker 콜백 함수
    onChangeStartDate(value) {
      this.searchData.startDate = value;
    },
    // 종료날짜 picker 콜백 함수
    onChangeEndDate(value) {
      this.searchData.endDate = value;
    },
    // 페이징 콜백
    setPagingData(param) {
      this.pagingData = param;
      if (this.isFirstSearch == false) {
        this.searchList();
      }
      if (this.isFirstSearch == true) {
        this.isFirstSearch = false;
      }
    },
    // 쿠폰상세 팝업 열기
    goDetail: function (comcpnidx, cpninfoidx) {
      this.activeComcpnidx = comcpnidx;
      this.activeCpninfoidx = cpninfoidx;
      this.isShowDetail = true;
    },
    // 적립금상세 팝업 닫기
    closeDetail: function (isreload) {
      this.isShowDetail = false;
      if (isreload) {
        this.searchList();
      }
    },
    // 선택적용
    sendSelectedList: function (item) {
      if (confirm('선택한 적립금을 적용하시겠습니까?')) {
        // 팝업닫기 - 파라미터 전달
        this.closePopup(item);
      }
    },
    // 라디오버튼 선택
    setCheckedList: function (value) {
      this.checkedList = [value];
    },
    // 팝업닫기
    closePopup: function (targetList) {
      if (typeof this.callbackFn == 'function') {
        this.callbackFn({ list: targetList });
      }
      this.$modal.hide('commonModal');
    },
  },
  watch: {
    // 조회기간
    'searchData.period': function (value) {
      let params = value.split('_');
      let type = params[0];
      let addValue = parseInt(params[1]) * -1;

      if (type == 'aday') {
        this.searchData.startdate = this.$util.getAddDate(this.$util.getDate(), addValue, '-');
        this.searchData.enddate = this.$util.getAddDate(this.$util.getDate(), addValue, '-');
      } else if (type == 'days') {
        this.searchData.startdate = this.$util.getAddDate(this.$util.getDate(), addValue, '-');
        this.searchData.enddate = this.$util.getDate('-');
      } else if (type == 'months') {
        this.searchData.startdate = this.$util.getAddMonth(this.$util.getDate(), addValue, '-');
        this.searchData.enddate = this.$util.getDate('-');
      }
    },
  },
};
</script>
