var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1100px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("A/S 문의 상세")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "as-area" }, [
              _vm._m(0),
              _c("div", { staticClass: "bd-wrap" }, [
                _c("table", { attrs: { cellpadding: "0", cellspacing: "0" } }, [
                  _c("caption", [_vm._v(" 파트너사 문의 - 질문 ")]),
                  _vm._m(1),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("주문번호")]),
                      _c("td", [_vm._v(_vm._s(_vm.info.ordno))]),
                      _c("th", [_vm._v("송장번호")]),
                      _c("td", [_vm._v(_vm._s(_vm.info.invoiceno))]),
                      _c("th", [_vm._v("전송여부")]),
                      _c("td", [_vm._v(_vm._s(_vm.info.ispassname))]),
                      _c("th", [_vm._v("접수일시")]),
                      _c("td", [_vm._v(_vm._s(_vm.info.regdate))]),
                    ]),
                    _vm.isAdmin
                      ? _c("tr", [
                          _c("th", [_vm._v("고객명")]),
                          _c("td", [_vm._v(_vm._s(_vm.info.username))]),
                          _c("th", [_vm._v("아이디")]),
                          _c("td", [_vm._v(_vm._s(_vm.info.userid))]),
                          _c("th", [_vm._v("회원유형")]),
                          _c("td", [_vm._v(_vm._s(_vm.info.membertypename))]),
                          _c("th", [_vm._v("회원등급")]),
                          _c("td", [_vm._v(_vm._s(_vm.info.memlvtypename))]),
                        ])
                      : _vm._e(),
                    _vm.isAdmin
                      ? _c("tr", [
                          _c("th", [_vm._v("접수자")]),
                          _c("td", [_vm._v(" " + _vm._s(_vm.info.writer))]),
                          _c("th", [_vm._v("연락처")]),
                          _c("td", [_vm._v(" " + _vm._s(_vm.info.tel))]),
                          _c("th", [_vm._v("주소")]),
                          _c("td", { attrs: { colspan: "3" } }, [
                            _vm._v(
                              " [" +
                                _vm._s(_vm.info.post) +
                                "] " +
                                _vm._s(_vm.info.addrroad) +
                                " " +
                                _vm._s(_vm.info.addrdetail) +
                                " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("tr", [
                      _c("th", [_vm._v("상품명")]),
                      _c("td", { attrs: { colspan: "7" } }, [
                        _vm._v(_vm._s(_vm.info.goodsname)),
                        _c("br"),
                        _vm._v("옵션 : " + _vm._s(_vm.info.optionconts)),
                      ]),
                    ]),
                    _c("tr", { staticClass: "contents" }, [
                      _c("th", [_vm._v("A/S접수내용")]),
                      _c("td", { attrs: { colspan: "7" } }, [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.info.content) },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("첨부이미지")]),
                      _c(
                        "td",
                        { attrs: { colspan: "3" } },
                        _vm._l(_vm.imgFileList, function (item, index) {
                          return _c("div", { key: index }, [
                            _c(
                              "a",
                              {
                                staticClass: "file-link",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.imageView(item.fullpath)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.imgforiname))]
                            ),
                          ])
                        }),
                        0
                      ),
                      _c("th", [_vm._v("첨부동영상")]),
                      _c(
                        "td",
                        { attrs: { colspan: "3" } },
                        _vm._l(_vm.videoFileList, function (item, index) {
                          return _c("div", { key: index }, [
                            _c(
                              "a",
                              {
                                staticClass: "file-link",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showVideo(index)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.imgforiname))]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "as-area" }, [
              _vm._m(2),
              _c("div", { staticClass: "bd-wrap" }, [
                _c("table", { attrs: { cellpadding: "0", cellspacing: "0" } }, [
                  _c("caption", [_vm._v(" 파트너사 문의 - 답변 ")]),
                  _vm._m(3),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("판매구분")]),
                      _c("td", [_vm._v(_vm._s(_vm.info.ispbgoodsname))]),
                      _c("th", [_vm._v("파트너사")]),
                      _c("td", [_vm._v(_vm._s(_vm.info.dealername))]),
                      _c("th", [_vm._v("상태")]),
                      _c("td", [_vm._v(_vm._s(_vm.info.asstatusname))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("답변자")]),
                      _c("td", [_vm._v(_vm._s(_vm.info.repname))]),
                      _c("th", [_vm._v("아이디")]),
                      _c("td", [_vm._v(_vm._s(_vm.info.repid))]),
                      _c("th", [_vm._v("답변일시")]),
                      _c("td", [_vm._v(_vm._s(_vm.info.repregdate))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("답변템플릿")]),
                      _c("td", { attrs: { colspan: "5" } }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.info.tplidx,
                                expression: "info.tplidx",
                              },
                            ],
                            staticStyle: { width: "800px" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.info,
                                    "tplidx",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                _vm.changeRepTemplate,
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("자주 쓰는 답변 선택"),
                            ]),
                            _vm._l(_vm.repTemplateList, function (item) {
                              return _c(
                                "option",
                                {
                                  key: item.tplidx,
                                  domProps: { value: item.tplidx },
                                },
                                [_vm._v(" " + _vm._s(item.subject) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _c("tr", { staticClass: "contents" }, [
                      _c("th", [_vm._v("답변내용")]),
                      _c(
                        "td",
                        { attrs: { colspan: "5" } },
                        [_c("CommonEditor", { ref: "aseditor" })],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "btn-group" }, [
              _vm.info.asstatus === _vm.$store.getters["ADMIN"].AS_RECEIPT
                ? _c(
                    "button",
                    {
                      staticClass: "btn big blue",
                      attrs: { type: "button" },
                      on: { click: _vm.save },
                    },
                    [_vm._v(" 저장 ")]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
      _vm.isShowVideoPopup
        ? _c("CommonVideoView", {
            ref: "videoPopup",
            attrs: { videoInfo: _vm.videoInfo },
            on: { close: _vm.closeVideoPopup },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title orange" }, [
      _c("i", { staticClass: "icon-q" }),
      _vm._v("질문"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title blue" }, [
      _c("i", { staticClass: "icon-a" }),
      _vm._v("답변"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "23%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "23%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }