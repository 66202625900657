<template>
  <!-- 컨텐츠 영역 -->
  <div class="content m-leftmenu">
    <common-navigator></common-navigator>
    <div class="inner">
      <div class="boxing search-area">
        <dl>
          <dt>조회기간</dt>
          <dd>
            <CommonDatePicker
              :formatType="formatType"
              :value="searchData.startdate"
              @change="onChangeStartDate"
            />
            <span>~</span>
            <CommonDatePicker
              :formatType="formatType"
              :value="searchData.enddate"
              @change="onChangeEndDate"
            />
          </dd>
        </dl>
      </div>
      <div class="btn-group">
        <button v-if="isRead" class="btn big blue" type="button" @click="onSearch(1)">검색</button>
        <button v-if="isRead" class="btn big gray" type="button" @click="initData">초기화</button>
      </div>
      <div class="caption-group mt10 clearfix">
        <div class="total-group fl">
          <span class="total">적립/사용/소멸</span>
        </div>
        <div class="btn-group fr">
          <button v-if="isWrite" class="btn green-line" type="button" @click="excelDown">
            <i class="icon-excel" />
            엑셀다운로드
          </button>
        </div>
      </div>
      <div class="scroll-x">
        <table cellpadding="0" cellspacing="0" class="data-tb align-c">
          <caption>
            적립금 정산 내역
          </caption>
          <colgroup>
            <col width="16%" />
            <!-- 년/월 -->
            <col width="28%" />
            <!-- 적립 -->
            <col width="28%" />
            <!-- 사용 -->
            <col width="28%" />
            <!-- 소멸 -->
          </colgroup>
          <thead>
            <tr>
              <th>년/월</th>
              <th>부여</th>
              <th>사용</th>
              <th>소멸</th>
            </tr>
          </thead>
          <tbody v-if="reserveMasterList.length > 0">
            <tr v-for="(row, index) in reserveMasterList" v-bind:key="index">
              <td>{{ row.yyyymm }}</td>
              <td class="right">{{ row.rewardpoint }}</td>
              <td class="right">{{ row.usepoint }}</td>
              <td class="right">{{ row.expirepoint }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">조회 결과가 존재하지 않습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="caption-group mt10 clearfix">
        <div class="total-group fl">
          <span class="total">적립/사용/소멸 상세</span>
        </div>
      </div>
      <div class="scroll-x">
        <table cellpadding="0" cellspacing="0" class="data-tb align-c">
          <caption>
            적립금 정산 내역 상세
          </caption>
          <colgroup>
            <col width="16%" />
            <!-- 년/월 -->
            <col width="21%" />
            <!-- 구분 -->
            <col width="21%" />
            <!-- 부여사유 -->
            <col width="21%" />
            <!-- 부여상세사유 -->
            <col width="21%" />
            <!-- 적립금액 -->
          </colgroup>
          <thead>
            <tr>
              <th>년/월</th>
              <th>구분</th>
              <th>부여사유</th>
              <th>부여상세사유</th>
              <th>금액</th>
            </tr>
          </thead>
          <tbody v-if="reserveDetailList.length > 0">
            <tr
              v-for="(row, index) in reserveDetailList"
              v-bind:key="index"
              :class="{ 'bg blue': row.type === '부여' }"
            >
              <td>{{ row.yyyymm }}</td>
              <td>{{ row.type }}</td>
              <td>{{ row.respaytypename }}</td>
              <td>{{ row.resreason }}</td>
              <td class="right">{{ row.point }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5">조회 결과가 존재하지 않습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- /컨텐츠 영역 -->
</template>

<script>
import CommonNavigator from '@views.admin/common/CommonNavigator';
import CommonDatePicker from '@views.admin/common/CommonDatePicker';

export default {
  name: 'admin.adjust.estimate.reserve',
  components: {
    CommonNavigator,
    CommonDatePicker,
  },
  data() {
    return {
      searchData: {
        startdate: '',
        enddate: '',
      },
      reserveMasterList: [],
      reserveDetailList: [],
      formatType: 'month',
      isRead: false,
      isWrite: false,
    };
  },
  mounted() {
    this.$http
      .post('/admin/common/pageAuth/check', { url: this.$options.name, isloading: false })
      .then((result) => {
        this.isRead = result.data.isread === 'T';
        this.isWrite = result.data.iswrite === 'T';

        if (this.isRead) {
          this.initData();
        }
      });
  },
  methods: {
    initData() {
      this.searchData = this.$options.data().searchData;
      let startDate = this.$util.getAddMonth(this.$util.getDate(''), -1, '-');
      let endDate = this.$util.getAddMonth(this.$util.getDate(''), -1, '-');

      this.searchData.startdate = startDate.substring(0, 7);
      this.searchData.enddate = endDate.substring(0, 7);
    },
    onSearch(page) {
      let param = this.searchData;
      param.isloading = true;

      this.$http
        .post('/admin/adjust/reserve/list', param)
        .then((result) => {
          this.reserveMasterList = result.data.master;
          this.reserveDetailList = result.data.detail;
          //this.$util.dataSetSearchParam(this);
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    excelDown() {
      let param = this.searchData;
      param.isloading = true;

      let config = { responseType: 'arraybuffer' };
      this.$http
        .post('/admin/adjust/reserve/excel', param, config)
        .then((result) => {
          this.$util.debug(result);
          // this.onSearch(1);
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    onChangeStartDate(val) {
      this.searchData.startdate = val;
    },
    // 날짜 picker 콜백 함수
    onChangeEndDate(val) {
      this.searchData.enddate = val;
    },
  },
};
</script>

<style scoped />
