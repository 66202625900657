var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content m-leftmenu" },
    [
      _c("CommonNavigator"),
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "boxing search-area pd0" }, [
          _c("dl", [
            _c("dt", [_vm._v("조회기간")]),
            _c(
              "dd",
              [
                _c("CommonDatePicker", {
                  attrs: { value: _vm.searchData.startdate },
                  on: { change: _vm.onChangeStartDate },
                }),
                _c("span", [_vm._v("-")]),
                _c("CommonDatePicker", {
                  attrs: { value: _vm.searchData.enddate },
                  on: { change: _vm.onChangeEndDate },
                }),
                _c("div", { staticClass: "radio_wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd1", value: "-1" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "-1") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "-1")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd1" } }, [_vm._v("어제")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd2", value: "0" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "0") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "0")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd2" } }, [_vm._v("오늘")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd3", value: "7" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "7") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "7")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd3" } }, [_vm._v("일주일")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd4", value: "1" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "1") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "1")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd4" } }, [_vm._v("1개월")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd5", value: "3" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "3") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "3")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd5" } }, [_vm._v("3개월")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.period,
                        expression: "searchData.period",
                      },
                    ],
                    attrs: { type: "radio", id: "rd6", value: "6" },
                    domProps: { checked: _vm._q(_vm.searchData.period, "6") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.searchData, "period", "6")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "rd6" } }, [_vm._v("6개월")]),
                ]),
              ],
              1
            ),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("매출산정기준")]),
            _c("dd", [
              _c("div", { staticClass: "radio_wrap wide" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.amtstandard,
                      expression: "searchData.amtstandard",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "group06",
                    id: "group61",
                    value: "NORMAL",
                  },
                  domProps: {
                    checked: _vm._q(_vm.searchData.amtstandard, "NORMAL"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.searchData, "amtstandard", "NORMAL")
                    },
                  },
                }),
                _c("label", { attrs: { for: "group61" } }, [
                  _vm._v("일반기준"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.amtstandard,
                      expression: "searchData.amtstandard",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "group06",
                    id: "group62",
                    value: "ACCOUNT",
                  },
                  domProps: {
                    checked: _vm._q(_vm.searchData.amtstandard, "ACCOUNT"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.searchData, "amtstandard", "ACCOUNT")
                    },
                  },
                }),
                _c("label", { attrs: { for: "group62" } }, [
                  _vm._v("회계기준"),
                ]),
              ]),
            ]),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("조회기준")]),
            _c("dd", [
              _c("div", { staticClass: "radio_wrap wide" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.standard,
                      expression: "searchData.standard",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "group07",
                    id: "group71",
                    value: "PAY",
                  },
                  domProps: { checked: _vm._q(_vm.searchData.standard, "PAY") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.searchData, "standard", "PAY")
                    },
                  },
                }),
                _c("label", { attrs: { for: "group71" } }, [
                  _vm._v("결제완료"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.standard,
                      expression: "searchData.standard",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "group07",
                    id: "group72",
                    value: "BUY",
                  },
                  domProps: { checked: _vm._q(_vm.searchData.standard, "BUY") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.searchData, "standard", "BUY")
                    },
                  },
                }),
                _c("label", { attrs: { for: "group72" } }, [
                  _vm._v("구매확정"),
                ]),
              ]),
            ]),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("상품구분")]),
            _c("dd", [
              _c("div", { staticClass: "radio_wrap wide" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.gubun,
                      expression: "searchData.gubun",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "group00",
                    id: "group01",
                    value: "GOODS",
                  },
                  domProps: { checked: _vm._q(_vm.searchData.gubun, "GOODS") },
                  on: {
                    change: [
                      function ($event) {
                        return _vm.$set(_vm.searchData, "gubun", "GOODS")
                      },
                      _vm.searchList,
                    ],
                  },
                }),
                _c("label", { attrs: { for: "group01" } }, [_vm._v("상품별")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.gubun,
                      expression: "searchData.gubun",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "group00",
                    id: "group02",
                    value: "OPTION",
                  },
                  domProps: { checked: _vm._q(_vm.searchData.gubun, "OPTION") },
                  on: {
                    change: [
                      function ($event) {
                        return _vm.$set(_vm.searchData, "gubun", "OPTION")
                      },
                      _vm.searchList,
                    ],
                  },
                }),
                _c("label", { attrs: { for: "group02" } }, [_vm._v("단품별")]),
              ]),
            ]),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("상품분류")]),
            _c("dd", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.depth1cateidx,
                      expression: "searchData.depth1cateidx",
                    },
                  ],
                  staticStyle: { width: "200px" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "depth1cateidx",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.getCategoryCodeList(2, $event.target.value)
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("대분류")]),
                  _vm._l(_vm.categoryObj.depth1list, function (item) {
                    return _c(
                      "option",
                      { key: item.idx, domProps: { value: item.idx } },
                      [_vm._v(" " + _vm._s(item.value) + " ")]
                    )
                  }),
                ],
                2
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.depth2cateidx,
                      expression: "searchData.depth2cateidx",
                    },
                  ],
                  staticStyle: { width: "200px" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "depth2cateidx",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.getCategoryCodeList(3, $event.target.value)
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("중분류")]),
                  _vm._l(_vm.categoryObj.depth2list, function (item) {
                    return _c(
                      "option",
                      { key: item.idx, domProps: { value: item.idx } },
                      [_vm._v(" " + _vm._s(item.value) + " ")]
                    )
                  }),
                ],
                2
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.depth3cateidx,
                      expression: "searchData.depth3cateidx",
                    },
                  ],
                  staticStyle: { width: "200px" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "depth3cateidx",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.getCategoryCodeList(4, $event.target.value)
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("소분류")]),
                  _vm._l(_vm.categoryObj.depth3list, function (item) {
                    return _c(
                      "option",
                      { key: item.idx, domProps: { value: item.idx } },
                      [_vm._v(" " + _vm._s(item.value) + " ")]
                    )
                  }),
                ],
                2
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.depth4cateidx,
                      expression: "searchData.depth4cateidx",
                    },
                  ],
                  staticStyle: { width: "200px" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "depth4cateidx",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("세분류")]),
                  _vm._l(_vm.categoryObj.depth4list, function (item) {
                    return _c(
                      "option",
                      { key: item.idx, domProps: { value: item.idx } },
                      [_vm._v(" " + _vm._s(item.value) + " ")]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("직접검색")]),
            _c("dd", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.skey,
                      expression: "searchData.skey",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "skey",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("전체")]),
                  _c("option", { attrs: { value: "dealercode" } }, [
                    _vm._v("공급사코드"),
                  ]),
                  _c("option", { attrs: { value: "dealername" } }, [
                    _vm._v("공급사명"),
                  ]),
                  _c("option", { attrs: { value: "brandcode" } }, [
                    _vm._v("브랜드코드"),
                  ]),
                  _c("option", { attrs: { value: "brandname" } }, [
                    _vm._v("브랜드명"),
                  ]),
                  _c("option", { attrs: { value: "goodsno" } }, [
                    _vm._v("상품코드"),
                  ]),
                  _c("option", { attrs: { value: "optioncode" } }, [
                    _vm._v("단품코드"),
                  ]),
                  _c("option", { attrs: { value: "goodsname" } }, [
                    _vm._v("상품명"),
                  ]),
                  _c("option", { attrs: { value: "optionname" } }, [
                    _vm._v("옵션명"),
                  ]),
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.sword,
                    expression: "searchData.sword",
                  },
                ],
                attrs: { type: "text", maxlength: "200" },
                domProps: { value: _vm.searchData.sword },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.searchList(1)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "sword", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("회원구분")]),
            _c("dd", [
              _c("div", { staticClass: "radio_wrap wide3" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.isnonmember,
                      expression: "searchData.isnonmember",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "isnonmember",
                    id: "isnonmemberAll",
                    value: "",
                  },
                  domProps: { checked: _vm._q(_vm.searchData.isnonmember, "") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.searchData, "isnonmember", "")
                    },
                  },
                }),
                _c("label", { attrs: { for: "isnonmemberAll" } }, [
                  _vm._v("전체"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.isnonmember,
                      expression: "searchData.isnonmember",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "isnonmember",
                    id: "isnonmemberF",
                    value: "F",
                  },
                  domProps: {
                    checked: _vm._q(_vm.searchData.isnonmember, "F"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.searchData, "isnonmember", "F")
                    },
                  },
                }),
                _c("label", { attrs: { for: "isnonmemberF" } }, [
                  _vm._v("회원"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.isnonmember,
                      expression: "searchData.isnonmember",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "isnonmember",
                    id: "isnonmemberT",
                    value: "T",
                  },
                  domProps: {
                    checked: _vm._q(_vm.searchData.isnonmember, "T"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.searchData, "isnonmember", "T")
                    },
                  },
                }),
                _c("label", { attrs: { for: "isnonmemberT" } }, [
                  _vm._v("비회원"),
                ]),
              ]),
            ]),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("회원유형")]),
            _c(
              "dd",
              [
                _c("div", { staticClass: "check-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.isallmember,
                        expression: "searchData.isallmember",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "chkAllMember",
                      "true-value": "T",
                      "false-value": "F",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.searchData.isallmember)
                        ? _vm._i(_vm.searchData.isallmember, null) > -1
                        : _vm._q(_vm.searchData.isallmember, "T"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.searchData.isallmember,
                            $$el = $event.target,
                            $$c = $$el.checked ? "T" : "F"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallmember",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallmember",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.searchData, "isallmember", $$c)
                          }
                        },
                        _vm.checkAllMembertype,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "chkAllMember" } }, [
                    _vm._v("전체"),
                  ]),
                ]),
                _vm._l(_vm.commonCode.dadamembertype, function (item) {
                  return _c(
                    "div",
                    { key: item.cmcode, staticClass: "check-wrap" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.mumembertypeArr,
                            expression: "searchData.mumembertypeArr",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "dadamembertype_" + item.cmcode,
                          "true-value": [],
                        },
                        domProps: {
                          value: item.cmcode,
                          checked: Array.isArray(_vm.searchData.mumembertypeArr)
                            ? _vm._i(
                                _vm.searchData.mumembertypeArr,
                                item.cmcode
                              ) > -1
                            : _vm._q(_vm.searchData.mumembertypeArr, []),
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.searchData.mumembertypeArr,
                              $$el = $event.target,
                              $$c = $$el.checked ? [] : false
                            if (Array.isArray($$a)) {
                              var $$v = item.cmcode,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "mumembertypeArr",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "mumembertypeArr",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.searchData, "mumembertypeArr", $$c)
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        { attrs: { for: "dadamembertype_" + item.cmcode } },
                        [_vm._v(_vm._s(item.codename))]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("회원등급")]),
            _c(
              "dd",
              [
                _c("div", { staticClass: "check-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.isallmemlv,
                        expression: "searchData.isallmemlv",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "chkAllMemlv",
                      "true-value": "T",
                      "false-value": "F",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.searchData.isallmemlv)
                        ? _vm._i(_vm.searchData.isallmemlv, null) > -1
                        : _vm._q(_vm.searchData.isallmemlv, "T"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.searchData.isallmemlv,
                            $$el = $event.target,
                            $$c = $$el.checked ? "T" : "F"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallmemlv",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallmemlv",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.searchData, "isallmemlv", $$c)
                          }
                        },
                        _vm.checkAllMemlvtype,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "chkAllMemlv" } }, [
                    _vm._v("전체"),
                  ]),
                ]),
                _vm._l(_vm.commonCode.memlvtype, function (item) {
                  return _c(
                    "div",
                    { key: item.cmcode, staticClass: "check-wrap" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.mumemlvtypeArr,
                            expression: "searchData.mumemlvtypeArr",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "mumemlvtype_" + item.cmcode,
                          "true-value": [],
                        },
                        domProps: {
                          value: item.cmcode,
                          checked: Array.isArray(_vm.searchData.mumemlvtypeArr)
                            ? _vm._i(
                                _vm.searchData.mumemlvtypeArr,
                                item.cmcode
                              ) > -1
                            : _vm._q(_vm.searchData.mumemlvtypeArr, []),
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.searchData.mumemlvtypeArr,
                              $$el = $event.target,
                              $$c = $$el.checked ? [] : false
                            if (Array.isArray($$a)) {
                              var $$v = item.cmcode,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "mumemlvtypeArr",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "mumemlvtypeArr",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.searchData, "mumemlvtypeArr", $$c)
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        { attrs: { for: "mumemlvtype_" + item.cmcode } },
                        [_vm._v(_vm._s(item.codename))]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _c("dl", [
            _c("dt", [_vm._v("주문경로")]),
            _c(
              "dd",
              [
                _c("div", { staticClass: "check-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.isallchannel,
                        expression: "searchData.isallchannel",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "chkAllAppch",
                      "true-value": "T",
                      "false-value": "F",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.searchData.isallchannel)
                        ? _vm._i(_vm.searchData.isallchannel, null) > -1
                        : _vm._q(_vm.searchData.isallchannel, "T"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.searchData.isallchannel,
                            $$el = $event.target,
                            $$c = $$el.checked ? "T" : "F"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallchannel",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.searchData,
                                  "isallchannel",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.searchData, "isallchannel", $$c)
                          }
                        },
                        _vm.checkAllAppchtype,
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "chkAllAppch" } }, [
                    _vm._v("전체"),
                  ]),
                ]),
                _vm._l(_vm.commonCode.muappchtype, function (item) {
                  return _c(
                    "div",
                    { key: item.cmcode, staticClass: "check-wrap" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.muappchtypeArr,
                            expression: "searchData.muappchtypeArr",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "muappchtype_" + item.cmcode,
                          "true-value": [],
                        },
                        domProps: {
                          value: item.cmcode,
                          checked: Array.isArray(_vm.searchData.muappchtypeArr)
                            ? _vm._i(
                                _vm.searchData.muappchtypeArr,
                                item.cmcode
                              ) > -1
                            : _vm._q(_vm.searchData.muappchtypeArr, []),
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.searchData.muappchtypeArr,
                              $$el = $event.target,
                              $$c = $$el.checked ? [] : false
                            if (Array.isArray($$a)) {
                              var $$v = item.cmcode,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "muappchtypeArr",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.searchData,
                                    "muappchtypeArr",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.searchData, "muappchtypeArr", $$c)
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        { attrs: { for: "muappchtype_" + item.cmcode } },
                        [_vm._v(_vm._s(item.codename))]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
            _c("dd", { staticStyle: { width: "130px" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.isTotCheck,
                    expression: "searchData.isTotCheck",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  id: "chk_searchAll",
                  "true-value": "T",
                  "false-value": "F",
                },
                domProps: {
                  checked: Array.isArray(_vm.searchData.isTotCheck)
                    ? _vm._i(_vm.searchData.isTotCheck, null) > -1
                    : _vm._q(_vm.searchData.isTotCheck, "T"),
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.searchData.isTotCheck,
                        $$el = $event.target,
                        $$c = $$el.checked ? "T" : "F"
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.searchData,
                              "isTotCheck",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.searchData,
                              "isTotCheck",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.searchData, "isTotCheck", $$c)
                      }
                    },
                    _vm.checkTotAll,
                  ],
                },
              }),
              _c(
                "label",
                { staticClass: "txt-blue", attrs: { for: "chk_searchAll" } },
                [_vm._v("조건 전체체크")]
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isRead,
                expression: "isRead",
              },
            ],
            staticClass: "btn-group",
          },
          [
            _c(
              "button",
              {
                staticClass: "btn big blue",
                attrs: { type: "button" },
                on: { click: _vm.searchList },
              },
              [_vm._v("검색")]
            ),
            _c(
              "button",
              {
                staticClass: "btn big gray",
                attrs: { type: "button" },
                on: { click: _vm.initSearchData },
              },
              [_vm._v("초기화")]
            ),
          ]
        ),
        _c("div", { staticClass: "caption-group mt10 clearfix" }, [
          _c("div", { staticClass: "total-group fl" }, [
            _c("span", { staticClass: "total" }, [
              _vm._v("전체 "),
              _c("strong", [_vm._v(_vm._s(_vm.totalCnt))]),
              _vm._v("건"),
            ]),
          ]),
          _c("div", { staticClass: "btn-group fr" }, [
            _c("i", { staticClass: "icon-alert" }),
            _c("span", { staticClass: "txt-orange mr5" }, [
              _vm._v("쿠폰할인은 상품쿠폰할인금액만 취합"),
            ]),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isRead,
                    expression: "isRead",
                  },
                ],
                staticClass: "btn green-line",
                attrs: { type: "button" },
                on: { click: _vm.goExcelDownload },
              },
              [_c("i", { staticClass: "icon-excel" }), _vm._v("엑셀다운로드 ")]
            ),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pagingData.pageCount,
                    expression: "pagingData.pageCount",
                  },
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isRead,
                    expression: "isRead",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.pagingData,
                      "pageCount",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { attrs: { value: "20" } }, [
                  _vm._v("20개씩 보기"),
                ]),
                _c("option", { attrs: { value: "50" } }, [
                  _vm._v("50개씩 보기"),
                ]),
                _c("option", { attrs: { value: "100" } }, [
                  _vm._v("100개씩 보기"),
                ]),
              ]
            ),
          ]),
        ]),
        _vm.searchData.gubun === "GOODS"
          ? _c(
              "table",
              {
                staticClass: "data-tb align-r",
                attrs: { cellpadding: "0", cellspacing: "0" },
              },
              [
                _c("caption", [_vm._v(" 목록 ")]),
                _vm._m(0),
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _vm._v(" 순위 "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.rank === "rank_asc" },
                          { down: _vm.sortData.rank === "rank_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.rank },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.rank)
                          },
                        },
                      }),
                    ]),
                    _c("th", [
                      _vm._v(" 상품코드 "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.goodscode === "goodscode_asc" },
                          { down: _vm.sortData.goodscode === "goodscode_desc" },
                        ],
                        attrs: {
                          type: "button",
                          value: _vm.sortData.goodscode,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.goodscode)
                          },
                        },
                      }),
                    ]),
                    _c("th", [
                      _vm._v(" 상품명 "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.goodsname === "goodsname_asc" },
                          { down: _vm.sortData.goodsname === "goodsname_desc" },
                        ],
                        attrs: {
                          type: "button",
                          value: _vm.sortData.goodsname,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.goodsname)
                          },
                        },
                      }),
                    ]),
                    _c("th", [
                      _vm._v(" 순 주문수량 ① "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.frstordcnt === "frstordcnt_asc" },
                          {
                            down: _vm.sortData.frstordcnt === "frstordcnt_desc",
                          },
                        ],
                        attrs: {
                          type: "button",
                          value: _vm.sortData.frstordcnt,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.frstordcnt)
                          },
                        },
                      }),
                    ]),
                    _c("th", [
                      _vm._v(" 환불수량 ② "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.clmcnt === "clmcnt_asc" },
                          { down: _vm.sortData.clmcnt === "clmcnt_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.clmcnt },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.clmcnt)
                          },
                        },
                      }),
                    ]),
                    _c("th", [
                      _vm._v(" 판매수량 ①-② "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.recnt === "recnt_asc" },
                          { down: _vm.sortData.recnt === "recnt_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.recnt },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.recnt)
                          },
                        },
                      }),
                    ]),
                    _c("th", [
                      _c("i", {
                        staticClass: "icon-th-q",
                        on: {
                          click: function ($event) {
                            _vm.toggleData.billing = !_vm.toggleData.billing
                          },
                        },
                      }),
                      _vm._v("취급고 합계 "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.billing === "billing_asc" },
                          { down: _vm.sortData.billing === "billing_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.billing },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.billing)
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "th-cell",
                          class: [
                            { dpb: _vm.toggleData.billing },
                            { dpn: !_vm.toggleData.billing },
                          ],
                          staticStyle: { width: "130px" },
                        },
                        [_vm._v(" 순주문금액 - 환불합계 ")]
                      ),
                    ]),
                    _c("th", [
                      _c("i", {
                        staticClass: "icon-th-q",
                        on: {
                          click: function ($event) {
                            _vm.toggleData.price = !_vm.toggleData.price
                          },
                        },
                      }),
                      _vm._v("매출 합계 ⓐ "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.price === "price_asc" },
                          { down: _vm.sortData.price === "price_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.price },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.price)
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "th-cell",
                          class: [
                            { dpb: _vm.toggleData.price },
                            { dpn: !_vm.toggleData.price },
                          ],
                          staticStyle: { width: "345px" },
                        },
                        [
                          _vm._v(
                            " 매출(일반기준) = 판매가(프로모션할인가)*수량"
                          ),
                          _c("br"),
                          _vm._v(
                            " 매출(회계기준) = 판매가(프로모션할인가)*수량 – 쿠폰할인 + 배송비 "
                          ),
                        ]
                      ),
                    ]),
                    _c("th", [
                      _c("i", {
                        staticClass: "icon-th-q",
                        on: {
                          click: function ($event) {
                            _vm.toggleData.rtnamt = !_vm.toggleData.rtnamt
                          },
                        },
                      }),
                      _vm._v("환불 합계 ⓑ "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.rtnamt === "rtnamt_asc" },
                          { down: _vm.sortData.rtnamt === "rtnamt_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.rtnamt },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.rtnamt)
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "th-cell",
                          class: [
                            { dpb: _vm.toggleData.rtnamt },
                            { dpn: !_vm.toggleData.rtnamt },
                          ],
                          staticStyle: { width: "325px" },
                        },
                        [
                          _vm._v(
                            " 고객반환 실 환불금액(적립금/D포인트/임직원포인트 합산 금액) "
                          ),
                        ]
                      ),
                    ]),
                    _c("th", [
                      _c("i", {
                        staticClass: "icon-th-q",
                        on: {
                          click: function ($event) {
                            _vm.toggleData.margin = !_vm.toggleData.margin
                          },
                        },
                      }),
                      _vm._v("영업이익 "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.margin === "margin_asc" },
                          { down: _vm.sortData.margin === "margin_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.margin },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.margin)
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "th-cell",
                          class: [
                            { dpb: _vm.toggleData.margin },
                            { dpn: !_vm.toggleData.margin },
                          ],
                          staticStyle: { width: "240px" },
                        },
                        [
                          _vm._v(" 영업이익 = 위탁상품 마진 + 직매입 마진"),
                          _c("br"),
                          _vm._v(
                            " - 위탁상품 마진 : 판매가 * 각파트너사 수수료"
                          ),
                          _c("br"),
                          _vm._v(" - 직매입 마진 : 판매가 - 원가 "),
                        ]
                      ),
                    ]),
                    _c("th", [
                      _c("i", {
                        staticClass: "icon-th-q",
                        on: {
                          click: function ($event) {
                            _vm.toggleData.marginper = !_vm.toggleData.marginper
                          },
                        },
                      }),
                      _vm._v("영업이익률(%) "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.marginper === "marginper_asc" },
                          { down: _vm.sortData.marginper === "marginper_desc" },
                        ],
                        attrs: {
                          type: "button",
                          value: _vm.sortData.marginper,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.marginper)
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "th-cell",
                          class: [
                            { dpb: _vm.toggleData.marginper },
                            { dpn: !_vm.toggleData.marginper },
                          ],
                          staticStyle: { width: "110px" },
                        },
                        [_vm._v(" 영업이익 / 순매출 ")]
                      ),
                    ]),
                    _c("th", [
                      _vm._v(" 상품쿠폰할인 "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          {
                            up: _vm.sortData.goodscpnamt === "goodscpnamt_asc",
                          },
                          {
                            down:
                              _vm.sortData.goodscpnamt === "goodscpnamt_desc",
                          },
                        ],
                        attrs: {
                          type: "button",
                          value: _vm.sortData.goodscpnamt,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.goodscpnamt)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _vm.listData.length > 0
                  ? _c(
                      "tbody",
                      [
                        _c("tr", { staticClass: "bg blue" }, [
                          _c("td", { attrs: { colspan: "3" } }, [
                            _vm._v("합계"),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.frstordcnt))]),
                          _c("td", [_vm._v(_vm._s(_vm.clmcnt))]),
                          _c("td", [_vm._v(_vm._s(_vm.recnt))]),
                          _c("td", [_vm._v(_vm._s(_vm.billing))]),
                          _c("td", [_vm._v(_vm._s(_vm.price))]),
                          _c("td", [_vm._v(_vm._s(_vm.rtnamt))]),
                          _c("td", [_vm._v(_vm._s(_vm.margin))]),
                          _c("td", [_vm._v(_vm._s(_vm.marginper) + "%")]),
                          _c("td", [_vm._v(_vm._s(_vm.goodscpnamt))]),
                        ]),
                        _vm._l(_vm.listData, function (row, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { staticClass: "center" }, [
                              _vm._v(_vm._s(row.ranking)),
                            ]),
                            _c("td", { staticClass: "center" }, [
                              _vm._v(_vm._s(row.goodscode)),
                            ]),
                            _c("td", { staticClass: "left" }, [
                              _vm._v(_vm._s(row.goodsname)),
                            ]),
                            _c("td", [_vm._v(_vm._s(row.frstordcnt))]),
                            _c("td", [_vm._v(_vm._s(row.clmcnt))]),
                            _c("td", [_vm._v(_vm._s(row.recnt))]),
                            _c("td", [_vm._v(_vm._s(row.billing))]),
                            _c("td", [_vm._v(_vm._s(row.price))]),
                            _c("td", [_vm._v(_vm._s(row.rtnamt))]),
                            _c("td", [_vm._v(_vm._s(row.margin))]),
                            _c("td", [_vm._v(_vm._s(row.marginper) + "%")]),
                            _c("td", [_vm._v(_vm._s(row.goodscpnamt))]),
                          ])
                        }),
                      ],
                      2
                    )
                  : _c("tbody", [_vm._m(1)]),
              ]
            )
          : _vm._e(),
        _vm.searchData.gubun === "OPTION"
          ? _c(
              "table",
              {
                staticClass: "data-tb align-r",
                attrs: { cellpadding: "0", cellspacing: "0" },
              },
              [
                _c("caption", [_vm._v(" 목록 ")]),
                _vm._m(2),
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _vm._v(" 순위 "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.rank === "rank_asc" },
                          { down: _vm.sortData.rank === "rank_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.rank },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.rank)
                          },
                        },
                      }),
                    ]),
                    _c("th", [
                      _vm._v(" 상품코드 "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.goodscode === "goodscode_asc" },
                          { down: _vm.sortData.goodscode === "goodscode_desc" },
                        ],
                        attrs: {
                          type: "button",
                          value: _vm.sortData.goodscode,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.goodscode)
                          },
                        },
                      }),
                    ]),
                    _c("th", [
                      _vm._v(" 단품코드 "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.optioncode === "optioncode_asc" },
                          {
                            down: _vm.sortData.optioncode === "optioncode_desc",
                          },
                        ],
                        attrs: {
                          type: "button",
                          value: _vm.sortData.optioncode,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.optioncode)
                          },
                        },
                      }),
                    ]),
                    _c("th", [
                      _vm._v(" 상품명 "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.goodsname === "goodsname_asc" },
                          { down: _vm.sortData.goodsname === "goodsname_desc" },
                        ],
                        attrs: {
                          type: "button",
                          value: _vm.sortData.goodsname,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.goodsname)
                          },
                        },
                      }),
                    ]),
                    _c("th", [
                      _vm._v(" 옵션명 "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.optionname === "optionname_asc" },
                          {
                            down: _vm.sortData.optionname === "optionname_desc",
                          },
                        ],
                        attrs: {
                          type: "button",
                          value: _vm.sortData.optionname,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.optionname)
                          },
                        },
                      }),
                    ]),
                    _c("th", [
                      _vm._v(" 순 주문수량 ① "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.frstordcnt === "frstordcnt_asc" },
                          {
                            down: _vm.sortData.frstordcnt === "frstordcnt_desc",
                          },
                        ],
                        attrs: {
                          type: "button",
                          value: _vm.sortData.frstordcnt,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.frstordcnt)
                          },
                        },
                      }),
                    ]),
                    _c("th", [
                      _vm._v(" 환불수량 ② "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.clmcnt === "clmcnt_asc" },
                          { down: _vm.sortData.clmcnt === "clmcnt_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.clmcnt },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.clmcnt)
                          },
                        },
                      }),
                    ]),
                    _c("th", [
                      _vm._v(" 판매수량 ①-② "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.recnt === "recnt_asc" },
                          { down: _vm.sortData.recnt === "recnt_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.recnt },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.recnt)
                          },
                        },
                      }),
                    ]),
                    _c("th", [
                      _c("i", {
                        staticClass: "icon-th-q",
                        on: {
                          click: function ($event) {
                            _vm.toggleData.billing = !_vm.toggleData.billing
                          },
                        },
                      }),
                      _vm._v("취급고 합계 "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.billing === "billing_asc" },
                          { down: _vm.sortData.billing === "billing_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.billing },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.billing)
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "th-cell",
                          class: [
                            { dpb: _vm.toggleData.billing },
                            { dpn: !_vm.toggleData.billing },
                          ],
                          staticStyle: { width: "130px" },
                        },
                        [_vm._v(" 순주문금액 - 환불합계 ")]
                      ),
                    ]),
                    _c("th", [
                      _c("i", {
                        staticClass: "icon-th-q",
                        on: {
                          click: function ($event) {
                            _vm.toggleData.price = !_vm.toggleData.price
                          },
                        },
                      }),
                      _vm._v("매출 합계 ⓐ "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.price === "price_asc" },
                          { down: _vm.sortData.price === "price_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.price },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.price)
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "th-cell",
                          class: [
                            { dpb: _vm.toggleData.price },
                            { dpn: !_vm.toggleData.price },
                          ],
                          staticStyle: { width: "345px" },
                        },
                        [
                          _vm._v(
                            " 매출(일반기준) = 판매가(프로모션할인가)*수량"
                          ),
                          _c("br"),
                          _vm._v(
                            " 매출(회계기준) = 판매가(프로모션할인가)*수량 – 쿠폰할인 + 배송비 "
                          ),
                        ]
                      ),
                    ]),
                    _c("th", [
                      _c("i", {
                        staticClass: "icon-th-q",
                        on: {
                          click: function ($event) {
                            _vm.toggleData.rtnamt = !_vm.toggleData.rtnamt
                          },
                        },
                      }),
                      _vm._v("환불 합계 ⓑ "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.rtnamt === "rtnamt_asc" },
                          { down: _vm.sortData.rtnamt === "rtnamt_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.rtnamt },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.rtnamt)
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "th-cell",
                          class: [
                            { dpb: _vm.toggleData.rtnamt },
                            { dpn: !_vm.toggleData.rtnamt },
                          ],
                          staticStyle: { width: "325px" },
                        },
                        [
                          _vm._v(
                            " 고객반환 실 환불금액(적립금/D포인트/임직원포인트 합산 금액) "
                          ),
                        ]
                      ),
                    ]),
                    _c("th", [
                      _c("i", {
                        staticClass: "icon-th-q",
                        on: {
                          click: function ($event) {
                            _vm.toggleData.margin = !_vm.toggleData.margin
                          },
                        },
                      }),
                      _vm._v("영업이익 "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.margin === "margin_asc" },
                          { down: _vm.sortData.margin === "margin_desc" },
                        ],
                        attrs: { type: "button", value: _vm.sortData.margin },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.margin)
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "th-cell",
                          class: [
                            { dpb: _vm.toggleData.margin },
                            { dpn: !_vm.toggleData.margin },
                          ],
                          staticStyle: { width: "240px" },
                        },
                        [
                          _vm._v(" 영업이익 = 위탁상품 마진 + 직매입 마진"),
                          _c("br"),
                          _vm._v(
                            " - 위탁상품 마진 : 판매가 * 각파트너사 수수료"
                          ),
                          _c("br"),
                          _vm._v(" - 직매입 마진 : 판매가 - 원가 "),
                        ]
                      ),
                    ]),
                    _c("th", [
                      _c("i", {
                        staticClass: "icon-th-q",
                        on: {
                          click: function ($event) {
                            _vm.toggleData.marginper = !_vm.toggleData.marginper
                          },
                        },
                      }),
                      _vm._v("영업이익률(%) "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          { up: _vm.sortData.marginper === "marginper_asc" },
                          { down: _vm.sortData.marginper === "marginper_desc" },
                        ],
                        attrs: {
                          type: "button",
                          value: _vm.sortData.marginper,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.marginper)
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "th-cell",
                          class: [
                            { dpb: _vm.toggleData.marginper },
                            { dpn: !_vm.toggleData.marginper },
                          ],
                          staticStyle: { width: "110px" },
                        },
                        [_vm._v(" 영업이익 / 순매출 ")]
                      ),
                    ]),
                    _c("th", [
                      _vm._v(" 상품쿠폰할인 "),
                      _c("button", {
                        staticClass: "sort",
                        class: [
                          {
                            up: _vm.sortData.goodscpnamt === "goodscpnamt_asc",
                          },
                          {
                            down:
                              _vm.sortData.goodscpnamt === "goodscpnamt_desc",
                          },
                        ],
                        attrs: {
                          type: "button",
                          value: _vm.sortData.goodscpnamt,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sortToggle(_vm.sortData.goodscpnamt)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _vm.listData.length > 0
                  ? _c(
                      "tbody",
                      [
                        _c("tr", { staticClass: "bg blue" }, [
                          _c("td", { attrs: { colspan: "5" } }, [
                            _vm._v("합계"),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.frstordcnt))]),
                          _c("td", [_vm._v(_vm._s(_vm.clmcnt))]),
                          _c("td", [_vm._v(_vm._s(_vm.recnt))]),
                          _c("td", [_vm._v(_vm._s(_vm.billing))]),
                          _c("td", [_vm._v(_vm._s(_vm.price))]),
                          _c("td", [_vm._v(_vm._s(_vm.rtnamt))]),
                          _c("td", [_vm._v(_vm._s(_vm.margin))]),
                          _c("td", [_vm._v(_vm._s(_vm.marginper) + "%")]),
                          _c("td", [_vm._v(_vm._s(_vm.goodscpnamt))]),
                        ]),
                        _vm._l(_vm.listData, function (row, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { staticClass: "center" }, [
                              _vm._v(_vm._s(row.ranking)),
                            ]),
                            _c("td", { staticClass: "center" }, [
                              _vm._v(_vm._s(row.goodscode)),
                            ]),
                            _c("td", { staticClass: "center" }, [
                              _vm._v(_vm._s(row.optioncode)),
                            ]),
                            _c("td", { staticClass: "left" }, [
                              _vm._v(_vm._s(row.goodsname)),
                            ]),
                            _c("td", { staticClass: "left" }, [
                              _vm._v(" " + _vm._s(row.optionname) + " "),
                            ]),
                            _c("td", [_vm._v(_vm._s(row.frstordcnt))]),
                            _c("td", [_vm._v(_vm._s(row.clmcnt))]),
                            _c("td", [_vm._v(_vm._s(row.recnt))]),
                            _c("td", [_vm._v(_vm._s(row.billing))]),
                            _c("td", [_vm._v(_vm._s(row.price))]),
                            _c("td", [_vm._v(_vm._s(row.rtnamt))]),
                            _c("td", [_vm._v(_vm._s(row.margin))]),
                            _c("td", [_vm._v(_vm._s(row.marginper) + "%")]),
                            _c("td", [_vm._v(_vm._s(row.goodscpnamt))]),
                          ])
                        }),
                      ],
                      2
                    )
                  : _c("tbody", [_vm._m(3)]),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "bottom-group" },
          [
            _c("CommonPageNavigator", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isRead,
                  expression: "isRead",
                },
              ],
              attrs: { pagingData: _vm.pagingData },
              on: { setPagingData: _vm.setPagingData },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "6.5%" } }),
      _c("col", { attrs: { width: "5.5%" } }),
      _c("col", { attrs: { width: "6.5%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "6%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "center", attrs: { colspan: "13" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "6.5%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "7%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "center", attrs: { colspan: "15" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }