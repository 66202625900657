var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content m-leftmenu" },
    [
      _c("common-navigator"),
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "boxing" }, [
          _c("div", { staticClass: "form-area" }, [
            _c("dl", [
              _c("dt", [_vm._v("수정권한설정")]),
              _c("dd", { staticClass: "full" }, [
                _c("div", { staticClass: "check-field" }, [
                  _c(
                    "ul",
                    { staticClass: "clearfix" },
                    _vm._l(
                      this.commonCode.muaddauthtype,
                      function (item, index) {
                        return _c("li", { key: index }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.muaddauthtypearr,
                                expression: "muaddauthtypearr",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "chk_" + index,
                              "true-value": "[]",
                            },
                            domProps: {
                              value: item.cmcode,
                              checked: Array.isArray(_vm.muaddauthtypearr)
                                ? _vm._i(_vm.muaddauthtypearr, item.cmcode) > -1
                                : _vm._q(_vm.muaddauthtypearr, "[]"),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.muaddauthtypearr,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "[]" : false
                                if (Array.isArray($$a)) {
                                  var $$v = item.cmcode,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.muaddauthtypearr = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.muaddauthtypearr = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.muaddauthtypearr = $$c
                                }
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "chk_" + index } }, [
                            _vm._v(_vm._s(item.codename)),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "btn-group" }, [
          _vm.isWrite
            ? _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.onSave },
                },
                [_vm._v("저장")]
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }