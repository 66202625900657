<template>
  <div class="content m-leftmenu">
    <CommonNavigator />

    <div class="inner">
      <div class="boxing search-area pd0">
        <dl>
          <dt>직접검색</dt>
          <dd>
            <select v-model="searchData.skey">
              <option
                v-for="item in getGoodsNoticeSearchOptions()"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </option>
            </select>
            <input
              type="text"
              v-model="searchData.sword"
              maxlength="200"
              @keyup.enter="searchList(1)"
            />
          </dd>
        </dl>
        <dl>
          <dt>등록일자</dt>
          <dd>
            <CommonDatePicker :value="searchData.startDate" @change="onChangeStartDate" />
            <span>-</span>
            <CommonDatePicker :value="searchData.endDate" @change="onChangeEndDate" />
            <div class="radio_wrap">
              <input type="radio" v-model="searchData.period" id="period_aday_1" value="aday_1" />
              <label for="period_aday_1">어제</label>
              <input type="radio" v-model="searchData.period" id="period_aday_0" value="aday_0" />
              <label for="period_aday_0">오늘</label>
              <input type="radio" v-model="searchData.period" id="period_days_7" value="days_7" />
              <label for="period_days_7">일주일</label>
              <input
                type="radio"
                v-model="searchData.period"
                id="period_months_1"
                value="months_1"
              />
              <label for="period_months_1">1개월</label>
              <input
                type="radio"
                v-model="searchData.period"
                id="period_months_3"
                value="months_3"
              />
              <label for="period_months_3">3개월</label>
              <input
                type="radio"
                v-model="searchData.period"
                id="period_months_6"
                value="months_6"
              />
              <label for="period_months_6">6개월</label>
            </div>
          </dd>
        </dl>
      </div>

      <div class="btn-group">
        <button type="button" class="btn big blue" @click="searchList(1)">검색</button>
        <button type="button" class="btn big gray" @click="initSearchData">초기화</button>
      </div>

      <div class="caption-group mt10 clearfix">
        <div class="total-group fl">
          <span class="total">조회결과</span>
        </div>
        <div class="btn-group fr">
          <select v-model="pagingData.pageSize">
            <option :value="20">20개씩 보기</option>
            <option :value="50">50개씩 보기</option>
            <option :value="100">100개씩 보기</option>
          </select>
        </div>
      </div>

      <div>
        <table cellpadding="0" cellspacing="0" class="data-tb align-c">
          <caption>상품 공지사항 목록</caption>
          <colgroup>
            <col width="10%" />
            <!-- No -->
            <col width="" />
            <!-- 제목 -->
            <col width="10%" />
            <!-- 파트너사명 -->
            <col width="10%" />
            <!-- 전시여부 -->
            <col width="10%" />
            <!-- 전시설정 -->
            <col width="10%" />
            <!-- 전시시작일 -->
            <col width="10%" />
            <!-- 전시종료일 -->
            <col width="15%" />
            <!-- 등록일 -->
          </colgroup>
          <thead>
            <tr>
              <th>No</th>
              <th>제목</th>
              <th>파트너사명</th>
              <th>전시여부</th>
              <th>전시설정</th>
              <th>전시시작일</th>
              <th>전시종료일</th>
              <th>등록일</th>
            </tr>
          </thead>
          <tbody v-if="noticeList.length > 0">
            <tr v-for="notice in noticeList" :key="notice.id">
              <td>{{ notice.id }}</td>
              <td>
                <button class="link" @click="onModifyNoticePopup(notice.id)">
                  {{ notice.title }}
                </button>
              </td>
              <td>
                {{ notice.partnerName }}
              </td>
              <td>
                {{ getActiveDisplayNoticeLabel(notice.isDisplay) }}
              </td>
              <td>
                {{ getDisplayStatusName(notice.displayStatus) }}
              </td>
              <td>{{ notice.displayStartDate || '-' }}</td>
              <td>{{ notice.displayEndDate || '-' }}</td>
              <td>{{ notice.createDate }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td :colspan="8">조회 결과가 존재하지 않습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="bottom-group">
        <div class="paging">
          <CommonNewPageNavigator :pagingData="pagingData" @changePage="onChangePage" />
        </div>
      </div>
    </div>

    <AdminGoodsNoticeDetailPopup
      v-if="isOpendDetailPopup"
      :noticeId="selectedNoticeId"
      @onRefresh="onRefresh"
      @onClose="onCloseNoticeDetailPopup"
    />
  </div>
</template>

<script>
import {
  GOODS_NOTICE_DISPLAY_STATUS,
  GOODS_NOTICE_SEARCH_TYPE,
  GOODS_NOTICE_SEARCH_OPTIONS,
} from '@constants/goods/notice';
import CommonNavigator from '@views.admin/common/CommonNavigator.vue';
import CommonDatePicker from '@views.admin/common/CommonDatePicker.vue';
import CommonNewPageNavigator from '@views.admin/common/CommonNewPageNavigator.vue';
import AdminGoodsNoticeDetailPopup from './NoticeDetailPopup.vue';

export default {
  name: 'admin.goods.notice',
  components: {
    CommonNavigator,
    CommonDatePicker,
    CommonNewPageNavigator,
    AdminGoodsNoticeDetailPopup,
  },
  data() {
    return {
      noticeList: [],
      selectedNoticeId: null,
      searchData: {
        period: '',
        startDate: '',
        endDate: '',
        skey: GOODS_NOTICE_SEARCH_TYPE.파트너명,
        sword: '',
      },
      pagingData: {
        pageNumber: 1,
        pageSize: 20,
        totalPageCount: 0,
      },
    };
  },
  computed: {
    isOpendDetailPopup() {
      return this.selectedNoticeId !== null;
    },
  },
  watch: {
    'searchData.period': function (value) {
      let params = value.split('_');
      let type = params[0];
      let addValue = parseInt(params[1]) * -1;

      let searchData = {
        ...this.searchData,
        startDate: this.$util.getAddDate(this.$util.getDate(), addValue, '-'),
        endDate: this.$util.getAddDate(this.$util.getDate(), addValue, '-'),
      };

      if (type == 'days') {
        searchData = {
          ...this.searchData,
          startDate: this.$util.getAddDate(this.$util.getDate(), addValue, '-'),
          endDate: this.$util.getDate('-'),
        };
      } else if (type == 'months') {
        searchData = {
          ...this.searchData,
          startDate: this.$util.getAddMonth(this.$util.getDate(), addValue, '-'),
          endDate: this.$util.getDate('-'),
        };
      }

      this.searchData = searchData;
    },
    'pagingData.pageSize': function () {
      this.searchList(1);
    },
  },
  mounted() {
    this.initSearchData();
    this.searchList(1);
  },
  methods: {
    onChangePage(pagingData) {
      this.searchList(pagingData.pageNumber);
    },
    onChangeStartDate(value) {
      this.searchData.startDate = value;
    },
    onChangeEndDate(value) {
      this.searchData.endDate = value;
    },
    onCloseNoticeDetailPopup() {
      this.selectedNoticeId = null;
    },
    onModifyNoticePopup(noticeId) {
      this.selectedNoticeId = `${noticeId}`;
    },
    onRefresh() {
      const { startDate, endDate, skey, sword } = this.searchData;
      const { pageSize, pageNumber } = this.pagingData;

      const keyword = (() => {
        if (!sword) {
          return {};
        }
        return skey === GOODS_NOTICE_SEARCH_TYPE.파트너명
          ? { partnerName: sword }
          : { title: sword };
      })();

      const params = {
        pageSize,
        pageNumber,
        startDate,
        endDate,
        ...keyword,
      };

      this.getNoticeList(params);
    },
    getActiveDisplayNoticeLabel(isDisplay) {
      return isDisplay ? '전시중' : '미전시';
    },
    getDisplayStatusName(displayStatus) {
      return displayStatus === GOODS_NOTICE_DISPLAY_STATUS.전시 ? '전시함' : '전시안함';
    },
    getGoodsNoticeSearchOptions() {
      return [...GOODS_NOTICE_SEARCH_OPTIONS];
    },
    initSearchData() {
      const searchData = {
        period: 'months_3',
        startDate: this.$util.getAddMonth(this.$util.getDate(), -3, '-'),
        endDate: this.$util.getDate('-'),
        skey: GOODS_NOTICE_SEARCH_TYPE.파트너명,
        sword: '',
      };

      this.searchData = searchData;
    },
    searchList(page) {
      if (
        this.$util.isNull(this.searchData.startDate) ||
        this.$util.isNull(this.searchData.endDate)
      ) {
        alert('등록일자는 필수 조회조건 입니다.');
        return;
      }

      const { startDate, endDate, skey, sword } = this.searchData;
      const { pageSize } = this.pagingData;

      const keyword = (() => {
        if (!sword) {
          return {};
        }
        return skey === GOODS_NOTICE_SEARCH_TYPE.파트너명
          ? { partnerName: sword }
          : { title: sword };
      })();

      const params = {
        pageSize,
        pageNumber: page,
        startDate,
        endDate,
        ...keyword,
      };

      this.getNoticeList(params);
    },
    async getNoticeList(params) {
      try {
        const { data } = await this.$http.get('/v2/goods-notice/list', {
          params: { ...params, isloading: true },
        });

        if (data) {
          const { list, pageNumber, pageSize, totalPageCount } = data;
          this.noticeList = list || [];
          this.pagingData = {
            pageNumber,
            pageSize,
            totalPageCount,
          };
        } else {
          this.noticeList = [];
        }
      } catch (error) {
        this.$util.debug(error);
      }
    },
  },
};
</script>

<style scoped>
.link {
  text-decoration: none !important;
  width: 100%;
}

button {
  background-color: transparent;
  border: none;
}
</style>
