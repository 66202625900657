<template>
  <div id="modal-wrap" class="modal" style="display: block">
    <div class="modal-content" style="width: 1200px">
      <div class="pop-header">
        <h2>상품 공지사항 {{ isNew ? '등록' : '수정' }}</h2>
        <button type="button" class="pop-close" @click="onClose"></button>
      </div>
      <div class="pop-body">
        <NoticeForm v-if="!!noticeInfo" v-model="noticeInfo" />
        <div class="btn-group">
          <button type="button" class="btn big blue" :disabled="!onValid()" @click="onSave">
            {{ isNew ? '등록' : '수정' }}
          </button>
          <button type="button" class="btn big darkgray" @click="onClose">취소</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GOODS_NOTICE_DISPLAY_STATUS, GOODS_NOTICE_TYPE } from '@constants/goods/notice';
import NoticeForm from './NoticeForm.vue';
import { PRESIGNED_UPLOAD_FILE_TYPE, presignedUpload } from '@/js/filePresignedUpload.js';

export default {
  name: 'goods.notice.detail.popup',
  props: {
    noticeId: String,
    partnerId: Number,
    managerId: Number,
  },
  components: {
    NoticeForm,
  },
  data() {
    return {
      noticeInfo: null,
    };
  },
  computed: {
    isNew() {
      return this.noticeId === 'new';
    },
  },
  mounted() {
    if (!!this.noticeId && this.noticeId !== 'new') {
      this.getProductNotice();
    } else {
      this.noticeInfo = {
        id: null,
        title: '',
        displayStatus: GOODS_NOTICE_DISPLAY_STATUS.전시,
        noticeType: GOODS_NOTICE_TYPE.TEXT,
        description: '',
        imageUrl: '',
        displayStartDate: this.$util.getDate('-'),
        displayEndDate: null,
        invaildField: [],
        uploadFile: null,
      };
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    async onSave() {
      if (this.noticeInfo?.invaildField.length > 0) {
        return alert('필수 정보를 입력해 주세요.');
      }

      const partnerId = this.partnerId;
      const managerId = this.managerId;
      const {
        id,
        title,
        displayStatus,
        displayStartDate,
        displayEndDate,
        noticeType,
        description,
        imageUrl,
        uploadFile,
      } = this.noticeInfo;

      const isVisibleStatus = displayStatus === GOODS_NOTICE_DISPLAY_STATUS.전시;
      const param = {
        partnerId,
        title,
        displayStatus,
        displayStartDate: isVisibleStatus ? displayStartDate : null,
        displayEndDate: isVisibleStatus ? displayEndDate : null,
        noticeType,
        ...(noticeType === GOODS_NOTICE_TYPE.TEXT ? { description } : { imageUrl }),
        isloading: true,
      };

      const confirmMessage = this.isNew
        ? '등록 시 모든 상품 상단에 공지 내용이 게시됩니다.\n공지사항을 등록하시겠습니까?'
        : '정보 수정 시 변경된 내용이 즉시 반영됩니다.\n공지 정보를 수정하시겠습니까?';
      if (confirm(confirmMessage)) {
        try {
          if (noticeType === GOODS_NOTICE_TYPE.IMAGE && uploadFile) {
            const data = await presignedUpload({
              file: this.noticeInfo.uploadFile,
              type: PRESIGNED_UPLOAD_FILE_TYPE.이미지,
              uploadPath: 'GoodsNoticeImage',
            });

            param.imageUrl = data.url;

            if (!data) {
              return alert('파일 업로드 실패 되었습니다.');
            }
          }
        } catch (error) {
          this.$util.debug(error);
          return alert('파일 업로드 중 오류가 발생하였습니다. 다시 시도해주세요');
        }

        if (this.isNew) {
          this.createProductNotice({ ...param, managerId });
        } else {
          this.updateProductNotice({ id, ...param, auditor: `${managerId || 'ADMIN'}` });
        }
      }
    },
    onValid() {
      return this.noticeInfo?.invaildField.length === 0;
    },
    async getProductNotice() {
      try {
        const { data } = await this.$http.get(`/v2/goods-notice/${this.noticeId}`, {
          params: { isloading: true },
        });

        if (data) {
          const {
            id,
            title,
            displayStatus,
            noticeType,
            description,
            imageUrl,
            displayStartDate,
            displayEndDate,
          } = data;
          this.noticeInfo = {
            id,
            title,
            displayStatus,
            noticeType,
            description,
            imageUrl,
            displayStartDate,
            displayEndDate,
            invaildField: [],
            uploadFile: null,
          };
        }
      } catch (error) {
        this.$util.debug(error);
        alert(error.message || '상품 공지사항 조회중 오류가 발생하였습니다.');
      }
    },
    async createProductNotice(param) {
      try {
        await this.$http.post('/v2/goods-notice', param);
        this.$emit('onRefresh');
        this.onClose();
      } catch (error) {
        this.$util.debug(error);
        alert(error.message || '상품 공지사항 생성중 오류가 발생하였습니다.');
      }
    },
    async updateProductNotice(param) {
      try {
        await this.$http.patch('/v2/goods-notice', param);
        this.$emit('onRefresh');
        this.onClose();
      } catch (error) {
        this.$util.debug(error);
        alert(error.message || '상품 공지사항 수정중 오류가 발생하였습니다.');
      }
    },
  },
};
</script>
