var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-area" }, [
    _c(
      "table",
      { staticClass: "gray-tb", attrs: { cellpadding: "0", cellspacing: "0" } },
      [
        _vm._m(0),
        _c("tbody", [
          _c("tr", [
            _vm._m(1),
            _c("td", { attrs: { colspan: "3" } }, [
              _c(
                "div",
                [
                  _c("div", { staticClass: "check-wrap" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isChecked,
                          expression: "isChecked",
                        },
                      ],
                      attrs: { type: "checkbox", id: "manager_role_all" },
                      domProps: {
                        checked: Array.isArray(_vm.isChecked)
                          ? _vm._i(_vm.isChecked, null) > -1
                          : _vm.isChecked,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.isChecked,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.isChecked = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.isChecked = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.isChecked = $$c
                            }
                          },
                          function ($event) {
                            return _vm.onCheckAll($event.target.checked)
                          },
                        ],
                      },
                    }),
                    _c("label", { attrs: { for: "manager_role_all" } }, [
                      _vm._v("전체"),
                    ]),
                  ]),
                  _vm._l(_vm.getManagerRoleOptions(), function (role) {
                    return _c(
                      "div",
                      { key: role.value, staticClass: "check-wrap" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.value.roles,
                              expression: "value.roles",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "manager_role_" + role.value,
                          },
                          domProps: {
                            value: role.value,
                            checked: Array.isArray(_vm.value.roles)
                              ? _vm._i(_vm.value.roles, role.value) > -1
                              : _vm.value.roles,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.value.roles,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = role.value,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.value,
                                      "roles",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.value,
                                      "roles",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.value, "roles", $$c)
                              }
                            },
                          },
                        }),
                        _c(
                          "label",
                          { attrs: { for: "manager_role_" + role.value } },
                          [_vm._v(_vm._s(role.label))]
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
          _c(
            "tr",
            [
              _vm._m(2),
              _vm.isNew
                ? _c("fragment", [
                    _c("td", { attrs: { colspan: "3" } }, [
                      _c("div", { staticClass: "new-id-field" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.value.loginId,
                              expression: "value.loginId",
                              modifiers: { trim: true },
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: { type: "text", disabled: !_vm.isNew },
                          domProps: { value: _vm.value.loginId },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.value,
                                "loginId",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _vm.checkValidField("loginId")
                        ? _c("p", { staticClass: "alert-text txt-red" }, [
                            _c("i", { staticClass: "icon-alert2" }),
                            _vm._v(
                              " 5~12자의 영문 소문자와 숫자 조합만 사용 가능합니다. "
                            ),
                          ])
                        : _c("p", { staticClass: "txt-gray" }, [
                            _vm._v(
                              "5~12자의 영문 소문자와 숫자를 조합해주세요."
                            ),
                          ]),
                    ]),
                  ])
                : _c("td", { attrs: { colspan: "3" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.value.loginId,
                          expression: "value.loginId",
                          modifiers: { trim: true },
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", disabled: !_vm.isNew },
                      domProps: { value: _vm.value.loginId },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.value,
                            "loginId",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
            ],
            1
          ),
          _c("tr", [
            _vm._m(3),
            _c("td", { attrs: { colspan: "3" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.value.name,
                    expression: "value.name",
                    modifiers: { trim: true },
                  },
                ],
                class: { error: _vm.checkValidField("name") },
                staticStyle: { width: "100%" },
                attrs: { type: "text" },
                domProps: { value: _vm.value.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.value, "name", $event.target.value.trim())
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.checkValidField("name"),
                      expression: "checkValidField('name')",
                    },
                  ],
                  staticClass: "alert-text txt-red",
                },
                [
                  _c("i", { staticClass: "icon-alert2" }),
                  _vm._v(" 이름은 필수 입력입니다. "),
                ]
              ),
            ]),
          ]),
          _c("tr", [
            _vm._m(4),
            _c("td", { attrs: { colspan: "3" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.value.mobile,
                    expression: "value.mobile",
                    modifiers: { trim: true },
                  },
                ],
                class: { error: _vm.checkValidField("mobile") },
                staticStyle: { width: "100%" },
                attrs: { type: "text", maxlength: "13" },
                domProps: { value: _vm.value.mobile },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.value, "mobile", $event.target.value.trim())
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm.checkValidField("mobile")
                ? _c("p", { staticClass: "alert-text txt-red" }, [
                    _c("i", { staticClass: "icon-alert2" }),
                    _vm._v(" 적합한 휴대폰번호 형식으로 기입해주세요. "),
                  ])
                : _c("p", { staticClass: "txt-gray" }, [
                    _vm._v(
                      " 임시비밀번호 메시지를 받는 연락처입니다. (‘-’ 없이 숫자만 입력) "
                    ),
                  ]),
            ]),
          ]),
          _c("tr", [
            _vm._m(5),
            _c("td", { attrs: { colspan: "3" } }, [
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.emailForm.id,
                      expression: "emailForm.id",
                      modifiers: { trim: true },
                    },
                  ],
                  class: { error: _vm.checkValidField("email") },
                  attrs: { type: "text" },
                  domProps: { value: _vm.emailForm.id },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.emailForm, "id", $event.target.value.trim())
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c("span", [_vm._v("@")]),
                _c("input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.emailForm.selectDomain === "input",
                      expression: "emailForm.selectDomain === 'input'",
                    },
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.emailForm.domain,
                      expression: "emailForm.domain",
                      modifiers: { trim: true },
                    },
                  ],
                  class: { error: _vm.checkValidField("email") },
                  staticStyle: { width: "150px" },
                  attrs: { type: "text" },
                  domProps: { value: _vm.emailForm.domain },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.emailForm,
                        "domain",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c("input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.emailForm.selectDomain !== "input",
                      expression: "emailForm.selectDomain !== 'input'",
                    },
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.emailForm.selectDomain,
                      expression: "emailForm.selectDomain",
                      modifiers: { trim: true },
                    },
                  ],
                  class: { error: _vm.checkValidField("email") },
                  staticStyle: { width: "150px" },
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.emailForm.selectDomain },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.emailForm,
                        "selectDomain",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.emailForm.selectDomain,
                        expression: "emailForm.selectDomain",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.emailForm,
                          "selectDomain",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.getDomainOptions(), function (domain) {
                    return _c(
                      "option",
                      { key: domain.value, domProps: { value: domain.value } },
                      [_vm._v(" " + _vm._s(domain.label) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _vm.checkValidField("email")
                ? _c("p", { staticClass: "alert-text txt-red" }, [
                    _c("i", { staticClass: "icon-alert2" }),
                    _vm._v(" 적합한 이메일 형식으로 기입해주세요. "),
                  ])
                : !_vm.checkValidField("email") && _vm.is세금계산서담당업무선택
                ? _c("p", { staticClass: "txt-gray" }, [
                    _vm._v(" 세금계산서를 받는 이메일 주소입니다. "),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm.isNew
            ? _c("tr", [
                _vm._m(6),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("div", [
                    _c("p", { staticClass: "bold-text" }, [
                      _vm._v("[필수] 개인정보 수집 동의 (운영 계정 신청)"),
                    ]),
                    _vm._m(7),
                    _c("div", { staticClass: "agree-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.value.agree,
                            expression: "value.agree",
                          },
                        ],
                        attrs: { type: "checkbox", id: "agree", name: "agree" },
                        domProps: {
                          checked: Array.isArray(_vm.value.agree)
                            ? _vm._i(_vm.value.agree, null) > -1
                            : _vm.value.agree,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.value.agree,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.value,
                                    "agree",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.value,
                                    "agree",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.value, "agree", $$c)
                            }
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "agree" } }, [
                        _vm._v("개인정보 수집 및 이용에 동의합니다."),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 담당업무 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v(" 아이디 "), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v(" 이름 "), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 휴대폰번호 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v(" 이메일 "), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 개인정보 수집 동의 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v("- 수집 항목 : 아이디, 비밀번호, 이름, 휴대폰번호, 이메일"),
      ]),
      _c("li", [_vm._v("- 이용 목적 : 판매자 별 담당자 식별 및 관리 목적")]),
      _c("li", { staticClass: "bold-text" }, [
        _vm._v(" - 보유 및 이용 기간 : 계약 종료 또는 계정 삭제 처리 시 파기 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }