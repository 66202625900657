var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1200px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("임직원 프로모션 생성")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "form-area" }, [
              _c(
                "table",
                {
                  staticClass: "gray-tb",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(0),
                  _c("tbody", [
                    _c("tr", [
                      _vm._m(1),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _c("div", { staticClass: "new-id-field" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.promotionTitle,
                                expression: "promotionTitle",
                                modifiers: { trim: true },
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: { type: "text", maxlength: "30" },
                            domProps: { value: _vm.promotionTitle },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.promotionTitle = $event.target.value.trim()
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                        !_vm.isValidPromotionTitle
                          ? _c("p", { staticClass: "alert-text txt-red" }, [
                              _c("i", { staticClass: "icon-alert2" }),
                              _vm._v(" 관리 제목은 필수 입력값입니다. "),
                            ])
                          : _c("p", { staticClass: "txt-gray" }, [
                              _vm._v(
                                "임직원 프로모션 관리를 위한 타이틀을 입력해주세요."
                              ),
                            ]),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(2),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _c("div", { staticClass: "new-id-field" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.serialPromotionId,
                                expression: "serialPromotionId",
                                modifiers: { trim: true },
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: { type: "text" },
                            domProps: { value: _vm.serialPromotionId },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.serialPromotionId =
                                  $event.target.value.trim()
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                        !_vm.isValidSerialPromotionId
                          ? _c("p", { staticClass: "alert-text txt-red" }, [
                              _c("i", { staticClass: "icon-alert2" }),
                              _vm._v(
                                " 시리얼 프로모션 ID는 필수 입력값입니다. "
                              ),
                            ])
                          : _c("p", { staticClass: "txt-gray" }, [
                              _vm._v(
                                " 시리얼 프로모션 관리 메뉴에서 시리얼 프로모션 ID를 확인하여 입력해주세요. "
                              ),
                            ]),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(3),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _c("div", { staticClass: "file-upload-wrap" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn green-line",
                              attrs: { type: "button" },
                              on: { click: _vm.onAttachExcelFile },
                            },
                            [
                              _c("i", { staticClass: "icon-excel" }),
                              _vm._v(" 엑셀 업로드 "),
                            ]
                          ),
                          _c("span", [_vm._v(_vm._s(_vm.uploadFileName))]),
                          _c("input", {
                            ref: "excelUpload",
                            attrs: {
                              type: "file",
                              accept: ".xls,.xlsx",
                              hidden: "",
                            },
                            on: { input: _vm.onInputFile },
                          }),
                        ]),
                        !_vm.isValidUploadFile
                          ? _c("p", { staticClass: "alert-text txt-red" }, [
                              _c("i", { staticClass: "icon-alert2" }),
                              _vm._v(" 파일 업로드는 필수값입니다. "),
                            ])
                          : _c("p", { staticClass: "txt-gray" }, [
                              _vm._v(
                                "E-Mail 발송을 위한 엑셀 파일을 업로드해주세요."
                              ),
                            ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button", disabled: !_vm.isValid },
                  on: { click: _vm.onSave },
                },
                [_vm._v(" 저장 ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("취소")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 관리 제목 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 시리얼 프로모션 ID "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 엑셀 업로드 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }