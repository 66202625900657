<template>
  <div class="content m-leftmenu">
    <CommonNavigator />
    <ManagerModifyPopup
      v-if="showModifyPopup"
      :partnerId="partnerId"
      :managerInfo="modifyManagerInfo"
      @onRefresh="onRefreshManagerList"
      @onClose="onCloseModifyPopup"
    />
    <ManagerLoginHistoryPopup
      v-if="showManagerLoginHistoryPopup"
      :managerId="checkLoginHistoryManagerId"
      @onClose="onCloseManagerLoginHistoryPopup"
    />

    <div class="inner">
      <div class="caption-group mt10 clearfix">
        <div class="total-group fl">
          <span class="total">
            전체
            <strong>{{ this.managerList.length }}</strong>
            건
          </span>
        </div>
        <div class="btn-group fr">
          <button type="button" class="btn blue" @click="onCopyInviteLink">
            부운영자 신청 링크 공유
          </button>
          <button type="button" class="btn red-line" @click="onDeleteManager">삭제</button>
        </div>
      </div>
      <table cellpadding="0" cellspacing="0" class="data-tb align-c">
        <caption>부운영자 목록</caption>
        <colgroup>
          <col width="3%" />
          <!-- checkbox -->
          <col width="3%" />
          <!-- No -->
          <col width="10%" />
          <!-- 아이디 -->
          <col width="10%" />
          <!-- 이름 -->
          <col width="15%" />
          <!-- 휴대폰번호 -->
          <col width="" />
          <!-- 이메일 -->
          <col width="5%" />
          <!-- 접속수 -->
          <col width="8%" />
          <!-- 최근접속일시 -->
          <col width="6%" />
          <!-- 운영자 구분 -->
          <col width="8%" />
          <!-- 세금계산서 담당 여부 -->
          <col width="10%" />
          <!-- 비밀번호 초기화 -->
          <col width="5%" />
          <!-- 접속기록 -->
        </colgroup>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                id="chkall"
                v-model="isChecked"
                @change="onCheckAll($event.target.checked)"
              />
            </th>
            <th>No</th>
            <th>아이디</th>
            <th>이름</th>
            <th>휴대폰번호</th>
            <th>이메일</th>
            <th>접속수</th>
            <th>최근접속일자</th>
            <th>운영자 구분</th>
            <th>세금계산서 담당 여부</th>
            <th>비밀번호 초기화</th>
            <th>접속기록</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(manager, index) in managerList" :key="manager.id">
            <td>
              <span v-if="manager.partnerType === 'MAIN'">-</span>
              <input
                v-else
                type="checkbox"
                id="chk01"
                v-model="checkManagerList"
                :value="manager.id"
              />
            </td>
            <td>{{ index + 1 }}</td>
            <td>
              <button class="link" @click="onModifyManagerInfo(manager.id)">
                {{ manager.loginId }}
              </button>
            </td>
            <td>
              {{ manager.name }}
            </td>
            <td>{{ convertMobileString(manager.mobile) }}</td>
            <td>{{ manager.email }}</td>
            <td>{{ convertNumberComma(manager.loginCount) }}</td>
            <td>{{ !manager.lastLoginDate ? '-' : manager.lastLoginDate }}</td>
            <td>{{ manager.partnerType === 'MAIN' ? '대표 운영자' : '부운영자' }}</td>
            <td>{{ checkTaxRole(manager.roleList) ? 'Y' : 'N' }}</td>
            <td>
              <button
                type="button"
                class="btn blue-line"
                @click="onResetPassword(manager.id, manager.mobile)"
              >
                비밀번호 초기화
              </button>
            </td>
            <td>
              <button
                type="button"
                class="btn blue-line"
                @click="onManagerLoginHistoryId(manager.id)"
              >
                접속기록
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CommonNavigator from '@views.admin/common/CommonNavigator';
import { MANAGER_TYPE, MANAGER_ROLE_TYPE } from '@constants/manager';
import ManagerModifyPopup from './ManagerModifyPopup.vue';
import ManagerLoginHistoryPopup from './ManagerLoginHistoryPopup.vue';

export default {
  name: 'partners.operation.managers',
  components: {
    CommonNavigator,
    ManagerModifyPopup,
    ManagerLoginHistoryPopup,
  },
  data() {
    return {
      isChecked: false,
      checkManagerList: [],
      managerList: [],
      modifyManagerId: null,
      checkLoginHistoryManagerId: null,
      isRead: false,
      isWrite: false,
    };
  },
  computed: {
    partnerId() {
      const user = this.$util.getUser(this.$store.getters['CONSTANTS'].MANAGER_SESSION);
      return user.no;
    },
    isMainManagerType() {
      const user = this.$util.getUser(this.$store.getters['CONSTANTS'].MANAGER_SESSION);
      return user.managerType === MANAGER_TYPE.대표운영자;
    },
    modifyManagerInfo() {
      if (!this.modifyManagerId) {
        return null;
      }

      return this.managerList.find(({ id }) => id === this.modifyManagerId);
    },
    showModifyPopup() {
      return !!this.modifyManagerId;
    },
    showManagerLoginHistoryPopup() {
      return !!this.checkLoginHistoryManagerId;
    },
  },
  watch: {
    checkManagerList(value) {
      this.isChecked = value.length === this.managerList.length - 1;
    },
  },
  mounted() {
    if (!this.isMainManagerType) {
      alert('대표운영자만 접근이 가능합니다.');
      return this.$router.push('/main');
    }

    this.getManagerList();
  },
  methods: {
    onCheckAll(checked) {
      this.checkManagerList = !checked
        ? []
        : this.managerList
            .filter(({ partnerType }) => partnerType !== MANAGER_TYPE.대표운영자)
            .map(({ id }) => id);
    },
    onDeleteManager() {
      if (this.checkManagerList.length === 0) {
        alert('계정을 선택해 주세요.');
      } else {
        const ids = [...this.checkManagerList];
        const 세금담당자포함여부 = this.managerList
          .filter(({ id }) => ids.includes(id))
          .some(({ roleList }) =>
            roleList.some(({ roleType }) => roleType === MANAGER_ROLE_TYPE.세금),
          );

        if (세금담당자포함여부) {
          return alert(
            "파트너사별 세금계산서 담당자 지정은 필수입니다.\n세금계산서 담당자를 다른 계정으로 지정 후 삭제해주세요.\n(해당 계정의 담당업무 '세금계산서' 선택 후 저장 시 자동 변경)",
          );
        }

        if (confirm('선택한 계정을 삭제하시겠습니까?')) {
          this.deleteManager(ids);
        }
      }
    },
    onCopyInviteLink() {
      this.getInviteLink((inviteLink) => {
        const tempTextArea = document.createElement('textarea');
        document.body.appendChild(tempTextArea);
        tempTextArea.value = inviteLink;
        tempTextArea.select();
        document.execCommand('copy');
        document.body.removeChild(tempTextArea);

        alert('주소가 복사되었습니다. \n입력하실 곳에 붙여넣기 해주세요.');
      });
    },
    onModifyManagerInfo(id) {
      this.modifyManagerId = id;
    },
    onCloseModifyPopup() {
      this.modifyManagerId = null;
    },
    onManagerLoginHistoryId(id) {
      this.checkLoginHistoryManagerId = id;
    },
    onCloseManagerLoginHistoryPopup() {
      this.checkLoginHistoryManagerId = null;
    },
    onRefreshManagerList() {
      this.getManagerList();
    },
    onResetPassword(id, mobile) {
      if (confirm('비밀번호 초기화 하시겠습니까?')) {
        this.resetPassword(id, mobile);
      }
    },
    checkTaxRole(roleList) {
      const roles = roleList.map(({ roleType }) => roleType);
      return !!roles.find((role) => role === MANAGER_ROLE_TYPE.세금);
    },
    convertMobileString(mobile) {
      return this.$util.replaceMobileNumber(mobile);
    },
    convertNumberComma(number) {
      return this.$util.maskComma(number);
    },
    async getManagerList() {
      try {
        const { data } = await this.$http.get(`/v2/manager/${this.partnerId}/list`, {
          params: { isloading: true },
        });
        this.managerList = data;
      } catch (error) {
        this.$util.debug(error);
      }
    },
    async deleteManager(ids) {
      try {
        await this.$http.post('/v2/manager/delete', { ids, isloading: true });
        alert('삭제가 완료되었습니다.');
        this.managerList = this.managerList.filter(({ id }) => !ids.includes(id));
        this.isChecked = false;
        this.checkManagerList = [];
      } catch (error) {
        this.$util.debug(error);
      }
    },
    async getInviteLink(callback) {
      try {
        const { data } = await this.$http.post(`/v2/manager/join-key`, {
          partnerId: this.partnerId,
        });

        callback(`${process.env.VUE_APP_CLIENT_URL_PARTNER}/manager/regist/${data.key}`);
      } catch (error) {
        this.$util.debug(error);
      }
    },
    async resetPassword(id, mobile) {
      try {
        const params = { userno: id, mobile, isloading: true };
        await this.$http.post('/admin/partners/apply/password/init', params);
        alert(
          '비밀번호 초기화가 완료되었습니다.\n휴대폰 번호로 발송된 임시 비밀번호를 확인해주세요.',
        );
      } catch (error) {
        this.$util.debug(error);
      }
    },
  },
};
</script>

<style scoped>
.content.m-leftmenu {
  min-width: 1700px;
}

.link {
  text-decoration: none !important;
}

button {
  background-color: transparent;
  border: none;
}
</style>
