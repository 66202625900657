export const EMPLOYEE_PROMOTION_SEND_STATUS = {
  발송준비: 'PREPARING',
  발송중: 'IN_PROGRESS',
  발송완료: 'SUCCESS',
  발송실패: 'FAILED',
};

export const EMPLOYEE_PROMOTION_SEND_STATUS_NAME = {
  PREPARING: '발송준비',
  IN_PROGRESS: '발송중',
  SUCCESS: '발송완료',
  FAILED: '발송실패',
};
