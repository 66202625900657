var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-area", staticStyle: { height: "calc(90vh - 246px)" } },
    [
      _vm._m(0),
      _c("div", { staticClass: "boxing search-area" }, [
        _c("dl", [
          _c("dt", [_vm._v("조회기간")]),
          _c(
            "dd",
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.dtkey,
                      expression: "searchData.dtkey",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "dtkey",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "order" } }, [
                    _vm._v("주문일"),
                  ]),
                  _c("option", { attrs: { value: "payment" } }, [
                    _vm._v("결제일"),
                  ]),
                ]
              ),
              _c("CommonDatePicker", {
                attrs: { value: _vm.searchData.startdate },
                on: { change: _vm.onChangeStartDate },
              }),
              _c("span", [_vm._v("-")]),
              _c("CommonDatePicker", {
                attrs: { value: _vm.searchData.enddate },
                on: { change: _vm.onChangeEndDate },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "btn-group" }, [
        _c(
          "button",
          {
            staticClass: "btn big blue",
            attrs: { type: "button" },
            on: { click: _vm.searchList },
          },
          [_vm._v("검색")]
        ),
      ]),
      _c("div", { staticClass: "caption-group mt10 clearfix" }, [
        _c("div", { staticClass: "total-group fl" }, [
          _c("span", { staticClass: "total" }, [
            _vm._v("전체 "),
            _c("strong", [_vm._v(_vm._s(_vm.totalCnt))]),
            _vm._v("건"),
          ]),
        ]),
        _c("div", { staticClass: "btn-group fr" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.pagingData.pageCount,
                  expression: "pagingData.pageCount",
                },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.pagingData,
                    "pageCount",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "20" } }, [_vm._v("20개씩 보기")]),
              _c("option", { attrs: { value: "50" } }, [_vm._v("50개씩 보기")]),
              _c("option", { attrs: { value: "100" } }, [
                _vm._v("100개씩 보기"),
              ]),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "scroll-x" }, [
        _c(
          "table",
          {
            staticClass: "data-tb align-c",
            staticStyle: { width: "250%" },
            attrs: { cellpadding: "0", cellspacing: "0" },
          },
          [
            _c("caption", [_vm._v(" 주문 목록 ")]),
            _vm._m(1),
            _c("thead", [
              _c("tr", [
                _c("th", [
                  _vm._v(" 주문경로 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.ordpathtype === "ordpathtype_asc" },
                      { down: _vm.sortData.ordpathtype === "ordpathtype_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.ordpathtype },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.ordpathtype)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 주문일 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.orderdate === "orderdate_asc" },
                      { down: _vm.sortData.orderdate === "orderdate_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.orderdate },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.orderdate)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 결제일 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.paymentdate === "paymentdate_asc" },
                      { down: _vm.sortData.paymentdate === "paymentdate_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.paymentdate },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.paymentdate)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 주문번호 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.ordno === "ordno_asc" },
                      { down: _vm.sortData.ordno === "ordno_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.ordno },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.ordno)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 첫 구매 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.isfrstorder === "isfrstorder_asc" },
                      { down: _vm.sortData.isfrstorder === "isfrstorder_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.isfrstorder },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.isfrstorder)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 판매구분 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.ispbgoods === "ispbgoods_asc" },
                      { down: _vm.sortData.ispbgoods === "ispbgoods_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.ispbgoods },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.ispbgoods)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 파트너사 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.dealername === "dealername_asc" },
                      { down: _vm.sortData.dealername === "dealername_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.dealername },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.dealername)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 상품코드 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.goodscode === "goodscode_asc" },
                      { down: _vm.sortData.goodscode === "goodscode_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.goodscode },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.goodscode)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 단품코드 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.optioncode === "optioncode_asc" },
                      { down: _vm.sortData.optioncode === "optioncode_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.optioncode },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.optioncode)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 상품순번 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.goodsturn === "goodsturn_asc" },
                      { down: _vm.sortData.goodsturn === "goodsturn_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.goodsturn },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.goodsturn)
                      },
                    },
                  }),
                ]),
                _c("th", { attrs: { colspan: "2" } }, [
                  _vm._v(" 상품명 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.goodsname === "goodsname_asc" },
                      { down: _vm.sortData.goodsname === "goodsname_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.goodsname },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.goodsname)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 옵션 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.optionconts === "optionconts_asc" },
                      { down: _vm.sortData.optionconts === "optionconts_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.optionconts },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.optionconts)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 주문수량 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.ordcnt === "ordcnt_asc" },
                      { down: _vm.sortData.ordcnt === "ordcnt_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.ordcnt },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.ordcnt)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 판매단가 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.price === "price_asc" },
                      { down: _vm.sortData.price === "price_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.price },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.price)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 판매금액 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.totprice === "totprice_asc" },
                      { down: _vm.sortData.totprice === "totprice_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.totprice },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.totprice)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 결제수단 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.paywaytype === "paywaytype_asc" },
                      { down: _vm.sortData.paywaytype === "paywaytype_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.paywaytype },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.paywaytype)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 배송유형 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.iscombdeliv === "iscombdeliv_asc" },
                      { down: _vm.sortData.iscombdeliv === "iscombdeliv_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.iscombdeliv },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.iscombdeliv)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 배송조건 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      {
                        up: _vm.sortData.delivfaretype === "delivfaretype_asc",
                      },
                      {
                        down:
                          _vm.sortData.delivfaretype === "delivfaretype_desc",
                      },
                    ],
                    attrs: {
                      type: "button",
                      value: _vm.sortData.delivfaretype,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.delivfaretype)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 배송비 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.delivamt === "delivamt_asc" },
                      { down: _vm.sortData.delivamt === "delivamt_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.delivamt },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.delivamt)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 수령인명 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.rcvname === "rcvname_asc" },
                      { down: _vm.sortData.rcvname === "rcvname_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.rcvname },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.rcvname)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 연락처 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.rcvtel === "rcvtel_asc" },
                      { down: _vm.sortData.rcvtel === "rcvtel_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.rcvtel },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.rcvtel)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 주소 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.rcvaddr === "rcvaddr_asc" },
                      { down: _vm.sortData.rcvaddr === "rcvaddr_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.rcvaddr },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.rcvaddr)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 택배사 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      {
                        up: _vm.sortData.logistypename === "logistypename_asc",
                      },
                      {
                        down:
                          _vm.sortData.logistypename === "logistypename_desc",
                      },
                    ],
                    attrs: {
                      type: "button",
                      value: _vm.sortData.logistypename,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.logistypename)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 송장번호 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.invoiceno === "invoiceno_asc" },
                      { down: _vm.sortData.invoiceno === "invoiceno_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.invoiceno },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.invoiceno)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 구매확정 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.cfmconts === "cfmconts_asc" },
                      { down: _vm.sortData.cfmconts === "cfmconts_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.cfmconts },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.cfmconts)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(" 주문상태 "),
                  _c("button", {
                    staticClass: "sort",
                    class: [
                      { up: _vm.sortData.ordstatus === "ordstatus_asc" },
                      { down: _vm.sortData.ordstatus === "ordstatus_desc" },
                    ],
                    attrs: { type: "button", value: _vm.sortData.ordstatus },
                    on: {
                      click: function ($event) {
                        return _vm.sortToggle(_vm.sortData.ordstatus)
                      },
                    },
                  }),
                ]),
                _c("th", [_vm._v("클레임여부")]),
              ]),
            ]),
            _vm.list.length > 0
              ? _c(
                  "tbody",
                  _vm._l(_vm.list, function (item) {
                    return _c("tr", { key: item.orgdelividx }, [
                      _c("td", [_vm._v(_vm._s(item.ordpathtypename))]),
                      _c("td", [
                        _vm._v(_vm._s(item.orderdate)),
                        _c("br"),
                        _vm._v(_vm._s(item.ordertime)),
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(item.paymentdate)),
                        _c("br"),
                        _vm._v(_vm._s(item.paymenttime)),
                      ]),
                      _c("td", [
                        _c(
                          "a",
                          {
                            staticClass: "link",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.goOrderDetail(item.ordno)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.ordno))]
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.isfrstorder))]),
                      _c("td", [_vm._v(_vm._s(item.ispbgoodsname))]),
                      _c("td", [_vm._v(_vm._s(item.dealername))]),
                      _c("td", [_vm._v(_vm._s(item.goodscode))]),
                      _c("td", [_vm._v(_vm._s(item.optioncode))]),
                      _c("td", [_vm._v(_vm._s(item.goodsturn))]),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "img-thumb size60 link",
                            class: {
                              "no-image": _vm.$util.isNull(item.fullpath),
                            },
                          },
                          [
                            !_vm.$util.isNull(item.fullpath)
                              ? _c("img", { attrs: { src: item.fullpath } })
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _c("td", { staticClass: "left no-left" }, [
                        _c(
                          "a",
                          {
                            staticClass: "link",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.goGoodsDetail(item.goodsno)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.goodsname))]
                        ),
                      ]),
                      _c("td", { staticStyle: { "white-space": "pre-wrap" } }, [
                        _vm._v(_vm._s(item.optionconts)),
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.$util.maskComma(item.ordcnt))),
                      ]),
                      _c("td", { staticClass: "right" }, [
                        _vm._v(_vm._s(_vm.$util.maskComma(item.price))),
                      ]),
                      _c("td", { staticClass: "right" }, [
                        _vm._v(_vm._s(_vm.$util.maskComma(item.totprice))),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.paywaytypename))]),
                      _c("td", [_vm._v(_vm._s(item.iscombdelivname))]),
                      _c("td", [_vm._v(_vm._s(item.delivfaretypename))]),
                      _c("td", { staticClass: "right" }, [
                        _vm._v(_vm._s(_vm.$util.maskComma(item.delivamt))),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.rcvname))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.$util.maskTel(item.rcvtel))),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.rcvaddr))]),
                      _c("td", [_vm._v(_vm._s(item.logistypename))]),
                      _c("td", [_vm._v(_vm._s(item.invoiceno))]),
                      _c("td", [_vm._v(_vm._s(item.cfmconts))]),
                      _c("td", [_vm._v(_vm._s(item.ordstatusname))]),
                      _c("td", [_vm._v(_vm._s(item.claimyn))]),
                    ])
                  }),
                  0
                )
              : _c("tbody", [_vm._m(2)]),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "bottom-group" },
        [
          _c("CommonPageNavigator", {
            attrs: { pagingData: _vm.pagingData },
            on: { setPagingData: _vm.setPagingData },
          }),
        ],
        1
      ),
      _vm.isShowOrderDetail
        ? _c("OrderDetail", {
            attrs: { activeOrderCode: _vm.activeOrderCode },
            on: { closeDetail: _vm.closeOrderDetail },
          })
        : _vm._e(),
      _vm.isGoodsDetailShow
        ? _c("GoodsDetail", {
            attrs: { activeGoodsNo: _vm.activeGoodsNo },
            on: { closePopup: _vm.closeGoodsDetail },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "bar-title small fl" }, [_vm._v("주문내역")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "2.7%" } }),
      _c("col", { attrs: { width: "3.2%" } }),
      _c("col", { attrs: { width: "3.2%" } }),
      _c("col", { attrs: { width: "4.2%" } }),
      _c("col", { attrs: { width: "2.5%" } }),
      _c("col", { attrs: { width: "2.7%" } }),
      _c("col", { attrs: { width: "3.2%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "2.7%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "2.7%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "2.7%" } }),
      _c("col", { attrs: { width: "2.7%" } }),
      _c("col", { attrs: { width: "2.5%" } }),
      _c("col", { attrs: { width: "2.7%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "2.7%" } }),
      _c("col", { attrs: { width: "3.2%" } }),
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "2.7%" } }),
      _c("col", { attrs: { width: "2.7%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "26" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }