<template>
  <div id="modal-wrap" class="modal" style="display: block">
    <div class="modal-content" style="width: 600px">
      <div class="pop-header">
        <h2>접속기록보기</h2>
        <button type="button" class="pop-close" @click="onClose"></button>
      </div>
      <div class="pop-body">
        <div class="caption-group clearfix">
          <i class="essential"></i>
          <span class="total">
            접속횟수 : 총
            <strong class="txt-black">{{ displayLoginCount }}</strong>
            회
          </span>
        </div>
        <div class="scroll-y" style="max-height: 500px">
          <table cellpadding="0" cellspacing="0" class="data-tb align-c" style="margin-left: 0">
            <colgroup>
              <col width="10%" />
              <!-- No -->
              <col width="" />
              <!-- 접속일시 -->
              <col width="30%" />
              <!-- 접속IP -->
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>접속일시</th>
                <th>접속IP</th>
              </tr>
            </thead>
            <tbody v-if="loginHistory.length > 0">
              <tr v-for="(history, index) in loginHistory" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ history.loginDate }}</td>
                <td>
                  {{ history.requestIp }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="6">조회 결과가 존재하지 않습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p class="txt-orange">
          <i class="icon-alert" />
          상세 정보는 최근 5회 접속 기록만 노출됩니다.
        </p>
        <div class="btn-group">
          <button type="button" class="btn darkgray" @click="onClose">닫기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'manager.login.history.popup',
  props: {
    managerId: {
      type: [Number, null],
    },
  },
  data() {
    return {
      loginHistory: [],
      loginCount: 0,
    };
  },
  computed: {
    displayLoginCount() {
      return this.$util.maskComma(this.loginCount);
    },
  },
  mounted() {
    if (this.managerId !== null) {
      this.getManagerLoginHistory();
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    async getManagerLoginHistory() {
      try {
        const { data } = await this.$http.get(`/v2/manager/login-history/${this.managerId}`, {
          params: { isloading: true },
        });
        this.loginHistory = data.loginList;
        this.loginCount = data.loginCount;
      } catch (error) {
        this.$util.debug(error);
      }
    },
  },
};
</script>
