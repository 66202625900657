<template>
  <!-- 컨텐츠 영역 -->
  <div class="content m-leftmenu">
    <common-navigator></common-navigator>
    <div class="inner">
      <div class="boxing search-area">
        <dl>
          <dt>조회월</dt>
          <dd>
            <CommonDatePicker
              :formatType="formatType"
              :value="searchData.startdate"
              @change="onChangeStartDate"
            />
          </dd>
        </dl>
      </div>
      <div class="btn-group">
        <button v-if="isRead" class="btn big blue" type="button" @click="onSearch(1)">검색</button>
        <button v-if="isRead" class="btn big gray" type="button" @click="initData">초기화</button>
      </div>
      <div class="caption-group mt10 clearfix">
        <div class="btn-group fr">
          <CommonDatePicker :value="paymentDate" @change="onChangePaymentDate" />&nbsp;
          <button type="button" v-if="isWrite" class="btn blue-line" @click="changePaymentDate()">
            지급일변경
          </button>
        </div>
      </div>
      <div class="scroll-x">
        <table cellpadding="0" cellspacing="0" class="data-tb align-c">
          <caption>
            매출 마감 전표 Summary
          </caption>
          <colgroup>
            <col width="15%" />
            <!-- 구분 -->
            <col width="5%" />
            <!-- 차변 GL계정 -->
            <col width="10%" />
            <!-- 차변 GL계정명 -->
            <col width="8%" />
            <!-- 차변 금액 -->
            <col width="5%" />
            <!-- 대변 GL계정 -->
            <col width="10%" />
            <!-- 대변 GL계정명 -->
            <col width="8%" />
            <!-- 대변 금액 -->
            <col width="10%" />
            <!-- 전표번호 -->
            <!--col width="8%" /-->
            <!-- 세금계산서 발행여부 -->
            <col width="5%" />
            <!-- 상세보기 -->
          </colgroup>
          <thead>
            <tr>
              <th rowspan="3">구분</th>
              <th colspan="6">분개</th>
              <th rowspan="3">전표번호</th>
              <!--th rowspan="3">세금계산서<br />발행여부</th-->
              <th rowspan="3">상세보기</th>
            </tr>
            <tr>
              <th colspan="3">차변</th>
              <th colspan="3">대변</th>
            </tr>
            <tr>
              <th>GL계정</th>
              <th>GL계정명</th>
              <th>금액</th>
              <th>GL계정</th>
              <th>GL계정명</th>
              <th>금액</th>
            </tr>
          </thead>
          <tbody v-if="!this.$util.isNull(resultdata)">
            <tr>
              <td>수수료매출</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colspan="2">
                <button
                  v-if="isWrite && resultdata.salesnotsendcnt > 0"
                  class="btn blue-line"
                  type="button"
                  @click="sendToSap('DOT001')"
                >
                  전송
                </button>
                <button
                  v-if="false"
                  class="btn blue-line"
                  type="button"
                  @click="cancelSendingToSap('DOT001')"
                >
                  전송취소
                </button>
              </td>
            </tr>
            <tr>
              <td rowspan="2"></td>
              <td rowspan="2">11090533</td>
              <td rowspan="2">외상매출금-온라인-기타</td>
              <td rowspan="2" class="right">{{ $util.maskComma(resultdata.salessglamount) }}</td>
              <td>41010105</td>
              <td>매출액-상품매출-수탁판매수수료</td>
              <td class="right">{{ $util.maskComma(resultdata.saleshglamount1) }}</td>
              <td rowspan="2">{{ resultdata.salesdocno }}</td>
              <td rowspan="2">
                <button
                  v-if="isRead"
                  class="btn blue-line"
                  type="button"
                  @click="showDetailPopup(resultdata.calcmonth, 'DOT001')"
                >
                  보기
                </button>
              </td>
            </tr>
            <tr>
              <td>21090501</td>
              <td>예수금-부가세</td>
              <td class="right">{{ $util.maskComma(resultdata.saleshglamount2) }}</td>
            </tr>
            <tr>
              <td>할인 쿠폰</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colspan="2">
                <button
                  v-if="isWrite && resultdata.cpnnotsendcnt > 0"
                  class="btn blue-line"
                  type="button"
                  @click="sendToSap('DOT002')"
                >
                  전송
                </button>
                <button
                  v-if="false"
                  class="btn blue-line"
                  type="button"
                  @click="cancelSendingToSap('DOT002')"
                >
                  전송취소
                </button>
              </td>
            </tr>
            <tr>
              <td rowspan="2" class="right">당사부담 상품쿠폰</td>
              <td>51250599</td>
              <td>기타판촉비-기타</td>
              <td class="right">{{ $util.maskComma(resultdata.cpnsglamount1) }}</td>
              <td rowspan="2">21030101</td>
              <td rowspan="2">미지급금-일반</td>
              <td rowspan="2" class="right">{{ $util.maskComma(resultdata.cpnhglamount) }}</td>
              <td rowspan="2">{{ resultdata.cpndocno }}</td>
              <td rowspan="2">
                <button
                  v-if="isRead"
                  class="btn blue-line"
                  type="button"
                  @click="showDetailPopup(resultdata.calcmonth, 'DOT002')"
                >
                  보기
                </button>
              </td>
            </tr>
            <tr>
              <td>11170301</td>
              <td>선급제세-부가세</td>
              <td class="right">{{ $util.maskComma(resultdata.cpnsglamount2) }}</td>
            </tr>
            <tr>
              <td>할인 쿠폰</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colspan="2">
                <button
                  v-if="isWrite && resultdata.basketnotsendcnt > 0"
                  class="btn blue-line"
                  type="button"
                  @click="sendToSap('DOT003')"
                >
                  전송
                </button>
                <button
                  v-if="false"
                  class="btn blue-line"
                  type="button"
                  @click="cancelSendingToSap('DOT003')"
                >
                  전송취소
                </button>
              </td>
            </tr>
            <tr>
              <td rowspan="2" class="right">당사부담 장바구니쿠폰</td>
              <td>51250599</td>
              <td>기타판촉비-기타</td>
              <td class="right">{{ $util.maskComma(resultdata.basketsglamount1) }}</td>
              <td rowspan="2">21030101</td>
              <td rowspan="2">미지급금-일반</td>
              <td rowspan="2" class="right">{{ $util.maskComma(resultdata.baskethglamount) }}</td>
              <td rowspan="2">{{ resultdata.basketdocno }}</td>
              <td rowspan="2">
                <button
                  v-if="isRead"
                  class="btn blue-line"
                  type="button"
                  @click="showDetailPopup(resultdata.calcmonth, 'DOT003')"
                >
                  보기
                </button>
              </td>
            </tr>
            <tr>
              <td>11170301</td>
              <td>선급제세-부가세</td>
              <td class="right">{{ $util.maskComma(resultdata.basketsglamount2) }}</td>
            </tr>
            <tr>
              <td>적립금</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colspan="2">
                <button
                  v-if="isWrite && resultdata.rewardnotsendcnt > 0"
                  class="btn blue-line"
                  type="button"
                  @click="sendToSap('DOT004')"
                >
                  전송
                </button>
                <button
                  v-if="false"
                  class="btn blue-line"
                  type="button"
                  @click="cancelSendingToSap('DOT004')"
                >
                  전송취소
                </button>
              </td>
            </tr>
            <tr>
              <td class="right">1) 적립</td>
              <td>51250599</td>
              <td>기타판촉비-기타</td>
              <td class="right">{{ $util.maskComma(resultdata.rewardhglamount1) }}</td>
              <td>21290005</td>
              <td>유동성이연수익-플랫폼적립금</td>
              <td class="right">{{ $util.maskComma(resultdata.rewardsglamount1) }}</td>
              <td>{{ resultdata.rewarddocno1 }}</td>
              <td rowspan="3">
                <button
                  v-if="isRead"
                  class="btn blue-line"
                  type="button"
                  @click="showDetailPopup(resultdata.calcmonth, 'DOT004')"
                >
                  보기
                </button>
              </td>
            </tr>
            <tr>
              <td class="right">2) 소멸</td>
              <td>21290005</td>
              <td>유동성이연수익-플랫폼적립금</td>
              <td class="right">{{ $util.maskComma(resultdata.rewardhglamount2) }}</td>
              <td>61052301</td>
              <td>영업외수익-잡이익</td>
              <td class="right">{{ $util.maskComma(resultdata.rewardsglamount2) }}</td>
              <td>{{ resultdata.rewarddocno2 }}</td>
            </tr>
            <tr>
              <td class="right">3) 차감</td>
              <td>21290005</td>
              <td>유동성이연수익-플랫폼적립금</td>
              <td class="right">{{ $util.maskComma(resultdata.rewardhglamount3) }}</td>
              <td>21030199</td>
              <td>미지급비용-일반</td>
              <td class="right">{{ $util.maskComma(resultdata.rewardsglamount3) }}</td>
              <td>{{ resultdata.rewarddocno3 }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="10">조회 결과가 존재하지 않습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <MonthlySalesClosingDetail
      v-if="detail"
      v-bind:dtlData="dtlData"
      v-on:closeDetailPopup="closeDetailPopup"
    ></MonthlySalesClosingDetail>
  </div>
  <!-- /컨텐츠 영역 -->
</template>

<script>
import CommonNavigator from '@views.admin/common/CommonNavigator';
import CommonDatePicker from '@views.admin/common/CommonDatePicker';
import MonthlySalesClosingDetail from '@views.admin/adjust/estimate/MonthlySalesClosingDetail';

export default {
  name: 'admin.adjust.estimate',
  components: {
    MonthlySalesClosingDetail,
    CommonNavigator,
    CommonDatePicker,
  },
  data() {
    return {
      searchData: {
        startdate: '',
      },
      commonCode: {
        doctype: [], //전표유형
      },
      formatType: 'month',
      resultdata: null,
      isRead: false,
      isWrite: false,
      detail: false,
      calcmonth: '',
      dtlData: Object,
      batchResult: null,
      paymentDate: null,
    };
  },
  mounted() {
    this.$http
      .post('/admin/common/pageAuth/check', { url: this.$options.name, isloading: false })
      .then((result) => {
        this.isRead = result.data.isread === 'T';
        this.isWrite = result.data.iswrite === 'T';

        if (this.isRead) {
          this.initData();
          let params = { cmclass: 'DOCTYPE' };
          this.$http
            .post('/common/code/list', params)
            .then((result) => {
              if (result.statusCode === 200) {
                this.commonCode.doctype = result.data.list;
              }
            })
            .catch((error) => {
              this.$util.debug(error);
            });
        }
      });
  },
  methods: {
    initData() {
      this.searchData = this.$options.data().searchData;
      let startDate = this.$util.getAddMonth(this.$util.getDate(''), -1, '-');

      this.searchData.startdate = startDate.substring(0, 7);
      this.paymentDate = this.$util.getMonthLastDate(this.$util.getDate(''), '-');
    },
    onSearch(page) {
      let param = this.searchData;
      param.isloading = true;

      this.$http
        .post('/admin/adjust/monthly-sales-closing', param)
        .then((result) => {
          this.resultdata = result.data;
          this.calcmonth = this.resultdata.calcmonth;
          this.$util.dataSetSearchParam(this);
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    onChangeStartDate(val) {
      this.searchData.startdate = val;
    },
    showDetailPopup(calcmonth, doctype) {
      this.dtlData = { calcmonth: calcmonth, doctype: doctype };
      this.detail = true;
    },
    closeDetailPopup: function () {
      this.detail = false;
    },
    sendToSap(docType) {
      let param = { doctype: docType, calcmonth: this.searchData.startdate.replace('-', '') };

      this.$http
        .post('/batch/settlement/send-fi-to-sap', param)
        .then((result) => {
          this.batchResult = result.data.data;
          alert(this.batchResult);
          this.onSearch(1);
          //this.$util.dataSetSearchParam(this);
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    cancelSendingToSap() {},
    onChangePaymentDate(obj) {
      this.paymentDate = obj;
    },
    changePaymentDate() {
      if (this.$util.isEmpty(this.resultdata)) {
        alert('조회 후 지급일을 변경하시기 바랍니다.');
        return;
      }

      let param = { calcmonth: this.calcmonth, paymentdate: this.paymentDate.replaceAll('-', '') };

      alert(JSON.stringify(param));
      this.$http
        .post('/admin/adjust/change-payment-date', param)
        .then((result) => {
          this.batchResult = result.data.data;
          this.onSearch(1);
          //this.$util.dataSetSearchParam(this);
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
  },
};
</script>

<style scoped />
