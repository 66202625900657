<template>
  <div class="page-navigator" v-if="pagingData.totalPageCount > 0">
    <ul class="page-navigator-wrap">
      <li>
        <button class="arrow left" @click="prev"></button>
      </li>
      <li v-for="page in pages" :key="page.pageNumber">
        <button @click="goTo(page.pageNumber)" :class="{ active: page.isActive }">
          {{ page.pageNumber }}
        </button>
      </li>
      <li>
        <button class="arrow right" @click="next"></button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CommonNewPageNavigator',
  props: {
    pagingData: {
      pageNumber: Number,
      pageSize: Number,
      totalPageCount: Number,
    },
  },
  data() {
    return {
      pages: [],
    };
  },
  computed: {},
  mounted() {
    this.updateNavigator(this.pagingData?.pageNumber || 1);
  },
  methods: {
    prev() {
      this.goTo(this.pagingData.pageNumber - 1);
    },
    next() {
      this.goTo(this.pagingData.pageNumber + 1);
    },
    goTo(page) {
      const { pageSize, totalPageCount } = this.pagingData;
      if (page < 1 || page > totalPageCount) {
        return;
      }

      this.updateNavigator(page);
      this.$emit('changePage', {
        pageNumber: page,
        pageSize,
        totalPageCount,
      });
    },
    updateNavigator(currentPage) {
      const { totalPageCount } = this.pagingData;
      const pages = Array.apply(null, Array(totalPageCount)).map((value, index) => {
        return {
          pageNumber: index + 1,
          isActive: currentPage === index + 1,
        };
      });

      this.pages = pages;
    },
  },
  watch: {
    pagingData(value) {
      this.updateNavigator(value.pageNumber);
    },
  },
};
</script>

<style scoped>
.page-navigator-wrap {
  display: flex;
  justify-content: center;
}

.page-navigator-wrap button {
  border: none;
  margin: 0;
  padding: 0;
  overflow: visible;
  background: transparent;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #999;
  box-sizing: border-box;
  vertical-align: middle;
}

.page-navigator-wrap li button.arrow.left {
  background: url('../../../assets/img/paging-left.png') center center no-repeat;
}

.page-navigator-wrap li button.arrow.right {
  background: url('../../../assets/img/paging-right.png') center center no-repeat;
}

.page-navigator-wrap li button.active {
  color: #fff;
  font-weight: 700;
  background: #167cd8;
  border: 1px solid #0c68bb;
  border-radius: 3px;
}
</style>
