import { render, staticRenderFns } from "./MainPopupDetail.vue?vue&type=template&id=2213cee0&"
import script from "./MainPopupDetail.vue?vue&type=script&lang=js&"
export * from "./MainPopupDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildagent/work/3684aff05d5d78ea/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2213cee0')) {
      api.createRecord('2213cee0', component.options)
    } else {
      api.reload('2213cee0', component.options)
    }
    module.hot.accept("./MainPopupDetail.vue?vue&type=template&id=2213cee0&", function () {
      api.rerender('2213cee0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/operation/setting/popup/MainPopupDetail.vue"
export default component.exports