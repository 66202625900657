import { render, staticRenderFns } from "./PartnershipAuthNew.vue?vue&type=template&id=624051e4&scoped=true&"
import script from "./PartnershipAuthNew.vue?vue&type=script&lang=js&"
export * from "./PartnershipAuthNew.vue?vue&type=script&lang=js&"
import style0 from "@assets/css/partnership.css?vue&type=style&index=0&id=624051e4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "624051e4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildagent/work/3684aff05d5d78ea/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('624051e4')) {
      api.createRecord('624051e4', component.options)
    } else {
      api.reload('624051e4', component.options)
    }
    module.hot.accept("./PartnershipAuthNew.vue?vue&type=template&id=624051e4&scoped=true&", function () {
      api.rerender('624051e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/partners/PartnershipAuthNew.vue"
export default component.exports