var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "top-menu-p" }, [
    _c("div", { staticClass: "top clearfix" }, [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          staticClass: "link",
          attrs: { src: require("../../assets/img/logo.png"), alt: "D.PLOT" },
          on: { click: _vm.goMain },
        }),
        _c("span", { staticClass: "left-name" }, [
          _vm._v(_vm._s(_vm.companyName)),
        ]),
      ]),
      _c("ul", { staticClass: "user" }, [
        _c("li", { staticClass: "name-info" }, [
          _c("span", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.managerName)),
          ]),
          _vm._v(" 님, 안녕하세요. "),
        ]),
        _c("li", { staticClass: "btns" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-outline-light",
              attrs: { href: "javascript:void(0);" },
              on: { click: _vm.goAccessHistoryPopup },
            },
            [_vm._v(" 접속기록보기 ")]
          ),
          _vm.isMainManagerType
            ? _c(
                "a",
                {
                  staticClass: "btn btn-outline-light",
                  attrs: { href: "javascript:void(0);" },
                  on: { click: _vm.goInfo },
                },
                [_vm._v(" 정보수정 ")]
              )
            : _vm._e(),
          _c(
            "a",
            {
              staticClass: "btn btn-outline-light",
              attrs: { href: "javascript:void(0);" },
              on: { click: _vm.goFrontMain },
            },
            [_vm._v(" 쇼핑몰메인 ")]
          ),
          _c(
            "a",
            {
              staticClass: "btn btn-dark",
              attrs: { href: "javascript:void(0);" },
              on: { click: _vm.logOut },
            },
            [_vm._v("로그아웃")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }