var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content m-leftmenu" },
    [
      _c("common-navigator"),
      _vm.showList
        ? _c("RecomRewardLog", { on: { closeList: _vm.closeList } })
        : _vm._e(),
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "boxing" }, [
          _c("div", { staticClass: "form-area" }, [
            _c("dl", [
              _c("dt", [_vm._v("사용여부")]),
              _c("dd", [
                _c("div", { staticClass: "radio_wrap wide" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.info.istrash,
                        expression: "info.istrash",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "group00",
                      id: "group01",
                      value: "F",
                      checked: "",
                    },
                    domProps: { checked: _vm._q(_vm.info.istrash, "F") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.info, "istrash", "F")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "group01" } }, [
                    _vm._v("사용함"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.info.istrash,
                        expression: "info.istrash",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "group00",
                      id: "group02",
                      value: "T",
                    },
                    domProps: { checked: _vm._q(_vm.info.istrash, "T") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.info, "istrash", "T")
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "group02" } }, [
                    _vm._v("사용안함"),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "bar-title small" }, [_vm._v("조건설정")]),
        _c("div", { staticClass: "form-area" }, [
          _c(
            "table",
            {
              staticClass: "gray-tb",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _vm._m(1),
                  _c(
                    "td",
                    [
                      _c("CommonDatePickerFromTo", {
                        attrs: {
                          fromYYYYMMDD: _vm.info.startdate,
                          fromHH: _vm.info.starthour,
                          fromMM: _vm.info.startmi,
                          toYYYYMMDD: _vm.info.enddate,
                          toHH: _vm.info.endhour,
                          toMM: _vm.info.endmi,
                          useFrom: true,
                          useTo: true,
                        },
                        on: { getDate: _vm.pickerEventBus },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _vm._m(2),
                  _c("td", [
                    _c("div", { staticClass: "radio_wrap wide3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.recomtype,
                            expression: "info.recomtype",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "group01",
                          id: "group11",
                          value: "RCT001",
                          checked: "",
                        },
                        domProps: {
                          checked: _vm._q(_vm.info.recomtype, "RCT001"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.info, "recomtype", "RCT001")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "group11" } }, [
                        _vm._v("적립금"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.recomtype,
                            expression: "info.recomtype",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "group01",
                          id: "group12",
                          value: "RCT002",
                        },
                        domProps: {
                          checked: _vm._q(_vm.info.recomtype, "RCT002"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.info, "recomtype", "RCT002")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "group12" } }, [
                        _vm._v("D포인트"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.recomtype,
                            expression: "info.recomtype",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "group01",
                          id: "group13",
                          value: "RCT003",
                        },
                        domProps: {
                          checked: _vm._q(_vm.info.recomtype, "RCT003"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.info, "recomtype", "RCT003")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "group13" } }, [
                        _vm._v("쿠폰"),
                      ]),
                    ]),
                  ]),
                ]),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.info.recomtype === "RCT001" ||
                          _vm.info.recomtype === "RCT002",
                        expression:
                          "info.recomtype === 'RCT001' || info.recomtype === 'RCT002'",
                      },
                    ],
                  },
                  [
                    _vm._m(3),
                    _c("td", [
                      _c("span", [
                        _vm._v(
                          "추천 받고 가입한 회원(피추천인)에게 " +
                            _vm._s(
                              _vm.info.recomtype === "RCT001" ? "적립금 " : ""
                            ) +
                            _vm._s(
                              _vm.info.recomtype === "RCT002" ? "D포인트 " : ""
                            )
                        ),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.revpoint,
                            expression: "info.revpoint",
                          },
                        ],
                        staticClass: "center",
                        staticStyle: { width: "60px" },
                        attrs: { type: "text" },
                        domProps: { value: _vm.info.revpoint },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.info, "revpoint", $event.target.value)
                          },
                        },
                      }),
                      _c("span", [_vm._v("포인트를 회원가입 완료 후 지급")]),
                    ]),
                  ]
                ),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.info.recomtype === "RCT003",
                        expression: "info.recomtype === 'RCT003'",
                      },
                    ],
                  },
                  [
                    _vm._m(4),
                    _c("td", [
                      _c("span", [
                        _vm._v("추천 받고 가입한 회원(피추천인)에게"),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.info.revcpidx,
                              expression: "info.revcpidx",
                            },
                          ],
                          staticStyle: { width: "450px" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.info,
                                "revcpidx",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.couponlist, function (item) {
                          return _c(
                            "option",
                            {
                              key: item.comcpnidx,
                              domProps: { value: item.comcpnidx },
                            },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          )
                        }),
                        0
                      ),
                      _c("span", [_vm._v("쿠폰을 회원가입 완료 후 지급")]),
                    ]),
                  ]
                ),
                _c("tr", [
                  _vm._m(5),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.isjoingive,
                          expression: "info.isjoingive",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "group21",
                        "true-value": "T",
                        "false-value": "F",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.info.isjoingive)
                          ? _vm._i(_vm.info.isjoingive, null) > -1
                          : _vm._q(_vm.info.isjoingive, "T"),
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.info.isjoingive,
                            $$el = $event.target,
                            $$c = $$el.checked ? "T" : "F"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info,
                                  "isjoingive",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info,
                                  "isjoingive",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.info, "isjoingive", $$c)
                          }
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "group21" } }, [
                      _vm._v("피추천인 회원가입 시 지급"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.iscfmgive,
                          expression: "info.iscfmgive",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "group22",
                        "true-value": "T",
                        "false-value": "F",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.info.iscfmgive)
                          ? _vm._i(_vm.info.iscfmgive, null) > -1
                          : _vm._q(_vm.info.iscfmgive, "T"),
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.info.iscfmgive,
                            $$el = $event.target,
                            $$c = $$el.checked ? "T" : "F"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info,
                                  "iscfmgive",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info,
                                  "iscfmgive",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.info, "iscfmgive", $$c)
                          }
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "group22" } }, [
                      _vm._v("피추천인이 첫 구매확정 시 지급"),
                    ]),
                  ]),
                ]),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.info.recomtype === "RCT001" ||
                          _vm.info.recomtype === "RCT002",
                        expression:
                          "info.recomtype === 'RCT001' || info.recomtype === 'RCT002'",
                      },
                    ],
                  },
                  [
                    _vm._m(6),
                    _c("td", [
                      _c("span", [
                        _vm._v(
                          "추천한 기존회원(추천인)에게 " +
                            _vm._s(
                              _vm.info.recomtype === "RCT001" ? "적립금 " : ""
                            ) +
                            _vm._s(
                              _vm.info.recomtype === "RCT002" ? "D포인트 " : ""
                            )
                        ),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.recjoinpoint,
                            expression: "info.recjoinpoint",
                          },
                        ],
                        staticClass: "center",
                        staticStyle: { width: "60px" },
                        attrs: { type: "text" },
                        domProps: { value: _vm.info.recjoinpoint },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.info,
                              "recjoinpoint",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("span", [_vm._v("포인트 지급")]),
                    ]),
                  ]
                ),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.info.recomtype === "RCT001" ||
                          _vm.info.recomtype === "RCT002",
                        expression:
                          "info.recomtype === 'RCT001' || info.recomtype === 'RCT002'",
                      },
                    ],
                  },
                  [
                    _vm._m(7),
                    _c("td", [
                      _c("span", [
                        _vm._v(
                          "추천한 기존회원(추천인)에게 " +
                            _vm._s(
                              _vm.info.recomtype === "RCT001" ? "적립금 " : ""
                            ) +
                            _vm._s(
                              _vm.info.recomtype === "RCT002" ? "D포인트 " : ""
                            )
                        ),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.reccfmpoint,
                            expression: "info.reccfmpoint",
                          },
                        ],
                        staticClass: "center",
                        staticStyle: { width: "60px" },
                        attrs: { type: "text" },
                        domProps: { value: _vm.info.reccfmpoint },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.info,
                              "reccfmpoint",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("span", [_vm._v("포인트 지급")]),
                    ]),
                  ]
                ),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.info.recomtype === "RCT002",
                        expression: "info.recomtype === 'RCT002'",
                      },
                    ],
                  },
                  [
                    _vm._m(8),
                    _c("td", [
                      _c("div", { staticClass: "radio_wrap wide dpib" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.info.isepointdup,
                              expression: "info.isepointdup",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "group03",
                            id: "group31",
                            value: "T",
                            checked: "",
                          },
                          domProps: {
                            checked: _vm._q(_vm.info.isepointdup, "T"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.info, "isepointdup", "T")
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "group31" } }, [
                          _vm._v("허용"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.info.isepointdup,
                              expression: "info.isepointdup",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "group03",
                            id: "group32",
                            value: "F",
                          },
                          domProps: {
                            checked: _vm._q(_vm.info.isepointdup, "F"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.info, "isepointdup", "F")
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "group32" } }, [
                          _vm._v("미허용"),
                        ]),
                      ]),
                      _vm._m(9),
                    ]),
                  ]
                ),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.info.recomtype === "RCT002",
                        expression: "info.recomtype === 'RCT002'",
                      },
                    ],
                  },
                  [
                    _vm._m(10),
                    _c("td", [
                      _vm._v(
                        " D포인트 적립금 프로모션을 통해 지급되는 D포인트의 유효기간은 진행기간과 동일하게 자동 설정됩니다. "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.info.recomtype === "RCT003",
                        expression: "info.recomtype === 'RCT003'",
                      },
                    ],
                  },
                  [
                    _vm._m(11),
                    _c("td", [
                      _c("span", [_vm._v("추천한 기존회원(추천인)에게")]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.info.recjoincpidx,
                              expression: "info.recjoincpidx",
                            },
                          ],
                          staticStyle: { width: "450px" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.info,
                                "recjoincpidx",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.couponlist, function (item) {
                          return _c(
                            "option",
                            {
                              key: item.comcpnidx,
                              domProps: { value: item.comcpnidx },
                            },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          )
                        }),
                        0
                      ),
                      _c("span", [_vm._v("쿠폰을 지급")]),
                    ]),
                  ]
                ),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.info.recomtype === "RCT003",
                        expression: "info.recomtype === 'RCT003'",
                      },
                    ],
                  },
                  [
                    _vm._m(12),
                    _c("td", [
                      _c("span", [_vm._v("추천한 기존회원(추천인)에게")]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.info.reccfmcpidx,
                              expression: "info.reccfmcpidx",
                            },
                          ],
                          staticStyle: { width: "450px" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.info,
                                "reccfmcpidx",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.couponlist, function (item) {
                          return _c(
                            "option",
                            {
                              key: item.comcpnidx,
                              domProps: { value: item.comcpnidx },
                            },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          )
                        }),
                        0
                      ),
                      _c("span", [_vm._v("쿠폰을 지급")]),
                    ]),
                  ]
                ),
                _c("tr", [
                  _vm._m(13),
                  _c("td", [
                    _c("span", [
                      _vm._v(
                        "1명의 회원이 최대한 추천 받을 수 있는 회원의 수를 "
                      ),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.reclimitcnt,
                          expression: "info.reclimitcnt",
                        },
                      ],
                      staticClass: "center",
                      staticStyle: { width: "60px" },
                      attrs: { type: "text" },
                      domProps: { value: _vm.info.reclimitcnt },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.info, "reclimitcnt", $event.target.value)
                        },
                      },
                    }),
                    _c("span", [_vm._v("명으로 제한")]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "bottom-group" }, [
          _c("div", { staticClass: "btn-group left" }, [
            _vm.isRead
              ? _c(
                  "button",
                  {
                    staticClass: "btn big blue-line",
                    attrs: { type: "button" },
                    on: { click: _vm.searchRewardList },
                  },
                  [_vm._v(" 이력조회 ")]
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "btn-group" }, [
            _vm.isWrite
              ? _c(
                  "button",
                  {
                    staticClass: "btn big blue",
                    attrs: { type: "button" },
                    on: { click: _vm.onSave },
                  },
                  [_vm._v("저장")]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("진행기간"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("혜택 구분"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("피추천인 지급"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("피추천인 지급"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("추천인 지급 조건"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("추천인 지급(회원가입)"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("추천인 지급(첫구매확정)"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("D포인트 중복사용여부"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "txt-orange ml10" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v("적립금과 함께 사용 가능한지의 여부"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("D포인트 유효기간"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("추천인 지급(회원가입)"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("추천인 지급(첫구매확정)"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("피추천인 수 제한"),
      _c("i", { staticClass: "essential" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }