<template>
  <div>
    <div class="visual">
      <h1>입 / 점 / 신 / 청</h1>
      <p>성공적인 비즈니스 파트너! D.PLOT과 함께하세요!</p>
    </div>
    <!-- 컨텐츠 영역 -->
    <div class="join-content">
      <div class="flow clearfix">
        <ul>
          <li class="on">
            <span>Step 1.</span>
            <p>입점절차 및 사업자 인증</p>
          </li>
          <li>
            <span>Step 2.</span>
            <p>정보입력</p>
          </li>
          <li>
            <span>Step 3.</span>
            <p>신청완료</p>
          </li>
        </ul>
      </div>
      <h2 class="title">입점절차</h2>
      <p>아래는 D.PLOT 에서 판매 활동을 하기 위한 입점 절차입니다.</p>
      <div class="diagram">
        <ul class="clearfix">
          <li><p>사업자 인증</p></li>
          <li><p>사업자 정보 입력</p></li>
          <li><p>신청완료</p></li>
          <li><p>입점검토</p></li>
          <li><p>입점완료</p></li>
        </ul>
      </div>
      <h2 class="title">사업자인증</h2>
      <p>
        - 사업자 등록번호가 있는 사업자에 한해 파트너사 신청이 가능합니다.<br />
        - 인증이 되지 않을 경우 고객센터로 문의 바랍니다. (1666-3642)
      </p>
      <div class="boxing">
        <div class="form-area middle">
          <dl>
            <dt>상호명</dt>
            <dd>
              <input
                ref="bizName"
                v-model.trim="bizAuthInfo.bizName"
                :readonly="isBizAuth"
                style="width: 339px"
                type="text"
              />
            </dd>
          </dl>
          <dl>
            <dt>사업자 번호</dt>
            <dd>
              <input
                ref="bizNum"
                v-model.trim="bizAuthInfo.bizNum"
                :readonly="isBizAuth"
                maxlength="3"
                style="width: 95px"
                type="text"
                data-seq="1"
                @keyup="checkNumber"
              /><span>-</span>
              <input
                ref="bizNum2"
                v-model.trim="bizAuthInfo.bizNum2"
                :readonly="isBizAuth"
                maxlength="2"
                style="width: 90px"
                type="text"
                data-seq="2"
                @keyup="checkNumber"
              /><span>-</span>
              <input
                ref="bizNum3"
                v-model.trim="bizAuthInfo.bizNum3"
                :readonly="isBizAuth"
                maxlength="5"
                style="width: 132px"
                type="text"
                data-seq="3"
                @keyup="checkNumber"
              />
            </dd>
          </dl>
          <dl>
            <dt>대표자명</dt>
            <dd>
              <input
                ref="ownerName"
                v-model.trim="bizAuthInfo.ownerName"
                :readonly="isBizAuth"
                style="width: 339px"
                type="text"
              />
            </dd>
          </dl>
          <dl>
            <dt>영업시작일</dt>
            <dd>
              <input
                ref="salesStartYear"
                v-model.trim="bizAuthInfo.salesStartYear"
                :readonly="isBizAuth"
                maxlength="4"
                placeholder="년(4자)"
                style="width: 117px"
                type="text"
                @input="handleCheckYear"
              />
              <input
                ref="salesStartMonth"
                v-model.trim="bizAuthInfo.salesStartMonth"
                :readonly="isBizAuth"
                maxlength="2"
                placeholder="월"
                style="width: 65px"
                type="text"
                @input="handleCheckMonth"
              />
              <input
                ref="salesStartDate"
                v-model.trim="bizAuthInfo.salesStartDate"
                :readonly="isBizAuth"
                maxlength="2"
                placeholder="일"
                style="width: 65px"
                type="text"
                @input="handleCheckDate"
              />
              <button
                :class="isBizAuth ? 'red-line' : 'blue-line'"
                :disabled="isBizAuth"
                class="btn"
                type="button"
                @click="checkBizAuth"
              >
                {{ isBizAuth ? '인증완료' : '사업자인증' }}
              </button>
            </dd>
          </dl>
        </div>
      </div>
      <div class="btn-group">
        <button class="btn big blue" type="button" @click="goNext">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import storage from '@js/storage';
import store from '@js/store';
import util from '@js/util';
const { VUE_APP_ENV } = process.env;
const isDev = VUE_APP_ENV === 'local' || VUE_APP_ENV === 'dev';
const DEFAULT_BIZ_AUTH_INFO = {
  bizName: '',
  bizNum: '',
  bizNum2: '',
  bizNum3: '',
  ownerName: '',
  salesStartYear: '',
  salesStartMonth: '',
  salesStartDate: '',
};
export default {
  name: 'PartnershipAuthNew',
  data() {
    return {
      bizAuthInfo: {
        ...DEFAULT_BIZ_AUTH_INFO,
      },
      isBizAuth: false,
    };
  },
  watch: {
    'bizAuthInfo.salesStartYear': {
      handler(val) {
        if (val.length === 4) {
          this.$refs['salesStartMonth'].focus();
        }
      },
      deep: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    if (util.isAuthorized(store.getters.CONSTANTS.MANAGER_SESSION)) {
      next('partners.login');
      return;
    }
    next();
  },
  mounted() {
    const { BIZ_AUTH_INFO } = this.$store.getters.CONSTANTS.STORAGE_KEYS;
    const sessionAuthInfo = storage.getSessionStorage(BIZ_AUTH_INFO);
    if (sessionAuthInfo) {
      this.isBizAuth = true;
      this.bizAuthInfo = { ...sessionAuthInfo };
    }
  },
  // 참고 https://v3.router.vuejs.org/kr/guide/advanced/navigation-guards.html
  methods: {
    setBizInfo(exist) {
      this.isBizAuth = exist;
      const { BIZ_AUTH_INFO } = this.$store.getters.CONSTANTS.STORAGE_KEYS;
      storage.setSessionStorage(BIZ_AUTH_INFO, this.bizAuthInfo);
    },
    debounceCheckBizAuth: debounce(
      async function () {
        try {
          const paramMsgForKey = Object.freeze({
            bizName: '상호명',
            bizNum: '사업자번호',
            bizNum2: '사업자번호',
            bizNum3: '사업자번호',
            ownerName: '대표자명',
            salesStartYear: '영업시작일',
            salesStartMonth: '영업시작일',
            salesStartDate: '영업시작일',
          });
          let isBreak = false;
          Object.entries(this.bizAuthInfo).every(([key, value]) => {
            if (key === 'businessStartDate') {
              return true;
            }
            if (!value || value.trim() === '') {
              typeof this.$refs[key] !== 'undefined' && this.$refs[key].focus();
              isBreak = true;
              alert('필수값 ' + paramMsgForKey[key] + ' 값이 입력되지 않았습니다.');
              return false;
            }
            return true;
          });

          if (isBreak) {
            return;
          }
          const salesStartMonthWithZero =
            Number(this.bizAuthInfo.salesStartMonth) < 10
              ? `0${Number(this.bizAuthInfo.salesStartMonth)}`
              : this.bizAuthInfo.salesStartMonth;
          const salesStartDateWithZero =
            Number(this.bizAuthInfo.salesStartDate) < 10
              ? `0${Number(this.bizAuthInfo.salesStartDate)}`
              : this.bizAuthInfo.salesStartDate;
          this.bizAuthInfo.businessStartDate = `${this.bizAuthInfo.salesStartYear}-${salesStartMonthWithZero}-${salesStartDateWithZero}`;

          const { VUE_APP_API_V2_PREFIX } = process.env;
          const { bizName, bizNum, bizNum2, bizNum3, ownerName, businessStartDate } =
            this.bizAuthInfo;

          const queryObj = {
            bizName,
            bizNum,
            bizNum2,
            bizNum3,
            ownerName,
            businessStartDate,
          };
          const params = {
            isloading: true,
          };
          const queryString = `?${new URLSearchParams(queryObj).toString()}`;
          const apiUrl = `${VUE_APP_API_V2_PREFIX}/partner/entry/sign-up/check-business-cert${queryString}`;
          const res = await this.$http.get(apiUrl, { params });
          const { data: exist } = res;
          if (!exist) {
            throw new Error('사업자 인증에 실패하였습니다.');
          }

          this.setBizInfo(exist);
        } catch (err) {
          alert(err.message);
        }
      },
      500,
      { leading: true, trailing: false },
    ),
    checkBizAuth() {
      this.debounceCheckBizAuth();
    },
    goNext: function () {
      if (!this.isBizAuth) {
        alert('사업자 인증이 필요합니다.');
        return;
      }
      this.$router.push({ name: 'common.new.partnership.join', query: this.$route.query });
    },
    // 사업자 번호를 양식에 맞게 채우면 다음 입력 input으로 포커스 이동
    moveFocusBusinessNumber(seq, value) {
      if (!seq || !value) {
        return;
      }
      if (seq === 1) {
        value.length >= 3 && this.$refs.bizNum2?.focus();
      } else if (seq === 2) {
        value.length >= 2 && this.$refs.bizNum3?.focus();
      } else if (seq === 3) {
        value.length >= 5 && this.$refs.ownerName?.focus();
      }
    },
    checkNumber(event) {
      const { value, dataset } = event.target;
      const { seq } = dataset;
      const seqToNumber = Number(seq);
      const temp = value.replaceAll(' ', '').replace(/[^\d]/g, '');
      event.target.value = temp;

      this.moveFocusBusinessNumber(seqToNumber, temp);
    },
    checkedMinMaxText({ max, min = 0, value }) {
      if (!value) {
        return '';
      }

      let temp = value.replace(/[^\d]/g, '');
      if (Number(temp) > max) {
        temp = max;
      } else if (Number(temp) < min) {
        temp = min;
      }
      return temp;
    },
    handleCheckYear(e) {
      const { value } = e.target;
      if (!value) {
        return;
      }
      let temp = value.replaceAll(/[^0-9]/g, '');
      this.bizAuthInfo.salesStartYear = temp;
    },
    handleCheckMonth(e) {
      const { value } = e.target;
      if (!value) {
        return;
      }
      const max = 12;
      const minMaxText = this.checkedMinMaxText({ max, value });
      this.bizAuthInfo.salesStartMonth = minMaxText;
    },
    handleCheckDate(e) {
      const { value } = e.target;
      if (!value) {
        return;
      }
      const max = 31;
      const minMaxText = this.checkedMinMaxText({ max, value });
      this.bizAuthInfo.salesStartDate = minMaxText;
    },
  },
};
</script>

<style scoped src="@assets/css/partnership.css"></style>
