var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "600px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("엑셀파일 정보 구성(관리자 제외)")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "check-field col3" }, [
              _c("ul", { staticClass: "clearfix" }, [
                _c("li", [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: "chk001",
                      value: "mobile,휴대폰번호",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickInput(
                          $event.target.value,
                          $event.target.checked
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "chk001" } }, [
                    _vm._v("휴대폰번호"),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: "chk002",
                      value: "couponcount,보유쿠폰수",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickInput(
                          $event.target.value,
                          $event.target.checked
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "chk002" } }, [
                    _vm._v("보유쿠폰"),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: "chk003",
                      value: "lastlogindate,최근접속일시",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickInput(
                          $event.target.value,
                          $event.target.checked
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "chk003" } }, [
                    _vm._v("최근접속일시"),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: "chk004",
                      value: "email,이메일",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickInput(
                          $event.target.value,
                          $event.target.checked
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "chk004" } }, [_vm._v("이메일")]),
                ]),
                _c("li", [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: "chk005",
                      value: "totpaypoint,총적립금",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickInput(
                          $event.target.value,
                          $event.target.checked
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "chk005" } }, [
                    _vm._v("총 적립금"),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: "chk006",
                      value: "lastbuydate,최근구매일시",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickInput(
                          $event.target.value,
                          $event.target.checked
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "chk006" } }, [
                    _vm._v("최근구매일시"),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: "chk007",
                      value: "gender,성별",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickInput(
                          $event.target.value,
                          $event.target.checked
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "chk007" } }, [_vm._v("성별")]),
                ]),
                _c("li", [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: "chk005",
                      value: "paypoint,적립금",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickInput(
                          $event.target.value,
                          $event.target.checked
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "chk005" } }, [_vm._v("적립금")]),
                ]),
                _c("li", [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: "chk009",
                      value: "frstbuydate,첫구매일자",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickInput(
                          $event.target.value,
                          $event.target.checked
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "chk009" } }, [
                    _vm._v("첫 구매일자"),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: "chk010",
                      value: "birthdate,생년월일",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickInput(
                          $event.target.value,
                          $event.target.checked
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "chk010" } }, [
                    _vm._v("생년월일"),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: "chk008",
                      value: "epoint,e포인트",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickInput(
                          $event.target.value,
                          $event.target.checked
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "chk008" } }, [
                    _vm._v("D포인트"),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: "chk012",
                      value: "memregdate,가입일자",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickInput(
                          $event.target.value,
                          $event.target.checked
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "chk012" } }, [
                    _vm._v("가입일자"),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: "chk013",
                      value: "address,배송지 주소",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickInput(
                          $event.target.value,
                          $event.target.checked
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "chk013" } }, [
                    _vm._v("배송지 주소"),
                  ]),
                ]),
                _c("li", [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: "chk011",
                      value: "payemploypoint,임직원적립금",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickInput(
                          $event.target.value,
                          $event.target.checked
                        )
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "chk011" } }, [
                    _vm._v("임직원적립금"),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "btn-group mt10" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.goDownExcel },
                },
                [_vm._v("선택 대상 적용")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }