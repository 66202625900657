<template>
  <div id="modal-wrap" class="modal" style="display: block">
    <div class="modal-content" style="width: 1200px">
      <div class="pop-header">
        <h2>{{ title }}</h2>
        <button type="button" class="pop-close" @click="onClose"></button>
      </div>
      <div class="pop-body">
        <div class="caption-group mt10 clearfix">
          <div class="btn-group fl">
            <i class="essential"></i>
            {{ summary }}
          </div>
          <div class="btn-group fr">
            <button
              v-if="isWrite"
              type="button"
              class="btn blue"
              :disabled="!isValidSendStatus"
              @click="sendEmail"
            >
              {{ sendButtonName }}
            </button>
          </div>
        </div>
        <div class="serial-coupon-list-wrap">
          <table cellpadding="0" cellspacing="0" class="data-tb align-c serial-coupon-list">
            <caption>임직원 프로모션 시리얼 쿠폰 발송 목록</caption>
            <colgroup>
              <col width="5%" />
              <!-- No -->
              <col width="" />
              <!-- Email -->
              <col width="25%" />
              <!-- 시리얼번호 -->
              <col width="10%" />
              <!-- 발송 여부 -->
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>Email</th>
                <th>시리얼번호</th>
                <th>발송 여부</th>
              </tr>
            </thead>
            <tbody v-if="serialCouponSendList.length > 0">
              <tr v-for="(item, index) in serialCouponSendList" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.email }}</td>
                <td class="serial-number-wrap">
                  <ul>
                    <li v-for="serialNumber in item.serialNumberList" :key="serialNumber">
                      <span>{{ serialNumber }}</span>
                    </li>
                  </ul>
                </td>
                <td>
                  {{ getSendStatusName(item.sendStatus) }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td :colspan="4">조회 결과가 존재하지 않습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EMPLOYEE_PROMOTION_SEND_STATUS,
  EMPLOYEE_PROMOTION_SEND_STATUS_NAME,
} from '@constants/employeePromotion';

export default {
  name: 'employee.promotion.detail.popup',
  props: {
    promotion: {
      type: Object,
      default: () => {
        return {
          id: 0,
          title: '',
        };
      },
    },
    isWrite: Boolean,
  },
  data() {
    return {
      isSendAction: false,
      sendStatus: EMPLOYEE_PROMOTION_SEND_STATUS.발송준비,
      serialCouponSendList: [],
    };
  },
  computed: {
    title() {
      return `${this.promotion?.title || '임직원 프로모션'} 상세`;
    },
    summary() {
      return `이메일 발송 대상 총: ${this.serialCouponSendList.length}명, 발송 실패 건수: 0건`;
    },
    isValidSendStatus() {
      if (this.isSendAction) {
        return false;
      }

      if (this.sendStatus === EMPLOYEE_PROMOTION_SEND_STATUS.발송완료 && !this.isSendComplete) {
        return true;
      }

      return this.sendStatus === EMPLOYEE_PROMOTION_SEND_STATUS.발송준비;
    },
    isSendComplete() {
      if (this.serialCouponSendList.length === 0) {
        return false;
      }

      return this.serialCouponSendList.every(
        ({ sendStatus }) => sendStatus === EMPLOYEE_PROMOTION_SEND_STATUS.발송완료,
      );
    },
    sendButtonName() {
      if (this.isSendAction || this.sendStatus === EMPLOYEE_PROMOTION_SEND_STATUS.발송중) {
        return '이메일 발송중';
      }

      if (this.sendStatus === EMPLOYEE_PROMOTION_SEND_STATUS.발송완료) {
        return this.isSendComplete ? '이메일 발송완료' : '이메일 재발송';
      }

      return '이메일 발송';
    },
  },
  mounted() {
    this.getPromotionDetail();
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    convertSerialCouponSendList(list) {
      return list.reduce((acc, currentValue) => {
        const { email: targetEmail, serialNumber, sendStatus } = currentValue;
        const temp = acc.find(({ email }) => email === targetEmail) || {
          email: targetEmail,
          serialNumberList: [],
          sendStatus,
        };

        temp.serialNumberList.push(serialNumber);
        return [temp, ...acc.filter(({ email }) => email !== targetEmail)];
      }, []);
    },
    getSendStatusName(sendStatus) {
      return EMPLOYEE_PROMOTION_SEND_STATUS_NAME[sendStatus] || '-';
    },
    async getPromotionDetail(isloading = true) {
      try {
        if (this.promotion?.id) {
          const { data } = await this.$http.get(
            `/v2/employee-promotion/${this.promotion.id}/email-serial`,
            {
              params: { isloading },
            },
          );

          this.sendStatus = data.sendStatus;
          this.serialCouponSendList = this.convertSerialCouponSendList(data.list);
        }
      } catch (error) {
        this.$util.debug(error);
      }
    },
    async sendEmail() {
      try {
        if (this.promotion?.id) {
          const { statusCode } = await this.$http.post(`/v2/employee-promotion/send/email`, {
            employeePromotionId: this.promotion.id,
            isloading: true,
          });

          if (statusCode === 200) {
            this.isSendAction = true;
          }
        }
      } catch (error) {
        this.$util.debug(error);
      }
    },
  },
};
</script>

<style scoped>
.serial-coupon-list-wrap {
  position: relative;
  height: 600px;
  overflow-y: scroll;
  margin-top: 10px;
}

.serial-coupon-list tbody tr td.serial-number-wrap {
  padding: 0;
}

.serial-number-wrap ul {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.serial-number-wrap ul li {
  padding: 10px 0;
}

.serial-number-wrap ul li + li {
  border-top: 1px solid #ddd;
}
</style>
