var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "560px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("송장추가")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.closePopup },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _vm._m(0),
            _c(
              "table",
              {
                staticClass: "data-tb align-c",
                attrs: { cellpadding: "0", cellspacing: "0" },
              },
              [
                _vm._m(1),
                _vm._m(2),
                _vm.info.orderDelivList.length > 0
                  ? _c(
                      "tbody",
                      _vm._l(_vm.info.orderDelivList, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.logistype,
                                    expression: "item.logistype",
                                  },
                                ],
                                attrs: {
                                  disabled:
                                    item.istracking === "T" &&
                                    _vm.$util.isNull(item.trckerrmsg),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      item,
                                      "logistype",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(
                                _vm.commonCode.logistype,
                                function (logistype) {
                                  return _c(
                                    "option",
                                    {
                                      key: logistype.cmcode,
                                      domProps: { value: logistype.cmcode },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(logistype.codename) + " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _c("td", { staticClass: "left" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.invoiceno,
                                  expression: "item.invoiceno",
                                },
                              ],
                              staticStyle: { width: "calc(100% - 58px)" },
                              attrs: {
                                type: "text",
                                placeholder: "송장번호",
                                disabled:
                                  item.istracking === "T" &&
                                  _vm.$util.isNull(item.trckerrmsg),
                                maxlength: "50",
                                oninput:
                                  "this.value = this.value.replace(/(^0|[^0-9])/gi, '');",
                              },
                              domProps: { value: item.invoiceno },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    item,
                                    "invoiceno",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("button", {
                              staticClass: "add",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.addInvoice(index)
                                },
                              },
                            }),
                            index > 0 &&
                            !(
                              item.istracking === "T" &&
                              _vm.$util.isNull(item.trckerrmsg)
                            )
                              ? _c("button", {
                                  staticClass: "del",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeInvoice(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.ordcnt,
                                  expression: "item.ordcnt",
                                },
                              ],
                              attrs: {
                                type: "text",
                                disabled:
                                  item.istracking === "T" &&
                                  _vm.$util.isNull(item.trckerrmsg),
                                maxlength: "5",
                                oninput:
                                  "this.value = this.value.replace(/(^0|[^0-9])/gi, '');",
                              },
                              domProps: { value: item.ordcnt },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(item, "ordcnt", $event.target.value)
                                },
                              },
                            }),
                          ]),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            ),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.saveInvoice },
                },
                [_vm._v("저장")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.closePopup },
                },
                [_vm._v("취소")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "blue-box mg0" }, [
      _c("ul", [
        _c("li", [
          _vm._v(
            "여러 개의 박스로 나눌 경우 송장을 추가로 입력하시기 바랍니다."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "120px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "60px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("택배사")]),
        _c("th", [_vm._v("송장번호")]),
        _c("th", [_vm._v("배송수량")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }