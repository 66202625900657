var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "content m-leftmenu" },
    [
      _c("common-navigator"),
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "category-layout" }, [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "clearfix" }, [
              _c("div", { staticClass: "bar-title fl" }, [
                _vm._v("전시 카테고리 설정"),
              ]),
              _c("div", { staticClass: "btn-group fr" }, [
                _vm.isRead
                  ? _c(
                      "button",
                      {
                        staticClass: "btn big blue-line",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.collapseAll()
                          },
                        },
                      },
                      [_vm._v(" 전체펼침 ")]
                    )
                  : _vm._e(),
                _vm.isRead
                  ? _c(
                      "button",
                      {
                        staticClass: "btn big blue-line",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.expandAll()
                          },
                        },
                      },
                      [_vm._v(" 전체닫힘 ")]
                    )
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "scroll-y" },
              [
                _c("v-jstree", {
                  ref: "tree",
                  attrs: { data: _vm.data, draggable: true },
                  on: {
                    "item-drag-start": _vm.onDragStart,
                    "item-drop": _vm.onDrop,
                    "item-drop-before": _vm.onDropBefore,
                    "item-drag-end": _vm.onDragEnd,
                    "item-click": _vm.onClick,
                    "item-drop-mouse-up": _vm.onDropMouseUp,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "btn-group fr" }, [
              _vm.isWrite
                ? _c(
                    "button",
                    {
                      staticClass: "btn big blue",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.onTreeSave()
                        },
                      },
                    },
                    [_vm._v(" 이동저장 ")]
                  )
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "right" }, [
            _vm._m(0),
            _c(
              "table",
              {
                staticClass: "gray-tb mt10",
                attrs: { cellpadding: "0", cellspacing: "0" },
              },
              [
                _vm._m(1),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("현재위치")]),
                    _c("td", [_vm._v(_vm._s(_vm.detailData.path))]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("카테고리 명(한글)")]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.detailData.name,
                            expression: "detailData.name",
                          },
                        ],
                        ref: "name",
                        staticStyle: { width: "100%" },
                        attrs: { type: "text" },
                        domProps: { value: _vm.detailData.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.detailData,
                              "name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("카테고리 명(영문)")]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.detailData.engname,
                            expression: "detailData.engname",
                          },
                        ],
                        ref: "name",
                        staticStyle: { width: "100%" },
                        attrs: { type: "text" },
                        domProps: { value: _vm.detailData.engname },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.detailData,
                              "engname",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("카테고리 코드")]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.detailData.idx,
                            expression: "detailData.idx",
                          },
                        ],
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: _vm.detailData.idx },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.detailData, "idx", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("노출여부")]),
                    _c("td", [
                      _c("div", { staticClass: "radio_wrap wide" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.detailData.hidden,
                              expression: "detailData.hidden",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "hidden",
                            id: "rd01",
                            value: "0",
                            checked: "",
                          },
                          domProps: {
                            checked: _vm._q(_vm.detailData.hidden, "0"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.detailData, "hidden", "0")
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "rd01" } }, [
                          _vm._v("노출"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.detailData.hidden,
                              expression: "detailData.hidden",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "hidden",
                            id: "rd02",
                            value: "1",
                          },
                          domProps: {
                            checked: _vm._q(_vm.detailData.hidden, "1"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.detailData, "hidden", "1")
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "rd02" } }, [
                          _vm._v("숨김"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("전시상품수")]),
                    _c("td", [
                      _c("strong", { staticClass: "txt-black" }, [
                        _vm._v(_vm._s(_vm.detailData.goodsno + " 개")),
                      ]),
                      _c("span", { staticClass: "txt-orange ml10" }, [
                        _vm._v("(하위 카테고리 상품 포함)"),
                      ]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("URL")]),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: {
                            href: _vm.detailData.linkurl,
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.detailData.linkurl))]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _vm._m(2),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.detailData.sort,
                            expression: "detailData.sort",
                          },
                        ],
                        staticStyle: { width: "10%" },
                        attrs: { type: "number", min: "1" },
                        domProps: { value: _vm.detailData.sort },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.detailData,
                              "sort",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("EP카테고리명")]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.detailData.epname,
                            expression: "detailData.epname",
                          },
                        ],
                        attrs: { type: "text", size: "200" },
                        domProps: { value: _vm.detailData.epname },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.detailData,
                              "epname",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c(
                    "tr",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.detailData.depth > 0,
                          expression: "detailData.depth > 0",
                        },
                      ],
                    },
                    [
                      _vm._m(3),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "img-with-text",
                            staticStyle: { width: "202px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "img-thumb size200",
                                class: {
                                  "no-image": _vm.$util.isNull(
                                    _vm.files["pcimgfile"]
                                  ),
                                },
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.$util.isNull(
                                        _vm.files["pcimgfile"]
                                      ),
                                      expression:
                                        "!$util.isNull(files['pcimgfile'])",
                                    },
                                  ],
                                  attrs: {
                                    src: _vm.imgPreview["pcimgfile"],
                                    alt: "대표이미지(PC)",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.$util.isNull(
                                      _vm.files["pcimgfile"]
                                    ),
                                    expression:
                                      "$util.isNull(files['pcimgfile'])",
                                  },
                                ],
                                staticClass: "btn blue-line mt10",
                                staticStyle: { width: "100%" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.fileAttach("pcimgfile")
                                  },
                                },
                              },
                              [_vm._v(" 파일 올리기 ")]
                            ),
                            _c("input", {
                              ref: "pcimgfile",
                              attrs: {
                                type: "file",
                                accept: "image/jpeg, image/png",
                                hidden: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleFileUpload("pcimgfile")
                                },
                              },
                            }),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.$util.isNull(
                                      _vm.files["pcimgfile"]
                                    ),
                                    expression:
                                      "!$util.isNull(files['pcimgfile'])",
                                  },
                                ],
                                staticClass: "btn blue-line mt10",
                                staticStyle: { width: "calc(50% - 3px)" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.fileAttach("pcimgfile")
                                  },
                                },
                              },
                              [_vm._v(" 변경 ")]
                            ),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.$util.isNull(
                                      _vm.files["pcimgfile"]
                                    ),
                                    expression:
                                      "!$util.isNull(files['pcimgfile'])",
                                  },
                                ],
                                staticClass: "btn red-line mt10",
                                staticStyle: { width: "calc(50% - 4px)" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFile("pcimgfile")
                                  },
                                },
                              },
                              [_vm._v(" 삭제 ")]
                            ),
                          ]
                        ),
                        _vm._m(4),
                      ]),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.detailData.depth > 0,
                          expression: "detailData.depth > 0",
                        },
                      ],
                    },
                    [
                      _vm._m(5),
                      _c("td", [
                        _c("div", { staticClass: "mb10" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.copyimgcheck,
                                expression: "copyimgcheck",
                              },
                            ],
                            attrs: { type: "checkbox", id: "copy-img" },
                            domProps: {
                              checked: Array.isArray(_vm.copyimgcheck)
                                ? _vm._i(_vm.copyimgcheck, null) > -1
                                : _vm.copyimgcheck,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.copyimgcheck,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.copyimgcheck = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.copyimgcheck = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.copyimgcheck = $$c
                                  }
                                },
                                _vm.setSameAsPcepreImg,
                              ],
                            },
                          }),
                          _c("label", { attrs: { for: "copy-img" } }, [
                            _vm._v("PC 대표 이미지를 복사"),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "img-with-text",
                            staticStyle: { width: "202px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "img-thumb",
                                class: [
                                  [
                                    _vm.copyimgcheck
                                      ? "size200"
                                      : "size460wide",
                                  ],
                                  {
                                    "no-image": _vm.$util.isNull(
                                      _vm.files["mobileimgfile"]
                                    ),
                                  },
                                ],
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.$util.isNull(
                                        _vm.files["mobileimgfile"]
                                      ),
                                      expression:
                                        "!$util.isNull(files['mobileimgfile'])",
                                    },
                                  ],
                                  attrs: {
                                    src: _vm.imgPreview["mobileimgfile"],
                                    alt: "대표이미지(모바일)",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.$util.isNull(
                                      _vm.files["mobileimgfile"]
                                    ),
                                    expression:
                                      "$util.isNull(files['mobileimgfile'])",
                                  },
                                ],
                                staticClass: "btn blue-line mt10",
                                staticStyle: { width: "100%" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.fileAttach("mobileimgfile")
                                  },
                                },
                              },
                              [_vm._v(" 파일 올리기 ")]
                            ),
                            _c("input", {
                              ref: "mobileimgfile",
                              attrs: {
                                type: "file",
                                accept: "image/jpeg, image/png",
                                hidden: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleFileUpload("mobileimgfile")
                                },
                              },
                            }),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.$util.isNull(
                                      _vm.files["mobileimgfile"]
                                    ),
                                    expression:
                                      "!$util.isNull(files['mobileimgfile'])",
                                  },
                                ],
                                staticClass: "btn blue-line mt10",
                                staticStyle: { width: "calc(50% - 3px)" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.fileAttach("mobileimgfile")
                                  },
                                },
                              },
                              [_vm._v(" 변경 ")]
                            ),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.$util.isNull(
                                      _vm.files["mobileimgfile"]
                                    ),
                                    expression:
                                      "!$util.isNull(files['mobileimgfile'])",
                                  },
                                ],
                                staticClass: "btn red-line mt10",
                                staticStyle: { width: "calc(50% - 4px)" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFile("mobileimgfile")
                                  },
                                },
                              },
                              [_vm._v(" 삭제 ")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.copyimgcheck,
                                expression: "!copyimgcheck",
                              },
                            ],
                            staticClass: "img-with-text text",
                          },
                          [_vm._m(6), _vm._m(7)]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.copyimgcheck,
                                expression: "copyimgcheck",
                              },
                            ],
                            staticClass: "img-with-text text",
                          },
                          [_vm._m(8), _vm._m(9)]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "btn-group fr" }, [
              _vm.isWrite
                ? _c(
                    "button",
                    {
                      staticClass: "btn big blue",
                      attrs: { type: "button" },
                      on: { click: _vm.onSave },
                    },
                    [_vm._v("저장")]
                  )
                : _vm._e(),
              _vm.isWrite
                ? _c(
                    "button",
                    {
                      staticClass: "btn big red",
                      attrs: { type: "button" },
                      on: { click: _vm.onDelete },
                    },
                    [_vm._v("삭제")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "bar-title fl" }, [_vm._v("카테고리 정보")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px;" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [_vm._v("정렬순서"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v("대표이미지(PC)"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "img-with-text text" }, [
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          "판매상품의 대표 이미지입니다. 보기 쉬운 간결한 이미지를 활용해 주세요. "
        ),
      ]),
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          "사이즈: 460*460 / 최소: 200*200 / 용량: 10MB 이하 / 파일 : JPG, JPEG, PNG "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v("대표이미지(모바일)"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        "모바일 리스팅 및 와이드형 화면에 노출되는 이미지를 업로드 해 주세요. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        "사이즈: 460*460 / 최소: 200*200 / 용량: 10MB 이하 / 파일 : JPG, JPEG, PNG "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        "판매상품의 대표 이미지입니다. 보기 쉬운 간결한 이미지를 활용해 주세요. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        "사이즈: 460*460 / 최소: 200*200 / 용량: 10MB 이하 / 파일 : JPG, JPEG, PNG "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }