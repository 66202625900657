import { render, staticRenderFns } from "./PartnersDashBoard.vue?vue&type=template&id=55b7109f&scoped=true&"
import script from "./PartnersDashBoard.vue?vue&type=script&lang=js&"
export * from "./PartnersDashBoard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55b7109f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildagent/work/3684aff05d5d78ea/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55b7109f')) {
      api.createRecord('55b7109f', component.options)
    } else {
      api.reload('55b7109f', component.options)
    }
    module.hot.accept("./PartnersDashBoard.vue?vue&type=template&id=55b7109f&scoped=true&", function () {
      api.rerender('55b7109f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/partners/PartnersDashBoard.vue"
export default component.exports