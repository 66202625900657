var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "900px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("답변내용상세")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "form-area" }, [
              _c(
                "table",
                {
                  staticClass: "gray-tb",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(0),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("작성일")]),
                      _c("td", [_vm._v(_vm._s(_vm.info.regdate))]),
                      _c("th", [_vm._v("작성자")]),
                      _c("td", [_vm._v(_vm._s(_vm.info.regusername))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("제목")]),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.info.subject,
                              expression: "info.subject",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "text",
                            placeholder: "답변제목을 입력하세요.",
                          },
                          domProps: { value: _vm.info.subject },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.info, "subject", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("답변내용")]),
                      _c(
                        "td",
                        { attrs: { colspan: "3" } },
                        [
                          _c("CommonEditor", {
                            ref: "repeditor",
                            attrs: { "style-object": { height: 300 + "px" } },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.saveRepTemplate },
                },
                [_vm._v("저장")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big red",
                  attrs: { type: "button" },
                  on: { click: _vm.removeRepTemplate },
                },
                [_vm._v("삭제")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }