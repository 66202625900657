var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1600px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("상세 내역")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "caption-group mt10 clearfix" }, [
              _c("div", { staticClass: "total-group fl" }, [
                _c("span", { staticClass: "total" }, [
                  _vm._v("상세내역 "),
                  _c("strong", [_vm._v(_vm._s(_vm.totalCnt))]),
                  _vm._v("건"),
                ]),
              ]),
              _c("div", { staticClass: "btn-group fr" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn green-line",
                    attrs: { type: "button" },
                    on: { click: _vm.excelDown },
                  },
                  [
                    _c("i", { staticClass: "icon-excel" }),
                    _vm._v("엑셀다운로드 "),
                  ]
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pagingData.pageCount,
                        expression: "pagingData.pageCount",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.pagingData,
                          "pageCount",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "20" } }, [
                      _vm._v("20개씩 보기"),
                    ]),
                    _c("option", { attrs: { value: "50" } }, [
                      _vm._v("50개씩 보기"),
                    ]),
                    _c("option", { attrs: { value: "100" } }, [
                      _vm._v("100개씩 보기"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "scroll-x" }, [
              _c(
                "table",
                {
                  staticClass: "data-tb align-c",
                  staticStyle: { "min-width": "2000px" },
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(0),
                  _vm._m(1),
                  _vm.detailList.length > 0
                    ? _c(
                        "tbody",
                        _vm._l(_vm.detailList, function (row, index) {
                          return _c("tr", { key: index }, [
                            _c("td", [
                              _vm._v(_vm._s(_vm.loopNumberForPaging(index))),
                            ]),
                            _c("td", [_vm._v(_vm._s(row.docsn))]),
                            _c("td", [_vm._v(_vm._s(row.budat))]),
                            _c("td", [_vm._v(_vm._s(row.bldat))]),
                            _c("td", [_vm._v(_vm._s(row.blart))]),
                            _c("td", [_vm._v(_vm._s(row.waers))]),
                            _c("td", { staticClass: "left" }, [
                              _vm._v(_vm._s(row.bktxt)),
                            ]),
                            _c("td", [_vm._v(_vm._s(row.xref1hd))]),
                            _c("td", [_vm._v(_vm._s(row.xref2hd))]),
                            _c("td", [_vm._v(_vm._s(row.shkzg))]),
                            _c("td", [_vm._v(_vm._s(row.hkont))]),
                            _c("td", [_vm._v(_vm._s(row.kunnr))]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(_vm.$util.maskComma(row.wrbtr))),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(_vm.$util.maskComma(row.fwbas))),
                            ]),
                            _c("td", [_vm._v(_vm._s(row.mwskz))]),
                            _c("td", [_vm._v(_vm._s(row.zfbdt))]),
                            _c("td", [_vm._v(_vm._s(row.regdate))]),
                            _c("td", [_vm._v(_vm._s(row.issend))]),
                            _c("td", [
                              row.issend === "미전송"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendToSap(row.headidx)
                                        },
                                      },
                                    },
                                    [_vm._v(" 전송")]
                                  )
                                : _vm._e(),
                              row.issend === "전송"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendCancelToSap(
                                            row.headidx
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 전송취소 ")]
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", [_vm._v(_vm._s(row.senddate))]),
                            _c("td", [_vm._v(_vm._s(row.belnr))]),
                            _c("td", [_vm._v(_vm._s(row.sapmsg))]),
                          ])
                        }),
                        0
                      )
                    : _c("tbody", [_vm._m(2)]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "bottom-group" },
              [
                _c("common-page-navigator", {
                  attrs: { pagingData: _vm.pagingData },
                  on: { setPagingData: _vm.setPagingData },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "btn-group mt20" }, [
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "1.5%" } }),
      _c("col", { attrs: { width: "7.3%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "2.7%" } }),
      _c("col", { attrs: { width: "2.5%" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "3.4%" } }),
      _c("col", { attrs: { width: "3.4%" } }),
      _c("col", { attrs: { width: "2.5%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "3.7%" } }),
      _c("col", { attrs: { width: "5.5%" } }),
      _c("col", { attrs: { width: "4.4%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "4.5%" } }),
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "4.5%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "14%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("No")]),
        _c("th", [_vm._v("전표생성 단위번호")]),
        _c("th", [_vm._v("전기일")]),
        _c("th", [_vm._v("증빙일")]),
        _c("th", [_vm._v("전표유형")]),
        _c("th", [_vm._v("통화키")]),
        _c("th", [_vm._v("헤더텍스트")]),
        _c("th", [_vm._v("입력자사번")]),
        _c("th", [_vm._v("입력자이름")]),
        _c("th", [_vm._v("차/대변")]),
        _c("th", [_vm._v("G/L계정")]),
        _c("th", [_vm._v("고객코드")]),
        _c("th", [_vm._v("전표통화금액")]),
        _c("th", [_vm._v("전표통화 과세"), _c("br"), _vm._v("표준금액")]),
        _c("th", [_vm._v("세금코드")]),
        _c("th", [_vm._v("지급기산일")]),
        _c("th", [_vm._v("등록일")]),
        _c("th", [_vm._v("SAP"), _c("br"), _vm._v("전송여부")]),
        _c("th", [_vm._v("SAP"), _c("br"), _vm._v("전송처리")]),
        _c("th", [_vm._v("SAP전송일")]),
        _c("th", [_vm._v("SAP"), _c("br"), _vm._v("전표번호")]),
        _c("th", [_vm._v("SAP 에러메시지")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "22" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }