var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1000px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("공통코드그룹 상세")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "bar-title" }, [_vm._v("기본정보")]),
            _c("div", { staticClass: "boxing" }, [
              _c("div", { staticClass: "form-area" }, [
                _c("dl", [
                  _vm._m(0),
                  _c("dd", [
                    _c("div", { staticClass: "radio_wrap wide" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.istrash,
                            expression: "info.istrash",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "group00",
                          id: "group01",
                          value: "F",
                          checked: "",
                        },
                        domProps: { checked: _vm._q(_vm.info.istrash, "F") },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.info, "istrash", "F")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "group01" } }, [
                        _vm._v("사용함"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.istrash,
                            expression: "info.istrash",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "group00",
                          id: "group02",
                          value: "T",
                        },
                        domProps: { checked: _vm._q(_vm.info.istrash, "T") },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.info, "istrash", "T")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "group02" } }, [
                        _vm._v("사용안함"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("dl", [
                  _vm._m(1),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.cmclass,
                          expression: "info.cmclass",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "text",
                        placeholder: "공통코드그룹",
                        disabled: "",
                      },
                      domProps: { value: _vm.info.cmclass },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.info, "cmclass", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("dl", [
                  _vm._m(2),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.classname,
                          expression: "info.classname",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", placeholder: "공통코드그룹명" },
                      domProps: { value: _vm.info.classname },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.info, "classname", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("dl", [
                  _vm._m(3),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.detail,
                          expression: "info.detail",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", placeholder: "공통코드그룹 설명" },
                      domProps: { value: _vm.info.detail },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.info, "detail", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.onSave },
                },
                [_vm._v("저장")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("취소")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_vm._v("사용여부"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _vm._v("공통코드그룹"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _vm._v("공통코드그룹명"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_vm._v("설명"), _c("i", { staticClass: "essential" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }