import Vue from 'vue';
import VueRouter from 'vue-router';

/** js file import ************************************/
import storage from '@/js/storage';
import util from '@/js/util';
import store from '@/js/store';

import PartnersMain from '@views/partners/PartnersMain';
import PartnersLogin from '@views/partners/PartnersLogin';
import partnersGoods from '/src/router/partners/partnersGoods.js';
import partnersOperation from '/src/router/partners/partnersOperation.js';
import partnersOrder from '/src/router/partners/partnersOrder.js';
import partnersAdjust from '/src/router/partners/partnersAdjust.js';
import PartnersSide from '@views/partners/PartnersSide.vue';
import PartnersInfoAuth from '@views/partners/PartnersInfoAuth.vue';
import KmcCertReqPopup from '@views/common/components/KmcCertReqPopup';
import KmcCertResPopup from '@views/common/components/KmcCertResPopup';
import PartnersDashBoard from '@views/partners/PartnersDashBoard';

import PartnershipJoinNew from '@views.partners/PartnershipJoinNew.vue'; // 새로운 파트너입점신청 페이지
import PartnershipAuthNew from '@views.partners/PartnershipAuthNew.vue'; // 새로운 파트너입점신청 페이지
import PartnershipCompleteNew from '@views.partners/PartnershipCompleteNew.vue'; // 새로운 파트너입점신청 페이지

import ManagerRegist from '@views/partners/manager/ManagerRegist.vue';

Vue.use(VueRouter);

let partnersRoute = [];
partnersRoute.push(partnersGoods);
partnersRoute.push(partnersOperation);
partnersRoute.push(partnersOrder);
partnersRoute.push(partnersAdjust);

const infoRoutes = {
  path: 'info',
  name: 'partners.info',
  component: PartnersSide,
  children: [
    {
      path: 'auth',
      name: 'partners.info.auth',
      component: PartnersInfoAuth,
    },
  ],
};
partnersRoute.push(infoRoutes);

const dashBoardRoute = {
  path: 'main',
  name: 'partners.main.dashboard',
  component: PartnersDashBoard,
};
partnersRoute.push(dashBoardRoute);

const routes = [
  {
    path: '/',
    name: 'partners.main',
    component: PartnersMain,
    children: partnersRoute,
  },
  {
    path: '/login',
    name: 'partners.login',
    component: PartnersLogin,
    meta: { authNotRequired: true },
  },
  // START 새로운 파트너입점신청 페이지
  {
    path: '/common/new/partnership/auth',
    name: 'common.new.partnership.auth',
    component: PartnershipAuthNew,
    meta: { authNotRequired: true },
  },
  {
    path: '/common/new/partnership/join',
    name: 'common.new.partnership.join',
    component: PartnershipJoinNew,
    meta: { authNotRequired: true },
  },
  {
    path: '/common/new/partnership/complete',
    name: 'common.new.partnership.complete',
    component: PartnershipCompleteNew,
    meta: { authNotRequired: true },
  },
  // END 새로운 파트너입점신청 페이지
  {
    path: '/common/partnership/kmc/request',
    name: 'common.partnership.kmc.cert.req',
    component: KmcCertReqPopup,
    meta: { authNotRequired: true },
  },
  {
    path: '/common/partnership/kmc/response',
    name: 'common.partnership.kmc.cert.res',
    component: KmcCertResPopup,
    meta: { authNotRequired: true },
  },
  {
    path: '/common/nice-success',
    name: 'common-niceSuccess',
    component: () => import('@views/common/components/NiceSuccess.vue'),
    meta: { authNotRequired: true },
  },
  {
    path: '/common/nice-fail',
    name: 'common-niceFail',
    component: () => import('@views/common/components/NiceFail.vue'),
    meta: { authNotRequired: true },
  },
  {
    path: '/manager/regist/:key',
    name: 'manager.regist',
    component: ManagerRegist,
    meta: { authNotRequired: true },
  },
  // 이부분은 맨 마지막에 들어가야 함. 안그러면 속성(name, path, component, meta) 정보를 제대로 읽어올 수 없음
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: () => import('@views.admin/NotFoundPage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    // 항상 맨 위로 스크롤
    return { top: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  // 로그인정보가 있는 경우에만 페이지 진입 허용
  if (
    to.matched.some((routeInfo) => {
      return !routeInfo.meta.authNotRequired;
    })
  ) {
    if (util.isAuthorized(store.getters.CONSTANTS.MANAGER_SESSION)) {
      // 새로고침이 아닌 경우 storage에 저장된 검색조건 초기화
      // 새로고침하는 경우 from.name이 undefined로 들어옴
      let searchParams = storage.getSessionStorage('searchParams');
      if (!util.isNull(from.name) && !util.isNull(searchParams)) {
        storage.removeSessionStorage('searchParams');
      }
      if (to.name === 'partners.main') {
        router.push({ name: 'partners.main.dashboard' });
      }
      next();
    } else {
      const enabledNoneMemeberPageRoute = [
        '/common/new/partnership/auth',
        '/common/new/partnership/join',
        '/common/new/partnership/complete',
      ];
      if (enabledNoneMemeberPageRoute.includes(location.pathname)) {
        //해당 Route 에는 직접 접근 불가하도록 가드함.
        alert('잘못된 접근입니다.');
      } else if (to.path !== '/' && !enabledNoneMemeberPageRoute.includes(location.pathname)) {
        alert('로그인 정보가 존재하지 않습니다.');
      }
      router.push({ name: 'partners.login' });
    }
  } else {
    next();
  }
});

// NavigationDuplicated 에러 무시
// - 같은 페이지로 router.push('path')를 하게될때 발생
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
