var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content m-leftmenu" },
    [
      _c("common-navigator"),
      _c("div", { staticClass: "inner" }, [
        _vm._m(0),
        _c("div", { staticClass: "bar-title small" }, [_vm._v("기초설정")]),
        _c("div", { staticClass: "form-area" }, [
          _c(
            "table",
            {
              staticClass: "gray-tb align-c line",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _c(
                "colgroup",
                [
                  _c("col", { attrs: { width: "8%" } }),
                  _c("col", { attrs: { width: "17%" } }),
                  _vm._l(_vm.commonCode.memlvtype, function (item) {
                    return _c("col", {
                      key: item.cmcode,
                      attrs: { width: "15%" },
                    })
                  }),
                ],
                2
              ),
              _c("thead", [
                _c(
                  "tr",
                  [
                    _c(
                      "th",
                      { staticClass: "no-left", attrs: { colspan: "2" } },
                      [_vm._v("구분")]
                    ),
                    _vm._l(_vm.commonCode.memlvtype, function (item) {
                      return _c("th", { key: item.cmcode }, [
                        _vm._v(_vm._s(item.codename)),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
              _vm._l(_vm.commonCode.dadamembertype, function (membertype) {
                return _c(
                  "tbody",
                  { key: membertype.cmcode },
                  _vm._l(_vm.respaytype, function (type) {
                    return _c(
                      "tr",
                      { key: type.sort },
                      [
                        type.sort == 1
                          ? _c(
                              "th",
                              {
                                staticClass: "no-left bold",
                                attrs: { rowspan: _vm.respaytype.length },
                              },
                              [_vm._v(" " + _vm._s(membertype.codename) + " ")]
                            )
                          : _vm._e(),
                        _c("th", [_vm._v(_vm._s(type.name))]),
                        _vm._l(_vm.commonCode.memlvtype, function (item) {
                          return _c("td", { key: item.cmcode }, [
                            _c("input", {
                              ref:
                                type.code +
                                "_" +
                                membertype.cmcode +
                                "_" +
                                item.cmcode,
                              refInFor: true,
                              staticClass: "right",
                              staticStyle: { width: "70%" },
                              attrs: {
                                type: "text",
                                oninput:
                                  "this.value = this.value.replace(/(^0[0-9]|[^0-9])/gi, '');",
                                maxlength: "8",
                              },
                            }),
                            _c("span", { staticClass: "ml3" }, [
                              _vm._v(_vm._s(type.unit)),
                            ]),
                          ])
                        }),
                      ],
                      2
                    )
                  }),
                  0
                )
              }),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "bar-title small" }, [_vm._v("추가설정")]),
        _c("div", { staticClass: "form-area" }, [
          _c(
            "table",
            {
              staticClass: "gray-tb",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _vm._m(1),
              _c("tbody", [
                _c("tr", [
                  _vm._m(2),
                  _c(
                    "td",
                    [
                      _c("CommonDatePickerFromTo", {
                        attrs: {
                          fromYYYYMMDD: _vm.config.cfmstdate,
                          fromHH: _vm.config.cfmsthour,
                          fromMM: _vm.config.cfmstmin,
                          toYYYYMMDD: _vm.config.cfmeddate,
                          toHH: _vm.config.cfmedhour,
                          toMM: _vm.config.cfmedmin,
                          useFrom: true,
                          useTo: true,
                        },
                        on: { getDate: _vm.pickerChangeEvent },
                      }),
                      _c("span", [_vm._v("동안 구매 확정 시 기초 설정의")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.config.cfmmulti,
                            expression: "config.cfmmulti",
                          },
                        ],
                        staticClass: "ml3 center",
                        staticStyle: { width: "50px" },
                        attrs: { type: "text", maxlength: "8" },
                        domProps: { value: _vm.config.cfmmulti },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.config,
                              "cfmmulti",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" 배 지급 "),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _vm._m(3),
                  _c("td", [
                    _c("span", [_vm._v("첫 리뷰 작성 시")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.config.frstreviewamt,
                          expression: "config.frstreviewamt",
                        },
                      ],
                      staticClass: "ml3 center",
                      staticStyle: { width: "60px" },
                      attrs: { type: "text", maxlength: "8" },
                      domProps: { value: _vm.config.frstreviewamt },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.config,
                            "frstreviewamt",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("span", [_vm._v("포인트 추가 지급")]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "btn-group" }, [
          _vm.isWrite
            ? _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.save },
                },
                [_vm._v("저장")]
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "bar-title fl" }, [_vm._v("자동지급설정")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("기간 내 구매확정"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("첫 리뷰 작성"),
      _c("i", { staticClass: "essential" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }