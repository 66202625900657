import OperationManageSide from '@views.admin/side/OperationManageSide';
import ExhibitionList from '@views.admin/operation/display/ExhibitList';
import InstargramFeed from '@views.admin/operation/display/InstagramFeed';
import NewGoods from '@views.admin/operation/display/NewGoods';
import TimeSpcPriceList from '@views.admin/operation/display/TimeSpcPriceList';
import BlinkSpcPriceList from '@views.admin/operation/display/BlinkSpcPriceList';
import NoticeList from '@views.admin/operation/notice/NoticeList';
import ReviewList from '@views.admin/operation/review/ReviewList';
import MainBannerList from '@views.admin/operation/magazine/banner/MainBannerList';
import DisplayList from '@views.admin/operation/magazine/display/DisplayList';
import KeywordList from '@views.admin/operation/magazine/keyword/KeywordList';
import CategoryList from '@views.admin/operation/magazine/category/CategoryList';
import TrendList from '@views.admin/operation/magazine/trend/TrendList';
import ShopMainBannerList from '@views.admin/operation/shopping/mainbanner/MainBannerList';
import ShopCateBannerList from '@views.admin/operation/shopping/catebanner/CateBannerList';
import CateSubsetList from '@views.admin/operation/shopping/catesubset/CateSubsetList';
import ShopExNameList from '@views.admin/operation/shopping/ShopExNameList';
import ExhitBannerList from '@views.admin/operation/shopping/exhitbanner/ExhitBannerList';
import ShopExOneList from '@views.admin/operation/shopping/shopex12/ShopExOneList';
import ShopExTwoList from '@views.admin/operation/shopping/shopex12/ShopExTwoList';
import ShopExThreeList from '@views.admin/operation/shopping/shopex3/ShopExThreeList';
import ShopExFourList from '@views.admin/operation/shopping/shopex45/ShopExFourList';
import ShopExFiveList from '@views.admin/operation/shopping/shopex45/ShopExFiveList';
import PromotionList from '@views.admin/operation/shopping/promotion/manage/PromotionList';
import AnnounceList from '@views.admin/operation/shopping/promotion/announce/AnnounceList';
import SubscribeList from '@views.admin/operation/setting/SubscribeList';
import SplashImgList from '@views.admin/operation/setting/SplashImgList';
import MainPopupList from '@views.admin/operation/setting/popup/MainPopupList';
import BannedList from '@views.admin/operation/setting/BannedList';
import SearchLink from '@views.admin/operation/setting/SearchLink';
import CardBenefitList from '@views.admin/operation/setting/CardBenefitList';
import QrCode from '@views.admin/operation/setting/QrCode';
import RevenueManagement from '@views.admin/operation/setting/RevenueManagement';

const route = {
  path: 'operation',
  name: 'admin.operation',
  component: OperationManageSide,
  children: [
    {
      path: 'display/exhibition',
      name: 'admin.operation.display.exhibitlist',
      component: ExhibitionList,
    },
    {
      path: 'display/instagramfeed',
      name: 'admin.operation.display.instagramfeed',
      component: InstargramFeed,
    },
    {
      path: 'display/newgoods',
      name: 'admin.operation.display.newgoods',
      component: NewGoods,
    },
    {
      path: 'display/timespclist',
      name: 'admin.operation.display.timespclist',
      component: TimeSpcPriceList,
    },
    {
      path: 'display/blinkspclist',
      name: 'admin.operation.display.blinkspclist',
      component: BlinkSpcPriceList,
    },
    {
      path: 'noticelist',
      name: 'admin.operation.noticelist',
      component: NoticeList,
    },
    {
      path: 'reviewlist',
      name: 'admin.operation.reviewlist',
      component: ReviewList,
    },
    {
      path: 'magazine/mainbannerlist',
      name: 'admin.operation.magazine.mainbannerlist',
      component: MainBannerList,
    },
    {
      path: 'magazine/displaylist',
      name: 'admin.operation.magazine.displaylist',
      component: DisplayList,
    },
    {
      path: 'magazine/keywordlist',
      name: 'admin.operation.magazine.keywordlist',
      component: KeywordList,
    },
    {
      path: 'magazine/categorylist',
      name: 'admin.operation.magazine.categorylist',
      component: CategoryList,
    },
    {
      path: 'magazine/trendlist',
      name: 'admin.operation.magazine.trendlist',
      component: TrendList,
    },
    {
      path: 'shopping/mainbannerlist',
      name: 'admin.operation.shopping.mainbannerlist',
      component: ShopMainBannerList,
    },
    {
      path: 'shopping/catebannerlist',
      name: 'admin.operation.shopping.catebannerlist',
      component: ShopCateBannerList,
    },
    {
      path: 'shopping/catesubsetlist',
      name: 'admin.operation.shopping.subsetlist',
      component: CateSubsetList,
    },
    {
      path: 'shopping/shopexname',
      name: 'admin.operation.shopping.shopexname',
      component: ShopExNameList,
    },
    {
      path: 'shopping/exhitbannerlist',
      name: 'admin.operation.shopping.exhitbannerlist',
      component: ExhitBannerList,
    },
    {
      path: 'shopping/shopexonelist',
      name: 'admin.operation.shopping.shopexonelist',
      component: ShopExOneList,
    },
    {
      path: 'shopping/shopextwolist',
      name: 'admin.operation.shopping.shopextwolist',
      component: ShopExTwoList,
    },
    {
      path: 'shopping/shopexthreelist',
      name: 'admin.operation.shopping.shopexthreelist',
      component: ShopExThreeList,
    },
    {
      path: 'shopping/shopexfourlist',
      name: 'admin.operation.shopping.shopexfourlist',
      component: ShopExFourList,
    },
    {
      path: 'shopping/shopexfivelist',
      name: 'admin.operation.shopping.shopexfivelist',
      component: ShopExFiveList,
    },
    {
      path: 'shopping/promotionlist',
      name: 'admin.operation.shopping.promotion.managelist',
      component: PromotionList,
    },
    {
      path: 'shopping/announcelist',
      name: 'admin.operation.shopping.promotion.announcelist',
      component: AnnounceList,
    },
    {
      path: 'setting/subscribelist',
      name: 'admin.operation.setting.subscribelist',
      component: SubscribeList,
    },
    {
      path: 'setting/splashimglist',
      name: 'admin.operation.setting.splashimglist',
      component: SplashImgList,
    },
    {
      path: 'setting/mainpopuplist',
      name: 'admin.operation.setting.mainpopuplist',
      component: MainPopupList,
    },
    {
      path: 'setting/bannedlist',
      name: 'admin.operation.setting.bannedlist',
      component: BannedList,
    },
    {
      path: 'setting/searchlink',
      name: 'admin.operation.setting.searchlink',
      component: SearchLink,
    },
    {
      path: 'setting/cardbenefit',
      name: 'admin.operation.setting.cardbenefitlist',
      component: CardBenefitList,
    },
    {
      path: 'setting/qrcode',
      name: 'admin.operation.setting.qrcode',
      component: QrCode,
    },
    {
      path: 'setting/revenueManagement',
      name: 'admin.operation.setting.revenuemanagement',
      component: RevenueManagement,
    },
  ],
};

export default route;
