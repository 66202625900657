var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pagingData.totalPageCount > 0
    ? _c("div", { staticClass: "page-navigator" }, [
        _c(
          "ul",
          { staticClass: "page-navigator-wrap" },
          [
            _c("li", [
              _c("button", {
                staticClass: "arrow left",
                on: { click: _vm.prev },
              }),
            ]),
            _vm._l(_vm.pages, function (page) {
              return _c("li", { key: page.pageNumber }, [
                _c(
                  "button",
                  {
                    class: { active: page.isActive },
                    on: {
                      click: function ($event) {
                        return _vm.goTo(page.pageNumber)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(page.pageNumber) + " ")]
                ),
              ])
            }),
            _c("li", [
              _c("button", {
                staticClass: "arrow right",
                on: { click: _vm.next },
              }),
            ]),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }