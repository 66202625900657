var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1100px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("상품 문의 상세")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "qna-area" }, [
              _vm._m(0),
              _c("div", { staticClass: "bd-wrap" }, [
                _c("table", { attrs: { cellpadding: "0", cellspacing: "0" } }, [
                  _c("caption", [_vm._v(" 상품 문의 - 질문 ")]),
                  _vm._m(1),
                  _c("tbody", [
                    _vm.isAdmin
                      ? _c("tr", [
                          _c("th", [_vm._v("고객명")]),
                          _c("td", [_vm._v(_vm._s(_vm.boardInfo.name))]),
                          _c("th", [_vm._v("아이디")]),
                          _c("td", [_vm._v(_vm._s(_vm.boardInfo.writer))]),
                          _c("th", [_vm._v("회원유형/등급")]),
                          _c("td", [_vm._v(_vm._s(_vm.boardInfo.usertype))]),
                          _c("th", [_vm._v("문의일시")]),
                          _c("td", [_vm._v(_vm._s(_vm.boardInfo.regdate))]),
                        ])
                      : _c("tr", [
                          _c("th", [_vm._v("문의구분")]),
                          _c("td", { attrs: { colspan: "3" } }, [
                            _vm._v(_vm._s(_vm.boardInfo.qnatype)),
                          ]),
                          _c("th", [_vm._v("문의일시")]),
                          _c("td", { attrs: { colspan: "3" } }, [
                            _vm._v(_vm._s(_vm.boardInfo.regdate)),
                          ]),
                        ]),
                    _c("tr", [
                      _c("th", [_vm._v("상품명")]),
                      _c("td", { attrs: { colspan: "7" } }, [
                        _vm._v(" " + _vm._s(_vm.boardInfo.goodsname)),
                        _c("br"),
                        _vm._v(
                          _vm._s(
                            !_vm.$util.isNull(_vm.boardInfo.optionname)
                              ? "옵션 : " + _vm.boardInfo.optionname
                              : ""
                          ) + " "
                        ),
                      ]),
                    ]),
                    _c("tr", { staticClass: "contents" }, [
                      _c("th", [_vm._v("질문내용")]),
                      _c("td", {
                        attrs: { colspan: "7" },
                        domProps: { innerHTML: _vm._s(_vm.boardInfo.content) },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "qna-area" }, [
              _vm._m(2),
              _c("div", { staticClass: "bd-wrap" }, [
                _c("table", { attrs: { cellpadding: "0", cellspacing: "0" } }, [
                  _c("caption", [_vm._v(" 상품 문의 - 답변 ")]),
                  _vm._m(3),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("판매구분")]),
                      _c("td", [_vm._v(_vm._s(_vm.boardInfo.ispbgoods))]),
                      _c("th", [_vm._v("파트너사")]),
                      _c("td", [_vm._v(_vm._s(_vm.boardInfo.dealername))]),
                      _c("th", [_vm._v("상태")]),
                      _c("td", [_vm._v(_vm._s(_vm.boardInfo.isreply))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("답변자")]),
                      _c("td", [_vm._v(_vm._s(_vm.boardInfo.repusername))]),
                      _c("th", [_vm._v("아이디")]),
                      _c("td", [_vm._v(_vm._s(_vm.boardInfo.repuserid))]),
                      _c("th", [_vm._v("답변일시")]),
                      _c("td", [_vm._v(_vm._s(_vm.boardInfo.repregdate))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("문구 템플릿")]),
                      _c("td", { attrs: { colspan: "5" } }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.tplidx,
                                expression: "boardInfo.tplidx",
                              },
                            ],
                            staticStyle: { width: "500px" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.boardInfo,
                                    "tplidx",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.changeContent()
                                },
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("자주 쓰는 답변 선택"),
                            ]),
                            _vm._l(this.template, function (row, c) {
                              return _c(
                                "option",
                                { key: c, domProps: { value: row.tplidx } },
                                [_vm._v(" " + _vm._s(row.subject) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _c("tr", { staticClass: "contents" }, [
                      _c("th", [_vm._v("답변내용")]),
                      _c(
                        "td",
                        { attrs: { colspan: "7" } },
                        [_c("CommonEditor", { ref: "editor" })],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "btn-group" }, [
              _vm.isWrite && _vm.boardInfo.isreply === "대기"
                ? _c(
                    "button",
                    {
                      staticClass: "btn big blue",
                      attrs: { type: "button" },
                      on: { click: _vm.goSave },
                    },
                    [_vm._v(" 저장 ")]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title orange" }, [
      _c("i", { staticClass: "icon-q" }),
      _vm._v("질문"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "11%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "16%" } }),
      _c("col", { attrs: { width: "2%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title blue" }, [
      _c("i", { staticClass: "icon-a" }),
      _vm._v("답변"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }