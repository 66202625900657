var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1100px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("파트너사 문의 상세")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "qna-area" }, [
              _vm._m(0),
              _c("div", { staticClass: "bd-wrap" }, [
                _c("table", { attrs: { cellpadding: "0", cellspacing: "0" } }, [
                  _c("caption", [_vm._v(" 파트너사 문의 - 질문 ")]),
                  _vm._m(1),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("담당자")]),
                      _c("td", [_vm._v(_vm._s(_vm.boardInfo.writer))]),
                      _c("th", [_vm._v("질문일시")]),
                      _c("td", [_vm._v(_vm._s(_vm.boardInfo.regdate))]),
                      _c("th", [_vm._v("답변상태")]),
                      _c("td", [_vm._v(_vm._s(_vm.boardInfo.isreply))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("파트너사")]),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.dealerno,
                                expression: "boardInfo.dealerno",
                              },
                            ],
                            ref: "dealerSelectBox",
                            staticStyle: { width: "240px" },
                            attrs: { disabled: _vm.isAdmin !== true },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.boardInfo,
                                    "dealerno",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.onDealerChange("F")
                                },
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("선택"),
                            ]),
                            _vm._l(_vm.dealerList, function (row, i) {
                              return _c(
                                "option",
                                { key: i, domProps: { value: row.no } },
                                [_vm._v(" " + _vm._s(row.name) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.boardInfo.dealerno !== "" &&
                                  _vm.chargeList.length !== 0,
                                expression:
                                  "boardInfo.dealerno !== '' && chargeList.length !== 0",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.boardInfo.partchargeidx,
                                expression: "boardInfo.partchargeidx",
                              },
                            ],
                            staticStyle: { width: "240px" },
                            attrs: { disabled: _vm.isAdmin !== true },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.boardInfo,
                                  "partchargeidx",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.chargeList, function (row, i) {
                            return _c(
                              "option",
                              { key: i, domProps: { value: row.idx } },
                              [_vm._v(" " + _vm._s(row.charge) + " ")]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("th", [_vm._v("주문번호")]),
                      _c("td", [
                        _vm.isAdmin === true
                          ? _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.boardInfo.ordno,
                                    expression: "boardInfo.ordno",
                                  },
                                ],
                                staticStyle: { width: "250px" },
                                attrs: {
                                  type: "search",
                                  placeholder: "",
                                  disabled: "",
                                },
                                domProps: { value: _vm.boardInfo.ordno },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.boardInfo,
                                      "ordno",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-search",
                                  attrs: { type: "button" },
                                  on: { click: _vm.openOrderPopup },
                                },
                                [_vm._v("검색")]
                              ),
                            ])
                          : _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.boardInfo.ordno,
                                    expression: "boardInfo.ordno",
                                  },
                                ],
                                staticStyle: { width: "250px" },
                                attrs: {
                                  type: "text",
                                  placeholder: "",
                                  disabled: "",
                                  read: "",
                                },
                                domProps: { value: _vm.boardInfo.ordno },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.boardInfo,
                                      "ordno",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("제목")]),
                      _c("td", { attrs: { colspan: "7" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.boardInfo.subject,
                              expression: "boardInfo.subject",
                            },
                          ],
                          ref: "subject",
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "text",
                            disabled: _vm.isAdmin !== true,
                          },
                          domProps: { value: _vm.boardInfo.subject },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.boardInfo,
                                "subject",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", { staticClass: "contents" }, [
                      _c("th", [_vm._v("질문내용")]),
                      _c(
                        "td",
                        { attrs: { colspan: "7" } },
                        [
                          _c("CommonEditor", {
                            ref: "editor",
                            attrs: { disable: !_vm.isAdmin },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.isAdmin ||
                      (_vm.isAdmin &&
                        !_vm.$util.isNull(_vm.boardInfo.repcontent)),
                    expression:
                      "!isAdmin || (isAdmin && !$util.isNull(boardInfo.repcontent))",
                  },
                ],
                staticClass: "qna-area",
              },
              [
                _vm._m(2),
                _c("div", { staticClass: "bd-wrap" }, [
                  _c(
                    "table",
                    { attrs: { cellpadding: "0", cellspacing: "0" } },
                    [
                      _c("caption", [_vm._v(" 파트너사 문의 - 답변 ")]),
                      _vm._m(3),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("답변자")]),
                          _c("td", [_vm._v(_vm._s(_vm.boardInfo.repname))]),
                          _c("th", [_vm._v("답변일시")]),
                          _c("td", [_vm._v(_vm._s(_vm.boardInfo.repregdate))]),
                        ]),
                        _c("tr", { staticClass: "contents" }, [
                          _c("th", [_vm._v("답변내용")]),
                          _c(
                            "td",
                            { attrs: { colspan: "7" } },
                            [
                              _c("CommonEditor", {
                                ref: "repEditor",
                                attrs: { disable: _vm.isAdmin },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isWrite && _vm.boardInfo.isreply !== "완료",
                      expression: "isWrite && boardInfo.isreply !== '완료'",
                    },
                  ],
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.goSave },
                },
                [_vm._v(" 저장 ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title orange" }, [
      _c("i", { staticClass: "icon-q" }),
      _vm._v("질문"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title blue" }, [
      _c("i", { staticClass: "icon-a" }),
      _vm._v("답변"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "40%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }