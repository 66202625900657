import AdjustManageSide from '@views.admin/side/AdjustManageSide';
import AdjustEstimate from '@views.admin/adjust/estimate/AdjustEstimate';
import AdjustDecision from '@views.admin/adjust/decision/AdjustDecision';
import AdjustEstimateNew from '@views.admin/adjust/estimate/AdjustEstimateNew';
import AdjustReserve from '@views.admin/adjust/estimate/AdjustReserve';
import AdjustCoupon from '@views.admin/adjust/estimate/AdjustCoupon';
import MonthlySalesClosing from '@views.admin/adjust/estimate/MonthlySalesClosing';

// TODO: 구 정산관리 화면으로 진입시 redirect 처리 (I-01-001) api 대응되면 삭제 예정
const route = {
  path: 'adjust',
  name: 'admin.adjust',
  component: AdjustManageSide,
  children: [
    {
      path: 'decision',
      name: 'admin.adjust.decision',
      component: AdjustDecision,
    },
    {
      path: 'estimate',
      name: 'admin.adjust.estimate.partner',
      component: AdjustEstimate,
      redirect: '/adjust/estimatenew',
    },
    {
      path: 'estimatenew',
      name: 'admin.adjust.estimate.partnernew',
      component: AdjustEstimateNew,
    },
    {
      path: 'reserve',
      name: 'admin.adjust.estimate.reserve',
      component: AdjustReserve,
    },
    {
      path: 'coupon',
      name: 'admin.adjust.estimate.coupon',
      component: AdjustCoupon,
    },
    {
      path: 'monthly-sales-closing',
      name: 'admin.adjust.estimate.monthlysalesclosing',
      component: MonthlySalesClosing,
    },
  ],
};

export default route;
