<template>
  <!-- 컨텐츠 영역 -->
  <div class="content m-leftmenu">
    <common-navigator></common-navigator>
    <div class="inner">
      <div class="boxing search-area">
        <dl>
          <dt>조회기간</dt>
          <dd>
            <select v-model="searchData.sdate">
              <option value="sales">취급고</option>
              <option value="order">주문일</option>
            </select>
            <common-date-picker
              :value="searchData.startdate"
              @change="onChangeStartDate"
            ></common-date-picker>
            <span>-</span>
            <common-date-picker
              :value="searchData.enddate"
              @change="onChangeEndDate"
            ></common-date-picker>
            <div class="radio_wrap">
              <input
                type="radio"
                v-model="searchData.period"
                id="period_aday_1"
                value="aday_1"
              /><label for="period_aday_1">어제</label>
              <input
                type="radio"
                v-model="searchData.period"
                id="period_aday_0"
                value="aday_0"
              /><label for="period_aday_0">오늘</label>
              <input
                type="radio"
                v-model="searchData.period"
                id="period_days_7"
                value="days_7"
              /><label for="period_days_7">일주일</label>
              <input
                type="radio"
                v-model="searchData.period"
                id="period_months_1"
                value="months_1"
              /><label for="period_months_1">1개월</label>
              <input
                type="radio"
                v-model="searchData.period"
                id="period_months_3"
                value="months_3"
              /><label for="period_months_3">3개월</label>
              <input
                type="radio"
                v-model="searchData.period"
                id="period_months_6"
                value="months_6"
              /><label for="period_months_6">6개월</label>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>파트너사별 분개 여부</dt>
          <dd>
            <div>
              <input
                type="checkbox"
                id="isPartnerDivergence"
                v-model="searchData.isPartnerDivergence"
                true-value="T"
                false-value="F"
              />
              <label for="isPartnerDivergence">분개</label>
            </div>
          </dd>
        </dl>
      </div>
      <div class="btn-group">
        <button v-if="isRead" class="btn big blue" type="button" @click="onSearch(1)">검색</button>
        <button v-if="isRead" class="btn big gray" type="button" @click="initData">초기화</button>
      </div>
      <div class="caption-group mt10 clearfix">
        <div class="total-group fl">
          <span class="total">쿠폰 사용 내역</span>
        </div>
        <div class="btn-group fr">
          <button v-if="isWrite" class="btn green-line" type="button" @click="excelDown">
            <i class="icon-excel" />
            엑셀다운로드
          </button>
        </div>
      </div>
      <div class="scroll-x">
        <table cellpadding="0" cellspacing="0" class="data-tb align-c" style="min-width: 1600px">
          <caption>
            쿠폰 사용 내역
          </caption>
          <colgroup v-if="isPartnerShow">
            <col width="6%" />
            <!-- 쿠폰코드 -->
            <col width="" />
            <!-- 쿠폰명 -->
            <col width="5%" />
            <!-- 파트너사번호 -->
            <col width="9%" />
            <!-- 파트너사명 -->
            <col width="6%" />
            <!-- 쿠폰타입 -->
            <col width="5%" />
            <!-- 적용타입 -->
            <col width="5%" />
            <!-- 발행수 -->
            <col width="5%" />
            <!-- 다운로드수 -->
            <col width="5%" />
            <!-- 사용수 -->
            <col width="6%" />
            <!-- 쿠폰할인금액 -->
            <col width="5%" />
            <!-- 당사부담율 -->
            <col width="6%" />
            <!-- 당사부담금액 -->
            <col width="5%" />
            <!-- 파트너부담율 -->
            <col width="6%" />
            <!-- 파트너부담금액 -->
            <col width="7%" />
            <!-- 전체주문금액 -->
          </colgroup>
          <colgroup v-else>
            <col width="6%" />
            <!-- 쿠폰코드 -->
            <col width="" />
            <!-- 쿠폰명 -->
            <col width="6%" />
            <!-- 쿠폰타입 -->
            <col width="5%" />
            <!-- 적용타입 -->
            <col width="5%" />
            <!-- 발행수 -->
            <col width="5%" />
            <!-- 다운로드수 -->
            <col width="5%" />
            <!-- 사용수 -->
            <col width="6%" />
            <!-- 쿠폰할인금액 -->
            <col width="5%" />
            <!-- 당사부담율 -->
            <col width="6%" />
            <!-- 당사부담금액 -->
            <col width="5%" />
            <!-- 파트너부담율 -->
            <col width="6%" />
            <!-- 파트너부담금액 -->
            <col width="7%" />
            <!-- 전체주문금액 -->
          </colgroup>
          <thead>
            <tr>
              <th>쿠폰코드</th>
              <th>쿠폰명</th>
              <th v-if="isPartnerShow">파트너사번호</th>
              <th v-if="isPartnerShow">파트너사명</th>
              <th>쿠폰타입</th>
              <th>적용타입</th>
              <th>발행수</th>
              <th>다운로드수</th>
              <th>사용수</th>
              <th>쿠폰할인금액</th>
              <th>당사부담율</th>
              <th>당사부담금액</th>
              <th>파트너부담율</th>
              <th>파트너부담금액</th>
              <th>전체주문금액</th>
            </tr>
          </thead>
          <tbody v-if="couponList.length > 0">
            <tr v-for="(row, index) in couponList" v-bind:key="index">
              <td>{{ row.couponcode }}</td>
              <td class="left">{{ row.couponname }}</td>
              <td v-if="isPartnerShow">{{ row.dealerno }}</td>
              <td v-if="isPartnerShow" class="left">{{ row.dealername }}</td>
              <td>{{ row.coupontype }}</td>
              <td>{{ row.applytype }}</td>
              <td class="right">{{ $util.maskComma(row.issuecount) }}</td>
              <td class="right">{{ $util.maskComma(row.downloadcount) }}</td>
              <td class="right">{{ $util.maskComma(row.usecount) }}</td>
              <td class="right">{{ $util.maskComma(row.coupondiscount) }}</td>
              <td class="right">{{ $util.maskComma(row.ownratio) }}</td>
              <td class="right">{{ $util.maskComma(row.ownamount) }}</td>
              <td class="right">{{ $util.maskComma(row.partnerratio) }}</td>
              <td class="right">{{ $util.maskComma(row.partneramount) }}</td>
              <td class="right">{{ $util.maskComma(row.totalorderamount) }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="15">조회 결과가 존재하지 않습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- /컨텐츠 영역 -->
</template>

<script>
import CommonNavigator from '@views.admin/common/CommonNavigator';
import CommonDatePicker from '@views.admin/common/CommonDatePicker';

export default {
  name: 'admin.adjust.estimate.reserve',
  components: {
    CommonNavigator,
    CommonDatePicker,
  },
  data() {
    return {
      searchData: {
        startdate: '',
        enddate: '',
        period: 'months_1', //기간
        sdate: 'sales', //기간구분(default: 취급고)
        isPartnerDivergence: 'T',
      },
      couponList: [],
      formatType: 'month',
      isRead: false,
      isWrite: false,
      isPartnerShow: true,
    };
  },
  mounted() {
    this.$http
      .post('/admin/common/pageAuth/check', { url: this.$options.name, isloading: false })
      .then((result) => {
        this.isRead = result.data.isread === 'T';
        this.isWrite = result.data.iswrite === 'T';

        if (this.isRead) {
          this.initData();
        }
      });
  },
  methods: {
    initData() {
      this.searchData.sdate = 'sales';
      this.searchData.startdate = this.$util.getAddMonth(this.$util.getDate(), -1, '-');
      this.searchData.enddate = this.$util.getDate('-');
      this.searchData.period = 'months_1';

      //this.searchData.startdate = startDate.substring(0, 7);
      //this.searchData.enddate = endDate.substring(0, 7);
    },
    onSearch(page) {
      let param = this.searchData;
      param.isloading = true;

      this.$http
        .post('/admin/adjust/coupon/list', param)
        .then((result) => {
          this.couponList = result.data.list;
          if (this.searchData.isPartnerDivergence === 'T') {
            this.isPartnerShow = true;
          } else {
            this.isPartnerShow = false;
          }
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    excelDown() {
      let param = this.searchData;
      param.isloading = true;

      let config = { responseType: 'arraybuffer' };
      this.$http
        .post('/admin/adjust/coupon/excel', param, config)
        .then((result) => {
          this.$util.debug(result);
          // this.onSearch(1);
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    onChangeStartDate(val) {
      this.searchData.startdate = val;
    },
    // 날짜 picker 콜백 함수
    onChangeEndDate(val) {
      this.searchData.enddate = val;
    },
  },
  watch: {
    // 조회기간
    'searchData.period': function (value) {
      let params = value.split('_');
      let type = params[0];
      let addValue = parseInt(params[1]) * -1;

      if (type == 'aday') {
        this.searchData.startdate = this.$util.getAddDate(this.$util.getDate(), addValue, '-');
        this.searchData.enddate = this.$util.getAddDate(this.$util.getDate(), addValue, '-');
      } else if (type == 'days') {
        this.searchData.startdate = this.$util.getAddDate(this.$util.getDate(), addValue, '-');
        this.searchData.enddate = this.$util.getDate('-');
      } else if (type == 'months') {
        this.searchData.startdate = this.$util.getAddMonth(this.$util.getDate(), addValue, '-');
        this.searchData.enddate = this.$util.getDate('-');
      }
    },
  },
};
</script>

<style scoped />
