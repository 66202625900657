<template>
  <div class="content m-leftmenu">
    <CommonNavigator />

    <div class="inner">
      <div class="caption-group mt10 clearfix">
        <div class="btn-group fr">
          <button v-if="isWrite" type="button" class="btn blue" @click="onClickAddPromotion">
            등록
          </button>
        </div>
      </div>
      <table cellpadding="0" cellspacing="0" class="data-tb align-c promotion-list">
        <caption>임직원 프로모션 관리 목록</caption>
        <colgroup>
          <col width="10%" />
          <!-- No -->
          <col width="10%" />
          <!-- ID -->
          <col width="" />
          <!-- 관리 제목 -->
        </colgroup>
        <thead>
          <tr>
            <th>No</th>
            <th>ID</th>
            <th>관리 제목</th>
          </tr>
        </thead>
        <tbody v-if="employeePromotionList.length > 0">
          <tr
            v-for="(promotion, index) in employeePromotionList"
            :key="promotion.id"
            class="clickable"
            @click="onClickPromotionDetail(promotion.id)"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ promotion.id }}</td>
            <td>
              {{ promotion.title }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="3">조회 결과가 존재하지 않습니다.</td>
          </tr>
        </tbody>
      </table>
    </div>

    <EmployeePromotionCreatePopup
      v-if="openedCreatePopup"
      @onRefresh="onRefresh"
      @onClose="onCloseCreatePopup"
    />
    <EmployeePromotionDetailPopup
      v-if="openedDetailPopup"
      :isWrite="isWrite"
      :promotion="selectEmployeePromotion"
      @onClose="onCloseDetailPopup"
    />
  </div>
</template>

<script>
import CommonNavigator from '@views.admin/common/CommonNavigator';
import EmployeePromotionCreatePopup from './PromotionCreatePopup.vue';
import EmployeePromotionDetailPopup from './PromotionDetailPopup.vue';

export default {
  name: 'admin.promotion.employee.promotionlist',
  components: {
    CommonNavigator,
    EmployeePromotionCreatePopup,
    EmployeePromotionDetailPopup,
  },
  data() {
    return {
      isRead: false,
      isWrite: false,
      openedCreatePopup: false,
      selectEmployeePromotion: null,
      employeePromotionList: [],
    };
  },
  computed: {
    openedDetailPopup() {
      return !!this.selectEmployeePromotion;
    },
  },
  mounted() {
    this.$http
      .post('/admin/common/pageAuth/check', { url: this.$options.name, isloading: false })
      .then((result) => {
        this.isRead = result.data.isread === 'T';
        this.isWrite = result.data.iswrite === 'T';

        if (this.isRead) {
          this.getEmployeePromotionList();
        }
      });
  },
  methods: {
    onClickAddPromotion() {
      this.openedCreatePopup = true;
    },
    onCloseCreatePopup() {
      this.openedCreatePopup = false;
    },
    onClickPromotionDetail(employeePromotionId) {
      const filteredEmployeePromotion = this.employeePromotionList.find(
        ({ id }) => id === employeePromotionId,
      );
      this.selectEmployeePromotion = filteredEmployeePromotion || null;
    },
    onCloseDetailPopup() {
      this.selectEmployeePromotion = null;
    },
    onRefresh() {
      this.getEmployeePromotionList(false);
    },
    async getEmployeePromotionList(isloading = true) {
      try {
        const { data } = await this.$http.get(`/v2/employee-promotion/list`, {
          params: { isloading },
        });
        this.employeePromotionList = data.reverse();
      } catch (error) {
        this.$util.debug(error);
      }
    },
  },
};
</script>

<style scoped>
.content.m-leftmenu {
  min-width: 1400px;
}

button {
  background-color: transparent;
  border: none;
}

.promotion-list tbody tr.clickable {
  cursor: pointer;
}
</style>
