var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1600px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v(_vm._s(_vm.clmname) + "신청")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c(
            "div",
            { staticClass: "pop-body" },
            [
              _c("div", { staticClass: "gray-box mg0" }, [
                _c("div", { staticClass: "clearfix" }, [
                  _c("div", { staticClass: "fl" }, [
                    _vm._v(
                      " [" +
                        _vm._s(_vm.orderInfo.ordpathtypename) +
                        "] 주문번호 : "
                    ),
                    _c("strong", [_vm._v(_vm._s(_vm.orderInfo.ordno))]),
                  ]),
                  _c("div", { staticClass: "fr txt-gray" }, [
                    _c("span", [
                      _vm._v("주문일 : " + _vm._s(_vm.orderInfo.orderdate)),
                    ]),
                  ]),
                ]),
                _c("hr", {
                  staticClass: "solid",
                  staticStyle: { margin: "10px 0" },
                }),
                _c("div", { staticClass: "clearfix" }, [
                  _c("div", { staticClass: "fl" }, [
                    _vm.orderInfo.isnonmember == "F"
                      ? _c("span", [
                          _vm._v(
                            "[" +
                              _vm._s(_vm.orderInfo.membertypename) +
                              "] [" +
                              _vm._s(_vm.orderInfo.memlvtypename) +
                              "] "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.goMemDetail(
                                    _vm.orderInfo.orduserno
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.orderInfo.ordname))]
                          ),
                          _vm._v("(" + _vm._s(_vm.orderInfo.ordid) + ") "),
                        ])
                      : _c("span", [_vm._v(_vm._s(_vm.orderInfo.ordname))]),
                    _vm.orderInfo.isnonmember == "F"
                      ? _c("span", { staticClass: "left-bar" }, [
                          _vm._v(
                            _vm._s(_vm.$util.maskTel(_vm.orderInfo.ordtel))
                          ),
                        ])
                      : _vm._e(),
                    _vm.orderInfo.isnonmember == "F"
                      ? _c("span", { staticClass: "left-bar" }, [
                          _vm._v(_vm._s(_vm.orderInfo.ordemail)),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "clearfix mt20" }, [
                _c("div", { staticClass: "bar-title small fl" }, [
                  _vm._v(_vm._s(_vm.clmname) + "상품"),
                ]),
              ]),
              _c(
                "table",
                {
                  staticClass: "data-tb align-c mt0",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _c("caption", [_vm._v(" 상품 목록 ")]),
                  _vm._m(0),
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("판매구분")]),
                      _c("th", [_vm._v("파트너사")]),
                      _c("th", [_vm._v("상품코드")]),
                      _c("th", [_vm._v("단품코드")]),
                      _c("th", [_vm._v("상품순번")]),
                      _c("th", { attrs: { colspan: "2" } }, [_vm._v("상품명")]),
                      _c("th", [_vm._v("옵션")]),
                      _c("th", [_vm._v("주문수량")]),
                      _c("th", [_vm._v(_vm._s(_vm.clmname) + "수량")]),
                      _c("th", [_vm._v("판매단가")]),
                      _c("th", [_vm._v("판매금액")]),
                      _c("th", [_vm._v("주문상태")]),
                    ]),
                  ]),
                  !_vm.$util.isNull(_vm.clmInfo.dispitems) &&
                  _vm.clmInfo.dispitems.length > 0
                    ? _c(
                        "tbody",
                        _vm._l(_vm.clmInfo.dispitems, function (row) {
                          return _c("tr", { key: row.ordgdidx }, [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  row.ispbgoods === "T" ? "자사" : "파트너사"
                                )
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(row.dealername))]),
                            _c("td", [_vm._v(_vm._s(row.goodscode))]),
                            _c("td", [_vm._v(_vm._s(row.optioncode))]),
                            _c("td", [_vm._v(_vm._s(row.goodsturn))]),
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticClass: "img-thumb size60 link",
                                  class: {
                                    "no-image": _vm.$util.isNull(row.fullpath),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goFrontGoodsDetail(
                                        row.goodscode
                                      )
                                    },
                                  },
                                },
                                [
                                  !_vm.$util.isNull(row.fullpath)
                                    ? _c("img", {
                                        attrs: { src: row.fullpath },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                            _c("td", { staticClass: "left no-left" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goGoodsDetail(row.goodsno)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.goodsname))]
                              ),
                            ]),
                            _c(
                              "td",
                              { staticStyle: { "white-space": "pre-wrap" } },
                              [_vm._v(_vm._s(row.optionname))]
                            ),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.$util.maskComma(row.origincnt))
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: row.clmcnt,
                                      expression: "row.clmcnt",
                                    },
                                  ],
                                  staticClass: "short",
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          row,
                                          "clmcnt",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      _vm.recalClaimRtnAmt,
                                    ],
                                  },
                                },
                                _vm._l(row.origincnt, function (i) {
                                  return _c(
                                    "option",
                                    { key: i, domProps: { value: i } },
                                    [_vm._v(_vm._s(i))]
                                  )
                                }),
                                0
                              ),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(_vm._s(_vm.$util.maskComma(row.price))),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.maskComma(row.price * row.origincnt)
                                )
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goOrderHistory(row.ordgdidx)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.ordstatusname))]
                              ),
                            ]),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "table",
                {
                  staticClass: "gray-tb",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(1),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(_vm._s(_vm.clmname) + "사유"),
                        _c("i", { staticClass: "essential" }),
                      ]),
                      _c("td", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.rstype,
                                expression: "rstype",
                              },
                            ],
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.rstype = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.recalClaimRtnAmt,
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("구분선택"),
                            ]),
                            _vm._l(_vm.commonCode.rstype, function (item) {
                              return _c(
                                "option",
                                {
                                  key: item.cmcode,
                                  domProps: { value: item.cmcode },
                                },
                                [_vm._v(" " + _vm._s(item.codename) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.rsdetail,
                              expression: "rsdetail",
                            },
                          ],
                          staticClass: "ml3",
                          staticStyle: { width: "calc(100% - 156px)" },
                          attrs: {
                            type: "text",
                            placeholder: "상세사유입력",
                            maxlength: "500",
                          },
                          domProps: { value: _vm.rsdetail },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.rsdetail = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm.clmtype !== _vm.$store.getters["ADMIN"].CLM_CANCEL
                      ? _c("tr", [
                          _c("th", [_vm._v("사진첨부")]),
                          _c(
                            "td",
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn blue-line",
                                  attrs: { type: "button" },
                                  on: { click: _vm.fileAttach },
                                },
                                [_vm._v("파일첨부")]
                              ),
                              _c("input", {
                                ref: "inputFile",
                                attrs: {
                                  type: "file",
                                  id: "input-fileP",
                                  accept: "image/*",
                                  hidden: "",
                                  multiple: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleFileUpload($event.target)
                                  },
                                },
                              }),
                              _vm._l(_vm.uploadFiles, function (row, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "dpib" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "file-link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.viewFile(row.file)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(row.file.name))]
                                    ),
                                    _c("button", {
                                      staticClass: "file-del",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeFile(index)
                                        },
                                      },
                                    }),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm.clmtype === _vm.$store.getters["ADMIN"].CLM_EXCHANGE
                ? [
                    _vm._m(2),
                    _c(
                      "table",
                      {
                        staticClass: "data-tb align-c mt0",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _c("caption", [_vm._v(" 주문 목록 ")]),
                        _vm._m(3),
                        _vm._m(4),
                        !_vm.$util.isNull(_vm.excgoods)
                          ? _c("tbody", [
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.excgoods.ispbgoods === "T"
                                        ? "자사"
                                        : "파트너사"
                                    )
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.excgoods.ispbgoods === "T"
                                        ? "-"
                                        : _vm.excgoods.dealername
                                    )
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.excgoods.goodscode)),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.excgoods.optioncode)),
                                ]),
                                _c("td", { staticClass: "left" }, [
                                  _c("span", { staticClass: "dpb" }, [
                                    _vm._v(_vm._s(_vm.excgoods.goodsname)),
                                  ]),
                                  _c("div", { staticClass: "dpb" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.excoption,
                                            expression: "excoption",
                                          },
                                        ],
                                        staticStyle: { width: "100%" },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.excoption = $event.target
                                              .multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                        },
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("선택"),
                                        ]),
                                        _vm._l(
                                          _vm.excgoodslist,
                                          function (row, index) {
                                            return _c(
                                              "option",
                                              {
                                                key: index,
                                                domProps: {
                                                  value: row.optioncode,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(row.optionname) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$util.maskComma(_vm.excgoods.exccnt)
                                    )
                                  ),
                                ]),
                                _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$util.maskComma(
                                          Number(_vm.excgoods.exccnt) *
                                            Number(_vm.excgoods.price)
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ])
                          : _c("tbody", [_vm._m(5)]),
                      ]
                    ),
                  ]
                : _vm._e(),
              !_vm.$util.isNull(_vm.claimGiftList) &&
              _vm.claimGiftList.length > 0
                ? _c("div", { staticClass: "bar-title small" }, [
                    _vm._v(" " + _vm._s(_vm.clmname) + " 사은품 "),
                  ])
                : _vm._e(),
              !_vm.$util.isNull(_vm.claimGiftList) &&
              _vm.claimGiftList.length > 0
                ? _c(
                    "table",
                    {
                      staticClass: "data-tb align-c",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _c("caption", [_vm._v(" 사은품 목록 ")]),
                      _vm._m(6),
                      _vm._m(7),
                      _c(
                        "tbody",
                        _vm._l(_vm.claimGiftList, function (item, index) {
                          return _c("tr", { key: "cncgift" + index }, [
                            _c("td", [_vm._v(_vm._s(item.giftcode))]),
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticClass: "img-thumb size60",
                                  class: {
                                    "no-image": _vm.$util.isNull(item.fullpath),
                                  },
                                },
                                [
                                  !_vm.$util.isNull(item.fullpath)
                                    ? _c("img", {
                                        attrs: { src: item.fullpath },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                            _c("td", { staticClass: "left no-left" }, [
                              _c("p", { staticClass: "mg0" }, [
                                _vm._v(_vm._s(item.giftname)),
                              ]),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.rcvamt))]),
                            _c("td", [_vm._v(_vm._s(item.promoname))]),
                          ])
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.$util.isNull(_vm.reapplyGiftList) &&
              _vm.reapplyGiftList.length > 0
                ? _c("div", { staticClass: "clearfix" }, [
                    _c("div", { staticClass: "bar-title small fl" }, [
                      _vm._v("재 적용 사은품"),
                    ]),
                    _c("div", { staticClass: "btn-group fr" }, [
                      _c("span", { staticClass: "txt-orange mr5" }, [
                        _vm._v("사은품을 다시 선택하시기 바랍니다."),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "btn blue-line",
                          attrs: { type: "button" },
                          on: { click: _vm.selectReapplyGiftList },
                        },
                        [_vm._v(" 사은품 선택 ")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              !_vm.$util.isNull(_vm.reapplyGiftList) &&
              _vm.reapplyGiftList.length > 0
                ? _c(
                    "table",
                    {
                      staticClass: "data-tb align-c mt0",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _c("caption", [_vm._v(" 사은품 목록 ")]),
                      _vm._m(8),
                      _vm._m(9),
                      !_vm.$util.isNull(_vm.chkReapplyGiftList) &&
                      _vm.chkReapplyGiftList.length > 0
                        ? _c(
                            "tbody",
                            _vm._l(
                              _vm.chkReapplyGiftList,
                              function (item, index) {
                                return _c("tr", { key: "chkgift" + index }, [
                                  _c("td", [_vm._v(_vm._s(item.giftcode))]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "img-thumb size60",
                                        class: {
                                          "no-image": _vm.$util.isNull(
                                            item.fullpath
                                          ),
                                        },
                                      },
                                      [
                                        !_vm.$util.isNull(item.fullpath)
                                          ? _c("img", {
                                              attrs: { src: item.fullpath },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                  _c("td", { staticClass: "left no-left" }, [
                                    _c("p", { staticClass: "mg0" }, [
                                      _vm._v(_vm._s(item.giftname)),
                                    ]),
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.$util.maskComma(item.rcvamt))
                                    ),
                                  ]),
                                  _c("td", [_vm._v(_vm._s(item.promoname))]),
                                ])
                              }
                            ),
                            0
                          )
                        : _c("tbody", [_vm._m(10)]),
                    ]
                  )
                : _vm._e(),
              _vm.clmtype !== _vm.$store.getters["ADMIN"].CLM_CANCEL
                ? [
                    _c("hr", { staticClass: "dash" }),
                    _c("div", { staticClass: "col2 pd scroll" }, [
                      _c("div", { staticClass: "left" }, [
                        _c("div", { staticClass: "bar-title small" }, [
                          _vm._v("회수지 정보"),
                        ]),
                        _c(
                          "table",
                          {
                            staticClass: "gray-tb",
                            attrs: { cellpadding: "0", cellspacing: "0" },
                          },
                          [
                            _vm._m(11),
                            _c("tbody", [
                              _c("tr", [
                                _c("th", [_vm._v("회수자 명")]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.recInfo.recname,
                                        expression: "recInfo.recname",
                                      },
                                    ],
                                    attrs: { type: "text", maxlength: "50" },
                                    domProps: { value: _vm.recInfo.recname },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.recInfo,
                                          "recname",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v("연락처")]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.recInfo.rectel,
                                        expression: "recInfo.rectel",
                                      },
                                    ],
                                    attrs: { type: "text", maxlength: "20" },
                                    domProps: { value: _vm.recInfo.rectel },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.recInfo,
                                          "rectel",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v("회수지 주소")]),
                                _c("td", { attrs: { colspan: "3" } }, [
                                  _c("div", { staticClass: "dpb" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.recInfo.recpost,
                                          expression: "recInfo.recpost",
                                        },
                                      ],
                                      staticClass: "short",
                                      attrs: {
                                        type: "text",
                                        maxlength: "7",
                                        readonly: "",
                                      },
                                      domProps: { value: _vm.recInfo.recpost },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.recInfo,
                                            "recpost",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn blue-line ml3",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.searchAddress("rec")
                                          },
                                        },
                                      },
                                      [_vm._v(" 주소검색 ")]
                                    ),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.recInfo.recaddrroad,
                                        expression: "recInfo.recaddrroad",
                                      },
                                    ],
                                    staticClass: "dpb",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "text",
                                      maxlength: "100",
                                      readonly: "",
                                    },
                                    domProps: {
                                      value: _vm.recInfo.recaddrroad,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.recInfo,
                                          "recaddrroad",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.recInfo.recaddrdetailroad,
                                        expression: "recInfo.recaddrdetailroad",
                                      },
                                    ],
                                    staticClass: "dpb",
                                    staticStyle: { width: "100%" },
                                    attrs: { type: "text", maxlength: "100" },
                                    domProps: {
                                      value: _vm.recInfo.recaddrdetailroad,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.recInfo,
                                          "recaddrdetailroad",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "right" }, [
                        _c("div", { staticClass: "bar-title small" }, [
                          _vm._v("회수택배사 정보"),
                        ]),
                        _c(
                          "table",
                          {
                            staticClass: "gray-tb",
                            attrs: { cellpadding: "0", cellspacing: "0" },
                          },
                          [
                            _vm._m(12),
                            _c("tbody", [
                              _c("tr", [
                                _c("th", [_vm._v("회수 택배사")]),
                                _c("td", [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.recInfo.reclogistype,
                                          expression: "recInfo.reclogistype",
                                        },
                                      ],
                                      staticStyle: { width: "160px" },
                                      attrs: { disabled: "" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.recInfo,
                                            "reclogistype",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        { domProps: { value: null } },
                                        [_vm._v("선택")]
                                      ),
                                      _vm._l(_vm.rtnlogislist, function (row) {
                                        return _c(
                                          "option",
                                          {
                                            key: row.idx,
                                            domProps: { value: row.logistype },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(row.logistname) + " "
                                            ),
                                          ]
                                        )
                                      }),
                                    ],
                                    2
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.recInfo.recinvoiceno,
                                        expression: "recInfo.recinvoiceno",
                                      },
                                    ],
                                    staticClass: "ml3",
                                    attrs: {
                                      type: "text",
                                      placeholder: "송장번호",
                                      maxlength: "50",
                                      disabled: "",
                                      oninput:
                                        "this.value = this.value.replace(/([^0-9])/gi, '');",
                                    },
                                    domProps: {
                                      value: _vm.recInfo.recinvoiceno,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.recInfo,
                                          "recinvoiceno",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v("회수 완료일")]),
                                _c(
                                  "td",
                                  [
                                    _c("CommonDatePicker", {
                                      attrs: {
                                        value: _vm.recInfo.reccompdate,
                                        disable: true,
                                      },
                                      on: { change: _vm.onChangeRecDate },
                                    }),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.recInfo.reccomptime,
                                          expression: "recInfo.reccomptime",
                                        },
                                      ],
                                      staticClass: "ml3",
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: _vm.recInfo.reccomptime,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.recInfo,
                                            "reccomptime",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v("상태")]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.recInfo.recstatname,
                                        expression: "recInfo.recstatname",
                                      },
                                    ],
                                    attrs: { type: "text", readonly: "" },
                                    domProps: {
                                      value: _vm.recInfo.recstatname,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.recInfo,
                                          "recstatname",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line ml3",
                                      attrs: { type: "button", disabled: "" },
                                    },
                                    [_vm._v("배송조회")]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _c("hr", { staticClass: "dash" }),
                  ]
                : _vm._e(),
              _vm.clmtype === _vm.$store.getters["ADMIN"].CLM_EXCHANGE
                ? [
                    _c("div", { staticClass: "col2 pd scroll" }, [
                      _c("div", { staticClass: "left" }, [
                        _c("div", { staticClass: "bar-title small" }, [
                          _vm._v("배송지 정보"),
                        ]),
                        _c(
                          "table",
                          {
                            staticClass: "gray-tb",
                            attrs: { cellpadding: "0", cellspacing: "0" },
                          },
                          [
                            _vm._m(13),
                            _c("tbody", [
                              _c("tr", [
                                _c("th", [_vm._v("수령인명")]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.excInfo.excdlvname,
                                        expression: "excInfo.excdlvname",
                                      },
                                    ],
                                    attrs: { type: "text", maxlength: "50" },
                                    domProps: { value: _vm.excInfo.excdlvname },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.excInfo,
                                          "excdlvname",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v("연락처")]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.excInfo.excdlvtel,
                                        expression: "excInfo.excdlvtel",
                                      },
                                    ],
                                    attrs: { type: "text", maxlength: "20" },
                                    domProps: { value: _vm.excInfo.excdlvtel },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.excInfo,
                                          "excdlvtel",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v("배송지 주소")]),
                                _c("td", { attrs: { colspan: "3" } }, [
                                  _c("div", { staticClass: "dpb" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.excInfo.excpost,
                                          expression: "excInfo.excpost",
                                        },
                                      ],
                                      staticClass: "short",
                                      attrs: {
                                        type: "text",
                                        maxlength: "7",
                                        readonly: "",
                                      },
                                      domProps: { value: _vm.excInfo.excpost },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.excInfo,
                                            "excpost",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn blue-line ml3",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.searchAddress("exc")
                                          },
                                        },
                                      },
                                      [_vm._v(" 주소검색 ")]
                                    ),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.excInfo.excdlvaddrroad,
                                        expression: "excInfo.excdlvaddrroad",
                                      },
                                    ],
                                    staticClass: "dpb",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "text",
                                      maxlength: "100",
                                      readonly: "",
                                    },
                                    domProps: {
                                      value: _vm.excInfo.excdlvaddrroad,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.excInfo,
                                          "excdlvaddrroad",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.excInfo.excdlvaddrdetailroad,
                                        expression:
                                          "excInfo.excdlvaddrdetailroad",
                                      },
                                    ],
                                    staticClass: "dpb",
                                    staticStyle: { width: "100%" },
                                    attrs: { type: "text", maxlength: "100" },
                                    domProps: {
                                      value: _vm.excInfo.excdlvaddrdetailroad,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.excInfo,
                                          "excdlvaddrdetailroad",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "right" }, [
                        _c("div", { staticClass: "bar-title small" }, [
                          _vm._v("배송택배사 정보"),
                        ]),
                        _c(
                          "table",
                          {
                            staticClass: "gray-tb",
                            attrs: { cellpadding: "0", cellspacing: "0" },
                          },
                          [
                            _vm._m(14),
                            _c("tbody", [
                              _c("tr", [
                                _c("th", [_vm._v("배송 택배사")]),
                                _c("td", [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.excInfo.exclogistype,
                                          expression: "excInfo.exclogistype",
                                        },
                                      ],
                                      staticStyle: { width: "160px" },
                                      attrs: { disabled: "" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.excInfo,
                                            "exclogistype",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        { domProps: { value: null } },
                                        [_vm._v("선택")]
                                      ),
                                      _vm._l(
                                        _vm.commonCode.logistype,
                                        function (logistype) {
                                          return _c(
                                            "option",
                                            {
                                              key: logistype.cmcode,
                                              domProps: {
                                                value: logistype.cmcode,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(logistype.codename) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.excInfo.excinvoiceno,
                                        expression: "excInfo.excinvoiceno",
                                      },
                                    ],
                                    staticClass: "ml3",
                                    attrs: {
                                      type: "text",
                                      placeholder: "송장번호",
                                      maxlength: "50",
                                      disabled: "",
                                      oninput:
                                        "this.value = this.value.replace(/([^0-9])/gi, '');",
                                    },
                                    domProps: {
                                      value: _vm.excInfo.excinvoiceno,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.excInfo,
                                          "excinvoiceno",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v("배송 완료일")]),
                                _c(
                                  "td",
                                  [
                                    _c("CommonDatePicker", {
                                      attrs: {
                                        value: _vm.excInfo.exccompdate,
                                        disable: true,
                                      },
                                      on: { change: _vm.onChangeRecDate },
                                    }),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.excInfo.exccomptime,
                                          expression: "excInfo.exccomptime",
                                        },
                                      ],
                                      staticClass: "ml3",
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: _vm.excInfo.exccomptime,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.excInfo,
                                            "exccomptime",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v("상태")]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.excInfo.excstatname,
                                        expression: "excInfo.excstatname",
                                      },
                                    ],
                                    attrs: { type: "text", readonly: "" },
                                    domProps: {
                                      value: _vm.excInfo.excstatname,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.excInfo,
                                          "excstatname",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line ml3",
                                      attrs: { type: "button", disabled: "" },
                                    },
                                    [_vm._v("배송조회")]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _c("hr", { staticClass: "dash" }),
                  ]
                : _vm._e(),
              _c("div", { staticClass: "col3 pd scroll" }, [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticClass: "bar-title small" }, [
                    _vm._v("이전 주문정보"),
                  ]),
                  _c(
                    "table",
                    {
                      staticClass: "gray-tb lower",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _vm._m(15),
                      _c(
                        "tbody",
                        [
                          _c("tr", [
                            _c("th", [_vm._v("판매금액 합계")]),
                            _c(
                              "td",
                              { staticClass: "right", attrs: { colspan: "2" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.clmInfo.bforder.ordtotprice
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("자사 배송비(+)")]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.clmInfo.bforder.isolatetype === "N"
                                      ? "기본배송비"
                                      : "도서산간배송비"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.maskComma(
                                    _vm.clmInfo.bforder.dadadelivamt
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("파트너사 배송비(+)")]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.clmInfo.bforder.isolatetype === "N"
                                      ? "기본배송비"
                                      : "도서산간배송비"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.maskComma(
                                    _vm.clmInfo.bforder.ptndelivamt
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm.$util.isNull(_vm.clmInfo.bfpromotion) ||
                          _vm.clmInfo.bfpromotion.length === 0
                            ? _c("tr", [
                                _c("th", [_vm._v("프로모션 할인")]),
                                _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                                _c("td", { staticClass: "right txt-red" }, [
                                  _vm._v("0"),
                                ]),
                              ])
                            : _vm._l(
                                _vm.clmInfo.bfpromotion,
                                function (promoRow, index) {
                                  return _c("tr", { key: promoRow.idx }, [
                                    index === 0
                                      ? _c(
                                          "th",
                                          {
                                            attrs: {
                                              rowspan:
                                                _vm.clmInfo.bfpromotion.length,
                                            },
                                          },
                                          [_vm._v("프로모션 할인")]
                                        )
                                      : _vm._e(),
                                    _c("td", [
                                      _vm._v(_vm._s(promoRow.promoname)),
                                    ]),
                                    _c("td", { staticClass: "right txt-red" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$util.maskComma(
                                              Number(promoRow.disamount) * -1
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                }
                              ),
                          _vm.$util.isNull(_vm.clmInfo.bfgoodscoupon) ||
                          _vm.clmInfo.bfgoodscoupon.length === 0
                            ? _c("tr", [
                                _c("th", [_vm._v("상품 할인")]),
                                _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                                _c("td", { staticClass: "right txt-red" }, [
                                  _vm._v("0"),
                                ]),
                              ])
                            : _vm._l(
                                _vm.clmInfo.bfgoodscoupon,
                                function (goodsCpnRow, index) {
                                  return _c("tr", { key: goodsCpnRow.idx }, [
                                    index === 0
                                      ? _c(
                                          "th",
                                          {
                                            attrs: {
                                              rowspan:
                                                _vm.clmInfo.bfgoodscoupon
                                                  .length,
                                            },
                                          },
                                          [_vm._v("상품 할인")]
                                        )
                                      : _vm._e(),
                                    _c("td", [
                                      _vm._v(_vm._s(goodsCpnRow.cpnname)),
                                    ]),
                                    _c("td", { staticClass: "right txt-red" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$util.maskComma(
                                              Number(goodsCpnRow.disamount) * -1
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                }
                              ),
                          _c("tr", [
                            _c("th", [_vm._v("장바구니 할인")]),
                            !_vm.$util.isNull(_vm.clmInfo.bfbasketcoupon) &&
                            _vm.clmInfo.bforder.basketcpnamt > 0
                              ? _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.clmInfo.bfbasketcoupon.cpnname
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                            _c("td", { staticClass: "right txt-red" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(_vm.clmInfo.bforder.basketcpnamt) *
                                        -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("배송비 할인")]),
                            !_vm.$util.isNull(_vm.clmInfo.bfdelivcoupon) &&
                            _vm.clmInfo.bforder.totdelivcpnamt > 0
                              ? _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.clmInfo.bfdelivcoupon.cpnname
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                            _c("td", { staticClass: "right txt-red" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(
                                        _vm.clmInfo.bforder.totdelivcpnamt
                                      ) * -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("임직원적립금 사용(-)")]),
                            _c(
                              "td",
                              {
                                staticClass: "right txt-red",
                                attrs: { colspan: "2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        Number(
                                          _vm.clmInfo.bforder.empreservetotamt
                                        ) * -1
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("적립금 사용(-)")]),
                            _c(
                              "td",
                              {
                                staticClass: "right txt-red",
                                attrs: { colspan: "2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        Number(
                                          _vm.clmInfo.bforder.reservetotamt
                                        ) * -1
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("D포인트 사용(-)")]),
                            _c(
                              "td",
                              {
                                staticClass: "right txt-red",
                                attrs: { colspan: "2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        Number(
                                          _vm.clmInfo.bforder.epointtotamt
                                        ) * -1
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("실 결제금액")]),
                            _c(
                              "td",
                              { staticClass: "right", attrs: { colspan: "2" } },
                              [
                                _c("strong", { staticClass: "large-txt" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.clmInfo.bforder.rpaytotprice
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "middle" }, [
                  _c("div", { staticClass: "bar-title small" }, [
                    _vm._v("재 계산 판매금액 합계"),
                  ]),
                  _c(
                    "table",
                    {
                      staticClass: "gray-tb lower",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _vm._m(16),
                      _c(
                        "tbody",
                        [
                          _c("tr", [
                            _c("th", [_vm._v("재 계산 주문금액 합계")]),
                            _c(
                              "td",
                              { staticClass: "right", attrs: { colspan: "2" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.clmInfo.aforder.ordtotprice
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("자사 배송비(+)")]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.clmInfo.bforder.isolatetype === "N"
                                      ? "기본배송비"
                                      : "도서산간배송비"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.maskComma(
                                    _vm.clmInfo.aforder.dadadelivamt
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("파트너사 배송비(+)")]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.clmInfo.bforder.isolatetype === "N"
                                      ? "기본배송비"
                                      : "도서산간배송비"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.maskComma(
                                    _vm.clmInfo.aforder.ptndelivamt
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm.$util.isNull(_vm.clmInfo.afpromotion) ||
                          _vm.clmInfo.afpromotion.length === 0
                            ? _c("tr", [
                                _c("th", [_vm._v("프로모션 할인")]),
                                _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                                _c("td", { staticClass: "right txt-red" }, [
                                  _vm._v("0"),
                                ]),
                              ])
                            : _vm._l(
                                _vm.clmInfo.afpromotion,
                                function (promoRow, index) {
                                  return _c("tr", { key: promoRow.idx }, [
                                    index === 0
                                      ? _c(
                                          "th",
                                          {
                                            attrs: {
                                              rowspan:
                                                _vm.clmInfo.afpromotion.length,
                                            },
                                          },
                                          [_vm._v("프로모션 할인")]
                                        )
                                      : _vm._e(),
                                    _c("td", [
                                      _vm._v(_vm._s(promoRow.promoname)),
                                    ]),
                                    _c("td", { staticClass: "right txt-red" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$util.maskComma(
                                              Number(promoRow.disamount) * -1
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                }
                              ),
                          _vm.$util.isNull(_vm.clmInfo.afgoodscoupon) ||
                          _vm.clmInfo.afgoodscoupon.length === 0
                            ? _c("tr", [
                                _c("th", [_vm._v("상품 할인")]),
                                _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                                _c("td", { staticClass: "right txt-red" }, [
                                  _vm._v("0"),
                                ]),
                              ])
                            : _vm._l(
                                _vm.clmInfo.afgoodscoupon,
                                function (goodsCpnRow, index) {
                                  return _c("tr", { key: goodsCpnRow.idx }, [
                                    index === 0
                                      ? _c(
                                          "th",
                                          {
                                            attrs: {
                                              rowspan:
                                                _vm.clmInfo.afgoodscoupon
                                                  .length,
                                            },
                                          },
                                          [_vm._v("상품 할인")]
                                        )
                                      : _vm._e(),
                                    _c("td", [
                                      _vm._v(_vm._s(goodsCpnRow.cpnname)),
                                    ]),
                                    _c("td", { staticClass: "right txt-red" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$util.maskComma(
                                              Number(goodsCpnRow.disamount) * -1
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                }
                              ),
                          _c("tr", [
                            _c("th", [_vm._v("장바구니 할인")]),
                            !_vm.$util.isNull(_vm.clmInfo.afbasketcoupon) &&
                            _vm.clmInfo.aforder.basketcpnamt > 0
                              ? _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.clmInfo.afbasketcoupon.cpnname
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                            _c("td", { staticClass: "right txt-red" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(_vm.clmInfo.aforder.basketcpnamt) *
                                        -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("배송비 할인")]),
                            !_vm.$util.isNull(_vm.clmInfo.afdelivcoupon) &&
                            _vm.clmInfo.aforder.totdelivcpnamt > 0
                              ? _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.clmInfo.afdelivcoupon.cpnname
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("td", { staticClass: "txt-gray2" }, [
                                  _vm._v("(적용할인없음)"),
                                ]),
                            _c("td", { staticClass: "right txt-red" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(
                                        _vm.clmInfo.aforder.totdelivcpnamt
                                      ) * -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("임직원적립금 사용(-)")]),
                            _c(
                              "td",
                              {
                                staticClass: "right txt-red",
                                attrs: { colspan: "2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        Number(
                                          _vm.clmInfo.aforder.empreservetotamt
                                        ) * -1
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("적립금 사용(-)")]),
                            _c(
                              "td",
                              {
                                staticClass: "right txt-red",
                                attrs: { colspan: "2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        Number(
                                          _vm.clmInfo.aforder.reservetotamt
                                        ) * -1
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("D포인트 사용(-)")]),
                            _c(
                              "td",
                              {
                                staticClass: "right txt-red",
                                attrs: { colspan: "2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        Number(
                                          _vm.clmInfo.aforder.epointtotamt
                                        ) * -1
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("재 계산 최종금액")]),
                            _c(
                              "td",
                              { staticClass: "right", attrs: { colspan: "2" } },
                              [
                                _c("strong", { staticClass: "large-txt" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$util.maskComma(
                                        _vm.clmInfo.aforder.rpaytotprice
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    Number(_vm.clmInfo.calinfo.rtndadadelivamt) +
                      Number(_vm.clmInfo.calinfo.rtnptndelivamt) >
                    0
                      ? [
                          _c("div", { staticClass: "bar-title small" }, [
                            _vm._v("차감배송비"),
                          ]),
                          _c(
                            "table",
                            {
                              staticClass: "gray-tb lower",
                              attrs: { cellpadding: "0", cellspacing: "0" },
                            },
                            [
                              _vm._m(17),
                              _c("tbody", [
                                _c("tr", [
                                  _c("th", [_vm._v("자사배송비")]),
                                  _c("td", { staticClass: "right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$util.maskComma(
                                          _vm.clmInfo.calinfo.rtndadadelivamt
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [_vm._v("파트너사 배송비")]),
                                  _c("td", { staticClass: "right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$util.maskComma(
                                          _vm.clmInfo.calinfo.rtnptndelivamt
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _vm.clmtype !== _vm.$store.getters["ADMIN"].CLM_CANCEL
                      ? [
                          _c("div", { staticClass: "bar-title small" }, [
                            _vm._v("추가배송비"),
                          ]),
                          _c(
                            "table",
                            {
                              staticClass: "gray-tb lower",
                              attrs: { cellpadding: "0", cellspacing: "0" },
                            },
                            [
                              _vm._m(18),
                              _c("tbody", [
                                _c("tr", [
                                  _c("th", [_vm._v("자사배송비")]),
                                  _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.clmInfo.calinfo.adddadadelivamt,
                                          expression:
                                            "clmInfo.calinfo.adddadadelivamt",
                                        },
                                      ],
                                      staticClass: "dpb right",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "text",
                                        maxlength: "11",
                                        disabled: _vm.ispbgoods === "F",
                                        oninput:
                                          "this.value = this.value.replace(/(^0[0-9]|[^0-9])/gi, '');",
                                      },
                                      domProps: {
                                        value:
                                          _vm.clmInfo.calinfo.adddadadelivamt,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.clmInfo.calinfo,
                                            "adddadadelivamt",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [_vm._v("파트너사 배송비")]),
                                  _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.clmInfo.calinfo.addptndelivamt,
                                          expression:
                                            "clmInfo.calinfo.addptndelivamt",
                                        },
                                      ],
                                      staticClass: "dpb right",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "text",
                                        maxlength: "11",
                                        disabled: _vm.ispbgoods === "T",
                                        oninput:
                                          "this.value = this.value.replace(/(^0[0-9]|[^0-9])/gi, '');",
                                      },
                                      domProps: {
                                        value:
                                          _vm.clmInfo.calinfo.addptndelivamt,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.clmInfo.calinfo,
                                            "addptndelivamt",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _vm.clmtype === _vm.$store.getters["ADMIN"].CLM_CANCEL &&
                    _vm.isAddPayment
                      ? [
                          _vm._m(19),
                          _c(
                            "table",
                            {
                              staticClass: "gray-tb lower",
                              attrs: { cellpadding: "0", cellspacing: "0" },
                            },
                            [
                              _vm._m(20),
                              _c("tbody", [
                                _c("tr", [
                                  _c("th", [_vm._v("결제예정금액")]),
                                  _c("td", { staticClass: "right" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.clmInfo.calinfo.addrpaytotprice,
                                          expression:
                                            "clmInfo.calinfo.addrpaytotprice",
                                        },
                                      ],
                                      staticClass: "right",
                                      staticStyle: { width: "100%" },
                                      attrs: { type: "text", maxlength: "11" },
                                      domProps: {
                                        value:
                                          _vm.clmInfo.calinfo.addrpaytotprice,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.clmInfo.calinfo,
                                            "addrpaytotprice",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _c("div", { staticClass: "bar-title small" }, [
                      _vm._v("환불 금액"),
                    ]),
                    _c(
                      "table",
                      {
                        staticClass: "gray-tb lower",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _vm._m(21),
                        _c("tbody", [
                          _c("tr", [
                            _c("th", [_vm._v("환불예정금액")]),
                            _c("td", { staticClass: "right" }, [
                              _c("strong", { staticClass: "large-txt" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      _vm.clmInfo.calinfo.rtnamt
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _vm.clmInfo.bforder.paywaytype ===
                    _vm.$store.getters["ADMIN"].PAY_VIRTURE_ACCOUNT
                      ? [
                          _c("div", { staticClass: "bar-title small fl mb0" }, [
                            _vm._v("환불계좌 정보"),
                          ]),
                          _c("div", { staticClass: "btn-group fr" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn small blue-line",
                                attrs: { type: "button" },
                                on: { click: _vm.accountConfirm },
                              },
                              [_vm._v(" 환불계좌 인증 ")]
                            ),
                          ]),
                          _c(
                            "table",
                            {
                              staticClass: "gray-tb lower",
                              attrs: { cellpadding: "0", cellspacing: "0" },
                            },
                            [
                              _vm._m(22),
                              _c("tbody", [
                                _c("tr", [
                                  _c("th", [_vm._v("예금주명/생년월일")]),
                                  _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.refInfo.refcusname,
                                          expression: "refInfo.refcusname",
                                        },
                                      ],
                                      staticStyle: { width: "115px" },
                                      attrs: {
                                        type: "text",
                                        placeholder: "예금주명",
                                        maxlength: "50",
                                      },
                                      domProps: {
                                        value: _vm.refInfo.refcusname,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.refInfo,
                                            "refcusname",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.refInfo.refbirthdate,
                                          expression: "refInfo.refbirthdate",
                                        },
                                      ],
                                      staticClass: "ml3",
                                      staticStyle: { width: "220px" },
                                      attrs: {
                                        type: "text",
                                        placeholder: "생년월일 6자리 입력",
                                        maxlength: "6",
                                        oninput:
                                          "this.value = this.value.replace(/([^0-9])/gi, '');",
                                      },
                                      domProps: {
                                        value: _vm.refInfo.refbirthdate,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.refInfo,
                                            "refbirthdate",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [_vm._v("환불은행/계좌번호")]),
                                  _c("td", [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.refInfo.refbank,
                                            expression: "refInfo.refbank",
                                          },
                                        ],
                                        staticStyle: { width: "115px" },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.refInfo,
                                              "refbank",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          { domProps: { value: null } },
                                          [_vm._v("은행 선택")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "002" } },
                                          [_vm._v("산업은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "003" } },
                                          [_vm._v("기업은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "004" } },
                                          [_vm._v("국민은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "007" } },
                                          [_vm._v("수협은행/수협중앙회")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "011" } },
                                          [_vm._v("농협은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "012" } },
                                          [_vm._v("농협중앙회")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "020" } },
                                          [_vm._v("우리은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "023" } },
                                          [_vm._v("SC제일은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "027" } },
                                          [_vm._v("한국씨티은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "031" } },
                                          [_vm._v("대구은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "032" } },
                                          [_vm._v("부산은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "034" } },
                                          [_vm._v("광주은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "035" } },
                                          [_vm._v("제주은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "037" } },
                                          [_vm._v("전북은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "039" } },
                                          [_vm._v("경남은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "045" } },
                                          [_vm._v("새마을금고중앙회")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "048" } },
                                          [_vm._v("신협중앙회")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "050" } },
                                          [_vm._v("상호저축은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "054" } },
                                          [_vm._v("HSBC은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "055" } },
                                          [_vm._v("도이치은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "057" } },
                                          [_vm._v("제이피모간체이스은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "060" } },
                                          [_vm._v("BOA은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "062" } },
                                          [_vm._v("중국공상은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "064" } },
                                          [_vm._v("산림조합중앙회")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "071" } },
                                          [_vm._v("우체국")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "081" } },
                                          [_vm._v("KEB하나은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "088" } },
                                          [_vm._v("신한은행")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "089" } },
                                          [_vm._v("K뱅크")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "090" } },
                                          [_vm._v("카카오뱅크")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "209" } },
                                          [_vm._v("유안타증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "218" } },
                                          [_vm._v("KB증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "238" } },
                                          [_vm._v("미래에셋대우")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "240" } },
                                          [_vm._v("삼성증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "243" } },
                                          [_vm._v("한국투자증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "247" } },
                                          [_vm._v("NH투자증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "261" } },
                                          [_vm._v("교보증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "262" } },
                                          [_vm._v("하이투자증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "263" } },
                                          [_vm._v("현대차투자증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "264" } },
                                          [_vm._v("키움증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "265" } },
                                          [_vm._v("이베스트투자증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "266" } },
                                          [_vm._v("SK증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "267" } },
                                          [_vm._v("대신증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "269" } },
                                          [_vm._v("한화투자증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "270" } },
                                          [_vm._v("하나금융투자")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "278" } },
                                          [_vm._v("신한금융투자")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "279" } },
                                          [_vm._v("동부증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "280" } },
                                          [_vm._v("유진투자증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "287" } },
                                          [_vm._v("메리츠종합금융증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "290" } },
                                          [_vm._v("부국증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "291" } },
                                          [_vm._v("신영증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "292" } },
                                          [_vm._v("케이프투자증권")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "103" } },
                                          [_vm._v("SBI 저축은행")]
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.refInfo.refaccnumber,
                                          expression: "refInfo.refaccnumber",
                                        },
                                      ],
                                      staticClass: "ml3",
                                      staticStyle: { width: "220px" },
                                      attrs: {
                                        type: "text",
                                        placeholder:
                                          "계좌번호 '-'없이 숫자 입력",
                                        maxlength: "50",
                                        oninput:
                                          "this.value = this.value.replace(/([^0-9])/gi, '');",
                                      },
                                      domProps: {
                                        value: _vm.refInfo.refaccnumber,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.refInfo,
                                            "refaccnumber",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _c("div", { staticClass: "bar-title small" }, [
                      _vm._v("최종환불"),
                    ]),
                    _c(
                      "table",
                      {
                        staticClass: "gray-tb lower",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _vm._m(23),
                        _c("tbody", [
                          _c("tr", [
                            _c("th", [_vm._v("금액(PG)")]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.clmInfo.bforder.paywaytypename)
                              ),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _c("strong", { staticClass: "large-txt" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      _vm.clmInfo.calinfo.rtnpayamt
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("임직원적립금 반환")]),
                            _c("td"),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.maskComma(
                                    _vm.clmInfo.calinfo.rtnempresamt
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("적립금 반환")]),
                            _c("td"),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.maskComma(
                                    _vm.clmInfo.calinfo.rtnresamt
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("D포인트 반환")]),
                            _c("td"),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.maskComma(
                                    _vm.clmInfo.calinfo.rtnepoamt
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "btn-group" }, [
                _vm.isCncProc
                  ? _c(
                      "button",
                      {
                        staticClass: "btn big blue",
                        attrs: { type: "button" },
                        on: { click: _vm.saveClaim },
                      },
                      [_vm._v(" 취소처리 ")]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn big blue",
                        attrs: { type: "button" },
                        on: { click: _vm.saveClaim },
                      },
                      [_vm._v(" " + _vm._s(_vm.clmname) + "신청 ")]
                    ),
                _c(
                  "button",
                  {
                    staticClass: "btn big darkgray",
                    attrs: { type: "button" },
                    on: { click: _vm.onClose },
                  },
                  [_vm._v("닫기")]
                ),
              ]),
            ],
            2
          ),
        ]
      ),
      _vm.isShowMemDetail
        ? _c("AdminMemberInfo", {
            attrs: { activeUserNo: _vm.activeUserNo },
            on: { closeDetail: _vm.closeMemDetail },
          })
        : _vm._e(),
      _vm.isGoodsDetailShow
        ? _c("GoodsDetail", {
            attrs: { activeGoodsNo: _vm.activeGoodsNo },
            on: { closePopup: _vm.closeGoodsDetail },
          })
        : _vm._e(),
      _vm.isShowAddGift
        ? _c("AddReapplyGiftPopup", {
            attrs: { reapplyGiftList: _vm.reapplyGiftList },
            on: { closePopup: _vm.closeAddGiftPopup },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "11%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "6.5%" } }),
      _c("col", { attrs: { width: "6.5%" } }),
      _c("col", { attrs: { width: "5%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix mt20" }, [
      _c("div", { staticClass: "bar-title small fl" }, [
        _vm._v("교환출고상품"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "6.5%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("판매구분")]),
        _c("th", [_vm._v("파트너사")]),
        _c("th", [_vm._v("상품코드")]),
        _c("th", [_vm._v("상품순번")]),
        _c("th", [_vm._v("상품명/옵션변경")]),
        _c("th", [_vm._v("교환수량")]),
        _c("th", [_vm._v("교환금액")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "7" } }, [
        _vm._v("교환 가능한 상품이 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "35%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("사은품코드")]),
        _c("th", { attrs: { colspan: "2" } }, [
          _vm._v("사은품명"),
          _c("button", { staticClass: "sort down", attrs: { type: "button" } }),
        ]),
        _c("th", [_vm._v("수량")]),
        _c("th", [_vm._v("적용프로모션 명")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "35%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("사은품코드")]),
        _c("th", { attrs: { colspan: "2" } }, [
          _vm._v("사은품명"),
          _c("button", { staticClass: "sort down", attrs: { type: "button" } }),
        ]),
        _c("th", [_vm._v("수량")]),
        _c("th", [_vm._v("적용프로모션 명")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "5" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "230px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "230px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bar-title small" }, [
      _vm._v(" 추가결제 예정금액"),
      _c("span", { staticClass: "normal txt-orange ml10" }, [
        _vm._v("고객 직접 결제"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "40%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }