var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tab-area",
      style: _vm.isPartner
        ? "height: calc(90vh - 100px);"
        : "height: calc(90vh - 290px);",
    },
    [
      _vm._l(_vm.claimList, function (item, index) {
        return [
          index > 0
            ? _c("hr", { key: "dash" + index, staticClass: "dash" })
            : _vm._e(),
          _c("div", { key: "title" + index, staticClass: "clearfix" }, [
            _c("div", { staticClass: "bar-title small fl" }, [
              _vm._v("반품상품 [반품 " + _vm._s(index + 1) + "차]"),
            ]),
          ]),
          _c(
            "table",
            {
              key: "list" + index,
              staticClass: "data-tb align-c mt0",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _c("caption", [_vm._v(" 반품상품 목록 ")]),
              _vm._m(0, true),
              _vm._m(1, true),
              _c(
                "tbody",
                _vm._l(item.claimgoodslist, function (row) {
                  return _c("tr", { key: row.clmgdidx }, [
                    _c("td", [_vm._v(_vm._s(row.clmreqdate))]),
                    _c("td", [_vm._v(_vm._s(row.clmcompdate))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(row.ispbgoods === "T" ? "자사" : "파트너사")
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(row.dealernm))]),
                    _c("td", [_vm._v(_vm._s(row.goodscode))]),
                    _c("td", [_vm._v(_vm._s(row.optioncode))]),
                    _c("td", [
                      _c(
                        "div",
                        {
                          staticClass: "img-thumb size60 link",
                          class: { "no-image": _vm.$util.isNull(row.fullpath) },
                        },
                        [
                          !_vm.$util.isNull(row.fullpath)
                            ? _c("img", { attrs: { src: row.fullpath } })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _c("td", { staticClass: "left no-left" }, [
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function ($event) {
                              return _vm.goGoodsDetail(row.goodsno)
                            },
                          },
                        },
                        [_vm._v(_vm._s(row.goodsname))]
                      ),
                    ]),
                    _c("td", { staticStyle: { "white-space": "pre-wrap" } }, [
                      _vm._v(_vm._s(row.optionname)),
                    ]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.$util.maskComma(row.bfordcnt))),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.$util.maskComma(row.clmcnt)))]),
                    _c("td", { staticClass: "right" }, [
                      _vm._v(_vm._s(_vm.$util.maskComma(row.price))),
                    ]),
                    _c("td", { staticClass: "right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$util.maskComma(
                            Number(row.price) * Number(row.bfordcnt)
                          )
                        )
                      ),
                    ]),
                    _c("td", { staticClass: "right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$util.maskComma(
                            Number(row.price) * Number(row.clmcnt)
                          )
                        )
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.rtntype ===
                              _vm.$store.getters["ADMIN"].RTN_DIS_SATISFACTION
                              ? "고객"
                              : "판매자"
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function ($event) {
                              return _vm.goClaimHistory(row.clmgdidx)
                            },
                          },
                        },
                        [_vm._v(_vm._s(row.boclmstatusnm))]
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function ($event) {
                              return _vm.goReturnDetail(row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(row.clmno))]
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
          _c("div", { key: "orderInfo" + index, staticClass: "col3 pd" }, [
            _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "bar-title small" }, [
                _vm._v("이전 주문정보"),
              ]),
              _c(
                "table",
                {
                  staticClass: "gray-tb lower",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(2, true),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("판매금액 합계")]),
                      _c(
                        "td",
                        { staticClass: "right", attrs: { colspan: "2" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$util.maskComma(item.bfordtotprice))
                          ),
                        ]
                      ),
                    ]),
                    !_vm.isPartner
                      ? _c("tr", [
                          _c("th", [_vm._v("자사 배송비(+)")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                item.isolatetype === "N"
                                  ? "기본배송비"
                                  : "도서산간배송비"
                              )
                            ),
                          ]),
                          _c("td", { staticClass: "right" }, [
                            _vm._v(
                              _vm._s(_vm.$util.maskComma(item.bfdadadelivamt))
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("tr", [
                      _c("th", [_vm._v("파트너사 배송비(+)")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            item.isolatetype === "N"
                              ? "기본배송비"
                              : "도서산간배송비"
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "right" }, [
                        _vm._v(_vm._s(_vm.$util.maskComma(item.bfptndelivamt))),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("프로모션 할인")]),
                      !_vm.$util.isNull(item.bftotsalepromoamt) &&
                      Number(item.bftotsalepromoamt) > 0
                        ? _c("td", { staticClass: "txt-gray2" })
                        : _c("td", { staticClass: "txt-gray2" }, [
                            _vm._v("(적용할인없음)"),
                          ]),
                      _c("td", { staticClass: "right txt-red" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$util.maskComma(
                                Number(item.bftotsalepromoamt) * -1
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("상품 할인")]),
                      !_vm.$util.isNull(item.bftotgoodscpnamt) &&
                      Number(item.bftotgoodscpnamt) > 0
                        ? _c("td", { staticClass: "txt-gray2" })
                        : _c("td", { staticClass: "txt-gray2" }, [
                            _vm._v("(적용할인없음)"),
                          ]),
                      _c("td", { staticClass: "right txt-red" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$util.maskComma(
                                Number(item.bftotgoodscpnamt) * -1
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("장바구니 할인")]),
                      !_vm.$util.isNull(item.bfbasketcpnamt) &&
                      Number(item.bfbasketcpnamt) > 0
                        ? _c("td", { staticClass: "txt-gray2" })
                        : _c("td", { staticClass: "txt-gray2" }, [
                            _vm._v("(적용할인없음)"),
                          ]),
                      _c("td", { staticClass: "right txt-red" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$util.maskComma(
                                Number(item.bfbasketcpnamt) * -1
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("배송비 할인")]),
                      !_vm.$util.isNull(item.bftotdelivcpnamt) &&
                      Number(item.bftotdelivcpnamt) > 0
                        ? _c("td", { staticClass: "txt-gray2" })
                        : _c("td", { staticClass: "txt-gray2" }, [
                            _vm._v("(적용할인없음)"),
                          ]),
                      _c("td", { staticClass: "right txt-red" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$util.maskComma(
                                Number(item.bftotdelivcpnamt) * -1
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    !_vm.isPartner
                      ? _c("tr", [
                          _c("th", [_vm._v("임직원적립금 사용(-)")]),
                          _c(
                            "td",
                            {
                              staticClass: "right txt-red",
                              attrs: { colspan: "2" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(item.bfempreservetotamt) * -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    !_vm.isPartner
                      ? _c("tr", [
                          _c("th", [_vm._v("적립금 사용(-)")]),
                          _c(
                            "td",
                            {
                              staticClass: "right txt-red",
                              attrs: { colspan: "2" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(item.bfreservetotamt) * -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    !_vm.isPartner
                      ? _c("tr", [
                          _c("th", [_vm._v("D포인트 사용(-)")]),
                          _c(
                            "td",
                            {
                              staticClass: "right txt-red",
                              attrs: { colspan: "2" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(item.bfepointtotamt) * -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.isPartner
                      ? _c("tr", [
                          _c("th", [_vm._v("적립금 사용(-)")]),
                          _c(
                            "td",
                            {
                              staticClass: "right txt-red",
                              attrs: { colspan: "2" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      (Number(item.bfempreservetotamt) +
                                        Number(item.bfreservetotamt) +
                                        Number(item.bfepointtotamt)) *
                                        -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c("tr", [
                      _c("th", [_vm._v("실 결제금액")]),
                      _c(
                        "td",
                        { staticClass: "right", attrs: { colspan: "2" } },
                        [
                          _c("strong", { staticClass: "large-txt" }, [
                            _vm._v(
                              _vm._s(_vm.$util.maskComma(item.bfrpaytotprice))
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "middle" }, [
              _c("div", { staticClass: "bar-title small" }, [
                _vm._v("재 계산 판매금액 합계"),
              ]),
              _c(
                "table",
                {
                  staticClass: "gray-tb lower",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(3, true),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("재 계산 주문금액 합계")]),
                      _c(
                        "td",
                        { staticClass: "right", attrs: { colspan: "2" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$util.maskComma(item.afordtotprice))
                          ),
                        ]
                      ),
                    ]),
                    !_vm.isPartner
                      ? _c("tr", [
                          _c("th", [_vm._v("자사 배송비(+)")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                item.isolatetype === "N"
                                  ? "기본배송비"
                                  : "도서산간배송비"
                              )
                            ),
                          ]),
                          _c("td", { staticClass: "right" }, [
                            _vm._v(
                              _vm._s(_vm.$util.maskComma(item.afdadadelivamt))
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("tr", [
                      _c("th", [_vm._v("파트너사 배송비(+)")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            item.isolatetype === "N"
                              ? "기본배송비"
                              : "도서산간배송비"
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "right" }, [
                        _vm._v(_vm._s(_vm.$util.maskComma(item.afptndelivamt))),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("프로모션 할인")]),
                      !_vm.$util.isNull(item.aftotsalepromoamt) &&
                      Number(item.aftotsalepromoamt) > 0
                        ? _c("td", { staticClass: "txt-gray2" })
                        : _c("td", { staticClass: "txt-gray2" }, [
                            _vm._v("(적용할인없음)"),
                          ]),
                      _c("td", { staticClass: "right txt-red" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$util.maskComma(
                                Number(item.aftotsalepromoamt) * -1
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("상품 할인")]),
                      !_vm.$util.isNull(item.aftotgoodscpnamt) &&
                      Number(item.aftotgoodscpnamt) > 0
                        ? _c("td", { staticClass: "txt-gray2" })
                        : _c("td", { staticClass: "txt-gray2" }, [
                            _vm._v("(적용할인없음)"),
                          ]),
                      _c("td", { staticClass: "right txt-red" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$util.maskComma(
                                Number(item.aftotgoodscpnamt) * -1
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("장바구니 할인")]),
                      !_vm.$util.isNull(item.afbasketcpnamt) &&
                      Number(item.afbasketcpnamt) > 0
                        ? _c("td", { staticClass: "txt-gray2" })
                        : _c("td", { staticClass: "txt-gray2" }, [
                            _vm._v("(적용할인없음)"),
                          ]),
                      _c("td", { staticClass: "right txt-red" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$util.maskComma(
                                Number(item.afbasketcpnamt) * -1
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("배송비 할인")]),
                      !_vm.$util.isNull(item.aftotdelivcpnamt) &&
                      Number(item.aftotdelivcpnamt) > 0
                        ? _c("td", { staticClass: "txt-gray2" })
                        : _c("td", { staticClass: "txt-gray2" }, [
                            _vm._v("(적용할인없음)"),
                          ]),
                      _c("td", { staticClass: "right txt-red" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$util.maskComma(
                                Number(item.aftotdelivcpnamt) * -1
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    !_vm.isPartner
                      ? _c("tr", [
                          _c("th", [_vm._v("임직원적립금 사용(-)")]),
                          _c(
                            "td",
                            {
                              staticClass: "right txt-red",
                              attrs: { colspan: "2" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(item.afempreservetotamt) * -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    !_vm.isPartner
                      ? _c("tr", [
                          _c("th", [_vm._v("적립금 사용(-)")]),
                          _c(
                            "td",
                            {
                              staticClass: "right txt-red",
                              attrs: { colspan: "2" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(item.afreservetotamt) * -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    !_vm.isPartner
                      ? _c("tr", [
                          _c("th", [_vm._v("D포인트 사용(-)")]),
                          _c(
                            "td",
                            {
                              staticClass: "right txt-red",
                              attrs: { colspan: "2" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(item.afepointtotamt) * -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.isPartner
                      ? _c("tr", [
                          _c("th", [_vm._v("적립금 사용(-)")]),
                          _c(
                            "td",
                            {
                              staticClass: "right txt-red",
                              attrs: { colspan: "2" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      (Number(item.afempreservetotamt) +
                                        Number(item.afreservetotamt) +
                                        Number(item.afepointtotamt)) *
                                        -1
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c("tr", [
                      _c("th", [_vm._v("재 계산 최종금액")]),
                      _c(
                        "td",
                        { staticClass: "right", attrs: { colspan: "2" } },
                        [
                          _c("strong", { staticClass: "large-txt" }, [
                            _vm._v(
                              _vm._s(_vm.$util.maskComma(item.afrpaytotprice))
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "right" },
              [
                Number(item.rtndadadelivamt) + Number(item.rtnptndelivamt) > 0
                  ? [
                      _c("div", { staticClass: "bar-title small" }, [
                        _vm._v("차감배송비"),
                      ]),
                      _c(
                        "table",
                        {
                          staticClass: "gray-tb lower",
                          attrs: { cellpadding: "0", cellspacing: "0" },
                        },
                        [
                          _vm._m(4, true),
                          _c("tbody", [
                            !_vm.isPartner
                              ? _c("tr", [
                                  _c("th", [_vm._v("자사배송비")]),
                                  _c("td", { staticClass: "right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$util.maskComma(
                                          item.rtndadadelivamt
                                        )
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _c("tr", [
                              _c("th", [_vm._v("파트너사 배송비")]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$util.maskComma(item.rtnptndelivamt)
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  : _vm._e(),
                _c("div", { staticClass: "bar-title small" }, [
                  _vm._v("추가배송비"),
                ]),
                _c(
                  "table",
                  {
                    staticClass: "gray-tb lower",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _vm._m(5, true),
                    _c("tbody", [
                      !_vm.isPartner
                        ? _c("tr", [
                            _c("th", [_vm._v("자사배송비")]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.maskComma(item.adddadadelivamt)
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c("tr", [
                        _c("th", [_vm._v("파트너사 배송비")]),
                        _c("td", { staticClass: "right" }, [
                          _vm._v(
                            _vm._s(_vm.$util.maskComma(item.addptndelivamt))
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                Number(item.addrpaytotprice) > 0
                  ? [
                      _c("div", { staticClass: "bar-title small" }, [
                        _vm._v(" 추가결제내역"),
                        item.rtnstatus ===
                        _vm.$store.getters["ADMIN"].RETURN_WAITING_DEPOSIT
                          ? _c(
                              "span",
                              { staticClass: "normal txt-orange ml10" },
                              [
                                _vm._v(
                                  "추가금 발생일로부터 " +
                                    _vm._s(
                                      _vm.$util.maskComma(item.elapsedaycnt)
                                    ) +
                                    "일 경과"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "table",
                        {
                          staticClass: "gray-tb lower",
                          attrs: { cellpadding: "0", cellspacing: "0" },
                        },
                        [
                          _vm._m(6, true),
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("결제일시")]),
                              _c("td", [_vm._v(_vm._s(item.addpaymentdate))]),
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("결제자명")]),
                              _c("td", [_vm._v(_vm._s(item.ordname))]),
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("결제수단")]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(item.addpaywaytypenm) +
                                    " " +
                                    _vm._s(
                                      item.addpaywaytype ===
                                        _vm.$store.getters["ADMIN"]
                                          .PAY_CREDIT_CARD
                                        ? "(" +
                                            item.cardcompany +
                                            "/" +
                                            (item.planmonth == 0 ||
                                            _vm.$util.isNull(item.planmonth)
                                              ? "일시불"
                                              : item.planmonth) +
                                            ")"
                                        : ""
                                    ) +
                                    " " +
                                    _vm._s(
                                      item.addpaywaytype ===
                                        _vm.$store.getters["ADMIN"]
                                          .PAY_VIRTURE_ACCOUNT
                                        ? "(" +
                                            item.virbank +
                                            "/" +
                                            item.accntnumber +
                                            ")"
                                        : ""
                                    ) +
                                    " " +
                                    _vm._s(
                                      item.addpaywaytype ===
                                        _vm.$store.getters["ADMIN"]
                                          .PAY_ACCOUNT_TRANSFER
                                        ? "(" + item.trsbank + ")"
                                        : ""
                                    ) +
                                    " " +
                                    _vm._s(
                                      item.addpaywaytype ===
                                        _vm.$store.getters["ADMIN"].PAY_MOBILE
                                        ? "(" + item.mopcarrier + ")"
                                        : ""
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("금액")]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.addrpaytotprice === 0
                                        ? ""
                                        : _vm.$util.maskComma(
                                            item.addrpaytotprice
                                          )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  : _vm._e(),
                _c("div", { staticClass: "bar-title small" }, [
                  _vm._v("환불 금액"),
                ]),
                _c(
                  "table",
                  {
                    staticClass: "gray-tb lower",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _vm._m(7, true),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v("환불예정금액")]),
                        _c("td", { staticClass: "right" }, [
                          _vm._v(_vm._s(_vm.$util.maskComma(item.rtnamt))),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("최종환불금액")]),
                        _c("td", { staticClass: "right" }, [
                          _vm._v(
                            _vm._s(_vm.$util.maskComma(item.finadjustamt))
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("조정금액(-)")]),
                        _c("td", { staticClass: "right" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$util.maskComma(
                                  (Number(item.rtnamt) -
                                    Number(item.finadjustamt)) *
                                    -1
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("조정사유")]),
                        _c("td", [_vm._v(_vm._s(item.adjustreason))]),
                      ]),
                    ]),
                  ]
                ),
                item.paywaytype ===
                  _vm.$store.getters["ADMIN"].PAY_VIRTURE_ACCOUNT &&
                Number(item.rtnpayamt) > 0
                  ? [
                      _c("div", { staticClass: "bar-title small" }, [
                        _vm._v("환불계좌 정보"),
                      ]),
                      _c(
                        "table",
                        {
                          staticClass: "gray-tb lower",
                          attrs: { cellpadding: "0", cellspacing: "0" },
                        },
                        [
                          _vm._m(8, true),
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("예금주명")]),
                              _c("td", [_vm._v(_vm._s(item.refcusname))]),
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("환불계좌")]),
                              _c("td", [
                                _vm._v(
                                  "[" +
                                    _vm._s(item.refbanknm) +
                                    "] " +
                                    _vm._s(item.refaccnumber)
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  : _vm._e(),
                _c("div", { staticClass: "bar-title small" }, [
                  _vm._v("최종환불"),
                ]),
                _c(
                  "table",
                  {
                    staticClass: "gray-tb lower",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _vm._m(9, true),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v("금액(PG)")]),
                        _c("td", [_vm._v(_vm._s(item.paywaytypenm))]),
                        _c("td", { staticClass: "right" }, [
                          _c("strong", { staticClass: "large-txt" }, [
                            _vm._v(_vm._s(_vm.$util.maskComma(item.rtnpayamt))),
                          ]),
                        ]),
                      ]),
                      !_vm.isPartner
                        ? _c("tr", [
                            _c("th", [_vm._v("임직원적립금 반환")]),
                            _c("td"),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(_vm.$util.maskComma(item.rtnempresamt))
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      !_vm.isPartner
                        ? _c("tr", [
                            _c("th", [_vm._v("적립금 반환")]),
                            _c("td"),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(_vm.$util.maskComma(item.rtnresamt))
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      !_vm.isPartner
                        ? _c("tr", [
                            _c("th", [_vm._v("D포인트 반환")]),
                            _c("td"),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                _vm._s(_vm.$util.maskComma(item.rtnepoamt))
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.isPartner
                        ? _c("tr", [
                            _c("th", [_vm._v("적립금 반환")]),
                            _c("td"),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$util.maskComma(
                                      Number(item.rtnempresamt) +
                                        Number(item.rtnresamt) +
                                        Number(item.rtnepoamt)
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ],
              2
            ),
          ]),
        ]
      }),
      _vm.$util.isNull(_vm.claimList) || _vm.claimList.length === 0
        ? [_vm._m(10), _vm._m(11)]
        : _vm._e(),
      !_vm.isPartner
        ? _c("fragment", [
            _c("div", { staticClass: "bar-title small" }, [
              _vm._v("관리자 메모"),
            ]),
            _vm.isWrite
              ? _c("div", { staticClass: "memo-write" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.memo.inputMemo,
                        expression: "memo.inputMemo",
                      },
                    ],
                    attrs: { placeholder: "메모를 작성해 주세요!" },
                    domProps: { value: _vm.memo.inputMemo },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.memo, "inputMemo", $event.target.value)
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn big blue",
                      attrs: { type: "button" },
                      on: { click: _vm.addMemo },
                    },
                    [_vm._v("메모"), _c("br"), _vm._v("저장")]
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "scroll-y",
                staticStyle: {
                  width: "100%",
                  "max-height": "550px",
                  "margin-bottom": "0",
                },
              },
              [
                _c(
                  "table",
                  {
                    staticClass: "data-tb align-c",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _c("colgroup", [
                      _c("col", { attrs: { width: "5%" } }),
                      _c("col", { attrs: { width: "20%" } }),
                      _c("col", { attrs: { width: "8%" } }),
                      _c("col", { attrs: { width: "" } }),
                      _c("col", { attrs: { width: "40px" } }),
                    ]),
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("No")]),
                        _c("th", [
                          _vm._v(" 작성일시 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.memo.psort === "regdate_asc" },
                              { down: _vm.memo.psort === "regdate_desc" },
                            ],
                            attrs: { type: "button", value: _vm.memo.psort },
                            on: {
                              click: function ($event) {
                                return _vm.memoSortToggle(_vm.memo.psort)
                              },
                            },
                          }),
                        ]),
                        _c("th", [_vm._v("작성자")]),
                        _c("th", { attrs: { colspan: "2" } }, [_vm._v("내용")]),
                      ]),
                    ]),
                    !_vm.$util.isNull(_vm.orderMemoList) &&
                    _vm.orderMemoList.length > 0
                      ? _c(
                          "tbody",
                          _vm._l(_vm.orderMemoList, function (item, index) {
                            return _c("tr", { key: item.ordmemoidx }, [
                              _c("td", [_vm._v(_vm._s(index + 1))]),
                              _c("td", [_vm._v(_vm._s(item.regdate))]),
                              _c("td", [_vm._v(_vm._s(item.regusername))]),
                              _c("td", { staticClass: "left" }, [
                                _vm._v(_vm._s(item.memo)),
                              ]),
                              _c("td", { staticClass: "no-left" }, [
                                _vm.isWrite
                                  ? _c("button", {
                                      staticClass: "del mg0",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeMeno(item.ordmemoidx)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                            ])
                          }),
                          0
                        )
                      : _c("tbody", [
                          _c("tr", [
                            _c("td", { attrs: { colspan: "5" } }, [
                              _vm._v("조회 결과가 존재하지 않습니다."),
                            ]),
                          ]),
                        ]),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm.isGoodsDetailShow
        ? _c("GoodsDetail", {
            attrs: { activeGoodsNo: _vm.activeGoodsNo },
            on: { closePopup: _vm.closeGoodsDetail },
          })
        : _vm._e(),
      _vm.isShowReturnDetail
        ? _c("ReturnDetail", {
            attrs: {
              activeClmParam: _vm.activeClmParam,
              activeOrderInfo: _vm.activeOrderInfo,
            },
            on: { closeDetail: _vm.closeReturnDetail },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "5.8%" } }),
      _c("col", { attrs: { width: "5.8%" } }),
      _c("col", { attrs: { width: "4.5%" } }),
      _c("col", { attrs: { width: "5.5%" } }),
      _c("col", { attrs: { width: "6.5%" } }),
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "3.8%" } }),
      _c("col", { attrs: { width: "3.8%" } }),
      _c("col", { attrs: { width: "5.8%" } }),
      _c("col", { attrs: { width: "5.8%" } }),
      _c("col", { attrs: { width: "5.8%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "4.5%" } }),
      _c("col", { attrs: { width: "8.3%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("반품 신청일")]),
        _c("th", [_vm._v("반품 완료일")]),
        _c("th", [_vm._v("판매구분")]),
        _c("th", [_vm._v("파트너사")]),
        _c("th", [_vm._v("상품코드")]),
        _c("th", [_vm._v("단품코드")]),
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("상품명")]),
        _c("th", [_vm._v("옵션")]),
        _c("th", [_vm._v("주문수량")]),
        _c("th", [_vm._v("반품수량")]),
        _c("th", [_vm._v("판매단가")]),
        _c("th", [_vm._v("판매금액")]),
        _c("th", [_vm._v("반품금액")]),
        _c("th", [_vm._v("귀책대상")]),
        _c("th", [_vm._v("반품상태")]),
        _c("th", [_vm._v("반품번호")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "230px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "230px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "bar-title small fl" }, [_vm._v("반품상품")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "table",
      {
        staticClass: "data-tb align-c mt0",
        attrs: { cellpadding: "0", cellspacing: "0" },
      },
      [
        _c("caption", [_vm._v(" 반품상품 목록 ")]),
        _c("colgroup", [
          _c("col", { attrs: { width: "5.8%" } }),
          _c("col", { attrs: { width: "5.8%" } }),
          _c("col", { attrs: { width: "4.5%" } }),
          _c("col", { attrs: { width: "5.5%" } }),
          _c("col", { attrs: { width: "6.5%" } }),
          _c("col", { attrs: { width: "3.5%" } }),
          _c("col", { attrs: { width: "62px" } }),
          _c("col", { attrs: { width: "" } }),
          _c("col", { attrs: { width: "7%" } }),
          _c("col", { attrs: { width: "3.8%" } }),
          _c("col", { attrs: { width: "3.8%" } }),
          _c("col", { attrs: { width: "5.8%" } }),
          _c("col", { attrs: { width: "5.8%" } }),
          _c("col", { attrs: { width: "5.8%" } }),
          _c("col", { attrs: { width: "5%" } }),
          _c("col", { attrs: { width: "4.5%" } }),
          _c("col", { attrs: { width: "8.3%" } }),
        ]),
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("반품 신청일")]),
            _c("th", [_vm._v("반품 완료일")]),
            _c("th", [_vm._v("판매구분")]),
            _c("th", [_vm._v("파트너사")]),
            _c("th", [_vm._v("상품코드")]),
            _c("th", [_vm._v("단품코드")]),
            _c("th", { attrs: { colspan: "2" } }, [_vm._v("상품명")]),
            _c("th", [_vm._v("옵션")]),
            _c("th", [_vm._v("주문수량")]),
            _c("th", [_vm._v("반품수량")]),
            _c("th", [_vm._v("판매단가")]),
            _c("th", [_vm._v("판매금액")]),
            _c("th", [_vm._v("반품금액")]),
            _c("th", [_vm._v("귀책대상")]),
            _c("th", [_vm._v("반품상태")]),
            _c("th", [_vm._v("반품번호")]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", { attrs: { colspan: "17" } }, [
              _vm._v("반품내역이 존재하지 않습니다."),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }