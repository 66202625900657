<template>
  <div class="top-menu-p">
    <!-- 파트너사 관리자는 top-menu-p 클래스 사용 -->
    <div class="top clearfix">
      <div class="logo">
        <img src="../../assets/img/logo.png" alt="D.PLOT" class="link" @click="goMain" />
        <span class="left-name">{{ companyName }}</span>
      </div>
      <ul class="user">
        <li class="name-info">
          <span class="name">{{ managerName }}</span>
          님, 안녕하세요.
        </li>
        <li class="btns">
          <a href="javascript:void(0);" class="btn btn-outline-light" @click="goAccessHistoryPopup">
            접속기록보기
          </a>
          <a
            v-if="isMainManagerType"
            href="javascript:void(0);"
            class="btn btn-outline-light"
            @click="goInfo"
          >
            정보수정
          </a>
          <a href="javascript:void(0);" class="btn btn-outline-light" @click="goFrontMain">
            쇼핑몰메인
          </a>
          <a href="javascript:void(0);" class="btn btn-dark" @click="logOut">로그아웃</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from '@/js/store';
import storage from '@/js/storage';
import constants from '@/js/constants';
import { MANAGER_TYPE } from '@constants/manager';
import CommonAccessHistoryPopup from '@/views/admin/common/popup/CommonAccessHistoryPopup.vue';

export default {
  name: 'PartnersHeader',
  data() {
    return {
      userInfo: '',
    };
  },
  computed: {
    isMainManagerType() {
      const user = this.$util.getUser(this.$store.getters['CONSTANTS'].MANAGER_SESSION);
      return user.managerType === MANAGER_TYPE.대표운영자;
    },
    managerName() {
      const user = this.$util.getUser(this.$store.getters['CONSTANTS'].MANAGER_SESSION);
      return user.managerName;
    },
    companyName() {
      const user = this.$util.getUser(this.$store.getters['CONSTANTS'].MANAGER_SESSION);
      return user.name;
    },
  },
  watch: {
    '$store.state.partnerCompanyName'() {
      // 스토어가 업데이트 되면 로컬스토리지 데이터도 최신화 한다.
      storage.setLocalStorage(constants.PARTNER_USER, this.userInfo);
    },
    '$store.state.managerName'() {
      // 스토어가 업데이트 되면 로컬스토리지 데이터도 최신화 한다.
      storage.setLocalStorage(constants.PARTNER_USER, this.userInfo);
    },
  },
  beforeMount() {
    const managerSession = this.$util.getUser(this.$store.getters.CONSTANTS.MANAGER_SESSION);
    this.userInfo = managerSession;

    store.commit('partnerCompanyName', managerSession ? managerSession.name : ''); // 업체명을 스토어에 업데이트한다.
    store.commit('managerName', managerSession ? managerSession.managerName : ''); // 매니저명을 스토어에 업데이트한다.
  },
  methods: {
    goInfo() {
      this.$router.push({ name: 'partners.info.auth' });
    },
    goMain() {
      this.$router.push({ name: 'partners.main.dashboard' });
    },
    goFrontMain: function () {
      window.open(process.env.VUE_APP_PC_DOMAIN, '_blank');
    },
    goAccessHistoryPopup() {
      if (this.$util.isAuthorized('PARTNER_USER')) {
        let param = {
          no: this.userInfo.no,
          managerNo: this.userInfo.managerId,
          managerType: this.userInfo.managerType,
          isPartner: true,
        };
        this.$eventBus.$emit('modalShow', CommonAccessHistoryPopup, param);
      }
    },
    logOut: function () {
      let param = {};
      this.$http
        .post('/partners/logout', param)
        .then((data) => {
          this.$util.debug(data);

          this.$storage.removeLocalStorage(this.$store.getters.CONSTANTS.PARTNER_USER);
          this.$storage.removeLocalStorage(this.$store.getters.CONSTANTS.MANAGER_SESSION);
          this.$router.push({ name: 'partners.login' });
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
  },
};
</script>

<style scoped></style>
