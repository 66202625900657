var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1600px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("주문내역조회")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide("commonModal")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "boxing search-area" }, [
              _c("dl", [
                _c("dt", [_vm._v("직접검색")]),
                _c("dd", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.skey,
                          expression: "searchData.skey",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "skey",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("전체")]),
                      _c("option", { attrs: { value: "ordno" } }, [
                        _vm._v("주문번호"),
                      ]),
                      _c("option", { attrs: { value: "ordname" } }, [
                        _vm._v("주문자명"),
                      ]),
                      _c("option", { attrs: { value: "ordid" } }, [
                        _vm._v("주문자ID"),
                      ]),
                    ]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.sword,
                        expression: "searchData.sword",
                      },
                    ],
                    attrs: { type: "text", maxlength: "200" },
                    domProps: { value: _vm.searchData.sword },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.searchList.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.searchData, "sword", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("dl", [
                _c("dt", [_vm._v("상품검색")]),
                _c("dd", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.gdkey,
                          expression: "searchData.gdkey",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "gdkey",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "goodsname" } }, [
                        _vm._v("상품명"),
                      ]),
                      _c("option", { attrs: { value: "goodscode" } }, [
                        _vm._v("상품코드"),
                      ]),
                      _c("option", { attrs: { value: "optioncode" } }, [
                        _vm._v("단품코드"),
                      ]),
                      _c("option", { attrs: { value: "optionname" } }, [
                        _vm._v("옵션명"),
                      ]),
                    ]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.gdword,
                        expression: "searchData.gdword",
                      },
                    ],
                    attrs: { type: "text", maxlength: "500" },
                    domProps: { value: _vm.searchData.gdword },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.searchList.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.searchData, "gdword", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("dl", [
                _c("dt", [_vm._v("조회일자")]),
                _c(
                  "dd",
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.dtkey,
                            expression: "searchData.dtkey",
                          },
                        ],
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "dtkey",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "order" } }, [
                          _vm._v("주문일"),
                        ]),
                        _c("option", { attrs: { value: "payment" } }, [
                          _vm._v("결제일"),
                        ]),
                      ]
                    ),
                    _c("CommonDatePicker", {
                      attrs: { value: _vm.searchData.startdate },
                      on: { change: _vm.onChangeStartDate },
                    }),
                    _c("span", [_vm._v("-")]),
                    _c("CommonDatePicker", {
                      attrs: { value: _vm.searchData.enddate },
                      on: { change: _vm.onChangeEndDate },
                    }),
                    _c("div", { staticClass: "radio_wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.period,
                            expression: "searchData.period",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "period_aday_1",
                          value: "aday_1",
                        },
                        domProps: {
                          checked: _vm._q(_vm.searchData.period, "aday_1"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.searchData, "period", "aday_1")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "period_aday_1" } }, [
                        _vm._v("어제"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.period,
                            expression: "searchData.period",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "period_aday_0",
                          value: "aday_0",
                        },
                        domProps: {
                          checked: _vm._q(_vm.searchData.period, "aday_0"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.searchData, "period", "aday_0")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "period_aday_0" } }, [
                        _vm._v("오늘"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.period,
                            expression: "searchData.period",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "period_days_7",
                          value: "days_7",
                        },
                        domProps: {
                          checked: _vm._q(_vm.searchData.period, "days_7"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.searchData, "period", "days_7")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "period_days_7" } }, [
                        _vm._v("일주일"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.period,
                            expression: "searchData.period",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "period_months_1",
                          value: "months_1",
                        },
                        domProps: {
                          checked: _vm._q(_vm.searchData.period, "months_1"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(
                              _vm.searchData,
                              "period",
                              "months_1"
                            )
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "period_months_1" } }, [
                        _vm._v("1개월"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.period,
                            expression: "searchData.period",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "period_months_3",
                          value: "months_3",
                        },
                        domProps: {
                          checked: _vm._q(_vm.searchData.period, "months_3"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(
                              _vm.searchData,
                              "period",
                              "months_3"
                            )
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "period_months_3" } }, [
                        _vm._v("3개월"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.period,
                            expression: "searchData.period",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "period_months_6",
                          value: "months_6",
                        },
                        domProps: {
                          checked: _vm._q(_vm.searchData.period, "months_6"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(
                              _vm.searchData,
                              "period",
                              "months_6"
                            )
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "period_months_6" } }, [
                        _vm._v("6개월"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm.searchData.isdealer === "T"
                ? _c("dl", [
                    _c("dt", [_vm._v("파트너사")]),
                    _c("dd", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.dealerno,
                              expression: "searchData.dealerno",
                            },
                          ],
                          attrs: {
                            disabled: !_vm.$util.isNull(_vm.params.dealerno),
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchData,
                                "dealerno",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("파트너사 선택"),
                          ]),
                          _vm._l(_vm.searchData.partnerList, function (item) {
                            return _c(
                              "option",
                              { key: item.no, domProps: { value: item.no } },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ])
                : _c("dl", [
                    _c("dt", [_vm._v("판매구분")]),
                    _c("dd", [
                      _c("div", { staticClass: "radio_wrap wide3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.ispbgoods,
                              expression: "searchData.ispbgoods",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "ispbgoods",
                            id: "ispbgoodsAll",
                            value: "",
                          },
                          domProps: {
                            checked: _vm._q(_vm.searchData.ispbgoods, ""),
                          },
                          on: {
                            click: function ($event) {
                              _vm.searchData.dealerno = ""
                            },
                            change: function ($event) {
                              return _vm.$set(_vm.searchData, "ispbgoods", "")
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "ispbgoodsAll" } }, [
                          _vm._v("전체"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.ispbgoods,
                              expression: "searchData.ispbgoods",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "ispbgoods",
                            id: "ispbgoodsT",
                            value: "T",
                          },
                          domProps: {
                            checked: _vm._q(_vm.searchData.ispbgoods, "T"),
                          },
                          on: {
                            click: function ($event) {
                              _vm.searchData.dealerno = ""
                            },
                            change: function ($event) {
                              return _vm.$set(_vm.searchData, "ispbgoods", "T")
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "ispbgoodsT" } }, [
                          _vm._v("직매입"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.ispbgoods,
                              expression: "searchData.ispbgoods",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "ispbgoods",
                            id: "ispbgoodsF",
                            value: "F",
                          },
                          domProps: {
                            checked: _vm._q(_vm.searchData.ispbgoods, "F"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.searchData, "ispbgoods", "F")
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "ispbgoodsF" } }, [
                          _vm._v("위탁"),
                        ]),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.dealerno,
                              expression: "searchData.dealerno",
                            },
                          ],
                          attrs: { disabled: _vm.searchData.ispbgoods != "F" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchData,
                                "dealerno",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("파트너사 선택"),
                          ]),
                          _vm._l(_vm.searchData.partnerList, function (item) {
                            return _c(
                              "option",
                              { key: item.no, domProps: { value: item.no } },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
            ]),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.searchList },
                },
                [_vm._v("검색")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big gray",
                  attrs: { type: "button" },
                  on: { click: _vm.initSearchData },
                },
                [_vm._v("초기화")]
              ),
            ]),
            _c("div", { staticClass: "caption-group mt10 clearfix" }, [
              _c("div", { staticClass: "total-group fl" }, [
                _c("span", { staticClass: "total" }, [
                  _vm._v("전체 "),
                  _c("strong", [_vm._v(_vm._s(_vm.list.length))]),
                  _vm._v("건"),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "scroll-y",
                staticStyle: { "max-height": "500px" },
              },
              [
                _c(
                  "table",
                  {
                    staticClass: "data-tb align-c",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _vm._m(0),
                    _c("thead", [
                      _c("tr", [
                        _c("th"),
                        _c("th", [
                          _vm._v(" 주문일 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up: _vm.sortData.orderdate === "orderdate_asc",
                              },
                              {
                                down:
                                  _vm.sortData.orderdate === "orderdate_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.orderdate,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.orderdate)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 결제일 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up:
                                  _vm.sortData.paymentdate ===
                                  "paymentdate_asc",
                              },
                              {
                                down:
                                  _vm.sortData.paymentdate ===
                                  "paymentdate_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.paymentdate,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.paymentdate)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 주문번호 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.ordno === "ordno_asc" },
                              { down: _vm.sortData.ordno === "ordno_desc" },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.ordno,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.ordno)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 주문자 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.ordname === "ordname_asc" },
                              { down: _vm.sortData.ordname === "ordname_desc" },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.ordname,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.ordname)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 아이디 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.ordid === "ordid_asc" },
                              { down: _vm.sortData.ordid === "ordid_desc" },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.ordid,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.ordid)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 판매구분 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up: _vm.sortData.ispbgoods === "ispbgoods_asc",
                              },
                              {
                                down:
                                  _vm.sortData.ispbgoods === "ispbgoods_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.ispbgoods,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.ispbgoods)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 파트너사 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up:
                                  _vm.sortData.dealername === "dealername_asc",
                              },
                              {
                                down:
                                  _vm.sortData.dealername === "dealername_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.dealername,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.dealername)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 상품코드 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up: _vm.sortData.goodscode === "goodscode_asc",
                              },
                              {
                                down:
                                  _vm.sortData.goodscode === "goodscode_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.goodscode,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.goodscode)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 단품코드 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up:
                                  _vm.sortData.optioncode === "optioncode_asc",
                              },
                              {
                                down:
                                  _vm.sortData.optioncode === "optioncode_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.optioncode,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.optioncode)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 상품순번 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up: _vm.sortData.goodsturn === "goodsturn_asc",
                              },
                              {
                                down:
                                  _vm.sortData.goodsturn === "goodsturn_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.goodsturn,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.goodsturn)
                              },
                            },
                          }),
                        ]),
                        _c("th", { attrs: { colspan: "2" } }, [
                          _vm._v(" 상품명 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up: _vm.sortData.goodsname === "goodsname_asc",
                              },
                              {
                                down:
                                  _vm.sortData.goodsname === "goodsname_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.goodsname,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.goodsname)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 옵션 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up:
                                  _vm.sortData.optionconts ===
                                  "optionconts_asc",
                              },
                              {
                                down:
                                  _vm.sortData.optionconts ===
                                  "optionconts_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.optionconts,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.optionconts)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 주문수량 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.ordcnt === "ordcnt_asc" },
                              { down: _vm.sortData.ordcnt === "ordcnt_desc" },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.ordcnt,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.ordcnt)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 판매단가 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.price === "price_asc" },
                              { down: _vm.sortData.price === "price_desc" },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.price,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.price)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 판매금액 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.totprice === "totprice_asc" },
                              { down: _vm.sortData.totprice === "totprice" },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.totprice,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.totprice)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 송장번호 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up: _vm.sortData.invoiceno === "invoiceno_asc",
                              },
                              {
                                down:
                                  _vm.sortData.invoiceno === "invoiceno_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.invoiceno,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.invoiceno)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 구매확정 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.sortData.cfmconts === "cfmconts_asc" },
                              {
                                down: _vm.sortData.cfmconts === "cfmconts_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.cfmconts,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.cfmconts)
                              },
                            },
                          }),
                        ]),
                        _c("th", [
                          _vm._v(" 주문상태 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              {
                                up: _vm.sortData.ordstatus === "ordstatus_asc",
                              },
                              {
                                down:
                                  _vm.sortData.ordstatus === "ordstatus_desc",
                              },
                            ],
                            attrs: {
                              type: "button",
                              value: _vm.sortData.ordstatus,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.sortData.ordstatus)
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _vm.list.length > 0
                      ? _c(
                          "tbody",
                          _vm._l(_vm.list, function (item) {
                            return _c("tr", { key: item.orgdelividx }, [
                              _c("td", [
                                _c("input", {
                                  staticClass: "circle",
                                  attrs: { type: "radio", name: "checked" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.setCheckedList(item)
                                    },
                                  },
                                }),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.orderdate))]),
                              _c("td", [_vm._v(_vm._s(item.paymentdate))]),
                              _c("td", [_vm._v(_vm._s(item.ordno))]),
                              _c("td", [_vm._v(_vm._s(item.ordname))]),
                              _c("td", [_vm._v(_vm._s(item.ordid))]),
                              _c("td", [_vm._v(_vm._s(item.ispbgoodsname))]),
                              _c("td", [_vm._v(_vm._s(item.dealername))]),
                              _c("td", [_vm._v(_vm._s(item.goodscode))]),
                              _c("td", [_vm._v(_vm._s(item.optioncode))]),
                              _c("td", [_vm._v(_vm._s(item.goodsturn))]),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "img-thumb size60",
                                    class: {
                                      "no-image": _vm.$util.isNull(
                                        item.fullpath
                                      ),
                                    },
                                  },
                                  [
                                    !_vm.$util.isNull(item.fullpath)
                                      ? _c("img", {
                                          attrs: { src: item.fullpath },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _c("td", { staticClass: "left no-left" }, [
                                _vm._v(_vm._s(item.goodsname)),
                              ]),
                              _c(
                                "td",
                                { staticStyle: { "white-space": "pre-wrap" } },
                                [_vm._v(_vm._s(item.optionconts))]
                              ),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.ordcnt))
                                ),
                              ]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(_vm._s(_vm.$util.maskComma(item.price))),
                              ]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.totprice))
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.invoiceno))]),
                              _c(
                                "td",
                                { staticStyle: { "white-space": "pre-wrap" } },
                                [_vm._v(_vm._s(item.cfmconts))]
                              ),
                              _c("td", [_vm._v(_vm._s(item.statusname))]),
                            ])
                          }),
                          0
                        )
                      : _c("tbody", [_vm._m(1)]),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.sendData },
                },
                [_vm._v("선택 대상 적용")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("commonModal")
                    },
                  },
                },
                [_vm._v(" 닫기 ")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "2%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "4.3%" } }),
      _c("col", { attrs: { width: "4.3%" } }),
      _c("col", { attrs: { width: "3.8%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "3.8%" } }),
      _c("col", { attrs: { width: "4.8%" } }),
      _c("col", { attrs: { width: "4.8%" } }),
      _c("col", { attrs: { width: "4.8%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "5%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "20" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }