var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1600px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("주문상세정보")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c(
            "div",
            { staticClass: "pop-body" },
            [
              _c("div", { staticClass: "gray-box mg0" }, [
                _c("div", { staticClass: "clearfix" }, [
                  _c("div", { staticClass: "fl" }, [
                    _vm._v(
                      " [" + _vm._s(_vm.info.ordpathtypename) + "] 주문번호 : "
                    ),
                    _c("strong", [_vm._v(_vm._s(_vm.info.ordno))]),
                  ]),
                  _c("div", { staticClass: "fr txt-gray" }, [
                    _c("span", [
                      _vm._v("주문일 : " + _vm._s(_vm.info.orderdate)),
                    ]),
                    _vm.isPartner
                      ? _c("span", { staticClass: "left-bar" }, [
                          _vm._v("결제일 : " + _vm._s(_vm.info.paymentdate)),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                !_vm.isPartner
                  ? _c("hr", {
                      staticClass: "solid",
                      staticStyle: { margin: "10px 0" },
                    })
                  : _vm._e(),
                !_vm.isPartner
                  ? _c("div", { staticClass: "clearfix" }, [
                      _c("div", { staticClass: "fl" }, [
                        _vm.info.isnonmember == "F"
                          ? _c("span", [
                              _vm._v(
                                " [" +
                                  _vm._s(_vm.info.membertypename) +
                                  "] [" +
                                  _vm._s(_vm.info.memlvtypename) +
                                  "] "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goMemDetail(_vm.info.orduserno)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.info.ordname) + " ")]
                              ),
                              _vm._v(" (" + _vm._s(_vm.info.ordid) + ") "),
                            ])
                          : _c("span", [_vm._v(_vm._s(_vm.info.ordname))]),
                        !_vm.$util.isNull(_vm.info.ordtel)
                          ? _c("span", { staticClass: "left-bar" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$util.maskTel(_vm.info.ordtel)) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        !_vm.$util.isNull(_vm.info.ordemail)
                          ? _c("span", { staticClass: "left-bar" }, [
                              _vm._v(_vm._s(_vm.info.ordemail)),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "fr txt-gray" }, [
                        _c("span", [
                          _vm._v("결제일 : " + _vm._s(_vm.info.paymentdate)),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", {
                staticClass: "clearfix mt10",
                staticStyle: { height: "30px" },
              }),
              _c("div", { staticClass: "tab-group clearfix" }, [
                _c(
                  "ul",
                  _vm._l(_vm.tabs, function (item) {
                    return _c(
                      "li",
                      {
                        key: item.tab,
                        class: { active: item.isActive },
                        on: {
                          click: function ($event) {
                            return _vm.toggleTab(item)
                          },
                        },
                      },
                      [
                        _c("a", { attrs: { href: "javascript:void(0);" } }, [
                          _vm._v(
                            " " +
                              _vm._s(item.name) +
                              " " +
                              _vm._s(
                                _vm.$util.isNull(item.cntName)
                                  ? ""
                                  : "(" + _vm.count[item.cntName] + ")"
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm.tabArea === "INFO"
                ? _c("AdminOrderInfo", {
                    attrs: {
                      activeOrderCode: _vm.activeOrderCode,
                      isRead: _vm.isRead,
                      isWrite: _vm.isWrite,
                      orderInfo: _vm.info,
                    },
                    on: {
                      setIsreload: _vm.setIsreload,
                      setOrderInfo: _vm.setOrderInfo,
                    },
                  })
                : _vm._e(),
              _vm.tabArea === "CANCEL"
                ? _c("AdminOrderCancel", {
                    attrs: {
                      activeOrderIdx: _vm.activeOrderIdx,
                      isRead: _vm.isRead,
                      isWrite: _vm.isWrite,
                      orderInfo: _vm.info,
                    },
                  })
                : _vm._e(),
              _vm.tabArea === "RETURN"
                ? _c("AdminOrderReturn", {
                    attrs: {
                      activeOrderIdx: _vm.activeOrderIdx,
                      isRead: _vm.isRead,
                      isWrite: _vm.isWrite,
                      orderInfo: _vm.info,
                    },
                  })
                : _vm._e(),
              _vm.tabArea === "EXCHANGE"
                ? _c("AdminOrderExchange", {
                    attrs: {
                      activeOrderIdx: _vm.activeOrderIdx,
                      isRead: _vm.isRead,
                      isWrite: _vm.isWrite,
                      orderInfo: _vm.info,
                    },
                  })
                : _vm._e(),
              _vm.tabArea === "CS"
                ? _c("AdminOrderCs", {
                    attrs: {
                      activeOrderIdx: _vm.activeOrderIdx,
                      isRead: _vm.isRead,
                      isWrite: _vm.isWrite,
                    },
                  })
                : _vm._e(),
              _vm.isShowMemDetail
                ? _c("AdminMemberInfo", {
                    attrs: {
                      activeUserNo: _vm.activeUserNo,
                      isRead: _vm.isRead,
                      isWrite: _vm.isWrite,
                    },
                    on: { closeDetail: _vm.closeMemDetail },
                  })
                : _vm._e(),
              _c("div", { staticClass: "btn-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn big darkgray",
                    attrs: { type: "button" },
                    on: { click: _vm.onClose },
                  },
                  [_vm._v("닫기")]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }