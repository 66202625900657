<template>
  <!-- 현금영수증 수기 발급 팝업(2023-10-19, James) -->
  <div id="modal-wrap" class="modal" style="display: block">
    <div class="modal-content" style="width: 700px">
      <div class="pop-header">
        <h2>현금영수증 수기 발급</h2>
        <button type="button" class="pop-close" @click="closePopup"></button>
      </div>
      <div class="pop-body">
        <table cellpadding="0" cellspacing="0" class="gray-tb">
          <colgroup>
            <col width="120px" />
            <col width="420px" />
          </colgroup>
          <tbody>
            <tr>
              <th>현금영수증 타입</th>
              <td>
                <div class="radio_wrap">
                  <input
                    type="radio"
                    v-model="cashReceiptType"
                    id="cashReceiptType1"
                    value="소득공제"
                  /><label for="cashReceiptType1">소득공제</label>
                  <input
                    type="radio"
                    v-model="cashReceiptType"
                    id="cashReceiptType2"
                    value="지출증빙"
                  /><label for="cashReceiptType2">지출증빙</label>
                </div>
              </td>
            </tr>
            <tr>
              <th>현금영수증 기준 정보</th>
              <td>
                <div class="radio_wrap" v-if="isCashReceiptTypeEarn" style="display: inline-block">
                  <input
                    type="radio"
                    v-model="cashReceiptValueType"
                    id="cashReceiptValueType1"
                    value="cashReceiptValueType1"
                  /><label for="cashReceiptValueType1">전화번호</label>
                  <input
                    type="radio"
                    v-model="cashReceiptValueType"
                    id="cashReceiptValueType2"
                    value="cashReceiptValueType2"
                  /><label for="cashReceiptValueType2">현금영수증카드번호</label>
                </div>
                &nbsp;<input
                  type="text"
                  style="width: 137px; display: inline-block"
                  v-model="info.ordtel"
                  maxlength="20"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="btn-group">
          <button type="button" class="btn big blue" @click="save">저장</button>
          <button type="button" class="btn big darkgray" @click="closePopup">닫기</button>
        </div>
      </div>
    </div>
  </div>
  <!-- /배송지 정보 수정 팝업 -->
</template>

<script>
export default {
  name: 'CashReceiptPopup',
  props: ['receiptInfo'],
  data() {
    return {
      info: {},
      cashReceiptType: '소득공제',
      cashReceiptValueType: 'cashReceiptValueType1',
      isCashReceiptTypeEarn: true,
    };
  },
  mounted() {
    this.info = { ...this.receiptInfo };
  },
  methods: {
    // 현금영수증 처리
    save: function () {
      let valid = [
        {
          field: 'info.rcvtel1',
          type: 'I',
          name: '전화번호, 카드번호 혹은 사업자번호',
          required: true,
        },
      ];
      let msg = this.$util.validMsg(this.$data, valid);
      if (!this.$util.isNull(msg)) {
        alert(msg);
        return;
      }

      let param = {
        orderidx: this.info.orderidx,
        type: this.cashReceiptType,
        value: this.info.ordtel,
        name: this.info.ordname,
        amount: this.info.paytotprice,
      };

      if (confirm('저장하시겠습니까?')) {
        this.$http
          .post('/admin/order/manage/cashreceipt/update', param)
          .then((result) => {
            this.$util.debug(result);
            if (result.statusCode == '200') {
              alert('저장이 완료되었습니다.');
              this.$emit('closePopup', true);
            }
          })
          .catch((error) => {
            this.$util.debug(error);
          });
      }
    },
    // 팝업 닫기
    closePopup: function () {
      this.$emit('closePopup');
    },
  },
  watch: {
    // 조회기간
    cashReceiptType: function (value) {
      if (value == '소득공제') {
        this.isCashReceiptTypeEarn = true;
      } else {
        this.isCashReceiptTypeEarn = false;
      }
    },
  },
};
</script>
