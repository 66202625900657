var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "800px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("인증번호 입력")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c(
              "table",
              {
                staticClass: "gray-tb",
                attrs: { cellpadding: "0", cellspacing: "0" },
              },
              [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _vm._m(1),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.phone,
                            expression: "info.phone",
                          },
                        ],
                        attrs: { type: "text", disabled: "" },
                        domProps: { value: _vm.info.phone },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.info, "phone", $event.target.value)
                          },
                        },
                      }),
                      !_vm.timer.isStart && !_vm.authComplete
                        ? _c(
                            "button",
                            {
                              staticClass: "btn blue-line ml3",
                              attrs: { type: "button" },
                              on: { click: _vm.onSendAuthNo },
                            },
                            [_vm._v(" 인증번호 전송 ")]
                          )
                        : _vm._e(),
                      _vm.timer.isStart && !_vm.authComplete
                        ? _c(
                            "button",
                            {
                              staticClass: "btn blue-line ml3",
                              attrs: { type: "button" },
                              on: { click: _vm.onSendAuthNo },
                            },
                            [_vm._v(" 재발송 ")]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("tr", [
                    _vm._m(2),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.inputAuthNo,
                            expression: "info.inputAuthNo",
                          },
                        ],
                        staticStyle: { width: "194px" },
                        attrs: {
                          type: "text",
                          placeholder: "휴대폰 인증번호 6자리",
                          readonly: _vm.authComplete,
                        },
                        domProps: { value: _vm.info.inputAuthNo },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.info,
                              "inputAuthNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.timer.isStart,
                              expression: "timer.isStart",
                            },
                          ],
                          staticClass: "ml3",
                          staticStyle: { color: "red" },
                        },
                        [_vm._v(_vm._s(_vm.timer.expiredTime))]
                      ),
                      _c("div", { staticClass: "mt10" }, [
                        _vm._v(
                          "인증번호 유효 시간은 발송 후 10분 이내 입니다."
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.onConfirm },
                },
                [_vm._v("확인")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("취소")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("전화번호"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("인증번호 입력"),
      _c("i", { staticClass: "essential" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }