var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-area", staticStyle: { height: "calc(90vh - 246px)" } },
    [
      _vm._m(0),
      _c("div", { staticClass: "mini-dash col3" }, [
        _c("dl", [
          _c("dt", [_vm._v("총 적립")]),
          _c("dd", [_c("p", [_vm._v(_vm._s(_vm.pay) + "원")])]),
        ]),
        _c("dl", [
          _c("dt", [_vm._v("총 차감")]),
          _c("dd", [_c("p", [_vm._v(_vm._s(_vm.used) + "원")])]),
        ]),
        _c("dl", [
          _c("dt", [_vm._v("가용")]),
          _c("dd", [_c("p", [_vm._v(_vm._s(_vm.pos) + "원")])]),
        ]),
      ]),
      _c("div", { staticClass: "boxing search-area" }, [
        _c("dl", [
          _c("dt", [_vm._v("적립/차감/소멸일자")]),
          _c(
            "dd",
            [
              _c("common-date-picker", {
                attrs: { value: _vm.searchData.startDate },
                on: { change: _vm.onChangeStartDate },
              }),
              _c("span", [_vm._v("-")]),
              _c("common-date-picker", {
                attrs: { value: _vm.searchData.endDate },
                on: { change: _vm.onChangeEndDate },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "btn-group" }, [
        _c(
          "button",
          {
            staticClass: "btn big blue",
            attrs: { type: "button" },
            on: { click: _vm.onSearch },
          },
          [_vm._v("검색")]
        ),
      ]),
      _c("div", { staticClass: "caption-group mt10 clearfix" }, [
        _c("div", { staticClass: "total-group fl" }, [
          _c("span", { staticClass: "total" }, [
            _vm._v("전체 "),
            _c("strong", [_vm._v(_vm._s(_vm.totalcnt))]),
            _vm._v("건"),
          ]),
          _c("span", [
            _vm._v("적립 "),
            _c("strong", [_vm._v(_vm._s(_vm.paycnt))]),
            _vm._v("건"),
          ]),
          _c("span", [
            _vm._v("차감 "),
            _c("strong", [_vm._v(_vm._s(_vm.usecnt))]),
            _vm._v("건"),
          ]),
          _c("span", [
            _vm._v("소멸 "),
            _c("strong", [_vm._v(_vm._s(_vm.extinctcnt))]),
            _vm._v("건"),
          ]),
        ]),
        _c("div", { staticClass: "btn-group fr" }, [
          _c(
            "button",
            {
              staticClass: "btn green-line",
              attrs: { type: "button" },
              on: { click: _vm.downloadExcel },
            },
            [_c("i", { staticClass: "icon-excel" }), _vm._v("엑셀다운로드 ")]
          ),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.pagingData.pageCount,
                  expression: "pagingData.pageCount",
                },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.pagingData,
                    "pageCount",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "20" } }, [_vm._v("20개씩 보기")]),
              _c("option", { attrs: { value: "50" } }, [_vm._v("50개씩 보기")]),
              _c("option", { attrs: { value: "100" } }, [
                _vm._v("100개씩 보기"),
              ]),
            ]
          ),
        ]),
      ]),
      _c(
        "table",
        {
          staticClass: "data-tb align-c",
          attrs: { cellpadding: "0", cellspacing: "0" },
        },
        [
          _c("caption", [_vm._v(" 적립금 목록 ")]),
          _vm._m(1),
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("No")]),
              _c("th", [
                _vm._v(" 포인트 "),
                _c("button", {
                  staticClass: "sort",
                  class: [
                    { up: _vm.sortData.point === "point_asc" },
                    { down: _vm.sortData.point === "point_desc" },
                  ],
                  attrs: { type: "button", value: _vm.sortData.point },
                  on: {
                    click: function ($event) {
                      return _vm.sortToggle(_vm.sortData.point)
                    },
                  },
                }),
              ]),
              _c("th", [_vm._v("자동/수동")]),
              _c("th", [_vm._v("종류")]),
              _c("th", [_vm._v("상세 구분")]),
              _c("th", [_vm._v("수동지급/차감 사유")]),
              _c("th", [_vm._v("주문번호")]),
              _c("th", [
                _vm._v(" 적립/차감/소멸일자 "),
                _c("button", {
                  staticClass: "sort",
                  class: [
                    { up: _vm.sortData.regdate === "regdate_asc" },
                    { down: _vm.sortData.regdate === "regdate_desc" },
                  ],
                  attrs: { type: "button", value: _vm.sortData.regdate },
                  on: {
                    click: function ($event) {
                      return _vm.sortToggle(_vm.sortData.regdate)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm.reserveList.length > 0
            ? _c(
                "tbody",
                _vm._l(_vm.reserveList, function (item, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [_vm._v(_vm._s(_vm.loopNumberForPaging(index)))]),
                    _c("td", [_vm._v(_vm._s(item.point))]),
                    _c("td", [_vm._v(_vm._s(item.isauto))]),
                    _c("td", [_vm._v(_vm._s(item.typekindname))]),
                    _c("td", [_vm._v(_vm._s(item.typename))]),
                    _c("td", [_vm._v(_vm._s(item.reason))]),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function ($event) {
                              return _vm.goOrderDetail(item.ordercode)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.ordercode))]
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(item.regdate))]),
                  ])
                }),
                0
              )
            : _c("tbody", [_vm._m(2)]),
        ]
      ),
      _c(
        "div",
        { staticClass: "bottom-group" },
        [
          _c("common-page-navigator", {
            attrs: { pagingData: _vm.pagingData },
            on: { setPagingData: _vm.setPagingData },
          }),
          _c("div", { staticClass: "btn-group" }, [
            _c(
              "button",
              {
                staticClass: "btn blue",
                attrs: { type: "button" },
                on: { click: _vm.goNewReserve },
              },
              [_vm._v("적립금 수동지급")]
            ),
          ]),
        ],
        1
      ),
      _vm.isOrderDetailShow
        ? _c("OrderDetail", {
            attrs: { activeOrderCode: _vm.activeOrderCode },
            on: { closeDetail: _vm.closeOrderDetailPopup },
          })
        : _vm._e(),
      _vm.isNewReserveShow
        ? _c("ManualRegist", { on: { closePopup: _vm.closeNewReservePopup } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "bar-title small fl" }, [
        _vm._v("임직원적립금 적립/차감/소멸내역"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "7" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }