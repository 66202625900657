<template>
  <!-- 상세 내역 팝업 -->
  <div id="modal-wrap" class="modal" style="display: block">
    <div class="modal-content" style="width: 1600px">
      <div class="pop-header">
        <h2>상세 내역</h2>
        <button type="button" class="pop-close" @click="onClose"></button>
      </div>
      <div class="pop-body">
        <div class="caption-group mt10 clearfix">
          <div class="total-group fl">
            <span class="total"
              >상세내역 <strong>{{ totalCnt }}</strong
              >건</span
            >
          </div>

          <div class="btn-group fr">
            <button class="btn green-line" type="button" @click="excelDown">
              <i class="icon-excel"></i>엑셀다운로드
            </button>
            <select v-model="pagingData.pageCount">
              <option value="20">20개씩 보기</option>
              <option value="50">50개씩 보기</option>
              <option value="100">100개씩 보기</option>
            </select>
          </div>
        </div>
        <div class="scroll-x">
          <table cellpadding="0" cellspacing="0" class="data-tb align-c" style="min-width: 2000px">
            <colgroup>
              <col width="1.5%" />
              <!-- No -->
              <col width="7.3%" />
              <!-- 전표생성단위번호 -->
              <col width="4%" />
              <!-- 전기일 -->
              <col width="4%" />
              <!-- 증빙일 -->
              <col width="2.7%" />
              <!-- 전표유형 -->
              <col width="2.5%" />
              <!-- 통화키 -->
              <col width="" />
              <!-- 헤더텍스트 -->
              <col width="3.4%" />
              <!-- 입력자사번 -->
              <col width="3.4%" />
              <!-- 입력자이름 -->
              <col width="2.5%" />
              <!-- 차/대지시자 -->
              <col width="4%" />
              <!-- G/L계정 -->
              <col width="3.7%" />
              <!-- 고객코드 -->
              <col width="5.5%" />
              <!-- 전표통화금액 -->
              <col width="4.4%" />
              <!-- 전표통화 과세표준 금액 -->
              <col width="3%" />
              <!-- 세금코드 -->
              <col width="4%" />
              <!-- 지급기산일 -->
              <col width="4.5%" />
              <!-- 등록일 -->
              <col width="3%" />
              <!-- SAP전송여부 -->
              <col width="4%" />
              <!-- SAP전송처리 -->
              <col width="4.5%" />
              <!-- SAP전송일 -->
              <col width="4%" />
              <!-- SAP전표번호 -->
              <col width="14%" />
              <!-- SAP에러메시지 -->
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>전표생성 단위번호</th>
                <th>전기일</th>
                <th>증빙일</th>
                <th>전표유형</th>
                <th>통화키</th>
                <th>헤더텍스트</th>
                <th>입력자사번</th>
                <th>입력자이름</th>
                <th>차/대변</th>
                <th>G/L계정</th>
                <th>고객코드</th>
                <th>전표통화금액</th>
                <th>전표통화 과세<br />표준금액</th>
                <th>세금코드</th>
                <th>지급기산일</th>
                <th>등록일</th>
                <th>SAP<br />전송여부</th>
                <th>SAP<br />전송처리</th>
                <th>SAP전송일</th>
                <th>SAP<br />전표번호</th>
                <th>SAP 에러메시지</th>
              </tr>
            </thead>
            <tbody v-if="detailList.length > 0">
              <tr v-for="(row, index) in detailList" v-bind:key="index">
                <td>{{ loopNumberForPaging(index) }}</td>
                <td>{{ row.docsn }}</td>
                <td>{{ row.budat }}</td>
                <td>{{ row.bldat }}</td>
                <td>{{ row.blart }}</td>
                <td>{{ row.waers }}</td>
                <td class="left">{{ row.bktxt }}</td>
                <td>{{ row.xref1hd }}</td>
                <td>{{ row.xref2hd }}</td>
                <td>{{ row.shkzg }}</td>
                <td>{{ row.hkont }}</td>
                <td>{{ row.kunnr }}</td>
                <td class="right">{{ $util.maskComma(row.wrbtr) }}</td>
                <td class="right">{{ $util.maskComma(row.fwbas) }}</td>
                <td>{{ row.mwskz }}</td>
                <td>{{ row.zfbdt }}</td>
                <td>{{ row.regdate }}</td>
                <td>{{ row.issend }}</td>
                <td>
                  <button
                    v-if="row.issend === '미전송'"
                    class="btn blue-line"
                    type="button"
                    @click="sendToSap(row.headidx)"
                  >
                    전송</button
                  ><button
                    v-if="row.issend === '전송'"
                    class="btn blue-line"
                    type="button"
                    @click="sendCancelToSap(row.headidx)"
                  >
                    전송취소
                  </button>
                </td>
                <td>{{ row.senddate }}</td>
                <td>{{ row.belnr }}</td>
                <td>{{ row.sapmsg }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="22">조회 결과가 존재하지 않습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bottom-group">
          <common-page-navigator
            v-bind:pagingData="pagingData"
            v-on:setPagingData="setPagingData"
          ></common-page-navigator>
        </div>
        <div class="btn-group mt20">
          <button type="button" class="btn big darkgray" @click="onClose">닫기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonPageNavigator from '@views.admin/common/CommonPageNavigator';

export default {
  name: 'MonthlySalesClosingDetail',
  components: { CommonPageNavigator },
  props: ['dtlData'],
  data() {
    return {
      info: this.dtlData,
      pagingData: {
        pageCount: 20,
        page: 1,
        listCount: 0,
      },
      totalCnt: 0,
      detailList: [],
      isFirstSearch: true, // 최초 조회 여부 (최초 조회여부가 true인 경우 setPagingData에서 조회하지 않는다.)
    };
  },
  mounted() {
    this.onSearch(1);
  },
  methods: {
    onSearch(page) {
      let param = this.dtlData;
      param.pageCount = this.pagingData.pageCount;
      param.page = typeof page === 'undefined' ? this.pagingData.page : 1;
      param.listCount = this.pagingData.listCount;

      this.$http
        .post('/admin/adjust/monthly-sales-closing/detail', param)
        .then((result) => {
          this.pagingData.listCount = result.data.listcount;
          this.detailList = result.data.list;
          this.totalCnt = result.data.listcount;
          this.$util.dataSetSearchParam(this);
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    setPagingData: function (obj) {
      this.pagingData = obj;
      if (this.isFirstSearch == false) {
        this.onSearch();
      }
      if (this.isFirstSearch == true) {
        this.isFirstSearch = false;
      }
    },
    loopNumberForPaging(index) {
      if (this.pagingData.page > 1) {
        let page = this.pagingData.page - 1;
        return index + 1 + page * this.pagingData.pageCount;
      } else {
        return index + 1;
      }
    },
    excelDown() {
      let param = this.dtlData;

      let config = { responseType: 'arraybuffer' };
      this.$http
        .post('/admin/adjust/monthly-sales-closing/detail/excel', param, config)
        .then((result) => {
          this.$util.debug(result);
          this.onSearch(1);
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    sendToSap(headIdx) {
      let param = {
        doctype: this.dtlData.doctype,
        calcmonth: this.dtlData.calcmonth,
        headidx: headIdx,
      };

      this.$http
        .post('/batch/settlement/send-fi-to-sap', param)
        .then((result) => {
          this.batchResult = result.data.data;
          alert(this.batchResult);
          this.onSearch(1);
          //this.$util.dataSetSearchParam(this);
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    sendCancelToSap(headIdx) {
      let param = {
        headidx: headIdx,
      };

      this.$http
        .post('/batch/settlement/send-cancel-doc-to-sap', param)
        .then((result) => {
          this.batchResult = result.data.data;
          alert(this.batchResult);
          this.onSearch(1);
          //this.$util.dataSetSearchParam(this);
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    onClose() {
      this.$emit('closeDetailPopup');
    },
  },
};
</script>

<style scoped></style>
