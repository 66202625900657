var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "left-menu" }, [
        _c("div", { staticClass: "inner" }, [
          _c(
            "div",
            { staticClass: "title", on: { click: _vm.goOperationMain } },
            [
              _c("i", { staticClass: "icons icon-promotion-t" }),
              _c("span", [_vm._v("프로모션")]),
            ]
          ),
          _c(
            "ul",
            { staticClass: "menu" },
            _vm._l(_vm.sideMenu, function (menu) {
              return _c(
                "li",
                { key: menu.code },
                [
                  _c(
                    "router-link",
                    {
                      class: { active: menu.isactive },
                      attrs: {
                        to:
                          menu.childmenulist.length > 0
                            ? ""
                            : { name: menu.url },
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onActive(menu)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(menu.name))]),
                      _c("i", {
                        staticClass: "icon-expand",
                        class: {
                          active:
                            menu.childmenulist.length > 0 && menu.isactive,
                        },
                      }),
                    ]
                  ),
                  _c(
                    "ul",
                    { staticClass: "sub-menu", class: { dpn: !menu.isactive } },
                    _vm._l(menu.childmenulist, function (childMenu) {
                      return _c(
                        "li",
                        { key: childMenu.code },
                        [
                          _c(
                            "router-link",
                            {
                              class: { active: childMenu.isactive },
                              attrs: { to: { name: childMenu.url } },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onActive(childMenu)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(childMenu.name) + " ")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }