var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "600px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _vm.isLongSentence === "F"
              ? _c("h2", [_vm._v("SMS 발송")])
              : _vm._e(),
            _vm.isLongSentence === "T"
              ? _c("h2", [_vm._v("LMS 발송")])
              : _vm._e(),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "tab-group mt0" }, [
              _c("ul", [
                _c(
                  "li",
                  { class: _vm.isLongSentence === "F" ? "active" : "" },
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.onChangeSentence("F")
                          },
                        },
                      },
                      [_vm._v("단문")]
                    ),
                  ]
                ),
                _c(
                  "li",
                  { class: _vm.isLongSentence === "T" ? "active" : "" },
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.onChangeSentence("T")
                          },
                        },
                      },
                      [_vm._v("장문")]
                    ),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "tab-area" }, [
              _c("div", { staticClass: "col2" }, [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticClass: "sms-input" }, [
                    _c("div", { staticClass: "input-area" }, [
                      _c("textarea", {
                        attrs: { placeholder: "보낼 문자를 입력하세요." },
                        domProps: { value: _vm.content },
                        on: { input: _vm.inputContent },
                      }),
                    ]),
                    _vm.isLongSentence === "F"
                      ? _c("div", { staticClass: "byte-cnt" }, [
                          _vm._v(" 단문 : "),
                          _c("strong", [_vm._v(_vm._s(_vm.content.length))]),
                          _vm._v(" / 90 "),
                        ])
                      : _vm._e(),
                    _vm.isLongSentence === "T"
                      ? _c("div", { staticClass: "byte-cnt" }, [
                          _vm._v(" 장문 : "),
                          _c("strong", [_vm._v(_vm._s(_vm.content.length))]),
                          _vm._v(" / 1200 "),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "right" }, [
                  _c(
                    "table",
                    {
                      staticClass: "gray-tb mt20",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _vm._m(0),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("수신번호")]),
                          _c("td", [_vm._v(_vm._s(_vm.userInfo.mobile))]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("고객명")]),
                          _c("td", [_vm._v(_vm._s(_vm.userInfo.fullname))]),
                        ]),
                      ]),
                    ]
                  ),
                  _c("div", [
                    _c("div", { staticClass: "small mt20" }, [
                      _vm._v("특수문자"),
                    ]),
                    _c("div", { staticClass: "btn-char-group" }, [
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("☆")
                            },
                          },
                        },
                        [_vm._v("☆")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("★")
                            },
                          },
                        },
                        [_vm._v("★")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♡")
                            },
                          },
                        },
                        [_vm._v("♡")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♥")
                            },
                          },
                        },
                        [_vm._v("♥")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♧")
                            },
                          },
                        },
                        [_vm._v("♧")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♣")
                            },
                          },
                        },
                        [_vm._v("♣")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("◁")
                            },
                          },
                        },
                        [_vm._v("◁")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("◀")
                            },
                          },
                        },
                        [_vm._v("◀")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("▷")
                            },
                          },
                        },
                        [_vm._v("▷")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("▶")
                            },
                          },
                        },
                        [_vm._v("▶")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♤")
                            },
                          },
                        },
                        [_vm._v("♤")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♠")
                            },
                          },
                        },
                        [_vm._v("♠")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♧")
                            },
                          },
                        },
                        [_vm._v("♧")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♣")
                            },
                          },
                        },
                        [_vm._v("♣")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("⊙")
                            },
                          },
                        },
                        [_vm._v("⊙")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("○")
                            },
                          },
                        },
                        [_vm._v("○")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("●")
                            },
                          },
                        },
                        [_vm._v("●")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("◎")
                            },
                          },
                        },
                        [_vm._v("◎")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("◇")
                            },
                          },
                        },
                        [_vm._v("◇")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("◆")
                            },
                          },
                        },
                        [_vm._v("◆")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("⇔")
                            },
                          },
                        },
                        [_vm._v("⇔")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("△")
                            },
                          },
                        },
                        [_vm._v("△")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("▲")
                            },
                          },
                        },
                        [_vm._v("▲")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("▽")
                            },
                          },
                        },
                        [_vm._v("▽")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("▼")
                            },
                          },
                        },
                        [_vm._v("▼")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("▒")
                            },
                          },
                        },
                        [_vm._v("▒")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("▤")
                            },
                          },
                        },
                        [_vm._v("▤")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("▥")
                            },
                          },
                        },
                        [_vm._v("▥")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("▦")
                            },
                          },
                        },
                        [_vm._v("▦")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("▩")
                            },
                          },
                        },
                        [_vm._v("▩")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("◈")
                            },
                          },
                        },
                        [_vm._v("◈")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("▣")
                            },
                          },
                        },
                        [_vm._v("▣")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("◐")
                            },
                          },
                        },
                        [_vm._v("◐")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("◑")
                            },
                          },
                        },
                        [_vm._v("◑")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♨")
                            },
                          },
                        },
                        [_vm._v("♨")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("☏")
                            },
                          },
                        },
                        [_vm._v("☏")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("☎")
                            },
                          },
                        },
                        [_vm._v("☎")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("☜")
                            },
                          },
                        },
                        [_vm._v("☜")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("☞")
                            },
                          },
                        },
                        [_vm._v("☞")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♭")
                            },
                          },
                        },
                        [_vm._v("♭")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♩")
                            },
                          },
                        },
                        [_vm._v("♩")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♪")
                            },
                          },
                        },
                        [_vm._v("♪")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♬")
                            },
                          },
                        },
                        [_vm._v("♬")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("㉿")
                            },
                          },
                        },
                        [_vm._v("㉿")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("㈜")
                            },
                          },
                        },
                        [_vm._v("㈜")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("℡")
                            },
                          },
                        },
                        [_vm._v("℡")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("㏇")
                            },
                          },
                        },
                        [_vm._v("㏇")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("±")
                            },
                          },
                        },
                        [_vm._v("±")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("㏂")
                            },
                          },
                        },
                        [_vm._v("㏂")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("㏘")
                            },
                          },
                        },
                        [_vm._v("㏘")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("€")
                            },
                          },
                        },
                        [_vm._v("€")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("®")
                            },
                          },
                        },
                        [_vm._v("®")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("↗")
                            },
                          },
                        },
                        [_vm._v("↗")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("↙")
                            },
                          },
                        },
                        [_vm._v("↙")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("↖")
                            },
                          },
                        },
                        [_vm._v("↖")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("↘")
                            },
                          },
                        },
                        [_vm._v("↘")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("↕")
                            },
                          },
                        },
                        [_vm._v("↕")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("↔")
                            },
                          },
                        },
                        [_vm._v("↔")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("↑")
                            },
                          },
                        },
                        [_vm._v("↑")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("↓")
                            },
                          },
                        },
                        [_vm._v("↓")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("∀")
                            },
                          },
                        },
                        [_vm._v("∀")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("∃")
                            },
                          },
                        },
                        [_vm._v("∃")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("∮")
                            },
                          },
                        },
                        [_vm._v("∮")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("∑")
                            },
                          },
                        },
                        [_vm._v("∑")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("∏")
                            },
                          },
                        },
                        [_vm._v("∏")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("℉")
                            },
                          },
                        },
                        [_vm._v("℉")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("‰")
                            },
                          },
                        },
                        [_vm._v("‰")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("￥")
                            },
                          },
                        },
                        [_vm._v("￥")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("￡")
                            },
                          },
                        },
                        [_vm._v("￡")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("￠")
                            },
                          },
                        },
                        [_vm._v("￠")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("Å")
                            },
                          },
                        },
                        [_vm._v("Å")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("℃")
                            },
                          },
                        },
                        [_vm._v("℃")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♂")
                            },
                          },
                        },
                        [_vm._v("♂")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("♀")
                            },
                          },
                        },
                        [_vm._v("♀")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("∴")
                            },
                          },
                        },
                        [_vm._v("∴")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("《")
                            },
                          },
                        },
                        [_vm._v("《")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("》")
                            },
                          },
                        },
                        [_vm._v("》")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("『")
                            },
                          },
                        },
                        [_vm._v("『")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("』")
                            },
                          },
                        },
                        [_vm._v("』")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("【")
                            },
                          },
                        },
                        [_vm._v("【")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("】")
                            },
                          },
                        },
                        [_vm._v("】")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("±")
                            },
                          },
                        },
                        [_vm._v("±")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("×")
                            },
                          },
                        },
                        [_vm._v("×")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("÷")
                            },
                          },
                        },
                        [_vm._v("÷")]
                      ),
                      _c(
                        "button",
                        {
                          staticStyle: { "font-family": "dotum" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("∥")
                            },
                          },
                        },
                        [_vm._v(" ∥ ")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("＼")
                            },
                          },
                        },
                        [_vm._v("＼")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("√")
                            },
                          },
                        },
                        [_vm._v("√")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("∽")
                            },
                          },
                        },
                        [_vm._v("∽")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickCharacter("∵")
                            },
                          },
                        },
                        [_vm._v("∵")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "pop-footer mt20" }, [
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.sendMessage },
                },
                [_vm._v("발송")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("취소")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "90px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }