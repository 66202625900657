<template>
  <div class="content m-leftmenu">
    <common-navigator></common-navigator>
    <div class="inner">
      <div class="boxing search-area">
        <dl>
          <dt>신청일자</dt>
          <dd>
            <CommonDatePicker :value="searchData.startdate" @change="onChangeStartDate" />
            <span>-</span>
            <CommonDatePicker :value="searchData.enddate" @change="onChangeEndDate" />
            <div class="radio_wrap">
              <input type="radio" v-model="searchData.period" id="rd1" value="-1" />
              <label for="rd1">어제</label>
              <input type="radio" v-model="searchData.period" id="rd2" value="0" />
              <label for="rd2">오늘</label>
              <input type="radio" v-model="searchData.period" id="rd3" value="7" />
              <label for="rd3">일주일</label>
              <input type="radio" v-model="searchData.period" id="rd4" value="1" />
              <label for="rd4">1개월</label>
              <input type="radio" v-model="searchData.period" id="rd5" value="3" />
              <label for="rd5">3개월</label>
              <input type="radio" v-model="searchData.period" id="rd6" value="6" />
              <label for="rd6">6개월</label>
              <input type="radio" v-model="searchData.period" id="rd7" value="100" />
              <label for="rd7">전체</label>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>입점상태</dt>
          <dd>
            <div class="radio_wrap">
              <input type="radio" name="status" id="rd11" v-model="searchData.state" value="" />
              <label for="rd11">전체</label>
              <input
                type="radio"
                name="status"
                id="rd22"
                v-model="searchData.state"
                value="RDS001"
              />
              <label for="rd22">대기</label>
              <input
                type="radio"
                name="status"
                id="rd44"
                v-model="searchData.state"
                value="RDS003"
              />
              <label for="rd44">반려</label>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>직접검색</dt>
          <dd>
            <select v-model="searchData.skey">
              <option value="name">업체명</option>
              <option value="bizno">사업자등록번호</option>
            </select>
            <input type="text" v-model="searchData.sword" @keyup.enter="getList(1)" />
          </dd>
        </dl>
      </div>
      <div class="btn-group" v-show="isRead">
        <button type="button" class="btn big blue" @click="getList(1)">검색</button>
        <button type="button" class="btn big gray" @click="initParam">초기화</button>
      </div>
      <div class="caption-group mt10 clearfix">
        <div class="total-group fl">
          <span class="total">
            전체
            <strong>{{ totalCnt }}</strong>
            건
          </span>
          <span>
            대기
            <strong>{{ waitCnt }}</strong>
            건
          </span>
          <span>
            반려
            <strong>{{ deniedCnt }}</strong>
            건
          </span>
        </div>
        <div class="btn-group fr">
          <select v-model="pagingData.pageCount" v-show="isRead">
            <option value="20">20개씩 보기</option>
            <option value="50">50개씩 보기</option>
            <option value="100">100개씩 보기</option>
          </select>
        </div>
      </div>
      <table cellpadding="0" cellspacing="0" class="data-tb align-c">
        <caption>파트너 입점 관리</caption>
        <colgroup>
          <!-- 아이디 -->
          <col width="12%" />
          <!-- 업체명 -->
          <col width="" />
          <!-- 사업자등록번호 -->
          <col width="11%" />
          <!-- 대표 담당자 -->
          <col width="10%" />
          <!-- 대표 담당자 휴대폰 번호 -->
          <col width="10%" />
          <!-- 담당MD -->
          <col width="8%" />
          <!-- 신청일 -->
          <col width="10%" />
          <!-- 상태 -->
          <col width="5%" />
        </colgroup>
        <thead>
          <tr>
            <th>
              아이디
              <button
                v-if="isRead"
                type="button"
                class="sort"
                :value="sortData.id"
                :class="[{ up: sortData.id === 'id_asc' }, { down: sortData.id === 'id_desc' }]"
                @click="sortToggle(sortData.id)"
              />
            </th>
            <th>
              업체명
              <button
                v-if="isRead"
                type="button"
                class="sort"
                :value="sortData.name"
                :class="[
                  { up: sortData.name === 'name_asc' },
                  { down: sortData.name === 'name_desc' },
                ]"
                @click="sortToggle(sortData.name)"
              />
            </th>
            <th>사업자등록번호</th>
            <th>대표 담당자</th>
            <th>대표 담당자 휴대폰</th>
            <th>담당 MD</th>
            <th>
              신청일
              <button
                v-if="isRead"
                type="button"
                class="sort"
                :value="sortData.regdate"
                :class="[
                  { up: sortData.regdate === 'regdate_asc' },
                  { down: sortData.regdate === 'regdate_desc' },
                ]"
                @click="sortToggle(sortData.regdate)"
              />
            </th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody v-if="listData.length > 0">
          <tr v-for="row in listData" v-bind:key="row.no">
            <td>
              <button v-if="isRead" class="link" @click="goDetail(row.no)">
                {{ row.dealer_id }}
              </button>
              <button v-else>{{ row.dealer_id }}</button>
            </td>
            <td>{{ row.name }}</td>
            <td>{{ row.bizno }}</td>
            <td>{{ row.managername }}</td>
            <td>{{ replaceMobileNumber(row.managermobile) }}</td>
            <td>{{ row.md }}</td>
            <td v-html="lineBreakDate(row.regdate)" />
            <td v-if="row.reqdealst === 'RDS001'">대기</td>
            <td v-if="row.reqdealst === 'RDS003'">반려</td>
            <td v-if="row.reqdealst === ''"></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="10">조회 결과가 존재하지 않습니다.</td>
          </tr>
        </tbody>
      </table>
      <div class="bottom-group">
        <common-page-navigator
          v-show="isRead"
          v-bind:pagingData="pagingData"
          v-on:setPagingData="setPagingData"
        ></common-page-navigator>
      </div>
    </div>
    <partners-apply-detail
      v-if="detailShow"
      :active-user-no="activeUserNo"
      :disableInitPasswordButton="true"
      :disableInitPartnerNameInput="false"
      v-on:closeDetail="closeDetail"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import util from '@js/util';
import CommonNavigator from '../../common/CommonNavigator';
import CommonPageNavigator from '../../common/CommonPageNavigator';
import CommonDatePicker from '@views.admin/common/CommonDatePicker';
import PartnersApplyDetail from '@views.admin/partners/apply/PartnersApplyDetail';

export default {
  name: 'admin.partners.applyList',
  components: { CommonNavigator, CommonPageNavigator, CommonDatePicker, PartnersApplyDetail },
  data() {
    return {
      searchData: {
        startdate: '',
        enddate: '',
        period: '7',
        state: '',
        skey: 'name',
        sword: '',
        psort: '',
        ignoreperiod: 'F',
      },
      pagingData: {
        pageCount: 20,
        page: 1,
        listCount: 0,
      },
      sortData: {
        id: 'id_desc',
        name: 'name_desc',
        regdate: 'regdate_desc',
      },
      totalCnt: 0,
      waitCnt: 0,
      approvalCnt: 0,
      deniedCnt: 0,
      listData: [],
      detailShow: false,
      activeUserNo: null,
      isRead: false,
      authUnblockPartnerAccount: false,
      isFirstSearch: true, // 최초 조회 여부 (최초 조회여부가 true인 경우 setPagingData에서 조회하지 않는다.)
    };
  },
  watch: {
    'searchData.period': function (val) {
      let dayType = ['-1', '0', '7'];
      let monthType = ['1', '3', '6'];
      let valueToInt = parseInt(val);

      if (val === '100') {
        this.searchData.ignoreperiod = 'T';
        return;
      }
      if (dayType.includes(val)) {
        if (valueToInt >= 0) {
          this.searchData.startdate = this.$util.getAddDate(
            this.$util.getDate(''),
            valueToInt * -1,
            '-',
          );
          this.searchData.enddate = this.$util.getDate('-');
        } else {
          this.searchData.startdate = this.$util.getAddDate(
            this.$util.getDate(''),
            valueToInt,
            '-',
          );
          this.searchData.enddate = this.$util.getAddDate(this.$util.getDate(''), valueToInt, '-');
        }
      } else if (monthType.includes(val)) {
        this.searchData.startdate = this.$util.getAddMonth(
          this.$util.getDate(''),
          valueToInt * -1,
          '-',
        );
        this.searchData.enddate = this.$util.getDate('-');
      }
      this.searchData.ignoreperiod = 'F';
    },
  },
  created() {
    // 상세에서 승인했을 때 상세 모달이 닫히고 리스트를 새로고침한다.
    this.$eventBus.$on('getPartnerApplyList', () => {
      this.getList();
    });
  },
  mounted() {
    this.$http
      .post('/admin/common/pageAuth/check', { url: this.$options.name, isloading: false })
      .then((result) => {
        this.isRead = result.data.isread === 'T';

        result.data.authgroup.forEach((element) => {
          if (element.authgroup == 'ATG002') {
            this.authUnblockPartnerAccount = true;
          }
        });

        /**
         * 파트너 잠금 해제권한 사용자만 현재 페이지를 이용할수 있게 한다.
         */
        this.isRead = this.isRead && this.authUnblockPartnerAccount;
        this.isWrite = this.isRead && this.authUnblockPartnerAccount;

        if (this.isRead) {
          this.searchData.startdate = this.$util.getAddDate(this.$util.getDate(''), -7, '-');
          this.searchData.enddate = this.$util.getDate('-');
          this.$util.componentSetSearchParam(this);
          this.getList(1);
        }
      })
      .catch((error) => {
        this.$util.debug(error);
      });
  },
  methods: {
    replaceMobileNumber(val) {
      return util.replaceMobileNumber(val);
    },
    replacePhoneNumber(val) {
      return util.replacePhoneNumber(val);
    },
    initParam: function () {
      this.searchData = this.$options.data().searchData;
      this.searchData.startdate = this.$util.getAddDate(this.$util.getDate(''), -7, '-');
      this.searchData.enddate = this.$util.getDate('-');
    },
    debounceGetList: debounce(
      async function (pageNum) {
        try {
          if (typeof pageNum !== 'number' && typeof pageNum !== 'undefined') {
            throw new Error('pageNum은 숫자여야 합니다.');
          }
          const param = this.searchData;
          param.pageCount = this.pagingData.pageCount;
          param.page = typeof pageNum === 'undefined' ? this.pagingData.page : pageNum;
          param.listCount = this.pagingData.listCount;
          param.isloading = true;

          const { data } = await this.$http.post('/admin/partners/apply/search', param);

          this.totalCnt = data.listcount;
          this.waitCnt = data.waitcnt;
          this.approvalCnt = data.approvalcnt;
          this.deniedCnt = data.deniedcnt;
          this.pagingData.listCount = data.listcount;
          this.listData = data.list;
          this.$util.dataSetSearchParam(this);
        } catch (err) {
          util.debug(err.message);
        }
      },
      500,
      { leading: true, trailing: false },
    ),
    getList(pageNum) {
      this.debounceGetList(pageNum);
    },
    sortToggle: function (key) {
      let arr = key.split('_');
      let sortKey = arr[0];
      let sortOrder = arr[1] === 'asc' ? 'desc' : 'asc';
      let sortName = sortKey + '_' + sortOrder;

      this.sortData = this.$options.data().sortData;
      this.sortData[sortKey] = sortName;
      this.searchData.psort = sortName;

      this.getList();
    },
    goDetail: function (userNo) {
      this.activeUserNo = userNo;
      this.detailShow = true;
    },
    setPagingData: function (obj) {
      this.pagingData = obj;
      if (this.isFirstSearch == false) {
        this.getList();
      }
      if (this.isFirstSearch == true) {
        this.isFirstSearch = false;
      }
    },
    // 날짜 picker 콜백 함수
    onChangeStartDate(val) {
      this.searchData.startdate = val;
    },
    // 날짜 picker 콜백 함수
    onChangeEndDate(val) {
      this.searchData.enddate = val;
    },
    closeDetail() {
      this.detailShow = false;
    },
    lineBreakDate(dateStr) {
      const splitStr = dateStr.split(' ');
      return `${splitStr[0]}<br />${splitStr[1]}`;
    },
  },
};
</script>
<style scoped>
.link {
  text-decoration: none !important;
}
button {
  background-color: transparent;
  border: none;
}
</style>
