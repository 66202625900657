<template>
  <div class="form-area">
    <table cellpadding="0" cellspacing="0" class="gray-tb">
      <colgroup>
        <col width="180px" />
        <col width="" />
      </colgroup>
      <tbody>
        <tr>
          <th>
            담당업무
            <i class="essential"></i>
          </th>
          <td colspan="3">
            <div>
              <div class="check-wrap">
                <input
                  type="checkbox"
                  id="manager_role_all"
                  v-model="isChecked"
                  @change="onCheckAll($event.target.checked)"
                />
                <label for="manager_role_all">전체</label>
              </div>
              <div v-for="role in getManagerRoleOptions()" :key="role.value" class="check-wrap">
                <input
                  type="checkbox"
                  :id="'manager_role_' + role.value"
                  v-model="value.roles"
                  :value="role.value"
                />
                <label :for="'manager_role_' + role.value">{{ role.label }}</label>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            아이디
            <i class="essential"></i>
          </th>
          <fragment v-if="isNew">
            <td colspan="3">
              <div class="new-id-field">
                <input
                  type="text"
                  style="width: 100%"
                  v-model.trim="value.loginId"
                  :disabled="!isNew"
                />
              </div>
              <p v-if="checkValidField('loginId')" class="alert-text txt-red">
                <i class="icon-alert2" />
                5~12자의 영문 소문자와 숫자 조합만 사용 가능합니다.
              </p>
              <p v-else class="txt-gray">5~12자의 영문 소문자와 숫자를 조합해주세요.</p>
            </td>
          </fragment>
          <td v-else colspan="3">
            <input
              type="text"
              style="width: 100%"
              v-model.trim="value.loginId"
              :disabled="!isNew"
            />
          </td>
        </tr>
        <tr>
          <th>
            이름
            <i class="essential"></i>
          </th>
          <td colspan="3">
            <input
              type="text"
              style="width: 100%"
              v-model.trim="value.name"
              :class="{ error: checkValidField('name') }"
            />
            <p v-show="checkValidField('name')" class="alert-text txt-red">
              <i class="icon-alert2" />
              이름은 필수 입력입니다.
            </p>
          </td>
        </tr>
        <tr>
          <th>
            휴대폰번호
            <i class="essential"></i>
          </th>
          <td colspan="3">
            <input
              type="text"
              maxlength="13"
              style="width: 100%"
              v-model.trim="value.mobile"
              :class="{ error: checkValidField('mobile') }"
            />
            <p v-if="checkValidField('mobile')" class="alert-text txt-red">
              <i class="icon-alert2" />
              적합한 휴대폰번호 형식으로 기입해주세요.
            </p>
            <p v-else class="txt-gray">
              임시비밀번호 메시지를 받는 연락처입니다. (‘-’ 없이 숫자만 입력)
            </p>
          </td>
        </tr>
        <tr>
          <th>
            이메일
            <i class="essential"></i>
          </th>
          <td colspan="3">
            <div>
              <input
                type="text"
                v-model.trim="emailForm.id"
                :class="{ error: checkValidField('email') }"
              />
              <span>@</span>
              <input
                type="text"
                v-show="emailForm.selectDomain === 'input'"
                v-model.trim="emailForm.domain"
                style="width: 150px"
                :class="{ error: checkValidField('email') }"
              />
              <input
                type="text"
                v-show="emailForm.selectDomain !== 'input'"
                v-model.trim="emailForm.selectDomain"
                style="width: 150px"
                :class="{ error: checkValidField('email') }"
                disabled
              />
              <select v-model="emailForm.selectDomain">
                <option
                  v-for="domain in getDomainOptions()"
                  :key="domain.value"
                  :value="domain.value"
                >
                  {{ domain.label }}
                </option>
              </select>
            </div>
            <p v-if="checkValidField('email')" class="alert-text txt-red">
              <i class="icon-alert2" />
              적합한 이메일 형식으로 기입해주세요.
            </p>
            <p v-else-if="!checkValidField('email') && is세금계산서담당업무선택" class="txt-gray">
              세금계산서를 받는 이메일 주소입니다.
            </p>
          </td>
        </tr>

        <tr v-if="isNew">
          <th>
            개인정보 수집 동의
            <i class="essential"></i>
          </th>
          <td colspan="3">
            <div>
              <p class="bold-text">[필수] 개인정보 수집 동의 (운영 계정 신청)</p>
              <ul>
                <li>- 수집 항목 : 아이디, 비밀번호, 이름, 휴대폰번호, 이메일</li>
                <li>- 이용 목적 : 판매자 별 담당자 식별 및 관리 목적</li>
                <li class="bold-text">
                  - 보유 및 이용 기간 : 계약 종료 또는 계정 삭제 처리 시 파기
                </li>
              </ul>
              <div class="agree-wrap">
                <input type="checkbox" id="agree" name="agree" v-model="value.agree" />
                <label for="agree">개인정보 수집 및 이용에 동의합니다.</label>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { EMAIL_DOMAIN_OPTIONS, MANAGER_ROLE_OPTIONS, MANAGER_ROLE_TYPE } from '@constants/manager';

export default {
  name: 'manager.regist.form',
  props: ['value', 'isNew'],
  data() {
    return {
      isChecked: false,
      emailForm: {
        id: '',
        domain: '',
        selectDomain: 'input',
      },
      originValue: {},
      invaildField: [],
      dirtyfield: [],
    };
  },
  computed: {
    is세금계산서담당업무선택() {
      return this.value.roles.includes(MANAGER_ROLE_TYPE.세금);
    },
  },
  watch: {
    'value.roles': {
      handler(value) {
        const isValid = value.length !== 0;
        this.updateField('roles', { value, isValid });
        this.isChecked = MANAGER_ROLE_OPTIONS.length === value.length;
      },
      deep: true,
    },
    'value.loginId': {
      handler(value) {
        if (this.isNew) {
          const isValid = this.$util.isValidId(value);
          this.updateField('loginId', { value, isValid });
        }
      },
      deep: true,
    },
    'value.name': {
      handler(value) {
        const isValid = !!value;
        this.updateField('name', { value, isValid });
      },
      deep: true,
    },
    'value.mobile': {
      handler(value) {
        this.handleChangeMobileNumber(value);
        const isValid = this.$util.isValidMobileNumber(value);
        this.updateField('mobile', { value, isValid });
      },
      deep: true,
    },
    'value.email': {
      handler(value) {
        const isValid = this.$util.isValidEmail(value);
        this.updateField('email', { value, isValid });
      },
      deep: true,
    },
    'value.agree': {
      handler(value) {
        this.updateField('agree', { value, isValid: value });
      },
      deep: true,
    },
    emailForm: {
      handler(value) {
        const is직접입력 = value.selectDomain === 'input';
        this.value.email = `${value.id}@${is직접입력 ? value.domain : value.selectDomain}`;
      },
      deep: true,
    },
    invaildField: {
      handler(value) {
        this.value.invaildField = value;
      },
      deep: true,
    },
  },
  mounted() {
    const { email, roles, mobile } = this.value;
    const replaceMobile = this.$util.replaceMobileNumber(mobile);
    this.originValue = { ...this.value, mobile: replaceMobile };

    if (this.$util.isValidEmail(email)) {
      const splitEmail = email.split('@');
      const domain = EMAIL_DOMAIN_OPTIONS.find(({ value }) => value === splitEmail[1]);

      this.emailForm = {
        id: splitEmail[0],
        domain: splitEmail[1],
        selectDomain: !domain ? 'input' : domain.value,
      };
    }

    this.isChecked = MANAGER_ROLE_OPTIONS.length === roles?.length;
    this.value.mobile = replaceMobile;
    this.onValid();
  },
  methods: {
    onCheckAll(checked) {
      this.value.roles = !checked ? [] : MANAGER_ROLE_OPTIONS.map(({ value }) => value);
    },
    onValid() {
      const managerInfo = this.value;

      Object.keys(managerInfo).forEach((key) => {
        let isValid = true;
        const value = managerInfo[key];

        switch (key) {
          case 'roles':
            isValid = value.length !== 0;
            break;
          case 'loginId':
            isValid = this.$util.isValidId(value);
            break;
          case 'name':
            isValid = !!value;
            break;
          case 'mobile':
            isValid = this.$util.isValidMobileNumber(value);
            break;
          case 'email':
            isValid = this.$util.isValidEmail(value);
            break;
          case 'agree':
            isValid = this.isNew ? value : true;
            break;
          default:
            break;
        }

        this.updateInvalidField(key, isValid);
      });
    },
    getManagerRoleOptions() {
      return [...MANAGER_ROLE_OPTIONS];
    },
    getDomainOptions() {
      return [...EMAIL_DOMAIN_OPTIONS];
    },
    updateField(fieldName, param) {
      this.updateInvalidField(fieldName, param.isValid);
      this.updateDirtyField(fieldName, param.value);
    },
    updateInvalidField(fieldName, isValid) {
      const invaildField = this.invaildField.filter((field) => field !== fieldName);
      this.invaildField = [...invaildField, ...(isValid ? [] : [fieldName])];
    },
    updateDirtyField(fieldName, value) {
      const originValue = this.originValue[fieldName];
      const isDirty =
        fieldName !== 'roles'
          ? originValue !== value
          : originValue.length !== value.length ||
            !originValue.every((role) => value.includes(role));

      this.dirtyfield = [
        ...this.dirtyfield.filter((field) => field !== fieldName),
        ...(isDirty ? [fieldName] : []),
      ];
    },
    checkValidField(fieldName) {
      return this.dirtyfield.includes(fieldName) && this.invaildField.includes(fieldName);
    },
    handleChangeMobileNumber(value) {
      const result = this.$util.replaceMobileNumber(value);
      this.value.mobile = result;
    },
  },
};
</script>

<style scoped>
input.error,
input:focus.error {
  border-color: #d74848;
}
.new-id-field {
  display: flex;
  gap: 10px;
}

.bold-text {
  font-weight: bold;
}
.agree-wrap {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
</style>
