var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1280px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("약관 상세")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c(
              "table",
              {
                staticClass: "gray-tb",
                attrs: { cellpadding: "0", cellspacing: "0" },
              },
              [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("구분")]),
                    _c("td", [_vm._v(_vm._s(_vm.info.iscusterms))]),
                    _c("th", [_vm._v("등록자")]),
                    _c("td", [_vm._v(_vm._s(_vm.info.reguserid))]),
                    _c("th", [_vm._v("버전")]),
                    _c("td", [_vm._v(_vm._s(_vm.info.version))]),
                    _c("th", [_vm._v("필수여부")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.info.isessen === "T" ? "필수" : "선택")
                      ),
                    ]),
                    _c("th", [_vm._v("업데이트일시")]),
                    _c("td", [_vm._v(_vm._s(_vm.info.moddate))]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("약관명")]),
                    _c("td", { attrs: { colspan: "9" } }, [
                      _vm._v(_vm._s(_vm.info.termsname)),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("약관 내용")]),
                    _c(
                      "td",
                      { attrs: { colspan: "9" } },
                      [
                        _c("CommonEditor", {
                          ref: "editor",
                          attrs: { disable: true },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "100px" } }),
      _c("col", { attrs: { width: "140px" } }),
      _c("col", { attrs: { width: "100px" } }),
      _c("col", { attrs: { width: "140px" } }),
      _c("col", { attrs: { width: "100px" } }),
      _c("col", { attrs: { width: "120px" } }),
      _c("col", { attrs: { width: "100px" } }),
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "100px" } }),
      _c("col", { attrs: { width: "160px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }