<template>
  <!-- 운영자 계정 비밀번호 초기화 팝업 -->
  <div id="modal-wrap" class="modal" style="display: block">
    <div class="modal-content" style="width: 800px">
      <div class="pop-header">
        <h2>비밀번호 초기화</h2>
        <button type="button" class="pop-close" @click="onClose"></button>
      </div>
      <div class="pop-body">
        <div class="bar-title">기본정보</div>
        <table cellpadding="0" cellspacing="0" class="gray-tb">
          <colgroup>
            <col width="150px" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th>현재 비밀번호<i class="essential"></i></th>
              <td>
                <input type="password" v-model="info.orgpw" />
              </td>
            </tr>
            <tr>
              <th>변경 비밀번호<i class="essential"></i></th>
              <td>
                <input type="password" v-model="info.userpw" />
                <span class="txt-orange ml3"
                  ><i class="icon-alert"></i>영문/숫자/특수문자 3가지 이상 조합(최소 8자 이상)</span
                >
              </td>
            </tr>
            <tr>
              <th>비밀번호 확인<i class="essential"></i></th>
              <td>
                <input type="password" v-model="info.userpw2" />
                <span class="txt-orange ml3"
                  ><i class="icon-alert"></i>영문/숫자/특수문자 3가지 이상 조합(최소 8자 이상)</span
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div class="btn-group">
          <button type="button" class="btn big blue" @click="onSave">저장</button>
          <button type="button" class="btn big darkgray" @click="onClose">취소</button>
        </div>
      </div>
    </div>
  </div>
  <!-- /운영자 등록 팝업 -->
</template>

<script>
export default {
  name: 'AdminResetPasswordPopup',
  props: {
    params: Object,
  },
  data() {
    return {
      info: {
        userno: 0,
        orgpw: '',
        userpw: '',
        userpw2: '',
      },
      pwcheck: false, // 비밀번호 유효성체크
      pwconfirmcheck: false, // 비밀번호 확인
    };
  },
  methods: {
    onSave() {
      if (this.checkValidation()) {
        let param = this.info;

        if (confirm('저장하시겠습니까?')) {
          this.$http.post('/admin/common/reset-password', param).then((result) => {
            if (result.statusCode === 200) {
              alert('저장되었습니다.');
              this.onClose();
            }
          });
        }
      }
    },
    checkValidation() {
      this.pwCheck();
      this.pwConfirm();

      let msg = '';

      let valid = [
        { field: 'info.orgpw', type: 'I', name: '[기본정보] 현재 비밀번호', required: true },
        { field: 'info.userpw', type: 'I', name: '[기본정보] 변경 비밀번호', required: true },
        { field: 'info.userpw2', type: 'I', name: '[기본정보] 변경 비밀번호 확인', required: true },
      ];

      msg = this.$util.validMsg(this.$data, valid);
      if (!this.$util.isNull(msg)) {
        alert(msg);
        return false;
      }

      if (!this.pwcheck) {
        alert('비밀번호 형식이 올바르지 않습니다.');
        return false;
      }

      if (!this.pwconfirmcheck) {
        alert('비밀번호확인이 일치하지 않습니다.');
        return false;
      }

      return true;
    },
    pwCheck() {
      if (this.$util.isPassword(this.info.userpw)) {
        this.pwcheck = true;
      } else {
        this.pwcheck = false;
      }
    },
    pwConfirm() {
      if (this.$util.isNull(this.info.userpw) || this.$util.isNull(this.info.userpw2)) {
        this.pwconfirmcheck = false;
      } else {
        if (this.info.userpw == this.info.userpw2) {
          this.pwconfirmcheck = true;
        } else {
          this.pwconfirmcheck = false;
        }
      }
    },
    onClose() {
      this.$modal.hide('commonModal');
    },
  },
  mounted() {
    this.info.userno = this.params.no1;
  },
};
</script>

<style></style>
