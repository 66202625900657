var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content m-leftmenu" },
    [
      _c("common-navigator"),
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "sub-title" }, [
          _c("div", { staticClass: "bar-title" }, [
            _vm._v("QR Code 정보 입력"),
          ]),
          _c(
            "button",
            {
              staticClass: "btn big blue",
              attrs: { type: "button" },
              on: { click: _vm.createQrcode },
            },
            [_vm._v("생성")]
          ),
        ]),
        _c("div", { staticClass: "form-area" }, [
          _c(
            "table",
            {
              staticClass: "gray-tb",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _vm._m(1),
                  _c("td", [
                    _c("input", {
                      ref: "linkRef",
                      staticStyle: { width: "calc(100% - 100px)" },
                      attrs: {
                        type: "text",
                        placeholder: "QR Code 리더기 통해서 연결되는 주소",
                      },
                    }),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "qr-section" }, [
          _c("div", { staticClass: "sub-title" }, [
            _c("div", { staticClass: "bar-title" }, [_vm._v("QR Code")]),
            _c(
              "button",
              {
                staticClass: "btn big blue",
                attrs: { type: "button" },
                on: { click: _vm.downloadQrImage },
              },
              [_vm._v("QR 다운로드")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "boxing forbiddenword-group clearfix" },
            [
              _c("vue-qrcode", {
                attrs: {
                  type: "image/png",
                  width: 20,
                  quality: 1,
                  color: { dark: "#000000ff", light: "#ffffffff" },
                  value: _vm.link,
                },
                on: { change: _vm.onDataUrlChange },
              }),
              _c("img", {
                staticClass: "qr-image",
                attrs: { src: _vm.base64QrCode },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("링크"), _c("i", { staticClass: "essential" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }