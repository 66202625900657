export const GOODS_NOTICE_DISPLAY_STATUS = {
  전시: 'VISIBLE',
  비전시: 'HIDDEN',
};

export const GOODS_NOTICE_TYPE = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
};

export const GOODS_NOTICE_DISPLAY_STATUS_OPTIONS = [
  { value: 'VISIBLE', label: '전시함' },
  { value: 'HIDDEN', label: '전시안함' },
];

export const GOODS_NOTICE_TYPE_OPTIONS = [
  { value: 'TEXT', label: '내용 입력' },
  { value: 'IMAGE', label: '이미지 업로드' },
];

export const GOODS_NOTICE_SEARCH_TYPE = {
  파트너명: 'PARTNERNAME',
  공지사항명: 'TITLE',
};

export const GOODS_NOTICE_SEARCH_OPTIONS = [
  { value: 'PARTNERNAME', label: '파트너명' },
  { value: 'TITLE', label: '공지사항명' },
];
