var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content m-leftmenu" },
    [
      _c("common-navigator"),
      _vm.showdupl
        ? _c("DuplBannedPopup", {
            attrs: { dupllist: _vm.dupllist },
            on: { closeDupl: _vm.closeDupl },
          })
        : _vm._e(),
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "boxing search-area" }, [
          _c("dl", [
            _c("dt", [_vm._v("금칙어 등록")]),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.addwordstr,
                    expression: "addwordstr",
                  },
                ],
                attrs: { type: "text", placeholder: "단어당 20자 이내" },
                domProps: { value: _vm.addwordstr },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.addwordstr = $event.target.value
                  },
                },
              }),
              _vm.isWrite
                ? _c(
                    "button",
                    {
                      staticClass: "btn blue",
                      attrs: { type: "button" },
                      on: { click: _vm.addBan },
                    },
                    [_vm._v(" 등록 ")]
                  )
                : _vm._e(),
              _vm._m(0),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "caption-group mt20 clearfix" }, [
          _c("div", { staticClass: "total-group fl" }, [
            _c("span", { staticClass: "total" }, [
              _vm._v("등록된 금칙어 "),
              _c("strong", [_vm._v(_vm._s(_vm.list.length))]),
              _vm._v("건"),
            ]),
          ]),
          _vm.isRead
            ? _c("div", { staticClass: "btn-group fr" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchword,
                      expression: "searchword",
                    },
                  ],
                  attrs: { type: "search", placeholder: "금칙어 조회" },
                  domProps: { value: _vm.searchword },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchword = $event.target.value
                    },
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn-search bg",
                    staticStyle: { "margin-left": "-3px" },
                    attrs: { type: "button" },
                    on: { click: _vm.searchList },
                  },
                  [_vm._v(" 검색 ")]
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.sort,
                        expression: "sort",
                      },
                    ],
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.sort = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.searchList,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "asc" } }, [
                      _vm._v("가나다 순"),
                    ]),
                    _c("option", { attrs: { value: "desc" } }, [
                      _vm._v("가나다 역순"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "boxing forbiddenword-group clearfix" },
          _vm._l(_vm.list, function (row, n) {
            return _c("div", { key: n }, [
              _vm._v(" " + _vm._s(row.bannedword)),
              _c("button", {
                staticClass: "file-del",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.removeBan(n)
                  },
                },
              }),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "dot-list mt20" }, [
      _c("li", [
        _vm._v(
          "한번에 여러 개의 금칙어 등록을 원할 시에는 단어 사이에 콤마(,)로 구분"
        ),
      ]),
      _c("li", [_vm._v("하나의 금칙어는 최대 20자까지 등록 가능")]),
      _c("li", [_vm._v("특수문자는 입력 불가")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }