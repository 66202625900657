var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "join-content" }, [
      _vm._m(1),
      _c("h2", { staticClass: "title" }, [_vm._v("기본정보")]),
      _c("div", { staticClass: "boxing" }, [
        _c("div", { staticClass: "form-area" }, [
          _c("dl", [
            _vm._m(2),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.info.userId,
                    expression: "info.userId",
                    modifiers: { trim: true },
                  },
                ],
                ref: "userId",
                class: { error: _vm.info.userId && !_vm.isValidId },
                attrs: {
                  placeholder: "5~12자의 영문 소문자, 숫자",
                  type: "text",
                },
                domProps: { value: _vm.info.userId },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.info, "userId", $event.target.value.trim())
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._m(3),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.info.userId,
                      expression: "info.userId",
                    },
                  ],
                  staticClass: "alert-text",
                  class: _vm.isValidId ? "txt-blue" : "txt-red",
                },
                [
                  _c("i", {
                    class: _vm.isValidId ? "icon-alert-check" : "icon-alert2",
                  }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isValidId ? "사용가능" : _vm.idCheckErrorMessage
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._m(4),
          _c("dl", [
            _vm._m(5),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.info.managerName,
                    expression: "info.managerName",
                    modifiers: { trim: true },
                  },
                ],
                ref: "managerName",
                attrs: { type: "text" },
                domProps: { value: _vm.info.managerName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.info,
                      "managerName",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
          ]),
          _c("dl", [
            _vm._m(6),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.info.managerMobile,
                    expression: "info.managerMobile",
                    modifiers: { trim: true },
                  },
                ],
                ref: "managerMobile",
                class: { error: !_vm.getIsValidMobile(_vm.info.managerMobile) },
                attrs: {
                  placeholder:
                    "핸드폰 번호를 입력해주세요. (임시비밀번호 전송)",
                  maxlength: "13",
                  type: "text",
                },
                domProps: { value: _vm.info.managerMobile },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.info,
                      "managerMobile",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._m(7),
            ]),
          ]),
        ]),
      ]),
      _c("h2", { staticClass: "title" }, [_vm._v("사업자정보")]),
      _c("div", { staticClass: "boxing" }, [
        _c("div", { staticClass: "form-area" }, [
          _c("dl", [
            _vm._m(8),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.bizNo1,
                    expression: "bizNo1",
                  },
                ],
                staticStyle: { width: "80px" },
                attrs: { disabled: "", type: "text" },
                domProps: { value: _vm.bizNo1 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.bizNo1 = $event.target.value
                  },
                },
              }),
              _c("span", [_vm._v("-")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.bizNo2,
                    expression: "bizNo2",
                  },
                ],
                staticStyle: { width: "72px" },
                attrs: { disabled: "", type: "text" },
                domProps: { value: _vm.bizNo2 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.bizNo2 = $event.target.value
                  },
                },
              }),
              _c("span", [_vm._v("-")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.bizNo3,
                    expression: "bizNo3",
                  },
                ],
                staticStyle: { width: "125px" },
                attrs: { disabled: "", type: "text" },
                domProps: { value: _vm.bizNo3 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.bizNo3 = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _c("dl", [
            _vm._m(9),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.info.bizName,
                    expression: "info.bizName",
                    modifiers: { trim: true },
                  },
                ],
                attrs: { disabled: "", type: "text" },
                domProps: { value: _vm.info.bizName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.info, "bizName", $event.target.value.trim())
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
          ]),
          _c("dl", [
            _vm._m(10),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.info.companyName,
                    expression: "info.companyName",
                    modifiers: { trim: true },
                  },
                ],
                ref: "companyName",
                attrs: { type: "text" },
                domProps: { value: _vm.info.companyName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.info,
                      "companyName",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
          ]),
          _c("dl", [
            _vm._m(11),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.info.ownerName,
                    expression: "info.ownerName",
                    modifiers: { trim: true },
                  },
                ],
                attrs: { disabled: "", type: "text" },
                domProps: { value: _vm.info.ownerName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.info, "ownerName", $event.target.value.trim())
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
          ]),
          _c("dl", [
            _vm._m(12),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.info.bizCategory,
                    expression: "info.bizCategory",
                    modifiers: { trim: true },
                  },
                ],
                ref: "bizCategory",
                attrs: { type: "text" },
                domProps: { value: _vm.info.bizCategory },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.info,
                      "bizCategory",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
          ]),
          _c("dl", [
            _vm._m(13),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.info.bizItem,
                    expression: "info.bizItem",
                    modifiers: { trim: true },
                  },
                ],
                ref: "bizItem",
                attrs: { type: "text" },
                domProps: { value: _vm.info.bizItem },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.info, "bizItem", $event.target.value.trim())
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
          ]),
          _c("dl", [
            _vm._m(14),
            _c(
              "dd",
              [
                _c("search-address", {
                  attrs: {
                    "on-search-complete-address":
                      _vm.handleCompleteSearchAddress,
                    "on-change-address-detail": _vm.handleChangeAddressDetail,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("dl", [
            _vm._m(15),
            _c("dd", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.info.mainCategory,
                      expression: "info.mainCategory",
                      modifiers: { trim: true },
                    },
                  ],
                  ref: "mainCategory",
                  staticStyle: { width: "300px" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.info,
                        "mainCategory",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "", disabled: "", selected: "" } },
                    [_vm._v("대표카테고리 선택")]
                  ),
                  _vm._l(_vm.categories, function (category) {
                    return _c(
                      "option",
                      { key: category.id, domProps: { value: category.id } },
                      [_vm._v(" " + _vm._s(category.name) + " ")]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
          _c("dl", [
            _vm._m(16),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.info.mailOrderSalesNumber,
                    expression: "info.mailOrderSalesNumber",
                    modifiers: { trim: true },
                  },
                ],
                ref: "mailOrderSalesNumber",
                attrs: { placeholder: "제2021-서울용산-0161호", type: "text" },
                domProps: { value: _vm.info.mailOrderSalesNumber },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.info,
                      "mailOrderSalesNumber",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._m(17),
            ]),
          ]),
          _c("dl", [
            _vm._m(18),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.info.csPhoneNumber,
                    expression: "info.csPhoneNumber",
                    modifiers: { trim: true },
                  },
                ],
                ref: "csPhoneNumber",
                class: { error: !_vm.getIsValidPhone(_vm.info.csPhoneNumber) },
                attrs: {
                  placeholder: "핸드폰번호 또는 전화번호를 입력해주세요.",
                  type: "text",
                },
                domProps: { value: _vm.info.csPhoneNumber },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.info,
                      "csPhoneNumber",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._m(19),
            ]),
          ]),
          _c("dl", [
            _vm._m(20),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.info.companyEmail,
                    expression: "info.companyEmail",
                    modifiers: { trim: true },
                  },
                ],
                ref: "companyEmail",
                class: { error: !_vm.isValidEmail },
                attrs: { placeholder: "예) master@gmail.com", type: "email" },
                domProps: { value: _vm.info.companyEmail },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.info,
                      "companyEmail",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isValidEmail,
                      expression: "!isValidEmail",
                    },
                  ],
                  staticClass: "alert-text txt-red",
                },
                [
                  _c("i", { staticClass: "icon-alert2" }),
                  _vm._v(" 이메일 형식에 맞게 작성해주세요. "),
                ]
              ),
              _vm._m(21),
            ]),
          ]),
        ]),
      ]),
      _c("h2", { staticClass: "title" }, [_vm._v("정산정보")]),
      _c("div", { staticClass: "boxing" }, [
        _c("div", { staticClass: "form-area" }, [
          _c("dl", [
            _vm._m(22),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.info.bankDepositor,
                    expression: "info.bankDepositor",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.info.bankDepositor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.info, "bankDepositor", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("dl", [
            _vm._m(23),
            _c("dd", [
              _c("div", { staticClass: "radio_wrap wide" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.info.remitType,
                      expression: "info.remitType",
                    },
                  ],
                  attrs: {
                    id: "rd01",
                    name: "remitType",
                    type: "radio",
                    value: "1",
                  },
                  domProps: { checked: _vm._q(_vm.info.remitType, "1") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.info, "remitType", "1")
                    },
                  },
                }),
                _c("label", { attrs: { for: "rd01" } }, [_vm._v("개인계좌")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.info.remitType,
                      expression: "info.remitType",
                    },
                  ],
                  attrs: {
                    id: "rd02",
                    name: "remitType",
                    type: "radio",
                    value: "2",
                  },
                  domProps: { checked: _vm._q(_vm.info.remitType, "2") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.info, "remitType", "2")
                    },
                  },
                }),
                _c("label", { attrs: { for: "rd02" } }, [_vm._v("법인계좌")]),
              ]),
            ]),
          ]),
          _c("dl", [
            _vm._m(24),
            _c("dd", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.info.bankCode,
                      expression: "info.bankCode",
                    },
                  ],
                  ref: "bank",
                  staticStyle: { width: "120px" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.info,
                        "bankCode",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("은행 선택")]),
                  _vm._l(_vm.banks, function (bank) {
                    return _c(
                      "option",
                      { key: bank.note, domProps: { value: bank.note } },
                      [_vm._v(" " + _vm._s(bank.codeName) + " ")]
                    )
                  }),
                ],
                2
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.info.bankAccount,
                    expression: "info.bankAccount",
                    modifiers: { trim: true },
                  },
                ],
                ref: "bankAccount",
                staticStyle: { width: "174px" },
                attrs: { type: "text" },
                domProps: { value: _vm.info.bankAccount },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.info,
                      "bankAccount",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c(
                "button",
                {
                  staticClass: "btn blue-line",
                  attrs: { type: "button" },
                  on: { click: _vm.handleClickValidBankAccount },
                },
                [_vm._v(" 계좌실명인증 ")]
              ),
              _c(
                "span",
                { class: _vm.isValidBankAccount ? "txt-blue" : "txt-red" },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.validateBankAccountText !== "",
                        expression: "validateBankAccountText !== ''",
                      },
                    ],
                    class: _vm.isValidBankAccount
                      ? "icon-alert-check"
                      : "icon-alert2",
                  }),
                  _vm._v(" " + _vm._s(_vm.validateBankAccountText) + " "),
                ]
              ),
              _vm._m(25),
            ]),
          ]),
        ]),
      ]),
      _c("h2", { staticClass: "title" }, [_vm._v("첨부서류")]),
      _c("div", { staticClass: "boxing" }, [
        _c("div", { staticClass: "form-area" }, [
          _c("dl", [
            _vm._m(26),
            _c("dd", { staticClass: "pt10" }, [
              _c(
                "button",
                {
                  staticClass: "btn blue-line",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickBizFile(true)
                    },
                  },
                },
                [_vm._v(" 파일첨부 ")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                ref: "businessFile",
                attrs: { accept: "image/jpeg, image/png, .pdf", type: "file" },
                on: {
                  change: function ($event) {
                    return _vm.handleFileUpload(true)
                  },
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.info.businessFile,
                      expression: "info.businessFile",
                    },
                  ],
                  staticClass: "file-link",
                },
                [
                  _vm._v(" " + _vm._s(_vm.info.businessFile?.name ?? "") + " "),
                  _c("button", {
                    staticClass: "file-del",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.handleClickFileInit(true)
                      },
                    },
                  }),
                ]
              ),
              _vm._m(27),
            ]),
          ]),
          _c("dl", [
            _vm._m(28),
            _c("dd", [
              _c(
                "button",
                {
                  staticClass: "btn blue-line",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickBizFile(false)
                    },
                  },
                },
                [_vm._v(" 파일첨부 ")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                ref: "copyPassbookFile",
                attrs: { accept: "image/jpeg, image/png, .pdf", type: "file" },
                on: {
                  change: function ($event) {
                    return _vm.handleFileUpload(false)
                  },
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.info.copyPassbookFile,
                      expression: "info.copyPassbookFile",
                    },
                  ],
                  staticClass: "file-link",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.info.copyPassbookFile?.name ?? "") + " "
                  ),
                  _c("button", {
                    staticClass: "file-del",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.handleClickFileInit(false)
                      },
                    },
                  }),
                ]
              ),
              _vm._m(29),
            ]),
          ]),
          _c("dl", [
            _vm._m(30),
            _c("dd", [
              _c(
                "button",
                {
                  staticClass: "btn blue-line",
                  attrs: { type: "button" },
                  on: { click: _vm.handleClickMailOrderSalesFile },
                },
                [_vm._v(" 파일첨부 ")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                ref: "mailOrderSalesFile",
                attrs: { accept: "image/jpeg, image/png, .pdf", type: "file" },
                on: { change: _vm.handleUploadMailOrderSalesFile },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.info.mailOrderSalesFile,
                      expression: "info.mailOrderSalesFile",
                    },
                  ],
                  staticClass: "file-link",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.info.mailOrderSalesFile?.name ?? "") + " "
                  ),
                  _c("button", {
                    staticClass: "file-del",
                    attrs: { type: "button" },
                    on: { click: _vm.handleClickFileInitMailOrderSales },
                  }),
                ]
              ),
              _vm._m(31),
            ]),
          ]),
        ]),
      ]),
      _c("h2", { staticClass: "title" }, [_vm._v("약관 동의")]),
      _vm._m(32),
      _c("div", { staticClass: "flex-center" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.isCheckConsent,
              expression: "isCheckConsent",
            },
          ],
          attrs: { type: "checkbox", id: "consent" },
          domProps: {
            checked: Array.isArray(_vm.isCheckConsent)
              ? _vm._i(_vm.isCheckConsent, null) > -1
              : _vm.isCheckConsent,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.isCheckConsent,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.isCheckConsent = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.isCheckConsent = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.isCheckConsent = $$c
              }
            },
          },
        }),
        _c("label", { attrs: { for: "consent" } }, [
          _vm._v("개인정보 수집 및 이용에 동의합니다."),
        ]),
      ]),
      _c("div", { staticClass: "btn-group" }, [
        _c(
          "button",
          {
            staticClass: "btn big blue",
            attrs: { type: "button" },
            on: { click: _vm.signupPartner },
          },
          [_vm._v("신청")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "visual" }, [
      _c("h1", [_vm._v("입 / 점 / 신 / 청")]),
      _c("p", [_vm._v("성공적인 비즈니스 파트너! D.PLOT과 함께하세요!")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flow clearfix" }, [
      _c("ul", [
        _c("li", [
          _c("span", [_vm._v("Step 1.")]),
          _c("p", [_vm._v("입점절차 및 사업자 인증")]),
        ]),
        _c("li", { staticClass: "on" }, [
          _c("span", [_vm._v("Step 2.")]),
          _c("p", [_vm._v("정보입력")]),
        ]),
        _c("li", [
          _c("span", [_vm._v("Step 3.")]),
          _c("p", [_vm._v("신청완료")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_c("i", { staticClass: "essential" }), _vm._v(" 아이디 ")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        " 파트너오피스 계정 담당자(대표자 또는 운영담당자)을 입력해주세요. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dl", [
      _c("dt", [_c("i", { staticClass: "essential" }), _vm._v(" 비밀번호 ")]),
      _c("dd", [
        _c("span", { staticClass: "txt-red" }, [
          _vm._v(
            " 입점승인 시 대표자 휴대폰 번호로 초기 비밀번호가 발송됩니다. (최초 로그인 시 비밀번호 변경 필수) "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("i", { staticClass: "essential" }),
      _vm._v(" 담당자명 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("i", { staticClass: "essential" }),
      _vm._v(" 담당자 휴대폰 번호 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(" 입점승인 시 해당 번호로 문자가 발송됩니다. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("i", { staticClass: "essential" }),
      _vm._v(" 사업자등록번호 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_c("i", { staticClass: "essential" }), _vm._v(" 상호 ")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_c("i", { staticClass: "essential" }), _vm._v(" 업체명 ")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("i", { staticClass: "essential" }),
      _vm._v(" 대표자명 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_c("i", { staticClass: "essential" }), _vm._v(" 업태 ")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_c("i", { staticClass: "essential" }), _vm._v(" 종목 ")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_c("i", { staticClass: "essential" }), _vm._v(" 주소 ")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("i", { staticClass: "essential" }),
      _vm._v(" 판매상품유형 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("i", { staticClass: "essential" }),
      _vm._v(" 통신판매신고번호 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(" 통신판매업 신고 후 가입을 진행해주시기 바랍니다. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("i", { staticClass: "essential" }),
      _vm._v(" 고객문의 연락처 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        " 고객문의 연락처 및 이메일은 주문정보에 노출되니 정확한 정보를 입력해주시기 바랍니다. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("i", { staticClass: "essential" }),
      _vm._v(" 대표 이메일 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        " 세금계산서 발행 시 위 메일주소로 발행됩니다. 담당자 변경 필요 시 고객센터(1666-3642)로 문의해 주세요. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_c("i", { staticClass: "essential" }), _vm._v(" 예금주 ")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("i", { staticClass: "essential" }),
      _vm._v(" 계좌구분 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("i", { staticClass: "essential" }),
      _vm._v(" 은행/계좌번호 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        " 파트너사 정산은 전월 1일부터 말일까지의 구매확정건을 기준으로 영업일 기준 익월 말일에 정산됩니다. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("i", { staticClass: "essential" }),
      _vm._v(" 사업자등록증사본 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "txt-orange multi-line" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(" 1년 이내에 발급 받은 사업자등록증을 첨부해 주시기 바랍니다. "),
      _c("br"),
      _vm._v(
        "         jpg, jpeg, png파일 또는 PDF 파일만 첨부 가능하며, 파일 최대 크기는 10MB를 초과 할 수 없습니다. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("i", { staticClass: "essential" }),
      _vm._v(" 통장사본 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        " jpg, jpeg, png파일 또는 PDF 파일만 첨부 가능하며, 파일 최대 크기는 10MB를 초과 할 수 없습니다. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("i", { staticClass: "essential" }),
      _vm._v(" 통신판매업신고증 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(
        " jpg, jpeg, png파일 또는 PDF 파일만 첨부 가능하며, 파일 최대 크기는 10MB를 초과 할 수 없습니다. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "boxing" }, [
      _c("h4", [_vm._v("[필수] 개인정보 수집 동의")]),
      _c("ul", [
        _c("li", [
          _vm._v(
            " - 수집 항목 : 아이디, 비밀번호, 대표자 명, 담당자 휴대폰, 정산정보(예금주, 은행명, 계좌번호), 통장사본 "
          ),
        ]),
        _c("li", [
          _vm._v(
            "- 이용 목적 : 판매자 입점 및 관리, 사용자 식별 및 로그인 시 2차 인증 목적"
          ),
        ]),
        _c("li", [
          _c("strong", [_vm._v("- 보유 및 이용 기간 : 계약 종료 시 파기")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }