var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "600px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("답글 관리")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close", "T")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.content,
                  expression: "content",
                },
              ],
              attrs: { placeholder: "내용을 입력하세요." },
              domProps: { value: _vm.content },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.content = $event.target.value
                },
              },
            }),
            _c("div", { staticClass: "btn-group mt10" }, [
              _c(
                "button",
                {
                  staticClass: "btn blue",
                  attrs: { type: "button" },
                  on: { click: _vm.goSave },
                },
                [_vm._v("저장")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close", "T")
                    },
                  },
                },
                [_vm._v("취소")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }