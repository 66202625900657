var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "60%" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("상품추가")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide("commonModal")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "winner_list" }, [
              _c(
                "ul",
                [
                  _vm._m(0),
                  _vm._l(_vm.winnerlist, function (winner, idx) {
                    return _c("li", { key: idx }, [
                      _c("span", [_vm._v(_vm._s(winner.name))]),
                      _c("span", [_vm._v(_vm._s(winner.userid))]),
                      _c("span", [_vm._v(_vm._s(winner.phone))]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "list_header" }, [
      _c("span", [_vm._v("성명")]),
      _c("span", [_vm._v("USER ID")]),
      _c("span", [_vm._v("휴대폰 번호 뒷자리")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }