<template>
  <div>
    <!-- 레프트영역 -->
    <div class="left-menu">
      <div class="inner">
        <div class="title">
          <i class="icons icon-calculate-t"></i>
          <span>정산관리</span>
        </div>
        <ul class="menu">
          <li v-for="menu in sideMenu" :key="menu.code">
            <router-link
              :to="menu.childmenulist.length > 0 ? '' : { name: menu.url }"
              :class="{ active: menu.isactive }"
              @click.native="onActive(menu)"
            >
              <span>{{ menu.name }}</span>
              <i
                class="icon-expand"
                :class="{ active: menu.childmenulist.length > 0 && menu.isactive }"
              ></i>
            </router-link>
            <ul class="sub-menu" :class="{ dpn: !menu.isactive }">
              <li v-for="childMenu in menu.childmenulist" :key="childMenu.code">
                <router-link
                  :to="{ name: childMenu.url }"
                  :class="{ active: childMenu.isactive }"
                  @click.native="onActive(childMenu)"
                >
                  {{ childMenu.name }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <!-- /레프트영역 -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'AdjustManageSide',
  data() {
    return {
      sideMenu: [],
      parentCode: 'I',
    };
  },
  mounted() {
    let param = { pageCd: this.parentCode, path: this.$route.name, isloading: false };
    this.$http
      .post('/admin/common/side-menu', param)
      .then((result) => {
        // TODO: 구 정산관리 화면 구분이 가능하도록 name 변경 (I-01-001) api 대응되면 삭제 예정
        const filteredSidemenu = result.data?.sidemenu?.map(({ code, childmenulist, ...rest }) => {
          if (code === 'I-01') {
            return {
              code,
              childmenulist: childmenulist?.filter(({ code }) => code !== 'I-01-001') ?? [],
              ...rest,
            };
          }

          return {
            code,
            childmenulist,
            ...rest,
          };
        });

        this.sideMenu = filteredSidemenu;
      })
      .catch((error) => {
        this.$util.debug(error);
      });
  },
  methods: {
    onActive: function (thisMenuObj) {
      // 하위 메뉴가 존재하는 경우
      if (thisMenuObj?.childmenulist != null && thisMenuObj?.childmenulist.length > 0) {
        if (thisMenuObj?.isactive) {
          thisMenuObj.isactive = false;
          return;
        }
        this.sideMenu.forEach((menu) => {
          menu.isactive = false;
        });
        thisMenuObj.isactive = true;
      }
      // 하위메뉴가 존재하지 않는 경우
      else {
        this.sideMenu.forEach((parentMenu) => {
          if (parentMenu.code === thisMenuObj?.uppercode) {
            parentMenu.isactive = true;
          } else {
            parentMenu.isactive = false;
          }
          parentMenu.childmenulist?.forEach((childMenu) => {
            childMenu.isactive = false;
          });
        });
        thisMenuObj.isactive = true;
      }
    },
    // goAdjustMain: function(){
    //   this.$router.push({name : 'admin.adjust.main'})
    // },
  },
  watch: {
    $route(to) {
      this.onActive(to.name);
    },
  },
};
</script>
