var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "400px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("일괄변경")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide("commonModal")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("p", [
              _vm._v("변경된 내용으로 상품정보를 일괄변경 하시겠습니까?"),
            ]),
            _c("div", { staticClass: "blue-box mg0" }, [
              _c(
                "ul",
                _vm._l(_vm.checkedItemList, function (item) {
                  return _c("li", { key: item.key }, [
                    _vm._v(_vm._s(item.name)),
                  ])
                }),
                0
              ),
            ]),
            _c("hr", { staticClass: "solid" }),
            _vm._m(0),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.confirm },
                },
                [_vm._v("확인")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("commonModal")
                    },
                  },
                },
                [_vm._v(" 취소 ")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "txt-orange" }, [
      _vm._v(" ※ 주의!"),
      _c("br"),
      _vm._v(" - 선택한 항목의 정보가 모두 동일한 내용으로 변경됩니다."),
      _c("br"),
      _vm._v(" - 일괄변경 이후에는 변경 전 정보로 되돌릴 수 없습니다. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }