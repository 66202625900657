var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-area", staticStyle: { height: "calc(90vh - 246px)" } },
    [
      _vm._m(0),
      _c("div", { staticClass: "boxing search-area" }, [
        _c("dl", [
          _c("dt", [_vm._v("조회기간")]),
          _c(
            "dd",
            [
              _c("common-date-picker", {
                attrs: { value: _vm.searchData.startDate },
                on: { change: _vm.onChangeStartDate },
              }),
              _c("span", [_vm._v("-")]),
              _c("common-date-picker", {
                attrs: { value: _vm.searchData.endDate },
                on: { change: _vm.onChangeEndDate },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "btn-group" }, [
        _c(
          "button",
          {
            staticClass: "btn big blue",
            attrs: { type: "button" },
            on: { click: _vm.onSearch },
          },
          [_vm._v("검색")]
        ),
      ]),
      _c("div", { staticClass: "caption-group mt10 clearfix" }, [
        _c("div", { staticClass: "total-group fl" }, [
          _c("span", { staticClass: "total" }, [
            _vm._v("전체 "),
            _c("strong", [_vm._v(_vm._s(_vm.totalCnt))]),
            _vm._v("건"),
          ]),
        ]),
        _c("div", { staticClass: "btn-group fr" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.pagingData.pageCount,
                  expression: "pagingData.pageCount",
                },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.pagingData,
                    "pageCount",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "20" } }, [_vm._v("20개씩 보기")]),
              _c("option", { attrs: { value: "50" } }, [_vm._v("50개씩 보기")]),
              _c("option", { attrs: { value: "100" } }, [
                _vm._v("100개씩 보기"),
              ]),
            ]
          ),
        ]),
      ]),
      _c(
        "table",
        {
          staticClass: "data-tb align-c",
          attrs: { cellpadding: "0", cellspacing: "0" },
        },
        [
          _c("caption", [_vm._v(" 리뷰 목록 ")]),
          _vm._m(1),
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("No")]),
              _c("th", [_vm._v("베스트")]),
              _c("th", [_vm._v("미노출")]),
              _c("th", [_vm._v("상품명")]),
              _c("th", [_vm._v("리뷰내용")]),
              _c("th", [_vm._v("아이디")]),
              _c("th", [_vm._v("이름")]),
              _c("th", [
                _vm._v(" 총상품평점 "),
                _c("button", {
                  staticClass: "sort",
                  class: [
                    { up: _vm.sortData.totpoint === "totpoint_asc" },
                    { down: _vm.sortData.totpoint === "totpoint_desc" },
                  ],
                  attrs: { type: "button", value: _vm.sortData.totpoint },
                  on: {
                    click: function ($event) {
                      return _vm.sortToggle(_vm.sortData.totpoint)
                    },
                  },
                }),
              ]),
              _c("th", [
                _vm._v(" 좋아요 "),
                _c("button", {
                  staticClass: "sort",
                  class: [
                    { up: _vm.sortData.goodcnt === "goodcnt_asc" },
                    { down: _vm.sortData.goodcnt === "goodcnt_desc" },
                  ],
                  attrs: { type: "button", value: _vm.sortData.goodcnt },
                  on: {
                    click: function ($event) {
                      return _vm.sortToggle(_vm.sortData.goodcnt)
                    },
                  },
                }),
              ]),
              _c("th", [
                _vm._v(" 포토&동영상 "),
                _c("button", {
                  staticClass: "sort",
                  class: [
                    { up: _vm.sortData.filecnt === "filecnt_asc" },
                    { down: _vm.sortData.filecnt === "filecnt_desc" },
                  ],
                  attrs: { type: "button", value: _vm.sortData.filecnt },
                  on: {
                    click: function ($event) {
                      return _vm.sortToggle(_vm.sortData.filecnt)
                    },
                  },
                }),
              ]),
              _c("th", [_vm._v("신고")]),
              _c("th", [
                _vm._v(" 시작일자 "),
                _c("button", {
                  staticClass: "sort",
                  class: [
                    { up: _vm.sortData.beststtime === "beststtime_asc" },
                    { down: _vm.sortData.beststtime === "beststtime_desc" },
                  ],
                  attrs: { type: "button", value: _vm.sortData.beststtime },
                  on: {
                    click: function ($event) {
                      return _vm.sortToggle(_vm.sortData.beststtime)
                    },
                  },
                }),
              ]),
              _c("th", [
                _vm._v(" 종료일자 "),
                _c("button", {
                  staticClass: "sort",
                  class: [
                    { up: _vm.sortData.bestedtime === "bestedtime_asc" },
                    { down: _vm.sortData.bestedtime === "bestedtime_desc" },
                  ],
                  attrs: { type: "button", value: _vm.sortData.bestedtime },
                  on: {
                    click: function ($event) {
                      return _vm.sortToggle(_vm.sortData.bestedtime)
                    },
                  },
                }),
              ]),
              _c("th", [
                _vm._v(" 등록일자 "),
                _c("button", {
                  staticClass: "sort",
                  class: [
                    { up: _vm.sortData.regdate === "regdate_asc" },
                    { down: _vm.sortData.regdate === "regdate_desc" },
                  ],
                  attrs: { type: "button", value: _vm.sortData.regdate },
                  on: {
                    click: function ($event) {
                      return _vm.sortToggle(_vm.sortData.regdate)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          this.list.length > 0
            ? _c(
                "tbody",
                _vm._l(this.list, function (row, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [_vm._v(_vm._s(_vm.loopNumberForPaging(index)))]),
                    _c("td", [_vm._v(_vm._s(row.isbest === "T" ? "●" : "-"))]),
                    _c("td", [
                      _vm._v(_vm._s(row.isdisplay === "T" ? "-" : "●")),
                    ]),
                    _c("td", { staticClass: "left" }, [
                      _vm._v(_vm._s(row.goodsname)),
                    ]),
                    _c("td", { staticClass: "left" }, [
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          on: {
                            click: function ($event) {
                              return _vm.goDetail(row.reviewidx)
                            },
                          },
                        },
                        [_vm._v(_vm._s(row.content))]
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(row.reguserid))]),
                    _c("td", [_vm._v(_vm._s(row.regusername))]),
                    _c("td", [_vm._v(_vm._s(row.totpoint))]),
                    _c("td", [_vm._v(_vm._s(row.goodcnt))]),
                    _c("td", [_vm._v(_vm._s(row.filecnt))]),
                    _c("td", [_vm._v(_vm._s(row.noticnt))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.$util.isNull(row.beststtime)
                            ? "-"
                            : row.beststtime
                        )
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.$util.isNull(row.bestedtime)
                            ? "-"
                            : row.bestedtime
                        )
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(row.regdate))]),
                  ])
                }),
                0
              )
            : _c("tbody", [_vm._m(2)]),
        ]
      ),
      _c(
        "div",
        { staticClass: "bottom-group" },
        [
          _c("common-page-navigator", {
            attrs: { pagingData: _vm.pagingData },
            on: { setPagingData: _vm.setPagingData },
          }),
        ],
        1
      ),
      _vm.showDetail
        ? _c("ReviewDetail", {
            attrs: { reviewidx: _vm.idx },
            on: { closeDetail: _vm.closeDetail },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "bar-title small fl" }, [
        _vm._v("리뷰작성내역"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "14" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }