var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1400px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("프로모션 등록")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "bar-title" }, [_vm._v("기본정보")]),
            _c("div", { staticClass: "boxing" }, [
              _c("div", { staticClass: "form-area" }, [
                _c("dl", [
                  _c("dt", [_vm._v("노출상태")]),
                  _c("dd", [
                    _c("div", { staticClass: "radio_wrap wide" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.boardInfo.isdisplay,
                            expression: "boardInfo.isdisplay",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "group00",
                          id: "group01",
                          value: "T",
                          checked: "",
                        },
                        domProps: {
                          checked: _vm._q(_vm.boardInfo.isdisplay, "T"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.boardInfo, "isdisplay", "T")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "group01" } }, [
                        _vm._v("노출"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.boardInfo.isdisplay,
                            expression: "boardInfo.isdisplay",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "group00",
                          id: "group02",
                          value: "F",
                        },
                        domProps: {
                          checked: _vm._q(_vm.boardInfo.isdisplay, "F"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.boardInfo, "isdisplay", "F")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "group02" } }, [
                        _vm._v("미노출"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("dl", [
                  _vm._m(0),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.boardInfo.subject,
                          expression: "boardInfo.subject",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", placeholder: "제목" },
                      domProps: { value: _vm.boardInfo.subject },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.boardInfo,
                            "subject",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("설명")]),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.boardInfo.evdesc,
                          expression: "boardInfo.evdesc",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", placeholder: "프로모션 설명" },
                      domProps: { value: _vm.boardInfo.evdesc },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.boardInfo, "evdesc", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "bar-title" }, [_vm._v("조건설정")]),
            _c("div", { staticClass: "form-area" }, [
              _c(
                "table",
                {
                  staticClass: "gray-tb",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(1),
                  _c(
                    "tbody",
                    [
                      _c("tr", [
                        _vm._m(2),
                        _c(
                          "td",
                          [
                            _c("CommonDatePickerFromTo", {
                              attrs: {
                                fromYYYYMMDD: _vm.eventTimeInfo.startYYYYMMDD,
                                fromHH: _vm.eventTimeInfo.startHH,
                                fromMM: _vm.eventTimeInfo.startMM,
                                toYYYYMMDD: _vm.eventTimeInfo.toYYYYMMDD,
                                toHH: _vm.eventTimeInfo.toHH,
                                toMM: _vm.eventTimeInfo.toMM,
                              },
                              on: { getDate: _vm.getEventTimeDate },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("발표일")]),
                        _c(
                          "td",
                          [
                            _c("CommonDatePickerFromTo", {
                              attrs: {
                                toYYYYMMDD: _vm.pubTimeInfo.toYYYYMMDD,
                                toHH: _vm.pubTimeInfo.toHH,
                                toMM: _vm.pubTimeInfo.toMM,
                                "use-from": false,
                                "use-to": true,
                              },
                              on: { getDate: _vm.getEventPubTimeDate },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _vm._m(3),
                        _c("td", [
                          _c("div", { staticClass: "radio_wrap" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.boardInfo.isevent,
                                  expression: "boardInfo.isevent",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                name: "group06",
                                value: "T",
                                id: "group61",
                                checked: "",
                              },
                              domProps: {
                                checked: _vm._q(_vm.boardInfo.isevent, "T"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.boardInfo, "isevent", "T")
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "group61" } }, [
                              _vm._v("이벤트"),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.boardInfo.isevent,
                                  expression: "boardInfo.isevent",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                name: "group06",
                                value: "F",
                                id: "group62",
                              },
                              domProps: {
                                checked: _vm._q(_vm.boardInfo.isevent, "F"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.boardInfo, "isevent", "F")
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "group62" } }, [
                              _vm._v("기획전"),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm.boardInfo.isevent == "T"
                        ? _c("tr", [
                            _vm._m(4),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "radio_wrap" },
                                _vm._l(
                                  _vm.commonCode.eventtype,
                                  function (row, i) {
                                    return _c("div", { key: i }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.boardInfo.eventtype,
                                            expression: "boardInfo.eventtype",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          id: "group5" + i,
                                        },
                                        domProps: {
                                          value: row.cmcode,
                                          checked: _vm._q(
                                            _vm.boardInfo.eventtype,
                                            row.cmcode
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.boardInfo,
                                              "eventtype",
                                              row.cmcode
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: "group5" + i } },
                                        [_vm._v(_vm._s(row.codename))]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c("tr", [
                        _vm._m(5),
                        _c(
                          "td",
                          [
                            _c("div", { staticClass: "check-wrap" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.checkObj.isallmuappch,
                                    expression: "checkObj.isallmuappch",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "all1",
                                  "true-value": "T",
                                  "false-value": "F",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.checkObj.isallmuappch
                                  )
                                    ? _vm._i(_vm.checkObj.isallmuappch, null) >
                                      -1
                                    : _vm._q(_vm.checkObj.isallmuappch, "T"),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.checkObj.isallmuappch,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? "T" : "F"
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.checkObj,
                                              "isallmuappch",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.checkObj,
                                              "isallmuappch",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.checkObj,
                                          "isallmuappch",
                                          $$c
                                        )
                                      }
                                    },
                                    function ($event) {
                                      return _vm.checkAllMuAppch(
                                        $event.target.checked
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c("label", { attrs: { for: "all1" } }, [
                                _vm._v("전체"),
                              ]),
                            ]),
                            _vm._l(_vm.pageCode.muappchtype, function (row, i) {
                              return _c(
                                "div",
                                { key: i, staticClass: "check-wrap" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.checkObj.muappchTypeChecked,
                                        expression:
                                          "checkObj.muappchTypeChecked",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "group1" + i,
                                    },
                                    domProps: {
                                      value: row.cmcode,
                                      checked: Array.isArray(
                                        _vm.checkObj.muappchTypeChecked
                                      )
                                        ? _vm._i(
                                            _vm.checkObj.muappchTypeChecked,
                                            row.cmcode
                                          ) > -1
                                        : _vm.checkObj.muappchTypeChecked,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.checkObj.muappchTypeChecked,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = row.cmcode,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.checkObj,
                                                "muappchTypeChecked",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.checkObj,
                                                "muappchTypeChecked",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.checkObj,
                                            "muappchTypeChecked",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "group1" + i } },
                                    [_vm._v(_vm._s(row.codename))]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                      _c("tr", [
                        _vm._m(6),
                        _c(
                          "td",
                          [
                            _c("div", { staticClass: "check-wrap" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.checkObj.isallmumember,
                                    expression: "checkObj.isallmumember",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "all2",
                                  "true-value": "T",
                                  "false-value": "F",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.checkObj.isallmumember
                                  )
                                    ? _vm._i(_vm.checkObj.isallmumember, null) >
                                      -1
                                    : _vm._q(_vm.checkObj.isallmumember, "T"),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.checkObj.isallmumember,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? "T" : "F"
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.checkObj,
                                              "isallmumember",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.checkObj,
                                              "isallmumember",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.checkObj,
                                          "isallmumember",
                                          $$c
                                        )
                                      }
                                    },
                                    function ($event) {
                                      return _vm.checkAllMuMemer(
                                        $event.target.checked
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c("label", { attrs: { for: "all2" } }, [
                                _vm._v("전체"),
                              ]),
                            ]),
                            _vm._l(
                              _vm.pageCode.mumembertype,
                              function (row, i) {
                                return _c(
                                  "div",
                                  { key: i, staticClass: "check-wrap" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.checkObj.mumemberTypeChecked,
                                          expression:
                                            "checkObj.mumemberTypeChecked",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: "group2" + i,
                                      },
                                      domProps: {
                                        value: row.cmcode,
                                        checked: Array.isArray(
                                          _vm.checkObj.mumemberTypeChecked
                                        )
                                          ? _vm._i(
                                              _vm.checkObj.mumemberTypeChecked,
                                              row.cmcode
                                            ) > -1
                                          : _vm.checkObj.mumemberTypeChecked,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a =
                                              _vm.checkObj.mumemberTypeChecked,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = row.cmcode,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.checkObj,
                                                  "mumemberTypeChecked",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.checkObj,
                                                  "mumemberTypeChecked",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.checkObj,
                                              "mumemberTypeChecked",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      { attrs: { for: "group2" + i } },
                                      [_vm._v(_vm._s(row.codename))]
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                      _c("tr", [
                        _vm._m(7),
                        _c(
                          "td",
                          [
                            _c("div", { staticClass: "check-wrap" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.checkObj.isallmumemlv,
                                    expression: "checkObj.isallmumemlv",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "all3",
                                  "true-value": "T",
                                  "false-value": "F",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.checkObj.isallmumemlv
                                  )
                                    ? _vm._i(_vm.checkObj.isallmumemlv, null) >
                                      -1
                                    : _vm._q(_vm.checkObj.isallmumemlv, "T"),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.checkObj.isallmumemlv,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? "T" : "F"
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.checkObj,
                                              "isallmumemlv",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.checkObj,
                                              "isallmumemlv",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.checkObj,
                                          "isallmumemlv",
                                          $$c
                                        )
                                      }
                                    },
                                    function ($event) {
                                      return _vm.checkAllMuMemLv(
                                        $event.target.checked
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c("label", { attrs: { for: "all3" } }, [
                                _vm._v("전체"),
                              ]),
                            ]),
                            _vm._l(_vm.pageCode.mumemlvtype, function (row, i) {
                              return _c(
                                "div",
                                { key: i, staticClass: "check-wrap" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.checkObj.mumemlvTypeChecked,
                                        expression:
                                          "checkObj.mumemlvTypeChecked",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "group3" + i,
                                    },
                                    domProps: {
                                      value: row.cmcode,
                                      checked: Array.isArray(
                                        _vm.checkObj.mumemlvTypeChecked
                                      )
                                        ? _vm._i(
                                            _vm.checkObj.mumemlvTypeChecked,
                                            row.cmcode
                                          ) > -1
                                        : _vm.checkObj.mumemlvTypeChecked,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.checkObj.mumemlvTypeChecked,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = row.cmcode,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.checkObj,
                                                "mumemlvTypeChecked",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.checkObj,
                                                "mumemlvTypeChecked",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.checkObj,
                                            "mumemlvTypeChecked",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "group3" + i } },
                                    [_vm._v(_vm._s(row.codename))]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                      _c("tr", [
                        _vm._m(8),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "radio_wrap wide3" },
                            _vm._l(_vm.pageCode.disptype, function (row, i) {
                              return _c("div", { key: i }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.boardInfo.disptype,
                                      expression: "boardInfo.disptype",
                                    },
                                  ],
                                  attrs: { type: "radio", id: "group4" + i },
                                  domProps: {
                                    value: row.cmcode,
                                    checked: _vm._q(
                                      _vm.boardInfo.disptype,
                                      row.cmcode
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.boardInfo,
                                        "disptype",
                                        row.cmcode
                                      )
                                    },
                                  },
                                }),
                                _c("label", { attrs: { for: "group4" + i } }, [
                                  _vm._v(_vm._s(row.codename)),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("옵션")]),
                        _c("td", [
                          _c("div", { staticClass: "dpb" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.boardInfo.iscomment,
                                  expression: "boardInfo.iscomment",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "Rgroup61",
                                "true-value": "T",
                                "false-value": "F",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.boardInfo.iscomment)
                                  ? _vm._i(_vm.boardInfo.iscomment, null) > -1
                                  : _vm._q(_vm.boardInfo.iscomment, "T"),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.boardInfo.iscomment,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "T" : "F"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.boardInfo,
                                          "iscomment",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.boardInfo,
                                          "iscomment",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.boardInfo, "iscomment", $$c)
                                  }
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "Rgroup61" } }, [
                              _vm._v("댓글"),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(9),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass: "img-with-text",
                              staticStyle: { width: "202px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "img-thumb",
                                  class: {
                                    "no-image": _vm.$util.isNull(
                                      _vm.files["pcimgfile"]
                                    ),
                                  },
                                  staticStyle: {
                                    width: "305px",
                                    height: "150px",
                                  },
                                },
                                [
                                  !_vm.$util.isNull(_vm.files["pcimgfile"])
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "305px",
                                          height: "150px",
                                        },
                                        attrs: {
                                          src: _vm.imgPreview["pcimgfile"],
                                          alt: "대표이미지(PC)",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _vm.$util.isNull(_vm.files["pcimgfile"])
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line mt10",
                                      staticStyle: { width: "100%" },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.fileAttach("pcimgfile")
                                        },
                                      },
                                    },
                                    [_vm._v(" 파일 올리기 ")]
                                  )
                                : _vm._e(),
                              _c("input", {
                                ref: "pcimgfile",
                                attrs: {
                                  type: "file",
                                  accept: "image/jpeg, image/png",
                                  hidden: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleFileUpload("pcimgfile")
                                  },
                                },
                              }),
                              !_vm.$util.isNull(_vm.files["pcimgfile"])
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line mt10",
                                      staticStyle: { width: "calc(50% - 3px)" },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.fileAttach("pcimgfile")
                                        },
                                      },
                                    },
                                    [_vm._v(" 변경 ")]
                                  )
                                : _vm._e(),
                              !_vm.$util.isNull(_vm.files["pcimgfile"])
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn red-line mt10",
                                      staticStyle: { width: "calc(50% - 4px)" },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeFile("pcimgfile")
                                        },
                                      },
                                    },
                                    [_vm._v(" 삭제 ")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._m(10),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(11),
                        _c("td", [
                          _c("div", { staticClass: "mb10" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.copyimgcheck,
                                  expression: "copyimgcheck",
                                },
                              ],
                              attrs: { type: "checkbox", id: "copy-img" },
                              domProps: {
                                checked: Array.isArray(_vm.copyimgcheck)
                                  ? _vm._i(_vm.copyimgcheck, null) > -1
                                  : _vm.copyimgcheck,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.copyimgcheck,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.copyimgcheck = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.copyimgcheck = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.copyimgcheck = $$c
                                    }
                                  },
                                  _vm.setSameAsPcepreImg,
                                ],
                              },
                            }),
                            _c("label", { attrs: { for: "copy-img" } }, [
                              _vm._v("PC 대표 이미지를 복사"),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "img-with-text",
                              staticStyle: { width: "202px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "img-thumb",
                                  class: {
                                    "no-image": _vm.$util.isNull(
                                      _vm.files["mobileimgfile"]
                                    ),
                                  },
                                  staticStyle: {
                                    width: "360px",
                                    height: "200px",
                                  },
                                },
                                [
                                  !_vm.$util.isNull(_vm.files["mobileimgfile"])
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "360px",
                                          height: "200px",
                                        },
                                        attrs: {
                                          src: _vm.imgPreview["mobileimgfile"],
                                          alt: "대표이미지(모바일)",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _vm.$util.isNull(_vm.files["mobileimgfile"])
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line mt10",
                                      staticStyle: { width: "100%" },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.fileAttach("mobileimgfile")
                                        },
                                      },
                                    },
                                    [_vm._v(" 파일 올리기 ")]
                                  )
                                : _vm._e(),
                              _c("input", {
                                ref: "mobileimgfile",
                                attrs: {
                                  type: "file",
                                  accept: "image/jpeg, image/png",
                                  hidden: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleFileUpload("mobileimgfile")
                                  },
                                },
                              }),
                              !_vm.$util.isNull(_vm.files["mobileimgfile"])
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line mt10",
                                      staticStyle: { width: "calc(50% - 3px)" },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.fileAttach("mobileimgfile")
                                        },
                                      },
                                    },
                                    [_vm._v(" 변경 ")]
                                  )
                                : _vm._e(),
                              !_vm.$util.isNull(_vm.files["mobileimgfile"])
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn red-line mt10",
                                      staticStyle: { width: "calc(50% - 4px)" },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeFile("mobileimgfile")
                                        },
                                      },
                                    },
                                    [_vm._v(" 삭제 ")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._m(12),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(13),
                        _c("td", [
                          _c(
                            "div",
                            [_c("CommonEditor", { ref: "pcEditor" })],
                            1
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _vm._m(14),
                        _c("td", [
                          _c("div", { staticClass: "mb10" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn blue-line",
                                attrs: { type: "button" },
                                on: { click: _vm.copyByContent },
                              },
                              [_vm._v(" PC 내용을 복사 ")]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "mt10" },
                            [_c("CommonEditor", { ref: "mobileEditor" })],
                            1
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("브랜드샵")]),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "unlinkfile-wrap clearfix" },
                            [
                              _vm.$util.isNull(_vm.boardInfo.brandidx)
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line",
                                      attrs: { type: "button" },
                                      on: { click: _vm.openSearchBrandPopup },
                                    },
                                    [_vm._v(" 브랜드추가 ")]
                                  )
                                : _vm._e(),
                              !_vm.$util.isNull(_vm.boardInfo.brandidx)
                                ? _c("div", { staticClass: "dpib" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "[" +
                                            _vm.brandInfo.brcode +
                                            "] " +
                                            _vm.brandInfo.brandname
                                        ) +
                                        " "
                                    ),
                                    _c("button", {
                                      staticClass: "file-del",
                                      attrs: { type: "button" },
                                      on: { click: _vm.removeBrand },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("상품 섹션 사용여부")]),
                        _c("td", [
                          _c("div", { staticClass: "dpb" }, [
                            _c("div", { staticClass: "radio_wrap" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.boardInfo.issectionvisible,
                                    expression: "boardInfo.issectionvisible",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "issectionvisible",
                                  value: "T",
                                  id: "issectionvisibleTrue",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.boardInfo.issectionvisible,
                                    "T"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.boardInfo,
                                      "issectionvisible",
                                      "T"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: "issectionvisibleTrue" } },
                                [_vm._v("사용")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.boardInfo.issectionvisible,
                                    expression: "boardInfo.issectionvisible",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "issectionvisible",
                                  value: "F",
                                  id: "issectionvisibleFalse",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.boardInfo.issectionvisible,
                                    "F"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.boardInfo,
                                      "issectionvisible",
                                      "F"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: "issectionvisibleFalse" } },
                                [_vm._v("미사용")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm.boardInfo.issectionvisible === "T"
                        ? _c("fragment", [
                            _c("tr", [
                              _c("th", [_vm._v("섹션 이름 목록")]),
                              _c("td", [
                                _c("div", { staticClass: "dpb" }, [
                                  _c("div", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.boardInfo.sectionnamelist,
                                          expression:
                                            "boardInfo.sectionnamelist",
                                        },
                                      ],
                                      staticStyle: { width: "100%" },
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.boardInfo.sectionnamelist,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.boardInfo,
                                            "sectionnamelist",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("섹션 별 상품 코드 목록")]),
                              _c("td", [
                                _c("div", { staticClass: "dpb" }, [
                                  _c("div", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.boardInfo.sectiongoodscodelist,
                                          expression:
                                            "boardInfo.sectiongoodscodelist",
                                        },
                                      ],
                                      staticStyle: { width: "100%" },
                                      attrs: { type: "text" },
                                      domProps: {
                                        value:
                                          _vm.boardInfo.sectiongoodscodelist,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.boardInfo,
                                            "sectiongoodscodelist",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _c("tr", [
                        _c("th", [_vm._v("연관상품(선택)")]),
                        _c("td", [
                          _c("div", { staticClass: "caption-group clearfix" }, [
                            _vm._m(15),
                            _c("div", { staticClass: "btn-group fr" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn blue-line",
                                  attrs: { type: "button" },
                                  on: { click: _vm.onGoodsCodeCopy },
                                },
                                [_vm._v(" 상품 코드 추출 ")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn blue-line",
                                  attrs: { type: "button" },
                                  on: { click: _vm.openGoodsAdditionPopup },
                                },
                                [_vm._v(" 상품추가 ")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn red-line",
                                  attrs: { type: "button" },
                                  on: { click: _vm.removeGoodsAddition },
                                },
                                [_vm._v(" 삭제 ")]
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "scroll-y",
                              staticStyle: {
                                width: "100%",
                                "max-height": "350px",
                                "margin-bottom": "0",
                              },
                            },
                            [
                              _c(
                                "table",
                                {
                                  staticClass: "data-tb align-c",
                                  attrs: { cellpadding: "0", cellspacing: "0" },
                                },
                                [
                                  _vm._m(16),
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.isallchk,
                                              expression: "isallchk",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: "chkall",
                                          },
                                          domProps: {
                                            checked: Array.isArray(_vm.isallchk)
                                              ? _vm._i(_vm.isallchk, null) > -1
                                              : _vm.isallchk,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkAll(
                                                $event.target.checked
                                              )
                                            },
                                            change: function ($event) {
                                              var $$a = _vm.isallchk,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.isallchk = $$a.concat([
                                                      $$v,
                                                    ]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.isallchk = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.isallchk = $$c
                                              }
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("th", [_vm._v("No")]),
                                      _c("th", [_vm._v("판매구분")]),
                                      _c("th", [_vm._v("파트너사명")]),
                                      _c("th", [_vm._v("상품코드")]),
                                      _c("th", { attrs: { colspan: "2" } }, [
                                        _vm._v("상품명"),
                                      ]),
                                      _c("th", [_vm._v("판매가")]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.productList, function (row, i) {
                                      return _c("tr", { key: i }, [
                                        _c("td", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.moveData.targetIdx,
                                                expression:
                                                  "moveData.targetIdx",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              id: "chk01",
                                            },
                                            domProps: {
                                              value: i,
                                              checked: Array.isArray(
                                                _vm.moveData.targetIdx
                                              )
                                                ? _vm._i(
                                                    _vm.moveData.targetIdx,
                                                    i
                                                  ) > -1
                                                : _vm.moveData.targetIdx,
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a =
                                                      _vm.moveData.targetIdx,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = i,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.moveData,
                                                          "targetIdx",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.moveData,
                                                          "targetIdx",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.moveData,
                                                      "targetIdx",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                _vm.onCheckProduct,
                                              ],
                                            },
                                          }),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$util.lpad(i + 1, 2, "0")
                                            )
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(row.ispbgoodsname)),
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(row.dealername)),
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(row.goodscode)),
                                        ]),
                                        _c("td", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "img-thumb size60",
                                              class: {
                                                "no-image": _vm.$util.isNull(
                                                  row.fullpath
                                                ),
                                              },
                                            },
                                            [
                                              !_vm.$util.isNull(row.fullpath)
                                                ? _c("img", {
                                                    attrs: {
                                                      src: row.fullpath,
                                                      alt: "사진",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "left no-left" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "small-txt" },
                                              [
                                                _vm._v(
                                                  _vm._s(row.fullcategoryname)
                                                ),
                                              ]
                                            ),
                                            _c("p", { staticClass: "mg0" }, [
                                              _vm._v(_vm._s(row.goodsname)),
                                            ]),
                                          ]
                                        ),
                                        _c("td", { staticClass: "right" }, [
                                          _vm._v(_vm._s(row.price)),
                                        ]),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "bottom-group" },
                            [
                              _c("CommonArraySort", {
                                attrs: {
                                  "move-data": _vm.moveData,
                                  "list-data": _vm.productList,
                                  "key-name": "goodsno",
                                  "is-active-save-btn": false,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm.boardInfo.isevent == "T" &&
                      _vm.boardInfo.eventtype == "EVT003"
                        ? _c("tr", [
                            _c("th", [_vm._v("당첨조건")]),
                            _c(
                              "td",
                              [
                                _c("tr", [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.searchData.condcode,
                                          expression: "searchData.condcode",
                                        },
                                      ],
                                      staticStyle: { width: "15%" },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.searchData,
                                              "condcode",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function ($event) {
                                            return _vm.getConcodeVal($event)
                                          },
                                        ],
                                      },
                                    },
                                    _vm._l(
                                      _vm.commonCode.lotterycond,
                                      function (item, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: index,
                                            domProps: { value: item.cmcode },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.codename) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.searchData.cond1,
                                        expression: "searchData.cond1",
                                      },
                                    ],
                                    staticStyle: { width: "10%" },
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.searchData.cond1 },
                                    on: {
                                      change: function ($event) {
                                        return _vm.getConsign1Val($event)
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.searchData,
                                          "cond1",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.searchData.sign1,
                                          expression: "searchData.sign1",
                                        },
                                      ],
                                      staticStyle: { width: "6%" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.searchData,
                                            "sign1",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.commonCode.lotterysign,
                                      function (item, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: index,
                                            domProps: { value: item.cmcode },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.codename) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.searchData.relation,
                                          expression: "searchData.relation",
                                        },
                                      ],
                                      staticStyle: { width: "8%" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.searchData,
                                            "relation",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }),
                                      _c(
                                        "option",
                                        { attrs: { value: "AND" } },
                                        [_vm._v("AND")]
                                      ),
                                      _c("option", { attrs: { value: "OR" } }, [
                                        _vm._v("OR"),
                                      ]),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.searchData.cond2,
                                        expression: "searchData.cond2",
                                      },
                                    ],
                                    staticStyle: { width: "10%" },
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.searchData.cond2 },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.searchData,
                                          "cond2",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.searchData.sign2,
                                          expression: "searchData.sign2",
                                        },
                                      ],
                                      staticStyle: { width: "6%" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.searchData,
                                            "sign2",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.commonCode.lotterysign,
                                      function (item, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: index,
                                            domProps: { value: item.cmcode },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.codename) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v("  확률 "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.searchData.condval,
                                        expression: "searchData.condval",
                                      },
                                    ],
                                    staticStyle: { width: "10%" },
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.searchData.condval },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.searchData,
                                          "condval",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" 배    "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line",
                                      attrs: { type: "button" },
                                      on: { click: _vm.addcond },
                                    },
                                    [_vm._v("조건추가")]
                                  ),
                                ]),
                                _vm._l(
                                  _vm.boardInfo.conddata,
                                  function (item, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn blue-line",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removecond(index)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.concodeval) +
                                                "," +
                                                _vm._s(item.cond1) +
                                                " " +
                                                _vm._s(item.sign1val) +
                                                " " +
                                                _vm._s(item.relation) +
                                                " " +
                                                _vm._s(item.cond2) +
                                                " " +
                                                _vm._s(item.sign2val) +
                                                "," +
                                                _vm._s(item.condval) +
                                                "배 X "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  }
                                ),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                      _vm.boardInfo.isevent == "T" &&
                      _vm.boardInfo.eventtype == "EVT003"
                        ? _c("tr", [
                            _c("th", [_vm._v("당첨자수")]),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.boardInfo.winnercount,
                                    expression: "boardInfo.winnercount",
                                  },
                                ],
                                staticStyle: { width: "10%" },
                                attrs: {
                                  type: "text",
                                  placeholder: "당첨자수",
                                },
                                domProps: { value: _vm.boardInfo.winnercount },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.boardInfo,
                                      "winnercount",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.boardInfo.isevent == "T" &&
                      _vm.boardInfo.eventtype == "EVT004"
                        ? _c("tr", [
                            _c("th", [_vm._v("혜택종류")]),
                            _c("td", { attrs: { colspan: "2" } }, [
                              _c(
                                "table",
                                { attrs: { width: "100%" } },
                                [
                                  _c(
                                    "tr",
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.rewardData.rewardtype,
                                              expression:
                                                "rewardData.rewardtype",
                                            },
                                          ],
                                          ref: "rewardtype",
                                          staticStyle: { width: "6%" },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.rewardData,
                                                  "rewardtype",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              function ($event) {
                                                return _vm.getRewardTypeName(
                                                  $event
                                                )
                                              },
                                            ],
                                          },
                                        },
                                        _vm._l(
                                          _vm.commonCode.eventrewardtype,
                                          function (item, index) {
                                            return _c(
                                              "option",
                                              {
                                                key: index,
                                                domProps: {
                                                  value: item.cmcode,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.codename) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _vm._v("   "),
                                      _vm.rewardData.rewardtype == "ERT001"
                                        ? _c(
                                            "button",
                                            {
                                              ref: "couponSelect",
                                              staticClass: "btn blue-line",
                                              attrs: { type: "button" },
                                              on: {
                                                click: _vm.openCouponPopup,
                                              },
                                            },
                                            [_vm._v(" 쿠폰선택 ")]
                                          )
                                        : _vm._e(),
                                      _vm._v("   "),
                                      _vm.rewardData.rewardtype == "ERT002"
                                        ? _c(
                                            "button",
                                            {
                                              ref: "pointSelect",
                                              staticClass: "btn blue-line",
                                              attrs: { type: "button" },
                                              on: { click: _vm.openPointPopup },
                                            },
                                            [_vm._v(" 적립금선택 ")]
                                          )
                                        : _vm._e(),
                                      _vm._v("   "),
                                      _vm.rewardData.rewardtype == "ERT002"
                                        ? _c("CommonDatePickerFromTo", {
                                            attrs: {
                                              toYYYYMMDD:
                                                _vm.pointEdTimeInfo.toYYYYMMDD,
                                              toHH: _vm.pointEdTimeInfo.toHH,
                                              toMM: _vm.pointEdTimeInfo.toMM,
                                              "use-from": false,
                                              "use-to": true,
                                            },
                                            on: {
                                              getDate:
                                                _vm.getEventPointEdTimeDate,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v("   "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.rewardData.rewardname,
                                            expression: "rewardData.rewardname",
                                          },
                                        ],
                                        ref: "rewardname",
                                        staticStyle: { width: "30%" },
                                        attrs: {
                                          type: "text",
                                          disabled:
                                            _vm.rewardData.rewardtype ==
                                              "ERT001" ||
                                            _vm.rewardData.rewardtype ==
                                              "ERT002",
                                        },
                                        domProps: {
                                          value: _vm.rewardData.rewardname,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.rewardData,
                                              "rewardname",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v("  확률 "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.rewardData.rewardprob,
                                            expression: "rewardData.rewardprob",
                                          },
                                        ],
                                        ref: "rewardprob",
                                        staticClass: "right ml3",
                                        staticStyle: { width: "5%" },
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.rewardData.rewardprob,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.rewardData,
                                              "rewardprob",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" %    "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn blue-line",
                                          attrs: { type: "button" },
                                          on: { click: _vm.addReward },
                                        },
                                        [_vm._v(" 혜택추가 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._l(
                                    _vm.boardInfo.rewarddata,
                                    function (item, index) {
                                      return _c("tr", { key: index }, [
                                        _c("td", [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn blue-line",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeReward(index)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.rewardtypename) +
                                                  ", " +
                                                  _vm._s(item.rewardname) +
                                                  ", " +
                                                  _vm._s(item.rewardprob) +
                                                  " %" +
                                                  _vm._s(
                                                    item.rewardtype === "ERT002"
                                                      ? ", " +
                                                          _vm.$util.getFormatDate2(
                                                            item.pointedday
                                                          )
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.goSave },
                },
                [_vm._v("저장")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("취소")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_vm._v(" 제목 "), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 진행기간 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 카테고리 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 이벤트타입 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 적용채널 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 대상회원유형 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 대상회원등급 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 전시여부 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 대표이미지(PC) "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-with-text text" }, [
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          " 판매상품의 대표 이미지입니다. 보기 쉬운 간결한 이미지를 활용해 주세요. "
        ),
      ]),
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          " 사이즈: 610*300 / 최소: 200*200 / 용량: 10MB 이하 / 파일 : JPG, JPEG, PNG "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 대표이미지(모바일) "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-with-text text" }, [
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          " 모바일 리스팅 및 와이드형 화면에 노출되는 이미지를 업로드 해 주세요. "
        ),
      ]),
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(" 사이즈: 720*400 / 용량: 10MB 이하 / 파일 : JPG, JPEG, PNG "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 내용(PC) "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 내용(모바일) "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "total-group fl" }, [
      _c("span", { staticClass: "total" }, [_vm._v("적용대상 상품목록")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "7%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }