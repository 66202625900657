export default {
  MEMBER_USER: 'MEMBER_USER',
  ADMIN_USER: 'ADMIN_USER',
  PARTNER_USER: 'PARTNER_USER',
  MEMBER: {},
  ADMIN: {
    /* USER TYPE */
    USER_TYPE_ADMIN: 'URT001', // 관리자
    USER_TYPE_PARTNER: 'URT003', // 파트너사
    /* 파일타입  */
    FILE_TYPE_IMG: 'FLT001', // 이미지
    FILE_TYPE_VIDEO: 'FLT002', // 동영상
    FILE_TYPE_ATTACH: 'FLT003', // 첨부파일
    /* 배송비종류 */
    DELIV_FARE_DCT001: 'DCT001', // 무료
    DELIV_FARE_DCT002: 'DCT002', // 유료
    DELIV_FARE_DCT003: 'DCT003', // 조건부무료
    DELIV_FARE_DCT005: 'DCT005', // 구매수량비례
    /* 배송방법 */
    DELIV_TYPE_DLT001: 'DLT001', // 택배배송
    DELIV_TYPE_DLT002: 'DLT002', // 직배송
    DELIV_TYPE_DLT004: 'DLT004', // 개별택배사(수동반품)
    /* 배송일정 */
    DELIV_SCH_TYPE_DSC001: 'DSC001', // 당일배송
    DELIV_SCH_TYPE_DSC002: 'DSC002', // 익일배송
    DELIV_SCH_TYPE_DSC003: 'DSC003', // 주문제작
    DELIV_SCH_TYPE_DSC004: 'DSC004', // (가구)설치/화물배송
    DELIV_SCH_TYPE_DSC005: 'DSC005', // (가전)설치/화물배송
    /* 상품판매상태 */
    GOODS_SELL_TYPE_GST001: 'GST001', // 판매대기
    GOODS_SELL_TYPE_GST002: 'GST002', // 판매중
    GOODS_SELL_TYPE_GST003: 'GST003', // 판매중지
    GOODS_SELL_TYPE_GST004: 'GST004', // 자동품절
    GOODS_SELL_TYPE_GST005: 'GST005', // 수기품절
    GOODS_SELL_TYPE_GST006: 'GST006', // 영구종료
    /* 상품구분상태 */
    GOODS_DIV_TYPE_GDT001: 'GDT001', // 새상품
    /* KC대상구분 */
    KC_DIV_TYPE_KDT001: 'KDT001', // 미대상
    KC_DIV_TYPE_KDT002: 'KDT002', // 대상
    /* 이미지구분 */
    IMG_TYPE_GOODS_IMG_PC_B: 'IGT001', // 상품_PC이미지_대
    IMG_TYPE_GOODS_IMG_MO_B: 'IGT004', // 상품_Mobile이미지_대
    IMG_TYPE_GOODS_MOV_PC: 'IGT007', // 상품_PC동영상 포스터 이미지_대
    IMG_TYPE_GOODS_MOV_MO: 'IGT008', // 상품_Mobile동영상 포스터 이미지_대
    MOV_TYPE_GOODS_PC: 'IGT009', // 상품_PC동영상
    MOV_TYPE_GOODS_MO: 'IGT010', // 상품_Mobile동영상
    IMG_TYPE_GOODS_IMG_ADD_B1: 'IGT032', // 상품_추가이미지_대1
    IMG_TYPE_GOODS_IMG_ADD_B2: 'IGT035', // 상품_추가이미지_대2
    IMG_TYPE_GOODS_IMG_ADD_B3: 'IGT038', // 상품_추가이미지_대3
    IMG_TYPE_GOODS_IMG_ADD_B4: 'IGT041', // 상품_추가이미지_대4
    IMG_TYPE_GOODS_IMG_ADD_B5: 'IGT044', // 상품_추가이미지_대5
    IMG_TYPE_GOODS_IMG_ADD_B6: 'IGT047', // 상품_추가이미지_대6
    IMG_TYPE_GOODS_IMG_ADD_B7: 'IGT050', // 상품_추가이미지_대7
    IMG_TYPE_GOODS_IMG_ADD_B8: 'IGT053', // 상품_추가이미지_대8
    IMG_TYPE_GOODS_IMG_ADD_B9: 'IGT056', // 상품_추가이미지_대9
    IMG_TYPE_GOODS_IMG_ADD_B10: 'IGT059', // 상품_추가이미지_대10
    IMG_TYPE_GOODS_KC_CERT: 'IGT069', // 상품KC인증서
    /* 상품승인상태 */
    GOODS_STATUS_TEMP: 'GAT001', // 임시저장
    GOODS_STATUS_REQ: 'GAT002', // 승인요청
    GOODS_STATUS_REJECT: 'GAT003', // 반려
    GOODS_STATUS_APPROVAL: 'GAT004', // 상품승인완료
    GOODS_STATUS_SAVE: 'GAT005', // 저장
    /* 프로모션구분 */
    PROMO_DIV_DISCOUNT: 'PDT001', // 할인
    PROMO_DIV_GIFT: 'PDT002', // 사은품
    PROMO_DIV_RESERVE: 'PDT003', // 적립금/e포인트
    /* 대상상품범위 */
    GOODS_RANGE_ALL: 'GGT001', // 전체상품
    GOODS_RANGE_INCLUDE: 'GGT002', // 특정상품 추가
    GOODS_RANGE_EXCEPT: 'GGT003', // 특정상품 제외
    /* 사은품지급조건 */
    GIFT_TERM_PURCH: 'GFT001', // 특정상품구매시지급
    GIFT_TERM_PRICE_MORE: 'GFT002', // 특정금액이상구매시지급
    GIFT_TERM_PRICE_RANGE: 'GFT003', // 특정금액구간구매시지급
    GIFT_TERM_FRST_PURCH: 'GFT004', // 첫구매시지급
    /* 프로모션상태 */
    PROMO_ST_BEFORE: 'PST001', // 진행전
    PROMO_ST_PROCEEDING: 'PST002', // 진행중
    PROMO_ST_END: 'PST003', // 종료
    /* 시리얼프로모션 혜택 구분 */
    SRPTYPE_SRT_SAVE: 'SRT001', // 적립금
    SRPTYPE_SRT_EPOINT: 'SRT002', // e포인트
    SRPTYPE_SRT_COUPON: 'SRT003', // 쿠폰
    /* 시리얼프로모션 생성 타입 */
    SRCTYPE_SRC_AUTO: 'SRC001', // 자동
    SRCTYPE_SRC_DIR: 'SRC002', // 직접
    SRCTYPE_SRC_EXCEL: 'SRC003', // 엑셀업로드
    /* 회원상태 */
    CM_STATE_REAL: 'MST001', // 정상
    /* 적립금사용구분 */
    RES_USE_EXTINCT: 'RUT002', // 소멸(유효기간만료)
    RES_USE_ORD_CANCEL: 'RUT003', // 주문취소적립
    /* 쿠폰발급상태 */
    CPN_ISSUE_ST_BEFORE: 'CIS001', // 발급전
    CPN_ISSUE_ST_ISSUE: 'CIS002', // 발급중
    CPN_ISSUE_ST_STOP: 'CIS003', // 발급중지
    CPN_ISSUE_ST_COMPLETE: 'CIS004', // 발급완료
    /* 쿠폰사용기간종류 */
    CPN_USE_PERIOD: 'CUT001', // 사용기간
    CPN_USE_DAY_CNT: 'CUT002', // 발급일기준
    CPN_USE_LAST_DAY: 'CUT003', // 발급당월말까지
    /* 쿠폰종류 */
    COM_CPN_GOODS: 'CCT001', // 상품쿠폰
    COM_CPN_CART: 'CCT002', // 장바구니쿠폰
    COM_CPN_DELIV: 'CCT003', // 배송비쿠폰
    /* 쿠폰발급종류 */
    CPN_ISSUE_NOW_DISCOUNT: 'CIT001', // 즉시할인
    CPN_ISSUE_NEW_JOIN: 'CIT002', // 신규가입
    CPN_ISSUE_BIRTHDAY: 'CIT003', // 생일
    CPN_ISSUE_REGULAR: 'CIT004', // 정기발급
    CPN_ISSUE_PURCH_CONFIRM: 'CIT005', // 구매확정보상
    CPN_ISSUE_MEM_PROMO: 'CIT006', // 회원(프로모션)
    /* 쿠폰발급처리여부 */
    CPN_IS_START: 'CST001', // 발급시작
    CPN_IS_STOP: 'CST002', // 발급중지
    CPN_IS_RESTART: 'CST003', // 발급재개
    /* 주문상태 */
    ORDER_WAITING_DEPOSIT: 'ODS001', // 입금대기
    ORDER_CANCEL_BF_DEPOSIT: 'ODS002', // 입금전취소
    ORDER_COMPLETE_PAYMENT: 'ODS003', // 결제완료
    ORDER_PREPARING_GOODS: 'ODS004', // 상품준비중
    ORDER_PREPARING_DELIV: 'ODS006', // 배송준비중
    ORDER_IN_DELIVERY: 'ODS007', // 배송중
    ORDER_COMPLETE_DELIV: 'ODS008', // 배송완료
    ORDER_PURCH_CONFIRM: 'ODS009', // 구매확정
    /* 결제수단타입 */
    PAY_CREDIT_CARD: 'PWT001', //신용카드
    PAY_VIRTURE_ACCOUNT: 'PWT002', //가상계좌
    PAY_ACCOUNT_TRANSFER: 'PWT003', //계좌이체
    PAY_MOBILE: 'PWT004', //휴대폰
    PAY_NABER: 'PWT005', //네이버페이
    /* 배송요청타입 */
    RCV_REQ_INPUT: 'RVT006', // 직접입력
    /* 취소사유 */
    CNC_REASON_INPUT: 'CNT005', // 직접입력

    /* 클레임구분 */
    CLM_CANCEL: 'CLM001', // 취소
    CLM_RETURN: 'CLM002', // 반품
    CLM_EXCHANGE: 'CLM003', // 교환

    /* 클레임상태 */
    CNC_WAITING_APPRV: 'CNS001', // 취소신청(승인대기)
    CNC_WAITING_PAYMENT: 'CNS002', // 취소처리(결제대기)
    CNC_REJECT: 'CNS003', // 취소완료(취소반려)
    CNC_WITHDRAW: 'CNS004', // 취소완료(취소철회)
    CNC_PRCCOMP: 'CNS005', // 취소완료(처리완료)
    CNC_REQ_REJECT: 'CNS006', // 취소처리(반려승인요청)
    CNC_COMP_APPRV: 'CNS007', // 취소처리(승인완료)
    RETURN_WAITING_APPLY: 'RTS001', // 반품신청(승인대기)
    RETURN_WAITING_DEPOSIT: 'RTS002', // 반품처리(결제대기)
    RETURN_COMPLEATE_PAYMENT: 'RTS003', // 반품처리(결제완료)
    RETURN_APPROVAL_REFUSE: 'RTS004', // 반품처리(반품반려)
    RETURN_CANCEL: 'RTS005', // 반품처리(반품철회)
    RETURN_APPROVAL_COMPLETE: 'RTS006', // 반품처리(승인완료)
    RETURN_IN_DELIVERY: 'RTS007', // 반품처리(회수진행)
    RETURN_COMPLETE_DELIVERY: 'RTS008', // 반품처리(회수완료)
    RETURN_A_RETURN: 'RTS009', // 반품완료(반송처리)
    RETURN_COMPLETE: 'RTS010', // 반품완료(처리완료)
    RETURN_REQUEST_REFUSE: 'RTS011', // 반품처리(반려승인요청)
    EXCHANGE_WAITING_APPLY: 'EXS001', // 교환신청(승인대기)
    EXCHANGE_WAITING_DEPOSIT: 'EXS002', // 교환처리(결제대기)
    EXCHANGE_COMPLEATE_PAYMENT: 'EXS003', // 교환처리(결제완료)
    EXCHANGE_REJECT: 'EXS004', // 교환처리(교환반려)
    EXCHANGE_CANCEL: 'EXS005', // 교환처리(교환철회)
    EXCHANGE_APPROVAL_COMPLETE: 'EXS006', // 교환처리(승인완료)
    EXCHANGE_RETURN_DELIVERY: 'EXS007', // 교환처리(회수진행)
    EXCHANGE_RETURN_COMPLETE: 'EXS008', // 교환처리(회수완료)
    EXCHANGE_IN_DELIVERY: 'EXS009', // 교환처리(배송진행)
    EXCHANGE_COMPLETE_DELIV: 'EXS010', // 교환처리(배송완료)
    EXCHANGE_A_RETURN: 'EXS011', // 교환완료(반송처리)
    EXCHANGE_COMPLETE: 'EXS012', // 교환완료(처리완료)
    EXCHANGE_REQUEST_REFUSE: 'EXS013', // 교환처리(반려승인요청)

    /* AS상태 */
    AS_RECEIPT: 'AST001', // 접수완료
    AS_CANCEL: 'AST002', // 신청취소
    AS_COMP: 'AST003', // A/S완료

    /* 배송방법 */
    DELIV_PARCEL: 'DLT001', // 택배배송
    DELIV_DIRECT: 'DLT002', // 직배송
    DELIV_MANPARCEL: 'DLT004', // 수동택배배송

    /* 쿠폰삭제구분 */
    COUPON_ADMIN_DELETE: 'CDE002', // 목록삭제
    COUPON_MEMBER_DELETE: 'CDE003', // 완전삭제

    /* 고객귀책대상 클레임사유 */
    RTN_DIS_SATISFACTION: 'RTT001', // 단순상품불만족
    EXCHANGE_OPTION_CHANGE: 'EXT001', // 색상 및 옵션변경

    /* 상품수정권한 */
    EDIT_AUTH_MARKET_PRICE: 'AAT006', // 정상가
    EDIT_AUTH_PRICE: 'AAT005', // 판매가
    EDIT_AUTH_STOCK_CNT: 'AAT009', // 재고
    EDIT_AUTH_OPTION: 'AAT012', // 옵션
  },
  PARTNER: {},
  EMAILDOMAIN: [
    { value: '', label: '도메인을 선택' },
    { value: 'naver.com', label: 'naver.com' },
    { value: 'gmail.com', label: 'gmail.com' },
    { value: 'daum.net', label: 'daum.net' },
    { value: 'INPUT', label: '직접입력' },
  ],
  MANAGER_SESSION: 'MANAGE_USER_SESSION',
  COMMON_VALUE: {
    TEN_MEGA_BYTE: 10485760,
  },
  COMMON_MESSAGE: {
    // 여러곳에서 공통적으로 사용되는 메시지를 선언한다.
    LEAVE_PAGE_MESSAGE: '사이트에서 나가시겠습니까? 변경사항이 저장되지 않을 수 있습니다.',
    DELETE_FILE_QUESTION: '파일을 삭제 하시겠습니까?',
    DELETE_MANAGER_QUESTION: '담당자를 삭제 하시겠습니까?',
    INIT_PASSWORD_QUESTION: '비밀번호를 초기화 하시겠습니까?',
    SEND_PASSWORD_TO_PARTNERSHIP_MANAGER:
      '해당 파트너 제휴담당자에게\n임시 비밀번호가 발급 되었습니다.',
    SEND_PASSWORD_TO_OWNER: '해당 파트너 대표자 휴대폰 번호로\n 임시 비밀번호가 발급 되었습니다.',
    SEND_PASSWORD_TO_MANAGER:
      '해당 파트너 대표운영자 휴대폰 번호로\n 임시 비밀번호가 발급 되었습니다.',
    ENABLED_FILE_TYPE: 'jpg, jpeg, png파일 또는 PDF 파일만 첨부 가능 합니다.',
    MAX_FILE_SIZE: '파일 최대 크기는 10MB를 초과 할 수 없습니다.',
    UNCHECKED_CONSENT: '개인정보 수집 약관 미동의 시 입점 신청이 불가합니다.',
  },
  STORAGE_KEYS: {
    // Session or Local Storage 키 값을 선언한다.
    BIZ_AUTH_INFO: 'bizAuthInfo', // 파트너 사업자 인증 정보
    FINISHED_JOIN_PARTNER: 'finishedJoinPartner', // 파트너 가입 완료 여부
  },
};
