var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "join-content" }, [
      _vm._m(1),
      _vm._m(2),
      _c("div", { staticClass: "btn-group" }, [
        _c(
          "button",
          {
            staticClass: "btn big blue",
            attrs: { type: "button" },
            on: { click: _vm.goPartnershipLogin },
          },
          [_vm._v("확인")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "visual" }, [
      _c("h1", [_vm._v("입 / 점 / 신 / 청")]),
      _c("p", [_vm._v("성공적인 비즈니스 파트너! D.PLOT과 함께하세요!")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flow clearfix" }, [
      _c("ul", [
        _c("li", [
          _c("span", [_vm._v("Step 1.")]),
          _c("p", [_vm._v("입점절차 및 사업자 인증")]),
        ]),
        _c("li", [
          _c("span", [_vm._v("Step 2.")]),
          _c("p", [_vm._v("정보입력")]),
        ]),
        _c("li", { staticClass: "on" }, [
          _c("span", [_vm._v("Step 3.")]),
          _c("p", [_vm._v("신청완료")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "step4" }, [
      _c("img", {
        attrs: { alt: "", src: require("@assets/img/partnership-img.png") },
      }),
      _c("h3", [_vm._v("신청완료")]),
      _c("p", [_vm._v("입점신청서가 정상적으로 접수되었습니다.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }