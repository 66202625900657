var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1600px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [
              _vm._v(
                "주문취소상세" + _vm._s(" - " + _vm.claimInfo.cncstatusnm)
              ),
            ]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c(
            "div",
            { staticClass: "pop-body" },
            [
              _c("div", { staticClass: "gray-box mg0" }, [
                _c("div", { staticClass: "clearfix" }, [
                  _c("div", { staticClass: "fl" }, [
                    _vm._v(
                      " [" +
                        _vm._s(_vm.orderInfo.ordpathtypename) +
                        "] 주문번호 : "
                    ),
                    _c("strong", [_vm._v(_vm._s(_vm.orderInfo.ordno))]),
                  ]),
                  _c("div", { staticClass: "fr txt-gray" }, [
                    _c("span", [
                      _vm._v("주문일 : " + _vm._s(_vm.orderInfo.orderdate)),
                    ]),
                    _c("span", { staticClass: "left-bar" }, [
                      _vm._v(
                        "취소신청일 : " + _vm._s(_vm.orderInfo.clmreqdate)
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "clearfix mt20" }, [
                _c("div", { staticClass: "bar-title small fl" }, [
                  _vm._v(
                    " 주문취소상품 " +
                      _vm._s("[부분취소" + _vm.orderInfo.clmturn + "차]") +
                      " "
                  ),
                ]),
              ]),
              _vm._l(_vm.claimGoodsList, function (row) {
                return [
                  _c(
                    "table",
                    {
                      key: "list_" + row.ordgdidx,
                      staticClass: "data-tb align-c mt0",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _c("caption", [_vm._v(" 취소상품 목록 ")]),
                      _vm._m(0, true),
                      _vm._m(1, true),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_vm._v(_vm._s(row.goodscode))]),
                          _c("td", [_vm._v(_vm._s(row.optioncode))]),
                          _c("td", [
                            _c(
                              "div",
                              {
                                staticClass: "img-thumb size60 link",
                                class: {
                                  "no-image": _vm.$util.isNull(row.fullpath),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goFrontGoodsDetail(row.goodscode)
                                  },
                                },
                              },
                              [
                                !_vm.$util.isNull(row.fullpath)
                                  ? _c("img", { attrs: { src: row.fullpath } })
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _c("td", { staticClass: "left no-left" }, [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goGoodsDetail(row.goodsno)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.goodsname))]
                            ),
                          ]),
                          _c(
                            "td",
                            { staticStyle: { "white-space": "pre-wrap" } },
                            [_vm._v(_vm._s(row.optionname))]
                          ),
                          _c("td", [
                            _vm._v(_vm._s(_vm.$util.maskComma(row.bfordcnt))),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.$util.maskComma(row.clmcnt))),
                          ]),
                          _c("td", { staticClass: "right" }, [
                            _vm._v(_vm._s(_vm.$util.maskComma(row.price))),
                          ]),
                          _c("td", { staticClass: "right" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$util.maskComma(
                                    Number(row.price) * Number(row.bfordcnt)
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", { staticClass: "right" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$util.maskComma(
                                  Number(row.price) * Number(row.clmcnt)
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goClaimHistory(row.clmgdidx)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.boclmstatusnm))]
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(row.clmno))]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "table",
                    {
                      key: "reason_" + row.ordgdidx,
                      staticClass: "gray-tb",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _vm._m(2, true),
                      _c("tbody", [
                        _c("tr", [
                          _vm._m(3, true),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.claimInfo.cnctype,
                                    expression: "claimInfo.cnctype",
                                  },
                                ],
                                attrs: { disabled: "" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.claimInfo,
                                      "cnctype",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("구분선택"),
                                ]),
                                _vm._l(_vm.commonCode.cnctype, function (item) {
                                  return _c(
                                    "option",
                                    {
                                      key: item.cmcode,
                                      domProps: { value: item.cmcode },
                                    },
                                    [_vm._v(" " + _vm._s(item.codename) + " ")]
                                  )
                                }),
                              ],
                              2
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.claimInfo.clmdetail,
                                  expression: "claimInfo.clmdetail",
                                },
                              ],
                              staticClass: "ml3",
                              staticStyle: { width: "calc(100% - 156px)" },
                              attrs: {
                                type: "text",
                                maxlength: "500",
                                disabled: "",
                              },
                              domProps: { value: _vm.claimInfo.clmdetail },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.claimInfo,
                                    "clmdetail",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        row.clmstatus !==
                          _vm.$store.getters["ADMIN"].CNC_REJECT &&
                        _vm.$util.isNull(row.rejsubreason) &&
                        !_vm.$util.isNull(row.rejreason)
                          ? _c("tr", [
                              _vm._m(4, true),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: row.rejreason,
                                      expression: "row.rejreason",
                                    },
                                  ],
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "text",
                                    placeholder: "반려사유입력",
                                    maxlength: "200",
                                    disabled: "",
                                  },
                                  domProps: { value: row.rejreason },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        row,
                                        "rejreason",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ])
                          : _vm._e(),
                        row.clmstatus ===
                          _vm.$store.getters["ADMIN"].CNC_WITHDRAW &&
                        !_vm.$util.isNull(row.dropreason)
                          ? _c("tr", [
                              _vm._m(5, true),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: row.dropreason,
                                      expression: "row.dropreason",
                                    },
                                  ],
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "text",
                                    placeholder: "철회사유입력",
                                    maxlength: "200",
                                    disabled: "",
                                  },
                                  domProps: { value: row.dropreason },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        row,
                                        "dropreason",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.isShowRejsubreason ||
                        row.clmstatus ===
                          _vm.$store.getters["ADMIN"].CNC_REQ_REJECT ||
                        !_vm.$util.isNull(row.rejsubreason)
                          ? _c("tr", [
                              _vm._m(6, true),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: row.rejsubreason,
                                      expression: "row.rejsubreason",
                                    },
                                  ],
                                  style: _vm.isEdit
                                    ? "width: calc(100% - 57px);"
                                    : "width: 100%;",
                                  attrs: {
                                    type: "text",
                                    placeholder: "반려승인요청사유입력",
                                    maxlength: "200",
                                    disabled: !_vm.isEdit,
                                  },
                                  domProps: { value: row.rejsubreason },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        row,
                                        "rejsubreason",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm.isEdit
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn blue ml3",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveGoodsCncStatus(
                                              _vm.$store.getters["ADMIN"]
                                                .CNC_REQ_REJECT
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 저장 ")]
                                    )
                                  : _vm._e(),
                              ]),
                            ])
                          : _vm._e(),
                        row.clmstatus ===
                          _vm.$store.getters["ADMIN"].CNC_REJECT &&
                        !_vm.$util.isNull(row.rejsubreason) &&
                        !_vm.$util.isNull(row.rejreason)
                          ? _c("tr", [
                              _vm._m(7, true),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: row.rejreason,
                                      expression: "row.rejreason",
                                    },
                                  ],
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "text",
                                    placeholder: "반려승인사유입력",
                                    maxlength: "200",
                                    disabled: "",
                                  },
                                  domProps: { value: row.rejreason },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        row,
                                        "rejreason",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ])
                          : _vm._e(),
                        (row.clmstatus ===
                          _vm.$store.getters["ADMIN"].CNC_COMP_APPRV ||
                          row.clmstatus ===
                            _vm.$store.getters["ADMIN"].CNC_PRCCOMP) &&
                        !_vm.$util.isNull(row.rejdenyreason)
                          ? _c("tr", [
                              _vm._m(8, true),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: row.rejdenyreason,
                                      expression: "row.rejdenyreason",
                                    },
                                  ],
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "text",
                                    placeholder: "반려승인거부사유입력",
                                    maxlength: "200",
                                    disabled: "",
                                  },
                                  domProps: { value: row.rejdenyreason },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        row,
                                        "rejdenyreason",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]
              }),
              _vm.isWrite
                ? _c("div", { staticClass: "btn-group" }, [
                    _vm.isEdit
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big blue",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.saveGoodsCncStatus(
                                  _vm.$store.getters["ADMIN"].CNC_COMP_APPRV
                                )
                              },
                            },
                          },
                          [_vm._v(" 승인완료 ")]
                        )
                      : _vm._e(),
                    _vm.isEdit
                      ? _c(
                          "button",
                          {
                            staticClass: "btn big red",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.isShowRejsubreason = true
                              },
                            },
                          },
                          [_vm._v(" 반려승인요청 ")]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn big darkgray",
                        attrs: { type: "button" },
                        on: { click: _vm.onClose },
                      },
                      [_vm._v("닫기")]
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm.isGoodsDetailShow
        ? _c("GoodsDetail", {
            attrs: { activeGoodsNo: _vm.activeGoodsNo },
            on: { closePopup: _vm.closeGoodsDetail },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "6.5%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "8%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("상품코드")]),
        _c("th", [_vm._v("단품코드")]),
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("상품명")]),
        _c("th", [_vm._v("옵션")]),
        _c("th", [_vm._v("주문수량")]),
        _c("th", [_vm._v("취소수량")]),
        _c("th", [_vm._v("판매단가")]),
        _c("th", [_vm._v("판매금액")]),
        _c("th", [_vm._v("취소금액")]),
        _c("th", [_vm._v("취소상태")]),
        _c("th", [_vm._v("취소번호")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("취소사유"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("반려사유"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("철회사유"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("반려승인요청사유"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("반려승인사유"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("반려승인거부사유"),
      _c("i", { staticClass: "essential" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }