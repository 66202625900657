var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content m-leftmenu" }, [
    _c("div", { staticClass: "dashboard" }, [
      _c("div", { staticClass: "condition" }, [
        _c("div", { staticClass: "list" }, [
          _c("div", { staticClass: "dash-title" }, [
            _c("strong", [_vm._v("판매현황(최근 1개월)")]),
            _c("button", {
              staticClass: "dash-refresh",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.onSearch("ORDER")
                },
              },
            }),
          ]),
          _c("ul", [
            _c("li", [
              _c("p", [_vm._v("입금대기")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("ORDER_WAIT")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.orderStatInfo.ods001) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("상품준비중")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("ORDER_PREPARING_GOODS")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.orderStatInfo.ods004) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("배송준비중")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("ORDER_PREPARING_DELIVERY")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.orderStatInfo.ods006) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("배송중")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("ORDER_DELIVERY")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.orderStatInfo.ods007) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("배송완료")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("ORDER_DELIVERY_COMPLETE")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.orderStatInfo.ods008) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("구매확정")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("ORDER_COMPLETE")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.orderStatInfo.ods009) + "건")]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "list" }, [
          _c("div", { staticClass: "dash-title" }, [
            _c("strong", [_vm._v("클레임현황(최근 1년)")]),
            _c("button", {
              staticClass: "dash-refresh",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.onSearch("CLAIM")
                },
              },
            }),
          ]),
          _c("ul", [
            _c("li", [
              _c("p", [_vm._v("취소신청")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("CLAIM_CANCEL")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.claimStatInfo.clm001) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("반품신청")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("CLAIM_RETURN")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.claimStatInfo.clm002) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("반품반려승인요청")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("CLAIM_RETURN_REJECT")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.claimStatInfo.rtncnt) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("교환신청")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("CLAIM_CHANGE")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.claimStatInfo.clm003) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("교환반려승인요청")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("CLAIM_CHANGE_REJECT")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.claimStatInfo.exccnt) + "건")]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "list" }, [
          _c("div", { staticClass: "dash-title" }, [
            _c("strong", [_vm._v("지연현황(최근 1년)")]),
            _c("button", {
              staticClass: "dash-refresh",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.onSearch("DELAY")
                },
              },
            }),
          ]),
          _c("ul", [
            _c("li", [
              _c("p", [_vm._v("주문확인지연")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("DELAY_ORDER")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.delayStatInfo.orddelay) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("발송지연")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("DELAY_DELIV")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.delayStatInfo.delivdelay) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("취소지연")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("DELAY_CANCEL")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.delayStatInfo.cncdelay) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("반품지연")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("DELAY_RETURN")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.delayStatInfo.rtndelay) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("교환지연")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("DELAY_CHANGE")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.delayStatInfo.excdelay) + "건")]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "list" }, [
          _c("div", { staticClass: "dash-title" }, [
            _c("strong", [_vm._v("상품현황")]),
            _c("button", {
              staticClass: "dash-refresh",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.onSearch("DELAY")
                },
              },
            }),
          ]),
          _c("ul", [
            _c("li", [
              _c("p", [_vm._v("승인요청")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("GOODS_APPLY")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.goodsStatInfo.apply) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("판매중인 상품")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("GOODS_SALES")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.goodsStatInfo.sales) + "건")]
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("품절상품")]),
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("GOODS_SOLD_OUT")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.goodsStatInfo.soldout) + "건")]
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "board clearfix" }, [
        _c("div", { staticClass: "col-left" }, [
          _c("div", { staticClass: "member-condition" }, [
            _c("div", { staticClass: "dash-title" }, [
              _c("strong", [_vm._v("회원현황")]),
              _c(
                "button",
                {
                  staticClass: "dash-more",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("MEMBER_TOTAL")
                    },
                  },
                },
                [_vm._v(" more ")]
              ),
            ]),
            _c("div", { staticClass: "total" }, [
              _c("div", { staticClass: "total-member" }, [
                _c("p", [_vm._v("전체 회원 수")]),
                _c("strong", [_vm._v(_vm._s(_vm.memberStatInfo.total))]),
              ]),
              _c("ul", { staticClass: "clearfix" }, [
                _c("li", [
                  _c("p", [_vm._v("PC")]),
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      on: {
                        click: function ($event) {
                          return _vm.goRouterView("MEMBER_PC")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.memberStatInfo.pc))]
                  ),
                ]),
                _c("li", [
                  _c("p", [_vm._v("Mobile")]),
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      on: {
                        click: function ($event) {
                          return _vm.goRouterView("MEMBER_MOBILE")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.memberStatInfo.mobile))]
                  ),
                ]),
                _c("li", [
                  _c("p", [_vm._v("Mobile App")]),
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      on: {
                        click: function ($event) {
                          return _vm.goRouterView("MEMBER_MOBILE_APP")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.memberStatInfo.mobileapp))]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "recent" }, [
              _c("p", [
                _vm._v("최근 7일간 " + _vm._s(_vm.memberStatInfo.period)),
              ]),
              _c("ul", { staticClass: "clearfix" }, [
                _c("li", [
                  _c("p", [_vm._v("신규가입")]),
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      on: {
                        click: function ($event) {
                          return _vm.goRouterView("MEMBER_REG")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.memberStatInfo.reg))]
                  ),
                ]),
                _c("li", [
                  _c("p", [_vm._v("휴면")]),
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      on: {
                        click: function ($event) {
                          return _vm.goRouterView("MEMBER_DORMANCY")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.memberStatInfo.dormancy))]
                  ),
                ]),
                _c("li", [
                  _c("p", [_vm._v("탈퇴")]),
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      on: {
                        click: function ($event) {
                          return _vm.goRouterView("MEMBER_RESIGN")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.memberStatInfo.resign))]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "notice-group" }, [
            _c("div", { staticClass: "tab-group mt0" }, [
              _c("ul", [
                _c("li", { class: { active: _vm.noticeType === "MEMBER" } }, [
                  _vm._v(" >"),
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      on: {
                        click: function ($event) {
                          return _vm.setNoticeType("MEMBER")
                        },
                      },
                    },
                    [_vm._v("고객공지")]
                  ),
                ]),
                _c("li", { class: { active: _vm.noticeType === "PARTNER" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      on: {
                        click: function ($event) {
                          return _vm.setNoticeType("PARTNER")
                        },
                      },
                    },
                    [_vm._v("파트너사공지")]
                  ),
                ]),
              ]),
              _vm.noticeType === "MEMBER"
                ? _c(
                    "button",
                    {
                      staticClass: "dash-more",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.goRouterView("NOTICE_MEMBER")
                        },
                      },
                    },
                    [_vm._v(" more ")]
                  )
                : _vm._e(),
              _vm.noticeType === "PARTNER"
                ? _c(
                    "button",
                    {
                      staticClass: "dash-more",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.goRouterView("NOTICE_PARTNER")
                        },
                      },
                    },
                    [_vm._v(" more ")]
                  )
                : _vm._e(),
            ]),
            _vm.noticeType === "MEMBER"
              ? _c(
                  "div",
                  { staticClass: "tab-area" },
                  _vm._l(_vm.noticeList, function (row, index) {
                    return _c("ul", { key: index }, [
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "link",
                            on: {
                              click: function ($event) {
                                return _vm.goDirectRouterView(
                                  "NOTICE_MEMBER_DETAIL",
                                  row
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.subject))]
                        ),
                        _c("span", [_vm._v(_vm._s(row.regdate))]),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm.noticeType === "PARTNER"
              ? _c(
                  "div",
                  { staticClass: "tab-area" },
                  _vm._l(_vm.partnerNoticeList, function (row, index) {
                    return _c("ul", { key: index }, [
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "link",
                            on: {
                              click: function ($event) {
                                return _vm.goDirectRouterView(
                                  "NOTICE_PARTNER_DETAIL",
                                  row
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.subject))]
                        ),
                        _c("span", [_vm._v(_vm._s(row.regdate))]),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "col-center" }, [
          _c("div", { staticClass: "tab-group mt0" }, [
            _c("ul", [
              _c("li", { class: { active: _vm.salesType === "DAY" } }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.onChangeSalesType("DAY")
                      },
                    },
                  },
                  [_vm._v("일별 매출")]
                ),
              ]),
              _c("li", { class: { active: _vm.salesType === "WEEK" } }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.onChangeSalesType("WEEK")
                      },
                    },
                  },
                  [_vm._v("주별 매출")]
                ),
              ]),
              _c("li", { class: { active: _vm.salesType === "MONTH" } }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.onChangeSalesType("MONTH")
                      },
                    },
                  },
                  [_vm._v("월별 매출")]
                ),
              ]),
            ]),
            _c("button", {
              staticClass: "dash-refresh",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.onSearch("ACCOUNT")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "tab-area" }, [
            _vm.salesType === "WEEK" || _vm.salesType === "DAY"
              ? _c("div", [
                  _c("div", { staticClass: "tab-title" }, [
                    _vm.salesType === "DAY"
                      ? _c("strong", [_vm._v("금일 매출 현황")])
                      : _vm._e(),
                    _vm.salesType === "WEEK"
                      ? _c("strong", [_vm._v("금주 매출 현황")])
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.accountInfo.infostr))]),
                    _c("div", { staticClass: "basic-date" }, [
                      _c("span", { staticClass: "dpib" }, [
                        _vm._v("실 결제금액 기준일"),
                      ]),
                      _c("div", { staticClass: "radio_wrap dpib" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.status,
                              expression: "status",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "group00",
                            id: "dayAndWeek01",
                            value: "PAY",
                          },
                          domProps: { checked: _vm._q(_vm.status, "PAY") },
                          on: {
                            change: function ($event) {
                              _vm.status = "PAY"
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "dayAndWeek01" } }, [
                          _vm._v("결제완료"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.status,
                              expression: "status",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "group00",
                            id: "dayAndWeek02",
                            value: "BUY",
                          },
                          domProps: { checked: _vm._q(_vm.status, "BUY") },
                          on: {
                            change: function ($event) {
                              _vm.status = "BUY"
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "dayAndWeek02" } }, [
                          _vm._v("구매확정"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "data clearfix" }, [
                    _c("div", { staticClass: "left" }, [
                      _c("div", { staticClass: "total" }, [
                        _c("span", [_vm._v("매출(일반기준)")]),
                        _c("strong", [_vm._v(_vm._s(_vm.accountInfo.saleamt))]),
                        _c("span", [_vm._v("원")]),
                      ]),
                      _c("div", { staticClass: "total" }, [
                        _c("span", [_vm._v("매출(회계기준)")]),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.accountInfo.accountamt)),
                        ]),
                        _c("span", [_vm._v("원")]),
                      ]),
                      _c("div", { staticClass: "total" }, [
                        _c("span", [_vm._v("실 결제금액")]),
                        _c("strong", [_vm._v(_vm._s(_vm.accountInfo.payamt))]),
                        _c("span", [_vm._v("원")]),
                      ]),
                      _c(
                        "table",
                        {
                          staticClass: "gray-tb tiny mt20 mb0",
                          attrs: { cellpadding: "0", cellspacing: "0" },
                        },
                        [
                          _vm._m(0),
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("쿠폰 할인금액")]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  _vm._s(_vm.accountInfo.goodscpnamt) + "원"
                                ),
                              ]),
                              _c("th", [_vm._v("결제완료")]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(_vm._s(_vm.payCountInfo.ordcnt) + "건"),
                              ]),
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("프로모션 할인금액")]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  _vm._s(_vm.accountInfo.salepromoamt) + "원"
                                ),
                              ]),
                              _c("th", [_vm._v("구매확정")]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(_vm._s(_vm.payCountInfo.compcnt) + "건"),
                              ]),
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("환불금액")]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(_vm._s(_vm.accountInfo.rtnamt) + "원"),
                              ]),
                              _c("th", [_vm._v("취소/교환/반품")]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(_vm._s(_vm.payCountInfo.clmcnt) + "건"),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "right" }, [
                      _c(
                        "div",
                        { staticClass: "graph" },
                        [
                          _vm.chartView
                            ? _c("GChart", {
                                ref: "chart",
                                attrs: {
                                  type: "BarChart",
                                  data: _vm.chartData,
                                  options: _vm.chartOptions,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "top-list clearfix" }, [
                    _c("div", { staticClass: "top-tb" }, [
                      _c("div", { staticClass: "tb-title" }, [
                        _vm.salesType === "DAY"
                          ? _c("strong", [_vm._v("금일 판매량 순위 Top 10")])
                          : _vm._e(),
                        _vm.salesType === "WEEK"
                          ? _c("strong", [_vm._v("금주 판매량 순위 Top 10")])
                          : _vm._e(),
                        _c(
                          "button",
                          {
                            staticClass: "dash-more",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.goRouterView("STAT_SALE")
                              },
                            },
                          },
                          [_vm._v(" more ")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "scroll-y",
                          staticStyle: { "max-height": "120px" },
                        },
                        [
                          _c(
                            "table",
                            {
                              staticClass: "gray-tb align-c line tiny",
                              attrs: { cellpadding: "0", cellspacing: "0" },
                            },
                            [
                              _vm._m(1),
                              _vm._m(2),
                              _vm.saleList.length > 0
                                ? _c(
                                    "tbody",
                                    _vm._l(_vm.saleList, function (row, index) {
                                      return _c("tr", { key: index }, [
                                        _c("td", [_vm._v(_vm._s(index + 1))]),
                                        _c("td", { staticClass: "left" }, [
                                          _vm._v(_vm._s(row.goodsname)),
                                        ]),
                                        _c("td", [_vm._v(_vm._s(row.recnt))]),
                                      ])
                                    }),
                                    0
                                  )
                                : _c("tbody", [_vm._m(3)]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "top-tb" }, [
                      _c("div", { staticClass: "tb-title" }, [
                        _vm.salesType === "DAY"
                          ? _c("strong", [
                              _vm._v("금일 분류 별 판매량 순위 Top 10"),
                            ])
                          : _vm._e(),
                        _vm.salesType === "WEEK"
                          ? _c("strong", [
                              _vm._v("금주 분류 별 판매량 순위 Top 10"),
                            ])
                          : _vm._e(),
                        _c(
                          "button",
                          {
                            staticClass: "dash-more",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.goRouterView("STAT_CATE")
                              },
                            },
                          },
                          [_vm._v(" more ")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "scroll-y",
                          staticStyle: { "max-height": "120px" },
                        },
                        [
                          _c(
                            "table",
                            {
                              staticClass: "gray-tb align-c line tiny",
                              attrs: { cellpadding: "0", cellspacing: "0" },
                            },
                            [
                              _vm._m(4),
                              _vm._m(5),
                              _vm.cateList.length > 0
                                ? _c(
                                    "tbody",
                                    _vm._l(_vm.cateList, function (row, index) {
                                      return _c("tr", { key: index }, [
                                        _c("td", [_vm._v(_vm._s(index + 1))]),
                                        _c("td", { staticClass: "left" }, [
                                          _vm._v(_vm._s(row.name1)),
                                        ]),
                                        _c("td", [_vm._v(_vm._s(row.recnt))]),
                                      ])
                                    }),
                                    0
                                  )
                                : _c("tbody", [_vm._m(6)]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "top-tb" }, [
                      _c("div", { staticClass: "tb-title" }, [
                        _vm.salesType === "DAY"
                          ? _c("strong", [
                              _vm._v("금일 장바구니에 담긴 순위 Top 10"),
                            ])
                          : _vm._e(),
                        _vm.salesType === "WEEK"
                          ? _c("strong", [
                              _vm._v("금주 장바구니에 담긴 순위 Top 10"),
                            ])
                          : _vm._e(),
                        _c(
                          "button",
                          {
                            staticClass: "dash-more",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.goRouterView("STAT_CART")
                              },
                            },
                          },
                          [_vm._v(" more ")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "scroll-y",
                          staticStyle: { "max-height": "120px" },
                        },
                        [
                          _c(
                            "table",
                            {
                              staticClass: "gray-tb align-c line tiny",
                              attrs: { cellpadding: "0", cellspacing: "0" },
                            },
                            [
                              _vm._m(7),
                              _vm._m(8),
                              _vm.cartList.length > 0
                                ? _c(
                                    "tbody",
                                    _vm._l(_vm.cartList, function (row, index) {
                                      return _c("tr", { key: index }, [
                                        _c("td", [_vm._v(_vm._s(index + 1))]),
                                        _c("td", { staticClass: "left" }, [
                                          _vm._v(
                                            _vm._s(row.goodsname) +
                                              " (" +
                                              _vm._s(row.optionname) +
                                              ")"
                                          ),
                                        ]),
                                        _c("td", [_vm._v(_vm._s(row.ordcnt))]),
                                      ])
                                    }),
                                    0
                                  )
                                : _c("tbody", [_vm._m(9)]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "top-tb" }, [
                      _c("div", { staticClass: "tb-title" }, [
                        _vm.salesType === "DAY"
                          ? _c("strong", [_vm._v("금일 환불 순위 Top 10")])
                          : _vm._e(),
                        _vm.salesType === "WEEK"
                          ? _c("strong", [_vm._v("금주 환불 순위 Top 10")])
                          : _vm._e(),
                        _c(
                          "button",
                          {
                            staticClass: "dash-more",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.goRouterView("STAT_CLAIM")
                              },
                            },
                          },
                          [_vm._v(" more ")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "scroll-y",
                          staticStyle: { "max-height": "120px" },
                        },
                        [
                          _c(
                            "table",
                            {
                              staticClass: "gray-tb align-c line tiny",
                              attrs: { cellpadding: "0", cellspacing: "0" },
                            },
                            [
                              _vm._m(10),
                              _vm._m(11),
                              _vm.claimList.length > 0
                                ? _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.claimList,
                                      function (row, index) {
                                        return _c("tr", { key: index }, [
                                          _c("td", [_vm._v(_vm._s(index + 1))]),
                                          _c("td", { staticClass: "left" }, [
                                            _vm._v(_vm._s(row.goodsname)),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(row.clmcnt)),
                                          ]),
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                : _c("tbody", [_vm._m(12)]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.salesType === "MONTH"
              ? _c("div", [
                  _c("div", { staticClass: "tab-title" }, [
                    _c("strong", [_vm._v("당월 매출 현황")]),
                    _c("div", { staticClass: "basic-date" }, [
                      _c("span", { staticClass: "dpib" }, [
                        _vm._v("실 결제금액 기준일"),
                      ]),
                      _c("div", { staticClass: "radio_wrap dpib" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.status,
                              expression: "status",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "group00",
                            id: "group01",
                            value: "PAY",
                          },
                          domProps: { checked: _vm._q(_vm.status, "PAY") },
                          on: {
                            change: function ($event) {
                              _vm.status = "PAY"
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "group01" } }, [
                          _vm._v("결제완료"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.status,
                              expression: "status",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "group00",
                            id: "group02",
                            value: "BUY",
                          },
                          domProps: { checked: _vm._q(_vm.status, "BUY") },
                          on: {
                            change: function ($event) {
                              _vm.status = "BUY"
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "group02" } }, [
                          _vm._v("구매확정"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "data clearfix" }, [
                    _c("div", { staticClass: "left" }, [
                      _c("div", { staticClass: "month-total clearfix" }, [
                        _c("p", { staticClass: "month" }, [
                          _vm._v(_vm._s(_vm.accountInfo.infostr)),
                        ]),
                        _c("div", { staticClass: "total mt0" }, [
                          _c("span", [_vm._v("매출(일반기준)")]),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.accountInfo.saleamt)),
                          ]),
                          _c("span", [_vm._v("원")]),
                        ]),
                        _c("div", { staticClass: "total" }, [
                          _c("span", [_vm._v("매출(회계기준)")]),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.accountInfo.accountamt)),
                          ]),
                          _c("span", [_vm._v("원")]),
                        ]),
                        _c("div", { staticClass: "total" }, [
                          _c("span", [_vm._v("실 결제금액")]),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.accountInfo.payamt)),
                          ]),
                          _c("span", [_vm._v("원")]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "right" }, [
                      _c(
                        "table",
                        {
                          staticClass: "gray-tb tiny mt10",
                          attrs: { cellpadding: "0", cellspacing: "0" },
                        },
                        [
                          _vm._m(13),
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("쿠폰 할인금액")]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  _vm._s(_vm.accountInfo.goodscpnamt) + "원"
                                ),
                              ]),
                              _c("th", [_vm._v("결제완료")]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(_vm._s(_vm.payCountInfo.ordcnt) + "건"),
                              ]),
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("프로모션 할인금액")]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  _vm._s(_vm.accountInfo.salepromoamt) + "원"
                                ),
                              ]),
                              _c("th", [_vm._v("구매확정")]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(_vm._s(_vm.payCountInfo.compcnt) + "건"),
                              ]),
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("환불금액")]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(_vm._s(_vm.accountInfo.rtnamt) + "원"),
                              ]),
                              _c("th", [_vm._v("취소/교환/반품")]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(_vm._s(_vm.payCountInfo.clmcnt) + "건"),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "wide-graph" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.calcYear,
                            expression: "calcYear",
                          },
                        ],
                        staticClass: "mb10",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.calcYear = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {
                              return _vm.onSearch("ACCOUNT")
                            },
                          ],
                        },
                      },
                      _vm._l(_vm.yearList, function (value, i) {
                        return _c(
                          "option",
                          {
                            key: i,
                            attrs: { selected: "" },
                            domProps: { value: value },
                          },
                          [_vm._v(" " + _vm._s(value) + "년 ")]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "graph" },
                      [
                        _vm.yearChartView
                          ? _c("GChart", {
                              ref: "yearChart",
                              attrs: {
                                type: "ColumnChart",
                                data: _vm.yearChartData,
                                options: _vm.chartOptions,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "total" }, [
                      _c("span", [_vm._v("누적 매출액")]),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$util.maskComma(_vm.totAmt))),
                      ]),
                      _c("span", [_vm._v("원")]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "col-right" }, [
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "dash-title" }, [
              _c("strong", [_vm._v("쿠폰")]),
              _c(
                "button",
                {
                  staticClass: "dash-more",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("COUPON")
                    },
                  },
                },
                [_vm._v("more")]
              ),
            ]),
            _c("ul", [
              _c("li", [
                _c("p", [_vm._v("발급 전")]),
                _c(
                  "a",
                  {
                    staticClass: "link",
                    on: {
                      click: function ($event) {
                        return _vm.goRouterView("COUPON_BEFORE")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.couponStatInfo.beforeissue) + "건")]
                ),
              ]),
              _c("li", [
                _c("p", [_vm._v("발급 중")]),
                _c(
                  "a",
                  {
                    staticClass: "link",
                    on: {
                      click: function ($event) {
                        return _vm.goRouterView("COUPON_ISSUE")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.couponStatInfo.issue) + "건")]
                ),
              ]),
              _c("li", [
                _c("p", [_vm._v("기간종료(7일전) 쿠폰")]),
                _c(
                  "a",
                  {
                    staticClass: "link",
                    on: {
                      click: function ($event) {
                        return _vm.goRouterView("COUPON_PERIOD")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.couponStatInfo.expireissue) + "건")]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "dash-title" }, [
              _c("strong", [_vm._v("프로모션")]),
              _c(
                "button",
                {
                  staticClass: "dash-more",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.goRouterView("PROMOTION")
                    },
                  },
                },
                [_vm._v(" more ")]
              ),
            ]),
            _c("ul", [
              _c("li", [
                _c("p", [_vm._v("할인 프로모션")]),
                _c(
                  "a",
                  {
                    staticClass: "link",
                    on: {
                      click: function ($event) {
                        return _vm.goRouterView("PROMOTION_SALE")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.promoStatInfo.sale) + "건")]
                ),
              ]),
              _c("li", [
                _c("p", [_vm._v("사은품 프로모션")]),
                _c(
                  "a",
                  {
                    staticClass: "link",
                    on: {
                      click: function ($event) {
                        return _vm.goRouterView("PROMOTION_GIFT")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.promoStatInfo.gift) + "건")]
                ),
              ]),
              _c("li", [
                _c("p", [_vm._v("적립금 프로모션")]),
                _c(
                  "a",
                  {
                    staticClass: "link",
                    on: {
                      click: function ($event) {
                        return _vm.goRouterView("PROMOTION_RESERVE")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.promoStatInfo.reserve) + "건")]
                ),
              ]),
              _c("li", [
                _c("p", [_vm._v("종료예정(7일전) 프로모션")]),
                _c(
                  "a",
                  {
                    staticClass: "link",
                    on: {
                      click: function ($event) {
                        return _vm.goRouterView("PROMOTION_EXPIRE")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.promoStatInfo.expire) + "건")]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "list" }, [
            _vm._m(14),
            _c("ul", [
              _c("li", [
                _c("p", [_vm._v("1:1문의")]),
                _c(
                  "a",
                  {
                    staticClass: "link",
                    on: {
                      click: function ($event) {
                        return _vm.goRouterView("INQUIRY")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.inquiryStatInfo.inquiry) + "건")]
                ),
              ]),
              _c("li", [
                _c("p", [_vm._v("상품문의")]),
                _c(
                  "a",
                  {
                    staticClass: "link",
                    on: {
                      click: function ($event) {
                        return _vm.goRouterView("GOODS_QNA")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.inquiryStatInfo.goodsqna) + "건")]
                ),
              ]),
              _c("li", [
                _c("p", [_vm._v("AS문의")]),
                _c(
                  "a",
                  {
                    staticClass: "link",
                    on: {
                      click: function ($event) {
                        return _vm.goRouterView("AS_INQUIRY")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.inquiryStatInfo.asinquiry) + "건")]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "29%" } }),
      _c("col", { attrs: { width: "29%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "17%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "70%" } }),
      _c("col", { attrs: { width: "20%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("No")]),
        _c("th", [_vm._v("상품명")]),
        _c("th", [_vm._v("판매수량")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "3" } }, [
        _vm._v("조회된 결과가 없습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "70%" } }),
      _c("col", { attrs: { width: "20%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("No")]),
        _c("th", [_vm._v("분류명")]),
        _c("th", [_vm._v("판매수량")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "3" } }, [
        _vm._v("조회된 결과가 없습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "70%" } }),
      _c("col", { attrs: { width: "20%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("No")]),
        _c("th", [_vm._v("상품명")]),
        _c("th", [_vm._v("판매수량")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "3" } }, [
        _vm._v("조회된 결과가 없습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "70%" } }),
      _c("col", { attrs: { width: "20%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("No")]),
        _c("th", [_vm._v("상품명")]),
        _c("th", [_vm._v("환불수량")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "3" } }, [
        _vm._v("조회된 결과가 없습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "29%" } }),
      _c("col", { attrs: { width: "29%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "17%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dash-title" }, [
      _c("strong", [_vm._v("미 답변 문의")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }