var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "600px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("다운로드 스크립트")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.scriptData,
                  expression: "scriptData",
                },
              ],
              staticStyle: { height: "180px", background: "#fff !important" },
              attrs: { readonly: "" },
              domProps: { value: _vm.scriptData },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.scriptData = $event.target.value
                },
              },
            }),
            _c("div", { staticClass: "btn-group mt10" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.copyScript },
                },
                [_vm._v("복사")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }