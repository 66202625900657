var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _c("loading", {
        attrs: {
          active: _vm.isLoading,
          "background-color": _vm.backgroundColor,
          "can-cancel": false,
          color: _vm.color,
          height: 80,
          "is-full-page": true,
          loader: _vm.loader,
          width: 80,
          "z-index": 99999,
        },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }