var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "500px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("단어중복")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "mb10" }, [
              _vm._v(
                " 중복된 단어가 존재합니다. 중복된 단어를 제거 후 다시 등록하시기 바랍니다. "
              ),
            ]),
            _c("div", { staticClass: "red-box mg0" }, [
              _c(
                "ul",
                _vm._l(_vm.list, function (row, index) {
                  return _c("li", { key: index }, [
                    _vm._v(_vm._s(row.bannedword)),
                  ])
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "btn-group mt10" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("확인")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }