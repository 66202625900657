import { render, staticRenderFns } from "./PromotionRegist.vue?vue&type=template&id=386b93d2&"
import script from "./PromotionRegist.js?vue&type=script&lang=js&"
export * from "./PromotionRegist.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildagent/work/3684aff05d5d78ea/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('386b93d2')) {
      api.createRecord('386b93d2', component.options)
    } else {
      api.reload('386b93d2', component.options)
    }
    module.hot.accept("./PromotionRegist.vue?vue&type=template&id=386b93d2&", function () {
      api.rerender('386b93d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/promotion/promotion/PromotionRegist.vue"
export default component.exports