<template>
  <div id="modal-wrap" class="modal" style="display: block">
    <div class="modal-content" style="width: 1200px">
      <div class="pop-header">
        <h2>임직원 프로모션 생성</h2>
        <button type="button" class="pop-close" @click="onClose"></button>
      </div>
      <div class="pop-body">
        <div class="form-area">
          <table cellpadding="0" cellspacing="0" class="gray-tb">
            <colgroup>
              <col width="180px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  관리 제목
                  <i class="essential"></i>
                </th>
                <td colspan="3">
                  <div class="new-id-field">
                    <input
                      type="text"
                      style="width: 100%"
                      v-model.trim="promotionTitle"
                      maxlength="30"
                    />
                  </div>
                  <p v-if="!isValidPromotionTitle" class="alert-text txt-red">
                    <i class="icon-alert2" />
                    관리 제목은 필수 입력값입니다.
                  </p>
                  <p v-else class="txt-gray">임직원 프로모션 관리를 위한 타이틀을 입력해주세요.</p>
                </td>
              </tr>
              <tr>
                <th>
                  시리얼 프로모션 ID
                  <i class="essential"></i>
                </th>
                <td colspan="3">
                  <div class="new-id-field">
                    <input type="text" style="width: 100%" v-model.trim="serialPromotionId" />
                  </div>
                  <p v-if="!isValidSerialPromotionId" class="alert-text txt-red">
                    <i class="icon-alert2" />
                    시리얼 프로모션 ID는 필수 입력값입니다.
                  </p>
                  <p v-else class="txt-gray">
                    시리얼 프로모션 관리 메뉴에서 시리얼 프로모션 ID를 확인하여 입력해주세요.
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  엑셀 업로드
                  <i class="essential"></i>
                </th>
                <td colspan="3">
                  <div class="file-upload-wrap">
                    <button class="btn green-line" type="button" @click="onAttachExcelFile">
                      <i class="icon-excel" />
                      엑셀 업로드
                    </button>
                    <span>{{ uploadFileName }}</span>
                    <input
                      type="file"
                      ref="excelUpload"
                      @input="onInputFile"
                      accept=".xls,.xlsx"
                      hidden
                    />
                  </div>

                  <p v-if="!isValidUploadFile" class="alert-text txt-red">
                    <i class="icon-alert2" />
                    파일 업로드는 필수값입니다.
                  </p>
                  <p v-else class="txt-gray">E-Mail 발송을 위한 엑셀 파일을 업로드해주세요.</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="btn-group">
          <button type="button" class="btn big blue" :disabled="!isValid" @click="onSave">
            저장
          </button>
          <button type="button" class="btn big darkgray" @click="onClose">취소</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PRESIGNED_UPLOAD_FILE_TYPE, presignedUpload } from '@/js/filePresignedUpload.js';

export default {
  name: 'employee.promotion.create.popup',
  data() {
    return {
      promotionTitle: '',
      serialPromotionId: '',
      uploadFile: null,
      dirtyfield: [],
      invaildField: [],
    };
  },
  computed: {
    uploadFileName() {
      if (!this.uploadFile) {
        return '';
      }

      return this.uploadFile.name || '';
    },
    isValidPromotionTitle() {
      return !this.invaildField.includes('promotionTitle');
    },
    isValidSerialPromotionId() {
      return !this.invaildField.includes('serialPromotionId');
    },
    isValidUploadFile() {
      return !this.invaildField.includes('uploadFile');
    },
    isValid() {
      if (this.dirtyfield.length < 3) {
        return false;
      }

      return this.isValidPromotionTitle && this.isValidSerialPromotionId && this.isValidUploadFile;
    },
  },
  watch: {
    promotionTitle(value) {
      this.updateField('promotionTitle', !!value);
    },
    serialPromotionId(value) {
      this.updateField('serialPromotionId', !!value);
    },
    uploadFile(value) {
      this.updateField('uploadFile', !!value);
    },
  },
  methods: {
    onAttachExcelFile() {
      this.$refs.excelUpload.click();
    },
    onClose() {
      this.$emit('onClose');
    },
    onSave() {
      this.save(() => {
        this.$emit('onRefresh');
        this.onClose();
      });
    },
    onInputFile(event) {
      const files = event.target.files;
      this.uploadFile = files[0] || null;
    },
    updateField(fieldName, isValid) {
      const isDirty = this.updateDirtyField(fieldName);

      if (isDirty) {
        this.invaildField = this.invaildField.reduce(
          (acc, invaildField) => {
            if (invaildField !== fieldName) {
              acc.push(invaildField);
            }
            return acc;
          },
          !isValid ? [fieldName] : [],
        );
      }
    },
    updateDirtyField(fieldName) {
      if (!this.dirtyfield.includes(fieldName)) {
        this.dirtyfield.push(fieldName);
        return false;
      }

      return true;
    },
    async save(callback) {
      if (!this.promotionTitle || !this.serialPromotionId || !this.uploadFile) {
        return alert('필수정보를 입력해주세요.');
      }

      try {
        const data = await presignedUpload({
          file: this.uploadFile,
          type: PRESIGNED_UPLOAD_FILE_TYPE.엑셀,
          uploadPath: 'EmployeePromotion',
        });

        if (!data) {
          return alert('파일 업로드 실패 되었습니다.');
        }

        const { statusCode } = await this.$http.post(`/admin/employee-promotion/save`, {
          serialPromotionId: this.serialPromotionId,
          title: this.promotionTitle,
          filePath: data.filePath,
          isloading: true,
        });

        if (statusCode === 200) {
          callback();
        }
      } catch (error) {
        this.$util.debug(error);
      }
    },
  },
};
</script>

<style scoped>
.file-upload-wrap {
  display: flex;
  gap: 5px;
  align-items: center;
}
</style>
