var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1600px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("상품 상세정보")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("closePopup")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "gray-box mg0 clearfix" }, [
              _c("div", { staticClass: "fl" }, [
                _vm._v(
                  " [" +
                    _vm._s(
                      _vm.basicInfo.orgispbgoods == "T"
                        ? "일반상품"
                        : _vm.basicInfo.dealername
                    ) +
                    "] " +
                    _vm._s(_vm.basicInfo.goodscode) +
                    " " +
                    _vm._s(_vm.basicInfo.orggoodsname) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "fr" }, [
                _c("span", [
                  _vm._v("등록일 : " + _vm._s(_vm.basicInfo.regdate)),
                ]),
                _c("span", { staticClass: "left-bar" }, [
                  _vm._v("최종수정일 : " + _vm._s(_vm.basicInfo.moddate)),
                ]),
                _c("span", { staticClass: "left-bar" }, [
                  _vm._v("[" + _vm._s(_vm.sellInfo.goodsselltypename) + "]"),
                ]),
                _vm.basicInfo.ispbgoods == "F"
                  ? _c("span", { staticClass: "left-bar" }, [
                      _vm._v(
                        " [" + _vm._s(_vm.basicInfo.goodsapprtypename) + "] "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "clearfix mt10" }, [
              _c(
                "div",
                {
                  staticClass: "btn-group fr",
                  style:
                    _vm.sellInfo.orggoodsselltype ===
                    _vm.$store.getters["ADMIN"].GOODS_SELL_TYPE_GST006
                      ? "visibility: hidden;"
                      : "",
                },
                [
                  _vm.iscopy
                    ? _c("span", { staticClass: "txt-orange mr5" }, [
                        _c("i", { staticClass: "icon-alert" }),
                        _vm._v(" 현재 상품은 복사한 상품입니다. "),
                      ])
                    : _vm._e(),
                  !_vm.$util.isNull(_vm.basicInfo.goodscode) && !_vm.iscopy
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big blue-line",
                          attrs: { type: "button" },
                          on: { click: _vm.goPriview },
                        },
                        [_vm._v(" 미리보기 ")]
                      )
                    : _vm._e(),
                  !_vm.iscopy
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big gray",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.iscopy = true
                            },
                          },
                        },
                        [_vm._v(" 복사 ")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn big blue",
                      attrs: { type: "button" },
                      on: { click: _vm.goTemporarySave },
                    },
                    [_vm._v("임시저장")]
                  ),
                  !_vm.isPartner &&
                  !_vm.iscopy &&
                  _vm.basicInfo.goodsapprtype ===
                    _vm.$store.getters["ADMIN"].GOODS_STATUS_REQ
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big blue",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.changeGoodsAppr(
                                _vm.$store.getters["ADMIN"]
                                  .GOODS_STATUS_APPROVAL
                              )
                            },
                          },
                        },
                        [_vm._v(" 승인 ")]
                      )
                    : _vm._e(),
                  !_vm.isPartner &&
                  !_vm.iscopy &&
                  _vm.basicInfo.goodsapprtype ===
                    _vm.$store.getters["ADMIN"].GOODS_STATUS_REQ
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big red",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.changeGoodsAppr(
                                _vm.$store.getters["ADMIN"].GOODS_STATUS_REJECT
                              )
                            },
                          },
                        },
                        [_vm._v(" 반려 ")]
                      )
                    : _vm._e(),
                  !_vm.isPartner ||
                  (_vm.isPartner &&
                    !_vm.iscopy &&
                    _vm.basicInfo.goodsapprtype ===
                      _vm.$store.getters["ADMIN"].GOODS_STATUS_APPROVAL)
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big blue",
                          attrs: { type: "button" },
                          on: { click: _vm.goSave },
                        },
                        [_vm._v(" 저장 ")]
                      )
                    : _vm._e(),
                  _vm.isPartner &&
                  !_vm.iscopy &&
                  _vm.basicInfo.goodsapprtype ===
                    _vm.$store.getters["ADMIN"].GOODS_STATUS_REJECT
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big red",
                          attrs: { type: "button" },
                          on: { click: _vm.goReqApprv },
                        },
                        [_vm._v(" 재승인요청 ")]
                      )
                    : _vm._e(),
                  _vm.isPartner &&
                  (_vm.iscopy ||
                    (!_vm.iscopy &&
                      _vm.basicInfo.goodsapprtype ===
                        _vm.$store.getters["ADMIN"].GOODS_STATUS_TEMP))
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big red",
                          attrs: { type: "button" },
                          on: { click: _vm.goReqApprv },
                        },
                        [_vm._v(" 승인요청 ")]
                      )
                    : _vm._e(),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "tab-group" },
              [
                _c(
                  "scrollactive",
                  {
                    attrs: {
                      "active-class": "active",
                      scrollContainerSelector: ".dtl",
                      "bezier-easing-value": ".5,0,.35,1",
                      highlightFirstItem: true,
                      offset: 900,
                      scrollOffset: 500,
                      duration: 600,
                    },
                  },
                  [
                    _c(
                      "ul",
                      _vm._l(_vm.tabObject, function (value, name, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            on: {
                              click: function ($event) {
                                return _vm.onActive(value)
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "scrollactive-item",
                                attrs: { href: value.href },
                              },
                              [_vm._v(_vm._s(value.tabName))]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "tab-area dtl",
                staticStyle: { "max-height": "calc(90vh - 190px)" },
              },
              [
                _c(
                  "div",
                  { staticClass: "boxing", attrs: { id: "goodsTab1" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "boxing-title",
                        class: { closed: !_vm.tabObject.goodsTab1.isOpen },
                      },
                      [
                        _vm._v(" 기본 정보 "),
                        _c("i", {
                          staticClass: "arrcodi",
                          on: {
                            click: function ($event) {
                              return _vm.onToggle(_vm.tabObject.goodsTab1)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-area",
                        style: {
                          display: _vm.tabObject.goodsTab1.isOpen
                            ? "block"
                            : "none",
                        },
                      },
                      [
                        !_vm.isPartner
                          ? _c("dl", [
                              _c("dt", [_vm._v("판매구분")]),
                              _c("dd", [
                                _c("div", { staticClass: "radio_wrap wide" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.basicInfo.ispbgoods,
                                        expression: "basicInfo.ispbgoods",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "ispbgoodsD",
                                      id: "ispbgoodsDT",
                                      value: "T",
                                      disabled: "",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.basicInfo.ispbgoods,
                                        "T"
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.basicInfo.dealerno = ""
                                      },
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.basicInfo,
                                          "ispbgoods",
                                          "T"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "ispbgoodsDT" } },
                                    [_vm._v("직매입")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.basicInfo.ispbgoods,
                                        expression: "basicInfo.ispbgoods",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "ispbgoodsD",
                                      id: "ispbgoodsDF",
                                      value: "F",
                                      disabled: "",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.basicInfo.ispbgoods,
                                        "F"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.basicInfo,
                                          "ispbgoods",
                                          "F"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "ispbgoodsDF" } },
                                    [_vm._v("위탁")]
                                  ),
                                ]),
                                _vm.basicInfo.ispbgoods == "F"
                                  ? _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.basicInfo.dealerno,
                                            expression: "basicInfo.dealerno",
                                          },
                                        ],
                                        staticStyle: { width: "250px" },
                                        attrs: { disabled: "" },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.basicInfo,
                                              "dealerno",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("파트너사 선택"),
                                        ]),
                                        _vm._l(
                                          _vm.basicInfo.partnerList,
                                          function (item) {
                                            return _c(
                                              "option",
                                              {
                                                key: item.no,
                                                domProps: { value: item.no },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.name) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "fieldset",
                          {
                            attrs: {
                              disabled:
                                !_vm.iscopy &&
                                _vm.isPartner &&
                                _vm.editauth.category.isauth === false,
                            },
                          },
                          [
                            _c("legend", [_vm._v("카테고리 수정권한")]),
                            _c("dl", [
                              _vm._m(0),
                              _c("dd", [
                                _c("span", [_vm._v("카테고리 명 검색")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.basicInfo.categoryname,
                                      expression: "basicInfo.categoryname",
                                    },
                                  ],
                                  staticStyle: { width: "440px" },
                                  attrs: {
                                    type: "search",
                                    placeholder: "예) 냉장고",
                                    maxlength: 100,
                                  },
                                  domProps: {
                                    value: _vm.basicInfo.categoryname,
                                  },
                                  on: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.onCategorynameShow.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.basicInfo,
                                        "categoryname",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-search",
                                    attrs: { type: "button" },
                                    on: { click: _vm.onCategorynameShow },
                                  },
                                  [_vm._v(" 검색 ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showInfo.iscateshow,
                                        expression: "showInfo.iscateshow",
                                      },
                                      {
                                        name: "click-outside",
                                        rawName: "v-click-outside",
                                        value: _vm.closeCatenameLayer,
                                        expression: "closeCatenameLayer",
                                      },
                                    ],
                                    staticClass: "result-component",
                                    staticStyle: {
                                      display: "block",
                                      "margin-left": "100px",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "list" }, [
                                      _vm.basicInfo.categorynameList.length > 0
                                        ? _c(
                                            "ul",
                                            _vm._l(
                                              _vm.basicInfo.categorynameList,
                                              function (item) {
                                                return _c(
                                                  "li",
                                                  { key: item.fullcategoryidx },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "javascript:void(0);",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.setCategoryInfo(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.fullcategoryname
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c("ul", [_vm._m(1)]),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c(
                                "dd",
                                {
                                  staticClass: "dd-right",
                                  staticStyle: { width: "180px" },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line",
                                      attrs: {
                                        type: "button",
                                        "data-auth": "category",
                                      },
                                      on: { click: _vm.addCategory },
                                    },
                                    [_vm._v(" 추가 ")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn blue-line",
                                      attrs: {
                                        type: "button",
                                        "data-auth": "category",
                                      },
                                      on: { click: _vm.onCateTemplateShow },
                                    },
                                    [_vm._v(" 카테고리템플릿 ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.showInfo.istempshow,
                                          expression: "showInfo.istempshow",
                                        },
                                        {
                                          name: "click-outside",
                                          rawName: "v-click-outside",
                                          value: _vm.closeCatetempLayer,
                                          expression: "closeCatetempLayer",
                                        },
                                      ],
                                      staticClass:
                                        "result-component right round",
                                      staticStyle: { display: "block" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "list checklist" },
                                        [
                                          _vm.basicInfo.categoryTemplateList
                                            .length > 0
                                            ? _c(
                                                "ul",
                                                _vm._l(
                                                  _vm.basicInfo
                                                    .categoryTemplateList,
                                                  function (item, index) {
                                                    return _c(
                                                      "li",
                                                      { key: item.cateidx },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.ischecked,
                                                              expression:
                                                                "item.ischecked",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "checkbox",
                                                            id:
                                                              "catetempD_" +
                                                              item.cateidx,
                                                          },
                                                          domProps: {
                                                            checked:
                                                              Array.isArray(
                                                                item.ischecked
                                                              )
                                                                ? _vm._i(
                                                                    item.ischecked,
                                                                    null
                                                                  ) > -1
                                                                : item.ischecked,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  item.ischecked,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      item,
                                                                      "ischecked",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      item,
                                                                      "ischecked",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  item,
                                                                  "ischecked",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for:
                                                                "catetempD_" +
                                                                item.cateidx,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.fullcategoryname
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("button", {
                                                          staticClass: "del",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteCateTemplate(
                                                                item,
                                                                index
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c("ul", [_vm._m(2)]),
                                        ]
                                      ),
                                      _vm.basicInfo.categoryTemplateList
                                        .length > 0
                                        ? _c(
                                            "div",
                                            { staticClass: "btn-group" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn blue-line",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: _vm.setGoodsCategory,
                                                  },
                                                },
                                                [_vm._v(" 적용 ")]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn gray",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click:
                                                      _vm.initCategoryTemplate,
                                                  },
                                                },
                                                [_vm._v(" 초기화 ")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("dl", [
                              _c("dt"),
                              _c("dd", [
                                _c(
                                  "table",
                                  {
                                    staticClass: "category-depth",
                                    attrs: {
                                      cellpadding: "0",
                                      cellspacing: "0",
                                    },
                                  },
                                  [
                                    _vm._m(3),
                                    _vm._m(4),
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("td", [
                                          _c("div", { staticClass: "list" }, [
                                            _c(
                                              "ul",
                                              _vm._l(
                                                _vm.basicInfo.categoryObj
                                                  .depth1list,
                                                function (item) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: item.idx,
                                                      class: {
                                                        active:
                                                          _vm.basicInfo
                                                            .depth1Category
                                                            .idx == item.idx,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setCategoryCodeInfo(
                                                            "1",
                                                            item.idx
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "javascript:void(0);",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.value)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]),
                                        ]),
                                        _c("td", [
                                          _c("div", { staticClass: "list" }, [
                                            _c(
                                              "ul",
                                              _vm._l(
                                                _vm.basicInfo.categoryObj
                                                  .depth2list,
                                                function (item) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: item.idx,
                                                      class: {
                                                        active:
                                                          _vm.basicInfo
                                                            .depth2Category
                                                            .idx == item.idx,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setCategoryCodeInfo(
                                                            "2",
                                                            item.idx
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "javascript:void(0);",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.value)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]),
                                        ]),
                                        _c("td", [
                                          _c("div", { staticClass: "list" }, [
                                            _c(
                                              "ul",
                                              _vm._l(
                                                _vm.basicInfo.categoryObj
                                                  .depth3list,
                                                function (item) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: item.idx,
                                                      class: {
                                                        active:
                                                          _vm.basicInfo
                                                            .depth3Category
                                                            .idx == item.idx,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setCategoryCodeInfo(
                                                            "3",
                                                            item.idx
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "javascript:void(0);",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.value)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]),
                                        ]),
                                        _c("td", [
                                          _c("div", { staticClass: "list" }, [
                                            _c(
                                              "ul",
                                              _vm._l(
                                                _vm.basicInfo.categoryObj
                                                  .depth4list,
                                                function (item) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: item.idx,
                                                      class: {
                                                        active:
                                                          _vm.basicInfo
                                                            .depth4Category
                                                            .idx == item.idx,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setCategoryCodeInfo(
                                                            "4",
                                                            item.idx
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "javascript:void(0);",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.value)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "category-selected" },
                                  [
                                    _c(
                                      "ul",
                                      _vm._l(
                                        _vm.basicInfo.goodsCategoryList,
                                        function (item, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectCategory(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("input", {
                                                staticClass: "circle",
                                                attrs: {
                                                  type: "radio",
                                                  name: "cate-selD",
                                                  id: "isrepreD_" + index,
                                                  "data-auth": "category",
                                                },
                                                domProps: {
                                                  checked: item.isrepre == "T",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.setIsrepreCategory(
                                                      item
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "isrepreD_" + index,
                                                  },
                                                },
                                                [_vm._v("대표")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "box",
                                                  class: {
                                                    blue:
                                                      item.isselected === "T",
                                                  },
                                                },
                                                [_vm._v(" 선택카테고리 ")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "ml10",
                                                  class: {
                                                    "txt-blue":
                                                      item.isselected === "T",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.fullcategoryname
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _vm.isWrite
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "left-bar",
                                                      attrs: {
                                                        "data-auth": "category",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: item.isstar,
                                                            expression:
                                                              "item.isstar",
                                                          },
                                                        ],
                                                        staticClass: "star",
                                                        attrs: {
                                                          type: "checkbox",
                                                          "true-value": "T",
                                                          "false-value": "F",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              item.isstar
                                                            )
                                                              ? _vm._i(
                                                                  item.isstar,
                                                                  null
                                                                ) > -1
                                                              : _vm._q(
                                                                  item.isstar,
                                                                  "T"
                                                                ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.saveCategoryTemp(
                                                              item
                                                            )
                                                          },
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                item.isstar,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? "T"
                                                                : "F"
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    item,
                                                                    "isstar",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    item,
                                                                    "isstar",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                item,
                                                                "isstar",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "left-bar",
                                                  attrs: {
                                                    "data-auth": "category",
                                                  },
                                                },
                                                [
                                                  _c("button", {
                                                    staticClass: "del",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeGoodsCategory(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              index == 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "txt-orange left-bar small-txt",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "icon-alert",
                                                      }),
                                                      _vm._v(
                                                        " 별 아이콘 체크 시 [카테고리템플릿]에 저장됩니다. "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "fieldset",
                          {
                            attrs: {
                              disabled:
                                !_vm.iscopy &&
                                _vm.isPartner &&
                                _vm.editauth.goodsname.isauth === false,
                            },
                          },
                          [
                            _c("legend", [_vm._v("상품명 수정권한")]),
                            _c("dl", [
                              _vm._m(5),
                              _c("dd", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.basicInfo.goodsname,
                                      expression: "basicInfo.goodsname",
                                    },
                                  ],
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "text",
                                    value: "",
                                    placeholder: "상품명을 입력하세요!",
                                    maxlength: 250,
                                  },
                                  domProps: { value: _vm.basicInfo.goodsname },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.basicInfo,
                                        "goodsname",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "fieldset",
                          {
                            attrs: {
                              disabled:
                                !_vm.iscopy &&
                                _vm.isPartner &&
                                _vm.editauth.summary.isauth === false,
                            },
                          },
                          [
                            _c("legend", [_vm._v("상품요약설명 수정권한")]),
                            _c("dl", [
                              _c("dt", [_vm._v("상품요약설명")]),
                              _c("dd", [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.basicInfo.summary,
                                      expression: "basicInfo.summary",
                                    },
                                  ],
                                  staticStyle: { width: "100%" },
                                  attrs: { maxlength: 250 },
                                  domProps: { value: _vm.basicInfo.summary },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.basicInfo,
                                        "summary",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]
                        ),
                        _c("dl", [
                          _c("dt", [_vm._v("검색키워드")]),
                          _c("dd", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.basicInfo.keyword,
                                  expression: "basicInfo.keyword",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "text",
                                value: "",
                                placeholder: "검색어는 ,(콤마)로 구분됩니다.",
                                maxlength: 100,
                              },
                              domProps: { value: _vm.basicInfo.keyword },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.basicInfo,
                                    "keyword",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c(
                            "dd",
                            {
                              staticClass: "dd-right",
                              staticStyle: { width: "105px" },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(_vm.basicInfo.keyword.length) +
                                    "/100)"
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("dl", [
                          _vm._m(6),
                          _c("dd", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.basicInfo.brandname,
                                  expression: "basicInfo.brandname",
                                },
                              ],
                              staticStyle: { width: "220px" },
                              attrs: {
                                type: "search",
                                placeholder: "브랜드명을 검색하세요.",
                                maxlength: 200,
                                disabled: _vm.basicInfo.isnobrand == "T",
                              },
                              domProps: { value: _vm.basicInfo.brandname },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.openSearchBrandPopup.apply(
                                    null,
                                    arguments
                                  )
                                },
                                change: function ($event) {
                                  _vm.basicInfo.brandidx = ""
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.basicInfo,
                                    "brandname",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "button",
                              {
                                staticClass: "btn-search",
                                attrs: { type: "button" },
                                on: { click: _vm.openSearchBrandPopup },
                              },
                              [_vm._v(" 검색 ")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.basicInfo.isnobrand,
                                  expression: "basicInfo.isnobrand",
                                },
                              ],
                              staticClass: "ml10",
                              attrs: {
                                type: "checkbox",
                                id: "no-brandD",
                                "true-value": "T",
                                "false-value": "F",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.basicInfo.isnobrand)
                                  ? _vm._i(_vm.basicInfo.isnobrand, null) > -1
                                  : _vm._q(_vm.basicInfo.isnobrand, "T"),
                              },
                              on: {
                                click: _vm.initBrandInfo,
                                change: function ($event) {
                                  var $$a = _vm.basicInfo.isnobrand,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "T" : "F"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.basicInfo,
                                          "isnobrand",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.basicInfo,
                                          "isnobrand",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.basicInfo, "isnobrand", $$c)
                                  }
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "no-brandD" } }, [
                              _vm._v("브랜드 없음"),
                            ]),
                            _vm.basicInfo.isnobrand == "T"
                              ? _c(
                                  "span",
                                  { staticClass: "left-bar txt-red" },
                                  [_vm._v("설정안함")]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        _c("dl", [
                          _vm._m(7),
                          _c("dd", [
                            _vm.isPartner
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.basicInfo.mdsword,
                                      expression: "basicInfo.mdsword",
                                    },
                                  ],
                                  staticStyle: { width: "220px" },
                                  attrs: {
                                    type: "search",
                                    placeholder:
                                      "사원이름 또는 메일주소를 입력하세요.",
                                  },
                                  domProps: { value: _vm.basicInfo.mdsword },
                                  on: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.onChargemdShow.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.basicInfo,
                                        "mdsword",
                                        $event.target.value
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.isPartner
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn-search",
                                    attrs: { type: "button" },
                                    on: { click: _vm.onChargemdShow },
                                  },
                                  [_vm._v(" 검색 ")]
                                )
                              : _vm._e(),
                            _vm.isPartner
                              ? _c("input", {
                                  staticStyle: { width: "220px" },
                                  attrs: {
                                    type: "text",
                                    placeholder: "담당MD를 선택하세요.",
                                    readonly: "",
                                    disabled: "",
                                  },
                                  domProps: {
                                    value: _vm.$util.isNull(
                                      _vm.basicInfo.mdsabun
                                    )
                                      ? ""
                                      : "[" +
                                        _vm.basicInfo.mdsabun +
                                        "] " +
                                        _vm.basicInfo.empname,
                                  },
                                })
                              : _vm._e(),
                            !_vm.isPartner
                              ? _c("input", {
                                  staticStyle: { width: "220px" },
                                  attrs: {
                                    type: "search",
                                    placeholder: "담당MD를 선택하세요.",
                                    readonly: "",
                                    disabled: "",
                                  },
                                  domProps: {
                                    value: _vm.$util.isNull(
                                      _vm.basicInfo.mdsabun
                                    )
                                      ? ""
                                      : "[" +
                                        _vm.basicInfo.mdsabun +
                                        "] " +
                                        _vm.basicInfo.empname,
                                  },
                                })
                              : _vm._e(),
                            !_vm.isPartner
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn-search",
                                    attrs: { type: "button" },
                                    on: { click: _vm.onChargemdShow },
                                  },
                                  [_vm._v(" 검색 ")]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showInfo.ismdshow,
                                    expression: "showInfo.ismdshow",
                                  },
                                  {
                                    name: "click-outside",
                                    rawName: "v-click-outside",
                                    value: _vm.closeChargemdLayer,
                                    expression: "closeChargemdLayer",
                                  },
                                ],
                                staticClass: "result-component ml0 no-round",
                                staticStyle: {
                                  display: "block",
                                  width: "700px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "list-header",
                                    staticStyle: { width: "100%" },
                                  },
                                  [
                                    _c(
                                      "table",
                                      {
                                        staticClass: "data-tb align-c mg0",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          cellspacing: "0",
                                          cellpadding: "0",
                                        },
                                      },
                                      [
                                        _vm._m(8),
                                        _c("thead", [
                                          _c("tr", [
                                            _c("th", [_vm._v("선택")]),
                                            _c("th", [_vm._v("No")]),
                                            _c("th", [
                                              _vm._v(" MD사번 "),
                                              _c("button", {
                                                staticClass: "sort",
                                                class: [
                                                  {
                                                    up:
                                                      _vm.sortData.chargemd
                                                        .mdsabun ===
                                                      "mdsabun_asc",
                                                  },
                                                  {
                                                    down:
                                                      _vm.sortData.chargemd
                                                        .mdsabun ===
                                                      "mdsabun_desc",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "button",
                                                  value:
                                                    _vm.sortData.chargemd
                                                      .mdsabun,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sortToggle(
                                                      "chargemd",
                                                      _vm.sortData.chargemd
                                                        .mdsabun
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                            _c("th", [
                                              _vm._v(" 사원이름 "),
                                              _c("button", {
                                                staticClass: "sort",
                                                class: [
                                                  {
                                                    up:
                                                      _vm.sortData.chargemd
                                                        .empname ===
                                                      "empname_asc",
                                                  },
                                                  {
                                                    down:
                                                      _vm.sortData.chargemd
                                                        .empname ===
                                                      "empname_desc",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "button",
                                                  value:
                                                    _vm.sortData.chargemd
                                                      .empname,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sortToggle(
                                                      "chargemd",
                                                      _vm.sortData.chargemd
                                                        .empname
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                            _c("th", [
                                              _vm._v(" MD코드 "),
                                              _c("button", {
                                                staticClass: "sort",
                                                class: [
                                                  {
                                                    up:
                                                      _vm.sortData.chargemd
                                                        .mdcode ===
                                                      "mdcode_asc",
                                                  },
                                                  {
                                                    down:
                                                      _vm.sortData.chargemd
                                                        .mdcode ===
                                                      "mdcode_desc",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "button",
                                                  value:
                                                    _vm.sortData.chargemd
                                                      .mdcode,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sortToggle(
                                                      "chargemd",
                                                      _vm.sortData.chargemd
                                                        .mdcode
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                            _c("th", [
                                              _vm._v(" MD명 "),
                                              _c("button", {
                                                staticClass: "sort",
                                                class: [
                                                  {
                                                    up:
                                                      _vm.sortData.chargemd
                                                        .mdname ===
                                                      "mdname_asc",
                                                  },
                                                  {
                                                    down:
                                                      _vm.sortData.chargemd
                                                        .mdname ===
                                                      "mdname_desc",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "button",
                                                  value:
                                                    _vm.sortData.chargemd
                                                      .mdname,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sortToggle(
                                                      "chargemd",
                                                      _vm.sortData.chargemd
                                                        .mdname
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                            _c("th", [_vm._v("이메일")]),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "list" }, [
                                  _c(
                                    "table",
                                    {
                                      staticClass: "data-tb align-c mg0",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        cellspacing: "0",
                                        cellpadding: "0",
                                      },
                                    },
                                    [
                                      _vm._m(9),
                                      _vm.basicInfo.chargemdList.length > 0
                                        ? _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.basicInfo.chargemdList,
                                              function (item, index) {
                                                return _c(
                                                  "tr",
                                                  { key: item.mdifidx },
                                                  [
                                                    _c("td", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.basicInfo
                                                                .mdifidx,
                                                            expression:
                                                              "basicInfo.mdifidx",
                                                          },
                                                        ],
                                                        staticClass: "circle",
                                                        attrs: {
                                                          type: "radio",
                                                          name: "mdifidxD",
                                                          id:
                                                            "mdifidxD" + index,
                                                        },
                                                        domProps: {
                                                          value: item.mdifidx,
                                                          checked: _vm._q(
                                                            _vm.basicInfo
                                                              .mdifidx,
                                                            item.mdifidx
                                                          ),
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.basicInfo,
                                                                "mdifidx",
                                                                item.mdifidx
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.setChargemdInfo(
                                                                item
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(_vm._s(index + 1)),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.mdsabun)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.empname)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.mdcode)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.mdname)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.mdemail)
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c("tbody", [_vm._m(10)]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                        !_vm.isPartner
                          ? _c("dl", [
                              _c("dt", [_vm._v("적용채널")]),
                              _c(
                                "dd",
                                [
                                  _c("div", { staticClass: "check-wrap" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.basicInfo.isallchannel,
                                          expression: "basicInfo.isallchannel",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: "chkAllChannelD",
                                        "true-value": "T",
                                        "false-value": "F",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.basicInfo.isallchannel
                                        )
                                          ? _vm._i(
                                              _vm.basicInfo.isallchannel,
                                              null
                                            ) > -1
                                          : _vm._q(
                                              _vm.basicInfo.isallchannel,
                                              "T"
                                            ),
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$a =
                                                _vm.basicInfo.isallchannel,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? "T" : "F"
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.basicInfo,
                                                    "isallchannel",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.basicInfo,
                                                    "isallchannel",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.basicInfo,
                                                "isallchannel",
                                                $$c
                                              )
                                            }
                                          },
                                          _vm.checkAllChannel,
                                        ],
                                      },
                                    }),
                                    _c(
                                      "label",
                                      { attrs: { for: "chkAllChannelD" } },
                                      [_vm._v("전체적용")]
                                    ),
                                  ]),
                                  _vm._l(
                                    _vm.commonCode.muappchtype,
                                    function (item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.cmcode,
                                          staticClass: "check-wrap",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.basicInfo.muappchtypeArr,
                                                expression:
                                                  "basicInfo.muappchtypeArr",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              id: "muappchtypeD_" + item.cmcode,
                                              "true-value": [],
                                            },
                                            domProps: {
                                              value: item.cmcode,
                                              checked: Array.isArray(
                                                _vm.basicInfo.muappchtypeArr
                                              )
                                                ? _vm._i(
                                                    _vm.basicInfo
                                                      .muappchtypeArr,
                                                    item.cmcode
                                                  ) > -1
                                                : _vm._q(
                                                    _vm.basicInfo
                                                      .muappchtypeArr,
                                                    []
                                                  ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.basicInfo
                                                      .muappchtypeArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? []
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = item.cmcode,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.basicInfo,
                                                        "muappchtypeArr",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.basicInfo,
                                                        "muappchtypeArr",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.basicInfo,
                                                    "muappchtypeArr",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for:
                                                  "muappchtypeD_" + item.cmcode,
                                              },
                                            },
                                            [_vm._v(_vm._s(item.codename))]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "boxing", attrs: { id: "goodsTab2" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "boxing-title",
                        class: { closed: !_vm.tabObject.goodsTab2.isOpen },
                      },
                      [
                        _vm._v(" 판매 정보 "),
                        _c("i", {
                          staticClass: "arrcodi",
                          on: {
                            click: function ($event) {
                              return _vm.onToggle(_vm.tabObject.goodsTab2)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-area",
                        style: {
                          display: _vm.tabObject.goodsTab2.isOpen
                            ? "block"
                            : "none",
                        },
                      },
                      [
                        _c("dl", [
                          _c("dt", [_vm._v("과세여부")]),
                          _c("dd", [
                            _c("div", { staticClass: "radio_wrap wide" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sellInfo.istaxfree,
                                    expression: "sellInfo.istaxfree",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "istaxfreeD",
                                  id: "istaxfreeDF",
                                  value: "F",
                                  disabled: "",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.sellInfo.istaxfree, "F"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.sellInfo,
                                      "istaxfree",
                                      "F"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "istaxfreeDF" } }, [
                                _vm._v("과세"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sellInfo.istaxfree,
                                    expression: "sellInfo.istaxfree",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "istaxfreeD",
                                  id: "istaxfreeDT",
                                  value: "T",
                                  disabled: "",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.sellInfo.istaxfree, "T"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.sellInfo,
                                      "istaxfree",
                                      "T"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "istaxfreeDT" } }, [
                                _vm._v("면세"),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("dl", [
                          _c("dt", [_vm._v("판매상태")]),
                          _c("dd", [
                            _c(
                              "div",
                              { staticClass: "radio_wrap" },
                              _vm._l(
                                _vm.commonCode.goodsselltype,
                                function (item) {
                                  return _c("div", { key: item.cmcode }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sellInfo.goodsselltype,
                                          expression: "sellInfo.goodsselltype",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "goodsselltypeD",
                                        id: "goodsselltypeD_" + item.cmcode,
                                        disabled:
                                          !_vm.iscopy &&
                                          _vm.isPartner &&
                                          _vm.editauth.soldout.isauth ===
                                            false &&
                                          (item.cmcode ===
                                            _vm.$store.getters["ADMIN"]
                                              .GOODS_SELL_TYPE_GST004 ||
                                            item.cmcode ===
                                              _vm.$store.getters["ADMIN"]
                                                .GOODS_SELL_TYPE_GST005),
                                      },
                                      domProps: {
                                        value: item.cmcode,
                                        checked: _vm._q(
                                          _vm.sellInfo.goodsselltype,
                                          item.cmcode
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.sellInfo,
                                            "goodsselltype",
                                            item.cmcode
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "goodsselltypeD_" + item.cmcode,
                                        },
                                      },
                                      [_vm._v(_vm._s(item.codename))]
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                        _c("dl", [
                          _c("dt", [_vm._v("전시기간")]),
                          _c(
                            "dd",
                            [
                              _c("CommonDatePickerFromTo", {
                                attrs: {
                                  fromYYYYMMDD: _vm.sellInfo.disstdate,
                                  fromHH: _vm.sellInfo.dissthour,
                                  fromMM: _vm.sellInfo.disstmin,
                                  toYYYYMMDD: _vm.sellInfo.diseddate,
                                  toHH: _vm.sellInfo.disedhour,
                                  toMM: _vm.sellInfo.disedmin,
                                  useFrom: true,
                                  useTo: true,
                                },
                                on: { getDate: _vm.pickerChangeEvent },
                              }),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.sellInfo.disperiod,
                                      expression: "sellInfo.disperiod",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.sellInfo,
                                        "disperiod",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { id: "periodD_day", value: "" } },
                                    [_vm._v("선택")]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        id: "periodD_day_1",
                                        value: "day_1",
                                      },
                                    },
                                    [_vm._v("1일")]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        id: "periodD_day_3",
                                        value: "day_3",
                                      },
                                    },
                                    [_vm._v("3일")]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        id: "periodD_day_5",
                                        value: "day_5",
                                      },
                                    },
                                    [_vm._v("5일")]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        id: "periodD_day_7",
                                        value: "day_7",
                                      },
                                    },
                                    [_vm._v("7일")]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        id: "periodD_day_15",
                                        value: "day_15",
                                      },
                                    },
                                    [_vm._v("15일")]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        id: "periodD_month_1",
                                        value: "month_1",
                                      },
                                    },
                                    [_vm._v("1개월")]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        id: "periodD_month_3",
                                        value: "month_3",
                                      },
                                    },
                                    [_vm._v("3개월")]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        id: "periodD_month_6",
                                        value: "month_6",
                                      },
                                    },
                                    [_vm._v("6개월")]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        id: "periodD_all_0",
                                        value: "all_0",
                                      },
                                    },
                                    [_vm._v("상시")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("dl", [
                          _vm._m(11),
                          _c("dd", [
                            _c("span", [_vm._v("정상가")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sellInfo.marketprice,
                                  expression: "sellInfo.marketprice",
                                },
                              ],
                              staticStyle: { width: "120px" },
                              attrs: {
                                type: "text",
                                maxlength: "11",
                                readonly: "",
                              },
                              domProps: { value: _vm.sellInfo.marketprice },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.sellInfo,
                                    "marketprice",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("span", [_vm._v("원")]),
                            _c("span", { staticClass: "left-bar" }, [
                              _vm._v("판매가"),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sellInfo.price,
                                  expression: "sellInfo.price",
                                },
                              ],
                              staticStyle: { width: "120px" },
                              attrs: {
                                type: "text",
                                maxlength: "11",
                                readonly: "",
                              },
                              domProps: { value: _vm.sellInfo.price },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.sellInfo,
                                    "price",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("span", [_vm._v("원")]),
                          ]),
                        ]),
                        _c("dl", [
                          _vm._m(12),
                          _c("dd", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sellInfo.minordcnt,
                                  expression: "sellInfo.minordcnt",
                                },
                              ],
                              staticStyle: { width: "80px" },
                              attrs: { type: "text", maxlength: "11" },
                              domProps: { value: _vm.sellInfo.minordcnt },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.sellInfo,
                                    "minordcnt",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("span", [_vm._v("개 부터 ~")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sellInfo.maxordcnt,
                                  expression: "sellInfo.maxordcnt",
                                },
                              ],
                              staticStyle: { width: "80px" },
                              attrs: { type: "text", maxlength: "11" },
                              domProps: { value: _vm.sellInfo.maxordcnt },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.sellInfo,
                                    "maxordcnt",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("span", [_vm._v("개 이내")]),
                          ]),
                        ]),
                        _c("dl", [
                          _vm._m(13),
                          _c("dd", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sellInfo.daymaxordcnt,
                                  expression: "sellInfo.daymaxordcnt",
                                },
                              ],
                              staticStyle: { width: "80px" },
                              attrs: { type: "text", maxlength: "11" },
                              domProps: { value: _vm.sellInfo.daymaxordcnt },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.sellInfo,
                                    "daymaxordcnt",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("span", [_vm._v("개 까지 구매가능")]),
                          ]),
                        ]),
                        _c("dl", [
                          _vm._m(14),
                          _c("dd", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sellInfo.permaxordcnt,
                                  expression: "sellInfo.permaxordcnt",
                                },
                              ],
                              staticStyle: { width: "80px" },
                              attrs: { type: "text", maxlength: "11" },
                              domProps: { value: _vm.sellInfo.permaxordcnt },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.sellInfo,
                                    "permaxordcnt",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("span", [_vm._v("개 까지 구매가능 (기간없이)")]),
                          ]),
                        ]),
                        _c(
                          "fieldset",
                          {
                            attrs: {
                              disabled:
                                !_vm.iscopy &&
                                _vm.isPartner &&
                                _vm.editauth.display.isauth === false,
                            },
                          },
                          [
                            _c("legend", [_vm._v("전시 여부 수정권한")]),
                            _c("dl", [
                              _c("dt", [_vm._v("전시 여부")]),
                              _c("dd", [
                                _c("div", { staticClass: "radio_wrap wide" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.sellInfo.isdisplay,
                                        expression: "sellInfo.isdisplay",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "isdisplayD",
                                      id: "isdisplayDT",
                                      value: "T",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.sellInfo.isdisplay,
                                        "T"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.sellInfo,
                                          "isdisplay",
                                          "T"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "isdisplayDT" } },
                                    [_vm._v("전시")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.sellInfo.isdisplay,
                                        expression: "sellInfo.isdisplay",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "isdisplayD",
                                      id: "isdisplayDF",
                                      value: "F",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.sellInfo.isdisplay,
                                        "F"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.sellInfo,
                                          "isdisplay",
                                          "F"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "isdisplayDF" } },
                                    [_vm._v("미 전시")]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                        !_vm.isPartner
                          ? _c("dl", [
                              _c("dt", [_vm._v("첫 구매시 할인혜택여부")]),
                              _c("dd", [
                                _c("div", { staticClass: "radio_wrap wide" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.sellInfo.isfrstsale,
                                        expression: "sellInfo.isfrstsale",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "isfrstsaleD",
                                      id: "isfrstsaleDT",
                                      value: "T",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.sellInfo.isfrstsale,
                                        "T"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.sellInfo,
                                          "isfrstsale",
                                          "T"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "isfrstsaleDT" } },
                                    [_vm._v("적용")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.sellInfo.isfrstsale,
                                        expression: "sellInfo.isfrstsale",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "isfrstsaleD",
                                      id: "isfrstsaleDF",
                                      value: "F",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.sellInfo.isfrstsale,
                                        "F"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.sellInfo,
                                          "isfrstsale",
                                          "F"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "isfrstsaleDF" } },
                                    [_vm._v("미 적용")]
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("dl", [
                              _c("dt", [_vm._v("취소시 판매자 승인여부")]),
                              _c("dd", [
                                _c("div", { staticClass: "radio_wrap wide" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.sellInfo.iscncappr,
                                        expression: "sellInfo.iscncappr",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "iscncappr",
                                      id: "iscncapprDF",
                                      value: "F",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.sellInfo.iscncappr,
                                        "F"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.sellInfo,
                                          "iscncappr",
                                          "F"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "iscncapprDF" } },
                                    [_vm._v("필요없음")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.sellInfo.iscncappr,
                                        expression: "sellInfo.iscncappr",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "iscncappr",
                                      id: "iscncapprDT",
                                      value: "T",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.sellInfo.iscncappr,
                                        "T"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.sellInfo,
                                          "iscncappr",
                                          "T"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "iscncapprDT" } },
                                    [_vm._v("승인필요")]
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _c("dl", [
                          _c("dt", [_vm._v("상품상태")]),
                          _c("dd", [
                            _c(
                              "div",
                              { staticClass: "radio_wrap" },
                              _vm._l(
                                _vm.commonCode.goodsdivtype,
                                function (item) {
                                  return _c("div", { key: item.cmcode }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sellInfo.goodsdivtype,
                                          expression: "sellInfo.goodsdivtype",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "goodsdivtypeD",
                                        id: "goodsdivtypeD_" + item.cmcode,
                                      },
                                      domProps: {
                                        value: item.cmcode,
                                        checked: _vm._q(
                                          _vm.sellInfo.goodsdivtype,
                                          item.cmcode
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.sellInfo,
                                            "goodsdivtype",
                                            item.cmcode
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "goodsdivtypeD_" + item.cmcode,
                                        },
                                      },
                                      [_vm._v(_vm._s(item.codename))]
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                        !_vm.isPartner
                          ? _c("dl", [
                              _c("dt", [_vm._v("판매대상")]),
                              _c("dd", [
                                _c("div", { staticClass: "radio_wrap" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.sellInfo.selltarget,
                                        expression: "sellInfo.selltarget",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "selltargetD",
                                      id: "selltargetD_all",
                                      value: "ALL",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.sellInfo.selltarget,
                                        "ALL"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.sellInfo,
                                          "selltarget",
                                          "ALL"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "selltargetD_all" } },
                                    [_vm._v("전체회원")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.sellInfo.selltarget,
                                        expression: "sellInfo.selltarget",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "selltargetD",
                                      id: "selltargetD_type",
                                      value: "TYPE",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.sellInfo.selltarget,
                                        "TYPE"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.sellInfo,
                                          "selltarget",
                                          "TYPE"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "selltargetD_type" } },
                                    [_vm._v("등급별")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.sellInfo.selltarget,
                                        expression: "sellInfo.selltarget",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "selltargetD",
                                      id: "selltargetD_sp",
                                      value: "SP",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.sellInfo.selltarget,
                                        "SP"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.sellInfo,
                                          "selltarget",
                                          "SP"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "selltargetD_sp" } },
                                    [_vm._v("특정회원")]
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        !_vm.isPartner && _vm.sellInfo.selltarget == "TYPE"
                          ? _c("dl", [
                              _c("dt", [_vm._v("등급별")]),
                              _c(
                                "dd",
                                [
                                  _c("div", { staticClass: "check-wrap" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sellInfo.isallmemlv,
                                          expression: "sellInfo.isallmemlv",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: "chkAllMemlvD",
                                        "true-value": "T",
                                        "false-value": "F",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.sellInfo.isallmemlv
                                        )
                                          ? _vm._i(
                                              _vm.sellInfo.isallmemlv,
                                              null
                                            ) > -1
                                          : _vm._q(
                                              _vm.sellInfo.isallmemlv,
                                              "T"
                                            ),
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$a = _vm.sellInfo.isallmemlv,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? "T" : "F"
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.sellInfo,
                                                    "isallmemlv",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.sellInfo,
                                                    "isallmemlv",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.sellInfo,
                                                "isallmemlv",
                                                $$c
                                              )
                                            }
                                          },
                                          _vm.checkAllMemlvtype,
                                        ],
                                      },
                                    }),
                                    _c(
                                      "label",
                                      { attrs: { for: "chkAllMemlvD" } },
                                      [_vm._v("전체")]
                                    ),
                                  ]),
                                  _vm._l(
                                    _vm.commonCode.memlvtype,
                                    function (item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.cmcode,
                                          staticClass: "check-wrap",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.sellInfo.mumemlvtypeArr,
                                                expression:
                                                  "sellInfo.mumemlvtypeArr",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              id: "mumemlvtypeD_" + item.cmcode,
                                              "true-value": [],
                                            },
                                            domProps: {
                                              value: item.cmcode,
                                              checked: Array.isArray(
                                                _vm.sellInfo.mumemlvtypeArr
                                              )
                                                ? _vm._i(
                                                    _vm.sellInfo.mumemlvtypeArr,
                                                    item.cmcode
                                                  ) > -1
                                                : _vm._q(
                                                    _vm.sellInfo.mumemlvtypeArr,
                                                    []
                                                  ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.sellInfo.mumemlvtypeArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? []
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = item.cmcode,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.sellInfo,
                                                        "mumemlvtypeArr",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.sellInfo,
                                                        "mumemlvtypeArr",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.sellInfo,
                                                    "mumemlvtypeArr",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for:
                                                  "mumemlvtypeD_" + item.cmcode,
                                              },
                                            },
                                            [_vm._v(_vm._s(item.codename))]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                        !_vm.isPartner && _vm.sellInfo.selltarget == "SP"
                          ? _c("dl", [
                              _c("dt", [_vm._v("특정회원")]),
                              _c("dd", { staticClass: "full" }, [
                                _c(
                                  "div",
                                  { staticClass: "caption-group clearfix dpb" },
                                  [
                                    _vm._m(15),
                                    _c("div", { staticClass: "btn-group fr" }, [
                                      _c("input", {
                                        ref: "spUserExcelFile",
                                        attrs: {
                                          type: "file",
                                          hidden: "",
                                          accept:
                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.readExcelFile(
                                              "spUserExcelFile",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn black-line",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadExcelTemplate(
                                                "MemberTemplate.xlsx"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 양식 다운로드 ")]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn green-line",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.fileAttach(
                                                "spUserExcelFile"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 엑셀파일 올리기 ")]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn blue-line",
                                          attrs: { type: "button" },
                                          on: { click: _vm.openAddUserPopup },
                                        },
                                        [_vm._v(" 회원추가 ")]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn red-line",
                                          attrs: { type: "button" },
                                          on: { click: _vm.removeUser },
                                        },
                                        [_vm._v("삭제")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "scroll-y",
                                    staticStyle: {
                                      width: "100%",
                                      "max-height": "400px",
                                    },
                                  },
                                  [
                                    _c(
                                      "table",
                                      {
                                        staticClass: "data-tb align-c",
                                        staticStyle: { "margin-left": "0" },
                                        attrs: {
                                          cellpadding: "0",
                                          cellspacing: "0",
                                        },
                                      },
                                      [
                                        _c("caption", [_vm._v("특정회원목록")]),
                                        _vm._m(16),
                                        _c("thead", [
                                          _c("tr", [
                                            _c("th", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.sellInfo.isallchkmem,
                                                    expression:
                                                      "sellInfo.isallchkmem",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id: "chkallgoodsmemD",
                                                  "true-value": "T",
                                                  "false-value": "F",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.sellInfo.isallchkmem
                                                  )
                                                    ? _vm._i(
                                                        _vm.sellInfo
                                                          .isallchkmem,
                                                        null
                                                      ) > -1
                                                    : _vm._q(
                                                        _vm.sellInfo
                                                          .isallchkmem,
                                                        "T"
                                                      ),
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$a =
                                                          _vm.sellInfo
                                                            .isallchkmem,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? "T"
                                                          : "F"
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.sellInfo,
                                                              "isallchkmem",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.sellInfo,
                                                              "isallchkmem",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.sellInfo,
                                                          "isallchkmem",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function ($event) {
                                                      return _vm.checkAllMemberList(
                                                        $event.target.checked
                                                      )
                                                    },
                                                  ],
                                                },
                                              }),
                                            ]),
                                            _c("th", [_vm._v("No")]),
                                            _c("th", [_vm._v("아이디")]),
                                            _c("th", [_vm._v("이름")]),
                                            _c("th", [
                                              _vm._v(" 유형 "),
                                              _c("button", {
                                                staticClass: "sort",
                                                class: [
                                                  {
                                                    up:
                                                      _vm.sortData.goodsMember
                                                        .membertype ===
                                                      "membertype_asc",
                                                  },
                                                  {
                                                    down:
                                                      _vm.sortData.goodsMember
                                                        .membertype ===
                                                      "membertype_desc",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "button",
                                                  value:
                                                    _vm.sortData.goodsMember
                                                      .membertype,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sortToggle(
                                                      "goodsMember",
                                                      _vm.sortData.goodsMember
                                                        .membertype
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                            _c("th", [
                                              _vm._v(" 등급 "),
                                              _c("button", {
                                                staticClass: "sort",
                                                class: [
                                                  {
                                                    up:
                                                      _vm.sortData.goodsMember
                                                        .memlv === "memlv_asc",
                                                  },
                                                  {
                                                    down:
                                                      _vm.sortData.goodsMember
                                                        .memlv === "memlv_desc",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "button",
                                                  value:
                                                    _vm.sortData.goodsMember
                                                      .memlv,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sortToggle(
                                                      "goodsMember",
                                                      _vm.sortData.goodsMember
                                                        .memlv
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                            _c("th", [
                                              _vm._v(" 가입일 "),
                                              _c("button", {
                                                staticClass: "sort",
                                                class: [
                                                  {
                                                    up:
                                                      _vm.sortData.goodsMember
                                                        .regdate ===
                                                      "regdate_asc",
                                                  },
                                                  {
                                                    down:
                                                      _vm.sortData.goodsMember
                                                        .regdate ===
                                                      "regdate_desc",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "button",
                                                  value:
                                                    _vm.sortData.goodsMember
                                                      .regdate,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sortToggle(
                                                      "goodsMember",
                                                      _vm.sortData.goodsMember
                                                        .regdate
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                          ]),
                                        ]),
                                        _vm.sellInfo.goodsMemberList.length > 0
                                          ? _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.sellInfo.goodsMemberList,
                                                function (item, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: item.userno },
                                                    [
                                                      _c("td", [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.ischecked,
                                                              expression:
                                                                "item.ischecked",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "checkbox",
                                                            id:
                                                              "goodsMember_" +
                                                              index,
                                                          },
                                                          domProps: {
                                                            checked:
                                                              Array.isArray(
                                                                item.ischecked
                                                              )
                                                                ? _vm._i(
                                                                    item.ischecked,
                                                                    null
                                                                  ) > -1
                                                                : item.ischecked,
                                                          },
                                                          on: {
                                                            change: [
                                                              function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    item.ischecked,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        item,
                                                                        "ischecked",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        item,
                                                                        "ischecked",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    item,
                                                                    "ischecked",
                                                                    $$c
                                                                  )
                                                                }
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.checkMemberList(
                                                                  $event.target
                                                                    .checked
                                                                )
                                                              },
                                                            ],
                                                          },
                                                        }),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(index + 1)
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.userid)
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.username)
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.dadamembertypename
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.memlvtypename
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.regdate)
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _c("tbody", [_vm._m(17)]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "boxing", attrs: { id: "goodsTab3" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "boxing-title",
                        class: { closed: !_vm.tabObject.goodsTab3.isOpen },
                      },
                      [
                        _vm._v(" 옵션 정보 "),
                        _c("i", {
                          staticClass: "arrcodi",
                          on: {
                            click: function ($event) {
                              return _vm.onToggle(_vm.tabObject.goodsTab3)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-area",
                        style: {
                          display: _vm.tabObject.goodsTab3.isOpen
                            ? "block"
                            : "none",
                        },
                      },
                      [
                        _vm.basicInfo.ispbgoods == "F"
                          ? _c("div", { staticClass: "blue-box" }, [
                              _vm._m(18),
                              !_vm.isPartner
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn big blue mt20",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadExcelTemplate(
                                            "AdminGoodsOptionTemplate.xlsx"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 양식 다운로드 ")]
                                  )
                                : _vm._e(),
                              _vm.isPartner
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn big blue mt20",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadExcelTemplate(
                                            "PartnerGoodsOptionTemplate.xlsx"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 양식 다운로드 ")]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "mt10" }, [
                                _c("input", {
                                  ref: "optionExcelFile",
                                  attrs: {
                                    type: "file",
                                    hidden: "",
                                    accept:
                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleFileUpload(
                                        "optionExcelFile"
                                      )
                                    },
                                  },
                                }),
                                _c("input", {
                                  ref: "optionExcelFileName",
                                  attrs: { type: "text", readonly: "" },
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn blue-line ml3",
                                    attrs: {
                                      type: "button",
                                      disabled:
                                        _vm.isPartner &&
                                        !_vm.editauth.option.isauth,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.fileAttach("optionExcelFile")
                                      },
                                    },
                                  },
                                  [_vm._v(" 파일찾기 ")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn blue-line",
                                    attrs: {
                                      type: "button",
                                      disabled:
                                        _vm.isPartner &&
                                        !_vm.editauth.option.isauth,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.readExcelFile(
                                          "optionExcelFile"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 일괄등록 ")]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.basicInfo.ispbgoods == "F"
                          ? _c("div", { staticStyle: { margin: "20px" } }, [
                              _c(
                                "fieldset",
                                {
                                  attrs: {
                                    disabled:
                                      !_vm.iscopy &&
                                      _vm.isPartner &&
                                      _vm.editauth.option.isauth === false,
                                  },
                                },
                                [
                                  _c("legend", [_vm._v("옵션 수정권한")]),
                                  _c(
                                    "div",
                                    { staticClass: "caption-group clearfix" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "total-group fl" },
                                        [
                                          _c("span", { staticClass: "total" }, [
                                            _vm._v(" 전체 "),
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.optionInfo.optionList
                                                    .length
                                                )
                                              ),
                                            ]),
                                            _vm._v(" 건 "),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "btn-group fr" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn blue-line ml3",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setOptDisplay("T")
                                                },
                                              },
                                            },
                                            [_vm._v(" 노출 ")]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn red-line",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setOptDisplay("F")
                                                },
                                              },
                                            },
                                            [_vm._v(" 숨김 ")]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn blue-line",
                                              attrs: { type: "button" },
                                              on: { click: _vm.addOption },
                                            },
                                            [_vm._v(" 행 추가 ")]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn red-line",
                                              attrs: { type: "button" },
                                              on: { click: _vm.removeOption },
                                            },
                                            [_vm._v(" 행 삭제 ")]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn blue-line",
                                              attrs: { type: "button" },
                                              on: {
                                                click: _vm.openOtionItemPopup,
                                              },
                                            },
                                            [_vm._v(" 항목관리 ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "scroll-y",
                                  staticStyle: {
                                    width: "100%",
                                    "max-height": "500px",
                                  },
                                },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass: "data-tb align-c",
                                      staticStyle: { "margin-left": "0" },
                                      attrs: {
                                        cellpadding: "0",
                                        cellspacing: "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "colgroup",
                                        [
                                          _c("col", { attrs: { width: "3%" } }),
                                          _c("col", { attrs: { width: "8%" } }),
                                          _c("col", { attrs: { width: "8%" } }),
                                          _c("col", { attrs: { width: "6%" } }),
                                          _c("col", { attrs: { width: "6%" } }),
                                          _c("col", { attrs: { width: "8%" } }),
                                          _c("col", { attrs: { width: "8%" } }),
                                          _c("col", { attrs: { width: "6%" } }),
                                          _c("col", { attrs: { width: "6%" } }),
                                          _vm._l(
                                            _vm.optionInfo.optionItemList,
                                            function (item, itemIdx) {
                                              return _c("col", {
                                                key: itemIdx,
                                                attrs: { width: "" },
                                              })
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _c("thead", [
                                        _c(
                                          "tr",
                                          [
                                            _c("th", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.optionInfo
                                                        .isallchkopt,
                                                    expression:
                                                      "optionInfo.isallchkopt",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id: "chkalloptD",
                                                  "true-value": "T",
                                                  "false-value": "F",
                                                  disabled:
                                                    _vm.editauth.option
                                                      .isauth === false,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.optionInfo.isallchkopt
                                                  )
                                                    ? _vm._i(
                                                        _vm.optionInfo
                                                          .isallchkopt,
                                                        null
                                                      ) > -1
                                                    : _vm._q(
                                                        _vm.optionInfo
                                                          .isallchkopt,
                                                        "T"
                                                      ),
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$a =
                                                          _vm.optionInfo
                                                            .isallchkopt,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? "T"
                                                          : "F"
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.optionInfo,
                                                              "isallchkopt",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.optionInfo,
                                                              "isallchkopt",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.optionInfo,
                                                          "isallchkopt",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function ($event) {
                                                      return _vm.checkAllOptionList(
                                                        $event.target.checked
                                                      )
                                                    },
                                                  ],
                                                },
                                              }),
                                            ]),
                                            _c("th", [_vm._v("옵션코드")]),
                                            _c("th", [_vm._v("상품품목코드")]),
                                            _c("th", [_vm._v("대표상품")]),
                                            _c("th", [_vm._v("옵션상태")]),
                                            _c("th", [_vm._v("정상가")]),
                                            _c("th", [_vm._v("판매가")]),
                                            _c("th", [_vm._v("수수료율(%)")]),
                                            _c("th", [_vm._v("재고")]),
                                            _vm._l(
                                              _vm.optionInfo.optionItemList,
                                              function (item, itemIdx) {
                                                return _c(
                                                  "th",
                                                  {
                                                    key: itemIdx,
                                                    staticClass: "bg-yellow",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.optionname
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]),
                                      _vm.optionInfo.optionList.length > 0
                                        ? _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.optionInfo.optionList,
                                              function (item, index) {
                                                return _c(
                                                  "tr",
                                                  { key: index },
                                                  [
                                                    _c("td", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              item.ischecked,
                                                            expression:
                                                              "item.ischecked",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "checkbox",
                                                          id:
                                                            "chkoptD_" +
                                                            item.optionno,
                                                          disabled:
                                                            _vm.editauth.option
                                                              .isauth === false,
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              item.ischecked
                                                            )
                                                              ? _vm._i(
                                                                  item.ischecked,
                                                                  null
                                                                ) > -1
                                                              : item.ischecked,
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$a =
                                                                  item.ischecked,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      item,
                                                                      "ischecked",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      item,
                                                                      "ischecked",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  item,
                                                                  "ischecked",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                            function ($event) {
                                                              return _vm.checkOptionList(
                                                                $event.target
                                                                  .checked
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.optioncode)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.optionitemcode
                                                        )
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _c("input", {
                                                        staticClass: "circle",
                                                        attrs: {
                                                          type: "radio",
                                                          name: "ismaingoodsD",
                                                          disabled:
                                                            _vm.editauth.option
                                                              .isauth === false,
                                                        },
                                                        domProps: {
                                                          checked:
                                                            item.ismaingoods ==
                                                            "T",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.setIsMainGoods(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]),
                                                    item.isoptdisplay == "T"
                                                      ? _c("td", [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn blue",
                                                              attrs: {
                                                                type: "button",
                                                                disabled:
                                                                  _vm.editauth
                                                                    .option
                                                                    .isauth ===
                                                                  false,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeOptDisplay(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v(" 노출 ")]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    item.isoptdisplay == "F"
                                                      ? _c("td", [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn red",
                                                              attrs: {
                                                                type: "button",
                                                                disabled:
                                                                  _vm.editauth
                                                                    .option
                                                                    .isauth ===
                                                                  false,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeOptDisplay(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v(" 숨김 ")]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _c(
                                                      "td",
                                                      { staticClass: "right" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.marketprice,
                                                              expression:
                                                                "item.marketprice",
                                                            },
                                                          ],
                                                          staticClass: "right",
                                                          attrs: {
                                                            type: "text",
                                                            maxlength: "11",
                                                            disabled:
                                                              !_vm.iscopy &&
                                                              _vm.isPartner &&
                                                              _vm.editauth
                                                                .marketprice
                                                                .isauth ===
                                                                false,
                                                          },
                                                          domProps: {
                                                            value:
                                                              item.marketprice,
                                                          },
                                                          on: {
                                                            input: [
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  item,
                                                                  "marketprice",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.changeMarketprice(
                                                                  item
                                                                )
                                                              },
                                                            ],
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      { staticClass: "right" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value: item.price,
                                                              expression:
                                                                "item.price",
                                                            },
                                                          ],
                                                          staticClass: "right",
                                                          attrs: {
                                                            type: "text",
                                                            maxlength: "11",
                                                            disabled:
                                                              !_vm.iscopy &&
                                                              _vm.isPartner &&
                                                              _vm.editauth.price
                                                                .isauth ===
                                                                false,
                                                          },
                                                          domProps: {
                                                            value: item.price,
                                                          },
                                                          on: {
                                                            input: [
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  item,
                                                                  "price",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.changePrice(
                                                                  item
                                                                )
                                                              },
                                                            ],
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c("td", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              item.commrate,
                                                            expression:
                                                              "item.commrate",
                                                          },
                                                        ],
                                                        staticClass: "center",
                                                        attrs: {
                                                          type: "text",
                                                          maxlength: "5",
                                                          disabled:
                                                            _vm.isPartner ||
                                                            (!_vm.iscopy &&
                                                              _vm.isPartner &&
                                                              _vm.editauth
                                                                .commrate
                                                                .isauth ===
                                                                false),
                                                        },
                                                        domProps: {
                                                          value: item.commrate,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            _vm.chgInfo.chgOpt =
                                                              "T"
                                                          },
                                                          input: [
                                                            function ($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                item,
                                                                "commrate",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.checkInputRate(
                                                                item,
                                                                "commrate",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                    ]),
                                                    _c("td", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              item.stockcnt,
                                                            expression:
                                                              "item.stockcnt",
                                                          },
                                                        ],
                                                        staticClass: "center",
                                                        attrs: {
                                                          type: "text",
                                                          maxlength: "11",
                                                          disabled:
                                                            !_vm.iscopy &&
                                                            _vm.isPartner &&
                                                            _vm.editauth
                                                              .stockcnt
                                                              .isauth === false,
                                                          oninput:
                                                            "this.value = this.value.replace(/(^0[0-9]|[^0-9])/gi, '');",
                                                        },
                                                        domProps: {
                                                          value: item.stockcnt,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            _vm.chgInfo.chgOpt =
                                                              "T"
                                                          },
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              item,
                                                              "stockcnt",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]),
                                                    _vm._l(
                                                      _vm.optionInfo
                                                        .optionItemList,
                                                      function (item, itemIdx) {
                                                        return _c(
                                                          "td",
                                                          { key: itemIdx },
                                                          [
                                                            _c("input", {
                                                              ref:
                                                                "optionnm" +
                                                                item.sort +
                                                                "_" +
                                                                index,
                                                              refInFor: true,
                                                              staticClass:
                                                                "center",
                                                              attrs: {
                                                                type: "text",
                                                                maxlength:
                                                                  "100",
                                                                disabled:
                                                                  _vm.editauth
                                                                    .option
                                                                    .isauth ===
                                                                  false,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.chgInfo.chgOpt =
                                                                      "T"
                                                                  },
                                                              },
                                                            }),
                                                            _c("input", {
                                                              ref:
                                                                "optionno" +
                                                                item.sort +
                                                                "_" +
                                                                index,
                                                              refInFor: true,
                                                              staticClass:
                                                                "center",
                                                              attrs: {
                                                                type: "hidden",
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c("tbody", [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  attrs: {
                                                    colspan:
                                                      8 +
                                                      _vm.optionInfo
                                                        .optionItemList.length,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " 조회 결과가 존재하지 않습니다. "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.basicInfo.ispbgoods == "T"
                          ? _c("div", { staticClass: "blue-box" }, [
                              _vm._m(19),
                              _c(
                                "button",
                                {
                                  staticClass: "btn big blue mt20",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadExcelTemplate(
                                        "PbGoodsOptionTemplate.xlsx"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 양식 다운로드 ")]
                              ),
                              _c("div", { staticClass: "mt10" }, [
                                _c("input", {
                                  ref: "optionExcelFile",
                                  attrs: {
                                    type: "file",
                                    hidden: "",
                                    accept:
                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleFileUpload(
                                        "optionExcelFile"
                                      )
                                    },
                                  },
                                }),
                                _c("input", {
                                  ref: "optionExcelFileName",
                                  attrs: { type: "text", readonly: "" },
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn blue-line ml3",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.fileAttach("optionExcelFile")
                                      },
                                    },
                                  },
                                  [_vm._v(" 파일찾기 ")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn blue-line",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.readExcelFile(
                                          "pbOptionExcelFile"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 일괄등록 ")]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.basicInfo.ispbgoods == "T"
                          ? _c("div", { staticStyle: { margin: "20px" } }, [
                              _c(
                                "div",
                                { staticClass: "caption-group clearfix" },
                                [
                                  _c("div", { staticClass: "total-group fl" }, [
                                    _c("span", { staticClass: "total" }, [
                                      _vm._v(" 전체 "),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.optionInfo.optionList.length
                                          )
                                        ),
                                      ]),
                                      _vm._v(" 건 "),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "btn-group fr" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn blue-line ml3",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setOptDisplay("T")
                                          },
                                        },
                                      },
                                      [_vm._v(" 노출 ")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn red-line",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setOptDisplay("F")
                                          },
                                        },
                                      },
                                      [_vm._v(" 숨김 ")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn blue-line",
                                        attrs: { type: "button" },
                                        on: { click: _vm.addOption },
                                      },
                                      [_vm._v("행 추가")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn red-line",
                                        attrs: { type: "button" },
                                        on: { click: _vm.removeOption },
                                      },
                                      [_vm._v(" 행 삭제 ")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn blue-line",
                                        attrs: { type: "button" },
                                        on: { click: _vm.openOtionItemPopup },
                                      },
                                      [_vm._v(" 항목관리 ")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "scroll-y",
                                  staticStyle: {
                                    width: "100%",
                                    "max-height": "500px",
                                  },
                                },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass: "data-tb align-c",
                                      staticStyle: { "margin-left": "0" },
                                      attrs: {
                                        cellpadding: "0",
                                        cellspacing: "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "colgroup",
                                        [
                                          _c("col", { attrs: { width: "3%" } }),
                                          _c("col", { attrs: { width: "8%" } }),
                                          _c("col", { attrs: { width: "6%" } }),
                                          _c("col", { attrs: { width: "6%" } }),
                                          _c("col", { attrs: { width: "8%" } }),
                                          _c("col", { attrs: { width: "8%" } }),
                                          _c("col", { attrs: { width: "6%" } }),
                                          _vm._l(
                                            _vm.optionInfo.optionItemList,
                                            function (item, itemIdx) {
                                              return _c("col", {
                                                key: itemIdx,
                                                attrs: { width: "" },
                                              })
                                            }
                                          ),
                                          _c("col", { attrs: { width: "6%" } }),
                                          _c("col", { attrs: { width: "6%" } }),
                                        ],
                                        2
                                      ),
                                      _c("thead", [
                                        _c(
                                          "tr",
                                          [
                                            _c("th", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.optionInfo
                                                        .isallchkopt,
                                                    expression:
                                                      "optionInfo.isallchkopt",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id: "chkalloptD",
                                                  "true-value": "T",
                                                  "false-value": "F",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.optionInfo.isallchkopt
                                                  )
                                                    ? _vm._i(
                                                        _vm.optionInfo
                                                          .isallchkopt,
                                                        null
                                                      ) > -1
                                                    : _vm._q(
                                                        _vm.optionInfo
                                                          .isallchkopt,
                                                        "T"
                                                      ),
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$a =
                                                          _vm.optionInfo
                                                            .isallchkopt,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? "T"
                                                          : "F"
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.optionInfo,
                                                              "isallchkopt",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.optionInfo,
                                                              "isallchkopt",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.optionInfo,
                                                          "isallchkopt",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function ($event) {
                                                      return _vm.checkAllOptionList(
                                                        $event.target.checked
                                                      )
                                                    },
                                                  ],
                                                },
                                              }),
                                            ]),
                                            _c("th", [_vm._v("옵션코드")]),
                                            _c("th", [_vm._v("대표상품")]),
                                            _c("th", [_vm._v("옵션상태")]),
                                            _c("th", [_vm._v("정상가")]),
                                            _c("th", [_vm._v("판매가")]),
                                            _c("th", [_vm._v("마진율(%)")]),
                                            _vm._l(
                                              _vm.optionInfo.optionItemList,
                                              function (item, itemIdx) {
                                                return _c(
                                                  "th",
                                                  {
                                                    key: itemIdx,
                                                    staticClass: "bg-yellow",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.optionname
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            _c("th", [_vm._v("안전재고")]),
                                            _c("th", [_vm._v("상품조회")]),
                                          ],
                                          2
                                        ),
                                      ]),
                                      _vm.optionInfo.optionList.length > 0
                                        ? _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.optionInfo.optionList,
                                              function (item, index) {
                                                return _c(
                                                  "tr",
                                                  { key: index },
                                                  [
                                                    _c("td", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              item.ischecked,
                                                            expression:
                                                              "item.ischecked",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "checkbox",
                                                          id:
                                                            "chkoptD_" +
                                                            item.optionno,
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              item.ischecked
                                                            )
                                                              ? _vm._i(
                                                                  item.ischecked,
                                                                  null
                                                                ) > -1
                                                              : item.ischecked,
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$a =
                                                                  item.ischecked,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      item,
                                                                      "ischecked",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      item,
                                                                      "ischecked",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  item,
                                                                  "ischecked",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                            function ($event) {
                                                              return _vm.checkOptionList(
                                                                $event.target
                                                                  .checked
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.optioncode)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _c("input", {
                                                        staticClass: "circle",
                                                        attrs: {
                                                          type: "radio",
                                                          name: "ismaingoodsD",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            item.ismaingoods ==
                                                            "T",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.setIsMainGoods(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]),
                                                    item.isoptdisplay == "T"
                                                      ? _c("td", [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn blue",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeOptDisplay(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v(" 노출 ")]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    item.isoptdisplay == "F"
                                                      ? _c("td", [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn red",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeOptDisplay(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v(" 숨김 ")]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _c(
                                                      "td",
                                                      { staticClass: "right" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.marketprice,
                                                              expression:
                                                                "item.marketprice",
                                                            },
                                                          ],
                                                          staticClass: "right",
                                                          attrs: {
                                                            type: "text",
                                                            maxlength: "11",
                                                          },
                                                          domProps: {
                                                            value:
                                                              item.marketprice,
                                                          },
                                                          on: {
                                                            input: [
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  item,
                                                                  "marketprice",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.changeMarketprice(
                                                                  item
                                                                )
                                                              },
                                                            ],
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      { staticClass: "right" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value: item.price,
                                                              expression:
                                                                "item.price",
                                                            },
                                                          ],
                                                          staticClass: "right",
                                                          attrs: {
                                                            type: "text",
                                                            maxlength: "11",
                                                          },
                                                          domProps: {
                                                            value: item.price,
                                                          },
                                                          on: {
                                                            input: [
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  item,
                                                                  "price",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.changePrice(
                                                                  item
                                                                )
                                                              },
                                                            ],
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c("td", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              item.marginrate,
                                                            expression:
                                                              "item.marginrate",
                                                          },
                                                        ],
                                                        staticClass: "center",
                                                        attrs: {
                                                          type: "text",
                                                          readonly: "",
                                                        },
                                                        domProps: {
                                                          value:
                                                            item.marginrate,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              item,
                                                              "marginrate",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]),
                                                    _vm._l(
                                                      _vm.optionInfo
                                                        .optionItemList,
                                                      function (item, itemIdx) {
                                                        return _c(
                                                          "td",
                                                          { key: itemIdx },
                                                          [
                                                            _c("input", {
                                                              ref:
                                                                "optionnm" +
                                                                item.sort +
                                                                "_" +
                                                                index,
                                                              refInFor: true,
                                                              staticClass:
                                                                "center",
                                                              attrs: {
                                                                type: "text",
                                                                maxlength:
                                                                  "100",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.chgInfo.chgOpt =
                                                                      "T"
                                                                  },
                                                              },
                                                            }),
                                                            _c("input", {
                                                              ref:
                                                                "optionno" +
                                                                item.sort +
                                                                "_" +
                                                                index,
                                                              refInFor: true,
                                                              staticClass:
                                                                "center",
                                                              attrs: {
                                                                type: "hidden",
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _c("td", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              item.safestockcnt,
                                                            expression:
                                                              "item.safestockcnt",
                                                          },
                                                        ],
                                                        staticClass: "center",
                                                        attrs: {
                                                          type: "text",
                                                          maxlength: "11",
                                                          oninput:
                                                            "this.value = this.value.replace(/(^0[0-9]|[^0-9])/gi, '');",
                                                        },
                                                        domProps: {
                                                          value:
                                                            item.safestockcnt,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            _vm.chgInfo.chgOpt =
                                                              "T"
                                                          },
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              item,
                                                              "safestockcnt",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]),
                                                    item.linkedcnt > 0
                                                      ? _c("td", [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "link",
                                                              attrs: {
                                                                href: "javascript:void(0);",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openSearchOrgCodePopup(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " 연결(" +
                                                                  _vm._s(
                                                                    item.linkedcnt
                                                                  ) +
                                                                  ") "
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : _c("td", [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn blue-line",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openSearchOrgCodePopup(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v(" 연결 ")]
                                                          ),
                                                        ]),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c("tbody", [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  attrs: {
                                                    colspan:
                                                      8 +
                                                      _vm.optionInfo
                                                        .optionItemList.length,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " 조회 결과가 존재하지 않습니다. "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "boxing", attrs: { id: "goodsTab4" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "boxing-title",
                        class: { closed: !_vm.tabObject.goodsTab4.isOpen },
                      },
                      [
                        _vm._v(" 이미지 정보 "),
                        _c("i", {
                          staticClass: "arrcodi",
                          on: {
                            click: function ($event) {
                              return _vm.onToggle(_vm.tabObject.goodsTab4)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-area",
                        style: {
                          display: _vm.tabObject.goodsTab4.isOpen
                            ? "block"
                            : "none",
                        },
                      },
                      [
                        _c("div", { staticStyle: { margin: "20px" } }, [
                          _c(
                            "table",
                            {
                              staticClass: "gray-tb",
                              attrs: { cellpadding: "0", cellspacing: "0" },
                            },
                            [
                              _vm._m(20),
                              _c("tbody", [
                                _c("tr", [
                                  _vm._m(21),
                                  _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "radio_wrap wide" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.imageInfo
                                                  .descriptionregistrationtype,
                                              expression:
                                                "imageInfo.descriptionregistrationtype",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: "descriptionregistrationtype",
                                            id: "descriptionregistrationtypePARTNER",
                                            value: "PARTNER",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.imageInfo
                                                .descriptionregistrationtype,
                                              "PARTNER"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.imageInfo,
                                                "descriptionregistrationtype",
                                                "PARTNER"
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "descriptionregistrationtypePARTNER",
                                            },
                                          },
                                          [_vm._v("파트너사")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.imageInfo
                                                  .descriptionregistrationtype,
                                              expression:
                                                "imageInfo.descriptionregistrationtype",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: "descriptionregistrationtype",
                                            id: "descriptionregistrationtypeDPLOT",
                                            value: "DPLOT",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.imageInfo
                                                .descriptionregistrationtype,
                                              "DPLOT"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.imageInfo,
                                                "descriptionregistrationtype",
                                                "DPLOT"
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "descriptionregistrationtypeDPLOT",
                                            },
                                          },
                                          [_vm._v("디플롯")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [_vm._v("아이콘설정")]),
                                  _c(
                                    "td",
                                    [
                                      _c("div", { staticClass: "check-wrap" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.imageInfo.isallgicontype,
                                              expression:
                                                "imageInfo.isallgicontype",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: "chkAllGicontype",
                                            "true-value": "T",
                                            "false-value": "F",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.imageInfo.isallgicontype
                                            )
                                              ? _vm._i(
                                                  _vm.imageInfo.isallgicontype,
                                                  null
                                                ) > -1
                                              : _vm._q(
                                                  _vm.imageInfo.isallgicontype,
                                                  "T"
                                                ),
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a =
                                                    _vm.imageInfo
                                                      .isallgicontype,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked ? "T" : "F"
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.imageInfo,
                                                        "isallgicontype",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.imageInfo,
                                                        "isallgicontype",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.imageInfo,
                                                    "isallgicontype",
                                                    $$c
                                                  )
                                                }
                                              },
                                              _vm.checkAllGicontype,
                                            ],
                                          },
                                        }),
                                        _c(
                                          "label",
                                          { attrs: { for: "chkAllGicontype" } },
                                          [_vm._v("전체")]
                                        ),
                                      ]),
                                      _vm._l(
                                        _vm.commonCode.gicontype,
                                        function (item) {
                                          return _c(
                                            "div",
                                            {
                                              key: item.cmcode,
                                              staticClass: "check-wrap",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.imageInfo
                                                        .mugicontypeArr,
                                                    expression:
                                                      "imageInfo.mugicontypeArr",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id:
                                                    "gicontype_" + item.cmcode,
                                                  "true-value": [],
                                                },
                                                domProps: {
                                                  value: item.cmcode,
                                                  checked: Array.isArray(
                                                    _vm.imageInfo.mugicontypeArr
                                                  )
                                                    ? _vm._i(
                                                        _vm.imageInfo
                                                          .mugicontypeArr,
                                                        item.cmcode
                                                      ) > -1
                                                    : _vm._q(
                                                        _vm.imageInfo
                                                          .mugicontypeArr,
                                                        []
                                                      ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.imageInfo
                                                          .mugicontypeArr,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? []
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = item.cmcode,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.imageInfo,
                                                            "mugicontypeArr",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.imageInfo,
                                                            "mugicontypeArr",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.imageInfo,
                                                        "mugicontypeArr",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for:
                                                      "gicontype_" +
                                                      item.cmcode,
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.codename))]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                                _c("tr", [
                                  _vm._m(22),
                                  _c("td", { ref: "repreimgtd" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "img-with-text",
                                        staticStyle: { width: "202px" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "img-thumb size200",
                                            class: {
                                              "no-image": _vm.$util.isNull(
                                                _vm.files["pcrepreimgfile"]
                                              ),
                                            },
                                          },
                                          [
                                            !_vm.$util.isNull(
                                              _vm.files["pcrepreimgfile"]
                                            ) &&
                                            _vm.files["pcrepreimgfile"]
                                              .status != "N"
                                              ? _c("img", {
                                                  attrs: {
                                                    src: _vm.imgPreview[
                                                      "pcrepreimgfile"
                                                    ],
                                                    alt: "대표이미지(PC)",
                                                  },
                                                })
                                              : _vm._e(),
                                            !_vm.$util.isNull(
                                              _vm.files["pcrepreimgfile"]
                                            ) &&
                                            _vm.files["pcrepreimgfile"]
                                              .status == "N"
                                              ? _c("img", {
                                                  attrs: {
                                                    src: _vm.files[
                                                      "pcrepreimgfile"
                                                    ].fullpath,
                                                    alt: "대표이미지(PC)",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm.$util.isNull(
                                          _vm.files["pcrepreimgfile"]
                                        ) &&
                                        (_vm.iscopy ||
                                          _vm.editauth.image.isauth)
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn blue-line mt10",
                                                staticStyle: { width: "100%" },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.fileAttach(
                                                      "pcrepreimgfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 파일 올리기 ")]
                                            )
                                          : _vm._e(),
                                        _c("input", {
                                          ref: "pcrepreimgfile",
                                          attrs: {
                                            type: "file",
                                            accept: "image/jpeg, image/png",
                                            hidden: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleFileUpload(
                                                "pcrepreimgfile"
                                              )
                                            },
                                          },
                                        }),
                                        !_vm.$util.isNull(
                                          _vm.files["pcrepreimgfile"]
                                        ) &&
                                        (_vm.iscopy ||
                                          _vm.editauth.image.isauth)
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn blue-line mt10",
                                                staticStyle: {
                                                  width: "calc(50% - 3px)",
                                                },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.fileAttach(
                                                      "pcrepreimgfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 변경 ")]
                                            )
                                          : _vm._e(),
                                        !_vm.$util.isNull(
                                          _vm.files["pcrepreimgfile"]
                                        ) &&
                                        (_vm.iscopy ||
                                          _vm.editauth.image.isauth)
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn red-line mt10",
                                                staticStyle: {
                                                  width: "calc(50% - 4px)",
                                                },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeFile(
                                                      "pcrepreimgfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 삭제 ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._m(23),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [_vm._v("추가이미지")]),
                                  _c("td", [
                                    _c("input", {
                                      ref: "addimgFile",
                                      attrs: {
                                        type: "file",
                                        id: "addimgFileD",
                                        accept: "image/jpeg, image/png",
                                        hidden: "",
                                        multiple: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleFileUpload(
                                            "addimgFile",
                                            $event.target
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "div",
                                      _vm._l(5, function (index, n) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "img-with-text",
                                            staticStyle: { width: "202px" },
                                          },
                                          [
                                            _vm.$util.isNull(
                                              _vm.files["addimgfilelist"][n]
                                            )
                                              ? _c("div", {
                                                  staticClass:
                                                    "img-thumb size200 no-image",
                                                })
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "img-thumb size200",
                                                  },
                                                  [
                                                    _vm.files["addimgfilelist"][
                                                      n
                                                    ].status != "N"
                                                      ? _c("img", {
                                                          attrs: {
                                                            src: _vm.imgPreview[
                                                              "addimgfilelist"
                                                            ][n],
                                                            alt:
                                                              "추가이미지" + n,
                                                          },
                                                        })
                                                      : _c("img", {
                                                          attrs: {
                                                            src: _vm.files[
                                                              "addimgfilelist"
                                                            ][n].fullpath,
                                                            alt:
                                                              "추가이미지" + n,
                                                          },
                                                        }),
                                                  ]
                                                ),
                                            _vm.$util.isNull(
                                              _vm.files["addimgfilelist"][n]
                                            ) &&
                                            (_vm.iscopy ||
                                              _vm.editauth.image.isauth)
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn blue-line mt10",
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.fileAttach(
                                                          "addimgFile"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 파일 올리기 ")]
                                                )
                                              : _vm._e(),
                                            !_vm.$util.isNull(
                                              _vm.files["addimgfilelist"][n]
                                            ) &&
                                            (_vm.iscopy ||
                                              _vm.editauth.image.isauth)
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn blue-line mt10",
                                                    staticStyle: {
                                                      width: "calc(50% - 3px)",
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.fileAttach(
                                                          "changeaddimgfile" + n
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 변경 ")]
                                                )
                                              : _vm._e(),
                                            !_vm.$util.isNull(
                                              _vm.files["addimgfilelist"][n]
                                            ) &&
                                            (_vm.iscopy ||
                                              _vm.editauth.image.isauth)
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn red-line mt10",
                                                    staticStyle: {
                                                      width: "calc(50% - 4px)",
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeFile(
                                                          "addimgFile",
                                                          n
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 삭제 ")]
                                                )
                                              : _vm._e(),
                                            _c("input", {
                                              ref: "changeaddimgfile" + n,
                                              refInFor: true,
                                              attrs: {
                                                type: "file",
                                                id: "changeaddimgfile" + n,
                                                accept: "image/jpeg, image/png",
                                                hidden: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handleFileUpload(
                                                    "changeaddimgfile",
                                                    $event.target,
                                                    n
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mt20" },
                                      _vm._l(2, function (index, n) {
                                        return _c(
                                          "div",
                                          {
                                            key: index + 5,
                                            staticClass: "img-with-text",
                                            staticStyle: { width: "202px" },
                                          },
                                          [
                                            _vm.$util.isNull(
                                              _vm.files["addimgfilelist"][n + 5]
                                            )
                                              ? _c("div", {
                                                  staticClass:
                                                    "img-thumb size200 no-image",
                                                })
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "img-thumb size200",
                                                  },
                                                  [
                                                    _vm.files["addimgfilelist"][
                                                      n + 5
                                                    ].status != "N"
                                                      ? _c("img", {
                                                          attrs: {
                                                            src: _vm.imgPreview[
                                                              "addimgfilelist"
                                                            ][n + 5],
                                                            alt:
                                                              "추가이미지" +
                                                              (n + 5),
                                                          },
                                                        })
                                                      : _c("img", {
                                                          attrs: {
                                                            src: _vm.files[
                                                              "addimgfilelist"
                                                            ][n + 5].fullpath,
                                                            alt:
                                                              "추가이미지" +
                                                              (n + 5),
                                                          },
                                                        }),
                                                  ]
                                                ),
                                            _vm.$util.isNull(
                                              _vm.files["addimgfilelist"][n + 5]
                                            ) &&
                                            (_vm.iscopy ||
                                              _vm.editauth.image.isauth)
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn blue-line mt10",
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.fileAttach(
                                                          "addimgFile"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 파일 올리기 ")]
                                                )
                                              : _vm._e(),
                                            !_vm.$util.isNull(
                                              _vm.files["addimgfilelist"][n + 5]
                                            ) &&
                                            (_vm.iscopy ||
                                              _vm.editauth.image.isauth)
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn blue-line mt10",
                                                    staticStyle: {
                                                      width: "calc(50% - 3px)",
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.fileAttach(
                                                          "changeaddimgfile" +
                                                            (n + 5)
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 변경 ")]
                                                )
                                              : _vm._e(),
                                            !_vm.$util.isNull(
                                              _vm.files["addimgfilelist"][n + 5]
                                            ) &&
                                            (_vm.iscopy ||
                                              _vm.editauth.image.isauth)
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn red-line mt10",
                                                    staticStyle: {
                                                      width: "calc(50% - 4px)",
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeFile(
                                                          "addimgFile",
                                                          n + 5
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 삭제 ")]
                                                )
                                              : _vm._e(),
                                            _c("input", {
                                              ref: "changeaddimgfile" + (n + 5),
                                              refInFor: true,
                                              attrs: {
                                                type: "file",
                                                id:
                                                  "changeaddimgfile" + (n + 5),
                                                accept: "image/jpeg, image/png",
                                                hidden: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handleFileUpload(
                                                    "changeaddimgfile",
                                                    $event.target,
                                                    n + 5
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ]),
                                ]),
                                _c("tr", [
                                  _vm._m(24),
                                  _c("td", [
                                    _c("div", { staticClass: "mb10" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.imageInfo.issamepcimg,
                                            expression: "imageInfo.issamepcimg",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "typical-sameD",
                                          disabled:
                                            !_vm.iscopy &&
                                            _vm.isPartner &&
                                            !_vm.editauth.image.isauth,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.imageInfo.issamepcimg
                                          )
                                            ? _vm._i(
                                                _vm.imageInfo.issamepcimg,
                                                null
                                              ) > -1
                                            : _vm.imageInfo.issamepcimg,
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$a =
                                                  _vm.imageInfo.issamepcimg,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.imageInfo,
                                                      "issamepcimg",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.imageInfo,
                                                      "issamepcimg",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.imageInfo,
                                                  "issamepcimg",
                                                  $$c
                                                )
                                              }
                                            },
                                            _vm.setSameAsPcepreImg,
                                          ],
                                        },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: "typical-sameD" } },
                                        [_vm._v("PC 대표 이미지와 동일")]
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "img-with-text",
                                        staticStyle: { width: "202px" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "img-thumb size200",
                                            class: {
                                              "no-image": _vm.$util.isNull(
                                                _vm.files["morepreimgfile"]
                                              ),
                                            },
                                          },
                                          [
                                            !_vm.$util.isNull(
                                              _vm.files["morepreimgfile"]
                                            ) &&
                                            _vm.files["morepreimgfile"]
                                              .status != "N"
                                              ? _c("img", {
                                                  staticClass: "fit",
                                                  attrs: {
                                                    src: _vm.imgPreview[
                                                      "morepreimgfile"
                                                    ],
                                                    alt: "대표이미지(모바일)",
                                                  },
                                                })
                                              : _vm._e(),
                                            !_vm.$util.isNull(
                                              _vm.files["morepreimgfile"]
                                            ) &&
                                            _vm.files["morepreimgfile"]
                                              .status == "N"
                                              ? _c("img", {
                                                  staticClass: "fit",
                                                  attrs: {
                                                    src: _vm.files[
                                                      "morepreimgfile"
                                                    ].fullpath,
                                                    alt: "대표이미지(모바일)",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm.$util.isNull(
                                          _vm.files["morepreimgfile"]
                                        ) &&
                                        (_vm.iscopy ||
                                          _vm.editauth.image.isauth)
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn blue-line mt10",
                                                staticStyle: { width: "100%" },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.fileAttach(
                                                      "morepreimgfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 파일 올리기 ")]
                                            )
                                          : _vm._e(),
                                        _c("input", {
                                          ref: "morepreimgfile",
                                          attrs: {
                                            type: "file",
                                            accept: "image/jpeg, image/png",
                                            hidden: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleFileUpload(
                                                "morepreimgfile"
                                              )
                                            },
                                          },
                                        }),
                                        !_vm.$util.isNull(
                                          _vm.files["morepreimgfile"]
                                        ) &&
                                        (_vm.iscopy ||
                                          _vm.editauth.image.isauth)
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn blue-line mt10",
                                                staticStyle: {
                                                  width: "calc(50% - 3px)",
                                                },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.fileAttach(
                                                      "morepreimgfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 변경 ")]
                                            )
                                          : _vm._e(),
                                        !_vm.$util.isNull(
                                          _vm.files["morepreimgfile"]
                                        ) &&
                                        (_vm.iscopy ||
                                          _vm.editauth.image.isauth)
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn red-line mt10",
                                                staticStyle: {
                                                  width: "calc(50% - 4px)",
                                                },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeFile(
                                                      "morepreimgfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 삭제 ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._m(25),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [_vm._v("공지이미지")]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "radio_wrap wide" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.imageInfo.isusenotice,
                                              expression:
                                                "imageInfo.isusenotice",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: "isusenoticeD",
                                            id: "isusenoticeDF",
                                            value: "F",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.imageInfo.isusenotice,
                                              "F"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.imageInfo,
                                                "isusenotice",
                                                "F"
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          { attrs: { for: "isusenoticeDF" } },
                                          [_vm._v("미사용")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.imageInfo.isusenotice,
                                              expression:
                                                "imageInfo.isusenotice",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: "isusenoticeD",
                                            id: "isusenoticeDT",
                                            value: "T",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.imageInfo.isusenotice,
                                              "T"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.imageInfo,
                                                "isusenotice",
                                                "T"
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          { attrs: { for: "isusenoticeDT" } },
                                          [_vm._v("사용")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.imageInfo.isusenotice == "T",
                                            expression:
                                              "imageInfo.isusenotice == 'T'",
                                          },
                                        ],
                                        staticClass: "mt10",
                                      },
                                      [
                                        _c("CommonEditor", {
                                          ref: "noticeeditor",
                                          attrs: {
                                            "style-object": "height:'200px';",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [_vm._v("인트로이미지")]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "radio_wrap wide" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.imageInfo.isuseintro,
                                              expression:
                                                "imageInfo.isuseintro",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: "isuseintroD",
                                            id: "isuseintroDT",
                                            value: "F",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.imageInfo.isuseintro,
                                              "F"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.imageInfo,
                                                "isuseintro",
                                                "F"
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          { attrs: { for: "isuseintroDT" } },
                                          [_vm._v("미사용")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.imageInfo.isuseintro,
                                              expression:
                                                "imageInfo.isuseintro",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: "isuseintroD",
                                            id: "isuseintroDF",
                                            value: "T",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.imageInfo.isuseintro,
                                              "T"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.imageInfo,
                                                "isuseintro",
                                                "T"
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          { attrs: { for: "isuseintroDF" } },
                                          [_vm._v("사용")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.imageInfo.isuseintro == "T",
                                            expression:
                                              "imageInfo.isuseintro == 'T'",
                                          },
                                        ],
                                        staticClass: "mt10",
                                      },
                                      [
                                        _c("CommonEditor", {
                                          ref: "introeditor",
                                          attrs: {
                                            "style-object": "height:'200px';",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(" 동영상 포스터 이미지(PC) "),
                                  ]),
                                  _c("td", { ref: "movimgtd" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "img-with-text",
                                        staticStyle: { width: "202px" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "img-thumb size200",
                                            class: {
                                              "no-image": _vm.$util.isNull(
                                                _vm.files["pcmovimgfile"]
                                              ),
                                            },
                                          },
                                          [
                                            !_vm.$util.isNull(
                                              _vm.files["pcmovimgfile"]
                                            ) &&
                                            _vm.files["pcmovimgfile"].status !==
                                              "N"
                                              ? _c("img", {
                                                  staticClass: "fit",
                                                  attrs: {
                                                    src: _vm.imgPreview[
                                                      "pcmovimgfile"
                                                    ],
                                                    alt: "동영상 포스터 이미지(pc)",
                                                  },
                                                })
                                              : _vm._e(),
                                            !_vm.$util.isNull(
                                              _vm.files["pcmovimgfile"]
                                            ) &&
                                            _vm.files["pcmovimgfile"].status ===
                                              "N"
                                              ? _c("img", {
                                                  staticClass: "fit",
                                                  attrs: {
                                                    src: _vm.files[
                                                      "pcmovimgfile"
                                                    ].fullpath,
                                                    alt: "동영상 포스터 이미지(pc)",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm.$util.isNull(
                                          _vm.files["pcmovimgfile"]
                                        )
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn blue-line mt10",
                                                staticStyle: { width: "100%" },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.fileAttach(
                                                      "pcmovimgfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 파일 올리기 ")]
                                            )
                                          : _vm._e(),
                                        _c("input", {
                                          ref: "pcmovimgfile",
                                          attrs: {
                                            type: "file",
                                            accept: "image/jpeg, image/png",
                                            hidden: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleFileUpload(
                                                "pcmovimgfile"
                                              )
                                            },
                                          },
                                        }),
                                        !_vm.$util.isNull(
                                          _vm.files["pcmovimgfile"]
                                        )
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn blue-line mt10",
                                                staticStyle: {
                                                  width: "calc(50% - 3px)",
                                                },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.fileAttach(
                                                      "pcmovimgfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 변경 ")]
                                            )
                                          : _vm._e(),
                                        !_vm.$util.isNull(
                                          _vm.files["pcmovimgfile"]
                                        )
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn red-line mt10",
                                                staticStyle: {
                                                  width: "calc(50% - 4px)",
                                                },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeFile(
                                                      "pcmovimgfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 삭제 ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._m(26),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(" 동영상 포스터 이미지(모바일) "),
                                  ]),
                                  _c("td", [
                                    _c("div", { staticClass: "mb10" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.imageInfo.issamepcmovimg,
                                            expression:
                                              "imageInfo.issamepcmovimg",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "typical-same-movimg",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.imageInfo.issamepcmovimg
                                          )
                                            ? _vm._i(
                                                _vm.imageInfo.issamepcmovimg,
                                                null
                                              ) > -1
                                            : _vm.imageInfo.issamepcmovimg,
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$a =
                                                  _vm.imageInfo.issamepcmovimg,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.imageInfo,
                                                      "issamepcmovimg",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.imageInfo,
                                                      "issamepcmovimg",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.imageInfo,
                                                  "issamepcmovimg",
                                                  $$c
                                                )
                                              }
                                            },
                                            _vm.setSameAsPcepreMovImg,
                                          ],
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          attrs: { for: "typical-same-movimg" },
                                        },
                                        [_vm._v("PC 이미지를 복사")]
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "img-with-text",
                                        staticStyle: { width: "202px" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "img-thumb size200",
                                            class: {
                                              "no-image": _vm.$util.isNull(
                                                _vm.files["mobilemovimgfile"]
                                              ),
                                            },
                                          },
                                          [
                                            !_vm.$util.isNull(
                                              _vm.files["mobilemovimgfile"]
                                            ) &&
                                            _vm.files["mobilemovimgfile"]
                                              .status !== "N"
                                              ? _c("img", {
                                                  staticClass: "fit",
                                                  attrs: {
                                                    src: _vm.imgPreview[
                                                      "mobilemovimgfile"
                                                    ],
                                                    alt: "대표동영상 포스터 이미지이미지(모바일)",
                                                  },
                                                })
                                              : _vm._e(),
                                            !_vm.$util.isNull(
                                              _vm.files["mobilemovimgfile"]
                                            ) &&
                                            _vm.files["mobilemovimgfile"]
                                              .status === "N"
                                              ? _c("img", {
                                                  staticClass: "fit",
                                                  attrs: {
                                                    src: _vm.files[
                                                      "mobilemovimgfile"
                                                    ].fullpath,
                                                    alt: "동영상 포스터 이미지(모바일)",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm.$util.isNull(
                                          _vm.files["mobilemovimgfile"]
                                        )
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn blue-line mt10",
                                                staticStyle: { width: "100%" },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.fileAttach(
                                                      "mobilemovimgfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 파일 올리기 ")]
                                            )
                                          : _vm._e(),
                                        _c("input", {
                                          ref: "mobilemovimgfile",
                                          attrs: {
                                            type: "file",
                                            accept: "image/jpeg, image/png",
                                            hidden: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleFileUpload(
                                                "mobilemovimgfile"
                                              )
                                            },
                                          },
                                        }),
                                        !_vm.$util.isNull(
                                          _vm.files["mobilemovimgfile"]
                                        )
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn blue-line mt10",
                                                staticStyle: {
                                                  width: "calc(50% - 3px)",
                                                },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.fileAttach(
                                                      "mobilemovimgfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 변경 ")]
                                            )
                                          : _vm._e(),
                                        !_vm.$util.isNull(
                                          _vm.files["mobilemovimgfile"]
                                        )
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn red-line mt10",
                                                staticStyle: {
                                                  width: "calc(50% - 4px)",
                                                },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeFile(
                                                      "mobilemovimgfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 삭제 ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._m(27),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [_vm._v(" 동영상(PC) ")]),
                                  _c("td", { ref: "movtd" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "img-with-text",
                                        staticStyle: { width: "202px" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "img-thumb",
                                            class: {
                                              "no-image": _vm.$util.isNull(
                                                _vm.files["pcmovfile"]
                                              ),
                                            },
                                            staticStyle: {
                                              width: "620px",
                                              height: "370px",
                                            },
                                          },
                                          [
                                            !_vm.$util.isNull(
                                              _vm.files["pcmovfile"]
                                            ) &&
                                            _vm.files["pcmovfile"].status !==
                                              "N"
                                              ? _c("video", {
                                                  staticStyle: {
                                                    width: "620px",
                                                    height: "370px",
                                                  },
                                                  attrs: {
                                                    controls: "",
                                                    src: _vm.imgPreview[
                                                      "pcmovfile"
                                                    ],
                                                    alt: "동영상(pc)",
                                                  },
                                                })
                                              : _vm._e(),
                                            !_vm.$util.isNull(
                                              _vm.files["pcmovfile"]
                                            ) &&
                                            _vm.files["pcmovfile"].status ===
                                              "N"
                                              ? _c("video", {
                                                  staticStyle: {
                                                    width: "620px",
                                                    height: "370px",
                                                  },
                                                  attrs: {
                                                    controls: "",
                                                    src: _vm.files["pcmovfile"]
                                                      .fullpath,
                                                    alt: "동영상(pc)",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm.$util.isNull(_vm.files["pcmovfile"])
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn blue-line mt10",
                                                staticStyle: { width: "100%" },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.fileAttach(
                                                      "pcmovfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 파일 올리기 ")]
                                            )
                                          : _vm._e(),
                                        _c("input", {
                                          ref: "pcmovfile",
                                          attrs: {
                                            type: "file",
                                            accept: "video/mp4",
                                            hidden: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleVFileUpload(
                                                "pcmovfile"
                                              )
                                            },
                                          },
                                        }),
                                        !_vm.$util.isNull(
                                          _vm.files["pcmovfile"]
                                        )
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn blue-line mt10",
                                                staticStyle: {
                                                  width: "calc(50% - 3px)",
                                                },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.fileAttach(
                                                      "pcmovfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 변경 ")]
                                            )
                                          : _vm._e(),
                                        !_vm.$util.isNull(
                                          _vm.files["pcmovfile"]
                                        )
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn red-line mt10",
                                                staticStyle: {
                                                  width: "calc(50% - 4px)",
                                                },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeFile(
                                                      "pcmovfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 삭제 ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._m(28),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("th", [_vm._v(" 동영상(모바일) ")]),
                                  _c("td", [
                                    _c("div", { staticClass: "mb10" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.imageInfo.issamepcmov,
                                            expression: "imageInfo.issamepcmov",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "typical-same-mov",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.imageInfo.issamepcmov
                                          )
                                            ? _vm._i(
                                                _vm.imageInfo.issamepcmov,
                                                null
                                              ) > -1
                                            : _vm.imageInfo.issamepcmov,
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$a =
                                                  _vm.imageInfo.issamepcmov,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.imageInfo,
                                                      "issamepcmov",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.imageInfo,
                                                      "issamepcmov",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.imageInfo,
                                                  "issamepcmov",
                                                  $$c
                                                )
                                              }
                                            },
                                            _vm.setSameAsPcepreMov,
                                          ],
                                        },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: "typical-same-mov" } },
                                        [_vm._v("PC 동영상을 복사")]
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "img-with-text",
                                        staticStyle: { width: "202px" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "img-thumb",
                                            class: {
                                              "no-image": _vm.$util.isNull(
                                                _vm.files["mobilemovfile"]
                                              ),
                                            },
                                            staticStyle: {
                                              width: "288px",
                                              height: "324px",
                                            },
                                          },
                                          [
                                            !_vm.$util.isNull(
                                              _vm.files["mobilemovfile"]
                                            ) &&
                                            _vm.files["mobilemovfile"]
                                              .status !== "N"
                                              ? _c("video", {
                                                  staticStyle: {
                                                    width: "288px",
                                                    height: "324px",
                                                  },
                                                  attrs: {
                                                    controls: "",
                                                    src: _vm.imgPreview[
                                                      "mobilemovfile"
                                                    ],
                                                    alt: "동영상(모바일)",
                                                  },
                                                })
                                              : _vm._e(),
                                            !_vm.$util.isNull(
                                              _vm.files["mobilemovfile"]
                                            ) &&
                                            _vm.files["mobilemovfile"]
                                              .status === "N"
                                              ? _c("video", {
                                                  staticStyle: {
                                                    width: "288px",
                                                    height: "324px",
                                                  },
                                                  attrs: {
                                                    controls: "",
                                                    src: _vm.files[
                                                      "mobilemovfile"
                                                    ].fullpath,
                                                    alt: "동영상(모바일)",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm.$util.isNull(
                                          _vm.files["mobilemovfile"]
                                        )
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn blue-line mt10",
                                                staticStyle: { width: "100%" },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.fileAttach(
                                                      "mobilemovfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 파일 올리기 ")]
                                            )
                                          : _vm._e(),
                                        _c("input", {
                                          ref: "mobilemovfile",
                                          attrs: {
                                            type: "file",
                                            accept: "video/mp4",
                                            hidden: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleVFileUpload(
                                                "mobilemovfile"
                                              )
                                            },
                                          },
                                        }),
                                        !_vm.$util.isNull(
                                          _vm.files["mobilemovfile"]
                                        )
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn blue-line mt10",
                                                staticStyle: {
                                                  width: "calc(50% - 3px)",
                                                },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.fileAttach(
                                                      "mobilemovfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 변경 ")]
                                            )
                                          : _vm._e(),
                                        !_vm.$util.isNull(
                                          _vm.files["mobilemovfile"]
                                        )
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn red-line mt10",
                                                staticStyle: {
                                                  width: "calc(50% - 4px)",
                                                },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeFile(
                                                      "mobilemovfile"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 삭제 ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._m(29),
                                  ]),
                                ]),
                                _c("tr", [
                                  _vm._m(30),
                                  _c("td", { staticStyle: {} }, [
                                    _c(
                                      "div",
                                      [
                                        _c("CommonEditor", {
                                          ref: "pceditor",
                                          attrs: {
                                            "style-object": "height:'200px';",
                                            disable:
                                              _vm.isPartner &&
                                              _vm.editauth.pccontent.isauth ===
                                                false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _c("legend", [
                                  _vm._v("모바일용 상품상세설명 수정권한"),
                                ]),
                                _c("tr", [
                                  _vm._m(31),
                                  _c("td", { staticStyle: {} }, [
                                    _c("div", { staticClass: "mb10" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn blue-line",
                                          attrs: {
                                            type: "button",
                                            disabled:
                                              !_vm.iscopy &&
                                              _vm.isPartner &&
                                              !_vm.editauth.image.isauth,
                                          },
                                          on: {
                                            click:
                                              _vm.setSameAsPcDetailContrent,
                                          },
                                        },
                                        [_vm._v(" PC용 상품상세설명 복사 ")]
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "mt10" },
                                      [
                                        _c("CommonEditor", {
                                          ref: "mobileeditor",
                                          attrs: {
                                            "style-object": "height:'200px';",
                                            disable:
                                              _vm.isPartner &&
                                              _vm.editauth.mocontent.isauth ===
                                                false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "boxing", attrs: { id: "goodsTab5" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "boxing-title",
                        class: { closed: !_vm.tabObject.goodsTab5.isOpen },
                      },
                      [
                        _vm._v(" 배송 정보 "),
                        _c("i", {
                          staticClass: "arrcodi",
                          on: {
                            click: function ($event) {
                              return _vm.onToggle(_vm.tabObject.goodsTab5)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-area",
                        style: {
                          display: _vm.tabObject.goodsTab5.isOpen
                            ? "block"
                            : "none",
                        },
                      },
                      [
                        _c(
                          "fieldset",
                          {
                            attrs: {
                              disabled:
                                !_vm.iscopy &&
                                _vm.isPartner &&
                                _vm.editauth.delividx.isauth === false,
                            },
                          },
                          [
                            _c("legend", [_vm._v("배송템플릿 수정권한")]),
                            _c("dl", [
                              _c("dt", [_vm._v("배송템플릿 선택")]),
                              _c("dd", [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.deliveryInfo.delividx,
                                        expression: "deliveryInfo.delividx",
                                      },
                                    ],
                                    staticStyle: { width: "400px" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.deliveryInfo,
                                          "delividx",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v("배송템플릿 선택"),
                                    ]),
                                    _vm._l(
                                      _vm.deliveryInfo.delivTempList,
                                      function (item) {
                                        return _c(
                                          "option",
                                          {
                                            key: item.delividx,
                                            domProps: { value: item.delividx },
                                          },
                                          [
                                            _vm._v(
                                              " [" +
                                                _vm._s(item.iscombdelivname) +
                                                "]" +
                                                _vm._s(item.delivbname) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn blue",
                                    attrs: { type: "button" },
                                    on: { click: _vm.openDelivTempListPopup },
                                  },
                                  [_vm._v(" 배송템플릿 ")]
                                ),
                                _vm._m(32),
                              ]),
                            ]),
                          ]
                        ),
                        !_vm.$util.isNull(
                          _vm.deliveryInfo.delivTemp.delivschtype
                        )
                          ? _c("dl", [
                              _c("dt", [_vm._v("배송안내")]),
                              _vm.deliveryInfo.delivTemp.delivschtype !=
                                "DSC001" &&
                              !_vm.$util.isNull(
                                _vm.deliveryInfo.delivTemp.delivschtype
                              )
                                ? _c("dd", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.basicInfo.delivinfo,
                                          expression: "basicInfo.delivinfo",
                                        },
                                      ],
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "text",
                                        value: "",
                                        placeholder:
                                          "배송안내문구를 입력하세요!",
                                        maxlength: 30,
                                      },
                                      domProps: {
                                        value: _vm.basicInfo.delivinfo,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.basicInfo,
                                            "delivinfo",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._m(33),
                                  ])
                                : _vm._e(),
                              _vm.deliveryInfo.delivTemp.delivschtype ==
                              "DSC001"
                                ? _c("dd", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.basicInfo.delivinfo,
                                          expression: "basicInfo.delivinfo",
                                        },
                                      ],
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "text",
                                        value:
                                          "주문확인 후 최대 1~4일 내 배송완료",
                                        readonly: "",
                                      },
                                      domProps: {
                                        value: _vm.basicInfo.delivinfo,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.basicInfo,
                                            "delivinfo",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "col2",
                            staticStyle: { padding: "0 10px 10px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "left",
                                staticStyle: { padding: "0 10px" },
                              },
                              [
                                _c("div", { staticClass: "bar-title small" }, [
                                  _vm._v("기본정보"),
                                ]),
                                _c(
                                  "table",
                                  {
                                    staticClass: "gray-tb",
                                    attrs: {
                                      cellpadding: "0",
                                      cellspacing: "0",
                                    },
                                  },
                                  [
                                    _vm._m(34),
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("th", [_vm._v("배송유형")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.deliveryInfo.delivTemp
                                                .iscombdelivname
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("배송비")]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.deliveryInfo.delivTemp
                                                  .delivfaretypename
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.deliveryInfo.delivTemp
                                                  .delivfaretype ==
                                                  _vm.$store.getters["ADMIN"]
                                                    .DELIV_FARE_DCT002
                                                  ? _vm.deliveryInfo.delivTemp
                                                      .delivfare + "원"
                                                  : ""
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.deliveryInfo.delivTemp
                                                  .delivfaretype ==
                                                  _vm.$store.getters["ADMIN"]
                                                    .DELIV_FARE_DCT003
                                                  ? _vm.deliveryInfo.delivTemp
                                                      .delivfare +
                                                      "원 (" +
                                                      _vm.deliveryInfo.delivTemp
                                                        .delivfreefare +
                                                      "원 이상 무료)"
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("배송방법")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.deliveryInfo.delivTemp
                                                .delivtypename
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("택배사")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.deliveryInfo.delivTemp
                                                .logistypename
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("배송일정")]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.deliveryInfo.delivTemp
                                                  .delivschtypename
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("배송가능지역")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.deliveryInfo.delivTemp
                                                .nationdelivconts
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", [
                                          _vm._v("도서산간 추가배송비(편도)"),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$util.isNull(
                                                  _vm.deliveryInfo.delivTemp
                                                    .chejufare
                                                )
                                                  ? ""
                                                  : "제주 " +
                                                      _vm.deliveryInfo.delivTemp
                                                        .chejufare +
                                                      "원"
                                              ) +
                                              "/ " +
                                              _vm._s(
                                                _vm.$util.isNull(
                                                  _vm.deliveryInfo.delivTemp
                                                    .isolfare
                                                )
                                                  ? ""
                                                  : "도서산간 " +
                                                      _vm.deliveryInfo.delivTemp
                                                        .isolfare +
                                                      "원"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "right",
                                staticStyle: { padding: "0 10px" },
                              },
                              [
                                _c("div", { staticClass: "bar-title small" }, [
                                  _vm._v("교환/반품"),
                                ]),
                                _c(
                                  "table",
                                  {
                                    staticClass: "gray-tb",
                                    attrs: {
                                      cellpadding: "0",
                                      cellspacing: "0",
                                    },
                                  },
                                  [
                                    _vm._m(35),
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("th", [_vm._v("반품택배사")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.deliveryInfo.delivTemp
                                                .rtnlogistypename
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("교환 배송비")]),
                                        _c("td", [
                                          _c("span", [
                                            _vm._v(" 편도 "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.deliveryInfo.delivTemp
                                                      .exowfare,
                                                  expression:
                                                    "deliveryInfo.delivTemp.exowfare",
                                                },
                                              ],
                                              staticClass: "short right",
                                              attrs: {
                                                type: "text",
                                                readonly: "",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.deliveryInfo.delivTemp
                                                    .exowfare,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.deliveryInfo.delivTemp,
                                                    "exowfare",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" 원 "),
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "left-bar" },
                                            [
                                              _vm._v(" 왕복 "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.deliveryInfo.delivTemp
                                                        .exrtnfare,
                                                    expression:
                                                      "deliveryInfo.delivTemp.exrtnfare",
                                                  },
                                                ],
                                                staticClass: "short right",
                                                attrs: {
                                                  type: "text",
                                                  readonly: "",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.deliveryInfo.delivTemp
                                                      .exrtnfare,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.deliveryInfo
                                                        .delivTemp,
                                                      "exrtnfare",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" 원 "),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("반품 배송비")]),
                                        _c("td", [
                                          _c("span", [
                                            _vm._v(" 편도 "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.deliveryInfo.delivTemp
                                                      .rfowfare,
                                                  expression:
                                                    "deliveryInfo.delivTemp.rfowfare",
                                                },
                                              ],
                                              staticClass: "short right",
                                              attrs: {
                                                type: "text",
                                                readonly: "",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.deliveryInfo.delivTemp
                                                    .rfowfare,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.deliveryInfo.delivTemp,
                                                    "rfowfare",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" 원 "),
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "left-bar" },
                                            [
                                              _vm._v(" 왕복 "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.deliveryInfo.delivTemp
                                                        .rfrtnfare,
                                                    expression:
                                                      "deliveryInfo.delivTemp.rfrtnfare",
                                                  },
                                                ],
                                                staticClass: "short right",
                                                attrs: {
                                                  type: "text",
                                                  readonly: "",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.deliveryInfo.delivTemp
                                                      .rfrtnfare,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.deliveryInfo
                                                        .delivTemp,
                                                      "rfrtnfare",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" 원 "),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("출고지 주소")]),
                                        _c("td", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.deliveryInfo.delivTemp
                                                    .relpost,
                                                expression:
                                                  "deliveryInfo.delivTemp.relpost",
                                              },
                                            ],
                                            staticClass: "dpb short",
                                            attrs: {
                                              type: "text",
                                              readonly: "",
                                            },
                                            domProps: {
                                              value:
                                                _vm.deliveryInfo.delivTemp
                                                  .relpost,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.deliveryInfo.delivTemp,
                                                  "relpost",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.deliveryInfo.delivTemp
                                                    .reladdr,
                                                expression:
                                                  "deliveryInfo.delivTemp.reladdr",
                                              },
                                            ],
                                            staticClass: "dpb",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "text",
                                              readonly: "",
                                            },
                                            domProps: {
                                              value:
                                                _vm.deliveryInfo.delivTemp
                                                  .reladdr,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.deliveryInfo.delivTemp,
                                                  "reladdr",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.deliveryInfo.delivTemp
                                                    .reladdrdetail,
                                                expression:
                                                  "deliveryInfo.delivTemp.reladdrdetail",
                                              },
                                            ],
                                            staticClass: "dpb",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "text",
                                              readonly: "",
                                            },
                                            domProps: {
                                              value:
                                                _vm.deliveryInfo.delivTemp
                                                  .reladdrdetail,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.deliveryInfo.delivTemp,
                                                  "reladdrdetail",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "small-txt" },
                                            [
                                              _vm._v(
                                                " [" +
                                                  _vm._s(
                                                    _vm.$util.isNull(
                                                      _vm.deliveryInfo.delivTemp
                                                        .reladdr
                                                    )
                                                      ? " "
                                                      : "도로명"
                                                  ) +
                                                  "] " +
                                                  _vm._s(
                                                    _vm.deliveryInfo.delivTemp
                                                      .reladdr
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.deliveryInfo.delivTemp
                                                      .reladdrdetail
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("교환/반품 주소")]),
                                        _c("td", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.deliveryInfo.delivTemp
                                                    .rfpost,
                                                expression:
                                                  "deliveryInfo.delivTemp.rfpost",
                                              },
                                            ],
                                            staticClass: "dpb short",
                                            attrs: {
                                              type: "text",
                                              readonly: "",
                                            },
                                            domProps: {
                                              value:
                                                _vm.deliveryInfo.delivTemp
                                                  .rfpost,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.deliveryInfo.delivTemp,
                                                  "rfpost",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.deliveryInfo.delivTemp
                                                    .rfaddr,
                                                expression:
                                                  "deliveryInfo.delivTemp.rfaddr",
                                              },
                                            ],
                                            staticClass: "dpb",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "text",
                                              readonly: "",
                                            },
                                            domProps: {
                                              value:
                                                _vm.deliveryInfo.delivTemp
                                                  .rfaddr,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.deliveryInfo.delivTemp,
                                                  "rfaddr",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.deliveryInfo.delivTemp
                                                    .rfaddrdetail,
                                                expression:
                                                  "deliveryInfo.delivTemp.rfaddrdetail",
                                              },
                                            ],
                                            staticClass: "dpb",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "text",
                                              readonly: "",
                                            },
                                            domProps: {
                                              value:
                                                _vm.deliveryInfo.delivTemp
                                                  .rfaddrdetail,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.deliveryInfo.delivTemp,
                                                  "rfaddrdetail",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "small-txt" },
                                            [
                                              _vm._v(
                                                " [" +
                                                  _vm._s(
                                                    _vm.$util.isNull(
                                                      _vm.deliveryInfo.delivTemp
                                                        .rfaddr
                                                    )
                                                      ? " "
                                                      : "도로명"
                                                  ) +
                                                  "] " +
                                                  _vm._s(
                                                    _vm.deliveryInfo.delivTemp
                                                      .rfaddr
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.deliveryInfo.delivTemp
                                                      .rfaddrdetail
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "boxing", attrs: { id: "goodsTab6" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "boxing-title",
                        class: { closed: !_vm.tabObject.goodsTab6.isOpen },
                      },
                      [
                        _vm._v(" 상품정보고시 "),
                        _c("i", {
                          staticClass: "arrcodi",
                          on: {
                            click: function ($event) {
                              return _vm.onToggle(_vm.tabObject.goodsTab6)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-area",
                        style: {
                          display: _vm.tabObject.goodsTab6.isOpen
                            ? "block"
                            : "none",
                        },
                      },
                      [
                        _c("div", { staticStyle: { margin: "20px" } }, [
                          _c("div", { staticClass: "bar-title" }, [
                            _vm._v("상품정보고시"),
                          ]),
                          _c(
                            "fieldset",
                            {
                              attrs: {
                                disabled:
                                  !_vm.iscopy &&
                                  _vm.isPartner &&
                                  _vm.editauth.notify.isauth === false,
                              },
                            },
                            [
                              _c("legend", [_vm._v("상품정보고시 수정권한")]),
                              _c(
                                "table",
                                {
                                  staticClass: "gray-tb",
                                  attrs: { cellpadding: "0", cellspacing: "0" },
                                },
                                [
                                  _vm._m(36),
                                  _c("tbody", [
                                    _c("tr", [
                                      _vm._m(37),
                                      _c("td", [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.notifyInfo.notifytplidx,
                                                expression:
                                                  "notifyInfo.notifytplidx",
                                              },
                                            ],
                                            staticStyle: { width: "250px" },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.notifyInfo,
                                                    "notifytplidx",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                                _vm.getNotifyTempItemList,
                                              ],
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "" } },
                                              [_vm._v("상품군 선택")]
                                            ),
                                            _vm._l(
                                              _vm.notifyInfo.notifyTempList,
                                              function (item) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: item.idx,
                                                    domProps: {
                                                      value: item.idx,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.title) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                        _vm._m(38),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              ),
                              _vm.notifyInfo.notifyTempItemList.length > 0
                                ? _c("dl", [
                                    _c("dt", [
                                      _vm._v(
                                        _vm._s(_vm.notifyInfo.notifytpltitle)
                                      ),
                                    ]),
                                    _c("dd", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.notifyInfo.chkRef,
                                            expression: "notifyInfo.chkRef",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "alltxtD",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.notifyInfo.chkRef
                                          )
                                            ? _vm._i(
                                                _vm.notifyInfo.chkRef,
                                                null
                                              ) > -1
                                            : _vm.notifyInfo.chkRef,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setAllRefDetail(
                                              $event.target.checked
                                            )
                                          },
                                          change: function ($event) {
                                            var $$a = _vm.notifyInfo.chkRef,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.notifyInfo,
                                                    "chkRef",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.notifyInfo,
                                                    "chkRef",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.notifyInfo,
                                                "chkRef",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: "alltxtD" } },
                                        [
                                          _vm._v(
                                            '전체 "상품상세설명 참고"로 표기'
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c(
                                "table",
                                {
                                  staticClass: "gray-tb",
                                  attrs: { cellpadding: "0", cellspacing: "0" },
                                },
                                [
                                  _vm._m(39),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.notifyInfo.notifyTempItemList,
                                      function (item) {
                                        return _c(
                                          "tr",
                                          { key: item.notifyitemidx },
                                          [
                                            _c("th", [
                                              _vm._v(_vm._s(item.notifyname)),
                                            ]),
                                            _c("td", [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.notifydata,
                                                    expression:
                                                      "item.notifydata",
                                                  },
                                                ],
                                                attrs: {
                                                  placeholder:
                                                    "세부사항을 입력하세요!",
                                                  maxlength: "1000",
                                                },
                                                domProps: {
                                                  value: item.notifydata,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      item,
                                                      "notifydata",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "text-align": "right",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " (" +
                                                      _vm._s(
                                                        _vm.$util.isNull(
                                                          item.notifydata
                                                        )
                                                          ? "0"
                                                          : item.notifydata
                                                              .length
                                                      ) +
                                                      "/1000) "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._m(40),
                          _c(
                            "table",
                            {
                              staticClass: "gray-tb",
                              attrs: { cellpadding: "0", cellspacing: "0" },
                            },
                            [
                              _vm._m(41),
                              _c("tbody", [
                                _c("tr", [
                                  _c("th", [_vm._v("대상여부")]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "radio_wrap" },
                                      _vm._l(
                                        _vm.commonCode.kcdivtype,
                                        function (item) {
                                          return _c(
                                            "div",
                                            { key: item.cmcode },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.notifyInfo.kcdivtype,
                                                    expression:
                                                      "notifyInfo.kcdivtype",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "radio",
                                                  name: "kcdivtypeD",
                                                  id:
                                                    "kcdivtypeD_" + item.cmcode,
                                                },
                                                domProps: {
                                                  value: item.cmcode,
                                                  checked: _vm._q(
                                                    _vm.notifyInfo.kcdivtype,
                                                    item.cmcode
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      _vm.notifyInfo,
                                                      "kcdivtype",
                                                      item.cmcode
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for:
                                                      "kcdivtypeD_" +
                                                      item.cmcode,
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.codename))]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]),
                                _vm.notifyInfo.kcdivtype ==
                                _vm.$store.getters["ADMIN"].KC_DIV_TYPE_KDT002
                                  ? _c("tr", [
                                      _c("th", [_vm._v("인증선택")]),
                                      _c(
                                        "td",
                                        _vm._l(
                                          _vm.notifyInfo.kccertlist,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: item.idx,
                                                staticClass: "dpb",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: item.kccertno,
                                                      expression:
                                                        "item.kccertno",
                                                    },
                                                  ],
                                                  staticClass: "ml3",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      "인증번호입력(-포함)",
                                                    maxlength: "30",
                                                  },
                                                  domProps: {
                                                    value: item.kccertno,
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          item,
                                                          "kccertno",
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        item.iscert = ""
                                                      },
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn blue-line ml3",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.kcCertification(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 인증확인 ")]
                                                ),
                                                _c("button", {
                                                  staticClass: "add",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addKccertItem(
                                                        index
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm.notifyInfo.kccertlist
                                                  .length > 1
                                                  ? _c("button", {
                                                      staticClass: "del",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeKccertItem(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          item.iscert == "T",
                                                        expression:
                                                          "item.iscert == 'T'",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "left-bar txt-blue",
                                                  },
                                                  [_vm._v("적합")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          item.iscert == "F",
                                                        expression:
                                                          "item.iscert == 'F'",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "left-bar txt-red",
                                                  },
                                                  [_vm._v("부 적합")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: _vm.$util.isNull(
                                                          item.iscert
                                                        ),
                                                        expression:
                                                          "$util.isNull(item.iscert)",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "left-bar txt-orange",
                                                  },
                                                  [_vm._v(" 미 인증 ")]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.notifyInfo.kcdivtype ==
                                _vm.$store.getters["ADMIN"].KC_DIV_TYPE_KDT002
                                  ? _c("tr", [
                                      _c("th", [_vm._v("파일첨부")]),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn blue-line",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.fileAttach(
                                                    "kcFile"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 파일첨부 ")]
                                          ),
                                          _vm._l(
                                            _vm.files.kcfilelist,
                                            function (item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: "kcFile" + index,
                                                  staticClass: "dpib",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "file-link",
                                                      style: item.isimage
                                                        ? ""
                                                        : "color: #666 !important; text-decoration: none !important",
                                                      attrs: {
                                                        href: "javascript:void(0);",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.viewKcFile(
                                                            item,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.status == "N"
                                                              ? item.imgforiname
                                                              : item.file.name
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("button", {
                                                    staticClass: "file-del",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeFile(
                                                          "kcFile",
                                                          index
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            }
                                          ),
                                          _c("input", {
                                            ref: "kcFile",
                                            attrs: {
                                              type: "file",
                                              id: "input-fileD",
                                              accept:
                                                "image/jpeg, image/png, .pdf",
                                              hidden: "",
                                              multiple: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleFileUpload(
                                                  "kcFile",
                                                  $event.target
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        2
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "boxing", attrs: { id: "goodsTab7" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "boxing-title",
                        class: { closed: !_vm.tabObject.goodsTab7.isOpen },
                      },
                      [
                        _vm._v(" 추가정보 "),
                        _c("i", {
                          staticClass: "arrcodi",
                          on: {
                            click: function ($event) {
                              return _vm.onToggle(_vm.tabObject.goodsTab7)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-area",
                        style: {
                          display: _vm.tabObject.goodsTab7.isOpen
                            ? "block"
                            : "none",
                        },
                      },
                      [
                        _c("div", { staticStyle: { margin: "20px" } }, [
                          _c(
                            "table",
                            {
                              staticClass: "gray-tb",
                              attrs: { cellpadding: "0", cellspacing: "0" },
                            },
                            [
                              _vm._m(42),
                              _c("tbody", [
                                _c("tr", [
                                  _vm._m(43),
                                  _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "radio_wrap wide" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.etcInfo.isepif,
                                              expression: "etcInfo.isepif",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: "isepif",
                                            id: "isepifT",
                                            value: "T",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.etcInfo.isepif,
                                              "T"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.etcInfo,
                                                "isepif",
                                                "T"
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          { attrs: { for: "isepifT" } },
                                          [_vm._v("연동")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.etcInfo.isepif,
                                              expression: "etcInfo.isepif",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: "isepif",
                                            id: "isepifF",
                                            value: "F",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.etcInfo.isepif,
                                              "F"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.etcInfo,
                                                "isepif",
                                                "F"
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          { attrs: { for: "isepifF" } },
                                          [_vm._v("미연동")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("tr", [
                                  _vm._m(44),
                                  _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.etcInfo.aswarmonth,
                                          expression: "etcInfo.aswarmonth",
                                        },
                                      ],
                                      staticStyle: { width: "70px" },
                                      attrs: { type: "text", maxlength: "3" },
                                      domProps: {
                                        value: _vm.etcInfo.aswarmonth,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.etcInfo,
                                            "aswarmonth",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c("span", { staticClass: "ml3" }, [
                                      _vm._v("개월"),
                                    ]),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c(
                                    "th",
                                    { staticStyle: { width: "170px" } },
                                    [_vm._v("A/S 안내문구")]
                                  ),
                                  _c("td", [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.etcInfo.asnotice,
                                          expression: "etcInfo.asnotice",
                                        },
                                      ],
                                      attrs: {
                                        placeholder:
                                          "A/S 안내문구를 입력하세요.",
                                        maxlength: "500",
                                      },
                                      domProps: { value: _vm.etcInfo.asnotice },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.etcInfo,
                                            "asnotice",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                !_vm.isPartner
                                  ? _c("tr", [
                                      _c(
                                        "th",
                                        { staticStyle: { width: "170px" } },
                                        [_vm._v("회원유형 별 노출여부")]
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "check-wrap" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.etcInfo.isallmember,
                                                    expression:
                                                      "etcInfo.isallmember",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id: "chkAllMemberD",
                                                  "true-value": "T",
                                                  "false-value": "F",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.etcInfo.isallmember
                                                  )
                                                    ? _vm._i(
                                                        _vm.etcInfo.isallmember,
                                                        null
                                                      ) > -1
                                                    : _vm._q(
                                                        _vm.etcInfo.isallmember,
                                                        "T"
                                                      ),
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$a =
                                                          _vm.etcInfo
                                                            .isallmember,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? "T"
                                                          : "F"
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.etcInfo,
                                                              "isallmember",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.etcInfo,
                                                              "isallmember",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.etcInfo,
                                                          "isallmember",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    _vm.checkAllMembertype,
                                                  ],
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "chkAllMemberD",
                                                  },
                                                },
                                                [_vm._v("전체")]
                                              ),
                                            ]
                                          ),
                                          _vm._l(
                                            _vm.commonCode.dadamembertype,
                                            function (item) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.cmcode,
                                                  staticClass: "check-wrap",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.etcInfo
                                                            .mumembertypeArr,
                                                        expression:
                                                          "etcInfo.mumembertypeArr",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      id:
                                                        "mumembertypeD_" +
                                                        item.cmcode,
                                                      "true-value": [],
                                                    },
                                                    domProps: {
                                                      value: item.cmcode,
                                                      checked: Array.isArray(
                                                        _vm.etcInfo
                                                          .mumembertypeArr
                                                      )
                                                        ? _vm._i(
                                                            _vm.etcInfo
                                                              .mumembertypeArr,
                                                            item.cmcode
                                                          ) > -1
                                                        : _vm._q(
                                                            _vm.etcInfo
                                                              .mumembertypeArr,
                                                            []
                                                          ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.etcInfo
                                                              .mumembertypeArr,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? []
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = item.cmcode,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm.etcInfo,
                                                                "mumembertypeArr",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm.etcInfo,
                                                                "mumembertypeArr",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm.etcInfo,
                                                            "mumembertypeArr",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for:
                                                          "mumembertypeD_" +
                                                          item.cmcode,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.codename)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ])
                                  : _vm._e(),
                                !_vm.isPartner
                                  ? _c("tr", [
                                      _c(
                                        "th",
                                        { staticStyle: { width: "170px" } },
                                        [_vm._v("리뷰 공개여부")]
                                      ),
                                      _c("td", [
                                        _c(
                                          "div",
                                          { staticClass: "radio_wrap wide" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.etcInfo.isopenreview,
                                                  expression:
                                                    "etcInfo.isopenreview",
                                                },
                                              ],
                                              attrs: {
                                                type: "radio",
                                                name: "isopenreviewD",
                                                id: "isopenreviewDT",
                                                value: "T",
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.etcInfo.isopenreview,
                                                  "T"
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    _vm.etcInfo,
                                                    "isopenreview",
                                                    "T"
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "isopenreviewDT",
                                                },
                                              },
                                              [_vm._v("공개")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.etcInfo.isopenreview,
                                                  expression:
                                                    "etcInfo.isopenreview",
                                                },
                                              ],
                                              attrs: {
                                                type: "radio",
                                                name: "isopenreviewD",
                                                id: "isopenreviewDF",
                                                value: "F",
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.etcInfo.isopenreview,
                                                  "F"
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    _vm.etcInfo,
                                                    "isopenreview",
                                                    "F"
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "isopenreviewDF",
                                                },
                                              },
                                              [_vm._v("비공개")]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                !_vm.isPartner
                                  ? _c("tr", [
                                      _c(
                                        "th",
                                        { staticStyle: { width: "170px" } },
                                        [_vm._v("리뷰묶어보기 상품")]
                                      ),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "caption-group clearfix",
                                          },
                                          [
                                            _vm._m(45),
                                            _c(
                                              "div",
                                              { staticClass: "btn-group fr" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn blue-line",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openGoodsAdditionPopup(
                                                          "review"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 상품추가 ")]
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn red-line",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeGoodsAddition(
                                                          "review"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 삭제 ")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "scroll-y",
                                            staticStyle: {
                                              width: "100%",
                                              "max-height": "500px",
                                              "margin-bottom": "0",
                                            },
                                          },
                                          [
                                            _c(
                                              "table",
                                              {
                                                staticClass: "data-tb align-c",
                                                attrs: {
                                                  cellpadding: "0",
                                                  cellspacing: "0",
                                                },
                                              },
                                              [
                                                _vm._m(46),
                                                _c("thead", [
                                                  _c("tr", [
                                                    _c("th", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.etcInfo
                                                                .isallchkgoodsgrp,
                                                            expression:
                                                              "etcInfo.isallchkgoodsgrp",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "checkbox",
                                                          id: "chkallgoodsgrpD",
                                                          "true-value": "T",
                                                          "false-value": "F",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.etcInfo
                                                                .isallchkgoodsgrp
                                                            )
                                                              ? _vm._i(
                                                                  _vm.etcInfo
                                                                    .isallchkgoodsgrp,
                                                                  null
                                                                ) > -1
                                                              : _vm._q(
                                                                  _vm.etcInfo
                                                                    .isallchkgoodsgrp,
                                                                  "T"
                                                                ),
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$a =
                                                                  _vm.etcInfo
                                                                    .isallchkgoodsgrp,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? "T"
                                                                    : "F"
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.etcInfo,
                                                                      "isallchkgoodsgrp",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.etcInfo,
                                                                      "isallchkgoodsgrp",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.etcInfo,
                                                                  "isallchkgoodsgrp",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                            function ($event) {
                                                              return _vm.checkAllGoodsGrpList(
                                                                $event.target
                                                                  .checked
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                    ]),
                                                    _c("th", [_vm._v("No")]),
                                                    _c("th", [
                                                      _vm._v("상품코드"),
                                                    ]),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { colspan: "2" },
                                                      },
                                                      [_vm._v("상품명")]
                                                    ),
                                                    _c("th", [
                                                      _vm._v("판매가"),
                                                    ]),
                                                    _c("th", [
                                                      _vm._v("판매상태"),
                                                    ]),
                                                    _c("th", [
                                                      _vm._v("등록일자"),
                                                    ]),
                                                  ]),
                                                ]),
                                                _vm.etcInfo.goodsGrpList
                                                  .length > 0
                                                  ? _c(
                                                      "tbody",
                                                      _vm._l(
                                                        _vm.etcInfo
                                                          .goodsGrpList,
                                                        function (item, index) {
                                                          return _c(
                                                            "tr",
                                                            { key: index },
                                                            [
                                                              _c("td", [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        item.ischecked,
                                                                      expression:
                                                                        "item.ischecked",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                    id:
                                                                      "goodsgrpaddD_" +
                                                                      index,
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        item.ischecked
                                                                      )
                                                                        ? _vm._i(
                                                                            item.ischecked,
                                                                            null
                                                                          ) > -1
                                                                        : item.ischecked,
                                                                  },
                                                                  on: {
                                                                    change: [
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            item.ischecked,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              _vm.$set(
                                                                                item,
                                                                                "ischecked",
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                )
                                                                              )
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              _vm.$set(
                                                                                item,
                                                                                "ischecked",
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                              )
                                                                          }
                                                                        } else {
                                                                          _vm.$set(
                                                                            item,
                                                                            "ischecked",
                                                                            $$c
                                                                          )
                                                                        }
                                                                      },
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.checkGoodsGrpList(
                                                                          $event
                                                                            .target
                                                                            .checked
                                                                        )
                                                                      },
                                                                    ],
                                                                  },
                                                                }),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    index + 1
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.goodsno
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "img-thumb size60",
                                                                    class: {
                                                                      "no-image":
                                                                        _vm.$util.isNull(
                                                                          item.fullpath
                                                                        ),
                                                                    },
                                                                  },
                                                                  [
                                                                    !_vm.$util.isNull(
                                                                      item.fullpath
                                                                    )
                                                                      ? _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src: item.fullpath,
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "left no-left",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "small-txt",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.fullcategoryname
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "mg0",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.goodsname
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$util.maskComma(
                                                                      item.price
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.goodsdivtypename
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.regdate
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _c("tbody", [_vm._m(47)]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _c("tr", [
                                  _c(
                                    "th",
                                    { staticStyle: { width: "170px" } },
                                    [_vm._v("추가상품")]
                                  ),
                                  _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "caption-group clearfix" },
                                      [
                                        _vm._m(48),
                                        _c(
                                          "div",
                                          { staticClass: "btn-group fr" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn blue-line",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openGoodsAdditionPopup(
                                                      "addgoods"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 상품추가 ")]
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn red-line",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeGoodsAddition(
                                                      "addgoods"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 삭제 ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "scroll-y",
                                        staticStyle: {
                                          width: "100%",
                                          "max-height": "500px",
                                          "margin-bottom": "0",
                                        },
                                      },
                                      [
                                        _c(
                                          "table",
                                          {
                                            staticClass: "data-tb align-c",
                                            attrs: {
                                              cellpadding: "0",
                                              cellspacing: "0",
                                            },
                                          },
                                          [
                                            _vm._m(49),
                                            _c("thead", [
                                              _c("tr", [
                                                _c("th", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.etcInfo
                                                            .isallchkgoods,
                                                        expression:
                                                          "etcInfo.isallchkgoods",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      id: "chkallgoodsaddD",
                                                      "true-value": "T",
                                                      "false-value": "F",
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.etcInfo
                                                          .isallchkgoods
                                                      )
                                                        ? _vm._i(
                                                            _vm.etcInfo
                                                              .isallchkgoods,
                                                            null
                                                          ) > -1
                                                        : _vm._q(
                                                            _vm.etcInfo
                                                              .isallchkgoods,
                                                            "T"
                                                          ),
                                                    },
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$a =
                                                              _vm.etcInfo
                                                                .isallchkgoods,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? "T"
                                                              : "F"
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  _vm.etcInfo,
                                                                  "isallchkgoods",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  _vm.etcInfo,
                                                                  "isallchkgoods",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              _vm.etcInfo,
                                                              "isallchkgoods",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                        function ($event) {
                                                          return _vm.checkAllGoodsAddList(
                                                            $event.target
                                                              .checked
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  }),
                                                ]),
                                                _c("th", [_vm._v("No")]),
                                                _c("th", [_vm._v("상품코드")]),
                                                _c(
                                                  "th",
                                                  { attrs: { colspan: "2" } },
                                                  [_vm._v("상품명")]
                                                ),
                                                _c("th", [_vm._v("판매가")]),
                                                _c("th", [_vm._v("판매상태")]),
                                                _c("th", [_vm._v("등록일자")]),
                                              ]),
                                            ]),
                                            _vm.etcInfo.goodsAdditionList
                                              .length > 0
                                              ? _c(
                                                  "tbody",
                                                  _vm._l(
                                                    _vm.etcInfo
                                                      .goodsAdditionList,
                                                    function (item, index) {
                                                      return _c(
                                                        "tr",
                                                        { key: index },
                                                        [
                                                          _c("td", [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    item.ischecked,
                                                                  expression:
                                                                    "item.ischecked",
                                                                },
                                                              ],
                                                              attrs: {
                                                                type: "checkbox",
                                                                id:
                                                                  "goodsaddD_" +
                                                                  index,
                                                              },
                                                              domProps: {
                                                                checked:
                                                                  Array.isArray(
                                                                    item.ischecked
                                                                  )
                                                                    ? _vm._i(
                                                                        item.ischecked,
                                                                        null
                                                                      ) > -1
                                                                    : item.ischecked,
                                                              },
                                                              on: {
                                                                change: [
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        item.ischecked,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c =
                                                                        $$el.checked
                                                                          ? true
                                                                          : false
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v =
                                                                          null,
                                                                        $$i =
                                                                          _vm._i(
                                                                            $$a,
                                                                            $$v
                                                                          )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          _vm.$set(
                                                                            item,
                                                                            "ischecked",
                                                                            $$a.concat(
                                                                              [
                                                                                $$v,
                                                                              ]
                                                                            )
                                                                          )
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          _vm.$set(
                                                                            item,
                                                                            "ischecked",
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              )
                                                                          )
                                                                      }
                                                                    } else {
                                                                      _vm.$set(
                                                                        item,
                                                                        "ischecked",
                                                                        $$c
                                                                      )
                                                                    }
                                                                  },
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.checkGoodsAddList(
                                                                      $event
                                                                        .target
                                                                        .checked
                                                                    )
                                                                  },
                                                                ],
                                                              },
                                                            }),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(index + 1)
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.goodsno
                                                              )
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "img-thumb size60",
                                                                class: {
                                                                  "no-image":
                                                                    _vm.$util.isNull(
                                                                      item.fullpath
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                !_vm.$util.isNull(
                                                                  item.fullpath
                                                                )
                                                                  ? _c("img", {
                                                                      attrs: {
                                                                        src: item.fullpath,
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "left no-left",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "small-txt",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.fullcategoryname
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "mg0",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.goodsname
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$util.maskComma(
                                                                  item.price
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.goodsdivtypename
                                                              )
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.regdate
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c("tbody", [_vm._m(50)]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c(
                                    "th",
                                    { staticStyle: { width: "170px" } },
                                    [_vm._v("관리자 메모")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        width: "calc(100%-170px)",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "memo-write" }, [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.etcInfo.inputmemo,
                                              expression: "etcInfo.inputmemo",
                                            },
                                          ],
                                          attrs: {
                                            placeholder:
                                              "메모를 작성해 주세요!",
                                          },
                                          domProps: {
                                            value: _vm.etcInfo.inputmemo,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.etcInfo,
                                                "inputmemo",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn big blue",
                                            attrs: { type: "button" },
                                            on: { click: _vm.addMeno },
                                          },
                                          [
                                            _vm._v(" 메모 "),
                                            _c("br"),
                                            _vm._v(" 저장 "),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "scroll-y",
                                          staticStyle: {
                                            width: "100%",
                                            "max-height": "300px",
                                            "margin-bottom": "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "table",
                                            {
                                              staticClass: "data-tb align-c",
                                              attrs: {
                                                cellpadding: "0",
                                                cellspacing: "0",
                                              },
                                            },
                                            [
                                              _vm._m(51),
                                              _c("thead", [
                                                _c("tr", [
                                                  _c("th", [_vm._v("No")]),
                                                  _c("th", [
                                                    _vm._v(" 작성일시 "),
                                                    _c("button", {
                                                      staticClass: "sort",
                                                      class: [
                                                        {
                                                          up:
                                                            _vm.sortData.memo
                                                              .regdate ===
                                                            "regdate_asc",
                                                        },
                                                        {
                                                          down:
                                                            _vm.sortData.memo
                                                              .regdate ===
                                                            "regdate_desc",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "button",
                                                        value:
                                                          _vm.sortData.memo
                                                            .regdate,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.sortToggle(
                                                            "memo",
                                                            _vm.sortData.memo
                                                              .regdate
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]),
                                                  _c("th", [_vm._v("작성자")]),
                                                  _c(
                                                    "th",
                                                    { attrs: { colspan: "2" } },
                                                    [_vm._v("내용")]
                                                  ),
                                                ]),
                                              ]),
                                              _vm.etcInfo.isshowmemo
                                                ? _c(
                                                    "tbody",
                                                    _vm._l(
                                                      _vm.etcInfo.goodsMemoList,
                                                      function (item) {
                                                        return _c(
                                                          "tr",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  item.istrash ==
                                                                  "F",
                                                                expression:
                                                                  "item.istrash == 'F'",
                                                              },
                                                            ],
                                                            key: item.idx,
                                                          },
                                                          [
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(item.no)
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.regdate
                                                                )
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.regusername
                                                                )
                                                              ),
                                                            ]),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "left",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.memo
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "no-left",
                                                              },
                                                              [
                                                                _c("button", {
                                                                  staticClass:
                                                                    "del mg0",
                                                                  attrs: {
                                                                    type: "button",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeMeno(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _c("tbody", [_vm._m(52)]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm.sellInfo.orggoodsselltype !=
            _vm.$store.getters["ADMIN"].GOODS_SELL_TYPE_GST006
              ? _c("div", { staticClass: "btn-group" }, [
                  !_vm.$util.isNull(_vm.basicInfo.goodscode) && !_vm.iscopy
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big blue-line",
                          attrs: { type: "button" },
                          on: { click: _vm.goPriview },
                        },
                        [_vm._v(" 미리보기 ")]
                      )
                    : _vm._e(),
                  !_vm.iscopy
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big gray",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.iscopy = true
                            },
                          },
                        },
                        [_vm._v(" 복사 ")]
                      )
                    : _vm._e(),
                  _vm.iscopy
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big blue",
                          attrs: { type: "button" },
                          on: { click: _vm.goTemporarySave },
                        },
                        [_vm._v(" 임시저장 ")]
                      )
                    : _vm._e(),
                  !_vm.isPartner &&
                  !_vm.iscopy &&
                  _vm.basicInfo.goodsapprtype ===
                    _vm.$store.getters["ADMIN"].GOODS_STATUS_REQ
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big blue",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.changeGoodsAppr(
                                _vm.$store.getters["ADMIN"]
                                  .GOODS_STATUS_APPROVAL
                              )
                            },
                          },
                        },
                        [_vm._v(" 승인 ")]
                      )
                    : _vm._e(),
                  !_vm.isPartner &&
                  !_vm.iscopy &&
                  _vm.basicInfo.goodsapprtype ===
                    _vm.$store.getters["ADMIN"].GOODS_STATUS_REQ
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big red",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.changeGoodsAppr(
                                _vm.$store.getters["ADMIN"].GOODS_STATUS_REJECT
                              )
                            },
                          },
                        },
                        [_vm._v(" 반려 ")]
                      )
                    : _vm._e(),
                  !_vm.isPartner ||
                  (_vm.isPartner &&
                    !_vm.iscopy &&
                    _vm.basicInfo.goodsapprtype ===
                      _vm.$store.getters["ADMIN"].GOODS_STATUS_APPROVAL)
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big blue",
                          attrs: { type: "button" },
                          on: { click: _vm.goSave },
                        },
                        [_vm._v(" 저장 ")]
                      )
                    : _vm._e(),
                  _vm.isPartner &&
                  !_vm.iscopy &&
                  _vm.basicInfo.goodsapprtype ===
                    _vm.$store.getters["ADMIN"].GOODS_STATUS_REJECT
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big red",
                          attrs: { type: "button" },
                          on: { click: _vm.goReqApprv },
                        },
                        [_vm._v(" 재승인요청 ")]
                      )
                    : _vm._e(),
                  _vm.isPartner &&
                  (_vm.iscopy ||
                    (!_vm.iscopy &&
                      _vm.basicInfo.goodsapprtype ===
                        _vm.$store.getters["ADMIN"].GOODS_STATUS_TEMP))
                    ? _c(
                        "button",
                        {
                          staticClass: "btn big red",
                          attrs: { type: "button" },
                          on: { click: _vm.goReqApprv },
                        },
                        [_vm._v(" 승인요청 ")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn big darkgray pop-close",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closePopup")
                        },
                      },
                    },
                    [_vm._v(" 닫기 ")]
                  ),
                ])
              : _vm._e(),
            _vm.sellInfo.orggoodsselltype ==
            _vm.$store.getters["ADMIN"].GOODS_SELL_TYPE_GST006
              ? _c("div", { staticClass: "btn-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn big darkgray pop-close",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closePopup")
                        },
                      },
                    },
                    [_vm._v(" 닫기 ")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [
      _vm._v(" 카테고리 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [_c("a", [_vm._v("조회 결과가 존재하지 않습니다.")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [
      _c("input", { attrs: { type: "checkbox" } }),
      _c("label", [_vm._v("조회 결과가 존재하지 않습니다.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("대분류")]),
        _c("th", [_vm._v("중분류")]),
        _c("th", [_vm._v("소분류")]),
        _c("th", [_vm._v("세분류")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [_vm._v(" 상품명 "), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [_vm._v(" 브랜드 "), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [_vm._v(" 담당MD "), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [
      _c("td", { attrs: { colspan: "7" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [
      _vm._v(" 판매가격(대표상품) "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [
      _vm._v(" 최소~최대 주문수량 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [
      _vm._v(" 1일 최대 주문수량 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [
      _vm._v(" 1인당 최대 구매수량 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "total-group fl" }, [
      _c("span", { staticClass: "total" }, [_vm._v("특정회원목록")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [
      _c("td", { attrs: { colspan: "7" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", [
      _c("li", [
        _vm._v("정상가, 판매가, 수수료율, 상품명은 필수 입력 항목입니다."),
      ]),
      _c("li", [_vm._v("단품은 그리드에서 행 추가 후 등록하시면 편리합니다.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", [
      _c("li", [
        _vm._v("정상가, 판매가, 상품명, 안전재고는 필수 입력 항목입니다."),
      ]),
      _c("li", [
        _vm._v(
          " 필수 항목 입력 후 Original상품코드와 매핑(연결)을 해주셔야 정상적으로 등록이 가능합니다. "
        ),
      ]),
      _c("li", [_vm._v("단품은 그리드에서 행 추가 후 등록하시면 편리합니다.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 상품설명 등록담당 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 대표이미지(PC) "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "img-with-text text" }, [
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          " 판매상품의 대표 이미지입니다. 보기 쉬운 간결한 이미지를 활용해 주세요. "
        ),
      ]),
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(" 사이즈: 720*720 / 용량: 2MB 이하 / 파일 : JPG, JPEG, PNG "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 대표이미지(모바일) "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "img-with-text text" }, [
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          " 모바일 리스팅 및 와이드형 화면에 노출되는 이미지를 업로드 해 주세요. "
        ),
      ]),
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(" 사이즈: 720*720 / 용량: 2MB 이하 / 파일 : JPG, JPEG, PNG "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "img-with-text text" }, [
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(" 동영상 포스터의 PC 이미지를 업로드 해 주세요. "),
      ]),
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(" 용량: 2MB 이하 / 파일 : JPG, JPEG, PNG "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "img-with-text text" }, [
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(" 동영상 포스터의 모바일 이미지를 업로드 해 주세요. "),
      ]),
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(" 용량: 2MB 이하 / 파일 : JPG, JPEG, PNG "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "img-with-text text" }, [
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          " 모바일 리스팅 및 와이드형 화면에 노출되는 영상을 업로드 해 주세요. "
        ),
      ]),
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(" 용량: 20MB 이하 / 파일 : MP4 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "img-with-text text" }, [
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          " 모바일 리스팅 및 와이드형 화면에 노출되는 영상을 업로드 해 주세요. "
        ),
      ]),
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(" 용량: 20MB 이하 / 파일 : MP4 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", { staticStyle: { width: "170px" } }, [
      _vm._v(" PC용 상품상세설명 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", { staticStyle: { width: "170px" } }, [
      _vm._v(" 모바일용 상품상세설명 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(" 자주 쓰는 배송정보는 템플릿으로 관리하시면 편리합니다. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "txt-orange" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(" 최대 30자까지만 입력이 가능합니다. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" 상품정보고시 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "txt-orange ml3" }, [
      _c("i", { staticClass: "icon-alert" }),
      _vm._v(" 상품정보 고시에 따른 세부 사항을 정확히 기재해 주셔야 합니다. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "bar-title fl" }, [_vm._v("KC인증")]),
      _c("div", { staticClass: "fr" }, [
        _c("p", { staticClass: "txt-orange" }, [
          _c("i", { staticClass: "icon-alert" }),
          _vm._v(
            " 사실과 다른 인증정보를 입력하실 경우, 관련법에 의하여 처벌받으실 수 있으니, 정확한 정보만 입력해주시기 바랍니다. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v(" EP연동여부 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", { staticStyle: { width: "170px" } }, [
      _vm._v(" A/S 보증기간 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "total-group fl" }, [
      _c("span", { staticClass: "total" }, [_vm._v("리뷰묶어보기 상품목록")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "8%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [
      _c("td", { attrs: { colspan: "8" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "total-group fl" }, [
      _c("span", { staticClass: "total" }, [_vm._v("추가상품목록")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "3%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "8%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [
      _c("td", { attrs: { colspan: "8" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "40px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [
      _c("td", { attrs: { colspan: "5" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }