<template>
  <!-- 풋터 영역 -->
  <div class="footer m-leftmenu">Copyright ⓒ D.PLOT All rights reserved.</div>
  <!-- /풋터 영역 -->
</template>

<script>
export default {
  name: 'CommonFooter',
};
</script>
