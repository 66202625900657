// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/paging-left.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/paging-right.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.page-navigator-wrap[data-v-11c33b2d] {\n  display: flex;\n  justify-content: center;\n}\n.page-navigator-wrap button[data-v-11c33b2d] {\n  border: none;\n  margin: 0;\n  padding: 0;\n  overflow: visible;\n  background: transparent;\n  -webkit-font-smoothing: inherit;\n  -moz-osx-font-smoothing: inherit;\n  -webkit-appearance: none;\n  width: 30px;\n  height: 30px;\n  line-height: 30px;\n  color: #999;\n  box-sizing: border-box;\n  vertical-align: middle;\n}\n.page-navigator-wrap li button.arrow.left[data-v-11c33b2d] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n}\n.page-navigator-wrap li button.arrow.right[data-v-11c33b2d] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") center center no-repeat;\n}\n.page-navigator-wrap li button.active[data-v-11c33b2d] {\n  color: #fff;\n  font-weight: 700;\n  background: #167cd8;\n  border: 1px solid #0c68bb;\n  border-radius: 3px;\n}\n", ""]);
// Exports
module.exports = exports;
