import http from '@/js/http.js';
import util from '@/js/util.js';
import axios from 'axios';

export const PRESIGNED_UPLOAD_FILE_TYPE = {
  이미지: 'IMAGE',
  엑셀: 'EXCEL',
};

export const presignedUpload = async (params) => {
  const { file, type, uploadPath, isloading = false } = params;
  try {
    if (!file) {
      return alert('업로드 파일이 없습니다.');
    }

    const extension = file.name.split('.').pop();
    const { data } = await http.get('/v2/file/pre-signed-url', {
      params: {
        extension,
        type,
        uploadPath,
        isloading,
      },
    });

    const uploadaxiosAxiosInstance = axios.create({
      headers: {
        'Content-Type': file.type,
      },
    });

    const { status } = await uploadaxiosAxiosInstance.put(data.preSignedUrl, file, {
      withCredentials: false,
    });

    if (status === 200) {
      return data;
    }

    return null;
  } catch (error) {
    util.debug(error);
    return null;
  }
};
