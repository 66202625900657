<template>
  <div class="content m-leftmenu">
    <common-navigator></common-navigator>
    <div class="inner">
      <div class="sub-title">
        <div class="bar-title">QR Code 정보 입력</div>
        <button type="button" class="btn big blue" @click="createQrcode">생성</button>
      </div>
      <div class="form-area">
        <table cellpadding="0" cellspacing="0" class="gray-tb">
          <colgroup>
            <col width="170px" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th>링크<i class="essential"></i></th>
              <td>
                <input
                  ref="linkRef"
                  type="text"
                  style="width: calc(100% - 100px)"
                  placeholder="QR Code 리더기 통해서 연결되는 주소"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="qr-section">
        <div class="sub-title">
          <div class="bar-title">QR Code</div>
          <button type="button" class="btn big blue" @click="downloadQrImage">QR 다운로드</button>
        </div>
        <div class="boxing forbiddenword-group clearfix">
          <vue-qrcode
            type="image/png"
            :width="20"
            :quality="1"
            :color="{ dark: '#000000ff', light: '#ffffffff' }"
            :value="link"
            @change="onDataUrlChange"
          />
          <img class="qr-image" :src="base64QrCode" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode';
import CommonNavigator from '@views.admin/common/CommonNavigator.vue';
export default {
  name: 'admin.operation.setting.qrcode',
  components: {
    CommonNavigator,
    VueQrcode,
  },
  data() {
    return {
      link: '',
      base64QrCode: '',
    };
  },
  methods: {
    createQrcode() {
      const link = this.$refs.linkRef.value;
      if (!this.$util.isURL(link)) {
        alert('URL 패턴에 맞춰 입력해주세요.');
        return;
      }

      this.link = link;
    },
    onDataUrlChange(data) {
      this.base64QrCode = data;
    },
    downloadQrImage() {
      if (!this.base64QrCode) {
        alert('QR 코드 생성 후 다운로드 해주세요.');
        return;
      }

      var link = document.createElement('a');
      link.download = 'Dplot_QR_Image.png';
      link.href = this.base64QrCode;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
.sub-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.qr-section {
  margin: 10px 0;
}

.boxing {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.qr-image {
  height: 400px;
}
</style>
