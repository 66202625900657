import { render, staticRenderFns } from "./PreparingDelivery.vue?vue&type=template&id=0b67efc9&scoped=true&"
import script from "./PreparingDelivery.vue?vue&type=script&lang=js&"
export * from "./PreparingDelivery.vue?vue&type=script&lang=js&"
import style0 from "./PreparingDelivery.vue?vue&type=style&index=0&id=0b67efc9&scoped=true&lang=css&"
import style1 from "./PreparingDelivery.vue?vue&type=style&index=1&id=0b67efc9&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b67efc9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildagent/work/3684aff05d5d78ea/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0b67efc9')) {
      api.createRecord('0b67efc9', component.options)
    } else {
      api.reload('0b67efc9', component.options)
    }
    module.hot.accept("./PreparingDelivery.vue?vue&type=template&id=0b67efc9&scoped=true&", function () {
      api.rerender('0b67efc9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/order/manage/PreparingDelivery.vue"
export default component.exports