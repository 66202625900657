<template>
  <div class="login-wrap" @keyup.enter="login">
    <img alt="" src="../../assets/img/logo-login.png" />
    <div class="input-id">
      <input v-model="id" placeholder="아이디" type="text" />
    </div>
    <div class="input-pw">
      <input v-model="pw" placeholder="비밀번호" type="password" />
    </div>
    <div class="clearfix">
      <div class="fl">
        <input id="rememberID" v-model="idRememberCheck" type="checkbox" />
        <label for="rememberID">아이디 기억하기</label>
      </div>
      <div class="fr">
        <a class="link" @click="adminFindPasswordPopup">비밀번호 찾기</a>
      </div>
    </div>
    <button class="btn blue btn-login" type="button" v-on:click="login">로그인</button>
    <div class="login-info">
      <ul>
        <li>본 시스템은 올리브영 임직원에 한하여 사용하실 수 있습니다.</li>
        <li>불법적인 접근 및 사용 시 관련 법규에 의해 처벌될 수 있습니다.</li>
      </ul>
    </div>
    <p class="login-copy">Copyright ⓒ D.PLOT All rights reserved.</p>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import AdminResetPasswordPopup from '@views.admin/common/popup/AdminResetPasswordPopup';
import AdminFindPasswordPopup from '@views.admin/common/popup/AdminFindPasswordPopup';

export default {
  name: 'AdminLogin',
  data() {
    return {
      showPopup: false,
      id: '',
      pw: '',
      idRememberCheck: false,
      isProd: process.env.VUE_APP_ENV === 'prod',
    };
  },
  mounted() {
    let rememberId = this.$cookies.get('remember_id');

    if (rememberId !== null) {
      this.id = rememberId;
    }

    let errorCode = this.$route.query.error;
    if ('401' === errorCode) {
      this.errorMsg('로그인 세션이 중복/만료 되었습니다. \n다시 로그인 바랍니다.');
      this.$router.push('/login');
    }
    this.showPopup = false;
  },
  methods: {
    debounceLogin: debounce(
      async function () {
        try {
          const param = {
            id: this.id,
            pw: this.pw,
            authKey: '',
            isloading: true,
          };

          if (this.$util.isNull(this.id)) {
            alert('아이디를 입력해주세요.');
            return;
          } else if (this.$util.isNull(this.pw)) {
            alert('비밀번호를 입력해주세요.');
            return;
          }
          const res = await this.$http.post('/admin/common/login', param);

          if (res.data.istemppw === 'T') {
            alert(
              '비밀번호 초기화 대상자 입니다.(비밀번호 변경이후 3개월 경과 혹은 최초 로그인시) \n초기화 후 재로그인 부탁드립니다.',
            );
            this.adminResetPasswordPopup(res.data.no);
          } else {
            param.authKey = res.data.authkey;
            const resAct = await this.$http.post('/admin/login_act', param);
            this.$storage.setLocalStorage(this.$store.getters.CONSTANTS.ADMIN_USER, resAct);
            this.$storage.setLocalStorage(this.$store.getters.CONSTANTS.MANAGER_SESSION, resAct);
            this.$storage.removeLocalStorage(this.$store.getters.CONSTANTS.PARTNER_USER);

            alert(
              '마지막 접속 일시 : ' +
                resAct.lastlogindate +
                '\n마지막 접속 IP : ' +
                resAct.lastloginip,
            );
            // 아이디 기억하기 체크시 쿠키에 7일 기한으로 저장
            this.idRememberCheck && this.$cookies.set('remember_id', this.id, '7d');

            this.$router.push({ name: 'admin.main.dashboard' });
          }
        } catch (err) {
          this.$util.debug(err);
        }
      },
      500,
      { leading: true, trailing: false },
    ),
    login() {
      this.debounceLogin();
    },
    errorMsg: function (msg) {
      alert(msg);
    },
    adminResetPasswordPopup(value) {
      let param = { no1: value };
      this.openModal(AdminResetPasswordPopup, param);
    },
    closePopup() {
      this.showPopup = false;
    },
    adminFindPasswordPopup() {
      this.openModal(AdminFindPasswordPopup);
    },
    joinPartner() {
      this.$router.push({ name: 'common.partnership.auth' });
    },
    openModal(comp, params, callbackFn) {
      this.$modal.show(
        comp,
        {
          modalComp: comp,
          params: params,
          callbackFn: callbackFn,
        },
        {
          name: 'commonModal',
          draggable: false,
          resizable: false,
          width: '100%',
          height: 'auto',
        },
      );
    },
  },
};
</script>
