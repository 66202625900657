var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1400px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("상품정보고시 일괄변경")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("closePopup")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "blue-box mg0" }, [
              _vm._v(
                " 총 " +
                  _vm._s(_vm.goodsnoList.length) +
                  "개 상품의 상품정보고시를 일괄 변경합니다. "
              ),
            ]),
            _vm._m(0),
            _c("div", { staticClass: "boxing mt10" }, [
              _c(
                "div",
                { staticClass: "form-area", staticStyle: { display: "block" } },
                [
                  _c("div", { staticStyle: { margin: "20px" } }, [
                    _c(
                      "table",
                      {
                        staticClass: "gray-tb",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _vm._m(1),
                        _c("tbody", [
                          _c("tr", [
                            _c("th", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.chkObject.notify.ischecked,
                                    expression: "chkObject.notify.ischecked",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "ischknotify",
                                  "true-value": "T",
                                  "false-value": "F",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.chkObject.notify.ischecked
                                  )
                                    ? _vm._i(
                                        _vm.chkObject.notify.ischecked,
                                        null
                                      ) > -1
                                    : _vm._q(
                                        _vm.chkObject.notify.ischecked,
                                        "T"
                                      ),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.chkObject.notify.ischecked,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? "T" : "F"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.chkObject.notify,
                                            "ischecked",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.chkObject.notify,
                                            "ischecked",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.chkObject.notify,
                                        "ischecked",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "ischknotify" } }, [
                                _vm._v("상품정보고시"),
                              ]),
                            ]),
                            _c("td", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.notifytplidx,
                                      expression: "info.notifytplidx",
                                    },
                                  ],
                                  staticStyle: { width: "250px" },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.info,
                                          "notifytplidx",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      _vm.getNotifyTempItemList,
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("상품군 선택"),
                                  ]),
                                  _vm._l(
                                    _vm.info.notifyTempList,
                                    function (item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.idx,
                                          domProps: { value: item.idx },
                                        },
                                        [_vm._v(" " + _vm._s(item.title) + " ")]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _vm.info.notifyTempItemList.length > 0
                      ? _c("dl", [
                          _c("dt", [_vm._v(_vm._s(_vm.info.notifytpltitle))]),
                          _c("dd", [
                            _c("input", {
                              attrs: { type: "checkbox", id: "alltxt" },
                              on: {
                                click: function ($event) {
                                  return _vm.setAllRefDetail(
                                    $event.target.checked
                                  )
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "alltxt" } }, [
                              _vm._v('전체 "상품상세설명 참고"로 표기'),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.info.notifyTempItemList.length > 0
                      ? _c(
                          "table",
                          {
                            staticClass: "gray-tb",
                            attrs: { cellpadding: "0", cellspacing: "0" },
                          },
                          [
                            _vm._m(2),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.info.notifyTempItemList,
                                function (item) {
                                  return _c("tr", { key: item.notifyitemidx }, [
                                    _c("th", [_vm._v(_vm._s(item.notifyname))]),
                                    _c("td", [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.notifydata,
                                            expression: "item.notifydata",
                                          },
                                        ],
                                        attrs: {
                                          placeholder: "세부사항을 입력하세요!",
                                          maxlength: "1000",
                                        },
                                        domProps: { value: item.notifydata },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              item,
                                              "notifydata",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " (" +
                                              _vm._s(
                                                _vm.$util.isNull(
                                                  item.notifydata
                                                )
                                                  ? "0"
                                                  : item.notifydata.length
                                              ) +
                                              "/1000) "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._m(3),
                    _c(
                      "table",
                      {
                        staticClass: "gray-tb",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _vm._m(4),
                        _c("tbody", [
                          _c("tr", [
                            _c("th", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.chkObject.kcdivtype.ischecked,
                                    expression: "chkObject.kcdivtype.ischecked",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "ischkkcdivtype",
                                  "true-value": "T",
                                  "false-value": "F",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.chkObject.kcdivtype.ischecked
                                  )
                                    ? _vm._i(
                                        _vm.chkObject.kcdivtype.ischecked,
                                        null
                                      ) > -1
                                    : _vm._q(
                                        _vm.chkObject.kcdivtype.ischecked,
                                        "T"
                                      ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.checkKcChecked(
                                      $event.target.checked
                                    )
                                  },
                                  change: function ($event) {
                                    var $$a = _vm.chkObject.kcdivtype.ischecked,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? "T" : "F"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.chkObject.kcdivtype,
                                            "ischecked",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.chkObject.kcdivtype,
                                            "ischecked",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.chkObject.kcdivtype,
                                        "ischecked",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: "ischkkcdivtype" } },
                                [_vm._v("대상여부")]
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "radio_wrap" },
                                _vm._l(
                                  _vm.commonCode.kcdivtype,
                                  function (item) {
                                    return _c("div", { key: item.cmcode }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.info.kcdivtype,
                                            expression: "info.kcdivtype",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "kcdivtypeD",
                                          id: "kcdivtypeD_" + item.cmcode,
                                        },
                                        domProps: {
                                          value: item.cmcode,
                                          checked: _vm._q(
                                            _vm.info.kcdivtype,
                                            item.cmcode
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.info,
                                              "kcdivtype",
                                              item.cmcode
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "kcdivtypeD_" + item.cmcode,
                                          },
                                        },
                                        [_vm._v(_vm._s(item.codename))]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                          _vm.info.kcdivtype ==
                          _vm.$store.getters["ADMIN"].KC_DIV_TYPE_KDT002
                            ? _c("tr", [
                                _c("th", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.chkObject.kccert.ischecked,
                                        expression:
                                          "chkObject.kccert.ischecked",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "ischkkccert",
                                      "true-value": "T",
                                      "false-value": "F",
                                      disabled: "",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.chkObject.kccert.ischecked
                                      )
                                        ? _vm._i(
                                            _vm.chkObject.kccert.ischecked,
                                            null
                                          ) > -1
                                        : _vm._q(
                                            _vm.chkObject.kccert.ischecked,
                                            "T"
                                          ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.chkObject.kccert.ischecked,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? "T" : "F"
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.chkObject.kccert,
                                                "ischecked",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.chkObject.kccert,
                                                "ischecked",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.chkObject.kccert,
                                            "ischecked",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "ischkkccert" } },
                                    [_vm._v("인증선택")]
                                  ),
                                ]),
                                _c(
                                  "td",
                                  _vm._l(
                                    _vm.info.kccertlist,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        { key: item.idx, staticClass: "dpb" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.kccertno,
                                                expression: "item.kccertno",
                                              },
                                            ],
                                            staticClass: "ml3",
                                            attrs: {
                                              type: "text",
                                              placeholder:
                                                "인증번호입력(-포함)",
                                              maxlength: "30",
                                            },
                                            domProps: { value: item.kccertno },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    item,
                                                    "kccertno",
                                                    $event.target.value
                                                  )
                                                },
                                                function ($event) {
                                                  item.iscert = ""
                                                },
                                              ],
                                            },
                                          }),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn blue-line ml3",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.kcCertification(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 인증확인 ")]
                                          ),
                                          _c("button", {
                                            staticClass: "add",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addKccertItem(index)
                                              },
                                            },
                                          }),
                                          _vm.info.kccertlist.length > 1
                                            ? _c("button", {
                                                staticClass: "del",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeKccertItem(
                                                      index
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: item.iscert == "T",
                                                  expression:
                                                    "item.iscert == 'T'",
                                                },
                                              ],
                                              staticClass: "left-bar txt-blue",
                                            },
                                            [_vm._v("적합")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: item.iscert == "F",
                                                  expression:
                                                    "item.iscert == 'F'",
                                                },
                                              ],
                                              staticClass: "left-bar txt-red",
                                            },
                                            [_vm._v("부 적합")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.$util.isNull(
                                                    item.iscert
                                                  ),
                                                  expression:
                                                    "$util.isNull(item.iscert)",
                                                },
                                              ],
                                              staticClass:
                                                "left-bar txt-orange",
                                            },
                                            [_vm._v("미 인증")]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                          _vm.info.kcdivtype ==
                          _vm.$store.getters["ADMIN"].KC_DIV_TYPE_KDT002
                            ? _c("tr", [
                                _c("th", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.chkObject.kcfile.ischecked,
                                        expression:
                                          "chkObject.kcfile.ischecked",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "ischkkcfile",
                                      "true-value": "T",
                                      "false-value": "F",
                                      disabled: "",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.chkObject.kcfile.ischecked
                                      )
                                        ? _vm._i(
                                            _vm.chkObject.kcfile.ischecked,
                                            null
                                          ) > -1
                                        : _vm._q(
                                            _vm.chkObject.kcfile.ischecked,
                                            "T"
                                          ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.chkObject.kcfile.ischecked,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? "T" : "F"
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.chkObject.kcfile,
                                                "ischecked",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.chkObject.kcfile,
                                                "ischecked",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.chkObject.kcfile,
                                            "ischecked",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "ischkkcfile" } },
                                    [_vm._v("파일첨부")]
                                  ),
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn blue-line",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fileAttach("kcFile")
                                          },
                                        },
                                      },
                                      [_vm._v(" 파일첨부 ")]
                                    ),
                                    _vm._l(
                                      _vm.files.kcfilelist,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: "kcFile" + index,
                                            staticClass: "dpib",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "file-link",
                                                style: item.isimage
                                                  ? ""
                                                  : "color: #666 !important; text-decoration: none !important",
                                                attrs: {
                                                  href: "javascript:void(0);",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.viewKcFile(
                                                      item,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.file.name) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("button", {
                                              staticClass: "file-del",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeFile(
                                                    "kcFile",
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      }
                                    ),
                                    _c("input", {
                                      ref: "kcFile",
                                      attrs: {
                                        type: "file",
                                        id: "input-file",
                                        accept: "image/jpeg, image/png, .pdf",
                                        hidden: "",
                                        multiple: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleFileUpload(
                                            "kcFile",
                                            $event.target
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.openConfirmPopup },
                },
                [_vm._v("일괄변경")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closePopup")
                    },
                  },
                },
                [_vm._v("닫기")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix mt10" }, [
      _c("div", { staticClass: "bar-title fl" }, [
        _vm._v(" 상품정보고시 "),
        _c("span", { staticClass: "txt-orange ml10" }, [
          _c("i", { staticClass: "icon-alert" }),
          _vm._v("수정을 원하는 항목을 체크하신 후 일괄변경 하시기 바랍니다."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "70px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "bar-title fl" }, [_vm._v("KC인증")]),
      _c("div", { staticClass: "fr" }, [
        _c("p", { staticClass: "txt-orange" }, [
          _c("i", { staticClass: "icon-alert" }),
          _vm._v(
            "사실과 다른 인증정보를 입력하실 경우, 관련법에 의하여 처벌받으실 수 있으니, 정확한 정보만 입력해주시기 바랍니다. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }