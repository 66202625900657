<template>
  <div>
    <div class="visual">
      <h1>입 / 점 / 신 / 청</h1>
      <p>성공적인 비즈니스 파트너! D.PLOT과 함께하세요!</p>
    </div>
    <!-- 컨텐츠 영역 -->
    <div class="join-content">
      <div class="flow clearfix">
        <ul>
          <li>
            <span>Step 1.</span>
            <p>입점절차 및 사업자 인증</p>
          </li>
          <li class="on">
            <span>Step 2.</span>
            <p>정보입력</p>
          </li>
          <li>
            <span>Step 3.</span>
            <p>신청완료</p>
          </li>
        </ul>
      </div>
      <h2 class="title">기본정보</h2>
      <div class="boxing">
        <div class="form-area">
          <dl>
            <dt>
              <i class="essential" />
              아이디
            </dt>
            <dd>
              <input
                ref="userId"
                v-model.trim="info.userId"
                placeholder="5~12자의 영문 소문자, 숫자"
                type="text"
                :class="{ error: info.userId && !isValidId }"
              />
              <span class="txt-orange">
                <i class="icon-alert" />
                파트너오피스 계정 담당자(대표자 또는 운영담당자)을 입력해주세요.
              </span>
              <p
                v-show="info.userId"
                class="alert-text"
                :class="isValidId ? 'txt-blue' : 'txt-red'"
              >
                <i :class="isValidId ? 'icon-alert-check' : 'icon-alert2'" />
                {{ isValidId ? '사용가능' : idCheckErrorMessage }}
              </p>
            </dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              비밀번호
            </dt>
            <dd>
              <!-- 비밀번호 셀프 입력에서 시스템에서 제공하는 패스워드로 셋팅되도록 변경 (2023-05-26, James) -->
              <span class="txt-red">
                입점승인 시 대표자 휴대폰 번호로 초기 비밀번호가 발송됩니다. (최초 로그인 시
                비밀번호 변경 필수)
              </span>
            </dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              담당자명
            </dt>
            <dd><input ref="managerName" v-model.trim="info.managerName" type="text" /></dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              담당자 휴대폰 번호
            </dt>
            <dd>
              <input
                ref="managerMobile"
                placeholder="핸드폰 번호를 입력해주세요. (임시비밀번호 전송)"
                v-model.trim="info.managerMobile"
                maxlength="13"
                type="text"
                :class="{ error: !getIsValidMobile(info.managerMobile) }"
              />
              <span class="txt-orange">
                <i class="icon-alert" />
                입점승인 시 해당 번호로 문자가 발송됩니다.
              </span>
            </dd>
          </dl>
        </div>
      </div>
      <h2 class="title">사업자정보</h2>
      <div class="boxing">
        <div class="form-area">
          <dl>
            <dt>
              <i class="essential" />
              사업자등록번호
            </dt>
            <dd>
              <input v-model="bizNo1" disabled style="width: 80px" type="text" />
              <span>-</span>
              <input v-model="bizNo2" disabled style="width: 72px" type="text" />
              <span>-</span>
              <input v-model="bizNo3" disabled style="width: 125px" type="text" />
            </dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              상호
            </dt>
            <dd><input v-model.trim="info.bizName" disabled type="text" /></dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              업체명
            </dt>
            <dd><input ref="companyName" v-model.trim="info.companyName" type="text" /></dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              대표자명
            </dt>
            <dd><input v-model.trim="info.ownerName" disabled type="text" /></dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              업태
            </dt>
            <dd><input ref="bizCategory" v-model.trim="info.bizCategory" type="text" /></dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              종목
            </dt>
            <dd><input ref="bizItem" v-model.trim="info.bizItem" type="text" /></dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              주소
            </dt>
            <dd>
              <search-address
                :on-search-complete-address="handleCompleteSearchAddress"
                :on-change-address-detail="handleChangeAddressDetail"
              />
            </dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              판매상품유형
            </dt>
            <dd>
              <select ref="mainCategory" v-model.trim="info.mainCategory" style="width: 300px">
                <option value="" disabled selected>대표카테고리 선택</option>
                <option
                  v-for="category in categories"
                  v-bind:key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              통신판매신고번호
            </dt>
            <dd>
              <input
                ref="mailOrderSalesNumber"
                v-model.trim="info.mailOrderSalesNumber"
                placeholder="제2021-서울용산-0161호"
                type="text"
              />
              <span class="txt-orange">
                <i class="icon-alert" />
                통신판매업 신고 후 가입을 진행해주시기 바랍니다.
              </span>
            </dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              고객문의 연락처
            </dt>
            <dd>
              <input
                ref="csPhoneNumber"
                placeholder="핸드폰번호 또는 전화번호를 입력해주세요."
                v-model.trim="info.csPhoneNumber"
                type="text"
                :class="{ error: !getIsValidPhone(info.csPhoneNumber) }"
              />
              <span class="txt-orange">
                <i class="icon-alert" />
                고객문의 연락처 및 이메일은 주문정보에 노출되니 정확한 정보를 입력해주시기 바랍니다.
              </span>
            </dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              대표 이메일
            </dt>
            <dd>
              <input
                ref="companyEmail"
                v-model.trim="info.companyEmail"
                placeholder="예) master@gmail.com"
                type="email"
                :class="{ error: !isValidEmail }"
              />
              <p v-show="!isValidEmail" class="alert-text txt-red">
                <i class="icon-alert2" />
                이메일 형식에 맞게 작성해주세요.
              </p>
              <p class="txt-orange">
                <i class="icon-alert" />
                세금계산서 발행 시 위 메일주소로 발행됩니다. 담당자 변경 필요 시
                고객센터(1666-3642)로 문의해 주세요.
              </p>
            </dd>
          </dl>
        </div>
      </div>
      <h2 class="title">정산정보</h2>
      <div class="boxing">
        <div class="form-area">
          <dl>
            <dt>
              <i class="essential" />
              예금주
            </dt>
            <dd>
              <input v-model="info.bankDepositor" type="text" />
            </dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              계좌구분
            </dt>
            <dd>
              <div class="radio_wrap wide">
                <input id="rd01" v-model="info.remitType" name="remitType" type="radio" value="1" />
                <label for="rd01">개인계좌</label>
                <input id="rd02" v-model="info.remitType" name="remitType" type="radio" value="2" />
                <label for="rd02">법인계좌</label>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              은행/계좌번호
            </dt>
            <dd>
              <select ref="bank" v-model="info.bankCode" style="width: 120px">
                <option value="">은행 선택</option>
                <option v-for="bank in banks" :key="bank.note" :value="bank.note">
                  {{ bank.codeName }}
                </option>
              </select>
              <input
                ref="bankAccount"
                v-model.trim="info.bankAccount"
                style="width: 174px"
                type="text"
              />
              <button class="btn blue-line" type="button" @click="handleClickValidBankAccount">
                계좌실명인증
              </button>
              <span :class="isValidBankAccount ? 'txt-blue' : 'txt-red'">
                <i
                  v-show="validateBankAccountText !== ''"
                  :class="isValidBankAccount ? 'icon-alert-check' : 'icon-alert2'"
                />
                {{ validateBankAccountText }}
              </span>
              <p class="txt-orange">
                <i class="icon-alert" />
                파트너사 정산은 전월 1일부터 말일까지의 구매확정건을 기준으로 영업일 기준 익월
                말일에 정산됩니다.
              </p>
            </dd>
          </dl>
        </div>
      </div>
      <h2 class="title">첨부서류</h2>
      <div class="boxing">
        <div class="form-area">
          <dl>
            <dt>
              <i class="essential" />
              사업자등록증사본
            </dt>
            <dd class="pt10">
              <button class="btn blue-line" type="button" @click="handleClickBizFile(true)">
                파일첨부
              </button>
              <input
                v-show="false"
                ref="businessFile"
                accept="image/jpeg, image/png, .pdf"
                type="file"
                @change="handleFileUpload(true)"
              />
              <div v-show="info.businessFile" class="file-link">
                {{ info.businessFile?.name ?? '' }}
                <button class="file-del" type="button" @click="handleClickFileInit(true)" />
              </div>
              <p class="txt-orange multi-line">
                <i class="icon-alert" />
                1년 이내에 발급 받은 사업자등록증을 첨부해 주시기 바랍니다.
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;jpg, jpeg, png파일 또는 PDF 파일만
                첨부 가능하며, 파일 최대 크기는 10MB를 초과 할 수 없습니다.
              </p>
            </dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              통장사본
            </dt>
            <dd>
              <button class="btn blue-line" type="button" @click="handleClickBizFile(false)">
                파일첨부
              </button>
              <input
                v-show="false"
                ref="copyPassbookFile"
                accept="image/jpeg, image/png, .pdf"
                type="file"
                v-on:change="handleFileUpload(false)"
              />
              <div v-show="info.copyPassbookFile" class="file-link">
                {{ info.copyPassbookFile?.name ?? '' }}
                <button class="file-del" type="button" @click="handleClickFileInit(false)" />
              </div>
              <p class="txt-orange">
                <i class="icon-alert" />
                jpg, jpeg, png파일 또는 PDF 파일만 첨부 가능하며, 파일 최대 크기는 10MB를 초과 할 수
                없습니다.
              </p>
            </dd>
          </dl>
          <dl>
            <dt>
              <i class="essential" />
              통신판매업신고증
            </dt>
            <dd>
              <button class="btn blue-line" type="button" @click="handleClickMailOrderSalesFile">
                파일첨부
              </button>
              <input
                v-show="false"
                ref="mailOrderSalesFile"
                accept="image/jpeg, image/png, .pdf"
                type="file"
                v-on:change="handleUploadMailOrderSalesFile"
              />
              <div v-show="info.mailOrderSalesFile" class="file-link">
                {{ info.mailOrderSalesFile?.name ?? '' }}
                <button class="file-del" type="button" @click="handleClickFileInitMailOrderSales" />
              </div>
              <p class="txt-orange">
                <i class="icon-alert" />
                jpg, jpeg, png파일 또는 PDF 파일만 첨부 가능하며, 파일 최대 크기는 10MB를 초과 할 수
                없습니다.
              </p>
            </dd>
          </dl>
        </div>
      </div>
      <h2 class="title">약관 동의</h2>
      <div class="boxing">
        <h4>[필수] 개인정보 수집 동의</h4>
        <ul>
          <li>
            - 수집 항목 : 아이디, 비밀번호, 대표자 명, 담당자 휴대폰, 정산정보(예금주, 은행명,
            계좌번호), 통장사본
          </li>
          <li>- 이용 목적 : 판매자 입점 및 관리, 사용자 식별 및 로그인 시 2차 인증 목적</li>
          <li>
            <strong>- 보유 및 이용 기간 : 계약 종료 시 파기</strong>
          </li>
        </ul>
      </div>
      <div class="flex-center">
        <input type="checkbox" id="consent" v-model="isCheckConsent" />
        <label for="consent">개인정보 수집 및 이용에 동의합니다.</label>
      </div>
      <div class="btn-group">
        <button class="btn big blue" type="button" @click="signupPartner">신청</button>
      </div>
    </div>
    <!-- TODO 아래 form은 사용하는지 여부 파악 필요 -->
    <!-- <form
      ref="nice_form"
      action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
      method="post"
      name="form_chk"
    >
      <input name="m" type="hidden" value="checkplusService" />
      <input ref="encData" name="EncodeData" type="hidden" />
    </form> -->
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import storage from '@js/storage';
import store from '@js/store';
import constants from '@js/constants';
import util from '@js/util';
import SearchAddress from '@/views/common/components/SearchAddress.vue';

let tempPartnerData = null; // 뒤로가기시 변경을 감지할 temp 데이터
const { TEN_MEGA_BYTE } = constants.COMMON_VALUE;
const ENABLED_FILE_TYPE = ['image/png', 'image/jpeg', 'application/pdf'];
const { VUE_APP_API_V2_PREFIX } = process.env;

export default {
  // 신규 페이지 전환. 추후에 new 제거 필요.
  name: 'PartnershipJoinNew',
  components: {
    'search-address': SearchAddress,
  },
  data() {
    return {
      info: {
        // 참고 https://oyitsm.cj.net/confluence/pages/viewpage.action?pageId=332707552
        // http://dev-api-partner.dplot.co.kr/swagger-ui/index.html#/Partner%20%EC%9E%85%EC%A0%90%20%EC%8B%A0%EC%B2%AD/checkBusinessCertification
        userId: '', //사용자 아이디 string
        bizNo: '', //사업자 등록 번호 string
        bizName: '', //상호명 string
        companyName: '', //업체명 string
        ownerName: '', //대표 이름 string
        managerName: '', // 담당자 이름 string
        managerMobile: '', // 담당자 핸드폰 번호 string
        bizCategory: '', //업태 string
        bizItem: '', //종목 string
        post: '', //우편번호 string
        address: '', //주소 string
        addressDetail: '', //상세 주소 string
        sigunguCode: '', //시군구 코드 string
        buildingCode: '', //빌딩 코드 string
        roadNameCode: '', //도로명 코드 string
        mainCategory: '', //판매 상품 유형 (대표 카테고리 id) long
        mailOrderSalesNumber: '', //통신 판매 신고 번호 string  '-' dash 제거해서 전달해야함. string
        csPhoneNumber: '', //고객문의 연락처 '-' dash 제거해서 전달해야함. string
        companyEmail: '', //대표 이메일 string
        bankDepositor: '', //예금주 이름 string
        bankCode: '', //cmCode (은행 정보 API 결과값)
        bankAccount: '', //계좌번호 '-' dash 제거해서 전달해야함.
        remitType: 1, // 계좌구분(개인/법인) default: 개인
        businessFile: null, // 사업자등록 파일
        mailOrderSalesFile: null, // 통신판매업신고증 파일
        copyPassbookFile: null, //통장사본 실제 파일객체 File
      },
      isCheckConsent: false, // 수집 동의
      isValidId: false, // 파트너 id validation
      isValidBankAccount: false, // 계좌번호 validation
      isValidEmail: true, // 이메일 validation, 기본은 valid
      validateBankAccountText: '', //
      bizNo1: '', // 사업자 번호 처음 3자리
      bizNo2: '', // 사업자 번호 중간 2자리
      bizNo3: '', // 사업자 번호 마지막 3자리
      categories: [], // 판매상품유형 목록
      banks: [], //은행 목록
      idCheckErrorMessage: '', // id 인증 에러 메시지
      confirmLeave: false, // 저장 안하고 뒤로 가기 여부
    };
  },
  watch: {
    'info.userId': {
      handler(val, oldVal) {
        this.debounceCheckId(val);
      },
      deep: true,
    },
    'info.managerMobile': {
      handler(val, oldVal) {
        this.handleChangeMobileNumber(val);
      },
      deep: true,
    },
    'info.csPhoneNumber': {
      handler(val, oldVal) {
        this.handleChangePhoneNumber(val);
      },
      deep: true,
    },
    'info.companyEmail': {
      handler(val, oldVal) {
        this.handleChangeEmail(val);
      },
      deep: true,
    },
    'info.bankAccount': {
      handler(val, oldVal) {
        this.handleChangeBankAccount(val);
      },
      deep: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    if (util.isAuthorized(store.getters.CONSTANTS.MANAGER_SESSION)) {
      next('partners.login');
      return;
    }
    const { BIZ_AUTH_INFO } = constants.STORAGE_KEYS;
    const hasSessionBizData =
      typeof storage.getSessionStorage(BIZ_AUTH_INFO) !== 'undefined' &&
      storage.getSessionStorage(BIZ_AUTH_INFO) != null;

    // 사업자 정보 인증 데이터와 약관 수락 정보가 세션에 있어야만 접근 가능.
    if (!hasSessionBizData) {
      next('partners.login');
      return;
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    // 정보 변경하고 나갈 경우 컨펌창으로 확인한다.
    if (isEqual(tempPartnerData, this.info)) {
      next();
      return;
    }
    if (!this.confirmLeave) {
      this.confirmLeave = confirm(constants.COMMON_MESSAGE.LEAVE_PAGE_MESSAGE);
      if (this.confirmLeave) {
        next();
      } else {
        next(false);
      }
    }
  },
  beforeCreate() {
    // temp 값을 초기화한다.
    tempPartnerData = null;
  },
  beforeMount() {
    this.initBizInfo();
    tempPartnerData = cloneDeep(this.info);
    this.initFinishedJoinInStorage();
    this.getBanks();
    this.getCategories();
  },
  methods: {
    getIsValidMobile(val) {
      if (!val) {
        return true;
      }
      return util.isValidMobileNumber(val);
    },
    getIsValidPhone(val) {
      if (!val) {
        return true;
      }
      return util.isValidPhoneNumber(val);
    },
    initBizInfo() {
      const { BIZ_AUTH_INFO } = this.$store.getters.CONSTANTS.STORAGE_KEYS;
      const sessionBizAuthInfo = storage.getSessionStorage(BIZ_AUTH_INFO);
      if (sessionBizAuthInfo) {
        this.bizNo1 = sessionBizAuthInfo.bizNum;
        this.bizNo2 = sessionBizAuthInfo.bizNum2;
        this.bizNo3 = sessionBizAuthInfo.bizNum3;
        this.info.bizName = sessionBizAuthInfo.bizName;
        this.info.ownerName = sessionBizAuthInfo.ownerName;
        this.info.bizNo = `${this.bizNo1}-${this.bizNo2}-${this.bizNo3}`;
      }
    },
    handleChangeBankAccount(value) {
      const replaced = util.replaceOtherNumber(value);
      this.info.bankAccount = replaced;
    },
    handleChangeEmail(value) {
      const replaced = value.replaceAll(' ', '');
      this.info.companyEmail = replaced;
      if (!replaced) {
        this.isValidEmail = true;
        return;
      }

      this.isValidEmail = util.isValidEmail(replaced);
    },
    // 모바일 및 전화번호 정규식으로 자동 하이픈 추가 및 숫자만 허용
    handleChangePhoneNumber(value) {
      const result = util.replacePhoneNumber(value);

      this.info.csPhoneNumber = result;
    },
    // 모바일 정규식으로 자동 하이픈 추가 및 숫자만 허용
    handleChangeMobileNumber(value) {
      const result = util.replaceMobileNumber(value);

      this.info.managerMobile = result;
    },
    async getCategories() {
      try {
        const apiUrl = `${VUE_APP_API_V2_PREFIX}/category?depth=1&isHidden=false`;
        const { data } = await this.$http.get(apiUrl);
        if (!data.length) {
          throw new Error('카테고리 정보를 가져오는데 오류가 발생했습니다.');
        }
        this.categories = data;
      } catch (e) {
        this.categories = [];
        util.debug(err.message);
      }
    },
    async getBanks() {
      try {
        const apiUrl = `${VUE_APP_API_V2_PREFIX}/common-code?cmClass=TBANKTYPE`;
        const { data = [] } = await this.$http.get(apiUrl);
        if (!data.length) {
          throw new Error('은행 정보를 가져오는데 오류가 발생했습니다.');
        }
        this.banks = data;
      } catch (e) {
        this.banks = [];
        util.debug(err.message);
      }
    },
    debounceCheckId: debounce(
      async function (userId) {
        try {
          const isValidId = util.isValidId(userId);
          if (userId.length >= 5 && userId.length <= 12 && isValidId) {
            await this.$http.get(
              `${VUE_APP_API_V2_PREFIX}/partner/entry/sign-up/check-duplicate-userid/${userId}`,
            );
            this.isValidId = true;
          } else {
            this.isValidId = false;
            this.idCheckErrorMessage = '오류! 5~12자의 영문 소문자와 숫자 조합만 사용가능';
          }
        } catch (err) {
          const msg = err?.message ?? err;
          this.isValidId = false;
          this.idCheckErrorMessage = msg;
        }
      },
      500,
      { leading: true },
    ),
    handleClickValidBankAccount() {
      this.debounceRequestValidBankAccount();
    },
    debounceRequestValidBankAccount: debounce(
      async function () {
        const emptyMessage = '계좌실명인증에 필요한 정보를 입력해 주세요.';
        try {
          const isEmptyBankInfo =
            !this.info.bankAccount || !this.info.bankCode || !this.info.bankDepositor;
          if (isEmptyBankInfo) {
            throw new Error(emptyMessage);
          }

          const param = {
            service: 2,
            svcGbn: 2,
            bankCode: this.info.bankCode,
            strResId: this.info.bizNo,
            bankAccount: this.info.bankAccount,
            name: this.info.bankDepositor,
            accountType: this.info.remitType,
            inquireReason: 10,
          };
          const queryString = new URLSearchParams(param).toString();

          const params = {
            isloading: true,
          };
          await this.$http.get(
            `${VUE_APP_API_V2_PREFIX}/partner/entry/sign-up/check-account?${queryString}`,
            { params },
          );
          this.isValidBankAccount = true;
          this.validateBankAccountText = '인증 성공';
        } catch (err) {
          this.isValidBankAccount = false;

          if (err.message === emptyMessage) {
            this.validateBankAccountText = emptyMessage;
          } else {
            this.validateBankAccountText = '인증에 실패 하였습니다.';
          }
        }
      },
      500,
      { leading: true },
    ),
    handleClickBizFile: function (isBusiness) {
      isBusiness ? this.$refs.businessFile.click() : this.$refs.copyPassbookFile.click();
    },
    handleClickMailOrderSalesFile() {
      this.$refs.mailOrderSalesFile.click();
    },
    setCopyPassbookFile(file) {
      this.info.copyPassbookFile = file ?? null;
    },
    setBusinessFile(file) {
      this.info.businessFile = file ?? null;
    },
    initBusinessFile() {
      this.info.businessFile = null;
      this.$refs.businessFile.value = '';
    },
    initCopyPassbookFile() {
      this.info.copyPassbookFile = null;
      this.$refs.copyPassbookFile.value = '';
    },
    initFile(isBusiness) {
      isBusiness ? this.initBusinessFile() : this.initCopyPassbookFile();
    },
    handleFileUpload: function (isBusiness) {
      const fileElement = isBusiness ? this.$refs.businessFile : this.$refs.copyPassbookFile;
      const file = fileElement?.files[0];
      if (!file) {
        alert('이미지를 선택해주세요.');
        return;
      }

      if (!ENABLED_FILE_TYPE.includes(file.type)) {
        alert('jpg, jpeg, png파일 또는 PDF 파일만 첨부 가능 합니다.');
        fileElement.value = null;
        this.initFile(isBusiness);
        return;
      }

      if (file.size > TEN_MEGA_BYTE) {
        alert('파일 최대 크기는 10MB를 초과 할 수 없습니다.');
        fileElement.value = null;
        this.initFile(isBusiness);
        return;
      }

      if (isBusiness) {
        this.setBusinessFile(file);
      } else {
        this.setCopyPassbookFile(file);
      }
    },
    handleClickFileInit: function (isBusiness) {
      isBusiness ? this.initBusinessFile() : this.initCopyPassbookFile();
    },
    handleUploadMailOrderSalesFile() {
      const fileElement = this.$refs.mailOrderSalesFile;
      const file = fileElement?.files[0];
      if (!ENABLED_FILE_TYPE.includes(file.type)) {
        alert('jpg, jpeg, png파일 또는 PDF 파일만 첨부 가능 합니다.');
        fileElement.value = null;
        return;
      }

      if (file.size > TEN_MEGA_BYTE) {
        alert('파일 최대 크기는 10MB를 초과 할 수 없습니다.');
        fileElement.value = null;
        return false;
      }

      this.info.mailOrderSalesFile = file;
    },
    initMailOrderSalesFile() {
      this.info.mailOrderSalesFile = null;
      this.$refs.mailOrderSalesFile.value = '';
    },
    handleClickFileInitMailOrderSales() {
      this.initMailOrderSalesFile();
    },
    // 주소 검색 완료시 데이터 설정을 한다.
    handleCompleteSearchAddress(data) {
      this.info.post = data.zonecode;
      this.info.address = data.address;
      this.info.sigunguCode = data.sigunguCode;
      this.info.buildingCode = data.buildingCode;
      this.info.roadNameCode = data.roadnameCode;
    },
    handleChangeAddressDetail(addressDetail) {
      this.info.addressDetail = addressDetail;
    },
    // 사용자 아이디 validation 실패시 얼럿을 띄운다.
    showAlertMessageByUserId() {
      if (!this.isValidId && !!this.info.userId) {
        alert('사용할 수 없는 아이디 입니다.');
      } else if (!this.isValidId && !this.info.userId) {
        alert('아이디를 입력해주세요.');
      }
    },
    // 대표자 휴대폰/고객문의 전화 validation 실패시 얼럿을 띄운다.
    showAlertMessageByPhoneNumber(inputKey = 'csPhoneNumber') {
      const value = this.info[inputKey] ?? '';

      if (!value) {
        const message =
          inputKey === 'managerMobile'
            ? '담당자 휴대폰 번호를 입력해주세요.'
            : '고객문의 연락처를 입력해주세요.';
        this.showAlertMessage(message);
        return;
      }

      const isValid =
        inputKey === 'managerMobile'
          ? util.isValidMobileNumber(value)
          : util.isValidPhoneNumber(value);

      if (!isValid) {
        const message =
          inputKey === 'managerMobile'
            ? '담당자 휴대폰 번호를 정확히 입력해주세요.'
            : '고객문의 연락처를 정확히 입력해주세요.';

        this.showAlertMessage(message);
        return;
      }
    },
    // 대표이메일 validation 실패시 얼럿을 띄운다.
    showAlertMessageByCompanyEmail() {
      const { companyEmail } = this.info;
      if (!companyEmail) {
        alert('대표 이메일을 입력해주세요.');
      } else if (!!companyEmail && !this.isValidEmail) {
        alert('대표 이메일을 정확히 입력해주세요.');
      }
    },
    showAlertMessage(message) {
      alert(message);
    },
    // 아이디 validation
    getValidId() {
      return this.isValidId;
    },
    getValidNormalInput(key) {
      return !!this.info?.[key];
    },
    // 고객문의 전화 validation
    getValidCsPhoneNumber() {
      return util.isValidPhoneNumber(this.info.csPhoneNumber);
    },
    // 담당자 휴대폰 validation
    getValidManagerMobile() {
      return util.isValidMobileNumber(this.info.managerMobile);
    },
    // 대표 이메일 validation
    getValidCompanyEmail() {
      return !!this.info.companyEmail && this.isValidEmail;
    },
    getValidBankAccount() {
      return this.isValidBankAccount;
    },
    showInvalidMessage(refKey = '') {
      switch (refKey) {
        case 'userId':
          this.showAlertMessageByUserId();
          break;
        case 'managerName':
          this.showAlertMessage('담당자명을 입력해주세요.');
          break;
        case 'companyName':
          this.showAlertMessage('업체명을 입력해주세요.');
          break;
        case 'bizCategory':
          this.showAlertMessage('업태를 입력해주세요.');
          break;
        case 'bizItem':
          this.showAlertMessage('종목을 입력해주세요.');
          break;
        case 'address':
          this.showAlertMessage('주소를 입력해주세요.');
          break;
        case 'addressDetail':
          this.showAlertMessage(
            '상세주소를 입력해주세요.\n상세주소가 없을 경우 - (대시) 를 입력해주세요.',
          );
          break;
        case 'mainCategory':
          this.showAlertMessage('판매상품유형의 대표카테고리를 선택해주세요.');
          break;
        case 'mailOrderSalesNumber':
          this.showAlertMessage('통신판매신고번호를 입력해주세요.');
          break;
        case 'csPhoneNumber':
        case 'managerMobile':
          this.showAlertMessageByPhoneNumber(refKey);
          break;
        case 'companyEmail':
          this.showAlertMessageByCompanyEmail();
          break;
        case 'bankAccount':
          this.showAlertMessage('계좌실명인증을 진행해주세요.');
          break;
        case 'businessFile':
        case 'copyPassbookFile':
        case 'mailOrderSalesFile': {
          const prefixMap = {
            businessFile: '사업자등록증사본',
            copyPassbookFile: '통장사본',
            mailOrderSalesFile: '통신판매업신고증',
          };
          this.showAlertMessage(`${prefixMap[refKey]}을 첨부해주세요.`);
          break;
        }
        default:
          break;
      }
    },
    scrollIntoViewTarget(target) {
      if (!target) {
        return;
      }
      target.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        target?.focus();
      }, 50);
    },
    showErrorToMove(refKey = '') {
      if (!refKey) {
        return;
      }
      this.showInvalidMessage(refKey);
      this.moveScrollTargetEl(refKey);
    },
    moveScrollTargetEl(refKey = '') {
      if (!refKey) {
        return;
      }
      let targetElementRef = this.$refs?.[refKey];
      if (!targetElementRef) {
        const findChild = this.$children?.find(
          (child) => child?.$vnode.tag.includes('SearchAddress'), // 컴포넌트 이름으로 vnode 찾음
        );
        targetElementRef = findChild?.$refs?.[refKey];
      }
      this.scrollIntoViewTarget(targetElementRef);
    },
    getValidations() {
      return [
        { key: 'userId', result: this.getValidId() },
        { key: 'managerName', result: this.getValidNormalInput('managerName') },
        { key: 'managerMobile', result: this.getValidManagerMobile() },
        { key: 'companyName', result: this.getValidNormalInput('companyName') },
        { key: 'bizCategory', result: this.getValidNormalInput('bizCategory') },
        { key: 'bizItem', result: this.getValidNormalInput('bizItem') },
        { key: 'address', result: this.getValidNormalInput('address') },
        { key: 'addressDetail', result: this.getValidNormalInput('addressDetail') },
        { key: 'mainCategory', result: this.getValidNormalInput('mainCategory') },
        { key: 'mailOrderSalesNumber', result: this.getValidNormalInput('mailOrderSalesNumber') },
        { key: 'csPhoneNumber', result: this.getValidCsPhoneNumber() },
        { key: 'companyEmail', result: this.getValidCompanyEmail() },
        { key: 'bankAccount', result: this.getValidBankAccount() },
        { key: 'businessFile', result: this.getValidNormalInput('businessFile') },
        { key: 'copyPassbookFile', result: this.getValidNormalInput('copyPassbookFile') },
        { key: 'mailOrderSalesFile', result: this.getValidNormalInput('mailOrderSalesFile') },
      ];
    },
    transformPartnerInfoForRequest() {
      const {
        userId: userLoginId, // 아이디
        bizNo, // 사업자 등록 번호
        bizName, // 상호
        companyName, // 업체명
        companyEmail: email, // 대표 이메일
        ownerName: representativeName, // 대표자 이름
        managerName, // 담당자 이름
        managerMobile, // 담당자 휴대폰번호
        bizCategory: bizCondition, // 업태
        bizItem, // 종목
        post: postNo, // 우편번호
        address, // 주소
        addressDetail, // 상세 주소
        roadNameCode, // 길 코드
        sigunguCode, // 시군구 코드
        buildingCode, // 빌딩 코드
        csPhoneNumber: helpPhoneNo, // 고객 문의 전화번호
        mainCategory: categoryId, // 판매상품 유형
        mailOrderSalesNumber: tellMarketNo, // 통신판매신고번호
        bankDepositor: settlementDepositor, // 예금주
        bankCode: settlementBankCode, // 은행코드
        bankAccount: settlementAccount, // 계좌
      } = this.info;

      return {
        userLoginId,
        bizNo,
        bizName,
        companyName,
        email,
        representativeName,
        representativeMobile: null, // 하위호환을 위해 대표자 휴대폰 번호는 null 처리
        managerName,
        managerMobile: managerMobile.replaceAll('-', ''),
        bizCondition,
        bizItem,
        postNo,
        address,
        addressDetail,
        roadNameCode,
        sigunguCode,
        buildingCode,
        helpPhoneNo: helpPhoneNo.replaceAll('-', ''),
        categoryId,
        tellMarketNo,
        settlementDepositor,
        settlementAccount,
        settlementBankCode,
      };
    },
    debounceSignupPartner: debounce(
      async function () {
        try {
          // validation 체크
          const validations = this.getValidations();
          const invalidFormData = validations.find((validation) => !validation.result);
          if (invalidFormData) {
            this.showErrorToMove(invalidFormData.key);
            return;
          }
          const { businessFile, copyPassbookFile, mailOrderSalesFile } = this.info;
          // 테스트시 해당 주석 풀어서 테스트
          // const transformedInfo = {
          //   userLoginId: 'mjjjhs2',
          //   bizNo: '111-11-11111',
          //   bizName: '문지훈테스트',
          //   companyName: '문지훈테스트',
          //   email: 'mjjjhs@nate.com',
          //   representativeName: '문지훈',
          //   representativeMobile: null,
          //   managerName: '문지훈',
          //   managerMobile: '01082942417',
          //   bizCondition: '문지훈테스트',
          //   bizItem: '문지훈테스트',
          //   postNo: '50111',
          //   address: '뚝섬로 57길 36-3',
          //   addressDetail: '3층',
          //   roadNameCode: '뚝섬로 57길 36-3',
          //   sigunguCode: '뚝섬로 57길 36-3',
          //   buildingCode: '뚝섬로 57길 36-3',
          //   helpPhoneNo: '01082942417',
          //   categoryId: 16,
          //   tellMarketNo: '문지훈테스트',
          //   settlementDepositor: '문지훈',
          //   settlementAccount: '29050100010683',
          //   settlementBankCode: '004',
          // };
          const transformedInfo = this.transformPartnerInfoForRequest();

          const params = Object.assign({}, { files: [] }, transformedInfo, { isloading: true });

          params.files.push({ key: 'copiedBizRegistration', file: businessFile });
          params.files.push({ key: 'copiedBankBook', file: copyPassbookFile });
          params.files.push({ key: 'onlineBizReport', file: mailOrderSalesFile });
          await this.$http.post(`${VUE_APP_API_V2_PREFIX}/partner/entry/sign-up`, params);
          alert(
            '입점 신청이 완료되었습니다.\n입점 심사는 1~5일 소요될 수 있으며, 입점 승인 시 파트너 오피스 로그인이 가능합니다.\n입점 심사가 장기 소요되는 경우 담당 MD에게 연락해 주세요.',
          );
          tempPartnerData = cloneDeep(this.info); // router 변경시 저장 컨펌 노출하지 않기 위해 temp에 저장한다.
          this.setFinishedJoinInStorage(); //입점 신청 여부 등록
          this.$router.replace({ name: 'common.new.partnership.complete' });
        } catch (err) {
          alert(err.message);
        }
      },
      500,
      { leading: true, trailing: false },
    ),
    signupPartner() {
      if (!this.isCheckConsent) {
        alert(constants.COMMON_MESSAGE.UNCHECKED_CONSENT);
        return;
      }
      this.debounceSignupPartner();
    },
    initFinishedJoinInStorage() {
      const { FINISHED_JOIN_PARTNER } = this.$store.getters.CONSTANTS.STORAGE_KEYS;
      storage.setSessionStorage(FINISHED_JOIN_PARTNER, false);
    },
    setFinishedJoinInStorage() {
      const { FINISHED_JOIN_PARTNER } = this.$store.getters.CONSTANTS.STORAGE_KEYS;
      storage.setSessionStorage(FINISHED_JOIN_PARTNER, true);
    },
  },
};
</script>

<style scoped src="@assets/css/partnership.css"></style>
