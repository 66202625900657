var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "1200px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("공지사항 등록")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "bar-title" }, [_vm._v("기본정보")]),
            _c("div", { staticClass: "boxing" }, [
              _c("div", { staticClass: "form-area" }, [
                _c("dl", [
                  _c("dt", [_vm._v("노출상태")]),
                  _c("dd", [
                    _c("div", { staticClass: "radio_wrap wide" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.isdisplay,
                            expression: "info.isdisplay",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "group00regist",
                          id: "group01regist",
                          value: "F",
                          checked: "",
                        },
                        domProps: { checked: _vm._q(_vm.info.isdisplay, "F") },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.info, "isdisplay", "F")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "group01regist" } }, [
                        _vm._v("노출"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.info.isdisplay,
                            expression: "info.isdisplay",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "group00regist",
                          id: "group02regist",
                          value: "T",
                        },
                        domProps: { checked: _vm._q(_vm.info.isdisplay, "T") },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.info, "isdisplay", "T")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "group02regist" } }, [
                        _vm._v("미노출"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("dl", [
                  _vm._m(0),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.subject,
                          expression: "info.subject",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", placeholder: "공지사항 제목" },
                      domProps: { value: _vm.info.subject },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.info, "subject", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("설명")]),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.notice_desc,
                          expression: "info.notice_desc",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "text", placeholder: "공지사항 설명" },
                      domProps: { value: _vm.info.notice_desc },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.info, "notice_desc", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "bar-title" }, [_vm._v("조건설정")]),
            _c("div", { staticClass: "form-area" }, [
              _c(
                "table",
                {
                  staticClass: "gray-tb",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(1),
                  _c("tbody", [
                    _c("tr", [
                      _vm._m(2),
                      _c(
                        "td",
                        [
                          _c("div", { staticClass: "radio_wrap wide dpib" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.info.isrightnow,
                                  expression: "info.isrightnow",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                name: "group01",
                                id: "group11",
                                value: "T",
                                checked: "",
                              },
                              domProps: {
                                checked: _vm._q(_vm.info.isrightnow, "T"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.info, "isrightnow", "T")
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "group11" } }, [
                              _vm._v("즉시등록"),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.info.isrightnow,
                                  expression: "info.isrightnow",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                name: "group01",
                                id: "group12",
                                value: "F",
                              },
                              domProps: {
                                checked: _vm._q(_vm.info.isrightnow, "F"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.info, "isrightnow", "F")
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "group12" } }, [
                              _vm._v("예약"),
                            ]),
                          ]),
                          _c("CommonDatePickerFromTo", {
                            attrs: {
                              fromYYYYMMDD: _vm.info.startYYYYMMDD,
                              fromHH: _vm.info.startHH,
                              fromMM: _vm.info.startMi,
                              "use-to": false,
                              "from-disable": _vm.info.isrightnow === "T",
                            },
                            on: { getDate: _vm.changeStartDate },
                          }),
                          _c("span", [_vm._v("분 부터")]),
                        ],
                        1
                      ),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("노출채널")]),
                      _c(
                        "td",
                        [
                          _c("div", { staticClass: "check-wrap" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.info.isallmuappch,
                                  expression: "info.isallmuappch",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "isallmuappch",
                                "true-value": "T",
                                "false-value": "F",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.info.isallmuappch)
                                  ? _vm._i(_vm.info.isallmuappch, null) > -1
                                  : _vm._q(_vm.info.isallmuappch, "T"),
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.info.isallmuappch,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? "T" : "F"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.info,
                                            "isallmuappch",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.info,
                                            "isallmuappch",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.info, "isallmuappch", $$c)
                                    }
                                  },
                                  _vm.checkAllAppchtype,
                                ],
                              },
                            }),
                            _c("label", { attrs: { for: "isallmuappch" } }, [
                              _vm._v("전체"),
                            ]),
                          ]),
                          _vm._l(_vm.commonCode.muappchtype, function (item) {
                            return _c(
                              "div",
                              { key: item.cmcode, staticClass: "check-wrap" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.muappchtypearr,
                                      expression: "info.muappchtypearr",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "muappchtype_" + item.cmcode,
                                    "true-value": "[]",
                                  },
                                  domProps: {
                                    value: item.cmcode,
                                    checked: Array.isArray(
                                      _vm.info.muappchtypearr
                                    )
                                      ? _vm._i(
                                          _vm.info.muappchtypearr,
                                          item.cmcode
                                        ) > -1
                                      : _vm._q(_vm.info.muappchtypearr, "[]"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.info.muappchtypearr,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? "[]" : false
                                      if (Array.isArray($$a)) {
                                        var $$v = item.cmcode,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.info,
                                              "muappchtypearr",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.info,
                                              "muappchtypearr",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.info,
                                          "muappchtypearr",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    attrs: {
                                      for: "muappchtype_" + item.cmcode,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.codename))]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("대상유형")]),
                      _c(
                        "td",
                        [
                          _c("div", { staticClass: "check-wrap" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.info.isallmumember,
                                  expression: "info.isallmumember",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "isallmumember",
                                "true-value": "T",
                                "false-value": "F",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.info.isallmumember)
                                  ? _vm._i(_vm.info.isallmumember, null) > -1
                                  : _vm._q(_vm.info.isallmumember, "T"),
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.info.isallmumember,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? "T" : "F"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.info,
                                            "isallmumember",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.info,
                                            "isallmumember",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.info, "isallmumember", $$c)
                                    }
                                  },
                                  _vm.checkAllMembertype,
                                ],
                              },
                            }),
                            _c("label", { attrs: { for: "isallmumember" } }, [
                              _vm._v("전체"),
                            ]),
                          ]),
                          _vm._l(_vm.commonCode.mumembertype, function (item) {
                            return _c(
                              "div",
                              { key: item.cmcode, staticClass: "check-wrap" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.info.mumembertypearr,
                                      expression: "info.mumembertypearr",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "mumembertype_" + item.cmcode,
                                    "true-value": "[]",
                                  },
                                  domProps: {
                                    value: item.cmcode,
                                    checked: Array.isArray(
                                      _vm.info.mumembertypearr
                                    )
                                      ? _vm._i(
                                          _vm.info.mumembertypearr,
                                          item.cmcode
                                        ) > -1
                                      : _vm._q(_vm.info.mumembertypearr, "[]"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.info.mumembertypearr,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? "[]" : false
                                      if (Array.isArray($$a)) {
                                        var $$v = item.cmcode,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.info,
                                              "mumembertypearr",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.info,
                                              "mumembertypearr",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.info,
                                          "mumembertypearr",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    attrs: {
                                      for: "mumembertype_" + item.cmcode,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.codename))]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("tr", [
                      _vm._m(3),
                      _c(
                        "td",
                        [
                          _c("div", { staticClass: "radio_wrap wide dpib" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.info.istopfix,
                                  expression: "info.istopfix",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                name: "group04",
                                id: "group41",
                                value: "F",
                                checked: "",
                              },
                              domProps: {
                                checked: _vm._q(_vm.info.istopfix, "F"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.info, "istopfix", "F")
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "group41" } }, [
                              _vm._v("고정하지 않음"),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.info.istopfix,
                                  expression: "info.istopfix",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                name: "group04",
                                id: "group42",
                                value: "T",
                              },
                              domProps: {
                                checked: _vm._q(_vm.info.istopfix, "T"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.info, "istopfix", "T")
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "group42" } }, [
                              _vm._v("게시일로부터"),
                            ]),
                          ]),
                          _c("CommonDatePickerFromTo", {
                            attrs: {
                              fromYYYYMMDD: _vm.info.endYYYYMMDD,
                              fromHH: _vm.info.endHH,
                              fromMM: _vm.info.endMi,
                              "use-to": false,
                              "from-disable": _vm.info.istopfix === "F",
                            },
                            on: { getDate: _vm.changeEndDate },
                          }),
                          _c("span", [_vm._v("분까지 상단 고정")]),
                        ],
                        1
                      ),
                    ]),
                    _c("tr", [
                      _vm._m(4),
                      _c("td", [
                        _c("div", [_c("CommonEditor", { ref: "pcEditor" })], 1),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(5),
                      _c("td", [
                        _c("div", { staticClass: "mb10" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn blue-line",
                              attrs: { type: "button" },
                              on: { click: _vm.copyByContent },
                            },
                            [_vm._v(" PC 내용을 복사 ")]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [_c("CommonEditor", { ref: "mobileEditor" })],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: { click: _vm.onSave },
                },
                [_vm._v("저장")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn big darkgray",
                  attrs: { type: "button" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("취소")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_vm._v("제목"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("예약여부"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("상단고정"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("내용(PC)"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("내용(모바일)"),
      _c("i", { staticClass: "essential" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }