import axios from 'axios';

import util from '@root/js/util.js';
import storage from '@root/js/storage.js';
import store from '@root/js/store.js';

import event from '@root/js/event.js';
import adminRouter from '@router/admin';
import partnersRouter from '@router/partners';

/*----------------------------- axios 설정 ------------------------------*/
axios.defaults.baseURL = '/api';
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const validSuccessStatusCodesWithModern = ({ statusCode, status }) =>
  statusCode < 300 && statusCode >= 200 && status?.toLowerCase() === 'ok'; // 모던에서는 200번대 + Status OK는 모두 성공 리스폰스

const validSuccessStatusCodesWithClassic = (statusCode) => statusCode === 200 || statusCode === 201; // 클래식에서는 200 & 201 말고는 모두 실패 코드

// 에러객체를 만들어 반환한다. 클래식에서는 얼럿을 띄운다.
const getErrorAfterAlertToClassic = (error) => {
  event.$emit('loading', false);
  util.error('Response Error : ' + error);
  const requestUrl = error.config.url ?? '';
  const transformError = new Error(error.response?.data?.message || error.message);
  !requestUrl?.includes('v2') && alert(transformError.message); //모던일 경우에는 비지니스 로직에서 에러를 처리하도록 함.
  return transformError;
};

// 커스텀 에러 핸들러에서 반환한 에러를 활용하여 얼럿을 띄운다.
const alertWithCustomError = (error) => {
  event.$emit('loading', false);
  util.error('Response Error : ' + error);
  alert(error.message); //모던일 경우에는 비지니스 로직에서 에러를 처리하도록 함.
};
const handleInterceptError = (error) => {
  const transformError = getErrorAfterAlertToClassic(error);
  return Promise.reject(transformError);
};

// API 요청 전처리
axios.interceptors.request.use(function (request) {
  try {
    const paramData = ['post', 'patch'].includes(request.method) ? request.data : request.params;
    !!paramData?.isloading && event.$emit('loading', true);
    const system = window.sessionStorage.getItem('system');
    if (request.url.indexOf('http') === -1) {
      request.headers.platform = window.sessionStorage.getItem('platform');
    }
    //관리자/파트너사 컴포넌트를 같이 쓰기위해서 URL 맵핑을 변경해줌
    const session = storage.getLocalStorage(store.getters.CONSTANTS.MANAGER_SESSION);
    const admin = storage.getLocalStorage(store.getters.CONSTANTS.ADMIN_USER);
    const partner = storage.getLocalStorage(store.getters.CONSTANTS.PARTNER_USER);

    const requestUrl = request.url;
    util.debug(`requestUrl::${requestUrl}`);
    // 로그인 액션 API 호출인지 아닌지 여부
    const isNotLoginActionRequestUrl =
      !requestUrl.startsWith('/v2') &&
      !requestUrl.includes('/admin/login_act') &&
      !requestUrl.includes('/partners/login_act');

    // 해당 request URL일 경우 system 과 session을 비교해서 다르면 로그인 페이지로 이동시킨다.
    const checkedSystemUrl =
      !requestUrl.startsWith('/v2') &&
      !requestUrl.startsWith('/partnership/') &&
      !requestUrl.startsWith('/common/') &&
      isNotLoginActionRequestUrl;

    const checkedSystem =
      typeof session !== 'undefined' && (system === 'ADMIN' || system === 'PARTNER');

    if (checkedSystemUrl && checkedSystem) {
      const targetSystem = system === 'ADMIN' ? admin : partner;
      const targetRemoveStorageKey =
        system === 'ADMIN'
          ? store.getters.CONSTANTS.ADMIN_USER
          : store.getters.CONSTANTS.PARTNER_USER;
      const targetRouterBySystem = system === 'ADMIN' ? adminRouter : partnersRouter;
      const blockToGoLoginPage = () => {
        storage.removeLocalStorage(targetRemoveStorageKey);
        targetRouterBySystem.push('/login').then((err) => util.debug(err));
        return new Promise(function (resolve, reject) {});
      };
      // session 과 system이 서로 다르면 login 페이지로 튕기도록 한다.
      JSON.stringify(session) !== JSON.stringify(targetSystem) && blockToGoLoginPage();
    }

    if (
      typeof session !== 'undefined' &&
      session.usertype === store.getters.CONSTANTS.ADMIN.USER_TYPE_PARTNER &&
      !requestUrl.startsWith('/v2') &&
      requestUrl.startsWith('/admin/') &&
      isNotLoginActionRequestUrl
    ) {
      request.url = requestUrl.replace('/admin/', '/partners/');
    }

    if (
      request.method !== 'post' ||
      request.data == null ||
      typeof request.data === 'string' ||
      request.data instanceof String
    ) {
      return request;
    }

    util.debug('Request Data to FormData');

    if (Object.prototype.hasOwnProperty.call(paramData, 'files')) {
      // Object paramData를 FormData로 전환
      // 첨부파일이 있을경우 files : Array[]로 추가
      // 나머지 파라미터는 JSON string으로 전송
      const frmData = new FormData();
      const files = paramData['files'];
      files?.forEach((fileObj) => {
        const { key, file } = fileObj;
        util.debug(file);
        frmData.append(key, file);
      });

      delete paramData['files'];
      if (!requestUrl.includes('v2')) {
        frmData.append(
          'params',
          new Blob([JSON.stringify(paramData)], { type: 'application/json' }),
        );
      } else {
        const entries = Object.entries(paramData);
        entries.forEach(([key, value]) => frmData.append(key, value));
      }
      request.data = frmData;
    } else {
      request.data = paramData;
    }
    return request;
  } catch (err) {
    return Promise.reject(err);
  }
}, handleInterceptError);

const getFileName = (contentDisposition) => {
  let fileName = 'unknown';
  if (contentDisposition) {
    const [fileNameMatch] = contentDisposition.split(';').filter((str) => str.includes('filename'));
    fileNameMatch && ([, fileName] = fileNameMatch.split('='));
  }

  fileName = decodeURIComponent(fileName);
  return fileName ?? '';
};

const setMSSaveOrOpenBlob = (res) => {
  const contentDisposition = res.headers['content-disposition'];

  const fileName = getFileName(contentDisposition);
  window.navigator.msSaveBlob(new Blob([res.data]), fileName);
};
const setLinkToDownload = (res) => {
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement('a');
  const contentDisposition = res.headers['content-disposition'];

  const fileName = getFileName(contentDisposition);

  link.href = url;
  link.setAttribute('download', `${fileName}`);
  link.style.cssText = 'display:none';
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const handleInterceptResponse = (res) => {
  try {
    let valid;
    const isClassic = !res.config.url?.match(/[v][2-9]/gi); // v2 ... v9 가 api url에 포함되었을 경우 모던 API 이고 없으면 클래식 API로 구분

    if (res.headers['content-type']?.includes('application/vnd')) {
      // 컨텐츠 타입이 파일이면 http status 201일 경우 성공으로 간주하여 validation 하지않는다.
      // 파일일 경우에는 response 모델이 없다. statusCode & status 없어서 http status 만 있음.
      if (!res.data?.statusCode && !res.data?.status) {
        // res.data 객체가 없을 경우 파일 다운로드가 성공했다고 가정한다.
        valid = true;

        window.navigator?.msSaveOrOpenBlob && setMSSaveOrOpenBlob(res);
        !window.navigator?.msSaveOrOpenBlob && setLinkToDownload(res);
      } else {
        // res.data 객체가 있으면 파일 다운로드가 실패했다고 가정한다.
        valid = isClassic
          ? validSuccessStatusCodesWithClassic(res.data.statusCode || res.data.status) // 클래식일 경우 succss status valid, classic 은 statudCode가 없고 status로 내려오는 경우도 있다.
          : validSuccessStatusCodesWithModern(res.data); // 모던일 경우 succss status valid
      }
    } else {
      // 컨텐츠 타입이 파일이 아닐 경우
      valid = isClassic
        ? validSuccessStatusCodesWithClassic(res.data.statusCode || res.data.status) // 클래식일 경우 succss status valid, classic 은 statudCode가 없고 status로 내려오는 경우도 있다.
        : validSuccessStatusCodesWithModern(res.data); // 모던일 경우 succss status valid
    }

    event.$emit('loading', false);
    util.debug('Response Info : ' + JSON.stringify(res));
    util.debug('Response Data : ' + JSON.stringify(res.data));

    if (valid) {
      // 성공일 경우
      return res.data;
    } else {
      const error = getCustomResponseError(res);
      alertWithCustomError(error);
      return Promise.reject(error);
    }
  } catch (err) {
    const transformError = getErrorAfterAlertToClassic(err);
    return Promise.reject(transformError);
  }
};

const getCustomResponseError = (res) => {
  const { statusCode, message, status } = res.data; // classic 은 statudCode가 없고 status로 내려오는 경우도 있다.
  const targetStatusCode = typeof statusCode !== 'number' ? status : statusCode; // classic 은 statudCode가 없고 status로 내려오는 경우도 있다.
  util.debug('Axios Custom Error Data');
  util.debug(`Response Data:: ${JSON.stringify(res.data)}`);
  const system = window.sessionStorage.getItem('system');

  // TODO 300 번대 리다이렉트 응답 코드일 경우 처리도 필요하지 않나?
  switch (targetStatusCode) {
    case 204: {
      // classic에 204인데 에러인 경우도 있다.
      util.debug('204 Status Error');
      return new Error(message || '오류가 발생했습니다.');
    }
    case 400: {
      util.debug('400 Error');
      return new Error(message || '오류가 발생했습니다.');
    }
    case 401: {
      //서버세션이 만료되었을 경우 처리
      if (res.data.errorShow !== true) {
        return new Error(message || '오류가 발생했습니다.');
      }

      if (system === 'ADMIN') {
        storage.removeLocalStorage(store.getters.CONSTANTS.MANAGER_SESSION);
        storage.removeLocalStorage(store.getters.CONSTANTS.ADMIN_USER);
        !location.pathname.includes('/login') &&
          adminRouter.push('/login').then((r) => util.debug(r));
      } else if (system === 'PARTNER') {
        storage.removeLocalStorage(store.getters.CONSTANTS.MANAGER_SESSION);
        storage.removeLocalStorage(store.getters.CONSTANTS.PARTNER_USER);
        !location.pathname.includes('/login') &&
          partnersRouter.push('/login').then((r) => util.debug(r));
      }
      return new Error(message || '오류가 발생했습니다.');
    }
    case 404: {
      util.debug('404 Not found');
      return new Error(message || '오류가 발생했습니다.');
    }
    case 500: {
      util.debug('500 Server error');
      return new Error(message || '오류가 발생했습니다.');
    }
    default: {
      return new Error(message || '오류가 발생했습니다.');
    }
  }
};

// API 요청 후처리
axios.interceptors.response.use(handleInterceptResponse, handleInterceptError);

export default axios;
