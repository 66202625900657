<template>
  <!-- 반품상세 팝업 -->
  <div id="modal-wrap" class="modal" style="display: block">
    <div class="modal-content" style="width: 1600px">
      <div class="pop-header">
        <h2>반품상세{{ ' - ' + claimInfo.rtnstatusnm }}</h2>
        <button type="button" class="pop-close" @click="onClose"></button>
      </div>
      <div class="pop-body">
        <div class="gray-box mg0">
          <div class="clearfix">
            <div class="fl">
              [{{ orderInfo.ordpathtypename }}] 주문번호 : <strong>{{ orderInfo.ordno }}</strong>
            </div>
            <div class="fr txt-gray">
              <span>주문일 : {{ orderInfo.orderdate }}</span>
              <span class="left-bar" v-if="isPartner">반품신청일 : {{ orderInfo.clmreqdate }}</span>
            </div>
          </div>
          <hr class="solid" style="margin: 10px 0" v-if="!isPartner" />
          <div class="clearfix" v-if="!isPartner">
            <div class="fl">
              <span v-if="orderInfo.isnonmember == 'F'"
                >[{{ orderInfo.membertypename }}] [{{ orderInfo.memlvtypename }}]
                {{ orderInfo.ordname }}({{ orderInfo.ordid }})</span
              >
              <span v-else>{{ orderInfo.ordname }}</span>
              <span class="left-bar" v-if="orderInfo.isnonmember == 'F'">{{
                orderInfo.ordtel
              }}</span>
              <span class="left-bar" v-if="orderInfo.isnonmember == 'F'">{{
                orderInfo.ordemail
              }}</span>
            </div>
            <div class="fr txt-gray">
              <span>반품신청일 : {{ orderInfo.clmreqdate }}</span>
            </div>
          </div>
        </div>
        <div class="clearfix mt20">
          <div class="bar-title small fl">반품상품</div>
        </div>
        <template v-for="row in claimGoodsList">
          <table
            cellpadding="0"
            cellspacing="0"
            class="data-tb align-c mt0"
            :key="'list_' + row.ordgdidx"
          >
            <caption>
              반품상품 목록
            </caption>
            <colgroup>
              <col width="4%" v-if="!isPartner" />
              <!-- 판매구분 -->
              <col width="6%" v-if="!isPartner" />
              <!-- 파트너사 -->
              <col width="6.5%" />
              <!-- 상품코드 -->
              <col width="4%" />
              <!-- 단품코드 -->
              <col width="62px" />
              <!-- 상품이미지 -->
              <col width="" />
              <!-- 상품명 -->
              <col width="10%" />
              <!-- 옵션 -->
              <col width="4%" />
              <!-- 주문수량 -->
              <col width="4%" />
              <!-- 반품수량 -->
              <col width="5.5%" />
              <!-- 판매단가 -->
              <col width="5.5%" />
              <!-- 판매금액 -->
              <col width="5.5%" />
              <!-- 반품금액 -->
              <col width="8%" />
              <!-- 반품상태 -->
              <col width="9%" />
              <!-- 반품번호 -->
            </colgroup>
            <thead>
              <tr>
                <th v-if="!isPartner">판매구분</th>
                <th v-if="!isPartner">파트너사</th>
                <th>상품코드</th>
                <th>단품코드</th>
                <th colspan="2">상품명</th>
                <th>옵션</th>
                <th>주문수량</th>
                <th>반품수량</th>
                <th>판매단가</th>
                <th>판매금액</th>
                <th>반품금액</th>
                <th>반품상태</th>
                <th>반품번호</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="!isPartner">{{ row.ispbgoods === 'T' ? '자사' : '파트너사' }}</td>
                <td v-if="!isPartner">{{ row.dealernm }}</td>
                <td>{{ row.goodscode }}</td>
                <td>{{ row.optioncode }}</td>
                <td>
                  <div
                    class="img-thumb size60 link"
                    @click="goFrontGoodsDetail(row.goodscode)"
                    :class="{ 'no-image': $util.isNull(row.fullpath) }"
                  >
                    <img :src="row.fullpath" v-if="!$util.isNull(row.fullpath)" />
                  </div>
                </td>
                <td class="left no-left">
                  <a href="javascript:void(0);" class="link" @click="goGoodsDetail(row.goodsno)">{{
                    row.goodsname
                  }}</a>
                </td>
                <td style="white-space: pre-wrap">{{ row.optionname }}</td>
                <td>{{ $util.maskComma(row.bfordcnt) }}</td>
                <td>{{ $util.maskComma(row.clmcnt) }}</td>
                <td class="right">{{ $util.maskComma(row.price) }}</td>
                <td class="right">
                  {{ $util.maskComma(Number(row.price) * Number(row.bfordcnt)) }}
                </td>
                <td class="right">{{ $util.maskComma(Number(row.price) * Number(row.clmcnt)) }}</td>
                <td>
                  <a href="javascript:void(0);" class="link" @click="goClaimHistory(row.clmgdidx)">
                    {{ row.boclmstatusnm }}
                    {{
                      row.clmstatus === $store.getters['ADMIN'].RETURN_APPROVAL_COMPLETE &&
                      claimInfo.isrectracking === 'T'
                        ? ' - 회수진행요청'
                        : ''
                    }}
                  </a>
                </td>
                <td>{{ row.clmno }}</td>
              </tr>
            </tbody>
          </table>
        </template>
        <table cellpadding="0" cellspacing="0" class="gray-tb">
          <colgroup>
            <col width="150px" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th>반품사유<i class="essential"></i></th>
              <td>
                <select v-model="claimInfo.rtntype" disabled>
                  <option value="">구분선택</option>
                  <option
                    v-for="item in commonCode.rtntype"
                    :key="item.cmcode"
                    :value="item.cmcode"
                  >
                    {{ item.codename }}
                  </option>
                </select>
                <input
                  type="text"
                  class="ml3"
                  style="width: calc(100% - 156px)"
                  v-model="claimInfo.clmdetail"
                  maxlength="500"
                  disabled
                />
              </td>
            </tr>
            <tr>
              <th>사진첨부</th>
              <td>
                <div v-for="(row, index) in fileList" :key="index" class="dpib">
                  <a href="javascript:void(0);" class="file-link" @click="viewFile(row.fullpath)">{{
                    row.imgforiname
                  }}</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="bar-title small"
          v-if="!isPartner && !$util.isNull(claimGiftList) && claimGiftList.length > 0"
        >
          반품 사은품
        </div>
        <table
          cellpadding="0"
          cellspacing="0"
          class="data-tb align-c"
          v-if="!$util.isNull(claimGiftList) && claimGiftList.length > 0"
        >
          <caption>
            사은품 목록
          </caption>
          <colgroup>
            <col width="10%" />
            <!-- 사은품코드 -->
            <col width="62px" />
            <!-- 사은품이미지 -->
            <col width="" />
            <!-- 사은품명 -->
            <col width="7%" />
            <!-- 수량 -->
            <col width="35%" />
            <!-- 적용프로모션 명 -->
          </colgroup>
          <thead>
            <tr>
              <th>사은품코드</th>
              <th colspan="2">사은품명</th>
              <th>수량</th>
              <th>적용프로모션 명</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in claimGiftList" :key="'cncgift' + index">
              <td>{{ item.giftcode }}</td>
              <td>
                <div class="img-thumb size60" :class="{ 'no-image': $util.isNull(item.fullpath) }">
                  <img :src="item.fullpath" v-if="!$util.isNull(item.fullpath)" />
                </div>
              </td>
              <td class="left no-left">
                <p class="mg0">{{ item.giftname }}</p>
              </td>
              <td>{{ item.rcvamt }}</td>
              <td>{{ item.promoname }}</td>
            </tr>
          </tbody>
        </table>
        <div
          class="clearfix"
          v-if="
            !isPartner &&
            (isEditGift || (!$util.isNull(chkReapplyGiftList) && chkReapplyGiftList.length > 0))
          "
        >
          <div class="bar-title small fl">재 적용 사은품</div>
          <!-- <div class="btn-group fr" v-if="isWrite && !isPartner && clmInfo.orgrtnstatus !== $store.getters['ADMIN'].RETURN_COMPLETE && isEditGift">
                        <span class="txt-orange mr5">사은품을 다시 선택하시기 바랍니다.</span>
                        <button type="button" class="btn blue-line" @click="selectReapplyGiftList">사은품 선택</button>
                    </div> -->
        </div>
        <table
          cellpadding="0"
          cellspacing="0"
          class="data-tb align-c mt0"
          v-if="
            !isPartner &&
            (isEditGift || (!$util.isNull(chkReapplyGiftList) && chkReapplyGiftList.length > 0))
          "
        >
          <caption>
            사은품 목록
          </caption>
          <colgroup>
            <col width="10%" />
            <!-- 사은품코드 -->
            <col width="62px" />
            <!-- 사은품이미지 -->
            <col width="" />
            <!-- 사은품명 -->
            <col width="7%" />
            <!-- 수량 -->
            <col width="35%" />
            <!-- 적용프로모션 명 -->
          </colgroup>
          <thead>
            <tr>
              <th>사은품코드</th>
              <th colspan="2">사은품명</th>
              <th>수량</th>
              <th>적용프로모션 명</th>
            </tr>
          </thead>
          <tbody v-if="chkReapplyGiftList.length > 0">
            <tr v-for="(item, index) in chkReapplyGiftList" :key="'chkgift' + index">
              <td>{{ item.giftcode }}</td>
              <td>
                <div class="img-thumb size60" :class="{ 'no-image': $util.isNull(item.fullpath) }">
                  <img :src="item.fullpath" v-if="!$util.isNull(item.fullpath)" />
                </div>
              </td>
              <td class="left no-left">
                <p class="mg0">{{ item.giftname }}</p>
              </td>
              <td>{{ item.rcvamt }}</td>
              <td>{{ item.promoname }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5">조회 결과가 존재하지 않습니다.</td>
            </tr>
          </tbody>
        </table>
        <hr class="dash" />
        <div class="col2 pd scroll">
          <div class="left">
            <div class="bar-title small">회수지 정보</div>
            <table cellpadding="0" cellspacing="0" class="gray-tb">
              <colgroup>
                <col width="150px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th>회수자 명</th>
                  <td>
                    <input
                      type="text"
                      v-model="recInfo.recname"
                      maxlength="50"
                      :disabled="claimInfo.isrectracking === 'T' || !isEditRecInfo"
                    />
                  </td>
                </tr>
                <tr>
                  <th>연락처</th>
                  <td>
                    <input
                      type="text"
                      v-model="recInfo.rectel"
                      maxlength="11"
                      :disabled="claimInfo.isrectracking === 'T' || !isEditRecInfo"
                      oninput="this.value = this.value.replace(/([^0-9])/gi, '');"
                    />
                  </td>
                </tr>
                <tr>
                  <th>회수지 주소</th>
                  <td colspan="3">
                    <div class="dpb">
                      <input
                        type="text"
                        class="short"
                        v-model="recInfo.recpost"
                        maxlength="7"
                        readonly
                      />
                      <button
                        type="button"
                        class="btn blue-line ml3"
                        @click="searchAddress('rec')"
                        :disabled="claimInfo.isrectracking === 'T' || !isEditRecInfo"
                      >
                        주소검색
                      </button>
                    </div>
                    <input
                      type="text"
                      class="dpb"
                      style="width: 100%"
                      v-model="recInfo.recaddrroad"
                      maxlength="100"
                      readonly
                    />
                    <input
                      type="text"
                      class="dpb"
                      style="width: 100%"
                      v-model="recInfo.recaddrdetailroad"
                      maxlength="100"
                      :disabled="claimInfo.isrectracking === 'T' || !isEditRecInfo"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="right">
            <div class="bar-title small">회수택배사 정보</div>
            <table cellpadding="0" cellspacing="0" class="gray-tb">
              <colgroup>
                <col width="150px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr
                  v-if="
                    this.claimInfo.rtndelivtype == 'DLT004' &&
                    (this.claimInfo.rtnstatus == 'RTS006' || this.claimInfo.rtnstatus == 'RTS007')
                  "
                >
                  <th>회수 택배사</th>
                  <td>
                    <select v-model="recInfo.reclogistype" style="width: 160px">
                      <option
                        v-for="row in rtnlogislist"
                        :key="row.index"
                        :value="row.rtnlogistype"
                      >
                        {{ row.logistypename }}
                      </option>
                    </select>
                    <input
                      type="text"
                      class="ml3"
                      placeholder="송장번호"
                      v-model="recInfo.recinvoiceno"
                      maxlength="50"
                      oninput="this.value = this.value.replace(/(^0|[^0-9])/gi, '');"
                    />
                    <button
                      type="button"
                      class="btn big blue ml3"
                      @click="saveRtninvoiceno(recInfo)"
                    >
                      저장
                    </button>
                  </td>
                </tr>
                <tr v-else>
                  <th>회수 택배사</th>
                  <td>
                    <select v-model="recInfo.reclogistype" style="width: 160px" disabled>
                      <option
                        v-for="row in rtnlogislist"
                        :key="row.rtnlogistype"
                        :value="row.rtnlogistype"
                      >
                        {{ row.logistypename }}
                      </option>
                    </select>
                    <input
                      type="text"
                      class="ml3"
                      placeholder="송장번호"
                      v-model="recInfo.recinvoiceno"
                      maxlength="50"
                      disabled
                      oninput="this.value = this.value.replace(/(^0|[^0-9])/gi, '');"
                    />
                  </td>
                </tr>
                <tr>
                  <th>회수 완료일</th>
                  <td>
                    <CommonDatePicker
                      :value="recInfo.reccomdate"
                      @change="onChangeRecDate"
                      :disable="true"
                    />
                    <input type="text" class="ml3" v-model="recInfo.reccomtime" readonly />
                  </td>
                </tr>
                <tr>
                  <th>상태</th>
                  <td>
                    <input type="text" v-model="recInfo.recstatname" readonly />
                    <button
                      type="button"
                      class="btn blue-line ml3"
                      :disabled="
                        $util.isNull(recInfo.recinvoiceno) ||
                        claimInfo.rtnstatus === $store.getters['ADMIN'].RETURN_APPROVAL_COMPLETE ||
                        claimInfo.rtndelivtype === $store.getters['ADMIN'].DELIV_DIRECT
                      "
                      @click="goDelivTracking('rec')"
                    >
                      배송조회
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr class="dash" />
        <div class="col3 pd scroll">
          <div class="left">
            <div class="bar-title small">이전 주문정보</div>
            <table cellpadding="0" cellspacing="0" class="gray-tb lower">
              <colgroup>
                <col width="150px" />
                <col width="230px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th>판매금액 합계</th>
                  <td colspan="2" class="right">
                    {{ $util.maskComma(clmInfo.bforder.ordtotprice) }}
                  </td>
                </tr>
                <tr v-if="!isPartner">
                  <th>자사 배송비(+)</th>
                  <td>
                    {{ clmInfo.bforder.isolatetype === 'N' ? '기본배송비' : '도서산간배송비' }}
                  </td>
                  <td class="right">{{ $util.maskComma(clmInfo.bforder.dadadelivamt) }}</td>
                </tr>
                <tr>
                  <th>파트너사 배송비(+)</th>
                  <td>
                    {{ clmInfo.bforder.isolatetype === 'N' ? '기본배송비' : '도서산간배송비' }}
                  </td>
                  <td class="right">{{ $util.maskComma(clmInfo.bforder.ptndelivamt) }}</td>
                </tr>
                <!-- 이전프로모션할인 -->
                <tr v-if="$util.isNull(clmInfo.bfpromotion) || clmInfo.bfpromotion.length === 0">
                  <th>프로모션 할인</th>
                  <td class="txt-gray2">(적용할인없음)</td>
                  <td class="right txt-red">0</td>
                </tr>
                <fragment v-else>
                  <tr v-for="(promoRow, index) in clmInfo.bfpromotion" :key="promoRow.idx">
                    <th :rowspan="clmInfo.bfpromotion.length" v-if="index === 0">프로모션 할인</th>
                    <td>{{ promoRow.promoname }}</td>
                    <td class="right txt-red">
                      {{ $util.maskComma(Number(promoRow.disamount) * -1) }}
                    </td>
                  </tr>
                </fragment>

                <!-- 이전상품쿠폰할인 -->
                <tr
                  v-if="$util.isNull(clmInfo.bfgoodscoupon) || clmInfo.bfgoodscoupon.length === 0"
                >
                  <th>상품 할인</th>
                  <td class="txt-gray2">(적용할인없음)</td>
                  <td class="right txt-red">0</td>
                </tr>
                <fragment v-else>
                  <tr v-for="(goodsCpnRow, index) in clmInfo.bfgoodscoupon" :key="goodsCpnRow.idx">
                    <th :rowspan="clmInfo.bfgoodscoupon.length" v-if="index === 0">상품 할인</th>
                    <td>{{ goodsCpnRow.cpnname }}</td>
                    <td class="right txt-red">
                      {{ $util.maskComma(Number(goodsCpnRow.disamount) * -1) }}
                    </td>
                  </tr>
                </fragment>

                <!-- 이전장바구니쿠폰할인 -->
                <tr>
                  <th>장바구니 할인</th>
                  <td
                    v-if="!$util.isNull(clmInfo.bfbasketcoupon) && clmInfo.bforder.basketcpnamt > 0"
                  >
                    {{ clmInfo.bfbasketcoupon.cpnname }}
                  </td>
                  <td v-else class="txt-gray2">(적용할인없음)</td>
                  <td class="right txt-red">
                    {{ $util.maskComma(Number(clmInfo.bforder.basketcpnamt) * -1) }}
                  </td>
                </tr>
                <!-- 이전배송비쿠폰할인 -->
                <tr>
                  <th>배송비 할인</th>
                  <td
                    v-if="
                      !$util.isNull(clmInfo.bfdelivcoupon) && clmInfo.bforder.totdelivcpnamt > 0
                    "
                  >
                    {{ clmInfo.bfdelivcoupon.cpnname }}
                  </td>
                  <td v-else class="txt-gray2">(적용할인없음)</td>
                  <td class="right txt-red">
                    {{ $util.maskComma(Number(clmInfo.bforder.totdelivcpnamt) * -1) }}
                  </td>
                </tr>
                <tr v-if="!isPartner">
                  <th>임직원적립금 사용(-)</th>
                  <td colspan="2" class="right txt-red">
                    {{ $util.maskComma(Number(clmInfo.bforder.empreservetotamt) * -1) }}
                  </td>
                </tr>
                <tr v-if="!isPartner">
                  <th>적립금 사용(-)</th>
                  <td colspan="2" class="right txt-red">
                    {{ $util.maskComma(Number(clmInfo.bforder.reservetotamt) * -1) }}
                  </td>
                </tr>
                <tr v-if="!isPartner">
                  <th>D포인트 사용(-)</th>
                  <td colspan="2" class="right txt-red">
                    {{ $util.maskComma(Number(clmInfo.bforder.epointtotamt) * -1) }}
                  </td>
                </tr>
                <tr v-if="isPartner">
                  <th>적립금 사용(-)</th>
                  <td colspan="2" class="right txt-red">
                    {{
                      $util.maskComma(
                        (Number(clmInfo.bforder.empreservetotamt) +
                          Number(clmInfo.bforder.reservetotamt) +
                          Number(clmInfo.bforder.epointtotamt)) *
                          -1,
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <th>실 결제금액</th>
                  <td colspan="2" class="right">
                    <strong class="large-txt">{{
                      $util.maskComma(clmInfo.bforder.rpaytotprice)
                    }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="middle">
            <div class="bar-title small">재 계산 판매금액 합계</div>
            <table cellpadding="0" cellspacing="0" class="gray-tb lower">
              <colgroup>
                <col width="150px" />
                <col width="230px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th>재 계산 주문금액 합계</th>
                  <td colspan="2" class="right">
                    {{ $util.maskComma(clmInfo.aforder.ordtotprice) }}
                  </td>
                </tr>
                <tr v-if="!isPartner">
                  <th>자사 배송비(+)</th>
                  <td>
                    {{ clmInfo.bforder.isolatetype === 'N' ? '기본배송비' : '도서산간배송비' }}
                  </td>
                  <td class="right">{{ $util.maskComma(clmInfo.aforder.dadadelivamt) }}</td>
                </tr>
                <tr>
                  <th>파트너사 배송비(+)</th>
                  <td>
                    {{ clmInfo.bforder.isolatetype === 'N' ? '기본배송비' : '도서산간배송비' }}
                  </td>
                  <td class="right">{{ $util.maskComma(clmInfo.aforder.ptndelivamt) }}</td>
                </tr>
                <!-- 재계산프로모션할인 -->
                <tr v-if="$util.isNull(clmInfo.afpromotion) || clmInfo.afpromotion.length === 0">
                  <th>프로모션 할인</th>
                  <td class="txt-gray2">(적용할인없음)</td>
                  <td class="right txt-red">0</td>
                </tr>
                <fragment v-else>
                  <tr v-for="(promoRow, index) in clmInfo.afpromotion" :key="promoRow.idx">
                    <th :rowspan="clmInfo.afpromotion.length" v-if="index === 0">프로모션 할인</th>
                    <td>{{ promoRow.promoname }}</td>
                    <td class="right txt-red">
                      {{ $util.maskComma(Number(promoRow.disamount) * -1) }}
                    </td>
                  </tr>
                </fragment>

                <!-- 재계산상품쿠폰할인 -->
                <tr
                  v-if="$util.isNull(clmInfo.afgoodscoupon) || clmInfo.afgoodscoupon.length === 0"
                >
                  <th>상품 할인</th>
                  <td class="txt-gray2">(적용할인없음)</td>
                  <td class="right txt-red">0</td>
                </tr>
                <fragment v-else>
                  <tr v-for="(goodsCpnRow, index) in clmInfo.afgoodscoupon" :key="goodsCpnRow.idx">
                    <th :rowspan="clmInfo.afgoodscoupon.length" v-if="index === 0">상품 할인</th>
                    <td>{{ goodsCpnRow.cpnname }}</td>
                    <td class="right txt-red">
                      {{ $util.maskComma(Number(goodsCpnRow.disamount) * -1) }}
                    </td>
                  </tr>
                </fragment>

                <!-- 재계산장바구니쿠폰할인 -->
                <tr>
                  <th>장바구니 할인</th>
                  <td v-if="clmInfo.aforder.basketcpnamt > 0">
                    {{ clmInfo.bfbasketcoupon.cpnname }}
                  </td>
                  <td v-else class="txt-gray2">(적용할인없음)</td>
                  <td class="right txt-red">
                    {{ $util.maskComma(Number(clmInfo.aforder.basketcpnamt) * -1) }}
                  </td>
                </tr>
                <!-- 재계산배송비쿠폰할인 -->
                <tr>
                  <th>배송비 할인</th>
                  <td
                    v-if="
                      !$util.isNull(clmInfo.afdelivcoupon) && clmInfo.aforder.totdelivcpnamt > 0
                    "
                  >
                    {{ clmInfo.afdelivcoupon.cpnname }}
                  </td>
                  <td v-else class="txt-gray2">(적용할인없음)</td>
                  <td class="right txt-red">
                    {{ $util.maskComma(Number(clmInfo.aforder.totdelivcpnamt) * -1) }}
                  </td>
                </tr>
                <tr v-if="!isPartner">
                  <th>임직원적립금 사용(-)</th>
                  <td colspan="2" class="right txt-red">
                    {{ $util.maskComma(Number(clmInfo.aforder.empreservetotamt) * -1) }}
                  </td>
                </tr>
                <tr v-if="!isPartner">
                  <th>적립금 사용(-)</th>
                  <td colspan="2" class="right txt-red">
                    {{ $util.maskComma(Number(clmInfo.aforder.reservetotamt) * -1) }}
                  </td>
                </tr>
                <tr v-if="!isPartner">
                  <th>D포인트 사용(-)</th>
                  <td colspan="2" class="right txt-red">
                    {{ $util.maskComma(Number(clmInfo.aforder.epointtotamt) * -1) }}
                  </td>
                </tr>
                <tr v-if="isPartner">
                  <th>적립금 사용(-)</th>
                  <td colspan="2" class="right txt-red">
                    {{
                      $util.maskComma(
                        (Number(clmInfo.aforder.empreservetotamt) +
                          Number(clmInfo.aforder.reservetotamt) +
                          Number(clmInfo.aforder.epointtotamt)) *
                          -1,
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <th>재 계산 최종금액</th>
                  <td colspan="2" class="right">
                    <strong class="large-txt">{{
                      $util.maskComma(clmInfo.aforder.rpaytotprice)
                    }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="right">
            <template
              v-if="Number(claimInfo.rtndadadelivamt) + Number(claimInfo.rtnptndelivamt) > 0"
            >
              <div class="bar-title small">차감배송비</div>
              <table cellpadding="0" cellspacing="0" class="gray-tb lower">
                <colgroup>
                  <col width="150px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr v-if="!isPartner">
                    <th>자사배송비</th>
                    <td class="right">{{ $util.maskComma(claimInfo.rtndadadelivamt) }}</td>
                  </tr>
                  <tr>
                    <th>파트너사 배송비</th>
                    <td class="right">{{ $util.maskComma(claimInfo.rtnptndelivamt) }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
            <div class="bar-title small">추가배송비</div>
            <table cellpadding="0" cellspacing="0" class="gray-tb lower">
              <colgroup>
                <col width="150px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr v-if="!isPartner">
                  <th>자사배송비</th>
                  <td class="right">{{ $util.maskComma(claimInfo.adddadadelivamt) }}</td>
                </tr>
                <tr>
                  <th>파트너사 배송비</th>
                  <td class="right">{{ $util.maskComma(claimInfo.addptndelivamt) }}</td>
                </tr>
              </tbody>
            </table>
            <template v-if="Number(claimInfo.addrpaytotprice) > 0">
              <div class="bar-title small">
                추가결제내역<span
                  class="normal txt-orange ml10"
                  v-if="claimInfo.rtnstatus === $store.getters['ADMIN'].RETURN_WAITING_DEPOSIT"
                  >추가금 발생일로부터 {{ $util.maskComma(claimInfo.elapsedaycnt) }}일 경과</span
                >
              </div>
              <table cellpadding="0" cellspacing="0" class="gray-tb lower">
                <colgroup>
                  <col width="150px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>결제일시</th>
                    <td>{{ claimInfo.addpaymentdate }}</td>
                  </tr>
                  <tr>
                    <th>결제자명</th>
                    <td>{{ $util.isNull(claimInfo.addpaymentdate) ? '' : orderInfo.ordname }}</td>
                  </tr>
                  <tr>
                    <th>결제수단</th>
                    <td>
                      {{ claimInfo.addpaywaytypenm }}
                      {{
                        claimInfo.addpaywaytype === $store.getters['ADMIN'].PAY_CREDIT_CARD
                          ? '(' +
                            claimInfo.cardcompany +
                            '/' +
                            (claimInfo.planmonth == 0 || $util.isNull(claimInfo.planmonth)
                              ? '일시불'
                              : claimInfo.planmonth + '개월') +
                            ')'
                          : ''
                      }}
                      {{
                        claimInfo.addpaywaytype === $store.getters['ADMIN'].PAY_VIRTURE_ACCOUNT
                          ? '(' + claimInfo.virbank + '/' + claimInfo.accntnumber + ')'
                          : ''
                      }}
                      {{
                        claimInfo.addpaywaytype === $store.getters['ADMIN'].PAY_ACCOUNT_TRANSFER
                          ? '(' + claimInfo.trsbank + ')'
                          : ''
                      }}
                      {{
                        claimInfo.addpaywaytype === $store.getters['ADMIN'].PAY_MOBILE
                          ? '(' + claimInfo.mopcarrier + ')'
                          : ''
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>금액</th>
                    <td class="right">
                      {{
                        claimInfo.addrpaytotprice === 0
                          ? ''
                          : $util.maskComma(claimInfo.addrpaytotprice)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
            <div class="bar-title small">환불 금액</div>
            <table cellpadding="0" cellspacing="0" class="gray-tb lower">
              <colgroup>
                <col width="150px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th>환불예정금액</th>
                  <td class="right">
                    <strong class="large-txt">{{ $util.maskComma(claimInfo.rtnamt) }}</strong>
                  </td>
                </tr>
                <tr>
                  <th>최종환불금액</th>
                  <td class="right" v-if="!isPartner">
                    <input
                      type="text"
                      class="right"
                      style="width: 100%"
                      v-model="claimInfo.finadjustamt"
                      :disabled="
                        claimInfo.orgrtnstatus !== $store.getters['ADMIN'].RETURN_COMPLETE_DELIVERY
                      "
                      maxlength="11"
                      oninput="this.value = this.value.replace(/(^0[0-9]|[^0-9])/gi, '');"
                    />
                  </td>
                  <td class="right" v-else>{{ $util.maskComma(claimInfo.finadjustamt) }}</td>
                </tr>
                <tr>
                  <th>조정금액(-)</th>
                  <td class="right">
                    {{
                      $util.maskComma(
                        (Number(claimInfo.rtnamt) - Number(claimInfo.finadjustamt)) * -1,
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <th>조정사유</th>
                  <td v-if="!isPartner">
                    <input
                      type="text"
                      style="width: 100%"
                      placeholder="조정금액 저장시 사유는 필수 입력사항입니다."
                      v-model="claimInfo.adjustreason"
                      maxlength="200"
                      :disabled="
                        claimInfo.orgrtnstatus !== $store.getters['ADMIN'].RETURN_COMPLETE_DELIVERY
                      "
                    />
                  </td>
                  <td v-else>{{ claimInfo.adjustreason }}</td>
                </tr>
              </tbody>
            </table>
            <template
              v-if="clmInfo.bforder.paywaytype === $store.getters['ADMIN'].PAY_VIRTURE_ACCOUNT"
            >
              <div class="bar-title small">환불계좌 정보</div>
              <table cellpadding="0" cellspacing="0" class="gray-tb lower">
                <colgroup>
                  <col width="150px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>예금주명</th>
                    <td>{{ claimInfo.refcusname }}</td>
                  </tr>
                  <tr>
                    <th>환불계좌</th>
                    <td>[{{ claimInfo.refbanknm }}] {{ claimInfo.refaccnumber }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
            <div class="bar-title small">최종환불</div>
            <table cellpadding="0" cellspacing="0" class="gray-tb lower">
              <colgroup>
                <col width="150px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th>금액(PG)</th>
                  <td>{{ clmInfo.bforder.paywaytypename }}</td>
                  <td class="right">
                    <strong class="large-txt">{{ $util.maskComma(claimInfo.rtnpayamt) }}</strong>
                  </td>
                </tr>
                <tr v-if="!isPartner">
                  <th>임직원적립금 반환</th>
                  <td></td>
                  <td class="right">{{ $util.maskComma(claimInfo.rtnempresamt) }}</td>
                </tr>
                <tr v-if="!isPartner">
                  <th>적립금 반환</th>
                  <td></td>
                  <td class="right">{{ $util.maskComma(claimInfo.rtnresamt) }}</td>
                </tr>
                <tr v-if="!isPartner">
                  <th>D포인트 반환</th>
                  <td></td>
                  <td class="right">{{ $util.maskComma(claimInfo.rtnepoamt) }}</td>
                </tr>
                <tr v-if="isPartner">
                  <th>적립금 반환</th>
                  <td></td>
                  <td class="right">
                    {{
                      $util.maskComma(
                        Number(claimInfo.rtnempresamt) +
                          Number(claimInfo.rtnresamt) +
                          Number(claimInfo.rtnepoamt),
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <table
          cellpadding="0"
          cellspacing="0"
          class="gray-tb"
          v-if="
            (claimInfo.orgrtnstatus !== $store.getters['ADMIN'].RETURN_REQUEST_REFUSE &&
              claimInfo.orgrtnstatus !== $store.getters['ADMIN'].RETURN_APPROVAL_REFUSE &&
              claimInfo.rtnstatus === $store.getters['ADMIN'].RETURN_APPROVAL_REFUSE) ||
            claimInfo.rtnstatus === $store.getters['ADMIN'].RETURN_CANCEL ||
            claimInfo.rtnstatus === $store.getters['ADMIN'].RETURN_REQUEST_REFUSE ||
            claimInfo.rtnstatus === $store.getters['ADMIN'].RETURN_REQUEST_REFUSE ||
            !$util.isNull(claimInfo.rejsubreason) ||
            (claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_REQUEST_REFUSE &&
              claimInfo.rtnstatus === $store.getters['ADMIN'].RETURN_APPROVAL_COMPLETE) ||
            claimInfo.rtnstatus === $store.getters['ADMIN'].RETURN_A_RETURN ||
            !$util.isNull(claimInfo.rtnlogistype) ||
            !$util.isNull(claimInfo.rejreason) ||
            !$util.isNull(claimInfo.dropreason) ||
            !$util.isNull(claimInfo.rejsubreason) ||
            !$util.isNull(claimInfo.rejdenyreason)
          "
        >
          <colgroup>
            <col width="150px" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr
              v-if="
                (claimInfo.orgrtnstatus !== $store.getters['ADMIN'].RETURN_REQUEST_REFUSE &&
                  claimInfo.orgrtnstatus !== $store.getters['ADMIN'].RETURN_APPROVAL_REFUSE &&
                  claimInfo.rtnstatus === $store.getters['ADMIN'].RETURN_APPROVAL_REFUSE) ||
                (claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_APPROVAL_REFUSE &&
                  $util.isNull(claimInfo.rejsubreason) &&
                  !$util.isNull(claimInfo.rejreason))
              "
            >
              <th>반려사유<i class="essential"></i></th>
              <td>
                <input
                  type="text"
                  :style="isEditReason ? 'width: calc(100% - 57px);' : 'width: 100%;'"
                  placeholder="반려사유 입력 필수"
                  v-model="claimInfo.rejreason"
                  maxlength="200"
                />
                <button
                  type="button"
                  class="btn blue ml3"
                  v-if="isEditReason"
                  @click="saveClaim($store.getters['ADMIN'].RETURN_APPROVAL_REFUSE, 'save')"
                >
                  저장
                </button>
              </td>
            </tr>
            <tr
              v-if="
                claimInfo.rtnstatus === $store.getters['ADMIN'].RETURN_CANCEL ||
                !$util.isNull(claimInfo.dropreason)
              "
            >
              <th>철회사유<i class="essential"></i></th>
              <td>
                <input
                  type="text"
                  :style="isEditReason ? 'width: calc(100% - 57px);' : 'width: 100%;'"
                  placeholder="철회사유 입력 필수"
                  v-model="claimInfo.dropreason"
                  maxlength="200"
                />
                <button
                  type="button"
                  class="btn blue ml3"
                  v-if="isEditReason"
                  @click="saveClaim($store.getters['ADMIN'].RETURN_CANCEL, 'save')"
                >
                  저장
                </button>
              </td>
            </tr>
            <tr
              v-if="
                claimInfo.rtnstatus === $store.getters['ADMIN'].RETURN_REQUEST_REFUSE ||
                !$util.isNull(claimInfo.rejsubreason)
              "
            >
              <th>반려승인요청사유<i class="essential"></i></th>
              <td>
                <input
                  type="text"
                  :style="isPartner && isEditReason ? 'width: calc(100% - 57px);' : 'width: 100%;'"
                  placeholder="반려승인요청사유 입력 필수"
                  v-model="claimInfo.rejsubreason"
                  maxlength="200"
                />
                <button
                  type="button"
                  class="btn blue ml3"
                  v-if="isPartner && isEditReason"
                  @click="saveClaim($store.getters['ADMIN'].RETURN_REQUEST_REFUSE, 'save')"
                >
                  저장
                </button>
              </td>
            </tr>
            <tr
              v-if="
                (claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_REQUEST_REFUSE &&
                  claimInfo.rtnstatus === $store.getters['ADMIN'].RETURN_APPROVAL_REFUSE) ||
                (claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_APPROVAL_REFUSE &&
                  !$util.isNull(claimInfo.rejsubreason) &&
                  !$util.isNull(claimInfo.rejreason))
              "
            >
              <th>반려승인사유<i class="essential"></i></th>
              <td>
                <input
                  type="text"
                  :style="isEditReason ? 'width: calc(100% - 57px);' : 'width: 100%;'"
                  placeholder="반려승인사유 입력 필수"
                  v-model="claimInfo.rejreason"
                  maxlength="200"
                />
                <button
                  type="button"
                  class="btn blue ml3"
                  v-if="isEditReason"
                  @click="saveClaim($store.getters['ADMIN'].RETURN_APPROVAL_REFUSE, 'save')"
                >
                  저장
                </button>
              </td>
            </tr>
            <tr
              v-if="
                (claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_REQUEST_REFUSE &&
                  claimInfo.rtnstatus === $store.getters['ADMIN'].RETURN_APPROVAL_COMPLETE) ||
                !$util.isNull(claimInfo.rejdenyreason)
              "
            >
              <th>반려승인거부사유<i class="essential"></i></th>
              <td>
                <input
                  type="text"
                  :style="
                    isEditReason && claimInfo.rtnstatus !== $store.getters['ADMIN'].RETURN_A_RETURN
                      ? 'width: calc(100% - 57px);'
                      : 'width: 100%;'
                  "
                  placeholder="반려승인거부사유 입력 필수"
                  v-model="claimInfo.rejdenyreason"
                  maxlength="200"
                />
                <button
                  type="button"
                  class="btn blue ml3"
                  v-if="
                    isEditReason && claimInfo.rtnstatus !== $store.getters['ADMIN'].RETURN_A_RETURN
                  "
                  @click="saveClaim($store.getters['ADMIN'].RETURN_APPROVAL_COMPLETE, 'save')"
                >
                  저장
                </button>
              </td>
            </tr>
            <template
              v-if="
                claimInfo.rtnstatus === $store.getters['ADMIN'].RETURN_A_RETURN ||
                !$util.isNull(claimInfo.rtnlogistype)
              "
            >
              <tr>
                <th>반송처리사유<i class="essential"></i></th>
                <td>
                  <input
                    type="text"
                    style="width: 100%"
                    placeholder="반송 사유 입력 필수"
                    v-model="claimInfo.rtnreason"
                    maxlength="200"
                  />
                </td>
              </tr>
              <tr>
                <th>반송 택배사<i class="essential"></i></th>
                <td>
                  <select v-model="claimInfo.rtnlogistype" style="width: 160px">
                    <option :value="null">선택</option>
                    <option
                      v-for="row in commonCode.logistype"
                      :key="row.cmcode"
                      :value="row.cmcode"
                    >
                      {{ row.codename }}
                    </option>
                  </select>
                  <input
                    type="text"
                    class="ml3"
                    placeholder="송장번호"
                    v-model="claimInfo.rtninvoiceno"
                    maxlength="50"
                    oninput="this.value = this.value.replace(/(^0|[^0-9])/gi, '');"
                  />
                  <button
                    type="button"
                    class="btn blue ml3"
                    v-if="isEditReason"
                    @click="saveClaim($store.getters['ADMIN'].RETURN_A_RETURN, 'save')"
                  >
                    저장
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <div class="btn-group" v-if="!isPartner">
          <button
            type="button"
            class="btn big blue"
            v-if="
              claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_WAITING_APPLY ||
              claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_COMPLEATE_PAYMENT
            "
            @click="saveClaim($store.getters['ADMIN'].RETURN_APPROVAL_COMPLETE, 'proc')"
          >
            반품승인
          </button>
          <button
            type="button"
            class="btn big red"
            v-if="
              claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_WAITING_APPLY ||
              claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_COMPLEATE_PAYMENT
            "
            @click="changeClaimStatus($store.getters['ADMIN'].RETURN_APPROVAL_REFUSE)"
          >
            반품반려
          </button>
          <button
            type="button"
            class="btn big red"
            v-if="claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_WAITING_DEPOSIT"
            @click="changeClaimStatus($store.getters['ADMIN'].RETURN_APPROVAL_REFUSE)"
          >
            반품반려
          </button>
          <!-- <button type="button" class="btn big red" v-if="claimInfo.orgrtnstatus===$store.getters['ADMIN'].RETURN_WAITING_DEPOSIT" @click="changeClaimStatus($store.getters['ADMIN'].RETURN_CANCEL)">반품철회</button> -->
          <button
            type="button"
            class="btn big blue"
            v-if="claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_REQUEST_REFUSE"
            @click="changeClaimStatus($store.getters['ADMIN'].RETURN_APPROVAL_REFUSE)"
          >
            반품반려승인
          </button>
          <button
            type="button"
            class="btn big red"
            v-if="claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_REQUEST_REFUSE"
            @click="changeClaimStatus($store.getters['ADMIN'].RETURN_APPROVAL_COMPLETE)"
          >
            반품반려승인거부
          </button>
          <button
            type="button"
            class="btn big blue"
            v-if="
              claimInfo.ispbgoods === 'F' &&
              claimInfo.isrectracking === 'F' &&
              claimInfo.rtndelivtype != 'DLT004' &&
              claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_APPROVAL_COMPLETE
            "
            @click="procRtnDeliv('rec')"
          >
            회수진행
          </button>
          <button
            type="button"
            class="btn big blue"
            v-if="
              claimInfo.ispbgoods === 'F' &&
              claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_IN_DELIVERY
            "
            @click="saveClaim($store.getters['ADMIN'].RETURN_COMPLETE_DELIVERY, 'proc')"
          >
            회수완료
          </button>
          <button
            type="button"
            class="btn big blue-line"
            v-if="
              !isPartner &&
              claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_COMPLETE_DELIVERY
            "
            @click="saveFinAdjustamt"
          >
            조정금액저장
          </button>
          <button
            type="button"
            class="btn big blue"
            v-if="claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_COMPLETE_DELIVERY"
            @click="saveClaim($store.getters['ADMIN'].RETURN_COMPLETE, 'proc')"
          >
            처리완료
          </button>
          <button
            type="button"
            class="btn big red"
            v-if="claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_COMPLETE_DELIVERY"
            @click="changeClaimStatus($store.getters['ADMIN'].RETURN_A_RETURN)"
          >
            반송처리
          </button>
          <button type="button" class="btn big darkgray" @click="onClose">닫기</button>
        </div>
        <div class="btn-group" v-else>
          <button
            type="button"
            class="btn big blue"
            v-if="
              claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_WAITING_APPLY ||
              claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_COMPLEATE_PAYMENT
            "
            @click="saveClaim($store.getters['ADMIN'].RETURN_APPROVAL_COMPLETE, 'proc')"
          >
            반품승인
          </button>
          <button
            type="button"
            class="btn big red"
            v-if="
              claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_WAITING_APPLY ||
              claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_COMPLEATE_PAYMENT
            "
            @click="changeClaimStatus($store.getters['ADMIN'].RETURN_REQUEST_REFUSE)"
          >
            반품반려승인요청
          </button>
          <button
            type="button"
            class="btn big red"
            v-if="claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_WAITING_DEPOSIT"
            @click="changeClaimStatus($store.getters['ADMIN'].RETURN_REQUEST_REFUSE)"
          >
            반품반려승인요청
          </button>
          <!-- <button type="button" class="btn big red" v-if="claimInfo.orgrtnstatus===$store.getters['ADMIN'].RETURN_WAITING_DEPOSIT" @click="changeClaimStatus($store.getters['ADMIN'].RETURN_CANCEL)">반품철회</button> -->
          <button
            type="button"
            class="btn big blue"
            v-if="
              claimInfo.isrectracking === 'F' &&
              claimInfo.rtndelivtype != 'DLT004' &&
              claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_APPROVAL_COMPLETE
            "
            @click="procRtnDeliv('rec')"
          >
            회수진행
          </button>
          <button
            type="button"
            class="btn big blue"
            v-if="claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_IN_DELIVERY"
            @click="saveClaim($store.getters['ADMIN'].RETURN_COMPLETE_DELIVERY, 'proc')"
          >
            회수완료
          </button>
          <button
            type="button"
            class="btn big blue"
            v-if="claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_COMPLETE_DELIVERY"
            @click="saveClaim($store.getters['ADMIN'].RETURN_COMPLETE, 'proc')"
          >
            처리완료
          </button>
          <button
            type="button"
            class="btn big red"
            v-if="claimInfo.orgrtnstatus === $store.getters['ADMIN'].RETURN_COMPLETE_DELIVERY"
            @click="changeClaimStatus($store.getters['ADMIN'].RETURN_A_RETURN)"
          >
            반송처리
          </button>
          <button type="button" class="btn big darkgray" @click="onClose">닫기</button>
        </div>
      </div>
    </div>
    <AdminMemberInfo
      v-if="isShowMemDetail"
      :activeUserNo="activeUserNo"
      @closeDetail="closeMemDetail"
    ></AdminMemberInfo>
    <GoodsDetail
      v-if="isGoodsDetailShow"
      :activeGoodsNo="activeGoodsNo"
      @closePopup="closeGoodsDetail"
    ></GoodsDetail>
    <AddReapplyGiftPopup
      v-if="isShowAddGift"
      :reapplyGiftList="reapplyGiftList"
      @closePopup="closeAddGiftPopup"
    ></AddReapplyGiftPopup>
  </div>
  <!-- /반품상세 팝업 -->
</template>

<script>
import CommonDatePicker from '@views.admin/common/CommonDatePicker';
import AdminMemberInfo from '@views.admin/member/info/AdminMemberInfo.vue';
import GoodsDetail from '@views.admin/goods/manage/GoodsDetail.vue';
import AddReapplyGiftPopup from '@/views/admin/order/popup/AddReapplyGiftPopup.vue';
import ClaimStatusHistoryPopup from '@views.admin/order/popup/ClaimStatusHistoryPopup.vue';

export default {
  name: 'admin.order.claim.return.detail',
  props: ['activeClmParam', 'activeOrderInfo'],
  components: {
    CommonDatePicker,
    AdminMemberInfo,
    GoodsDetail,
    AddReapplyGiftPopup,
  },
  data() {
    return {
      isPartner: false,
      orderInfo: {}, // 주문정보
      claimInfo: {}, // 클레임정보
      claimGoodsList: [], // 클레임상품목록
      list: [], // 조회 데이터
      clmInfo: {
        bforder: {}, // 이전클레임 정보
        bfpromotion: [], // 이전프로모션
        bfgoodscoupon: [], // 이전상품쿠폰
        bfdelivcoupon: [], // 이전배송비쿠폰
        bfbasketcoupon: [], // 이전장바구니쿠폰
        calinfo: {}, // 재계산정보
        aforder: {}, // 재계산정보
        afpromotion: [], // 재계산프로모션
        afgoodscoupon: [], // 재계산상품쿠폰
        afdelivcoupon: [], // 재계산배송비쿠폰
        afbasketcoupon: [], // 재계산장바구니쿠폰
        dispitems: [], // 클레임신청(노출)
        items: [], // 클레임상품
        afitems: [], // 주문후상품
        giftlist: [], // 사은품목록
      },
      recInfo: {
        // 회수지, 회수택배사 정보
        recname: null, // 회수자명
        rectel: null, // 회수자연락처
        recpost: null, // 회수자우편번호
        recaddr: null, // 회수자지번주소
        recaddrdetail: null, // 회주사지번상세주소
        recaddrroad: null, // 회수자도로명주소
        recaddrdetailroad: null, // 회수자도로명상세주소
        recsigungucode: null, // 회수자시군구코드
        recbuildingcode: null, // 회수자빌딩코드
        recroadnamecode: null, // 회수자도로명코드
        recinvoiceno: null, // 회수운송장코드
        reclogistype: null, // 회수택배사코드
        reccomdate: null, // 회수완료일자
        reccomtime: null, // 회수완료시간
        recdelivname: null, // 회수처리자명
        recstatname: null, // 회수상태명
      },
      reapplyGiftList: [], // 재적용사은품목록
      claimGiftList: [], // 클레임사은품목록
      chkReapplyGiftList: [], // 선택한 재적용사은품 목록
      rtnlogislist: [], // 반품택배사목록
      commonCode: {
        logistype: [], // 택배사코드
        rtntype: [], // 반품사유
      },
      fileList: [], // 첨부파일 목록
      videoList: [], // 동영상 목록
      activeUserNo: '',
      activeGoodsNo: false,
      isShowMemDetail: false, // 회원상세 팝업 노출여부
      isGoodsDetailShow: false, // 상품상세 팝업 노출여부
      isShowAddGift: false, // 재적용사은품 팝업 노출여부
      isRead: false,
      isWrite: false,
      isEditRecInfo: false, // 회수지수정가능여부
      isEditReason: false, // 사유수정가능여부
      isEditGift: false, // 사은품수정가능여부
      isShowAdjustamt: false, // 조정사유 노출여부
    };
  },
  mounted() {
    this.isPartner = this.$util.isAuthorized(this.$store.getters['CONSTANTS'].PARTNER_USER);
    this.orderInfo = this.activeOrderInfo;
    let params = { url: this.$options.name, isloading: false };
    this.$http
      .post('/admin/common/pageAuth/check', params)
      .then((result) => {
        this.isRead = result.data.isread === 'T';
        this.isWrite = result.data.iswrite === 'T';

        if (this.isRead) {
          // 공통코드 조회
          this.getCommonCodeList();
        } else {
          alert('페이지 접근 권한이 없습니다.');
          this.onClose();
        }
      })
      .catch((error) => {
        this.$util.debug(error);
      });
  },
  methods: {
    // 공통코드 목록 조회
    getCommonCodeList: function () {
      let cmclassArr = ['RTNTYPE', 'LOGISTYPE'];
      this.$http
        .post('/common/code/map/list', { cmclass: cmclassArr, isloading: false })
        .then((result) => {
          let data = result.data;
          for (const [key] of Object.entries(data)) {
            this.commonCode[key] = data[key];
          }
          // 반품정보 조회
          this.searchOrdReturnInfo();
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    // 반품정보 조회
    searchOrdReturnInfo: function () {
      let params = {
        orderidx: this.activeClmParam.orderidx,
        clmidx: this.activeClmParam.clmidx,
        clmgdidx: this.activeClmParam.clmgdidx,
        clmtype: this.$store.getters['ADMIN'].CLM_RETURN,
        isPartner: this.isPartner,
      };
      this.$http
        .post('/admin/order/claim/detail/info', params)
        .then((result) => {
          this.$util.debug(result);
          let data = result.data;
          this.list = result.data;
          this.claimInfo = data.claiminfo;
          this.claimInfo.orgrtnstatus = this.claimInfo.rtnstatus;
          this.claimGoodsList = data.claimgoodslist;
          for (const [key] of Object.entries(data)) {
            this.clmInfo[key] = data[key];
          }
          this.claimInfo.orgfinadjustamt = this.claimInfo.finadjustamt;
          this.rtnlogislist = data.rtnlogislist;

          // 재계산 자사/파트너사 배송비, 적립금/임직원적립금/E포인트
          this.clmInfo.aforder.dadadelivamt =
            Number(this.clmInfo.aforder.dadadelivamt) -
            Number(this.clmInfo.calinfo.rtndadadelivamt);
          this.clmInfo.aforder.ptndelivamt =
            Number(this.clmInfo.aforder.ptndelivamt) - Number(this.clmInfo.calinfo.rtnptndelivamt);
          this.clmInfo.aforder.empreservetotamt =
            Number(this.clmInfo.bforder.empreservetotamt) -
            Number(this.clmInfo.calinfo.rtnempresamt);
          this.clmInfo.aforder.reservetotamt =
            Number(this.clmInfo.bforder.reservetotamt) - Number(this.clmInfo.calinfo.rtnresamt);
          this.clmInfo.aforder.epointtotamt =
            Number(this.clmInfo.bforder.epointtotamt) - Number(this.clmInfo.calinfo.rtnepoamt);
          this.clmInfo.aforder.rpaytotprice =
            Number(this.clmInfo.aforder.rpaytotprice) -
            Number(this.clmInfo.calinfo.rtndadadelivamt) -
            Number(this.clmInfo.calinfo.rtnptndelivamt) +
            Number(this.clmInfo.calinfo.rtnempresamt) +
            Number(this.clmInfo.calinfo.rtnresamt) +
            Number(this.clmInfo.calinfo.rtnepoamt);

          // 클레임정보 세팅(결제대기경과일, 직매입여부, 주문수령인연락처, 주문일자)
          this.claimInfo.elapsedaycnt = this.clmInfo.items[0].elapsedaycnt;
          this.claimInfo.ispbgoods = this.clmInfo.items[0].ispbgoods;
          this.claimInfo.rtndelivtype = this.clmInfo.items[0].rtndelivtype;
          this.claimInfo.rcvtel = this.clmInfo.bforder.rcvtel1;

          // 추가배송비 자사/파트너사 세팅
          let adddelivamt = this.claimInfo.adddelivamt;
          this.claimInfo.adddadadelivamt = this.claimInfo.ispbgoods === 'T' ? adddelivamt : 0;
          this.claimInfo.addptndelivamt = this.claimInfo.ispbgoods === 'F' ? adddelivamt : 0;

          //회수지 디폴트 설정
          let addr = {};
          if (this.$util.isNull(this.claimInfo.recpost)) {
            this.recInfo.recname = this.clmInfo.bforder.rcvname;
            this.recInfo.rectel = this.clmInfo.bforder.rcvtel1;
            addr = {
              post: this.clmInfo.bforder.rcvpost,
              addrroad: this.clmInfo.bforder.rcvaddrroad,
              addrdetailroad: this.clmInfo.bforder.rcvaddrdetailroad,
              addr: this.clmInfo.bforder.rcvaddr,
              addrdetail: this.clmInfo.bforder.rcvaddrdetail,
              sigungucode: this.clmInfo.bforder.rcvsigungucode,
              buildingcode: this.clmInfo.bforder.rcvbuildingcode,
              roadnamecode: this.clmInfo.bforder.rcvroadnamecode,
            };
            this.setAddr('rec', addr);
          } else {
            for (const [key] of Object.entries(this.recInfo)) {
              this.recInfo[key] = this.claimInfo[key];
            }
          }
          //회수택배사 디폴트 설정
          if (this.$util.isNull(this.recInfo.reclogistype)) {
            this.recInfo.reclogistype = this.clmInfo.items[0].rtnlogistype;
          }

          // 회수지 수정가능여부 세팅
          let isEditRecStatusArr = [
            this.$store.getters['ADMIN'].RETURN_WAITING_APPLY,
            this.$store.getters['ADMIN'].RETURN_WAITING_DEPOSIT,
            this.$store.getters['ADMIN'].RETURN_COMPLEATE_PAYMENT,
            this.$store.getters['ADMIN'].RETURN_APPROVAL_COMPLETE,
            this.$store.getters['ADMIN'].RETURN_REQUEST_REFUSE,
          ];
          if (isEditRecStatusArr.indexOf(this.claimInfo.orgrtnstatus) > -1) {
            this.isEditRecInfo = true;
          }
          // 조정금액 노출여부 세팅
          let isShowAdjustStatusArr = [
            this.$store.getters['ADMIN'].RETURN_COMPLETE_DELIVERY,
            this.$store.getters['ADMIN'].RETURN_A_RETURN,
            this.$store.getters['ADMIN'].RETURN_COMPLETE,
          ];
          if (isShowAdjustStatusArr.indexOf(this.claimInfo.orgrtnstatus) > -1) {
            this.isShowAdjustamt = true;
          }

          // 사은품목록 세팅
          this.claimGiftList = data.claimgiftlist.filter((item) => {
            return item.isadd === 'F';
          });
          this.chkReapplyGiftList = data.claimgiftlist.filter((item) => {
            return item.isadd === 'T';
          });
          this.reapplyGiftList = data.giftlist.filter((item) => {
            return item.isadd === 'T';
          });
          // 사은품 수정가능여부 세팅
          let isEditGiftStatusArr = [
            this.$store.getters['ADMIN'].RETURN_WAITING_APPLY,
            this.$store.getters['ADMIN'].RETURN_COMPLEATE_PAYMENT,
          ];
          if (
            isEditGiftStatusArr.indexOf(this.claimInfo.orgrtnstatus) > -1 &&
            !this.$util.isNull(this.reapplyGiftList) &&
            this.reapplyGiftList.length > 0
          ) {
            this.isEditGift = true;
          }

          // 첨부파일 세팅
          let uploadedFiles = data.uploadedfiles;
          for (let i = 0; i < uploadedFiles.length; i++) {
            if (uploadedFiles[i].filetype === this.$store.getters['ADMIN'].FILE_TYPE_IMG) {
              this.fileList.push(uploadedFiles[i]);
            } else if (uploadedFiles[i].filetype === this.$store.getters['ADMIN'].FILE_TYPE_VIDEO) {
              this.videoList.push(uploadedFiles[i]);
            }
          }
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    // 반품클레임 상태 변경
    changeClaimStatus: function (reqStatus) {
      this.claimInfo.rtnstatus = reqStatus;
      let isEditRsStatusArr = [
        this.$store.getters['ADMIN'].RETURN_REQUEST_REFUSE,
        this.$store.getters['ADMIN'].RETURN_CANCEL,
        this.$store.getters['ADMIN'].RETURN_APPROVAL_REFUSE,
        this.$store.getters['ADMIN'].RETURN_A_RETURN,
        this.$store.getters['ADMIN'].RETURN_APPROVAL_COMPLETE,
      ];
      if (isEditRsStatusArr.indexOf(reqStatus) > -1) {
        this.isEditReason = true;
      }
    },
    // 반품클레임 저장
    saveClaim: function (reqStatus, type) {
      this.claimInfo.rtnstatus = reqStatus;
      if (this.claimInfo.orgfinadjustamt !== this.claimInfo.finadjustamt) {
        alert('조정금액 저장 후 진행해주세요.');
        return;
      }
      if (
        this.claimInfo.orgrtnstatus !== this.$store.getters['ADMIN'].RETURN_REQUEST_REFUSE &&
        reqStatus === this.$store.getters['ADMIN'].RETURN_APPROVAL_REFUSE &&
        (this.$util.isNull(this.claimInfo.rejreason) || this.claimInfo.rejreason.trim() === '')
      ) {
        alert('반려사유를 입력해주세요.');
        return;
      }
      if (
        reqStatus === this.$store.getters['ADMIN'].RETURN_CANCEL &&
        (this.$util.isNull(this.claimInfo.dropreason) || this.claimInfo.dropreason.trim() === '')
      ) {
        alert('철회사유를 입력해주세요.');
        return;
      }
      if (
        reqStatus === this.$store.getters['ADMIN'].RETURN_REQUEST_REFUSE &&
        (this.$util.isNull(this.claimInfo.rejsubreason) ||
          this.claimInfo.rejsubreason.trim() === '')
      ) {
        alert('반려승인요청사유를 입력해주세요.');
        return;
      }
      if (
        this.claimInfo.orgrtnstatus === this.$store.getters['ADMIN'].RETURN_REQUEST_REFUSE &&
        reqStatus === this.$store.getters['ADMIN'].RETURN_APPROVAL_REFUSE &&
        (this.$util.isNull(this.claimInfo.rejreason) || this.claimInfo.rejreason.trim() === '')
      ) {
        alert('반려승인사유를 입력해주세요.');
        return;
      }
      if (
        this.claimInfo.orgrtnstatus === this.$store.getters['ADMIN'].RETURN_REQUEST_REFUSE &&
        reqStatus === this.$store.getters['ADMIN'].RETURN_APPROVAL_COMPLETE &&
        (this.$util.isNull(this.claimInfo.rejdenyreason) ||
          this.claimInfo.rejdenyreason.trim() === '')
      ) {
        alert('반려승인거부사유를 입력해주세요.');
        return;
      }
      if (reqStatus === this.$store.getters['ADMIN'].RETURN_A_RETURN) {
        if (this.$util.isNull(this.claimInfo.rtnreason) || this.claimInfo.rtnreason.trim() === '') {
          alert('반송처리사유를 입력해주세요.');
          return;
        }
        if (this.$util.isNull(this.claimInfo.rtnlogistype)) {
          alert('반송택배사를 선택해주세요.');
          return;
        }
        if (
          this.$util.isNull(this.claimInfo.rtninvoiceno) ||
          this.claimInfo.rtninvoiceno.trim() === ''
        ) {
          alert('반송 운송장번호를 입력해주세요.');
          return;
        }
      }

      let msgType = '처리';
      if (type === 'save') {
        msgType = '저장';
      }
      let params = {
        orderidx: this.claimInfo.orderidx,
        clmtype: this.claimInfo.clmtype,
        clmidx: this.claimInfo.clmidx,
        rtnstatus: reqStatus,
        rejreason:
          reqStatus === this.$store.getters['ADMIN'].RETURN_APPROVAL_REFUSE
            ? this.claimInfo.rejreason
            : null,
        dropreason:
          reqStatus === this.$store.getters['ADMIN'].RETURN_CANCEL
            ? this.claimInfo.dropreason
            : null,
        rejsubreason:
          this.claimInfo.orgrtnstatus === this.$store.getters['ADMIN'].RETURN_REQUEST_REFUSE ||
          reqStatus === this.$store.getters['ADMIN'].RETURN_REQUEST_REFUSE
            ? this.claimInfo.rejsubreason
            : null,
        rejdenyreason:
          this.claimInfo.orgrtnstatus === this.$store.getters['ADMIN'].RETURN_REQUEST_REFUSE &&
          reqStatus === this.$store.getters['ADMIN'].RETURN_APPROVAL_COMPLETE
            ? this.claimInfo.rejdenyreason
            : null,
        rtnreason:
          reqStatus === this.$store.getters['ADMIN'].RETURN_A_RETURN
            ? this.claimInfo.rtnreason
            : null,
        rtnlogistype:
          reqStatus === this.$store.getters['ADMIN'].RETURN_A_RETURN
            ? this.claimInfo.rtnlogistype
            : null,
        rtninvoiceno:
          reqStatus === this.$store.getters['ADMIN'].RETURN_A_RETURN
            ? this.claimInfo.rtninvoiceno
            : null,
        adddelivamt: this.claimInfo.adddelivamt,
        finadjustamt: this.claimInfo.finadjustamt,
      };
      if (this.isEditGift) {
        // this.$set(params, 'giftlist', Object.assign([], this.claimGiftList, this.chkReapplyGiftList));
      }
      params = Object.assign({}, params, this.recInfo);
      if (confirm(msgType + '하시겠습니까?')) {
        this.$http
          .post('/admin/order/claim/save/status', params)
          .then((result) => {
            this.$util.debug(result);
            if (result.statusCode === 200) {
              alert(this.$util.josaStr(msgType, '이') + ' 완료되었습니다.');
              this.onClose(true);
            }
          })
          .catch((error) => {
            this.$util.debug(error);
          });
      }
    },
    // 회수진행
    procRtnDeliv: function (type) {
      this.recInfo.type = type;
      let msgType = '회수요청';
      let params = { centercode: '' };
      if (this.$util.isNull(this.recInfo.recname) || this.recInfo.recname.trim() === '') {
        alert('회수자명을 입력해주세요.');
        return;
      }
      if (this.$util.isNull(this.recInfo.rectel) || this.recInfo.rectel.trim() === '') {
        alert('회수자 연락처를 입력해주세요.');
        return;
      }
      if (this.$util.isNull(this.recInfo.recpost) || this.recInfo.recpost.trim() === '') {
        alert('회수지 주소를 입력해주세요.');
        return;
      }

      if (this.claimInfo.rtndelivtype === this.$store.getters['ADMIN'].DELIV_PARCEL) {
        if (this.$util.isNull(this.recInfo.reclogistype)) {
          alert('해당 상품의 반품택배사가 존재하지 않습니다. 확인후 진행해주세요.');
          return;
        }
        this.rtnlogislist.forEach((item) => {
          if (item.rtnlogistype === this.recInfo.reclogistype && item.isvalid === 'T') {
            params.centercode = item.centercode;
          }
        });
        if (this.$util.isNull(params.centercode)) {
          alert('계약한 반품택배사의 센터코드가 존재하지 않습니다.');
          return;
        }
      } else {
        // 직배송시 상태변경
        msgType = '회수진행';
        this.claimInfo.rtnstatus = this.$store.getters['ADMIN'].RETURN_IN_DELIVERY;
      }

      params = Object.assign({}, params, this.claimInfo, this.recInfo, {
        claimgoods: this.clmInfo.items[0],
      });
      if (confirm(msgType + ' 하시겠습니까?')) {
        //alert(JSON.stringify(params));
        this.$http
          .post('/admin/order/claim/proc/return', params)
          .then((result) => {
            this.$util.debug(result);
            if (result.statusCode === 200) {
              alert(msgType + '이 완료되었습니다.');
              this.onClose(true);
            }
          })
          .catch((error) => {
            this.$util.debug(error);
          });
      }
    },
    // 배송추적
    goDelivTracking: function (type) {
      let params = {
        invoiceno: '',
        logistype: '',
      };
      if (type === 'rec') {
        params.invoiceno = this.recInfo.recinvoiceno;
        params.logistype = this.recInfo.reclogistype;
      }
      this.$http
        .post('/common/deliv/tracking', params)
        .then((result) => {
          this.$util.debug(result);
          window.open(result.data.url, '_blank', 'width=400,height=650');
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    // 조정금액 저장
    saveFinAdjustamt: function () {
      // 필수값 체크
      let msg = '';
      let valid = [
        { field: 'claimInfo.finadjustamt', type: 'I', name: '조정금액', required: true },
        { field: 'claimInfo.adjustreason', type: 'I', name: '조정사유', required: true },
      ];
      msg = this.$util.validMsg(this.$data, valid);
      if (!this.$util.isNull(msg)) {
        alert(msg);
        return false;
      }

      // 조정금액 max 환불금액 체크
      let finAdjustAmt = Number(this.claimInfo.finadjustamt);
      let rtnAmt = Number(this.clmInfo.calinfo.rtnamt);
      if (finAdjustAmt > rtnAmt) {
        alert('조정금액은 환불예정금액 이내로만 조정이 가능합니다. 확인후 진행해주세요.');
        return false;
      }

      // 조정금액 저장
      if (confirm('저장 하시겠습니까?')) {
        this.$http
          .post('/admin/order/claim/save/adjust', this.claimInfo)
          .then((result) => {
            this.$util.debug(result);
            if (result.statusCode === 200) {
              alert('저장이 완료되었습니다.');
              this.searchOrdReturnInfo();
            }
          })
          .catch((error) => {
            this.$util.debug(error);
          });
      }
    },
    // 날짜 picker 콜백 함수
    onChangeRecDate: function (value) {
      this.recInfo.reccomdate = value;
    },
    // 파일보기
    viewFile: function (url) {
      this.$viewerApi({
        images: [url],
      });
    },
    // 주소 세팅(회수지, 교환배송지)
    setAddr(type, addr) {
      if (type === 'rec') {
        //회수지
        this.recInfo.recpost = addr.post;
        this.recInfo.recaddr = addr.addr;
        this.recInfo.recaddrdetail = addr.addrdetail;
        this.recInfo.recaddrroad = addr.addrroad;
        this.recInfo.recaddrdetailroad = addr.addrdetailroad;
        this.recInfo.recsigungucode = addr.sigungucode;
        this.recInfo.recbuildingcode = addr.buildingcode;
        this.recInfo.recroadnamecode = addr.roadnamecode;
      }
    },
    // 주소검색
    searchAddress: function (type) {
      new window.daum.Postcode({
        oncomplete: (data) => {
          let addr = {
            post: data.zonecode,
            addr: this.$util.isNull(data.jibunAddress) ? data.autoJibunAddress : data.jibunAddress,
            addrdetail: '',
            addrroad: this.$util.isNull(data.roadAddress) ? data.autoRoadAddress : data.roadAddress,
            addrdetailroad: '',
            sigungucode: data.sigunguCode,
            buildingcode: data.buildingCode,
            roadnamecode: data.roadnameCode,
          };
          this.setAddr(type, addr);
        },
      }).open();
    },
    // 재적용사은품 선택
    selectReapplyGiftList: function () {
      let param = { reapplyGiftList: this.reapplyGiftList };
      this.$eventBus.$emit('modalShow', AddReapplyGiftPopup, param, (result) => {
        this.chkReapplyGiftList = result.list;
      });
    },
    // 재적용사은품 선택
    closeAddGiftPopup: function () {
      this.isShowAddGift = false;
    },
    // 클래임상태변경이력 팝업 오픈
    goClaimHistory: function (value) {
      let param = { clmgdidx: value };
      this.$eventBus.$emit('modalShow', ClaimStatusHistoryPopup, param, null);
    },
    // Front 화면으로 이동
    goFrontGoodsDetail: function (value) {
      window.open(process.env.VUE_APP_PC_GOODS_DETAIL_URL + value, '_blank');
    },
    // 회원상세 팝업 오픈
    goMemDetail: function (value) {
      this.isShowMemDetail = true;
      this.activeUserNo = value;
    },
    // 회원상세 팝업 닫기
    closeMemDetail: function () {
      this.isShowMemDetail = false;
    },
    // 상품상세 팝업 오픈
    goGoodsDetail: function (value) {
      this.activeGoodsNo = value;
      this.isGoodsDetailShow = true;
    },
    // 상품상세 팝업 닫기
    closeGoodsDetail: function () {
      this.isGoodsDetailShow = false;
    },
    // 팝업닫기
    onClose(isreload) {
      if (typeof isreload === 'boolean' && isreload) {
        this.$emit('setOrderInfo');
        this.$emit('closeDetail', true);
      } else {
        this.$emit('closeDetail');
      }
    },
    saveRtninvoiceno: function (obj) {
      let params = {
        delivtype: 'DLT004',
        recinvoiceno: this.recInfo.recinvoiceno,
        reclogistype: this.recInfo.reclogistype,
        recname: this.recInfo.recname,
      };
      params = Object.assign({}, params, this.claimInfo, this.recInfo, {
        claimgoods: this.clmInfo.items[0],
      });
      if (confirm('송장등록 하시겠습니까?')) {
        // console.log(params);
        this.$http
          .post('/admin/order/claim/proc/return/man', params)
          .then((result) => {
            this.$util.debug(result);
            if (result.statusCode == 200) {
              alert('처리가 완료되었습니다.');
              this.searchOrdReturnInfo();
            }
          })
          .catch((error) => {
            this.$util.debug(error);
          });
      }
    },
  },
};
</script>
