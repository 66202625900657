<template>
  <div id="app">
    <router-view />
    <loading
      :active.sync="isLoading"
      :background-color="backgroundColor"
      :can-cancel="false"
      :color="color"
      :height="80"
      :is-full-page="true"
      :loader="loader"
      :width="80"
      :z-index="99999"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'App',
  components: {
    Loading,
  },
  data() {
    return {
      loader: 'bars',
      color: '#00b2fd',
      backgroundColor: '#eeeeee',
      isLoading: false,
    };
  },
  mounted() {
    //this.$util.debug("Environment : " + JSON.stringify(process.env));
    //통신 로딩이미지
    this.$eventBus.$on('loading', (flag) => {
      this.isLoading = flag;
    });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
};
</script>
