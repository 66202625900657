var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "400px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("반려사유")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide("commonModal")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.contents,
                  expression: "contents",
                },
              ],
              attrs: {
                placeholder: "반려사유를 입력하세요.",
                maxlength: "200",
              },
              domProps: { value: _vm.contents },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.contents = $event.target.value
                },
              },
            }),
            _c("div", { staticClass: "btn-group mt10" }, [
              _c(
                "button",
                {
                  staticClass: "btn blue",
                  attrs: { type: "button" },
                  on: { click: _vm.sendRejectRs },
                },
                [_vm._v("저장")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn darkgray",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("commonModal")
                    },
                  },
                },
                [_vm._v(" 취소 ")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }