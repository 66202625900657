var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-area" }, [
    _c(
      "table",
      { staticClass: "gray-tb", attrs: { cellpadding: "0", cellspacing: "0" } },
      [
        _vm._m(0),
        _c("tbody", [
          _c("tr", [
            _vm._m(1),
            _c("td", { attrs: { colspan: "3" } }, [
              _c(
                "div",
                [
                  _c("CommonTextInput", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.value.title,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.value,
                          "title",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "value.title",
                    },
                  }),
                ],
                1
              ),
              _vm.checkValidField("title")
                ? _c("p", { staticClass: "alert-text txt-red" }, [
                    _c("i", { staticClass: "icon-alert2" }),
                    _vm._v(" 공지 제목은 필수 입력입니다. "),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("tr", [
            _vm._m(2),
            _c("td", { attrs: { colspan: "3" } }, [
              _c(
                "div",
                { staticClass: "radio_wrap" },
                _vm._l(
                  _vm.getGoodsNoticeDisplayStatusOptions(),
                  function (noticeDisplayStatus) {
                    return _c("div", { key: noticeDisplayStatus.value }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.value.displayStatus,
                            expression: "value.displayStatus",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id:
                            "notice_display_status_" +
                            noticeDisplayStatus.value,
                        },
                        domProps: {
                          value: noticeDisplayStatus.value,
                          checked: _vm._q(
                            _vm.value.displayStatus,
                            noticeDisplayStatus.value
                          ),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(
                              _vm.value,
                              "displayStatus",
                              noticeDisplayStatus.value
                            )
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          attrs: {
                            for:
                              "notice_display_status_" +
                              noticeDisplayStatus.value,
                          },
                        },
                        [_vm._v(" " + _vm._s(noticeDisplayStatus.label) + " ")]
                      ),
                    ])
                  }
                ),
                0
              ),
            ]),
          ]),
          _c("tr", [
            _vm._m(3),
            _c("td", { attrs: { colspan: "3" } }, [
              _c(
                "div",
                [
                  _c("CommonDatePicker", {
                    attrs: {
                      disable: !_vm.hasNoticeDisplayStatusVisible(
                        _vm.value.displayStatus
                      ),
                      value: _vm.value.displayStartDate,
                      disabledDateCallback: _vm.onStartDatedisabledDateCallback,
                    },
                    on: { change: _vm.onChangeStartDate },
                  }),
                  _c("span", [_vm._v("-")]),
                  _c("CommonDatePicker", {
                    attrs: {
                      disable: !_vm.hasNoticeDisplayStatusVisible(
                        _vm.value.displayStatus
                      ),
                      value: _vm.value.displayEndDate,
                      disabledDateCallback: _vm.onEndDatedisabledDateCallback,
                    },
                    on: { change: _vm.onChangeEndDate },
                  }),
                ],
                1
              ),
              _vm.checkValidField("displayStartDate") ||
              _vm.checkValidField("displayEndDate")
                ? _c("p", { staticClass: "alert-text txt-red" }, [
                    _c("i", { staticClass: "icon-alert2" }),
                    _vm._v(
                      " 전시 기간은 필수 입력입니다. 범위를 지정해주세요. "
                    ),
                  ])
                : _vm._e(),
              _c("p", { staticClass: "txt-gray" }, [
                _vm._v(
                  "전시기간이 겹치는 경우, 최신으로 등록한 공지사항이 게시됩니다."
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _vm._m(4),
            _c("td", { attrs: { colspan: "3" } }, [
              _c(
                "div",
                { staticClass: "radio_wrap" },
                _vm._l(_vm.getGoodsNoticeTypeOptions(), function (noticeType) {
                  return _c("div", { key: noticeType.value }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value.noticeType,
                          expression: "value.noticeType",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "notice_type_" + noticeType.value,
                      },
                      domProps: {
                        value: noticeType.value,
                        checked: _vm._q(_vm.value.noticeType, noticeType.value),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.value,
                            "noticeType",
                            noticeType.value
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      { attrs: { for: "notice_type_" + noticeType.value } },
                      [_vm._v(_vm._s(noticeType.label))]
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
          _vm.isTextNoticeType
            ? _c("tr", [
                _vm._m(5),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("div", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.value.description,
                          expression: "value.description",
                          modifiers: { trim: true },
                        },
                      ],
                      staticStyle: { width: "100%", "min-height": "200px" },
                      attrs: { type: "text", maxlength: "150" },
                      domProps: { value: _vm.value.description },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.value,
                            "description",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _vm.checkValidField("description")
                      ? _c("p", { staticClass: "alert-text txt-red" }, [
                          _c("i", { staticClass: "icon-alert2" }),
                          _vm._v(" 내용은 필수 입력입니다. "),
                        ])
                      : _c("p", { staticClass: "txt-gray" }, [
                          _vm._v("- 최대 150자 까지 등록 가능합니다."),
                        ]),
                  ]),
                ]),
              ])
            : _c("tr", [
                _vm._m(6),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("div", { staticClass: "file-upload-wrap" }, [
                    !_vm.isImageUpload
                      ? _c(
                          "button",
                          {
                            staticClass: "btn blue-line",
                            attrs: { type: "button" },
                            on: { click: _vm.onAttachImageFile },
                          },
                          [
                            _c("i", { staticClass: "icon-file-photo" }),
                            _vm._v(" 이미지 추가 "),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "file-name-wrap" }, [
                      _c("span", [_vm._v(_vm._s(_vm.uploadImageName))]),
                      _vm.isImageUpload
                        ? _c(
                            "button",
                            {
                              staticClass: "btn red-line",
                              attrs: { type: "button" },
                              on: { click: _vm.onClickDeleteImage },
                            },
                            [_vm._v(" 삭제 ")]
                          )
                        : _vm._e(),
                    ]),
                    _c("input", {
                      ref: "imageUpload",
                      attrs: { type: "file", accept: "image/*", hidden: "" },
                      on: { input: _vm.onInputFile },
                    }),
                  ]),
                  _vm.checkValidField("imageUrl")
                    ? _c("p", { staticClass: "alert-text txt-red" }, [
                        _c("i", { staticClass: "icon-alert2" }),
                        _vm._v(" 이미지는 필수 입력입니다. "),
                      ])
                    : _vm._e(),
                  _vm._m(7),
                ]),
              ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 공지제목 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 전시설정 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 전시기간 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 공지사항 유형 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 내용 입력 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v(" 이미지 업로드 "),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "txt-gray" }, [
        _vm._v(
          " - 이미지 업로드 시, 가로 1000px / 1MB 이내 이미지만 등록 가능합니다. (PNG, JPG, JPEG만 가능) "
        ),
      ]),
      _c("p", { staticClass: "txt-gray" }, [
        _vm._v(" - 권장 공지사항 이미지 가이드 "),
        _c(
          "a",
          {
            staticClass: "download_guide",
            attrs: {
              href: "https://img.dplot.co.kr/partners/images/D.PLOT_상품상세공지가이드.psd",
              target: "_blank",
            },
          },
          [_vm._v(" [다운로드] ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }