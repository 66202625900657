<template>
  <div class="login-wrap" @keyup.enter="login">
    <img src="../../assets/img/logo-login-partner.png" alt="" />
    <div class="input-id">
      <input type="text" v-model="id" placeholder="아이디" />
    </div>
    <div class="input-pw">
      <input type="password" v-model="pw" placeholder="비밀번호" />
    </div>
    <div class="clearfix">
      <div class="fl">
        <input type="checkbox" id="rememberID" v-model="idRememberCheck" />
        <label for="rememberID">아이디 기억하기</label>
      </div>
      <div class="fr">
        <a class="link" @click="openFindIdPassword">아이디/비밀번호찾기</a>
      </div>
    </div>
    <button type="button" class="btn blue btn-login" v-on:click="login">로그인</button>
    <div :class="[{ 'login-info-p': true, 'hide-bottom-line': isProd }]">
      <ul>
        <li>본시스템은 올리브영 협력사에 한하여 사용하실 수 있습니다.</li>
        <li>불법적인 접근 및 사용 시 관련 법규에 의해 처벌될 수 있습니다.</li>
      </ul>
      <br />
      <div v-if="!isProd">
        <div>
          <strong>성공적인 비즈니스 파트너!</strong>
          <br />
          <strong class="large-txt txt-blue">D.PLOT</strong>
          과 함께 하세요!
        </div>
        <p>
          <button class="btn blue-line btn-login" type="button" @click="newJoinPartner">
            파트너 입점 등록
          </button>
        </p>
      </div>
    </div>
    <div class="login-footer">
      <a class="terms" href="https://www.dplot.co.kr/company/privacy" target="_blank">
        <p>개인정보 처리방침</p>
      </a>
      <p class="copyrights">Copyright ⓒ D.PLOT All rights reserved.</p>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import PartnersFindIdPassword from '@views/partners/PartnersFindIdPassword.vue';
import AdminResetPasswordPopup from '@views.admin/common/popup/AdminResetPasswordPopup';
import SecondAuthBySms from '@views.admin/common/popup/SecondAuthBySmsPopup';
import storage from '@js/storage';

export default {
  name: 'PartnersLogin',
  data() {
    return {
      id: '',
      pw: '',
      idRememberCheck: false,
      authKey: '',
      isProd: process.env.VUE_APP_ENV === 'prod',
    };
  },
  beforeMount() {
    // 파트너 입점 신청 과정에서 생성된 세션 정보들은 제거한다.
    const { BIZ_AUTH_INFO, FINISHED_JOIN_PARTNER } = this.$store.getters.CONSTANTS.STORAGE_KEYS;
    storage.removeSessionStorage(BIZ_AUTH_INFO);
    storage.removeSessionStorage(FINISHED_JOIN_PARTNER);
  },
  mounted() {
    let rememberId = this.$cookies.get('partners_remember_id');

    if (rememberId !== null) {
      this.id = rememberId;
    }

    let errorCode = this.$route.query.error;
    if ('401' === errorCode) {
      this.errorMsg('로그인 세션이 중복/만료 되었습니다. \n다시 로그인 바랍니다.');
      this.$router.push('/partners/login');
    }

    /**
     * SMS 2차 인증 성공 시 발생하는 이벤트를 캐치한다. (2023-06-02, James)
     * 파라미터 넘길때 주의 필요함. function으로 정의해서 실행하면 객체가 호출주체의 객체가 되어 this가 emit하는 vue를 바라보게 됨!!!!!!!!
     */
    this.$eventBus.$on('secondAuthSuccess', (params) => {
      if (params == true) {
        //console.log("==== secondAuthSuccess called ====");
        // 인증 완료후 로그인 처리 함수를 호출한다.
        this.confirmAuthMessage();
      }
    });
  },
  destroyed() {
    this.$eventBus.$off('secondAuthSuccess');
  },
  methods: {
    debounceLogin: debounce(
      async function () {
        try {
          const param = {
            id: this.id,
            pw: this.pw,
            isloading: true,
          };

          if (this.$util.isNull(this.id)) {
            alert('아이디를 입력해주세요.');
            return;
          } else if (this.$util.isNull(this.pw)) {
            alert('비밀번호를 입력해주세요.');
            return;
          }

          const res = await this.$http.post('/admin/common/login', param);
          // 만약 계정 로그인이 정상 처리되면 istemppw 및 pwdmoddate 를 체크하여 비밀번호 초기화 대상자이면 팝업을 띄우고 그렇지 않다면 쿠키 생성 로그인 페이지로 다시 redirect 시킨다. (2023-05-26, James)

          if (res.data.istemppw === 'T') {
            alert(
              '비밀번호 초기화 대상자 입니다.(비밀번호 변경이후 6개월 경과 혹은 최초 로그인시) \n초기화 후 재로그인 부탁드립니다.',
            );
            this.adminResetPasswordPopup(res.data.no);
          } else {
            /**
             * 로그인을 성공하면 파트너 페이지의 경우 2차 인증을(sms 통한 인증번호 입력) 진행해야 한다. (2023-05-30, James)
             */
            this.authKey = res.data.authkey;
            this.openSecondAuthBySms(res.data);
            //this.confirmAuthMessage();
          }
        } catch (err) {
          this.$util.debug(err);
        }
      },
      500,
      { leading: true, trailing: false },
    ),
    login() {
      this.debounceLogin();
    },
    newJoinPartner() {
      this.$router.push({ name: 'common.new.partnership.auth' });
    },
    errorMsg: function (msg) {
      alert(msg);
    },
    adminResetPasswordPopup(value) {
      let param = { no1: value };
      this.openModal(AdminResetPasswordPopup, param);
    },
    openFindIdPassword() {
      this.openModal(PartnersFindIdPassword);
    },
    closePopup() {},
    openSecondAuthBySms(param) {
      this.openModal(SecondAuthBySms, param);
    },
    confirmAuthMessage: function () {
      let param = {
        id: this.id,
        pw: this.pw,
        authKey: this.authKey,
      };
      //console.info("================================ ");
      //console.info(JSON.stringify(param));
      //console.info("================================ ");
      this.$http
        .post('/partners/login_act', param)
        .then((result) => {
          if (result.status === 200) {
            if (result.reqDealst !== 'RDS002') {
              this.$router.push({ name: 'common.partnership.wait' });
            } else {
              this.$storage.setLocalStorage(this.$store.getters.CONSTANTS.PARTNER_USER, result);
              this.$storage.setLocalStorage(this.$store.getters.CONSTANTS.MANAGER_SESSION, result);
              this.$storage.removeLocalStorage(this.$store.getters.CONSTANTS.ADMIN_USER);

              alert(
                '마지막 접속 일시 : ' +
                  result.lastlogindate +
                  '\n마지막 접속 IP : ' +
                  result.lastloginip,
              );

              if (this.idRememberCheck) {
                this.$cookies.set('partners_remember_id', this.id, '7d');
              }
              this.$router.push({ name: 'partners.main.dashboard' });
            }
          }
        })
        .catch((error) => {
          this.$util.debug(error);
        });
    },
    openModal(comp, params, callbackFn) {
      this.$modal.show(
        comp,
        {
          modalComp: comp,
          params: params,
          callbackFn: callbackFn,
        },
        {
          name: 'commonModal',
          draggable: false,
          resizable: false,
          width: '100%',
          height: 'auto',
        },
      );
    },
  },
};
</script>

<style scoped>
.login-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
}

.login-footer p {
  margin: 0;
  padding: 0;
}

.login-footer .copyrights {
  font-size: 12px;
  color: #aaa;
}

.login-footer .terms {
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
</style>
