<template>
  <div id="modal-wrap" class="modal" style="display: block">
    <div class="modal-content" style="width: 1200px">
      <div class="pop-header">
        <h2>운영자 정보 수정</h2>
        <button type="button" class="pop-close" @click="onClose"></button>
      </div>
      <div class="pop-body">
        <ManagerRegistForm v-if="modifyManagerInfo.id" :isNew="false" v-model="modifyManagerInfo" />
        <div class="btn-group">
          <button type="button" class="btn big blue" :disabled="!onValid()" @click="onSave">
            저장
          </button>
          <button type="button" class="btn big darkgray" @click="onClose">취소</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManagerRegistForm from './ManagerRegistForm.vue';
import { MANAGER_ROLE_TYPE } from '@constants/manager';

export default {
  name: 'manager.modify.popup',
  props: {
    managerInfo: {
      id: Number,
      loginId: String,
      name: String,
      mobile: String,
      email: String,
      roleList: Array,
    },
    partnerId: Number,
  },
  components: {
    ManagerRegistForm,
  },
  data() {
    return {
      modifyManagerInfo: {
        id: null,
        loginId: '',
        name: '',
        mobile: '',
        email: '',
        roles: [],
        agree: true,
        invaildField: [],
      },
    };
  },
  mounted() {
    const { roleList, ...info } = this.managerInfo;
    const roles = roleList.map(({ roleType }) => roleType);

    this.modifyManagerInfo = {
      ...info,
      roles,
      agree: true,
      invaildField: [],
    };
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    async onSave() {
      const originRoles = this.managerInfo.roleList.map(({ roleType }) => roleType);
      const modifyRoles = this.modifyManagerInfo.roles;

      const 세금담당자해제여부 =
        originRoles.includes(MANAGER_ROLE_TYPE.세금) &&
        !modifyRoles.includes(MANAGER_ROLE_TYPE.세금);

      if (세금담당자해제여부) {
        return alert(
          "파트너사별 세금계산서 담당자 지정은 필수입니다.\n담당자 변경 필요 시, 해당 계정의 '운영자 정보'를 수정해주세요.\n(해당 계정의 담당업무 '세금계산서' 선택 후 저장 시 자동 변경')",
        );
      }

      try {
        const 세금담당자추가여부 =
          !originRoles.includes(MANAGER_ROLE_TYPE.세금) &&
          modifyRoles.includes(MANAGER_ROLE_TYPE.세금);

        if (세금담당자추가여부) {
          const { data } = await this.$http.post('/v2/manager/validate/essential-role', {
            partnerId: this.partnerId,
          });

          if (
            data &&
            !confirm(
              '귀사의 세금계산서 담당자가 다른 계정에 이미 지정되어 있습니다.\n담당자를 변경하시겠습니까?\n("확인" 버튼 클릭 시 세금계산서 담당자 자동 변경)',
            )
          ) {
            return;
          }
        }

        this.updateManager(() => this.onClose());
      } catch (error) {
        this.$util.debug(error);
        alert(error.message || '운영자 정보 수정중 오류가 발생하였습니다.');
      }
    },
    onValid() {
      return this.modifyManagerInfo.invaildField.length === 0;
    },
    async updateManager(callback) {
      try {
        const { id, name, mobile, email, roles } = this.modifyManagerInfo;
        const param = {
          id,
          name,
          mobile: mobile.replaceAll('-', ''),
          email,
          roleList: roles.map((role) => ({ roleType: role })),
          isloading: true,
        };
        await this.$http.patch('/v2/manager', param);
        this.$emit('onRefresh');
        callback();
      } catch (error) {
        this.$util.debug(error);
        alert(error.message || '운영자 정보 수정중 오류가 발생하였습니다.');
      }
    },
  },
};
</script>
