var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "800px" } },
        [
          _c("div", { staticClass: "pop-header f-height" }, [
            _c("h2", [_vm._v(_vm._s(_vm.noticeList[_vm.nowIdx].subject))]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", {
              staticClass: "boxing notice-con",
              staticStyle: { "min-height": "30vh" },
              domProps: {
                innerHTML: _vm._s(_vm.noticeList[_vm.nowIdx].content),
              },
            }),
            _c("div", { staticClass: "clearfix mt10" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.closeEver,
                    expression: "closeEver",
                  },
                ],
                attrs: { type: "checkbox", id: "close" },
                domProps: {
                  checked: Array.isArray(_vm.closeEver)
                    ? _vm._i(_vm.closeEver, null) > -1
                    : _vm.closeEver,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.closeEver,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.closeEver = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.closeEver = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.closeEver = $$c
                    }
                  },
                },
              }),
              _c("label", { attrs: { for: "close" } }, [
                _vm._v("다시보지않기"),
              ]),
              _c("div", { staticClass: "btn-group fr" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn big darkgray",
                    attrs: { type: "button" },
                    on: { click: _vm.onClose },
                  },
                  [_vm._v("닫기")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }