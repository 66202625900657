export const MANAGER_TYPE = {
  대표운영자: 'MAIN',
  부운영자: 'SUB',
};

export const MANAGER_ROLE_TYPE = {
  제휴: 'CHA001',
  정산: 'CHA002',
  물류: 'CHA003',
  CS: 'CHA004',
  기타: 'CHA005',
  세금: 'CHA006',
};

export const EMAIL_DOMAIN_OPTIONS = [
  { value: 'naver.com', label: 'naver.com' },
  { value: 'gmail.com', label: 'gmail.com' },
  { value: 'hanmail.net', label: 'hanmail.net' },
  { value: 'hotmail.net', label: 'hotmail.net' },
  { value: 'nate.com', label: 'nate.com' },
  { value: 'input', label: '직접입력' },
];

export const MANAGER_ROLE_OPTIONS = [
  { value: 'CHA001', label: '제휴' },
  { value: 'CHA002', label: '정산' },
  { value: 'CHA006', label: '세금계산서' },
  { value: 'CHA004', label: 'CS' },
  { value: 'CHA003', label: '물류' },
  { value: 'CHA005', label: '기타' },
];
