var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "720px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v("송장일괄등록")]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.closePopup },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "blue-box mg0" }, [
              _c("p", { staticClass: "mg0" }, [
                _vm._v("택배사 송장번호를 일괄적으로 변경 적용할 수 있습니다."),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn big green mt20",
                  attrs: { type: "button" },
                  on: { click: _vm.downloadExcelTemplate },
                },
                [_vm._v(" 엑셀양식 다운로드 ")]
              ),
              _c("div", { staticClass: "mt10" }, [
                _c("input", {
                  ref: "excelFile",
                  attrs: {
                    type: "file",
                    hidden: "",
                    accept:
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  },
                  on: { change: _vm.handleFileUpload },
                }),
                _c("input", {
                  ref: "excelFileName",
                  attrs: { type: "text", readonly: "" },
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn blue-line ml3",
                    attrs: { type: "button" },
                    on: { click: _vm.fileAttach },
                  },
                  [_vm._v("파일찾기")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn blue-line",
                    attrs: { type: "button" },
                    on: { click: _vm.readExcelFile },
                  },
                  [_vm._v("일괄등록")]
                ),
              ]),
            ]),
            _vm._m(0),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "dot-list mt20" }, [
      _c("li", [_vm._v("파일 등록은 반드시 “xlsx”로 저장을 하셔야 합니다.")]),
      _c("li", [
        _vm._v(
          " 업로드 할 엑셀파일에는 주문번호, 상품코드, 단품코드, 상품순번, 택배사명, 운송장번호 항목은 필수로 있어야 합니다. "
        ),
      ]),
      _c("li", [
        _vm._v(
          "이미 등록된 송장번호가 있어도 새로 등록한 택배사/송장번호로 업데이트 됩니다."
        ),
      ]),
      _c("li", [
        _vm._v("택배배송의 경우 송장번호는 하이픈(-)없이 입력해 주세요."),
      ]),
      _c("li", [
        _vm._v(
          "한번에 등록 가능한 개수는 1,000개 이며 최대 2MB까지 등록 가능합니다."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }