<template>
  <div class="common_text_input_wrap">
    <input
      :type="type"
      :maxlength="maxlength"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <div class="common_text_input_counter">
      <strong>{{ getCurrentTextCount }}</strong>
      /
      <span>{{ maxlength }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonTextInput',
  props: {
    value: String,
    type: {
      type: String,
      default() {
        return 'text';
      },
    },
    maxlength: {
      type: Number,
      default() {
        return 60;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    getCurrentTextCount() {
      return this.value.length;
    },
  },
};
</script>

<style scoped>
.common_text_input_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.common_text_input_wrap input {
  flex: 1;
}

.common_text_input_counter {
  width: auto;
  min-width: 80px;
  padding: 0 5px;
  text-align: center;
}

.common_text_input_counter strong {
  font-weight: bold;
  color: #167cd8;
}
</style>
