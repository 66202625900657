var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "400px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [_vm._v(_vm._s(_vm.reqName))]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide("commonModal")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reason,
                  expression: "reason",
                },
              ],
              attrs: {
                placeholder: _vm.reqName + " 사유 입력 필수",
                maxlength: "200",
              },
              domProps: { value: _vm.reason },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.reason = $event.target.value
                },
              },
            }),
            _vm.returnStatusArr.indexOf(_vm.params.reqStatus) > -1
              ? _c(
                  "table",
                  {
                    staticClass: "gray-tb mt10",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _vm._m(0),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v("택배사")]),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.rtnlogistype,
                                  expression: "rtnlogistype",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.rtnlogistype = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c("option", { domProps: { value: null } }, [
                                _vm._v("선택"),
                              ]),
                              _vm._l(_vm.commonCode.logistype, function (row) {
                                return _c(
                                  "option",
                                  {
                                    key: row.cmcode,
                                    domProps: { value: row.cmcode },
                                  },
                                  [_vm._v(" " + _vm._s(row.codename) + " ")]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("송장번호")]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.rtninvoiceno,
                                expression: "rtninvoiceno",
                              },
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "송장번호",
                              maxlength: "50",
                              oninput:
                                "this.value = this.value.replace(/(^0|[^0-9])/gi, '');",
                            },
                            domProps: { value: _vm.rtninvoiceno },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.rtninvoiceno = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "btn-group mt10" }, [
              _c(
                "button",
                {
                  staticClass: "btn blue",
                  attrs: { type: "button" },
                  on: { click: _vm.sendData },
                },
                [_vm._v("저장")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "120px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }