var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      staticStyle: { display: "block" },
      attrs: { id: "modal-wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "800px" } },
        [
          _c("div", { staticClass: "pop-header" }, [
            _c("h2", [
              _vm._v(
                _vm._s(_vm.partnername) + "(" + _vm._s(_vm.partnerid) + ") Memo"
              ),
            ]),
            _c("button", {
              staticClass: "pop-close",
              attrs: { type: "button" },
              on: { click: _vm.onClose },
            }),
          ]),
          _c("div", { staticClass: "pop-body" }, [
            _c("div", { staticClass: "memo-write" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.savememo,
                    expression: "savememo",
                  },
                ],
                attrs: { placeholder: "메모를 작성해 주세요!" },
                domProps: { value: _vm.savememo },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.savememo = $event.target.value
                  },
                },
              }),
              _c(
                "button",
                {
                  staticClass: "btn big blue",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.goSave(-1)
                    },
                  },
                },
                [_vm._v("메모"), _c("br"), _vm._v("추가")]
              ),
            ]),
            this.memoList.length > 0
              ? _c(
                  "ul",
                  { staticClass: "memo-list" },
                  _vm._l(this.memoList, function (row, i) {
                    return _c("li", { key: i }, [
                      _c("div", { staticClass: "memo-info clearfix" }, [
                        _c("div", { staticClass: "info-group" }, [
                          _c("span", { staticClass: "num" }, [
                            _vm._v(_vm._s(row.rownum)),
                          ]),
                          _c("span", [_vm._v(_vm._s(row.regdate))]),
                          _c("span", [_vm._v(_vm._s(row.regusername))]),
                        ]),
                        _c("div", { staticClass: "btn-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn blue",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  row.readonly === "T"
                                    ? _vm.changeReadonly(i)
                                    : _vm.goSave(i)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.readonly === "T" ? "수정" : "저장"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn red-line",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeMemo(row.idx)
                                },
                              },
                            },
                            [_vm._v(" 삭제 ")]
                          ),
                        ]),
                      ]),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: row.adminmemo,
                            expression: "row.adminmemo",
                          },
                        ],
                        attrs: { disabled: row.readonly === "T" },
                        domProps: { value: row.adminmemo },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(row, "adminmemo", $event.target.value)
                          },
                        },
                      }),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "bottom-group" },
              [
                _c("CommonPageNavigator", {
                  attrs: { pagingData: this.pagingData },
                  on: { setPagingData: _vm.setPagingData },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }