var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "content m-leftmenu" },
    [
      _c("common-navigator"),
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "clearfix" }, [
          _c("div", { staticClass: "btn-group fr" }, [
            !_vm.$util.isNull(_vm.basicInfo.dealno)
              ? _c(
                  "button",
                  {
                    staticClass: "btn big blue-line",
                    attrs: { type: "button" },
                    on: { click: _vm.goPriview },
                  },
                  [_vm._v(" 미리보기 ")]
                )
              : _vm._e(),
            _vm.$util.isNull(_vm.basicInfo.istempsave) ||
            _vm.basicInfo.istempsave === "T"
              ? _c(
                  "button",
                  {
                    staticClass: "btn big blue",
                    attrs: { type: "button" },
                    on: { click: _vm.goTemporarySave },
                  },
                  [_vm._v(" 임시저장 ")]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "btn big blue",
                attrs: { type: "button" },
                on: { click: _vm.goSave },
              },
              [_vm._v("저장")]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "tab-group" },
          [
            _c(
              "scrollactive",
              {
                attrs: {
                  "active-class": "active",
                  scrollContainerSelector: ".tab-area",
                  "bezier-easing-value": ".5,0,.35,1",
                  highlightFirstItem: true,
                  offset: 160,
                  scrollOffset: 20,
                  duration: 600,
                },
              },
              [
                _c(
                  "ul",
                  _vm._l(_vm.tabObject, function (value, name, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        on: {
                          click: function ($event) {
                            return _vm.onActive(value)
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "scrollactive-item",
                            attrs: { href: value.href },
                          },
                          [_vm._v(_vm._s(value.tabName))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "tab-area" }, [
          _c("div", { staticClass: "boxing", attrs: { id: "tab1" } }, [
            _c(
              "div",
              {
                staticClass: "boxing-title",
                class: { closed: !_vm.tabObject.tab1.isOpen },
              },
              [
                _vm._v(" 기본 정보"),
                _c("i", {
                  staticClass: "arrcodi",
                  on: {
                    click: function ($event) {
                      return _vm.onToggle(_vm.tabObject.tab1)
                    },
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "form-area",
                style: {
                  display: _vm.tabObject.tab1.isOpen ? "block" : "none",
                },
              },
              [
                _c("dl", [
                  _vm._m(0),
                  _c("dd", [
                    _c("span", [_vm._v("카테고리 명 검색")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.basicInfo.categoryname,
                          expression: "basicInfo.categoryname",
                        },
                      ],
                      staticStyle: { width: "440px" },
                      attrs: {
                        type: "search",
                        placeholder: "예) 냉장고",
                        maxlength: 100,
                      },
                      domProps: { value: _vm.basicInfo.categoryname },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.onCategorynameShow.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.basicInfo,
                            "categoryname",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "btn-search",
                        attrs: { type: "button" },
                        on: { click: _vm.onCategorynameShow },
                      },
                      [_vm._v("검색")]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showInfo.iscateshow,
                            expression: "showInfo.iscateshow",
                          },
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.closeCatenameLayer,
                            expression: "closeCatenameLayer",
                          },
                        ],
                        staticClass: "result-component",
                        staticStyle: {
                          display: "block",
                          "margin-left": "100px",
                        },
                      },
                      [
                        _c("div", { staticClass: "list" }, [
                          _vm.basicInfo.categorynameList.length > 0
                            ? _c(
                                "ul",
                                _vm._l(
                                  _vm.basicInfo.categorynameList,
                                  function (item) {
                                    return _c(
                                      "li",
                                      { key: item.fullcategoryidx },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0);",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setCategoryInfo(item)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.fullcategoryname)
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("ul", [_vm._m(1)]),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "dd",
                    {
                      staticClass: "dd-right",
                      staticStyle: { width: "180px" },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn blue-line",
                          attrs: { type: "button" },
                          on: { click: _vm.addCategory },
                        },
                        [_vm._v("추가")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn blue-line",
                          attrs: { type: "button" },
                          on: { click: _vm.onCateTemplateShow },
                        },
                        [_vm._v(" 카테고리템플릿 ")]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showInfo.istempshow,
                              expression: "showInfo.istempshow",
                            },
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.closeCatetempLayer,
                              expression: "closeCatetempLayer",
                            },
                          ],
                          staticClass: "result-component right round",
                          staticStyle: { display: "block" },
                        },
                        [
                          _c("div", { staticClass: "list checklist" }, [
                            _vm.basicInfo.categoryTemplateList.length > 0
                              ? _c(
                                  "ul",
                                  _vm._l(
                                    _vm.basicInfo.categoryTemplateList,
                                    function (item, index) {
                                      return _c("li", { key: item.cateidx }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.ischecked,
                                              expression: "item.ischecked",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: item.cateidx,
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              item.ischecked
                                            )
                                              ? _vm._i(item.ischecked, null) >
                                                -1
                                              : item.ischecked,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = item.ischecked,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      item,
                                                      "ischecked",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      item,
                                                      "ischecked",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(item, "ischecked", $$c)
                                              }
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          { attrs: { for: item.cateidx } },
                                          [
                                            _vm._v(
                                              _vm._s(item.fullcategoryname)
                                            ),
                                          ]
                                        ),
                                        _c("button", {
                                          staticClass: "del",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteCateTemplate(
                                                item,
                                                index
                                              )
                                            },
                                          },
                                        }),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _c("ul", [_vm._m(2)]),
                          ]),
                          _vm.basicInfo.categoryTemplateList.length > 0
                            ? _c("div", { staticClass: "btn-group" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn blue-line",
                                    attrs: { type: "button" },
                                    on: { click: _vm.setGoodsCategory },
                                  },
                                  [_vm._v(" 적용 ")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn gray",
                                    attrs: { type: "button" },
                                    on: { click: _vm.initCategoryTemplate },
                                  },
                                  [_vm._v(" 초기화 ")]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ]),
                _c("dl", [
                  _c("dt"),
                  _c("dd", [
                    _c(
                      "table",
                      {
                        staticClass: "category-depth",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _vm._m(3),
                        _vm._m(4),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "list" }, [
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.basicInfo.categoryList.depth1List,
                                    function (item) {
                                      return _c(
                                        "li",
                                        {
                                          key: item.idx,
                                          class: {
                                            active:
                                              _vm.basicInfo.depth1Category
                                                .idx == item.idx,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setCategoryCodeInfo(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.value))]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "list" }, [
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.basicInfo.categoryList.depth2List,
                                    function (item) {
                                      return _c(
                                        "li",
                                        {
                                          key: item.idx,
                                          class: {
                                            active:
                                              _vm.basicInfo.depth2Category
                                                .idx == item.idx,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setCategoryCodeInfo(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.value))]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "list" }, [
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.basicInfo.categoryList.depth3List,
                                    function (item) {
                                      return _c(
                                        "li",
                                        {
                                          key: item.idx,
                                          class: {
                                            active:
                                              _vm.basicInfo.depth3Category
                                                .idx == item.idx,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setCategoryCodeInfo(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.value))]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "list" }, [
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.basicInfo.categoryList.depth4List,
                                    function (item) {
                                      return _c(
                                        "li",
                                        {
                                          key: item.idx,
                                          class: {
                                            active:
                                              _vm.basicInfo.depth4Category
                                                .idx == item.idx,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setCategoryCodeInfo(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.value))]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "category-selected" }, [
                      _c(
                        "ul",
                        _vm._l(
                          _vm.basicInfo.goodsCategoryList,
                          function (item, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                on: {
                                  click: function ($event) {
                                    return _vm.selectCategory(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "box",
                                    class: { blue: item.isselected == "T" },
                                  },
                                  [_vm._v("선택카테고리")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml10",
                                    class: {
                                      "txt-blue": item.isselected == "T",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.fullcategoryname))]
                                ),
                                _c("div", { staticClass: "left-bar" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.isstar,
                                        expression: "item.isstar",
                                      },
                                    ],
                                    staticClass: "star",
                                    attrs: {
                                      type: "checkbox",
                                      "true-value": "T",
                                      "false-value": "F",
                                    },
                                    domProps: {
                                      checked: Array.isArray(item.isstar)
                                        ? _vm._i(item.isstar, null) > -1
                                        : _vm._q(item.isstar, "T"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveCategoryTemp(item)
                                      },
                                      change: function ($event) {
                                        var $$a = item.isstar,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? "T" : "F"
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                item,
                                                "isstar",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                item,
                                                "isstar",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(item, "isstar", $$c)
                                        }
                                      },
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "left-bar" }, [
                                  _c("button", {
                                    staticClass: "del",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeGoodsCategory(item)
                                      },
                                    },
                                  }),
                                ]),
                                index == 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "txt-orange left-bar small-txt",
                                      },
                                      [
                                        _c("i", { staticClass: "icon-alert" }),
                                        _vm._v(
                                          "별 아이콘 체크 시 [카테고리템플릿]에 저장됩니다."
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                ]),
                _c("dl", [
                  _vm._m(5),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.basicInfo.goodsname,
                          expression: "basicInfo.goodsname",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "text",
                        value: "",
                        placeholder: "딜명을 입력하세요!",
                        maxlength: 70,
                      },
                      domProps: { value: _vm.basicInfo.goodsname },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.basicInfo,
                            "goodsname",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c(
                    "dd",
                    {
                      staticClass: "dd-right",
                      staticStyle: { width: "105px" },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "(" + _vm._s(_vm.basicInfo.goodsname.length) + "/70)"
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("검색키워드")]),
                  _c("dd", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.basicInfo.keyword,
                          expression: "basicInfo.keyword",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "text",
                        value: "",
                        placeholder: "검색어는 ,(콤마)로 구분됩니다.",
                        maxlength: 100,
                      },
                      domProps: { value: _vm.basicInfo.keyword },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.basicInfo,
                            "keyword",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c(
                    "dd",
                    {
                      staticClass: "dd-right",
                      staticStyle: { width: "105px" },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "(" + _vm._s(_vm.basicInfo.keyword.length) + "/100)"
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("dl", [
                  _vm._m(6),
                  _c(
                    "dd",
                    [
                      _c("CommonDatePickerFromTo", {
                        attrs: {
                          fromYYYYMMDD: _vm.basicInfo.disstdate,
                          fromHH: _vm.basicInfo.dissthour,
                          fromMM: _vm.basicInfo.disstmin,
                          toYYYYMMDD: _vm.basicInfo.diseddate,
                          toHH: _vm.basicInfo.disedhour,
                          toMM: _vm.basicInfo.disedmin,
                          useFrom: true,
                          useTo: true,
                        },
                        on: { getDate: _vm.pickerChangeEvent },
                      }),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.basicInfo.disperiod,
                              expression: "basicInfo.disperiod",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.basicInfo,
                                "disperiod",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            { attrs: { id: "period_day_1", value: "day_1" } },
                            [_vm._v("1일")]
                          ),
                          _c(
                            "option",
                            { attrs: { id: "period_day_3", value: "day_3" } },
                            [_vm._v("3일")]
                          ),
                          _c(
                            "option",
                            { attrs: { id: "period_day_5", value: "day_5" } },
                            [_vm._v("5일")]
                          ),
                          _c(
                            "option",
                            { attrs: { id: "period_day_7", value: "day_7" } },
                            [_vm._v("7일")]
                          ),
                          _c(
                            "option",
                            { attrs: { id: "period_day_15", value: "day_15" } },
                            [_vm._v("15일")]
                          ),
                          _c(
                            "option",
                            {
                              attrs: { id: "period_month_1", value: "month_1" },
                            },
                            [_vm._v("1개월")]
                          ),
                          _c(
                            "option",
                            {
                              attrs: { id: "period_month_3", value: "month_3" },
                            },
                            [_vm._v("3개월")]
                          ),
                          _c(
                            "option",
                            {
                              attrs: { id: "period_month_6", value: "month_6" },
                            },
                            [_vm._v("6개월")]
                          ),
                          _c(
                            "option",
                            { attrs: { id: "period_all_0", value: "all_0" } },
                            [_vm._v("상시")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("전시 여부")]),
                  _c("dd", [
                    _c("div", { staticClass: "radio_wrap wide" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.basicInfo.isdisplay,
                            expression: "basicInfo.isdisplay",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "isdisplay",
                          id: "isdisplayT",
                          value: "T",
                        },
                        domProps: {
                          checked: _vm._q(_vm.basicInfo.isdisplay, "T"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.basicInfo, "isdisplay", "T")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "isdisplayT" } }, [
                        _vm._v("전시"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.basicInfo.isdisplay,
                            expression: "basicInfo.isdisplay",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "isdisplay",
                          id: "isdisplayF",
                          value: "F",
                        },
                        domProps: {
                          checked: _vm._q(_vm.basicInfo.isdisplay, "F"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.basicInfo, "isdisplay", "F")
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "isdisplayF" } }, [
                        _vm._v("미 전시"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("판매상태")]),
                  _c("dd", [
                    _c(
                      "div",
                      { staticClass: "radio_wrap" },
                      _vm._l(_vm.commonCode.goodsselltype, function (item) {
                        return _c("div", { key: item.cmcode }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.basicInfo.goodsselltype,
                                expression: "basicInfo.goodsselltype",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "goodsselltype",
                              id: "goodsselltype_" + item.cmcode,
                            },
                            domProps: {
                              value: item.cmcode,
                              checked: _vm._q(
                                _vm.basicInfo.goodsselltype,
                                item.cmcode
                              ),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(
                                  _vm.basicInfo,
                                  "goodsselltype",
                                  item.cmcode
                                )
                              },
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "goodsselltype_" + item.cmcode } },
                            [_vm._v(_vm._s(item.codename))]
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("dl", [
                  _vm._m(7),
                  _c("dd", [
                    _c("input", {
                      staticStyle: { width: "220px" },
                      attrs: {
                        type: "search",
                        placeholder: "담당MD를 선택하세요.",
                        readonly: "",
                        disabled: "",
                      },
                      domProps: {
                        value: _vm.$util.isNull(_vm.basicInfo.mdcode)
                          ? ""
                          : "[" +
                            _vm.basicInfo.mdcode +
                            "] " +
                            _vm.basicInfo.mdname,
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "btn-search",
                        attrs: { type: "button" },
                        on: { click: _vm.onChargemdShow },
                      },
                      [_vm._v("검색")]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showInfo.ismdshow,
                            expression: "showInfo.ismdshow",
                          },
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.closeChargemdLayer,
                            expression: "closeChargemdLayer",
                          },
                        ],
                        staticClass: "result-component ml0 no-round",
                        staticStyle: { display: "block", width: "600px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "list-header",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c(
                              "table",
                              {
                                staticClass: "data-tb align-c mg0",
                                staticStyle: { width: "100%" },
                                attrs: { cellspacing: "0", cellpadding: "0" },
                              },
                              [
                                _vm._m(8),
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [_vm._v("선택")]),
                                    _c("th", [_vm._v("No")]),
                                    _c("th", [
                                      _vm._v(" 코드 "),
                                      _c("button", {
                                        staticClass: "sort",
                                        class: [
                                          {
                                            up:
                                              _vm.sortData.chargemd.mdcode ===
                                              "mdcode_asc",
                                          },
                                          {
                                            down:
                                              _vm.sortData.chargemd.mdcode ===
                                              "mdcode_desc",
                                          },
                                        ],
                                        attrs: {
                                          type: "button",
                                          value: _vm.sortData.chargemd.mdcode,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sortToggle(
                                              "chargemd",
                                              _vm.sortData.chargemd.mdcode
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _c("th", [
                                      _vm._v(" MD명 "),
                                      _c("button", {
                                        staticClass: "sort",
                                        class: [
                                          {
                                            up:
                                              _vm.sortData.chargemd.mdname ===
                                              "mdname_asc",
                                          },
                                          {
                                            down:
                                              _vm.sortData.chargemd.mdname ===
                                              "mdname_desc",
                                          },
                                        ],
                                        attrs: {
                                          type: "button",
                                          value: _vm.sortData.chargemd.mdname,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sortToggle(
                                              "chargemd",
                                              _vm.sortData.chargemd.mdname
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _c("th", [
                                      _vm._v(" 연락처 "),
                                      _c("button", {
                                        staticClass: "sort",
                                        class: [
                                          {
                                            up:
                                              _vm.sortData.chargemd.mdtel ===
                                              "mdtel_asc",
                                          },
                                          {
                                            down:
                                              _vm.sortData.chargemd.mdtel ===
                                              "mdtel_desc",
                                          },
                                        ],
                                        attrs: {
                                          type: "button",
                                          value: _vm.sortData.chargemd.mdtel,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sortToggle(
                                              "chargemd",
                                              _vm.sortData.chargemd.mdtel
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _c("th", [_vm._v("이메일")]),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "list" }, [
                          _c(
                            "table",
                            {
                              staticClass: "data-tb align-c mg0",
                              staticStyle: { width: "100%" },
                              attrs: { cellspacing: "0", cellpadding: "0" },
                            },
                            [
                              _vm._m(9),
                              _vm.basicInfo.chargemdList.length > 0
                                ? _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.basicInfo.chargemdList,
                                      function (item, index) {
                                        return _c("tr", { key: item.mdcode }, [
                                          _c("td", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.basicInfo.mdcode,
                                                  expression:
                                                    "basicInfo.mdcode",
                                                },
                                              ],
                                              staticClass: "circle",
                                              attrs: {
                                                type: "radio",
                                                name: "mdcode",
                                                id: "mdcode" + index,
                                              },
                                              domProps: {
                                                value: item.mdcode,
                                                checked: _vm._q(
                                                  _vm.basicInfo.mdcode,
                                                  item.mdcode
                                                ),
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    return _vm.$set(
                                                      _vm.basicInfo,
                                                      "mdcode",
                                                      item.mdcode
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.setChargemdInfo(
                                                      item
                                                    )
                                                  },
                                                ],
                                              },
                                            }),
                                          ]),
                                          _c("td", [_vm._v(_vm._s(index + 1))]),
                                          _c("td", [
                                            _vm._v(_vm._s(item.mdcode)),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(item.mdname)),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(item.mdtel)),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(item.mdemail)),
                                          ]),
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                : _c("tbody", [_vm._m(10)]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("dl", [
                  _c("dt", [_vm._v("적용채널")]),
                  _c(
                    "dd",
                    [
                      _c("div", { staticClass: "check-wrap" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.basicInfo.isallchannel,
                              expression: "basicInfo.isallchannel",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "chkAllChannel",
                            "true-value": "T",
                            "false-value": "F",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.basicInfo.isallchannel)
                              ? _vm._i(_vm.basicInfo.isallchannel, null) > -1
                              : _vm._q(_vm.basicInfo.isallchannel, "T"),
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = _vm.basicInfo.isallchannel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "T" : "F"
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.basicInfo,
                                        "isallchannel",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.basicInfo,
                                        "isallchannel",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.basicInfo, "isallchannel", $$c)
                                }
                              },
                              _vm.checkAllChannel,
                            ],
                          },
                        }),
                        _c("label", { attrs: { for: "chkAllChannel" } }, [
                          _vm._v("전체적용"),
                        ]),
                      ]),
                      _vm._l(_vm.commonCode.muappchtype, function (item) {
                        return _c(
                          "div",
                          { key: item.cmcode, staticClass: "check-wrap" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.basicInfo.muappchtypeArr,
                                  expression: "basicInfo.muappchtypeArr",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "muappchtype_" + item.cmcode,
                                "true-value": [],
                              },
                              domProps: {
                                value: item.cmcode,
                                checked: Array.isArray(
                                  _vm.basicInfo.muappchtypeArr
                                )
                                  ? _vm._i(
                                      _vm.basicInfo.muappchtypeArr,
                                      item.cmcode
                                    ) > -1
                                  : _vm._q(_vm.basicInfo.muappchtypeArr, []),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.basicInfo.muappchtypeArr,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? [] : false
                                  if (Array.isArray($$a)) {
                                    var $$v = item.cmcode,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.basicInfo,
                                          "muappchtypeArr",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.basicInfo,
                                          "muappchtypeArr",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.basicInfo,
                                      "muappchtypeArr",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _c(
                              "label",
                              { attrs: { for: "muappchtype_" + item.cmcode } },
                              [_vm._v(_vm._s(item.codename))]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "boxing", attrs: { id: "tab2" } }, [
            _c(
              "div",
              {
                staticClass: "boxing-title",
                class: { closed: !_vm.tabObject.tab2.isOpen },
              },
              [
                _vm._v(" 구성상품"),
                _c("i", {
                  staticClass: "arrcodi",
                  on: {
                    click: function ($event) {
                      return _vm.onToggle(_vm.tabObject.tab2)
                    },
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "form-area",
                style: {
                  display: _vm.tabObject.tab2.isOpen ? "block" : "none",
                },
              },
              [
                _c("div", { staticClass: "blue-box" }, [
                  _vm._m(11),
                  _c(
                    "button",
                    {
                      staticClass: "btn big green mt20",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadExcelTemplate()
                        },
                      },
                    },
                    [_vm._v(" 엑셀양식 다운로드 ")]
                  ),
                  _c("div", { staticClass: "mt10" }, [
                    _c("input", {
                      ref: "excelFile",
                      attrs: {
                        type: "file",
                        hidden: "",
                        accept:
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleFileUpload("excelFile")
                        },
                      },
                    }),
                    _c("input", {
                      ref: "excelFileName",
                      attrs: { type: "text", readonly: "" },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "btn blue-line ml3",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.fileAttach("excelFile")
                          },
                        },
                      },
                      [_vm._v(" 파일찾기 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn blue-line",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.readExcelFile("excelFile")
                          },
                        },
                      },
                      [_vm._v(" 일괄등록 ")]
                    ),
                  ]),
                ]),
                _c("div", { staticStyle: { margin: "20px" } }, [
                  _c("div", { staticClass: "caption-group clearfix" }, [
                    _c("div", { staticClass: "total-group fl" }, [
                      _c("span", { staticClass: "total" }, [
                        _vm._v("전체 "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.constInfo.constGoodsList.length)),
                        ]),
                        _vm._v("건"),
                      ]),
                    ]),
                    _c("div", { staticClass: "btn-group fr" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn blue-line",
                          attrs: { type: "button" },
                          on: { click: _vm.openConstGoodsPopup },
                        },
                        [_vm._v(" 구성상품추가 ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn red-line",
                          attrs: { type: "button" },
                          on: { click: _vm.removeConstGoods },
                        },
                        [_vm._v("삭제")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn green-line",
                          attrs: { type: "button" },
                          on: { click: _vm.downloadExcel },
                        },
                        [
                          _c("i", { staticClass: "icon-excel" }),
                          _vm._v("엑셀다운로드 "),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "scroll-y",
                      staticStyle: { width: "100%", "max-height": "500px" },
                    },
                    [
                      _c(
                        "table",
                        {
                          staticClass: "data-tb align-c",
                          staticStyle: { "margin-left": "0" },
                          attrs: { cellpadding: "0", cellspacing: "0" },
                        },
                        [
                          _vm._m(12),
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.constInfo.isallchkgoods,
                                      expression: "constInfo.isallchkgoods",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "chkgoods",
                                    "true-value": "T",
                                    "false-value": "F",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.constInfo.isallchkgoods
                                    )
                                      ? _vm._i(
                                          _vm.constInfo.isallchkgoods,
                                          null
                                        ) > -1
                                      : _vm._q(
                                          _vm.constInfo.isallchkgoods,
                                          "T"
                                        ),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.constInfo.isallchkgoods,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? "T" : "F"
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.constInfo,
                                                "isallchkgoods",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.constInfo,
                                                "isallchkgoods",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.constInfo,
                                            "isallchkgoods",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        return _vm.checkAllConstGoodsList(
                                          $event.target.checked
                                        )
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              _c("th", [_vm._v("노출순위")]),
                              _c("th", [_vm._v("대표상품")]),
                              _c("th", [_vm._v("노출여부")]),
                              _c("th", [_vm._v("판매구분")]),
                              _c("th", [_vm._v("파트너사")]),
                              _c("th", [_vm._v("상품코드")]),
                              _c("th", [_vm._v("이미지")]),
                              _c("th", [_vm._v("상품명")]),
                              _c("th", [_vm._v("상품유형")]),
                              _c("th", [_vm._v("판매가")]),
                              _c("th", [_vm._v("판매상태")]),
                              _c("th", [_vm._v("전시시작일")]),
                              _c("th", [_vm._v("전시종료일")]),
                              _c("th", [_vm._v("배송정보명")]),
                              _c("th", [_vm._v("배송비")]),
                              _c("th", [_vm._v("배송유형")]),
                              _c("th", [_vm._v("수정")]),
                            ]),
                          ]),
                          _vm.constInfo.constGoodsList.length > 0
                            ? _c(
                                "tbody",
                                _vm._l(
                                  _vm.constInfo.constGoodsList,
                                  function (item, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.moveData.targetIdx,
                                              expression: "moveData.targetIdx",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: "chkgoods_" + index,
                                          },
                                          domProps: {
                                            value: index,
                                            checked: Array.isArray(
                                              _vm.moveData.targetIdx
                                            )
                                              ? _vm._i(
                                                  _vm.moveData.targetIdx,
                                                  index
                                                ) > -1
                                              : _vm.moveData.targetIdx,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a =
                                                    _vm.moveData.targetIdx,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = index,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.moveData,
                                                        "targetIdx",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.moveData,
                                                        "targetIdx",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.moveData,
                                                    "targetIdx",
                                                    $$c
                                                  )
                                                }
                                              },
                                              _vm.checkConstGoodsList,
                                            ],
                                          },
                                        }),
                                      ]),
                                      _c("td", [_vm._v(_vm._s(item.sortnum))]),
                                      _c("td", [
                                        _c("input", {
                                          staticClass: "circle",
                                          attrs: {
                                            type: "radio",
                                            name: "ismaingoods",
                                          },
                                          domProps: {
                                            checked: item.ismaingoods == "T",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.setIsMainGoods(item)
                                            },
                                          },
                                        }),
                                      ]),
                                      item.isdisplay == "T"
                                        ? _c("td", [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn blue",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeDisplay(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 노출 ")]
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.isdisplay == "F"
                                        ? _c("td", [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn red",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeDisplay(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 숨김 ")]
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c("td", [
                                        _vm._v(_vm._s(item.ispbgoodsname)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.dealername)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.goodscode)),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "img-thumb size60",
                                            class: {
                                              "no-image": _vm.$util.isNull(
                                                item.fullpath
                                              ),
                                            },
                                          },
                                          [
                                            !_vm.$util.isNull(item.fullpath)
                                              ? _c("img", {
                                                  attrs: { src: item.fullpath },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                      _c("td", { staticClass: "left" }, [
                                        _vm._v(_vm._s(item.goodsname)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.goodsdivtypename)),
                                      ]),
                                      _c("td", { staticClass: "right" }, [
                                        _vm._v(_vm._s(item.price)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.goodsselltypename)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.disstdate)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.diseddate)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.delivbname)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.delivfaretext)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.iscombdelivname)),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn blue-line",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.goGoodsDetail(
                                                  item.goodsno
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 수정 ")]
                                        ),
                                      ]),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _c("tbody", [_vm._m(13)]),
                        ]
                      ),
                    ]
                  ),
                  _vm.isWrite
                    ? _c(
                        "div",
                        { staticClass: "bottom-group" },
                        [
                          _c("CommonArraySort", {
                            attrs: {
                              "list-data": _vm.constInfo.constGoodsList,
                              "move-data": _vm.moveData,
                              "is-active-save-btn": false,
                              "key-name": "goodsno",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "boxing", attrs: { id: "tab3" } }, [
            _c(
              "div",
              {
                staticClass: "boxing-title",
                class: { closed: !_vm.tabObject.tab3.isOpen },
              },
              [
                _vm._v(" 이미지 정보"),
                _c("i", {
                  staticClass: "arrcodi",
                  on: {
                    click: function ($event) {
                      return _vm.onToggle(_vm.tabObject.tab3)
                    },
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "form-area",
                style: {
                  display: _vm.tabObject.tab3.isOpen ? "block" : "none",
                },
              },
              [
                _c("div", { staticStyle: { margin: "20px" } }, [
                  _c(
                    "table",
                    {
                      staticClass: "gray-tb",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _vm._m(14),
                      _c("tbody", [
                        _c("tr", [
                          _vm._m(15),
                          _c("td", { ref: "repreimgtd" }, [
                            _c("div", { staticClass: "radio_wrap dpb" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.imageInfo.ismaingoodsimage,
                                    expression: "imageInfo.ismaingoodsimage",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "ismaingoodsimage",
                                  id: "ismaingoodsimageT",
                                  value: "T",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.imageInfo.ismaingoodsimage,
                                    "T"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.imageInfo,
                                        "ismaingoodsimage",
                                        "T"
                                      )
                                    },
                                    function ($event) {
                                      return _vm.setPcrepreImg("T")
                                    },
                                  ],
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: "ismaingoodsimageT" } },
                                [_vm._v("대표상품 이미지 사용")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.imageInfo.ismaingoodsimage,
                                    expression: "imageInfo.ismaingoodsimage",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "ismaingoodsimage",
                                  id: "ismaingoodsimageF",
                                  value: "F",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.imageInfo.ismaingoodsimage,
                                    "F"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.imageInfo,
                                        "ismaingoodsimage",
                                        "F"
                                      )
                                    },
                                    function ($event) {
                                      return _vm.setPcrepreImg("F")
                                    },
                                  ],
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: "ismaingoodsimageF" } },
                                [_vm._v("이미지 직접등록")]
                              ),
                            ]),
                            _c("div", { staticClass: "dpb" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "img-with-text mt10",
                                  staticStyle: { width: "202px" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "img-thumb size200",
                                      class: {
                                        "no-image": _vm.$util.isNull(
                                          _vm.files["pcrepreimgfile"]
                                        ),
                                      },
                                    },
                                    [
                                      !_vm.$util.isNull(
                                        _vm.files["pcrepreimgfile"]
                                      ) &&
                                      _vm.files["pcrepreimgfile"].status != "N"
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.imgPreview[
                                                "pcrepreimgfile"
                                              ],
                                              alt: "대표이미지",
                                            },
                                          })
                                        : _vm._e(),
                                      !_vm.$util.isNull(
                                        _vm.files["pcrepreimgfile"]
                                      ) &&
                                      _vm.files["pcrepreimgfile"].status == "N"
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.files["pcrepreimgfile"]
                                                .fullpath,
                                              alt: "대표이미지",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm.imageInfo.ismaingoodsimage == "F" &&
                                  _vm.$util.isNull(_vm.files["pcrepreimgfile"])
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn blue-line mt10",
                                          staticStyle: { width: "100%" },
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.fileAttach(
                                                "pcrepreimgfile"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 파일 올리기 ")]
                                      )
                                    : _vm._e(),
                                  _c("input", {
                                    ref: "pcrepreimgfile",
                                    attrs: {
                                      type: "file",
                                      accept: "image/jpeg, image/png",
                                      hidden: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleFileUpload(
                                          "pcrepreimgfile"
                                        )
                                      },
                                    },
                                  }),
                                  _vm.imageInfo.ismaingoodsimage == "F" &&
                                  !_vm.$util.isNull(_vm.files["pcrepreimgfile"])
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn blue-line mt10",
                                          staticStyle: {
                                            width: "calc(50% - 3px)",
                                          },
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.fileAttach(
                                                "pcrepreimgfile"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 변경 ")]
                                      )
                                    : _vm._e(),
                                  _vm.imageInfo.ismaingoodsimage == "F" &&
                                  !_vm.$util.isNull(_vm.files["pcrepreimgfile"])
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn red-line mt10",
                                          staticStyle: {
                                            width: "calc(50% - 4px)",
                                          },
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeFile(
                                                "pcrepreimgfile"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 삭제 ")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._m(16),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("추가이미지")]),
                          _c("td", [
                            _c("input", {
                              ref: "addimgFile",
                              attrs: {
                                type: "file",
                                id: "addimgFile",
                                accept: "image/jpeg, image/png",
                                hidden: "",
                                multiple: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleFileUpload(
                                    "addimgFile",
                                    $event.target
                                  )
                                },
                              },
                            }),
                            _c(
                              "div",
                              _vm._l(5, function (index, n) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "img-with-text",
                                    staticStyle: { width: "202px" },
                                  },
                                  [
                                    _vm.$util.isNull(
                                      _vm.files["addimgfilelist"][n]
                                    )
                                      ? _c("div", {
                                          staticClass:
                                            "img-thumb size200 no-image",
                                        })
                                      : _c(
                                          "div",
                                          { staticClass: "img-thumb size200" },
                                          [
                                            _vm.files["addimgfilelist"][n]
                                              .status != "N"
                                              ? _c("img", {
                                                  attrs: {
                                                    src: _vm.imgPreview[
                                                      "addimgfilelist"
                                                    ][n],
                                                    alt: "추가이미지" + n,
                                                  },
                                                })
                                              : _c("img", {
                                                  attrs: {
                                                    src: _vm.files[
                                                      "addimgfilelist"
                                                    ][n].fullpath,
                                                    alt: "추가이미지" + n,
                                                  },
                                                }),
                                          ]
                                        ),
                                    _vm.$util.isNull(
                                      _vm.files["addimgfilelist"][n]
                                    )
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn blue-line mt10",
                                            staticStyle: { width: "100%" },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fileAttach(
                                                  "addimgFile"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 파일 올리기 ")]
                                        )
                                      : _vm._e(),
                                    !_vm.$util.isNull(
                                      _vm.files["addimgfilelist"][n]
                                    )
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn blue-line mt10",
                                            staticStyle: {
                                              width: "calc(50% - 3px)",
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fileAttach(
                                                  "changeaddimgfile" + n
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 변경 ")]
                                        )
                                      : _vm._e(),
                                    !_vm.$util.isNull(
                                      _vm.files["addimgfilelist"][n]
                                    )
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn red-line mt10",
                                            staticStyle: {
                                              width: "calc(50% - 4px)",
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeFile(
                                                  "addimgFile",
                                                  n
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 삭제 ")]
                                        )
                                      : _vm._e(),
                                    _c("input", {
                                      ref: "changeaddimgfile" + n,
                                      refInFor: true,
                                      attrs: {
                                        type: "file",
                                        id: "changeaddimgfile" + n,
                                        accept: "image/jpeg, image/png",
                                        hidden: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleFileUpload(
                                            "changeaddimgfile",
                                            $event.target,
                                            n
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              { staticClass: "mt20" },
                              _vm._l(5, function (index, n) {
                                return _c(
                                  "div",
                                  {
                                    key: index + 5,
                                    staticClass: "img-with-text",
                                    staticStyle: { width: "202px" },
                                  },
                                  [
                                    _vm.$util.isNull(
                                      _vm.files["addimgfilelist"][n + 5]
                                    )
                                      ? _c("div", {
                                          staticClass:
                                            "img-thumb size200 no-image",
                                        })
                                      : _c(
                                          "div",
                                          { staticClass: "img-thumb size200" },
                                          [
                                            _vm.files["addimgfilelist"][n]
                                              .status != "N"
                                              ? _c("img", {
                                                  attrs: {
                                                    src: _vm.imgPreview[
                                                      "addimgfilelist"
                                                    ][n + 5],
                                                    alt: "추가이미지" + (n + 5),
                                                  },
                                                })
                                              : _c("img", {
                                                  attrs: {
                                                    src: _vm.files[
                                                      "addimgfilelist"
                                                    ][n + 5].fullpath,
                                                    alt: "추가이미지" + (n + 5),
                                                  },
                                                }),
                                          ]
                                        ),
                                    _vm.$util.isNull(
                                      _vm.files["addimgfilelist"][n + 5]
                                    )
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn blue-line mt10",
                                            staticStyle: { width: "100%" },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fileAttach(
                                                  "addimgFile"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 파일 올리기 ")]
                                        )
                                      : _vm._e(),
                                    !_vm.$util.isNull(
                                      _vm.files["addimgfilelist"][n + 5]
                                    )
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn blue-line mt10",
                                            staticStyle: {
                                              width: "calc(50% - 3px)",
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                _vm.fileAttach(
                                                  "changeaddimgfile" + (n + 5)
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 변경 ")]
                                        )
                                      : _vm._e(),
                                    !_vm.$util.isNull(
                                      _vm.files["addimgfilelist"][n + 5]
                                    )
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn red-line mt10",
                                            staticStyle: {
                                              width: "calc(50% - 4px)",
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeFile(
                                                  "addimgFile",
                                                  n + 5
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 삭제 ")]
                                        )
                                      : _vm._e(),
                                    _c("input", {
                                      ref: "changeaddimgfile" + (n + 5),
                                      refInFor: true,
                                      attrs: {
                                        type: "file",
                                        id: "changeaddimgfile" + (n + 5),
                                        accept: "image/jpeg, image/png",
                                        hidden: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleFileUpload(
                                            "changeaddimgfile",
                                            $event.target,
                                            n + 5
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("모바일이미지")]),
                          _c("td", [
                            _c(
                              "div",
                              {
                                staticClass: "img-with-text",
                                staticStyle: { width: "202px" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "img-thumb size460wide",
                                    class: {
                                      "no-image": _vm.$util.isNull(
                                        _vm.files["morepreimgfile"]
                                      ),
                                    },
                                  },
                                  [
                                    !_vm.$util.isNull(
                                      _vm.files["morepreimgfile"]
                                    ) &&
                                    _vm.files["morepreimgfile"].status != "N"
                                      ? _c("img", {
                                          staticClass: "fit",
                                          attrs: {
                                            src: _vm.imgPreview[
                                              "morepreimgfile"
                                            ],
                                            alt: "대표이미지(모바일)",
                                          },
                                        })
                                      : _vm._e(),
                                    !_vm.$util.isNull(
                                      _vm.files["morepreimgfile"]
                                    ) &&
                                    _vm.files["morepreimgfile"].status == "N"
                                      ? _c("img", {
                                          staticClass: "fit",
                                          attrs: {
                                            src: _vm.files["morepreimgfile"]
                                              .fullpath,
                                            alt: "대표이미지(모바일)",
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _vm.$util.isNull(_vm.files["morepreimgfile"])
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn blue-line mt10",
                                        staticStyle: { width: "100%" },
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fileAttach(
                                              "morepreimgfile"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 파일 올리기 ")]
                                    )
                                  : _vm._e(),
                                _c("input", {
                                  ref: "morepreimgfile",
                                  attrs: {
                                    type: "file",
                                    accept: "image/jpeg, image/png",
                                    hidden: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleFileUpload(
                                        "morepreimgfile"
                                      )
                                    },
                                  },
                                }),
                                !_vm.$util.isNull(_vm.files["morepreimgfile"])
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn blue-line mt10",
                                        staticStyle: {
                                          width: "calc(50% - 3px)",
                                        },
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fileAttach(
                                              "morepreimgfile"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 변경 ")]
                                    )
                                  : _vm._e(),
                                !_vm.$util.isNull(_vm.files["morepreimgfile"])
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn red-line mt10",
                                        staticStyle: {
                                          width: "calc(50% - 4px)",
                                        },
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeFile(
                                              "morepreimgfile"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 삭제 ")]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._m(17),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("공지이미지")]),
                          _c("td", [
                            _c("div", { staticClass: "radio_wrap wide" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.imageInfo.isusenotice,
                                    expression: "imageInfo.isusenotice",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "isusenotice",
                                  id: "isusenoticeF",
                                  value: "F",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.imageInfo.isusenotice,
                                    "F"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.imageInfo,
                                      "isusenotice",
                                      "F"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "isusenoticeF" } }, [
                                _vm._v("미사용"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.imageInfo.isusenotice,
                                    expression: "imageInfo.isusenotice",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "isusenotice",
                                  id: "isusenoticeT",
                                  value: "T",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.imageInfo.isusenotice,
                                    "T"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.imageInfo,
                                      "isusenotice",
                                      "T"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "isusenoticeT" } }, [
                                _vm._v("사용"),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.imageInfo.isusenotice == "T",
                                    expression: "imageInfo.isusenotice == 'T'",
                                  },
                                ],
                                staticClass: "mt10",
                              },
                              [
                                _c("common-editor", {
                                  ref: "noticeeditor",
                                  attrs: { "style-object": _vm.styleObject },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("인트로이미지")]),
                          _c("td", [
                            _c("div", { staticClass: "radio_wrap wide" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.imageInfo.isuseintro,
                                    expression: "imageInfo.isuseintro",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "isuseintro",
                                  id: "isuseintroT",
                                  value: "F",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.imageInfo.isuseintro,
                                    "F"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.imageInfo,
                                      "isuseintro",
                                      "F"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "isuseintroT" } }, [
                                _vm._v("미사용"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.imageInfo.isuseintro,
                                    expression: "imageInfo.isuseintro",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "isuseintro",
                                  id: "isuseintroF",
                                  value: "T",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.imageInfo.isuseintro,
                                    "T"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.imageInfo,
                                      "isuseintro",
                                      "T"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "isuseintroF" } }, [
                                _vm._v("사용"),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.imageInfo.isuseintro == "T",
                                    expression: "imageInfo.isuseintro == 'T'",
                                  },
                                ],
                                staticClass: "mt10",
                              },
                              [
                                _c("common-editor", {
                                  ref: "introeditor",
                                  attrs: { "style-object": _vm.styleObject },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _vm._m(18),
                          _c("td", [
                            _c(
                              "div",
                              [
                                _c("common-editor", {
                                  ref: "pceditor",
                                  attrs: { "style-object": _vm.styleObject },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _vm._m(19),
                          _c("td", [
                            _c("div", { staticClass: "mb10" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn blue-line",
                                  attrs: { type: "button" },
                                  on: { click: _vm.setSameAsPcDetailContrent },
                                },
                                [_vm._v(" PC용 상품상세설명 복사 ")]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "mt10" },
                              [
                                _c("common-editor", {
                                  ref: "mobileeditor",
                                  attrs: { "style-object": _vm.styleObject },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "boxing", attrs: { id: "tab4" } }, [
            _c(
              "div",
              {
                staticClass: "boxing-title",
                class: { closed: !_vm.tabObject.tab4.isOpen },
              },
              [
                _vm._v(" 추가정보"),
                _c("i", {
                  staticClass: "arrcodi",
                  on: {
                    click: function ($event) {
                      return _vm.onToggle(_vm.tabObject.tab4)
                    },
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "form-area",
                style: {
                  display: _vm.tabObject.tab4.isOpen ? "block" : "none",
                },
              },
              [
                _c("div", { staticStyle: { margin: "20px" } }, [
                  _c(
                    "table",
                    {
                      staticClass: "gray-tb",
                      attrs: { cellpadding: "0", cellspacing: "0" },
                    },
                    [
                      _vm._m(20),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("회원유형 별 노출여부")]),
                          _c(
                            "td",
                            [
                              _c("div", { staticClass: "check-wrap" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.etcInfo.isallmember,
                                      expression: "etcInfo.isallmember",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "chkAllMember",
                                    "true-value": "T",
                                    "false-value": "F",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.etcInfo.isallmember
                                    )
                                      ? _vm._i(_vm.etcInfo.isallmember, null) >
                                        -1
                                      : _vm._q(_vm.etcInfo.isallmember, "T"),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.etcInfo.isallmember,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? "T" : "F"
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.etcInfo,
                                                "isallmember",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.etcInfo,
                                                "isallmember",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.etcInfo,
                                            "isallmember",
                                            $$c
                                          )
                                        }
                                      },
                                      _vm.checkAllMembertype,
                                    ],
                                  },
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "chkAllMember" } },
                                  [_vm._v("전체")]
                                ),
                              ]),
                              _vm._l(
                                _vm.commonCode.dadamembertype,
                                function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.cmcode,
                                      staticClass: "check-wrap",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.etcInfo.mumembertypeArr,
                                            expression:
                                              "etcInfo.mumembertypeArr",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "mumembertype_" + item.cmcode,
                                          "true-value": [],
                                        },
                                        domProps: {
                                          value: item.cmcode,
                                          checked: Array.isArray(
                                            _vm.etcInfo.mumembertypeArr
                                          )
                                            ? _vm._i(
                                                _vm.etcInfo.mumembertypeArr,
                                                item.cmcode
                                              ) > -1
                                            : _vm._q(
                                                _vm.etcInfo.mumembertypeArr,
                                                []
                                              ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.etcInfo.mumembertypeArr,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? [] : false
                                            if (Array.isArray($$a)) {
                                              var $$v = item.cmcode,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.etcInfo,
                                                    "mumembertypeArr",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.etcInfo,
                                                    "mumembertypeArr",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.etcInfo,
                                                "mumembertypeArr",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "mumembertype_" + item.cmcode,
                                          },
                                        },
                                        [_vm._v(_vm._s(item.codename))]
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("리뷰 공개유무")]),
                          _c("td", [
                            _c("div", { staticClass: "radio_wrap wide" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.etcInfo.isopenreview,
                                    expression: "etcInfo.isopenreview",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "isopenreview",
                                  id: "isopenreviewT",
                                  value: "T",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.etcInfo.isopenreview,
                                    "T"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.etcInfo,
                                      "isopenreview",
                                      "T"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "isopenreviewT" } }, [
                                _vm._v("공개"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.etcInfo.isopenreview,
                                    expression: "etcInfo.isopenreview",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "isopenreview",
                                  id: "isopenreviewF",
                                  value: "F",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.etcInfo.isopenreview,
                                    "F"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.etcInfo,
                                      "isopenreview",
                                      "F"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "isopenreviewF" } }, [
                                _vm._v("비공개"),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("관리자 메모")]),
                          _c("td", [
                            _c("div", { staticClass: "memo-write" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.etcInfo.inputmemo,
                                    expression: "etcInfo.inputmemo",
                                  },
                                ],
                                attrs: { placeholder: "메모를 작성해 주세요!" },
                                domProps: { value: _vm.etcInfo.inputmemo },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.etcInfo,
                                      "inputmemo",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn big blue",
                                  attrs: { type: "button" },
                                  on: { click: _vm.addMeno },
                                },
                                [_vm._v(" 메모"), _c("br"), _vm._v("저장 ")]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "scroll-y",
                                staticStyle: {
                                  width: "100%",
                                  "max-height": "300px",
                                  "margin-bottom": "0",
                                },
                              },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass: "data-tb align-c",
                                    attrs: {
                                      cellpadding: "0",
                                      cellspacing: "0",
                                    },
                                  },
                                  [
                                    _vm._m(21),
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", [_vm._v("No")]),
                                        _c("th", [
                                          _vm._v(" 작성일시 "),
                                          _c("button", {
                                            staticClass: "sort",
                                            class: [
                                              {
                                                up:
                                                  _vm.sortData.memo.regdate ===
                                                  "regdate_asc",
                                              },
                                              {
                                                down:
                                                  _vm.sortData.memo.regdate ===
                                                  "regdate_desc",
                                              },
                                            ],
                                            attrs: {
                                              type: "button",
                                              value: _vm.sortData.memo.regdate,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sortToggle(
                                                  "memo",
                                                  _vm.sortData.memo.regdate
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                        _c("th", [_vm._v("작성자")]),
                                        _c("th", { attrs: { colspan: "2" } }, [
                                          _vm._v("내용"),
                                        ]),
                                      ]),
                                    ]),
                                    _vm.etcInfo.isshowmemo
                                      ? _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.etcInfo.goodsMemoList,
                                            function (item) {
                                              return _c(
                                                "tr",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.istrash == "F",
                                                      expression:
                                                        "item.istrash == 'F'",
                                                    },
                                                  ],
                                                  key: item.idx,
                                                },
                                                [
                                                  _c("td", [
                                                    _vm._v(_vm._s(item.no)),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(item.regdate)
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(item.regusername)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    { staticClass: "left" },
                                                    [_vm._v(_vm._s(item.memo))]
                                                  ),
                                                  _c(
                                                    "td",
                                                    { staticClass: "no-left" },
                                                    [
                                                      _c("button", {
                                                        staticClass: "del mg0",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeMeno(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _c("tbody", [_vm._m(22)]),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "btn-group" }, [
            !_vm.$util.isNull(_vm.basicInfo.dealno)
              ? _c(
                  "button",
                  {
                    staticClass: "btn big blue-line",
                    attrs: { type: "button" },
                    on: { click: _vm.goPriview },
                  },
                  [_vm._v(" 미리보기 ")]
                )
              : _vm._e(),
            _vm.$util.isNull(_vm.basicInfo.istempsave) ||
            _vm.basicInfo.istempsave === "T"
              ? _c(
                  "button",
                  {
                    staticClass: "btn big blue",
                    attrs: { type: "button" },
                    on: { click: _vm.goTemporarySave },
                  },
                  [_vm._v(" 임시저장 ")]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "btn big blue",
                attrs: { type: "button" },
                on: { click: _vm.goSave },
              },
              [_vm._v("저장")]
            ),
          ]),
        ]),
      ]),
      _vm.isGoodsDetailShow
        ? _c("GoodsDetail", {
            attrs: { activeGoodsNo: _vm.activeGoodsNo },
            on: { closePopup: _vm.closeGoodsDetail },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [_vm._v("카테고리"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [_c("a", [_vm._v("조회 결과가 존재하지 않습니다.")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [
      _c("input", { attrs: { type: "checkbox" } }),
      _c("label", [_vm._v("조회 결과가 존재하지 않습니다.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("대분류")]),
        _c("th", [_vm._v("중분류")]),
        _c("th", [_vm._v("소분류")]),
        _c("th", [_vm._v("세분류")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [_vm._v("딜명"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [_vm._v("전시기간"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("dt", [_vm._v("담당MD"), _c("i", { staticClass: "essential" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "22%" } }),
      _c("col", { attrs: { width: "36%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "22%" } }),
      _c("col", { attrs: { width: "36%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [
      _c("td", { attrs: { colspan: "6" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", [
      _c("li", [
        _vm._v(
          " 딜 구성 상품은 최대 000개까지 일괄 등록 가능하며, 000개 초과 시 일괄 등록 대상에서 제외합니다. "
        ),
      ]),
      _c("li", [
        _vm._v(
          " 엑셀 파일 업로드와 동시에 구성 상품 정보를 체크하며, 정상 건은 추가 등록하고 등록 불가 상품은 제외 처리하여 추가 등록하지 않습니다. "
        ),
      ]),
      _c("li", [
        _vm._v(
          "존재하지 않는 상품번호 또는 문자가 입력된 경우 제외 처리 됩니다."
        ),
      ]),
      _c("li", [_vm._v("상태가 판매중인 상품만 딜로 구성 할 수 있습니다.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "2%" } }),
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "3.5%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "3.5%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [
      _c("td", { attrs: { colspan: "19" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v("대표이미지"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "img-with-text text" }, [
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          "딜 판매상품의 대표 이미지입니다. 보기 쉬운 간결한 이미지를 활용해 주세요. "
        ),
      ]),
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          "사이즈: 460*460 / 최소: 200*200 / 용량: 10MB 이하 / 파일 : JPG, JPEG, PNG "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "img-with-text text" }, [
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          "모바일 리스팅 및 와이드형 화면에 노출되는 이미지를 업로드 해 주세요. "
        ),
      ]),
      _c("p", { staticClass: "txt-orange" }, [
        _c("i", { staticClass: "icon-alert" }),
        _vm._v(
          "사이즈: 460*460 / 최소: 200*200 / 용량: 10MB 이하 / 파일 : JPG, JPEG, PNG "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v("PC용 상품상세설명"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _vm._v("모바일용 상품상세설명"),
      _c("i", { staticClass: "essential" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("colgroup", [
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "40px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [
      _c("td", { attrs: { colspan: "5" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }