var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tab-area",
      style: _vm.isPartner
        ? "height: calc(90vh - 100px);"
        : "height: calc(90vh - 290px);",
    },
    [
      (_vm.$util.isNull(_vm.waitingDeposit.list) ||
        _vm.waitingDeposit.list.length === 0) &&
      (_vm.$util.isNull(_vm.prepGoods.list) ||
        _vm.prepGoods.list.length === 0) &&
      (_vm.$util.isNull(_vm.prepDeliv.list) ||
        _vm.prepDeliv.list.length === 0) &&
      (_vm.$util.isNull(_vm.inDeliv.list) || _vm.inDeliv.list.length === 0) &&
      (_vm.$util.isNull(_vm.completeDeliv.list) ||
        _vm.completeDeliv.list.length === 0)
        ? _c("div", [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "mt0", class: { "scroll-x": !_vm.isPartner } },
              [
                _c(
                  "table",
                  {
                    staticClass: "data-tb align-c",
                    style: !_vm.isPartner ? "width: 1800px;" : "",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _c("caption", [_vm._v("주문 목록")]),
                    !_vm.isPartner
                      ? _c("colgroup", [
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "62px" } }),
                          _c("col", { attrs: { width: "" } }),
                          _c("col", { attrs: { width: "7%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "3.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "3.8%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                        ])
                      : _c("colgroup", [
                          _c("col", { attrs: { width: "7%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "62px" } }),
                          _c("col", { attrs: { width: "" } }),
                          _c("col", { attrs: { width: "8%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                        ]),
                    _c("thead", [
                      _c("tr", [
                        !_vm.isPartner
                          ? _c("th", [_vm._v("판매구분")])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("파트너사")])
                          : _vm._e(),
                        _c("th", [_vm._v("상품코드")]),
                        _c("th", [_vm._v("단품코드")]),
                        _c("th", [_vm._v("상품순번")]),
                        _c("th", { attrs: { colspan: "2" } }, [
                          _vm._v("상품명"),
                        ]),
                        _c("th", [_vm._v("옵션")]),
                        _c("th", [_vm._v("주문수량")]),
                        _c("th", [_vm._v("판매단가")]),
                        _c("th", [_vm._v("판매금액")]),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("입금자")])
                          : _vm._e(),
                        _c("th", [_vm._v("결제수단")]),
                        _c("th", [_vm._v("배송유형")]),
                        _c("th", [_vm._v("배송조건")]),
                        _c("th", [_vm._v("배송비")]),
                        _c("th", [_vm._v("주문상태")]),
                        _c("th", [_vm._v("클레임상태")]),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("추가상품여부")])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c(
                          "td",
                          { attrs: { colspan: _vm.isPartner ? 15 : 19 } },
                          [_vm._v("주문상품 목록이 존재하지 않습니다.")]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      !_vm.$util.isNull(_vm.waitingDeposit.list) &&
      _vm.waitingDeposit.list.length > 0
        ? _c("div", [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "mt0", class: { "scroll-x": !_vm.isPartner } },
              [
                _c(
                  "table",
                  {
                    staticClass: "data-tb align-c",
                    style: !_vm.isPartner ? "width: 1800px;" : "",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _c("caption", [_vm._v("주문 목록")]),
                    !_vm.isPartner
                      ? _c("colgroup", [
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "62px" } }),
                          _c("col", { attrs: { width: "" } }),
                          _c("col", { attrs: { width: "7%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "3.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "3.8%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                        ])
                      : _c("colgroup", [
                          _c("col", { attrs: { width: "7%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "62px" } }),
                          _c("col", { attrs: { width: "" } }),
                          _c("col", { attrs: { width: "8%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                        ]),
                    _c("thead", [
                      _c("tr", [
                        !_vm.isPartner
                          ? _c("th", [_vm._v("판매구분")])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("파트너사")])
                          : _vm._e(),
                        _c("th", [_vm._v("상품코드")]),
                        _c("th", [_vm._v("단품코드")]),
                        _c("th", [_vm._v("상품순번")]),
                        _c("th", { attrs: { colspan: "2" } }, [
                          _vm._v("상품명"),
                        ]),
                        _c("th", [_vm._v("옵션")]),
                        _c("th", [_vm._v("주문수량")]),
                        _c("th", [_vm._v("판매단가")]),
                        _c("th", [_vm._v("판매금액")]),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("입금자")])
                          : _vm._e(),
                        _c("th", [_vm._v("결제수단")]),
                        _c("th", [_vm._v("배송유형")]),
                        _c("th", [_vm._v("배송조건")]),
                        _c("th", [_vm._v("배송비")]),
                        _c("th", [_vm._v("주문상태")]),
                        _c("th", [_vm._v("클레임상태")]),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("추가상품여부")])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm.waitingDeposit.list.length > 0
                      ? _c(
                          "tbody",
                          _vm._l(
                            _vm.waitingDeposit.list,
                            function (item, index) {
                              return _c("tr", { key: index }, [
                                !_vm.isPartner
                                  ? _c("td", [
                                      _vm._v(_vm._s(item.ispbgoodsname)),
                                    ])
                                  : _vm._e(),
                                !_vm.isPartner
                                  ? _c("td", [_vm._v(_vm._s(item.dealername))])
                                  : _vm._e(),
                                _c("td", [_vm._v(_vm._s(item.goodscode))]),
                                _c("td", [_vm._v(_vm._s(item.optioncode))]),
                                _c("td", [_vm._v(_vm._s(item.goodsturn))]),
                                _c("td", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "img-thumb size60 link",
                                      class: {
                                        "no-image": _vm.$util.isNull(
                                          item.fullpath
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goFrontGoodsDetail(
                                            item.goodscode
                                          )
                                        },
                                      },
                                    },
                                    [
                                      !_vm.$util.isNull(item.fullpath)
                                        ? _c("img", {
                                            attrs: { src: item.fullpath },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _c("td", { staticClass: "left no-left" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "link",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goGoodsDetail(item.goodsno)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.goodsname) + " ")]
                                  ),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "white-space": "pre-wrap" },
                                  },
                                  [_vm._v(_vm._s(item.optionconts))]
                                ),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.$util.maskComma(item.ordcnt))
                                  ),
                                ]),
                                _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    _vm._s(_vm.$util.maskComma(item.price))
                                  ),
                                ]),
                                _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    _vm._s(_vm.$util.maskComma(item.totprice))
                                  ),
                                ]),
                                !_vm.isPartner
                                  ? _c("td", [_vm._v(_vm._s(item.vircusname))])
                                  : _vm._e(),
                                _c("td", [_vm._v(_vm._s(item.paywaytypename))]),
                                _c("td", [
                                  _vm._v(_vm._s(item.iscombdelivname)),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(item.delivfaretypename)),
                                ]),
                                _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    _vm._s(_vm.$util.maskComma(item.delivamt))
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "link",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goOrderHistory(
                                            item.orgdelividx
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.ordstatusname) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "link",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goClaimHistory(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.clmstatusname) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                                !_vm.isPartner
                                  ? _c("td", [_vm._v(_vm._s(item.isaddgoods))])
                                  : _vm._e(),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      !_vm.$util.isNull(_vm.prepGoods.list) && _vm.prepGoods.list.length > 0
        ? _c("div", [
            _c("div", { staticClass: "clearfix" }, [
              _c("div", { staticClass: "bar-title small fl" }, [
                _vm._v("상품준비중"),
              ]),
              _vm.isWrite
                ? _c("div", { staticClass: "btn-group fr" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn blue-line",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.fnChangeOrdStatus(
                              "prepGoods",
                              _vm.$store.getters["ADMIN"].ORDER_PREPARING_DELIV
                            )
                          },
                        },
                      },
                      [_vm._v(" 배송준비중 ")]
                    ),
                    !_vm.isPartner
                      ? _c(
                          "button",
                          {
                            staticClass: "btn red-line",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.goCancelApply("prepGoods")
                              },
                            },
                          },
                          [_vm._v(" 주문취소 ")]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "mt0", class: { "scroll-x": !_vm.isPartner } },
              [
                _c(
                  "table",
                  {
                    staticClass: "data-tb align-c",
                    style: !_vm.isPartner ? "width: 1800px;" : "",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _c("caption", [_vm._v("주문 목록")]),
                    !_vm.isPartner
                      ? _c("colgroup", [
                          _c("col", { attrs: { width: "2%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "62px" } }),
                          _c("col", { attrs: { width: "" } }),
                          _c("col", { attrs: { width: "7%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "3.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                        ])
                      : _c("colgroup", [
                          _c("col", { attrs: { width: "2%" } }),
                          _c("col", { attrs: { width: "7%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "62px" } }),
                          _c("col", { attrs: { width: "" } }),
                          _c("col", { attrs: { width: "8%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                        ]),
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.prepGoods.isallchk,
                                expression: "prepGoods.isallchk",
                              },
                            ],
                            attrs: { id: "chkall", type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.prepGoods.isallchk)
                                ? _vm._i(_vm.prepGoods.isallchk, null) > -1
                                : _vm.prepGoods.isallchk,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.prepGoods.isallchk,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.prepGoods,
                                          "isallchk",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.prepGoods,
                                          "isallchk",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.prepGoods, "isallchk", $$c)
                                  }
                                },
                                function ($event) {
                                  return _vm.checkAllList(
                                    "prepGoods",
                                    $event.target.checked
                                  )
                                },
                              ],
                            },
                          }),
                        ]),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("판매구분")])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("파트너사")])
                          : _vm._e(),
                        _c("th", [_vm._v("상품코드")]),
                        _c("th", [_vm._v("단품코드")]),
                        _c("th", [_vm._v("상품순번")]),
                        _c("th", { attrs: { colspan: "2" } }, [
                          _vm._v("상품명"),
                        ]),
                        _c("th", [_vm._v("옵션")]),
                        _c("th", [_vm._v("주문수량")]),
                        _c("th", [_vm._v("판매단가")]),
                        _c("th", [_vm._v("판매금액")]),
                        _c("th", [_vm._v("배송유형")]),
                        _c("th", [_vm._v("배송조건")]),
                        _c("th", [_vm._v("배송비")]),
                        _c("th", [_vm._v("주문상태")]),
                        _c("th", [_vm._v("클레임상태")]),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("추가상품여부")])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm.prepGoods.list.length > 0
                      ? _c(
                          "tbody",
                          _vm._l(_vm.prepGoods.list, function (item, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.prepGoods.checkedList,
                                      expression: "prepGoods.checkedList",
                                    },
                                  ],
                                  attrs: {
                                    id: item.orgdelividx,
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    value: item.orgdelividx,
                                    checked: Array.isArray(
                                      _vm.prepGoods.checkedList
                                    )
                                      ? _vm._i(
                                          _vm.prepGoods.checkedList,
                                          item.orgdelividx
                                        ) > -1
                                      : _vm.prepGoods.checkedList,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.prepGoods.checkedList,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = item.orgdelividx,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.prepGoods,
                                                "checkedList",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.prepGoods,
                                                "checkedList",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.prepGoods,
                                            "checkedList",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        return _vm.checkList("prepGoods")
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              !_vm.isPartner
                                ? _c("td", [_vm._v(_vm._s(item.ispbgoodsname))])
                                : _vm._e(),
                              !_vm.isPartner
                                ? _c("td", [_vm._v(_vm._s(item.dealername))])
                                : _vm._e(),
                              _c("td", [_vm._v(_vm._s(item.goodscode))]),
                              _c("td", [_vm._v(_vm._s(item.optioncode))]),
                              _c("td", [_vm._v(_vm._s(item.goodsturn))]),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "img-thumb size60 link",
                                    class: {
                                      "no-image": _vm.$util.isNull(
                                        item.fullpath
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goFrontGoodsDetail(
                                          item.goodscode
                                        )
                                      },
                                    },
                                  },
                                  [
                                    !_vm.$util.isNull(item.fullpath)
                                      ? _c("img", {
                                          attrs: { src: item.fullpath },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _c("td", { staticClass: "left no-left" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goGoodsDetail(item.goodsno)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.goodsname) + " ")]
                                ),
                              ]),
                              _c(
                                "td",
                                { staticStyle: { "white-space": "pre-wrap" } },
                                [_vm._v(_vm._s(item.optionconts))]
                              ),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.ordcnt))
                                ),
                              ]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(_vm._s(_vm.$util.maskComma(item.price))),
                              ]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.totprice))
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.iscombdelivname))]),
                              _c("td", [
                                _vm._v(_vm._s(item.delivfaretypename)),
                              ]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.delivamt))
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goOrderHistory(
                                          item.orgdelividx
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.ordstatusname) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goClaimHistory(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.clmstatusname) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              !_vm.isPartner
                                ? _c("td", [_vm._v(_vm._s(item.isaddgoods))])
                                : _vm._e(),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      !_vm.$util.isNull(_vm.prepDeliv.list) && _vm.prepDeliv.list.length > 0
        ? _c("div", [
            _c("div", { staticClass: "clearfix" }, [
              _c("div", { staticClass: "bar-title small fl" }, [
                _vm._v("배송준비중"),
              ]),
              _vm.isWrite
                ? _c("div", { staticClass: "btn-group fr" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn blue-line",
                        attrs: { type: "button" },
                        on: { click: _vm.saveInvoiceAll },
                      },
                      [_vm._v("일괄등록처리")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn blue-line",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.fnChangeOrdStatus(
                              "prepDeliv",
                              _vm.$store.getters["ADMIN"].ORDER_PREPARING_GOODS
                            )
                          },
                        },
                      },
                      [_vm._v(" 상품준비중 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn blue-line",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.fnChangeOrdStatus(
                              "prepDeliv",
                              _vm.$store.getters["ADMIN"].ORDER_IN_DELIVERY
                            )
                          },
                        },
                      },
                      [_vm._v(" 배송중처리 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn blue-line",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.fnChangeOrdStatus(
                              "prepDeliv",
                              _vm.$store.getters["ADMIN"].ORDER_COMPLETE_DELIV
                            )
                          },
                        },
                      },
                      [_vm._v(" 배송완료 ")]
                    ),
                    !_vm.isPartner
                      ? _c(
                          "button",
                          {
                            staticClass: "btn red-line",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.goCancelApply("prepDeliv")
                              },
                            },
                          },
                          [_vm._v(" 주문취소 ")]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "mt0", class: { "scroll-x": !_vm.isPartner } },
              [
                _c(
                  "table",
                  {
                    staticClass: "data-tb align-c",
                    style: !_vm.isPartner ? "width: 2200px;" : "",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _c("caption", [_vm._v("주문 목록")]),
                    !_vm.isPartner
                      ? _c("colgroup", [
                          _c("col", { attrs: { width: "1.5%" } }),
                          _c("col", { attrs: { width: "3.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "3.5%" } }),
                          _c("col", { attrs: { width: "62px" } }),
                          _c("col", { attrs: { width: "" } }),
                          _c("col", { attrs: { width: "6%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "3%" } }),
                          _c("col", { attrs: { width: "400px" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "3.5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "3.5%" } }),
                        ])
                      : _c("colgroup", [
                          _c("col", { attrs: { width: "1.5%" } }),
                          _c("col", { attrs: { width: "6%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "62px" } }),
                          _c("col", { attrs: { width: "" } }),
                          _c("col", { attrs: { width: "6.5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "360px" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                        ]),
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.prepDeliv.isallchk,
                                expression: "prepDeliv.isallchk",
                              },
                            ],
                            attrs: { id: "chkall", type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.prepDeliv.isallchk)
                                ? _vm._i(_vm.prepDeliv.isallchk, null) > -1
                                : _vm.prepDeliv.isallchk,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.prepDeliv.isallchk,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.prepDeliv,
                                          "isallchk",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.prepDeliv,
                                          "isallchk",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.prepDeliv, "isallchk", $$c)
                                  }
                                },
                                function ($event) {
                                  return _vm.checkAllList(
                                    "prepDeliv",
                                    $event.target.checked
                                  )
                                },
                              ],
                            },
                          }),
                        ]),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("판매구분")])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("파트너사")])
                          : _vm._e(),
                        _c("th", [_vm._v("상품코드")]),
                        _c("th", [_vm._v("단품코드")]),
                        _c("th", [_vm._v("상품순번")]),
                        _c("th", { attrs: { colspan: "2" } }, [
                          _vm._v("상품명"),
                        ]),
                        _c("th", [_vm._v("옵션")]),
                        _c("th", [_vm._v("주문수량")]),
                        _c("th", [_vm._v("판매단가")]),
                        _c("th", [_vm._v("판매금액")]),
                        _c("th", [_vm._v("배송유형")]),
                        _c("th", [_vm._v("배송조건")]),
                        _c("th", [_vm._v("배송비")]),
                        _c("th", [
                          _vm._v("택배사/송장번호/직배송안내문구 등록"),
                        ]),
                        _c("th", [_vm._v("배송수량")]),
                        _c("th", [_vm._v("주문상태")]),
                        _c("th", [_vm._v("클레임상태")]),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("추가상품여부")])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm.prepDeliv.list.length > 0
                      ? _c(
                          "tbody",
                          _vm._l(_vm.prepDeliv.list, function (item, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.prepDeliv.checkedList,
                                      expression: "prepDeliv.checkedList",
                                    },
                                  ],
                                  attrs: {
                                    id: item.orgdelividx,
                                    disabled:
                                      item.orgdelivsort > 1 ||
                                      (_vm.isPartner &&
                                        item.istracking !== "F" &&
                                        _vm.$util.isNull(item.trckerrmsg)),
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    value: item.orgdelividx,
                                    checked: Array.isArray(
                                      _vm.prepDeliv.checkedList
                                    )
                                      ? _vm._i(
                                          _vm.prepDeliv.checkedList,
                                          item.orgdelividx
                                        ) > -1
                                      : _vm.prepDeliv.checkedList,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.prepDeliv.checkedList,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = item.orgdelividx,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.prepDeliv,
                                                "checkedList",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.prepDeliv,
                                                "checkedList",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.prepDeliv,
                                            "checkedList",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        return _vm.checkListForParcel(
                                          "prepDeliv",
                                          item,
                                          $event
                                        )
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              !_vm.isPartner
                                ? _c("td", [_vm._v(_vm._s(item.ispbgoodsname))])
                                : _vm._e(),
                              !_vm.isPartner
                                ? _c("td", [_vm._v(_vm._s(item.dealername))])
                                : _vm._e(),
                              _c("td", [_vm._v(_vm._s(item.goodscode))]),
                              _c("td", [_vm._v(_vm._s(item.optioncode))]),
                              _c("td", [_vm._v(_vm._s(item.goodsturn))]),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "img-thumb size60 link",
                                    class: {
                                      "no-image": _vm.$util.isNull(
                                        item.fullpath
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goFrontGoodsDetail(
                                          item.goodscode
                                        )
                                      },
                                    },
                                  },
                                  [
                                    !_vm.$util.isNull(item.fullpath)
                                      ? _c("img", {
                                          attrs: { src: item.fullpath },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _c("td", { staticClass: "left no-left" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goGoodsDetail(item.goodsno)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.goodsname) + " ")]
                                ),
                              ]),
                              _c(
                                "td",
                                { staticStyle: { "white-space": "pre-wrap" } },
                                [_vm._v(_vm._s(item.optionconts))]
                              ),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.ordcnt))
                                ),
                              ]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(_vm._s(_vm.$util.maskComma(item.price))),
                              ]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.totprice))
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.iscombdelivname))]),
                              _c("td", [
                                _vm._v(_vm._s(item.delivfaretypename)),
                              ]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.delivamt))
                                ),
                              ]),
                              item.ispbgoods === "T"
                                ? _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          !_vm.$util.isNull(item.logistypename)
                                            ? "택배사: " + item.logistypename
                                            : ""
                                        ) +
                                        " " +
                                        _vm._s(
                                          !_vm.$util.isNull(item.invoiceno)
                                            ? ", 운송장번호: " + item.invoiceno
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              item.ispbgoods === "F" &&
                              item.delivtype ===
                                _vm.$store.getters["ADMIN"].DELIV_PARCEL
                                ? _c("td", [
                                    _c("div", { staticClass: "dpb" }, [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.logistype,
                                              expression: "item.logistype",
                                            },
                                          ],
                                          attrs: {
                                            disabled:
                                              item.istracking === "T" &&
                                              _vm.$util.isNull(item.trckerrmsg),
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                item,
                                                "logistype",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.commonCode.logistype,
                                          function (logistype) {
                                            return _c(
                                              "option",
                                              {
                                                key: logistype.cmcode,
                                                domProps: {
                                                  value: logistype.cmcode,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(logistype.codename) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.invoiceno,
                                            expression: "item.invoiceno",
                                          },
                                        ],
                                        staticClass: "short ml3",
                                        attrs: {
                                          disabled:
                                            item.istracking === "T" &&
                                            _vm.$util.isNull(item.trckerrmsg),
                                          maxlength: "50",
                                          oninput:
                                            "this.value = this.value.replace(/(^0|[^0-9])/gi, '');",
                                          type: "text",
                                        },
                                        domProps: { value: item.invoiceno },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              item,
                                              "invoiceno",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      item.istracking === "F" &&
                                      item.orgdelivsort === 1 &&
                                      _vm.isWrite
                                        ? _c("button", {
                                            staticClass: "add ml3",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.goAddInvoice(item)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      (item.istracking === "F" &&
                                        item.orgdelivsort > 1 &&
                                        _vm.isWrite) ||
                                      (item.ordstatus === "ODS006" &&
                                        !_vm.isPartner)
                                        ? _c("button", {
                                            staticClass: "del ml3",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeInvoice(item)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.$util.isNull(item.trckerrmsg) &&
                                      _vm.isWrite
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn blue ml3",
                                              attrs: {
                                                disabled:
                                                  item.istracking === "T" ||
                                                  item.orgdelivsort > 1,
                                                type: "button",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveInvoice(item)
                                                },
                                              },
                                            },
                                            [_vm._v(" 등록 ")]
                                          )
                                        : _vm._e(),
                                      !_vm.$util.isNull(item.trckerrmsg) &&
                                      _vm.isWrite
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn blue ml3",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveInvoice(item)
                                                },
                                              },
                                            },
                                            [_vm._v(" 재등록 ")]
                                          )
                                        : _vm._e(),
                                      !_vm.$util.isNull(item.trckerrmsg)
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn blue ml3",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showTrckErrMsg(
                                                    item.trckerrmsg
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 사유 ")]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ])
                                : _vm._e(),
                              item.ispbgoods === "F" &&
                              item.delivtype ===
                                _vm.$store.getters["ADMIN"].DELIV_DIRECT
                                ? _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.dirdelivmsg,
                                          expression: "item.dirdelivmsg",
                                        },
                                      ],
                                      staticClass: "ml3",
                                      staticStyle: { width: "170px" },
                                      attrs: { maxlength: "300", type: "text" },
                                      domProps: { value: item.dirdelivmsg },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            item,
                                            "dirdelivmsg",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn blue ml3",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveDirDelivMsg(item)
                                          },
                                        },
                                      },
                                      [_vm._v(" 저장 ")]
                                    ),
                                    _vm.isWrite
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn blue ml3",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fnChangeOrdStatus(
                                                  "prepDeliv",
                                                  _vm.$store.getters["ADMIN"]
                                                    .ORDER_IN_DELIVERY,
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 배송중처리 ")]
                                        )
                                      : _vm._e(),
                                    _vm.isWrite
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn blue ml3",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fnChangeOrdStatus(
                                                  "prepDeliv",
                                                  _vm.$store.getters["ADMIN"]
                                                    .ORDER_COMPLETE_DELIV,
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 배송완료 ")]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.delivcnt))
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goOrderHistory(
                                          item.orgdelividx
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.ordstatusname) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goClaimHistory(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.clmstatusname) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              !_vm.isPartner
                                ? _c("td", [_vm._v(_vm._s(item.isaddgoods))])
                                : _vm._e(),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      !_vm.$util.isNull(_vm.inDeliv.list) && _vm.inDeliv.list.length > 0
        ? _c("div", [
            _c("div", { staticClass: "clearfix" }, [
              _c("div", { staticClass: "bar-title small fl" }, [
                _vm._v("배송중"),
              ]),
              _vm.isWrite
                ? _c("div", { staticClass: "btn-group fr" }, [
                    !_vm.isPartner
                      ? _c(
                          "button",
                          {
                            staticClass: "btn blue-line",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.fnChangeOrdStatus(
                                  "inDeliv",
                                  _vm.$store.getters["ADMIN"]
                                    .ORDER_PREPARING_DELIV
                                )
                              },
                            },
                          },
                          [_vm._v(" 배송준비중 ")]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn blue-line",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.checkValidCompDeliv(
                              "inDeliv",
                              _vm.$store.getters["ADMIN"].ORDER_COMPLETE_DELIV
                            )
                          },
                        },
                      },
                      [_vm._v(" 배송완료 ")]
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "mt0", class: { "scroll-x": !_vm.isPartner } },
              [
                _c(
                  "table",
                  {
                    staticClass: "data-tb align-c",
                    style: !_vm.isPartner ? "width: 2100px;" : "",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _c("caption", [_vm._v("주문 목록")]),
                    !_vm.isPartner
                      ? _c("colgroup", [
                          _c("col", { attrs: { width: "1.5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "62px" } }),
                          _c("col", { attrs: { width: "" } }),
                          _c("col", { attrs: { width: "6%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "3.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "6%" } }),
                          _c("col", { attrs: { width: "6.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                        ])
                      : _c("colgroup", [
                          _c("col", { attrs: { width: "1.5%" } }),
                          _c("col", { attrs: { width: "6.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "62px" } }),
                          _c("col", { attrs: { width: "" } }),
                          _c("col", { attrs: { width: "8%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "8%" } }),
                          _c("col", { attrs: { width: "8.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                        ]),
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.inDeliv.isallchk,
                                expression: "inDeliv.isallchk",
                              },
                            ],
                            attrs: { id: "chkall", type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.inDeliv.isallchk)
                                ? _vm._i(_vm.inDeliv.isallchk, null) > -1
                                : _vm.inDeliv.isallchk,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.inDeliv.isallchk,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.inDeliv,
                                          "isallchk",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.inDeliv,
                                          "isallchk",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.inDeliv, "isallchk", $$c)
                                  }
                                },
                                function ($event) {
                                  return _vm.checkAllList(
                                    "inDeliv",
                                    $event.target.checked
                                  )
                                },
                              ],
                            },
                          }),
                        ]),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("판매구분")])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("파트너사")])
                          : _vm._e(),
                        _c("th", [_vm._v("상품코드")]),
                        _c("th", [_vm._v("단품코드")]),
                        _c("th", [_vm._v("상품순번")]),
                        _c("th", { attrs: { colspan: "2" } }, [
                          _vm._v("상품명"),
                        ]),
                        _c("th", [_vm._v("옵션")]),
                        _c("th", [_vm._v("주문수량")]),
                        _c("th", [_vm._v("판매단가")]),
                        _c("th", [_vm._v("판매금액")]),
                        _c("th", [_vm._v("배송유형")]),
                        _c("th", [_vm._v("배송조건")]),
                        _c("th", [_vm._v("배송비")]),
                        _c("th", [_vm._v("택배사")]),
                        _c("th", [_vm._v("송장번호")]),
                        _c("th", [_vm._v("직배송 안내문구")]),
                        _c("th", [_vm._v("배송수량")]),
                        _c("th", [_vm._v("주문상태")]),
                        _c("th", [_vm._v("클레임상태")]),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("추가상품여부")])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm.inDeliv.list.length > 0
                      ? _c(
                          "tbody",
                          _vm._l(_vm.inDeliv.list, function (item, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.inDeliv.checkedList,
                                      expression: "inDeliv.checkedList",
                                    },
                                  ],
                                  attrs: {
                                    id: item.orgdelividx,
                                    disabled: item.ispbgoods === "T",
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    value: item.orgdelividx,
                                    checked: Array.isArray(
                                      _vm.inDeliv.checkedList
                                    )
                                      ? _vm._i(
                                          _vm.inDeliv.checkedList,
                                          item.orgdelividx
                                        ) > -1
                                      : _vm.inDeliv.checkedList,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.inDeliv.checkedList,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = item.orgdelividx,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.inDeliv,
                                                "checkedList",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.inDeliv,
                                                "checkedList",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.inDeliv,
                                            "checkedList",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        return _vm.checkList("inDeliv")
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              !_vm.isPartner
                                ? _c("td", [_vm._v(_vm._s(item.ispbgoodsname))])
                                : _vm._e(),
                              !_vm.isPartner
                                ? _c("td", [_vm._v(_vm._s(item.dealername))])
                                : _vm._e(),
                              _c("td", [_vm._v(_vm._s(item.goodscode))]),
                              _c("td", [_vm._v(_vm._s(item.optioncode))]),
                              _c("td", [_vm._v(_vm._s(item.goodsturn))]),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "img-thumb size60 link",
                                    class: {
                                      "no-image": _vm.$util.isNull(
                                        item.fullpath
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goFrontGoodsDetail(
                                          item.goodscode
                                        )
                                      },
                                    },
                                  },
                                  [
                                    !_vm.$util.isNull(item.fullpath)
                                      ? _c("img", {
                                          attrs: { src: item.fullpath },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _c("td", { staticClass: "left no-left" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goGoodsDetail(item.goodsno)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.goodsname) + " ")]
                                ),
                              ]),
                              _c(
                                "td",
                                { staticStyle: { "white-space": "pre-wrap" } },
                                [_vm._v(_vm._s(item.optionconts))]
                              ),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.ordcnt))
                                ),
                              ]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(_vm._s(_vm.$util.maskComma(item.price))),
                              ]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.totprice))
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.iscombdelivname))]),
                              _c("td", [
                                _vm._v(_vm._s(item.delivfaretypename)),
                              ]),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.delivamt))
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.logistypename))]),
                              item.delivtype ===
                              _vm.$store.getters["ADMIN"].DELIV_PARCEL
                                ? _c("td", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "link",
                                        attrs: { href: "javascript:void(0);" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.goDelivTracking(
                                              item.logistype,
                                              item.invoiceno
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.invoiceno) + " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              item.delivtype ===
                              _vm.$store.getters["ADMIN"].DELIV_DIRECT
                                ? _c("td")
                                : _vm._e(),
                              _c("td", [_vm._v(_vm._s(item.dirdelivmsg))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.delivcnt))
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goOrderHistory(
                                          item.orgdelividx
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.ordstatusname) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goClaimHistory(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.clmstatusname) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              !_vm.isPartner
                                ? _c("td", [_vm._v(_vm._s(item.isaddgoods))])
                                : _vm._e(),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      !_vm.$util.isNull(_vm.completeDeliv.list) &&
      _vm.completeDeliv.list.length > 0
        ? _c("div", [
            _c("div", { staticClass: "clearfix" }, [
              _c("div", { staticClass: "bar-title small fl" }, [
                _vm._v("배송완료(구매확정)"),
              ]),
              _vm.isWrite && !_vm.isPartner
                ? _c("div", { staticClass: "btn-group fr" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn blue-line",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.fnChangeOrdStatus(
                              "completeDeliv",
                              _vm.$store.getters["ADMIN"].ORDER_PURCH_CONFIRM
                            )
                          },
                        },
                      },
                      [_vm._v(" 구매확정처리 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn red-line",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.goExchangeApply("completeDeliv")
                          },
                        },
                      },
                      [_vm._v(" 교환 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn red-line",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.goReturnApply("completeDeliv")
                          },
                        },
                      },
                      [_vm._v(" 반품 ")]
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "mt0", class: { "scroll-x": !_vm.isPartner } },
              [
                _c(
                  "table",
                  {
                    staticClass: "data-tb align-c",
                    style: !_vm.isPartner ? "width: 2100px;" : "",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _c("caption", [_vm._v("주문 목록")]),
                    !_vm.isPartner
                      ? _c("colgroup", [
                          _c("col", { attrs: { width: "1.5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "3.5%" } }),
                          _c("col", { attrs: { width: "3.5%" } }),
                          _c("col", { attrs: { width: "62px" } }),
                          _c("col", { attrs: { width: "" } }),
                          _c("col", { attrs: { width: "7%" } }),
                          _c("col", { attrs: { width: "3.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "3.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "6%" } }),
                          _c("col", { attrs: { width: "6.5%" } }),
                          _c("col", { attrs: { width: "3.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "3.5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                        ])
                      : _c("colgroup", [
                          _c("col", { attrs: { width: "6.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "62px" } }),
                          _c("col", { attrs: { width: "" } }),
                          _c("col", { attrs: { width: "8%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "4.5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5.5%" } }),
                          _c("col", { attrs: { width: "8%" } }),
                          _c("col", { attrs: { width: "8.5%" } }),
                          _c("col", { attrs: { width: "4%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                          _c("col", { attrs: { width: "5%" } }),
                        ]),
                    _c("thead", [
                      _c("tr", [
                        !_vm.isPartner
                          ? _c("th", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.completeDeliv.isallchk,
                                    expression: "completeDeliv.isallchk",
                                  },
                                ],
                                attrs: { id: "chkall", type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.completeDeliv.isallchk
                                  )
                                    ? _vm._i(_vm.completeDeliv.isallchk, null) >
                                      -1
                                    : _vm.completeDeliv.isallchk,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.completeDeliv.isallchk,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.completeDeliv,
                                              "isallchk",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.completeDeliv,
                                              "isallchk",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.completeDeliv,
                                          "isallchk",
                                          $$c
                                        )
                                      }
                                    },
                                    function ($event) {
                                      return _vm.checkAllList(
                                        "completeDeliv",
                                        $event.target.checked
                                      )
                                    },
                                  ],
                                },
                              }),
                            ])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("판매구분")])
                          : _vm._e(),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("파트너사")])
                          : _vm._e(),
                        _c("th", [_vm._v("상품코드")]),
                        _c("th", [_vm._v("단품코드")]),
                        _c("th", [_vm._v("상품순번")]),
                        _c("th", { attrs: { colspan: "2" } }, [
                          _vm._v("상품명"),
                        ]),
                        _c("th", [_vm._v("옵션")]),
                        _c("th", [_vm._v("주문수량")]),
                        _c("th", [_vm._v("판매단가")]),
                        _c("th", [_vm._v("판매금액")]),
                        _c("th", [_vm._v("배송유형")]),
                        _c("th", [_vm._v("배송조건")]),
                        _c("th", [_vm._v("배송비")]),
                        _c("th", [_vm._v("택배사")]),
                        _c("th", [_vm._v("송장번호")]),
                        _c("th", [_vm._v("직배송 안내문구")]),
                        _c("th", [_vm._v("배송수량")]),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("구매확정")])
                          : _vm._e(),
                        _c("th", [_vm._v("주문상태")]),
                        _c("th", [_vm._v("클레임상태")]),
                        !_vm.isPartner
                          ? _c("th", [_vm._v("추가상품여부")])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm.completeDeliv.list.length > 0
                      ? _c(
                          "tbody",
                          _vm._l(
                            _vm.completeDeliv.list,
                            function (item, index) {
                              return _c("tr", { key: index }, [
                                !_vm.isPartner
                                  ? _c("td", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.completeDeliv.checkedList,
                                            expression:
                                              "completeDeliv.checkedList",
                                          },
                                        ],
                                        attrs: {
                                          id:
                                            item.orgdelividx +
                                            "_" +
                                            item.clmgdidx,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          value:
                                            item.orgdelividx +
                                            "_" +
                                            item.clmgdidx,
                                          checked: Array.isArray(
                                            _vm.completeDeliv.checkedList
                                          )
                                            ? _vm._i(
                                                _vm.completeDeliv.checkedList,
                                                item.orgdelividx +
                                                  "_" +
                                                  item.clmgdidx
                                              ) > -1
                                            : _vm.completeDeliv.checkedList,
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$a =
                                                  _vm.completeDeliv.checkedList,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v =
                                                    item.orgdelividx +
                                                    "_" +
                                                    item.clmgdidx,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.completeDeliv,
                                                      "checkedList",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.completeDeliv,
                                                      "checkedList",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.completeDeliv,
                                                  "checkedList",
                                                  $$c
                                                )
                                              }
                                            },
                                            function ($event) {
                                              return _vm.checkList(
                                                "completeDeliv"
                                              )
                                            },
                                          ],
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                                !_vm.isPartner
                                  ? _c("td", [
                                      _vm._v(_vm._s(item.ispbgoodsname)),
                                    ])
                                  : _vm._e(),
                                !_vm.isPartner
                                  ? _c("td", [_vm._v(_vm._s(item.dealername))])
                                  : _vm._e(),
                                _c("td", [_vm._v(_vm._s(item.goodscode))]),
                                _c("td", [_vm._v(_vm._s(item.optioncode))]),
                                _c("td", [_vm._v(_vm._s(item.goodsturn))]),
                                _c("td", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "img-thumb size60 link",
                                      class: {
                                        "no-image": _vm.$util.isNull(
                                          item.fullpath
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goFrontGoodsDetail(
                                            item.goodscode
                                          )
                                        },
                                      },
                                    },
                                    [
                                      !_vm.$util.isNull(item.fullpath)
                                        ? _c("img", {
                                            attrs: { src: item.fullpath },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _c("td", { staticClass: "left no-left" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "link",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goGoodsDetail(item.goodsno)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.goodsname) + " ")]
                                  ),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "white-space": "pre-wrap" },
                                  },
                                  [_vm._v(_vm._s(item.optionconts))]
                                ),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.$util.maskComma(item.ordcnt))
                                  ),
                                ]),
                                _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    _vm._s(_vm.$util.maskComma(item.price))
                                  ),
                                ]),
                                _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    _vm._s(_vm.$util.maskComma(item.totprice))
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(item.iscombdelivname)),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(item.delivfaretypename)),
                                ]),
                                _c("td", { staticClass: "right" }, [
                                  _vm._v(
                                    _vm._s(_vm.$util.maskComma(item.delivamt))
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(item.logistypename))]),
                                item.delivtype ===
                                _vm.$store.getters["ADMIN"].DELIV_PARCEL
                                  ? _c("td", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "link",
                                          attrs: {
                                            href: "javascript:void(0);",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goDelivTracking(
                                                item.logistype,
                                                item.invoiceno
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.invoiceno) + " "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                item.delivtype ===
                                _vm.$store.getters["ADMIN"].DELIV_DIRECT
                                  ? _c("td")
                                  : _vm._e(),
                                _c("td", [_vm._v(_vm._s(item.dirdelivmsg))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.$util.maskComma(item.delivcnt))
                                  ),
                                ]),
                                !_vm.isPartner
                                  ? _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "white-space": "pre-wrap",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.cfmconts))]
                                    )
                                  : _vm._e(),
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "link",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goOrderHistory(
                                            item.orgdelividx
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.ordstatusname) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "link",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goClaimHistory(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.clmstatusname) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                                !_vm.isPartner
                                  ? _c("td", [_vm._v(_vm._s(item.isaddgoods))])
                                  : _vm._e(),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "bar-title small" }, [_vm._v("사은품 정보")]),
      _c(
        "table",
        {
          staticClass: "data-tb align-c",
          attrs: { cellpadding: "0", cellspacing: "0" },
        },
        [
          _c("caption", [_vm._v("사은품 목록")]),
          _vm._m(2),
          _vm._m(3),
          !_vm.$util.isNull(_vm.orderGiftList) && _vm.orderGiftList.length > 0
            ? _c(
                "tbody",
                _vm._l(_vm.orderGiftList, function (item, index) {
                  return _c("tr", { key: "gift_" + index }, [
                    _c("td", [_vm._v(_vm._s(item.giftcode))]),
                    _c("td", [
                      _c(
                        "div",
                        {
                          staticClass: "img-thumb size60",
                          class: {
                            "no-image": _vm.$util.isNull(item.fullpath),
                          },
                        },
                        [
                          !_vm.$util.isNull(item.fullpath)
                            ? _c("img", { attrs: { src: item.fullpath } })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _c("td", { staticClass: "left no-left" }, [
                      _c("p", { staticClass: "mg0" }, [
                        _vm._v(_vm._s(item.giftname)),
                      ]),
                    ]),
                    _c("td", [_vm._v(_vm._s(item.rcvamt))]),
                    _c("td", [_vm._v(_vm._s(item.promoname))]),
                    _c("td", [_vm._v(_vm._s(item.regdate))]),
                  ])
                }),
                0
              )
            : _c("tbody", [_vm._m(4)]),
        ]
      ),
      _c("div", { staticClass: "col2 pd" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "bar-title small" }, [_vm._v("주문정보")]),
          _c(
            "table",
            {
              staticClass: "gray-tb",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _vm._m(5),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v("판매금액 합계")]),
                  _c("td", { staticClass: "right", attrs: { colspan: "2" } }, [
                    _c("strong", { staticClass: "large-txt" }, [
                      _vm._v(_vm._s(_vm.$util.maskComma(_vm.info.ordtotprice))),
                    ]),
                  ]),
                ]),
                !_vm.isPartner
                  ? _c("tr", [
                      _c("th", [_vm._v("자사 배송비(+)")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.info.isolatetype === "N"
                              ? "기본배송비"
                              : "도서산간배송비"
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "right" }, [
                        _vm._v(
                          _vm._s(_vm.$util.maskComma(_vm.info.dadadelivamt))
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("tr", [
                  _c("th", [_vm._v("파트너사 배송비(+)")]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.info.isolatetype === "N"
                          ? "기본배송비"
                          : "도서산간배송비"
                      )
                    ),
                  ]),
                  _c("td", { staticClass: "right" }, [
                    _vm._v(_vm._s(_vm.$util.maskComma(_vm.info.ptndelivamt))),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("PG할인금액(-)")]),
                  _c("td", { staticClass: "right", attrs: { colspan: "2" } }, [
                    _c("span", { staticClass: "txt-red" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$util.maskComma(
                            Number(_vm.info.discount_amount) * -1
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("할인 내역 합계(-)")]),
                  _c("td", { staticClass: "right", attrs: { colspan: "2" } }, [
                    _c("span", { staticClass: "txt-red" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$util.maskComma(Number(_vm.info.totsaleamt) * -1)
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  !_vm.isPartner
                    ? _c("th", [_vm._v("적립금/D포인트 사용 합계(-)")])
                    : _c("th", [_vm._v("적립금 사용 합계(-)")]),
                  _c("td", { staticClass: "right", attrs: { colspan: "2" } }, [
                    _c("span", { staticClass: "txt-red" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$util.maskComma(Number(_vm.info.totpointamt) * -1)
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("실 결제금액")]),
                  _c("td", { staticClass: "right", attrs: { colspan: "2" } }, [
                    _c("strong", { staticClass: "large-txt" }, [
                      _vm._v(
                        _vm._s(_vm.$util.maskComma(_vm.info.rpaytotprice))
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "bar-title small" }, [_vm._v("할인 내역")]),
          _c(
            "div",
            {
              staticClass: "scroll-y mt0",
              staticStyle: { "max-height": "260px" },
            },
            [
              _c(
                "table",
                {
                  staticClass: "gray-tb align-c",
                  attrs: { cellpadding: "0", cellspacing: "0" },
                },
                [
                  _vm._m(6),
                  _vm._m(7),
                  !_vm.$util.isNull(_vm.orderDiscountList) &&
                  _vm.orderDiscountList.length > 0
                    ? _c(
                        "tbody",
                        _vm._l(_vm.orderDiscountList, function (item, index) {
                          return _c("tr", { key: "discount_" + index }, [
                            item.rowno === 1
                              ? _c("td", { attrs: { rowspan: item.rowspan } }, [
                                  _vm._v(_vm._s(item.typename)),
                                ])
                              : _vm._e(),
                            _c("td", [_vm._v(_vm._s(item.goodscode))]),
                            _c("td", [_vm._v(_vm._s(item.optioncode))]),
                            _c("td", { staticClass: "left" }, [
                              _vm._v(_vm._s(item.cpnname)),
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _c("span", { staticClass: "txt-red" }, [
                                _vm._v(
                                  _vm._s(_vm.$util.maskComma(item.discountamt))
                                ),
                              ]),
                            ]),
                          ])
                        }),
                        0
                      )
                    : _c("tbody", [_vm._m(8)]),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "col2 pd mt10" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "bar-title small" }, [_vm._v("결제정보")]),
          _c(
            "table",
            {
              staticClass: "gray-tb",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _vm._m(9),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v("결제일시")]),
                  _c("td", [_vm._v(_vm._s(_vm.info.paymentdate))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("결제수단")]),
                  _c("td", [
                    _c("span", [_vm._v(_vm._s(_vm.info.paywaytypename))]),
                    _vm.info.paywaytype ===
                    _vm.$store.getters["ADMIN"].PAY_CREDIT_CARD
                      ? _c("span", { staticClass: "left-bar" }, [
                          _vm._v(" " + _vm._s(_vm.info.cardcompany) + " "),
                        ])
                      : _vm._e(),
                    _vm.info.paywaytype ===
                    _vm.$store.getters["ADMIN"].PAY_CREDIT_CARD
                      ? _c("span", { staticClass: "left-bar" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.info.planmonth == 0 ||
                                  _vm.$util.isNull(_vm.info.planmonth)
                                  ? "일시불"
                                  : _vm.info.planmonth + "개월"
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.info.paywaytype ===
                    _vm.$store.getters["ADMIN"].PAY_VIRTURE_ACCOUNT
                      ? _c("span", { staticClass: "left-bar" }, [
                          _vm._v(" " + _vm._s(_vm.info.virbank) + " "),
                        ])
                      : _vm._e(),
                    _vm.info.paywaytype ===
                    _vm.$store.getters["ADMIN"].PAY_VIRTURE_ACCOUNT
                      ? _c("span", { staticClass: "left-bar" }, [
                          _vm._v(" " + _vm._s(_vm.info.accntnumber) + " "),
                        ])
                      : _vm._e(),
                    _vm.info.paywaytype ===
                    _vm.$store.getters["ADMIN"].PAY_MOBILE
                      ? _c("span", { staticClass: "left-bar" }, [
                          _vm._v(" " + _vm._s(_vm.info.mopcarrier) + " "),
                        ])
                      : _vm._e(),
                    _vm._v("   "),
                    _vm.info.paywaytype ===
                      _vm.$store.getters["ADMIN"].PAY_ACCOUNT_TRANSFER &&
                    _vm.$util.isNull(_vm.info.cashreceiptkey)
                      ? _c(
                          "button",
                          {
                            staticClass: "btn small blue-line",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.openCashReceiptPopup(_vm.info)
                              },
                            },
                          },
                          [_vm._v(" 현금영수증 수기발급 ")]
                        )
                      : _vm._e(),
                    _vm.info.paywaytype ===
                      _vm.$store.getters["ADMIN"].PAY_ACCOUNT_TRANSFER &&
                    !_vm.$util.isNull(_vm.info.cashreceiptkey)
                      ? _c("span", [_vm._v(" [현금영수증 발급완료] ")])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "right" }, [
          !_vm.isPartner
            ? _c("div", { staticClass: "bar-title small" }, [
                _vm._v("적립금/D포인트 사용 내역"),
              ])
            : _c("div", { staticClass: "bar-title small" }, [
                _vm._v("적립금 사용 내역"),
              ]),
          _c(
            "table",
            {
              staticClass: "gray-tb align-c",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _vm._m(10),
              _vm._m(11),
              !_vm.isPartner
                ? _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v("임직원적립금")]),
                      _c("td", { staticClass: "right" }, [
                        _c("span", { staticClass: "txt-red" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$util.maskComma(_vm.info.empreservetotamt)
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("적립금")]),
                      _c("td", { staticClass: "right" }, [
                        _c("span", { staticClass: "txt-red" }, [
                          _vm._v(
                            _vm._s(_vm.$util.maskComma(_vm.info.reservetotamt))
                          ),
                        ]),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("D포인트")]),
                      _c("td", { staticClass: "right" }, [
                        _c("span", { staticClass: "txt-red" }, [
                          _vm._v(
                            _vm._s(_vm.$util.maskComma(_vm.info.epointtotamt))
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v("적립금")]),
                      _c("td", { staticClass: "right" }, [
                        _c("span", { staticClass: "txt-red" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$util.maskComma(
                                  _vm.info.reservetotamt +
                                    _vm.info.empreservetotamt +
                                    _vm.info.epointtotamt
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "col2 pd mt10" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "bar-title small" }, [
            _vm._v("주문자 정보"),
          ]),
          _c(
            "table",
            {
              staticClass: "gray-tb",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _vm._m(12),
              !_vm.isPartner
                ? _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("주문자명")]),
                      _c("td", [_vm._v(_vm._s(_vm.info.ordname))]),
                      _c("th", [_vm._v("주문자ID")]),
                      _c("td", [_vm._v(_vm._s(_vm.info.ordid))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("주문자 연락처")]),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _vm._v(_vm._s(_vm.$util.maskTel(_vm.info.ordtel))),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("주문자 Email")]),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _vm._v(_vm._s(_vm.info.ordemail)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("주문자 주소")]),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              !_vm.$util.isNull(_vm.info.ordpost)
                                ? "[" + _vm.info.ordpost + "]"
                                : ""
                            ) +
                            " " +
                            _vm._s(_vm.info.ordaddr) +
                            " " +
                            _vm._s(
                              _vm.$util.isNull(_vm.info.ordaddrdetail)
                                ? ""
                                : " " + _vm.info.ordaddrdetail
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ])
                : _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("주문자명")]),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _vm._v(_vm._s(_vm.info.ordname)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("주문자 연락처")]),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _vm._v(_vm._s(_vm.$util.maskTel(_vm.info.ordtel))),
                      ]),
                    ]),
                  ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "clearfix" }, [
            _c("div", { staticClass: "bar-title small fl mb0" }, [
              _vm._v("배송지 정보"),
            ]),
            _vm.isWrite
              ? _c("div", { staticClass: "btn-group fr" }, [
                  _vm.ischgrcvinfo
                    ? _c(
                        "button",
                        {
                          staticClass: "btn small blue-line",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.goChgRcvinfo(_vm.info.ordno)
                            },
                          },
                        },
                        [_vm._v(" 배송지 정보 수정 ")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _c(
            "table",
            {
              staticClass: "gray-tb",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _vm._m(13),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v("수령인 명")]),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _vm._v(_vm._s(_vm.info.rcvname)),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("연락처1")]),
                  _c("td", [
                    _vm._v(_vm._s(_vm.$util.maskTel(_vm.info.rcvtel1))),
                  ]),
                  _c("th", [_vm._v("연락처2")]),
                  _c("td", [
                    _vm._v(_vm._s(_vm.$util.maskTel(_vm.info.rcvtel2))),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("주소")]),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _vm._v(
                      " [" +
                        _vm._s(_vm.info.rcvpost) +
                        "] " +
                        _vm._s(_vm.info.rcvaddr) +
                        _vm._s(
                          _vm.$util.isNull(_vm.info.rcvaddrdetail)
                            ? ""
                            : " " + _vm.info.rcvaddrdetail
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("배송요청사항")]),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.info.rcvreqtypename))]),
                    !_vm.$util.isNull(_vm.info.rcvreqdetail)
                      ? _c("span", { staticClass: "left-bar" }, [
                          _vm._v(" " + _vm._s(_vm.info.rcvreqdetail) + " "),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      !_vm.isPartner
        ? _c("fragment", [
            _c("div", { staticClass: "bar-title small" }, [
              _vm._v("관리자 메모"),
            ]),
            _vm.isWrite
              ? _c("div", { staticClass: "memo-write" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.memo.inputMemo,
                        expression: "memo.inputMemo",
                      },
                    ],
                    attrs: { placeholder: "메모를 작성해 주세요!" },
                    domProps: { value: _vm.memo.inputMemo },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.memo, "inputMemo", $event.target.value)
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn big blue",
                      attrs: { type: "button" },
                      on: { click: _vm.addMemo },
                    },
                    [_vm._v(" 메모 "), _c("br"), _vm._v(" 저장 ")]
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "scroll-y",
                staticStyle: {
                  width: "100%",
                  "max-height": "550px",
                  "margin-bottom": "0",
                },
              },
              [
                _c(
                  "table",
                  {
                    staticClass: "data-tb align-c",
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _c("colgroup", [
                      _c("col", { attrs: { width: "5%" } }),
                      _c("col", { attrs: { width: "20%" } }),
                      _c("col", { attrs: { width: "8%" } }),
                      _c("col", { attrs: { width: "" } }),
                      _c("col", { attrs: { width: "40px" } }),
                    ]),
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("No")]),
                        _c("th", [
                          _vm._v(" 작성일시 "),
                          _c("button", {
                            staticClass: "sort",
                            class: [
                              { up: _vm.memo.psort === "regdate_asc" },
                              { down: _vm.memo.psort === "regdate_desc" },
                            ],
                            attrs: { value: _vm.memo.psort, type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.sortToggle(_vm.memo.psort)
                              },
                            },
                          }),
                        ]),
                        _c("th", [_vm._v("작성자")]),
                        _c("th", { attrs: { colspan: "2" } }, [_vm._v("내용")]),
                      ]),
                    ]),
                    !_vm.$util.isNull(_vm.orderMemoList) &&
                    _vm.orderMemoList.length > 0
                      ? _c(
                          "tbody",
                          _vm._l(_vm.orderMemoList, function (item, index) {
                            return _c("tr", { key: item.ordmemoidx }, [
                              _c("td", [_vm._v(_vm._s(index + 1))]),
                              _c("td", [_vm._v(_vm._s(item.regdate))]),
                              _c("td", [_vm._v(_vm._s(item.regusername))]),
                              _c("td", { staticClass: "left" }, [
                                _vm._v(_vm._s(item.memo)),
                              ]),
                              _c("td", { staticClass: "no-left" }, [
                                _vm.isWrite
                                  ? _c("button", {
                                      staticClass: "del mg0",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeMeno(item.ordmemoidx)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                            ])
                          }),
                          0
                        )
                      : _c("tbody", [
                          _c("tr", [
                            _c("td", { attrs: { colspan: "5" } }, [
                              _vm._v("조회 결과가 존재하지 않습니다."),
                            ]),
                          ]),
                        ]),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm.isGoodsDetailShow
        ? _c("GoodsDetail", {
            attrs: { activeGoodsNo: _vm.activeGoodsNo },
            on: { closePopup: _vm.closeGoodsDetail },
          })
        : _vm._e(),
      _vm.isShowChgRcvInfo
        ? _c("ChangeRcvinfoPopup", {
            attrs: { activeOrdno: _vm.activeOrdno },
            on: { closePopup: _vm.closeChgRcvinfo },
          })
        : _vm._e(),
      _vm.isShowAddInvoice
        ? _c("AddInvoicePopup", {
            attrs: {
              activeOrdgdidx: _vm.activeOrdgdidx,
              activeOrgdelividx: _vm.activeOrgdelividx,
            },
            on: { closePopup: _vm.closeAddInvoice },
          })
        : _vm._e(),
      _vm.isShowApplyClaim
        ? _c("ApplyClaimPopup", {
            attrs: { claimParams: _vm.claimParams },
            on: { closePopup: _vm.closeApplyClaim },
          })
        : _vm._e(),
      _vm.isShowCashReceipt
        ? _c("CashReceiptPopup", {
            attrs: { receiptInfo: _vm.receiptInfo },
            on: { closePopup: _vm.closeCashReceipt },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "bar-title small fl" }, [
        _vm._v("주문상품목록"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "bar-title small fl" }, [_vm._v("입금대기")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "62px" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "12%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("사은품코드")]),
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("사은품명")]),
        _c("th", [_vm._v("수량")]),
        _c("th", [_vm._v("적용프로모션 명")]),
        _c("th", [_vm._v("일자")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "7" } }, [
        _vm._v("사은품 내역이 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "200px" } }),
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "13%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "15%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("구분")]),
        _c("th", [_vm._v("상품코드")]),
        _c("th", [_vm._v("단품코드")]),
        _c("th", [_vm._v("내용")]),
        _c("th", [_vm._v("금액")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "4" } }, [
        _vm._v("조회 결과가 존재하지 않습니다."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "200px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "200px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("구분")]), _c("th", [_vm._v("금액")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "120px" } }),
      _c("col", { attrs: { width: "155px" } }),
      _c("col", { attrs: { width: "120px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "120px" } }),
      _c("col", { attrs: { width: "155px" } }),
      _c("col", { attrs: { width: "120px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }