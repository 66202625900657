var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.childPagingData.listCount > 0
    ? _c(
        "div",
        { staticClass: "paging" },
        [
          _c("a", {
            staticClass: "arrow left",
            attrs: { href: "javascript:void(0);" },
            on: {
              click: function ($event) {
                return _vm.goPage(_vm.childPagingData.page - 1)
              },
            },
          }),
          _vm._l(_vm.pageData, function (page) {
            return _c(
              "a",
              {
                key: page.page,
                class: { active: page.isActive },
                attrs: { href: "javascript:void(0);" },
                on: {
                  click: function ($event) {
                    return _vm.goPage(page.page)
                  },
                },
              },
              [_vm._v(" " + _vm._s(page.page) + " ")]
            )
          }),
          _c("a", {
            staticClass: "arrow right",
            attrs: { href: "javascript:void(0);" },
            on: {
              click: function ($event) {
                return _vm.goPage(_vm.childPagingData.page + 1)
              },
            },
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }