var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "top-menu" }, [
    _c("div", { staticClass: "top clearfix" }, [
      _c("div", { staticClass: "logo link" }, [
        _c("img", {
          attrs: {
            src: require("../../../assets/img/logo.png"),
            alt: "D.PLOT",
          },
          on: { click: _vm.goMain },
        }),
      ]),
      _c("ul", { staticClass: "user" }, [
        _c("li", { staticClass: "name-info" }, [
          _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.user.name))]),
          _vm._v(" 님, 안녕하세요. "),
        ]),
        _c("li", { staticClass: "today-info" }, [
          _c("span", { staticClass: "today" }, [_vm._v("Today :")]),
          _c("span", { staticClass: "date" }, [_vm._v(_vm._s(_vm.toDay))]),
        ]),
        _c("li", { staticClass: "btns" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-outline-light",
              attrs: { href: "javascript:void(0);" },
              on: { click: _vm.goAccessHistoryPopup },
            },
            [_vm._v(" 접속기록보기 ")]
          ),
          _c(
            "a",
            {
              staticClass: "btn btn-outline-yellow",
              attrs: { href: "javascript:void(0);" },
              on: { click: _vm.goFavoriteMenu },
            },
            [_vm._v(" 즐겨찾기 ")]
          ),
          _c(
            "a",
            {
              staticClass: "btn btn-outline-light",
              attrs: { href: "javascript:void(0);" },
              on: { click: _vm.goMain },
            },
            [_vm._v(" " + _vm._s(_vm.goMainText) + " ")]
          ),
          _c(
            "a",
            {
              staticClass: "btn btn-outline-light",
              attrs: { href: "javascript:void(0);" },
              on: { click: _vm.goFrontMain },
            },
            [_vm._v(" 쇼핑몰메인 ")]
          ),
          _c(
            "a",
            {
              staticClass: "btn btn-dark",
              attrs: { href: "javascript:void(0);" },
              on: { click: _vm.logOut },
            },
            [_vm._v("로그아웃")]
          ),
        ]),
      ]),
    ]),
    _c(
      "ul",
      { staticClass: "nav clearfix" },
      _vm._l(_vm.topMenu, function (menu) {
        return _c(
          "li",
          { key: menu.code },
          [
            _c(
              "router-link",
              {
                class: { active: menu.isactive },
                attrs: {
                  to: { name: menu.description, params: { code: menu.code } },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onActive(menu)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "icons",
                  class: [
                    { "icon-setting": menu.code === "A" },
                    { "icon-goods": menu.code === "C" },
                    { "icon-order": menu.code === "D" },
                    { "icon-manage": menu.code === "E" },
                    { "icon-member": menu.code === "F" },
                    { "icon-stats": menu.code === "G" },
                    { "icon-partner": menu.code === "H" },
                    { "icon-calculate": menu.code === "I" },
                    { "icon-promotion": menu.code === "K" },
                    { "icon-cs": menu.code === "L" },
                  ],
                }),
                _c("span", [_vm._v(_vm._s(menu.name))]),
              ]
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }